import * as Yup from 'yup'

import { type ProspectFormValues } from './ProspectForm.type'
import useQuotesRequestValidationSchema from '../../quotes/QuotesRequestFormLegacy/validationSchema'

const useValidationSchema = () => {
  const quotesRequestValidation = useQuotesRequestValidationSchema()

  return quotesRequestValidation.shape({
    name: Yup.string().required(),
  }) as Yup.ObjectSchema<ProspectFormValues, ProspectFormValues>
}

export default useValidationSchema
