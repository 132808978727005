import MarkerIcon from '@mui/icons-material/RoomOutlined'
import TimerIcon from '@mui/icons-material/TimerOutlined'
import TruckIcon from '@mui/icons-material/LocalShippingOutlined'

import { mergeClassName } from '../../../utils/mergeClassName'
import { useTimeFormatter } from '../../../utils/time'

export enum CheckpointType {
  Action,
  Location,
  Transport,
}

type CheckpointProps = {
  type: CheckpointType
  time?: Date
  label?: React.ReactNode
  highlighted?: boolean
}

const Checkpoint: React.FC<CheckpointProps> = ({
  type,
  time,
  label,
  highlighted = false,
}) => {
  const { formatTime } = useTimeFormatter()

  const getTimelineIcon = () => {
    switch (type) {
      case CheckpointType.Action:
        return TimerIcon
      case CheckpointType.Location:
        return MarkerIcon
    }
  }
  const TimelineIcon = getTimelineIcon()

  return (
    <li className="mb-4 ml-6">
      { TimelineIcon && (
        <span className={mergeClassName(
          'absolute -left-3 flex h-6 w-6 items-center justify-center rounded-full bg-slate-200 ring-8 ring-white',
          highlighted && 'bg-slate-400 text-gray-100',
        )}
        >
          <TimelineIcon className="!text-[18px]" />
        </span>
      ) }

      { time && (
        <time className="mb-1 flex items-center text-lg font-semibold text-gray-500">
          { formatTime(time) }
        </time>
      ) }

      { label && (
        <p className="pb-2 text-base font-normal text-gray-400">
          { label }
        </p>
      ) }

      { type === CheckpointType.Transport && (
        <div
          className="flex h-10 items-center text-zinc-400"
        >
          <div className="absolute -left-px h-10 w-px border-l border-dashed border-zinc-400 bg-white" />
          <TruckIcon />
        </div>
      ) }
    </li>
  )
}

export default Checkpoint
