import { useTranslation } from 'react-i18next'

import SelectMonths from './SelectMonths'
import SelectWeekDays from './SelectWeekDays'

type TemporalityEveryWeekDayFormProps = {
  prefixName: string
}

const TemporalityEveryWeekDayForm: React.FC<TemporalityEveryWeekDayFormProps> = ({
  prefixName,
}) => {
  const daysName = `${prefixName}.days`
  const monthsName = `${prefixName}.months`

  const { t } = useTranslation()

  return (
    <div className="flex w-full flex-col gap-2 lg:flex-row">
      <div className="flex gap-2">
        <div className="w-[60px] shrink-0 pt-2">
          { t('forms.companyBranch.availability.temporality.every') }
        </div>
        <div className="w-full lg:w-[243px]">
          <SelectWeekDays name={daysName} className="bg-white" />
        </div>
      </div>

      <div className="flex gap-2">
        <div className="w-[60px] shrink-0 pt-2 lg:w-[30px] lg:text-center">
          { t('forms.companyBranch.availability.temporality.from') }
        </div>
        <div className="w-full lg:w-[243px]">
          <SelectMonths name={monthsName} className="bg-white" />
        </div>
      </div>
    </div>
  )
}

export default TemporalityEveryWeekDayForm
