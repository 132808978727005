import * as Yup from 'yup'

import { getStringTranslationsValidation } from '../../../../common/translation'
import { type CompanyPayload } from '../../../../modules/companies'

const useValidationSchema = () => {
  return Yup.object<CompanyPayload>({
    identification: Yup.object({
      name: getStringTranslationsValidation({ min: 3 }).required(),
    }).required(),
  }) as Yup.ObjectSchema<CompanyPayload, CompanyPayload>
}

export default useValidationSchema
