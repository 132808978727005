import { useLazyQuery } from '@apollo/client'

import { type QueryResult } from '../../graphQl'
import * as queries from './adminAccounting.queries'

export type DateRange = {
  from: Date
  to?: Date
}

type UseExportReportHookResult = {
  exportReport: (dateRange: DateRange) => Promise<string>
} & QueryResult<string>

/**
 * return sales report (base64 csv file content)
 */
export const useSalesReportExport = (queryArgs = {}): UseExportReportHookResult => {
  const [fetch, { data, previousData, networkStatus, loading, error, ...queryProps }] = useLazyQuery(
    queries.exportSalesReport,
    {
      fetchPolicy: 'network-only',
      ...queryArgs,
    },
  )
  const exportReport = async (dateRange: DateRange) => {
    const { data } = await fetch({ variables: { dateRange } })
    return data?.admin_exportSalesReport
  }

  return {
    exportReport,
    data: data?.admin_exportSalesReport,
    loading,
    error,
    ...queryProps,
  }
}

/**
 * return customers balance report (base64 csv file content)
 */
export const useCustomerBalanceReportExport = (queryArgs = {}): UseExportReportHookResult => {
  const [fetch, { data, previousData, networkStatus, loading, error, ...queryProps }] = useLazyQuery(
    queries.exportCustomersBalanceReport,
    {
      fetchPolicy: 'network-only',
      ...queryArgs,
    },
  )
  const exportReport = async (dateRange: DateRange) => {
    const { data } = await fetch({ variables: { dateRange } })
    return data?.admin_exportCustomersBalanceReport
  }

  return {
    exportReport,
    data: data?.admin_exportCustomersBalanceReport,
    loading,
    error,
    ...queryProps,
  }
}
