/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { type PromoCode, useGetSubtotalWithPromoCode } from '../../../../modules/adminPromoCodes'
import { type Quote } from '../../../../modules/quotes'
import { ignoreTimezone } from '../../../../utils/date'
import { mergeClassName } from '../../../../utils/mergeClassName'
import AlternativeDate from './AlternativeDate'
import DateBox from './DateBox'

type AlternativeDatesProps = {
  quote: Quote
  promoCode?: PromoCode
  className?: string
}

const AlternativeDates: React.FC<AlternativeDatesProps> = ({
  quote,
  promoCode,
  className,
}) => {
  const priceWithPromoCode = useGetSubtotalWithPromoCode()

  const alternativeDates = quote.alternativeDates
  if (!alternativeDates?.length) {
    return null
  }

  const before = alternativeDates.filter(date => new Date(date.date) < new Date(quote.date))
  const after = alternativeDates.filter(date => new Date(date.date) > new Date(quote.date))

  return (
    <div className={mergeClassName(
      'flex flex-wrap gap-1 justify-center sm:justify-normal',
      className,
    )}
    >
      { before.map(alternativeDate => (
        <AlternativeDate
          key={new Date(alternativeDate.date).getTime()}
          alternativeDate={alternativeDate}
          quote={quote}
          promoCode={promoCode}
        />
      )) }

      <DateBox
        current
        date={ignoreTimezone(quote.date)!}
        price={priceWithPromoCode(quote.subtotal, promoCode).price}
      />

      { after.map(alternativeDate => (
        <AlternativeDate
          key={new Date(alternativeDate.date).getTime()}
          alternativeDate={alternativeDate}
          quote={quote}
          promoCode={promoCode}
        />
      )) }

    </div>
  )
}

export default AlternativeDates
