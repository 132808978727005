import { mergeClassName } from '../../utils/mergeClassName'

type ReactTd = React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>

export type CellProps = {
  right?: boolean
  center?: boolean
  noWrap?: boolean
} & ReactTd

const Column: React.FC<CellProps> = ({
  className,
  children,
  right = false,
  center = false,
  noWrap = false,
  ...props
}) => {
  return (
    <td
      className={mergeClassName(
        'px-6 first:pl-4 last:pr-4 py-4 text-neutral-600',
        right && 'text-right',
        center && 'text-center',
        noWrap && 'whitespace-nowrap',
        className,
      )}
      {...props}
    >
      { children }
    </td>
  )
}

export default Column
