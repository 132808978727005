import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import AuthCode from 'react-auth-code-input'
import { useFormContext } from 'react-hook-form'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import InputLabel from '@mui/material/InputLabel'

const InputsContainer = styled(Box)(({ theme }) => ({
  '&.error *': {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    outline: '0 none',
  },
  '& > div': {
    display: 'flex',
    gap: 10,
    margin: '1rem 0',
  },
  '& > div > input': {
    width: 45,
    height: 45,
    padding: '0.5rem',
    textAlign: 'center',
    fontSize: 22,
    border: 'solid 1px #737373',
    borderRadius: 4,
  },
  [theme.breakpoints.down('sm')]: {
    '& > div': {
      gap: 5,
    },
    '& > div > input': {
      width: 40,
      height: 40,
      padding: '0.25rem',
      fontSize: 18,
    },
  },
  [theme.breakpoints.down(350)]: {
    '& > div': {
      gap: 0,
    },
    '& > div > input': {
      width: 35,
      height: 40,
      padding: '0.25rem',
      fontSize: 18,
    },
  },
}))

type SixDigitsCodeFieldProps = {
  name: string
  label?: React.ReactNode
  danger?: boolean
}

const SixDigitsCodeField: React.FC<SixDigitsCodeFieldProps> = ({
  name,
  label,
  danger = false,
}) => {
  const { t } = useTranslation()
  const { setValue, formState: { errors, submitCount } } = useFormContext()

  const fieldError = errors?.[name]

  const handleOnChange = (value: string) => {
    setValue(name, value, { shouldValidate: submitCount > 0 })
  }

  return (
    <>
      { label && (
        <Box mt="1rem">
          <InputLabel>{ label }</InputLabel>
        </Box>
      ) }

      <InputsContainer
        className={clsx((danger || fieldError) && 'error')}
      >
        <AuthCode allowedCharacters="numeric" onChange={handleOnChange} />
      </InputsContainer>

      { fieldError && (
        <Typography color="error">
          { t('formValidation.required') }
        </Typography>
      ) }
    </>
  )
}

export default SixDigitsCodeField
