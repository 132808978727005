import { mergeClassName } from '../../utils/mergeClassName'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const Label: React.FC<ReactDiv> = ({
  children,
  className,
}) => {
  return (
    <div className={mergeClassName(
      'text-gray-400 text-sm',
      className,
    )}
    >
      { children }
    </div>
  )
}

export default Label
