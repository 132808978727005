import { Views, type EventWrapperProps } from 'react-big-calendar'

import { mergeClassName } from '../../../utils/mergeClassName'
import { useCalendar } from '../CalendarContext'
import { type Event } from './EventCalendar.types'

interface EventWrapperPropsWithChildren extends EventWrapperProps<Event> {
  children?: React.ReactNode
}

const EventWrapper: React.FC<EventWrapperPropsWithChildren> = ({ children }) => {
  const { view } = useCalendar()
  const isMonthlyView = view === Views.MONTH

  return (
    <div className={mergeClassName(
      isMonthlyView && 'hidden lg:flex',
    )}
    >
      { children }
    </div>
  )
}

export default EventWrapper
