import Spinner from '../../../Spinner'
import { useCurrentUser, useUpdateUserAction, type UpdateUserPayload } from '../../../../modules/users'
import UpdateUserForm from '../../../forms/users/UpdateUserForm'
import ServerError from '../../../errors/ServerError'

type EditPersonalInformationsProps = {
  onClose?: () => void
}

const EditPersonalInformations: React.FC<EditPersonalInformationsProps> = ({ onClose }) => {
  const { data: user } = useCurrentUser()
  const { updateUser, error } = useUpdateUserAction()

  if (!user) {
    return <Spinner />
  }

  const onSubmit = async (payload: UpdateUserPayload) => {
    try {
      await updateUser(payload)
      onClose?.()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      { error && <ServerError error={error} /> }
      <UpdateUserForm onSubmit={onSubmit} onClose={onClose} />
    </>
  )
}

export default EditPersonalInformations
