import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'

import { Form, TranslatedInput, SubmitButton } from '../../../form'
import useValidationSchema from './validationSchema'
import { type Company, type CompanyPayload } from '../../../../modules/companies'
import { CompanyToFormData } from './formDataBuilder'
import { useNavigate } from '../../../../modules/navigation'
import ImageHandler from '../../../ImageHandler'
import { useConfig } from '../../../../modules/config'

type CompanyFormProps = {
  onSubmit: (payload: CompanyPayload) => Promise<any>
  data?: Company
  backlink?: string
}

const CompanyForm: React.FC<CompanyFormProps> = ({
  onSubmit,
  data,
  backlink,
}) => {
  const { t } = useTranslation()
  const { navigate } = useNavigate()
  const schema = useValidationSchema()
  const form = useForm<CompanyPayload>({
    resolver: yupResolver(schema),
    defaultValues: data ? CompanyToFormData(data) : undefined,
  })
  const [uploadedLogo, setUploadedLogo] = useState<File | null>()
  const logoSize = useConfig<number>('companies.logoSize')

  const editing = !!data

  const handleSubmit = async (payload: CompanyPayload) => await onSubmit({
    ...payload,
    logo: uploadedLogo === undefined ? undefined : { en: uploadedLogo },
  })

  useEffect(() => {
    setUploadedLogo(undefined)
  }, [data])

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <TranslatedInput
        name="identification.name"
        label={t('forms.fields.companyName.label')}
        variant="standard"
      />

      <div className="mt-4">
        { t('forms.fields.companyLogo.label') }
      </div>

      <ImageHandler
        image={uploadedLogo === null ? undefined : data?.logo?.en}
        imageOptions={{
          width: logoSize,
          height: logoSize,
          trimVertically: true,
        }}
        onUpload={setUploadedLogo}
        onDelete={() => { setUploadedLogo(null) }}
        displayWidth={200}
      />

      <div className="mt-8 flex justify-center gap-3 lg:justify-end">
        { backlink && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => { navigate(backlink) }}
          >
            { t('actions.cancel') }
          </Button>
        ) }
        <SubmitButton size="large">
          { editing ? t('actions.update') : t('actions.create') }
        </SubmitButton>
      </div>
    </Form>
  )
}

export default CompanyForm
