import { type CompanyBranchPayload, type CompanyBranch } from '../../../../modules/companies'
import { type InitialRatingValues } from './InitialRatingForm.type'

export const companyBranchToFormData = (companyBranch?: CompanyBranch): InitialRatingValues => {
  return {
    initialRating: companyBranch?.initialRating ? String(companyBranch.initialRating) : '',
    initialNbRatings: companyBranch?.initialNbRatings ? String(companyBranch.initialNbRatings) : '',
  }
}

export const formatFormOutput = (formData: InitialRatingValues): CompanyBranchPayload => {
  return {
    initialRating: formData?.initialRating ? parseFloat(formData.initialRating) : null,
    initialNbRatings: formData?.initialNbRatings ? parseInt(formData.initialNbRatings) : null,
  }
}
