import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

import ContainedPage from '../../../components/layout/ContainedPage'
import {
  protectAdminPage,
  useCurrentUser,
  useRoles,
  usePermission,
  Permission,
} from '../../../modules/users'
import {
  type UpdateUserAdminPayload,
  useUpdateUserAction,
  useUser,
} from '../../../modules/adminUsers'
import Spinner from '../../../components/Spinner'
import ServerError from '../../../components/errors/ServerError'
import EditUserForm from '../../../components/forms/admin/EditUserForm'
import Header from './Header'
import { scrollToTop } from '../../../utils/scroll'
import ReadOnlyUser from './ReadOnlyUser'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import PageContent from '../../../components/layout/PageContent'
import MobileHeader from '../../../components/layout/MobileHeader/MobileHeader'
import Route from '../../../app/Route.enum'

const UsersPage: React.FC = () => {
  const { t } = useTranslation()
  const { userId } = useParams()
  const { data: currentUser } = useCurrentUser()
  const { data: user, loading, error: usersErrors } = useUser(userId as string)
  const { loading: loadingRoles, error: rolesErrors } = useRoles()
  const { updateUser } = useUpdateUserAction()
  const formatError = useErrorFormatter()
  const canEditUsers = usePermission(Permission.canEditUsers)
  const canPromoteUsers = usePermission(Permission.canPromoteUsers)
  const adminProfile = user && Array.isArray(user?.roles) && user.roles.length > 0
  const selfProfile = userId === currentUser?.id
  const canEditUser = canEditUsers && (adminProfile && !selfProfile ? canPromoteUsers : true)

  const onEdit = async (data: UpdateUserAdminPayload) => {
    await toast.promise(
      updateUser(String(userId), data),
      {
        pending: t('pages.admin.user.savingUser'),
        success: t('pages.admin.user.saveSuccess'),
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
    scrollToTop()
  }

  if (loading || loadingRoles) {
    return <ContainedPage><Spinner floating /></ContainedPage>
  }
  if (usersErrors ?? rolesErrors) {
    return (
      <ContainedPage>
        { usersErrors && <ServerError error={usersErrors} /> }
        { rolesErrors && <ServerError error={rolesErrors} /> }
      </ContainedPage>
    )
  }

  if (!user) {
    return null
  }

  return (
    <ContainedPage
      mobileHeader={(
        <MobileHeader
          backlinkTo={Route.AdminUsers}
          title={t('pages.admin.users.title')}
        />
      )}
    >
      <Header />

      <PageContent wide>

        { canEditUser
          ? <EditUserForm onSubmit={onEdit} user={user} />
          : <ReadOnlyUser user={user} /> }

      </PageContent>
    </ContainedPage>
  )
}

export default protectAdminPage(UsersPage)
