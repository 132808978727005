import { mergeClassName } from '../../utils/mergeClassName'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const Cell: React.FC<ReactDiv> = ({
  children,
  className,
}) => {
  return (
    <div className={mergeClassName(
      'w-full lg:w-auto lg:px-4 mt-4 flex-1 lg:first-of-type:pl-0 lg:last-of-type:pr-0',
      className,
    )}
    >
      { children }
    </div>
  )
}

export default Cell
