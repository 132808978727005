import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import InfoIcon from '@mui/icons-material/InfoOutlined'

import {
  type CompanyBranch,
  type CompanyBranchLegal,
  Accreditation,
  BbbRating,
  useCurrentCompanyBranchName,
  useDeleteLegalDocumentsAction,
  useLegalFileUrlGenerator,
} from '../../../../modules/companies'
import { Tax } from '../../../../modules/taxes'
import {
  CardCheckboxGroup,
  DateTime,
  Form,
  Input,
  Label,
  PriceInput,
  Select,
  AddressField,
  PhoneNumberField,
} from '../../../form'
import useValidationSchema from './validationSchema'
import Section from '../../../Section'
import Paper from '../../../Paper/Paper'
import { CompanyBranchLegalToFormData, formatFormOutput } from './formDataBuilder'
import SubmitButton from '../SubmitButton'
import { type LegalFormValues } from './LegalForm.type'
import { Cell, Row } from '../../../cell'
import { ReactComponent as BbbLogo } from '../../../../assets/bbb/logo.svg'
import { ReactComponent as CamLogo } from '../../../../assets/cam/logo.svg'
import Files from '../../../Files/Files'
import { useErrorFormatter } from '../../../errors/useErrorFormatter'
import { errorNotification, successNotification, warningNotification } from '../../../ToastNotifications'
import FilesDropzone from '../../../FilesDropzone'

type LegalFormProps = {
  onSubmit: (payload: CompanyBranchLegal) => Promise<any>
  onUpload: (files: File[]) => void | Promise<void>
  companyBranch?: CompanyBranch
}

const LegalForm: React.FC<LegalFormProps> = ({
  onSubmit,
  companyBranch,
  onUpload,
}) => {
  const { t } = useTranslation()
  const [uploading, setUploading] = useState(false)
  const currentBranchName = useCurrentCompanyBranchName()
  const { deleteLegalDocuments } = useDeleteLegalDocumentsAction()
  const getLegalFileUrl = useLegalFileUrlGenerator(companyBranch)
  const formatError = useErrorFormatter()

  const initialValues = {
    legalName: currentBranchName,
  }

  const schema = useValidationSchema()
  const form = useForm<LegalFormValues>({
    resolver: yupResolver(schema),
    defaultValues: companyBranch?.legal ? CompanyBranchLegalToFormData(companyBranch.legal) : initialValues,
    mode: 'onTouched',
  })
  const defaultCountry = companyBranch?.legal?.companyAddress?.country ?? 'CA'
  const selectedCountry = useWatch({ control: form.control, name: 'companyAddress.country', defaultValue: defaultCountry })
  const accreditationsValue = useWatch({ control: form.control, name: 'accreditations' })

  const availableTaxes = useMemo(() => {
    if (selectedCountry === 'CA') {
      return [Tax.Gst, Tax.Hst, Tax.Pst]
    }
    if (selectedCountry === 'US') {
      return [Tax.Ein]
    }
  }, [selectedCountry])

  const formatBeforeSubmit = async (values: LegalFormValues) => await onSubmit(formatFormOutput(values))

  const handleDeleteLegalDocuments = async (fileIds: string[]) => {
    try {
      const updatedCompanyBranch = await deleteLegalDocuments(fileIds)

      const partialSuccess = !!updatedCompanyBranch.legalFiles?.find(({ id }) => fileIds.includes(id))
      if (partialSuccess) {
        warningNotification(t('forms.companyBranch.legal.deleteFiles.partialSuccess'))
      } else {
        successNotification(t(
          'forms.companyBranch.legal.deleteFiles.success',
          { count: fileIds.length },
        ))
      }
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  return (
    <Form form={form} onSubmit={formatBeforeSubmit}>
      <Section>
        { t('forms.companyBranch.legal.companyBranch') }
      </Section>
      <Paper className="child:pt-1 child:lg:pt-4">
        <Row>
          <Cell>
            <Label label={t('forms.fields.companyLegalName.label')} required>
              <Input
                name="legalName"
                size="small"
                required
              />
            </Label>
          </Cell>
          <Cell>
            <Label label={t('forms.fields.companyNumber.label')}>
              <Input
                name="companyNumber"
                size="small"
              />
            </Label>
          </Cell>
        </Row>

        <Row>
          <Cell>
            <DateTime
              name="dateOfIncorporation"
              label={t('forms.fields.incorporationDate.label')}
              size="small"
            />
          </Cell>
          <Cell />
        </Row>

        <div className="mb-3 mt-6 font-extrabold">
          { t('forms.companyBranch.legal.companyAddress') }
        </div>

        <AddressField name="companyAddress" required />

        <div className="mb-1 mt-6 font-extrabold">
          { t('forms.companyBranch.legal.companyPhoneNumber') }
        </div>
        <div className="mb-1 flex items-center gap-x-2 text-neutral-500">
          <InfoIcon className="!text-[22px] text-sky-500" />
          { t('forms.companyBranch.legal.companyPhoneNumberGoogle') }
        </div>

        <Row>
          <Cell>
            <PhoneNumberField
              name="companyPhoneNumber"
              size="small"
            />
          </Cell>
          <Cell />
        </Row>

        <div className="mb-3 mt-6 font-extrabold">
          { t('forms.companyBranch.legal.legalOwner') }
        </div>

        <Row>
          <Cell>
            <Label label={t('forms.fields.ownerFirstName.label')} required>
              <Input
                name="owner.firstName"
                size="small"
                required
              />
            </Label>
          </Cell>
          <Cell>
            <Label label={t('forms.fields.ownerLastName.label')} required>
              <Input
                name="owner.lastName"
                size="small"
                required
              />
            </Label>
          </Cell>
        </Row>

        <Row>
          <Cell>
            <Label label={t('forms.fields.ownerPhoneNumber.label')} required>
              <PhoneNumberField
                name="owner.phoneNumber"
                size="small"
              />
            </Label>
          </Cell>
          <Cell />
        </Row>

        <div className="mb-3 mt-6 font-extrabold">
          { t('forms.companyBranch.legal.taxNumbers') }
        </div>

        <Row>
          { availableTaxes?.map((taxCode) => (
            <Cell key={taxCode}>
              <Label label={t(`taxes.${taxCode}`)}>
                <Input
                  name={`taxes.${taxCode}`}
                  size="small"
                />
              </Label>
            </Cell>
          )) }
        </Row>
      </Paper>

      <Section>
        { t('forms.companyBranch.legal.insurance') }
      </Section>
      <Paper>
        <Row>
          <Cell>
            <Label
              label={(
                <>
                  <strong>({ t('forms.companyBranch.legal.mandatory') })</strong>
                  { ' ' }
                  { t('forms.fields.commercialLiabilityCoverageLimit.label') }
                </>
              )}
              required
            >
              <PriceInput
                name="insurances.commercialLiabilityCoverageLimit"
                size="small"
                fullWidth={false}
                allowDecimal={false}
                maxLength={12}
                className="lg:max-w-[200px]"
                required
              />
            </Label>
          </Cell>

          <Cell className="flex items-end">
            <Label
              label={(
                <>
                  <strong>({ t('forms.companyBranch.legal.mandatory') })</strong>
                  { ' ' }
                  { t('forms.fields.cargoCoverageLimit.label') }
                </>
              )}
              required
            >
              <PriceInput
                name="insurances.cargoCoverageLimit"
                size="small"
                fullWidth={false}
                allowDecimal={false}
                maxLength={12}
                className="lg:max-w-[200px]"
                required
              />
            </Label>
          </Cell>
        </Row>
      </Paper>

      <Section>
        { t('forms.companyBranch.legal.accreditations') }
      </Section>
      <Paper>
        <CardCheckboxGroup
          name="accreditations"
          options={{
            [Accreditation.Bbb]: (
              <div className="flex min-h-[80px] items-center">
                <BbbLogo className="w-[50px] shrink-0" />
                <div className="flex flex-col px-2">
                  <div>
                    <strong>
                      { t('accreditations.bbb') }
                    </strong>
                  </div>
                  { accreditationsValue?.includes(Accreditation.Bbb) && (
                    <div className="pt-2">
                      <Select
                        name="bbbRating"
                        options={Object.entries(BbbRating).map(([label, value]) => ({
                          label,
                          value,
                        }))}
                        textFieldProps={{
                          label: t('forms.companyBranch.legal.bbbRatingLabel'),
                          size: 'small',
                          InputProps: { className: 'bg-white' },
                        }}
                        allowEmpty
                      />
                    </div>
                  ) }
                </div>
              </div>
            ),
            [Accreditation.Cam]: (
              <div className="flex min-h-[80px] items-center">
                <CamLogo className="w-[85px] shrink-0" />
                <div className="px-2 text-center">
                  <strong>
                    { t('accreditations.cam') }
                  </strong>
                </div>
              </div>
            ),
          }}
        />
      </Paper>

      <Section>
        { t('forms.companyBranch.legal.documents') }
      </Section>
      <Paper>
        <div className="mb-4">
          { t('forms.companyBranch.legal.pleaseProvideDocuments') }
          <br />

          { t('forms.companyBranch.legal.acceptedFormatsAre') }
          { ' ' }
          <strong>
            { t('forms.companyBranch.legal.acceptedFormats') }
          </strong>.
        </div>
        <FilesDropzone
          onUpload={onUpload}
          onUploading={setUploading}
          maxFiles={10}
        >
          <Files
            files={companyBranch?.legalFiles}
            getUrl={getLegalFileUrl}
            onDelete={handleDeleteLegalDocuments}
          />
        </FilesDropzone>
      </Paper>

      <SubmitButton
        companyBranch={companyBranch}
        disabled={uploading}
      />
    </Form>
  )
}

export default LegalForm
