import { Currency } from '../../common/amount'

/**
 * returns the currency used in the given country
 */
export const getCurrencyForCountry = (country?: string): Currency => {
  switch (country) {
    case 'US':
      return Currency.USD
    case 'CA':
    default:
      return Currency.CAD
  }
}
