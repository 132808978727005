import { mergeClassName } from '../../utils/mergeClassName'

type CardsProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDListElement>, HTMLDListElement>

const Cards: React.FC<CardsProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <dl
      className={mergeClassName(
        'mx-auto grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 my-5',
        className,
      )}
      {...props}
    >
      { children }
    </dl>
  )
}

export default Cards
