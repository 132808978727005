import { mergeClassName } from '../../../utils/mergeClassName'

type CalendarSidebarTitleProps = {
  children?: React.ReactNode
  className?: string
  top?: boolean
}

const CalendarSidebarTitle: React.FC<CalendarSidebarTitleProps> = ({
  className,
  children,
  top = false,
}) => {
  return (
    <div className={mergeClassName(
      'my-4 border-t px-4 py-2 text-center font-body2 shadow-md sticky top-0 bg-white',
      top && 'mt-0 mb-4',
      className,
    )}
    >
      { children }
    </div>
  )
}

export default CalendarSidebarTitle
