import { useTranslation } from 'react-i18next'

import ContainedPage from '../../../components/layout/ContainedPage'
import PageTitle from '../../../components/layout/PageTitle'
import PageContent from '../../../components/layout/PageContent'
import { useCreateCompanyAction, useCurrentCompanyBranch } from '../../../modules/companies'
import CompanyForm from '../../../components/forms/companies/CompanyForm'

const CreateCompany: React.FC = () => {
  const { t } = useTranslation()
  const { createCompany, data: companyCreated } = useCreateCompanyAction()
  useCurrentCompanyBranch({ reload: !!companyCreated })

  return (
    <ContainedPage>
      <PageTitle>
        { t('pages.partnerDashboard.createCompany.title') }
      </PageTitle>

      <PageContent className="py-8">
        <h2 className="font-body2 text-lg font-bold">{ t('pages.partnerDashboard.createCompany.subtitle') }</h2>
        <CompanyForm onSubmit={createCompany} />
      </PageContent>
    </ContainedPage>
  )
}

export default CreateCompany
