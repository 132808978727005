import { type FullMove } from '../../../../../modules/adminMoves'
import ActionsDropdown from '../ActionsDropdown'
import SummaryBox from './SummaryBox'
import AccountingBox from './AccountingBox'
import PromoCodeBox from './PromoCodeBox'
import SourceBox from './SourceBox'

type SidebarProps = {
  move: FullMove
  onEstimateClick?: () => void
  onBalanceClick?: () => void
}

const Sidebar: React.FC<SidebarProps> = ({
  move,
  onEstimateClick,
  onBalanceClick,
}) => {
  return (
    <div className="-mt-4 flex shrink-0 flex-col gap-y-2 2xl:mt-0 2xl:w-[400px] 2xl:gap-y-0">
      <div className="hidden h-[65px] 2xl:block">
        <ActionsDropdown move={move} />
      </div>
      <SummaryBox
        move={move}
        onEstimateClick={onEstimateClick}
        className="hidden 2xl:block"
      />
      <AccountingBox
        move={move}
        onBalanceClick={onBalanceClick}
      />
      <PromoCodeBox move={move} />
      <SourceBox move={move} />
    </div>
  )
}

export default Sidebar
