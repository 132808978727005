import { useTranslation } from 'react-i18next'

import Route from '../../../app/Route.enum'
import { Permission, usePermission } from '../../../modules/users'
import { useCurrentCompanyBranch } from '../../../modules/companies'
import { useUpcomingJobs } from '../../../modules/jobs'
import Link, { LinkVariant } from '../../../components/Link'
import JobItem from '../../../components/job/JobItem'

const UpcomingJobs: React.FC = () => {
  const { t } = useTranslation()
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()
  const canManageOwnJobs = usePermission(Permission.canManageOwnJobs)
  const { data: upcomingJobs } = useUpcomingJobs({ reload: true })

  const widgetEnabled = canManageOwnJobs && currentCompanyBranch?.approved

  if (!widgetEnabled || !upcomingJobs.length) {
    return null
  }

  return (
    <div className="mb-10">
      <div className="flex flex-col justify-between lg:flex-row lg:pr-4">
        <h2 className="mb-4 flex items-center gap-2 font-body2 text-lg font-semibold">
          { t('pages.partnerDashboard.upcomingJobs.title') }
        </h2>

        <Link
          to={Route.JobsLandingPage}
          variant={LinkVariant.Primary}
        >
          { t('actions.showAll') }
        </Link>
      </div>

      { upcomingJobs.map((upcomingJob) => (
        <Link
          key={upcomingJob.id}
          to={Route.Job}
          params={{
            branchId: currentCompanyBranch.id,
            jobId: upcomingJob.id,
          }}
        >
          <JobItem
            key={upcomingJob.id}
            job={upcomingJob}
          />
        </Link>
      )) }
    </div>
  )
}

export default UpcomingJobs
