import { mergeClassName } from '../../utils/mergeClassName'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const Row: React.FC<ReactDiv> = ({
  children,
  className,
}) => {
  return (
    <div className={mergeClassName(
      'flex flex-col lg:flex-row',
      className,
    )}
    >
      { children }
    </div>
  )
}

export default Row
