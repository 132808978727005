/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useTranslation } from 'react-i18next'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

import Spinner from '../../../components/Spinner'
import ServerError from '../../../components/errors/ServerError'
import ContainedPage from '../../../components/layout/ContainedPage'
import PageContent from '../../../components/layout/PageContent'
import PageTitle from '../../../components/layout/PageTitle'
import { useNavigate, useParams } from '../../../modules/navigation'
import {
  useProspect,
  useRearrangeProspectQuotesAction,
  useRefreshProspectQuotesAction,
} from '../../../modules/adminProspects/core/prospects.hooks'
import { protectAdminPage } from '../../../modules/users'
import { validateUuid } from '../../../utils/uuid'
import { Error404Page } from '../../errors'
import Link from '../../../components/Link'
import Route from '../../../app/Route.enum'
import { capitalizeFirstLetter } from '../../../utils/string'
import {
  Quotes,
  QuotesSidebar,
  QuotesTopBar,
  QuotesSpinner,
} from '../../../components/quotesLegacy'
import { errorNotification } from '../../../components/ToastNotifications'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'

const ProspectPage: React.FC = () => {
  const { t } = useTranslation()
  const { navigate } = useNavigate()
  const { prospectId } = useParams()
  const validId = validateUuid(prospectId)
  const { data: prospect, loading, error } = useProspect(prospectId ?? '', { skip: !validId })
  const { refreshProspectQuotes, loading: refreshingQuotes } = useRefreshProspectQuotesAction()
  const { rearrangeProspectQuotes, loading: updatingQuotes } = useRearrangeProspectQuotesAction()
  const formatError = useErrorFormatter()

  const refreshQuotes = async () => {
    try {
      await refreshProspectQuotes(prospectId!)
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  const editRequest = () => {
    navigate(Route.EditProspect, { prospectId: prospectId! })
  }

  const onSortUpdate = async (quotesIds: string[]) => {
    try {
      return await rearrangeProspectQuotes(prospectId!, quotesIds)
    } catch (error) {
      errorNotification(
        <>
          { t('pages.admin.prospect.errors.sorting') }
          <br />
          { t('pages.admin.prospect.errors.tryRefresh') }
        </>,
      )
      throw error
    }
  }

  const onDelete = async (selectedIds: string[]) => {
    try {
      const remainingQuotesIds = prospect!.quotes
        .filter(({ id }) => !selectedIds.includes(id))
        .map(({ id }) => id)
      return await rearrangeProspectQuotes(prospectId!, remainingQuotesIds)
    } catch (error) {
      errorNotification(
        <>
          { t('pages.admin.prospect.errors.deleting') }
          <br />
          { t('pages.admin.prospect.errors.tryRefresh') }
        </>,
      )
      throw error
    }
  }

  if (!validId) {
    return <Error404Page />
  }

  if (error) {
    return (
      <ContainedPage>
        <ServerError error={error} />
      </ContainedPage>
    )
  }

  if (loading || !prospect) {
    return <ContainedPage><Spinner /></ContainedPage>
  }

  return (
    <ContainedPage>
      <PageTitle wide className="lg:mb-0">
        <Link to={Route.Prospects} className="hidden lg:block">
          <div className="ml-[-5px] text-base/loose underline">
            <ChevronLeftIcon />
            { ' ' }
            { t('pages.admin.prospects.title') }
          </div>
        </Link>
        { capitalizeFirstLetter(prospect.name) }
      </PageTitle>

      <QuotesTopBar
        quotesRequest={prospect.request}
        onGetQuotes={refreshQuotes}
        onEdit={editRequest}
        ctaLabel={t('actions.refreshQuotes')}
        wide
      />

      <PageContent wide className="px-3 py-8 pr-4">
        { refreshingQuotes && <QuotesSpinner /> }

        { !refreshingQuotes && prospect.quotes.length > 0 && (
          <div className="flex w-full flex-col gap-6 lg:flex-row">
            <div className="grow">

              <Quotes
                quotes={prospect.quotes}
                onOrderChange={onSortUpdate}
                onDelete={onDelete}
                loading={updatingQuotes}
              />

            </div>
            <div>
              <QuotesSidebar request={prospect.request} />
            </div>
          </div>
        ) }

        { !refreshingQuotes && prospect.quotes.length === 0 && (
          <div className="py-12 text-center italic">
            { t('pages.admin.prospect.noQuotes') }
          </div>
        ) }
      </PageContent>
    </ContainedPage>
  )
}

export default protectAdminPage(ProspectPage)
