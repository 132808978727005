import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import { type CustomFiltersProps } from '../../../components/dataGrid'
import { useTranslation } from 'react-i18next'

export const ADMIN_ONLY_FILTER = 'adminOnly'

/**
 * custom filters to render on top of the DataGrid component
 */
const CustomFilters: React.FC<CustomFiltersProps> = ({
  values,
  setValues,
  disabled,
}) => {
  const { t } = useTranslation()
  const adminOnly = values[ADMIN_ONLY_FILTER] ?? false

  const onClick = () => {
    setValues({
      ...values,
      [ADMIN_ONLY_FILTER]: !adminOnly,
    })
  }

  return (
    <div className="hidden md:block">
      <FormControlLabel
        control={(
          <Switch
            checked={adminOnly}
            onClick={onClick}
            disabled={disabled}
          />
        )}
        label={t('pages.admin.users.table.filters.adminOnly')}
      />
    </div>
  )
}

export default CustomFilters
