import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import ArrowIcon from '@mui/icons-material/NavigateNext'

import Route from '../../../app/Route.enum'
import { useNavigate } from '../../navigation'
import { successNotification } from '../../../components/ToastNotifications'
import { useCurrentUserAccountType } from '../../users'
import { useIncomingRequest } from './jobRequestsNotifications.hooks'
import { type LightJobRequest } from './jobRequests.models'

const IncomingJobRequestsNotification: React.FC = () => {
  const { t } = useTranslation()
  const { navigate } = useNavigate()
  const { isAdmin } = useCurrentUserAccountType()

  useIncomingRequest((jobRequest, branchId) => { handleIncomingRequest(jobRequest, branchId) })

  const handleIncomingRequest = (jobRequest: LightJobRequest, branchId: string) => {
    if (isAdmin) {
      return
    }
    successNotification(
      <div className="p-2">
        { t('jobRequest.notification.title') }
        <div className="font-bold">
          { t('jobRequest.notification.youMustAccept') }
        </div>

        <div className="mt-4">
          <Button
            variant="contained"
            color="secondary"
            size="small"
            fullWidth
            startIcon={<ArrowIcon />}
            className="!normal-case"
            onClick={() => {
              navigate(Route.JobRequest, {
                branchId,
                jobRequestId: String(jobRequest.id),
              })
            }}
          >
            { t('jobRequest.notification.cta') }
          </Button>
        </div>
      </div>,
    )

    const audio = new Audio('/notification.mp3')
    audio.volume = 0.4
    audio.play().catch(console.error)
  }

  return null
}

export default IncomingJobRequestsNotification
