import { type DateHeaderProps } from 'react-big-calendar'
import { format } from 'date-fns/format'

import { mergeClassName } from '../../../utils/mergeClassName'
import { useCalendar } from '../CalendarContext'

const DateCell: React.FC<DateHeaderProps> = ({ label, drilldownView, onDrillDown, date }) => {
  const { eventsByDay } = useCalendar()
  const onClick = drilldownView ? onDrillDown : undefined

  const day = format(date, 'yyyy-MM-dd')
  const nbEvents = eventsByDay?.[day] ?? 0

  return (
    <div className="flex min-h-[60px] flex-col items-center justify-between lg:min-h-0">
      <span
        className={mergeClassName(
          'calendar-date text-xs md:text-sm',
          onClick && 'cursor-pointer',
        )}
        onClick={onClick}
      >
        { label }
      </span>

      { nbEvents > 0 && (
        <div className="flex justify-center lg:hidden">
          <div
            className="flex size-[30px] cursor-pointer items-center justify-center rounded-full bg-primary text-white"
            onClick={onClick}
          >
            { nbEvents }
          </div>
        </div>
      ) }

    </div>
  )
}

export default DateCell
