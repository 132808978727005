export const boxVolume = 4

export const boxesQuantities = [
  10,
  20,
  40,
  60,
  80,
  120,
  160,
  200,
]

export const furnitureRatio = [
  66,
  100,
  133,
]

export const maxAppliances = 10

export const weightVolumeRatio = 7

export const menByVolume = [
  [750, 2],
  [1200, 3],
  [1900, 4],
  [Infinity, 5],
]

export const hourlyMovingVolumeByMan = 50

export const minLabour = 1
