import { formatAddressMultiLines } from '../address/formatAddress'
import { type Place } from './place.type'

/**
 * format place (google maps or address)
 */
export const formatPlace = (place?: Place, compact = false) => {
  if (!place) {
    return null
  }
  if ('street' in place && place.street) {
    return formatAddressMultiLines(place, compact)
  }

  if ('placeId' in place && place.placeId) {
    return (
      <>
        <div>{ place.placeTextLine1 }</div>
        <div>{ place.placeTextLine2 }</div>
      </>
    )
  }

  return null
}
