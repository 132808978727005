import { useState } from 'react'

export const useShowAvailabilities = () => {
  const key = 'companyBranch:availability:showAvailabilities'

  const initialState = localStorage
    ? localStorage.getItem(key) !== '0'
    : true
  const [enabled, setEnabled] = useState(initialState)

  const set = (newState: boolean) => {
    localStorage?.setItem(key, newState ? '1' : '0')
    setEnabled(newState)
  }

  return [enabled, set] as [typeof enabled, typeof set]
}
