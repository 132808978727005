import { type KeyValueMap } from '.'

/**
 * convert "Record"  into "KeyValueMap"
 * { key: 'value' }  =>  { key: 'key', value: 'value' }[]
 */
export const recordToKeyValueMap = <K extends string, V, T = V>(
  record?: Partial<Record<K, T>>,
  callback?: (value: T) => V | undefined,
  filterEmptyValues = true,
): KeyValueMap<K, V> => {
  if (!record || Object.keys(record).length === 0) {
    return []
  }
  return Object
    .entries(record)
    .map(([key, value]: any[]) => ({
      key,
      value: callback ? callback(value) : value,
    }))
    .filter(({ value }) => {
      if (!filterEmptyValues) {
        return true
      }
      return value !== undefined && value !== null && value !== ''
    })
}

/**
 * convert "KeyValueMap" into "Record"
 * { key: 'key', value: 'value' }[]  =>  { key: 'value' }
 */
export const keyValueMapToRecord = <K, V, T = V>(
  keyValueMap?: KeyValueMap<K, V>,
  callback?: (value: V) => T | undefined,
) => {
  if (!keyValueMap) {
    return
  }
  return Object.fromEntries(keyValueMap.map(({ key, value }) => ([
    key,
    callback ? callback(value) : value,
  ])))
}
