import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'

import { useLanguage } from '../../../../modules/i18n'
import { type FullMove, type MovePayload } from '../../../../modules/adminMoves'
import {
  Form,
  SubmitButton,
} from '../../../form'
import Paper from '../../../Paper'
import useValidationSchema from './validationSchema'
import { moveToCreateFormData, formatCreateFormOutput } from './formDataBuilder'
import { type CreateMoveFormValues } from './MoveForm.type'
import { QuotesRequestFormContent, defaultQuoteRequestValues } from '../../quotes/QuotesRequestForm'
import MoveFormCustomerFormContent from './MoveFormCustomerFormContent'

type CreateMoveFormProps = {
  onSubmit: (payload: MovePayload) => Promise<any>
  onCancel?: () => void
  data?: FullMove
}

const CreateMoveForm: React.FC<CreateMoveFormProps> = ({
  onSubmit,
  onCancel,
  data,
}) => {
  const { t } = useTranslation()
  const language = useLanguage()

  const schema = useValidationSchema<CreateMoveFormValues>({ includeUser: true })
  const form = useForm<CreateMoveFormValues>({
    resolver: yupResolver(schema),
    defaultValues: data
      ? moveToCreateFormData(data)
      : {
          ...defaultQuoteRequestValues,
          user: {
            language,
          },
        },
  })
  const editing = !!data
  const onFormSubmit = async (data: CreateMoveFormValues) => await onSubmit(formatCreateFormOutput(data))

  return (
    <Form form={form} onSubmit={onFormSubmit}>
      <QuotesRequestFormContent
        customerIdentificationSection={(
          <>
            <h2 className="mb-2 font-body2 text-lg font-bold lg:my-4">
              { t('forms.move.customer.title') }
            </h2>
            <Paper>
              <MoveFormCustomerFormContent compact />
            </Paper>
          </>
        )}
        requireDetails
      />

      <div className="mt-8 flex justify-end gap-3">
        { onCancel && (
          <Button
            variant="contained"
            color="secondary"
            onClick={onCancel}
          >
            { t('actions.cancel') }
          </Button>
        ) }
        <SubmitButton size="large">
          { editing ? t('actions.update') : t('actions.create') }
        </SubmitButton>
      </div>
    </Form>
  )
}

export default CreateMoveForm
