import { useCurrentCompanyBranch } from '../../../modules/companies'
import { type LightJob } from '../../../modules/jobs'
import { mergeClassName } from '../../../utils/mergeClassName'
import Customer from './Customer'
import Estimate from './Estimate'
import Status from './Status'

type JobItemProps = {
  job: LightJob
  highlighted?: boolean
  cta?: React.ReactNode
}

const JobItem: React.FC<JobItemProps> = ({
  job,
  highlighted = false,
  cta,
}) => {
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()

  if (!currentCompanyBranch) {
    return null
  }

  return (
    <div className={mergeClassName(
      'relative my-5 rounded-3xl border bg-white px-8 py-6 shadow-md shadow-neutral-300/30 transition-all  duration-200 hover:shadow-lg',
      highlighted && 'rounded-tl-none',
    )}
    >

      { highlighted && (
        <>
          <div className="absolute left-[-4px] top-[-4px] z-10 size-[8px] rounded-full bg-red-400" />
          <div className="absolute left-[-4px] top-[-4px] size-[8px] animate-ping rounded-full bg-transparent shadow-[0_0_0_3px_rgba(255,0,0,0.1)]" />
        </>
      ) }

      <div className="flex justify-between">

        <div className="flex grow flex-col gap-y-3 sm:flex-row">

          <div className="flex flex-col gap-y-5">
            <Status job={job} />
            <div className="mb-5 sm:mb-0 lg:hidden">
              <Estimate job={job} />
            </div>
          </div>

          <div className="flex grow flex-col gap-y-2">
            <Customer job={job} />
          </div>

          <div className="hidden lg:block">
            <Estimate job={job} cta={cta} />
          </div>
        </div>
      </div>

    </div>
  )
}

export default JobItem
