import { type ProspectPayload } from '../../../../modules/adminProspects'
import { type ProspectFormValues } from './ProspectForm.type'
import {
  quotesRequestToFormData,
  formatFormOutput as formatQuotesRequest,
} from '../../quotes/QuotesRequestFormLegacy/formDataBuilder'
import { type Prospect } from '../../../../modules/adminProspects/core/prospects.models'
import { removeTypeNameFromObject } from '../../../../utils/graphql'

export const prospectToFormData = (
  prospect: Prospect,
): ProspectFormValues => {
  const {
    moveLeg,
    originTimezone,
    timeline,
    moveDurationSeconds,
    requiredMovingService,
    ...request
  } = removeTypeNameFromObject<Prospect>(prospect).request
  return {
    name: prospect.name,
    ...quotesRequestToFormData(request),
  }
}

export const formatFormOutput = (data: ProspectFormValues): ProspectPayload => {
  return {
    name: data.name,
    ...formatQuotesRequest(data),
  }
}
