import React from 'react'

import { Error500Page } from '../../pages/errors'

type ErrorBoundaryProps = {
  children?: React.ReactNode
}

type ErrorBoundaryState = {
  hasError: boolean
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor (props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError () {
    return { hasError: true }
  }

  componentDidCatch (error: Error, errorInfo: unknown) {
    console.error(error, errorInfo)
  }

  render () {
    if (this.state.hasError) {
      return <Error500Page />
    }
    return this.props.children
  }
}

export default ErrorBoundary
