import { useTranslation } from 'react-i18next'

import { type CompanyBranch, Season } from '../../modules/companies'
import { type Currency } from '../../common/amount'
import { mergeClassName } from '../../utils/mergeClassName'
import { PriceInput } from '../form'

type PriceInputsRowProps = {
  prefixName: string
  companyBranch?: CompanyBranch
  basePriceRequired?: boolean
  endAdornment?: string
  allowDecimal?: boolean
  className?: string
  maxLength?: number
  currency?: Currency
}

const PricesInput: React.FC<PriceInputsRowProps> = ({
  companyBranch,
  prefixName,
  basePriceRequired = false,
  endAdornment,
  allowDecimal = false,
  className,
  maxLength = 3,
  currency,
}) => {
  const { t } = useTranslation()
  const hasLowSeason = companyBranch?.seasons?.low?.enabled ?? false
  const hasHighSeason = companyBranch?.seasons?.high?.enabled ?? false

  const cellClassName = 'xl:max-w-[300px]'

  return (
    <div className={mergeClassName(
      'flex flex-col gap-4 xl:flex-row xl:gap-8',
      className,
    )}
    >
      <div className={cellClassName}>
        <PriceInput
          name={`${prefixName}.basePrice`}
          label={t('forms.companyBranch.labour.basePrice')}
          allowDecimal={allowDecimal}
          maxLength={allowDecimal ? undefined : maxLength}
          endAdornment={endAdornment}
          required={basePriceRequired}
          currency={currency}
        />
      </div>
      { hasLowSeason && (
        <div className={cellClassName}>
          <PriceInput
            name={`${prefixName}.low`}
            label={t(`seasons.${Season.Low}.title`)}
            allowDecimal={allowDecimal}
            maxLength={allowDecimal ? undefined : maxLength}
            endAdornment={endAdornment}
            currency={currency}
          />
        </div>
      ) }

      { hasHighSeason && (
        <div className={cellClassName}>
          <PriceInput
            name={`${prefixName}.high`}
            label={t(`seasons.${Season.High}.title`)}
            allowDecimal={allowDecimal}
            maxLength={allowDecimal ? undefined : maxLength}
            endAdornment={endAdornment}
            currency={currency}
          />
        </div>
      ) }

    </div>
  )
}

export default PricesInput
