import React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import MuiPagination from '@mui/material/Pagination'
import Box from '@mui/material/Box'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useDataGridContext } from '../DataGridContext'
import { DEFAULT_NB_RESULTS_PER_PAGE, NB_RESULTS_PER_PAGE_OPTIONS } from '../DataGrid.const'
import { useTranslation } from 'react-i18next'

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '1rem',
  paddingTop: '1rem',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
    alignItems: 'center',
    paddingTop: '2rem',
  },
}))

const Footer: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const xLargeScreen = useMediaQuery(theme.breakpoints.up('xl'))
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'))
  const mediumScreen = useMediaQuery(theme.breakpoints.up(1200))
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const tinyScreen = useMediaQuery(theme.breakpoints.down(365))

  const { count, loading, query, setQuery } = useDataGridContext()

  const totalResults = count ?? 0
  const currentPage = query.page ?? 1
  const nbResultsPerPage = query.nbResultsPerPage ?? DEFAULT_NB_RESULTS_PER_PAGE
  const nbPages = Math.ceil(totalResults / nbResultsPerPage)
  const disabled = loading || totalResults === 0

  const onPageChange = (_: unknown, newPage: number) => {
    setQuery({
      ...query,
      page: newPage,
    })
  }

  const onNbResultsPerPageChange = (event: SelectChangeEvent<number>) => {
    setQuery({
      ...query,
      page: 1,
      nbResultsPerPage: Number(event.target.value ?? DEFAULT_NB_RESULTS_PER_PAGE),
    })
  }

  const getNbSibling = () => {
    if (xLargeScreen) {
      return 5
    }
    if (largeScreen) {
      return 3
    }
    if (mediumScreen) {
      return 2
    }
    if (smallScreen) {
      return 0
    }
    return 0
  }

  const getBoundaryCount = () => {
    if (tinyScreen) {
      return 0
    }
    return 1
  }

  const showFirstAndLast = () => {
    return !smallScreen
  }

  return (
    <Container>
      <Select<number>
        variant="standard"
        value={nbResultsPerPage}
        size="small"
        color="secondary"
        style={{ width: 130 }}
        onChange={onNbResultsPerPageChange}
        disabled={disabled}
      >
        { NB_RESULTS_PER_PAGE_OPTIONS.map(value => (
          <MenuItem key={value} value={value} color="secondary">
            { value } { t('components.dataGrid.result', { count: value }) }
          </MenuItem>
        )) }
      </Select>

      <MuiPagination
        count={nbPages}
        siblingCount={getNbSibling()}
        boundaryCount={getBoundaryCount()}
        page={currentPage}
        size="large"
        showFirstButton={showFirstAndLast()}
        showLastButton={showFirstAndLast()}
        onChange={onPageChange}
        disabled={disabled}
      />
    </Container>
  )
}

export default Footer
