import { mergeClassName } from '../../../utils/mergeClassName'

type CalendarSidebarListProps = {
  children?: React.ReactNode
  compact?: boolean
  className?: string
}

const CalendarSidebarList: React.FC<CalendarSidebarListProps> = ({
  children,
  compact = false,
  className,
}) => {
  return (
    <div className={mergeClassName(
      'my-6 flex flex-col',
      compact && 'my-0',
      className,
    )}
    >
      { children }
    </div>
  )
}

export default CalendarSidebarList
