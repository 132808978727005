import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'

import {
  Form,
  Input,
  Label,
  SubmitButton,
} from '../../../form'
import useValidationSchema from './validationSchema'
import { prospectToFormData, formatFormOutput } from './formDataBuilder'
import { type ProspectFormValues } from './ProspectForm.type'
import QuotesRequestFormContent, { initialData } from '../../quotes/QuotesRequestFormLegacy/QuotesRequestFormContent'
import { type ProspectPayload } from '../../../../modules/adminProspects'
import Paper from '../../../Paper'
import { type Prospect } from '../../../../modules/adminProspects/core/prospects.models'

type ProspectFormProps = {
  onSubmit: (payload: ProspectPayload) => Promise<any>
  onCancel?: () => void
  data?: Prospect
}

const ProspectForm: React.FC<ProspectFormProps> = ({
  onSubmit,
  onCancel,
  data,
}) => {
  const { t } = useTranslation()
  const schema = useValidationSchema()
  const form = useForm<ProspectFormValues>({
    resolver: yupResolver(schema),
    defaultValues: data ? prospectToFormData(data) : initialData,
  })
  const editing = !!data

  const onFormSubmit = async (data: ProspectFormValues) => await onSubmit(formatFormOutput(data))

  return (
    <Form form={form} onSubmit={onFormSubmit}>

      <div className="flex-1 lg:max-w-[400px]">
        <h2 className="mb-2 font-body2 text-lg font-bold lg:my-4">
          { t('forms.prospect.prospectSectionTitle') }
        </h2>
        <Paper>
          <Label label={t('forms.fields.prospectName.label')}>
            <Input
              name="name"
              size="small"
            />
          </Label>
        </Paper>
      </div>

      <QuotesRequestFormContent />

      <div className="mt-8 flex justify-end gap-3">
        { onCancel && (
          <Button
            variant="contained"
            color="secondary"
            onClick={onCancel}
          >
            { t('actions.cancel') }
          </Button>
        ) }
        <SubmitButton size="large">
          { editing ? t('actions.update') : t('actions.create') }
        </SubmitButton>
      </div>
    </Form>
  )
}

export default ProspectForm
