import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import EditIcon from '@mui/icons-material/Edit'

type SectionProps = {
  children: React.ReactNode
  onEdit?: () => void
  editing?: boolean
}

const Section: React.FC<SectionProps> = ({
  children,
  onEdit,
  editing = false,
}) => {
  const { t } = useTranslation()

  return (
    <div className="mb-5 flex flex-col lg:flex-row">
      <h2 className="font-body2 text-[1.4rem] font-normal leading-7 sm:text-[1.6rem] sm:font-light">{ children }</h2>
      { onEdit && (
        <div className="mt-4 lg:mt-0 lg:pl-4">
          <Button
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<EditIcon />}
            onClick={onEdit}
            disabled={editing}
          >
            { t('actions.edit') }
          </Button>
        </div>
      ) }
    </div>
  )
}

export default Section
