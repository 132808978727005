import { type ObjectsWithId } from './Draggable.types'

export const getIds = (data: ObjectsWithId) => data.map(({ id }) => id)

export const reorder = (list: string[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}
