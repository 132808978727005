import * as Yup from 'yup'

import { type AdditionalChargesFormValues } from './AdditionalChargesForm.type'
import { usePricesValidation } from '../../../../modules/companies'
import { useRecordValidation } from '../../../../utils/validation'

const useValidationSchema = () => {
  const recordValidation = useRecordValidation()
  const pricesValidation = usePricesValidation()
  const additionalChargesPricesValidation = pricesValidation({
    basePriceRequired: false,
    allowDecimal: false,
    allowZero: true,
  })

  return Yup.object<AdditionalChargesFormValues>({
    overnight: additionalChargesPricesValidation,
    stairs: additionalChargesPricesValidation,
    specialServices: recordValidation(pricesValidation({
      basePriceRequired: true,
      allowDecimal: false,
      allowZero: true,
    })),
    specialItems: recordValidation(pricesValidation({
      basePriceRequired: true,
      allowDecimal: false,
      allowZero: true,
    })),
  })
}

export default useValidationSchema
