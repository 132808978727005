import { mergeClassName } from '../../utils/mergeClassName'

type ModalBackdropProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const ModalBackdrop: React.FC<ModalBackdropProps> = ({
  children,
  className,
  ...props
}) => (
  <div
    className={mergeClassName(
      'fixed inset-0 g-background/80 backdrop-blur-sm transition-all duration-100 data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=open]:fade-in cursor-pointer',
      className,
    )}
    data-component="backdrop"
    {...props}
  >
    { children }
  </div>
)

export default ModalBackdrop
