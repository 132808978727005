import { type FieldArrayWithId } from 'react-hook-form'

import { ChargeCategory, chargeCategoryChild } from '../../../../modules/invoices'
import {
  type ChargeFormValue,
  type ChargesFormValue,
} from './ChargesFormContent.type'

type Field = FieldArrayWithId<ChargesFormValue, 'charges', 'id'>
type SortedField = Record<ChargeCategory, Array<[Field, number]>>

const positions: Record<string, number> = {}
let position = 0
Object.entries(chargeCategoryChild).forEach(([category, descriptions]) => {
  descriptions.forEach(description => {
    positions[`${category}.${description}`] = position
    position++
  })
})

/**
 * sort fields by category and keep original rhf index
 */
export const sortFieldsByCategory = (fields: Field[]) => {
  const indexedFields: Array<[Field, number]> = fields.map((field, index) => ([field, index]))

  indexedFields.sort((a, b) => {
    const posA = positions[a[0].categoryAndDescription]
    const posB = positions[b[0].categoryAndDescription]
    if (posA > posB) {
      return 1
    }
    if (posA < posB) {
      return -1
    }
    return 0
  })

  const grouped = Object.fromEntries(
    Object.values(ChargeCategory).map((category) => ([category, []])),
  ) as unknown as SortedField

  indexedFields.forEach(([field, index]) => {
    const category = field.categoryAndDescription.split('.')[0] as ChargeCategory
    if (!grouped[category]) {
      return
    }
    grouped[category].push([field, index])
  })
  return grouped
}

/**
 * get single row subtotal
 */
export const getRowSubtotal = (row: ChargeFormValue): number => {
  if (!row.quantity || !row.unitPrice) {
    return 0
  }
  const subtotal = parseFloat(row.quantity) * parseFloat(row.unitPrice)
  return isNaN(subtotal) ? 0 : subtotal
}

/**
 * get charges subtotal
 */
export const getSubtotal = (rows: ChargeFormValue[]): number => {
  return rows.reduce((acc, curr) => acc + getRowSubtotal(curr), 0)
}
