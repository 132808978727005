/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState } from 'react'
import Button, { type ButtonProps } from '@mui/material/Button'

import { type FullMove } from '../../../modules/adminMoves'
import { useActions, type ActionName, type Action } from './useActions'
import ConfirmationModal from '../../../components/ConfirmationModal'
import Spinner from '../../../components/Spinner'
import { mergeClassName } from '../../../utils/mergeClassName'

type MoveActionButtonProps = ButtonProps & {
  move: FullMove
  actionName: ActionName
  args?: any
  overrideAction?: Partial<Action>
}

const MoveActionButton: React.FC<MoveActionButtonProps> = ({
  move,
  actionName,
  args,
  overrideAction,
  ...buttonProps
}) => {
  const { actions, modalProps } = useActions(move)
  const action = { ...actions[actionName], ...overrideAction }
  const [loading, setLoading] = useState(false)

  if (!action || action.hidden) {
    return null
  }

  const handleClick = async () => {
    setLoading(true)
    try {
      if (action.callback) {
        await action.callback(args)
      }
    } finally {
      setLoading(false)
    }
  }

  const Icon = action.Icon
  const icon = Icon && <Icon />

  return (
    <>
      <Button
        startIcon={loading ? <Spinner small className="!text-inherit" /> : icon}
        onClick={handleClick}
        disabled={!action.enabled || loading}
        variant="contained"
        color={action.danger ? 'error' : 'secondary'}
        size="small"
        {...buttonProps}
        className={mergeClassName(
          '!normal-case',
          buttonProps.className,
        )}
      >
        { action.label }
      </Button>
      <ConfirmationModal {...modalProps} />
    </>
  )
}

export default MoveActionButton
