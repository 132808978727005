import { type PaginatedQueryResultData } from '../../graphQl'
import { type LightJobRequest } from './jobRequests.models'
import { useCurrentCompanyBranch, useNotifications } from '../../companies'
import { useEffect, useRef } from 'react'

/**
 * returns the pending requests globally fetched in the app
 * the data include the total pending count and the last 3 requests
 * data is fetched at interval
 */
export const usePendingRequestsNotifications = (companyBranchId?: string): PaginatedQueryResultData<LightJobRequest> | undefined => {
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()
  const { get } = useNotifications()
  const id = companyBranchId ?? currentCompanyBranch?.id
  if (!id) {
    return
  }
  const key = `${id}:pendingRequestsNotifications`
  return get(key)
}

/**
 * this hook will call your callback when a new request is received
 */
export const useIncomingRequest = (
  callback: (request: LightJobRequest, branchId: string) => void | Promise<void>,
) => {
  const { listening } = useNotifications()
  const jobRequests = usePendingRequestsNotifications(listening ?? undefined)

  const nbPendingRequests = useRef<number | null>(null)

  useEffect(() => {
    if (!jobRequests || !listening) {
      return
    }
    const latestRequest = jobRequests?.data[0]
    const newTotal = jobRequests.count

    const hasReceivedNewRequest = nbPendingRequests.current !== null &&
      newTotal > nbPendingRequests.current

    nbPendingRequests.current = newTotal

    if (hasReceivedNewRequest && latestRequest) {
      callback(latestRequest, listening)?.catch(console.error)
    }
  }, [callback, jobRequests, listening])
}
