import * as Yup from 'yup'

import Language from '../../app/Language.enum'

type GetStringTranslationsValidationOptions = {
  min?: number
  max?: number
}

export const getStringTranslationsValidation = ({ min = 0, max = 255 }: GetStringTranslationsValidationOptions = {}) => {
  return Yup.object({
    [Language.En]: Yup.string().required().min(min).max(max),
    [Language.Fr]: Yup.string().notRequired(),
  })
}
