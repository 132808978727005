import { type CompanyBranch } from '../../modules/companies'
import { useLocation } from '../../modules/navigation'
import { getLastPathnameElement } from '../../utils/routing/routing'
import steps from './steps.const'

export const checkForLandingPage = (pathname: string, hasCompanyId = false) => {
  return pathname.replace(/\/$/, '').split('/').length === (hasCompanyId ? 6 : 5)
}

export const checkForInvalidStep = (pathname: string, companyBranch?: CompanyBranch) => {
  if (!companyBranch) {
    return false
  }
  const nextIncompleteStep = getNextIncompleteStep(companyBranch)
  if (!nextIncompleteStep) {
    return false
  }
  const slug = getLastPathnameElement(pathname)
  const step = Object.values(steps).find(step => step.routeSlug === slug)
  if (!step) {
    return false
  }
  const stepCompleted = !!(companyBranch as any)[step.branchProperty]
  return !stepCompleted && slug !== nextIncompleteStep.routeSlug
}

export const getCompletedSteps = (companyBranch?: CompanyBranch) => {
  return Object.fromEntries(
    Object
      .entries(steps)
      .map(([step, { branchProperty }]) => ([step, !!(companyBranch as any)?.[branchProperty]])),
  )
}

export const useNextStep = () => {
  const { pathname } = useLocation()
  const currentSlug = getLastPathnameElement(pathname)
  const stepValues = Object.values(steps)
  const currentStepIndex = stepValues.findIndex(({ routeSlug }) => routeSlug === currentSlug)
  return stepValues[currentStepIndex + 1]
}

export const getNextIncompleteStep = (companyBranch?: CompanyBranch) => {
  const completed = getCompletedSteps(companyBranch)
  const firstIncomplete = (Object.entries(completed).find(([, completed]) => !completed))?.[0]
  return firstIncomplete ? steps[firstIncomplete as keyof typeof steps] : undefined
}
