import { useAuthenticationState } from '../../../modules/users'
import Header from '../Header'
import Footer from '../Footer'
import Menus from '../Menu'
import { mergeClassName } from '../../../utils/mergeClassName'

export type ContainedPageProps = {
  children?: React.ReactNode
  topContent?: React.ReactNode
  mobileHeader?: React.ReactNode
  className?: string
  contrastBackground?: boolean
}

const ContainedPage: React.FC<ContainedPageProps> = ({
  children,
  topContent,
  mobileHeader,
  contrastBackground = false,
}) => {
  const { isAuthenticated } = useAuthenticationState()

  return (
    <div className="flex h-screen w-screen flex-col overflow-hidden">
      <Header mobileSubHeader={mobileHeader} />
      <div className="flex min-h-0 flex-1">
        { isAuthenticated && <Menus /> }
        <div className={mergeClassName(
          'flex w-full grow flex-col',
          contrastBackground && 'bg-[#f9fafc]',
        )}
        >
          <div id="scrollable-body" className="flex grow flex-col justify-between overflow-auto">
            <div className="grow pb-6">
              { topContent }
              { children }
            </div>
            <div className="lg:hidden">
              <Footer />
            </div>
          </div>
          <div className="hidden lg:flex">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContainedPage
