import { v4 as uuid } from 'uuid'

export const DEFAULT_MAX_PICKUP_RADIUS = 50
export const DEFAULT_MAX_DISTANCE = 500
export const MIN_PICKUP_RADIUS = 5
export const MAX_PICKUP_RADIUS = 1000
export const MIN_DISTANCE = 50
export const MAX_RULES = 5

export const DEFAULT_RULES = [
  {
    id: uuid(),
    to: '100',
    travel: {
      chargeLabour: true,
      minLabourTime: '1',
      pricePerKm: { basePrice: '' },
    },
    transport: {
      chargeLabour: true,
      pricePerKm: { basePrice: '' },
    },
    chargeTravelKmFee: false,
    chargeTransportKmFee: false,
    chargeFixedTruckFee: false,
  },
  {
    id: uuid(),
    to: String(DEFAULT_MAX_DISTANCE),
    travel: {
      chargeLabour: true,
      pricePerKm: { basePrice: '0.75' },
    },
    transport: {
      chargeLabour: true,
      pricePerKm: { basePrice: '0.75' },
    },
    chargeTravelKmFee: true,
    chargeTransportKmFee: true,
    chargeFixedTruckFee: false,
  },
]
