import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

const SubmitButtonsContainer = styled(Box)(({ theme }) => ({
  padding: '1.5rem 0',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '1rem',
}))

export default SubmitButtonsContainer
