const empty = (value: any) => {
  if (!value) {
    return true
  }
  return parseFloat(value) === 0
}

export const hasSameQuantities = (
  values: Record<string, any> = {},
  defaultValues: Record<string, any> = {},
) => {
  const nonEmptyValues = Object.fromEntries(Object.entries(values).filter(([, value]) => !empty(value)))
  const nonEmptyDefaultValues = Object.fromEntries(Object.entries(defaultValues).filter(([, value]) => !empty(value)))
  return JSON.stringify(nonEmptyValues) === JSON.stringify(nonEmptyDefaultValues)
}
