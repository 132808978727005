import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Alert from '@mui/material/Alert'
import InputAdornment from '@mui/material/InputAdornment'

import { AdditionalService, type CompanyBranch, type CompanyBranchLabour } from '../../../../modules/companies'
import { Form, Input, Label } from '../../../form'
import useValidationSchema from './validationSchema'
import Section from '../../../Section'
import Paper from '../../../Paper/Paper'
import { formatFormOutput, companyBranchLabourToFormData } from './formDataBuilder'
import SubmitButton from '../SubmitButton'
import { type LabourFormValues } from './LabourForm.type'
import PricingByMen from './PricingByMen'

export const DEFAULT_MOVING_PRICES = ['140', '190']
export const DEFAULT_MOVING_PRICE_EXTRA = '50'
export const DEFAULT_PACKING_PRICES = ['130', '180']
export const DEFAULT_PACKING_PRICE_EXTRA = '50'
export const DEFAULT_MIN_LABOUR_TIME = '3'

type LabourFormProps = {
  onSubmit: (payload: CompanyBranchLabour) => Promise<any>
  companyBranch?: CompanyBranch
}

const LabourForm: React.FC<LabourFormProps> = ({
  onSubmit,
  companyBranch,
}) => {
  const hasPackingService = companyBranch?.services?.additionalServices?.includes(AdditionalService.Packing) ?? false
  const initialValues = {
    movingLabour: [
      { basePrice: DEFAULT_MOVING_PRICES[0] },
      { basePrice: DEFAULT_MOVING_PRICES[1] },
    ],
    movingLabourExtraMen: { basePrice: DEFAULT_MOVING_PRICE_EXTRA },
    packingLabour: hasPackingService
      ? [
          { basePrice: DEFAULT_PACKING_PRICES[0] },
          { basePrice: DEFAULT_PACKING_PRICES[1] },
        ]
      : undefined,
    packingLabourExtraMen: hasPackingService ? { basePrice: DEFAULT_PACKING_PRICE_EXTRA } : undefined,
    minLabourTime: DEFAULT_MIN_LABOUR_TIME,
  }

  const defaultValues = companyBranch?.labour
    ? companyBranchLabourToFormData(companyBranch?.labour)
    : initialValues

  if (hasPackingService && (defaultValues.packingLabour?.length ?? 0) < 1) {
    defaultValues.packingLabour = [{ basePrice: '' }]
  }

  const { t } = useTranslation()
  const schema = useValidationSchema({ hasPackingService })
  const form = useForm<LabourFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'onTouched',
  })

  const formatBeforeSubmit = async (values: LabourFormValues) => await onSubmit(formatFormOutput(values))

  return (
    <Form form={form} onSubmit={formatBeforeSubmit}>
      <Section>
        { t('forms.companyBranch.labour.title') }
      </Section>

      <Paper>
        <Label label={t('forms.companyBranch.labour.minLabourTime')} required>
          <div className="xl:w-[calc(33%-1rem)]">
            <Input
              name="minLabourTime"
              size="small"
              inputProps={{
                maxLength: 3,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    { t('units.hour_s') }
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Label>
      </Paper>

      <Section>
        { t('forms.companyBranch.labour.movingLabour') }
      </Section>

      <Alert severity="info" className="mb-4">
        <div className="text-base">
          { t('forms.companyBranch.labour.tip') }
        </div>
      </Alert>

      <PricingByMen
        name="movingLabour"
        companyBranch={companyBranch}
      />

      { hasPackingService && (
        <>
          <Section>
            { t('forms.companyBranch.labour.packingLabour') }
          </Section>

          <PricingByMen
            name="packingLabour"
            companyBranch={companyBranch}
          />
        </>
      ) }

      <SubmitButton companyBranch={companyBranch} />
    </Form>
  )
}

export default LabourForm
