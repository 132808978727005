import { v4 as uuid } from 'uuid'

import {
  type CompanyBranchPricing,
  type CompanyBranchTripAndTravel,
  pricingAsString,
  pricingAsNumber,
  isPriceRowDefined,
  type CompanyBranchTripAndTravelRuleTrucksFee,
} from '../../../../modules/companies'
import { type TripAndTravelFormValues, type TripAndTravelFormValuesTrucksFee } from './TripAndTravel.type'
import { keyValueMapToRecord, recordToKeyValueMap } from '../../../../common/keyValueMap'

const pricingIntAsString = (pricing?: CompanyBranchPricing<number>) => {
  return pricingAsString(pricing, { asInteger: true })
}

const hasFixedTruckFee = (trucksFee?: CompanyBranchTripAndTravelRuleTrucksFee<any>) => {
  if (!trucksFee) {
    return false
  }
  if (Object.keys(trucksFee).length === 0) {
    return false
  }
  return true
}

export const CompanyBranchTripAndTravelToFormData = (companyBranchTripAndTravel: CompanyBranchTripAndTravel): TripAndTravelFormValues => {
  const formData = {
    trucksOriginAddress: { ...companyBranchTripAndTravel.trucksOriginAddress },
    maxDistance: String(companyBranchTripAndTravel.maxDistance),
    maxPickupRadius: String(companyBranchTripAndTravel.maxPickupRadius),
    rules: companyBranchTripAndTravel.rules.map(rule => ({
      id: uuid(),
      to: String(rule.to),
      travel: {
        chargeLabour: rule.travel.chargeLabour,
        minLabourTime: rule.travel.minLabourTime ? String(rule.travel.minLabourTime) : undefined,
        pricePerKm: pricingAsString(rule.travel.pricePerKm),
      },
      transport: {
        chargeLabour: rule.transport.chargeLabour,
        pricePerKm: pricingAsString(rule.transport.pricePerKm),
      },
      trucksFee: keyValueMapToRecord(rule.trucksFee, pricingIntAsString),
      chargeTravelKmFee: isPriceRowDefined(rule.travel.pricePerKm),
      chargeTransportKmFee: isPriceRowDefined(rule.transport.pricePerKm),
      chargeFixedTruckFee: hasFixedTruckFee(rule.trucksFee),
    })),
  }
  if ((formData.trucksOriginAddress as any).__typename) {
    delete (formData.trucksOriginAddress as any).__typename
  }

  return formData
}

const filterEmptyTruckValues = (value?: TripAndTravelFormValuesTrucksFee) => {
  if (!value) {
    return value
  }
  return Object.fromEntries(
    Object.entries(value)
      .filter(([_, value]) => Object.keys(value ?? {}).length > 0),
  ) as TripAndTravelFormValuesTrucksFee
}

export const formatFormOutput = (formData: TripAndTravelFormValues): CompanyBranchTripAndTravel => {
  return {
    trucksOriginAddress: {
      ...formData.trucksOriginAddress,
      apartment: formData.trucksOriginAddress.apartment !== ''
        ? formData.trucksOriginAddress.apartment
        : undefined,
    },
    maxDistance: parseInt(formData.maxDistance),
    maxPickupRadius: parseInt(formData.maxPickupRadius),
    rules: formData.rules.map((rule, index) => {
      const chargeTravelKmFee = !!rule.chargeTravelKmFee
      const chargeTransportKmFee = !!rule.chargeTransportKmFee
      const chargeFixedTruckFee = !!rule.chargeFixedTruckFee

      return {
        to: index === (formData.rules.length - 1) ? parseInt(formData.maxDistance) : parseInt(rule.to),
        travel: {
          chargeLabour: rule.travel.chargeLabour,
          minLabourTime: rule.travel.minLabourTime ? parseFloat(rule.travel.minLabourTime) : undefined,
          pricePerKm: chargeTravelKmFee ? pricingAsNumber(rule.travel.pricePerKm) : undefined,
        },
        transport: {
          chargeLabour: rule.transport.chargeLabour,
          pricePerKm: chargeTransportKmFee ? pricingAsNumber(rule.transport.pricePerKm) : undefined,
        },
        trucksFee: chargeFixedTruckFee
          ? recordToKeyValueMap(
            filterEmptyTruckValues(rule.trucksFee),
            pricingAsNumber,
          )
          : [],
      }
    }),
  }
}
