import { ChargeDescription } from '../../../../../modules/invoices'
import { RowType } from './ChargeRow.enum'

export const rowTypeByDescription: Partial<Record<ChargeDescription, RowType>> = {
  [ChargeDescription.movingLabour]: RowType.Labour,
  [ChargeDescription.packingLabour]: RowType.Labour,
  [ChargeDescription.travelLabour]: RowType.Labour,
  [ChargeDescription.transportLabour]: RowType.Labour,

  [ChargeDescription.travelTruckFeePerKilometer]: RowType.Truck,
  [ChargeDescription.transportTruckFeePerKilometer]: RowType.Truck,
  [ChargeDescription.fixedTruckFee]: RowType.TruckFixedFee,

  [ChargeDescription.additionalChargesOvernight]: RowType.Overnight,
  [ChargeDescription.additionalChargesStairs]: RowType.Stairs,
  [ChargeDescription.additionalChargesSpecialServices]: RowType.SpecialService,
  [ChargeDescription.additionalChargesSpecialItems]: RowType.SpecialItem,
  [ChargeDescription.protectiveMaterialProduct]: RowType.ProtectiveMaterial,
}
