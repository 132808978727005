import { useTranslation } from 'react-i18next'
import AccountBalanceIcon from '@mui/icons-material/AccountBalanceOutlined'

import { type FullMove } from '../../../../../modules/adminMoves'
import { useMoveState } from '../../../../../modules/moves'
import { formatPrice } from '../../../../../utils/money'
import { mergeClassName } from '../../../../../utils/mergeClassName'
import Paper from '../../../../../components/Paper'
import PaymentMethod from '../../../../../components/payment/PaymentMethod'
import AccountingMetric, {
  MetricVariant,
  Variant,
} from '../accounting/AccountingMetric'

type AccountingBoxProps = {
  move: FullMove
  onBalanceClick?: () => void
}

const AccountingBox: React.FC<AccountingBoxProps> = ({
  move,
  onBalanceClick,
}) => {
  const { t } = useTranslation()
  const { balance } = useMoveState(move)

  const showBalance = balance.price !== 0
  const hasPaymentMethod = !!move.paymentProfile?.paymentMethod

  if (!showBalance && !hasPaymentMethod) {
    return null
  }

  return (
    <Paper
      title={t('pages.admin.move.sidebar.accounting.title')}
      titleIcon={<AccountBalanceIcon />}
      compact
    >
      <div className="flex flex-col gap-y-4">
        { showBalance && (
          <AccountingMetric
            label={t('pages.admin.move.sidebar.accounting.balance')}
            variant={Variant.Light}
            metricVariant={balance.price > 0 ? MetricVariant.Negative : MetricVariant.Positive}
            className={mergeClassName(
              'rounded-md border py-4',
              onBalanceClick && 'cursor-pointer',
            )}
            onClick={onBalanceClick}
          >
            { formatPrice(balance, { showCurrency: true }) }
          </AccountingMetric>
        ) }

        { move.paymentProfile?.paymentMethod && (
          <PaymentMethod
            paymentMethod={move.paymentProfile.paymentMethod}
          />
        ) }
      </div>
    </Paper>
  )
}

export default AccountingBox
