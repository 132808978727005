import clsx from 'clsx'
import { styled } from '@mui/material/styles'
import { type SvgIconProps } from '@mui/material/SvgIcon'
import MuiTooltip, { type TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'

const Tooltip: React.FC<TooltipProps> = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.info.main,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    fontSize: '0.9rem',
  },
  '&.warning': {
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.warning.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
    },
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const Icon = styled(InfoIcon)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

type InfoTooltipProps = {
  message: string
  iconPros?: SvgIconProps
  warning?: boolean
} & Omit<TooltipProps, 'title' | 'children'>

const InfoTooltip: React.FC<InfoTooltipProps> = ({
  message,
  iconPros,
  warning = false,
  className,
  ...tooltipProps
}) => {
  const preventDefaultClick = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }
  return (
    <Tooltip
      title={message}
      {...tooltipProps}
      placement="top"
      arrow
      className={clsx(className, warning && 'warning')}
    >
      <Icon
        {...iconPros}
        color={warning ? 'warning' : 'info'}
        onClick={preventDefaultClick}
      />
    </Tooltip>
  )
}

export default InfoTooltip
