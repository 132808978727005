import { gql } from '@apollo/client'

import { jobFieldsForPartner } from './jobs.queries'

export const closeJobForPartner = gql`
mutation CloseJobForPartner($jobId: String!, $payload: CloseJobForPartnerDto!) {
  closeJobForPartner(jobId: $jobId, payload: $payload) {
    ${jobFieldsForPartner}
  }
}
`
