import Link, { type LinkProps } from '../../../components/Link'
import { useChangeLanguageAction, useLanguage } from '..'

type LanguageSelectorProps = {
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
} & Omit<LinkProps, 'to' | 'onClick'>

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  onClick,
  ...linkProps
}) => {
  const language = useLanguage()
  const changeLanguage = useChangeLanguageAction()

  const onChangeLanguage = (event: React.MouseEvent<HTMLAnchorElement>, newLanguage: string) => {
    event.preventDefault()
    changeLanguage(newLanguage)
    onClick?.(event)
  }

  const newLanguageCode = language === 'en' ? 'fr' : 'en'
  const newLanguageLabel = language === 'en' ? 'Français' : 'English'

  return (
    <Link
      {...linkProps}
      to="#"
      onClick={(event: React.MouseEvent<HTMLAnchorElement>) => { onChangeLanguage(event, newLanguageCode) }}
    >
      { newLanguageLabel }
    </Link>
  )
}

export default LanguageSelector
