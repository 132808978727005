import CalendarIcon from '@mui/icons-material/CalendarToday'

type SelectedDatesProps = {
  children?: React.ReactNode
}

const SelectedDates: React.FC<SelectedDatesProps> = ({ children }) => {
  if (!children) {
    return null
  }
  return (
    <div className="mt-2 flex items-center justify-center text-lg font-semibold text-slate-500 lg:justify-start">
      <CalendarIcon className="mr-2 !text-[15px]" />
      { children }
    </div>
  )
}

export default SelectedDates
