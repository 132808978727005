import { useTranslation } from 'react-i18next'
import InputAdornment from '@mui/material/InputAdornment'
import { type InputProps } from '@concrete-form/mui/Input'
import ArrowIcon from '@mui/icons-material/SubdirectoryArrowRight'

import Languages from '../../../app/Language.enum'
import { Input } from '..'
import { LanguageFlag } from '../../../common/translation'

type TranslatedInputProps = Omit<InputProps, 'margin' | 'placeholder' | 'required'>

const TranslatedInput: React.FC<TranslatedInputProps> = ({
  name,
  label,
  ...inputProps
}) => {
  const { t } = useTranslation()
  return (
    <div className="pb-2 pt-5">
      { label && <div>{ label }</div> }
      { Object.values(Languages).map(language => {
        return (
          <div
            key={language}
            className="relative pl-[35px]"
          >
            <ArrowIcon className="absolute left-[5px] top-[10px] !w-[20px] text-gray-400" />
            <Input
              {...inputProps}
              name={`${name}.${language}`}
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LanguageFlag language={language} />
                  </InputAdornment>
                ),
                ...inputProps.InputProps,
              }}
              placeholder={t(`form.translatedInput.label.${language}`)}
              margin="dense"
            />
          </div>
        )
      }) }
    </div>
  )
}

export default TranslatedInput
