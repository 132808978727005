import { type Interval } from 'date-fns'
import { subDays } from 'date-fns/subDays'
import { addDays } from 'date-fns/addDays'
import { addMonths } from 'date-fns/addMonths'
import { lastDayOfMonth } from 'date-fns/lastDayOfMonth'
import { isWithinInterval } from 'date-fns/isWithinInterval'
import { clamp } from 'date-fns/clamp'
import { set } from 'date-fns/set'
import { isEqual } from 'date-fns/isEqual'
import { addMilliseconds } from 'date-fns/addMilliseconds'
import { areIntervalsOverlapping } from 'date-fns/areIntervalsOverlapping'
import { startOfMonth } from 'date-fns/startOfMonth'
import { endOfMonth } from 'date-fns/endOfMonth'

import { RuleTemporalityRange } from './availabilityAndPricing.models'

export type DateInterval = {
  start: Date
  end: Date
}

export const getFirstDayOfCalendar = (date: Date) => {
  const firstDay = startOfMonth(date)
  return subDays(firstDay, firstDay.getDay())
}

export const getLastDayOfCalendar = (date: Date) => {
  const lastDay = endOfMonth(date)
  return addDays(lastDay, 6 - lastDay.getDay())
}

export const withinInterval = (date: Date, { start, end }: Interval) => {
  try {
    return isWithinInterval(
      date,
      {
        start,
        end,
      },
    )
  } catch (error) {
    return false
  }
}

export const areIntervalsOverlappingInclusive = (left: Interval, right: Interval) => areIntervalsOverlapping(
  { start: left.start, end: addMilliseconds(left.end, 1) },
  { start: right.start, end: addMilliseconds(right.end, 1) },
)

/**
 * take an interval and split it into month intervals
 */
export const splitIntoMonthsIntervals = (interval: DateInterval) => {
  const intervals: DateInterval[] = []
  const monthDiff = interval.end.getMonth() - interval.start.getMonth()
  const nbMonths = (monthDiff < 0 ? (12 + monthDiff) : monthDiff) + 1

  for (let i = 0; i < nbMonths; i++) {
    const start = clamp(
      set(addMonths(interval.start, i), { date: 1 }),
      interval,
    )
    const end = addDays(clamp(
      lastDayOfMonth(start),
      interval,
    ), 1)
    intervals.push({ start, end })
  }
  return intervals
}

/**
 * ASC sort of intervals start date
 */
export const sortIntervals = (intervals: Interval[]) => {
  return intervals.sort((a, b) => {
    if (a.start < b.start) {
      return -1
    }
    if (a.start > b.start) {
      return 1
    }
    return 0
  })
}

/**
 * if an interval ends with a neighbor's start date, stitch both intervals
 */
export const stitchIntervals = (intervals: Interval[]): Interval[] => {
  sortIntervals(intervals)

  const stitched: Interval[] = []
  intervals.forEach(interval => {
    if (stitched.length === 0) {
      stitched.push(interval)
      return
    }
    const lastDate = stitched[stitched.length - 1].end
    if (isEqual(interval.start, lastDate)) {
      stitched[stitched.length - 1].end = interval.end
      return
    }
    stitched.push(interval)
  })

  return stitched
}

export const getUnavailableState = (ranges: RuleTemporalityRange[]) => {
  if (ranges.includes(RuleTemporalityRange.Day)) {
    return RuleTemporalityRange.Day
  }
  if (ranges.includes(RuleTemporalityRange.Am) && ranges.includes(RuleTemporalityRange.Pm)) {
    return RuleTemporalityRange.Day
  }
  if (ranges.includes(RuleTemporalityRange.Am)) {
    return RuleTemporalityRange.Am
  }
  return RuleTemporalityRange.Pm
}
