import { Pie, PieChart, ResponsiveContainer } from 'recharts'
import { mergeClassName } from '../../utils/mergeClassName'

type PercentPieChartProps = {
  value: number
  label?: string
  startAngle?: number
  margin?: number
  innerRadius?: number
  outterRadius?: number
  animationBegin?: number
  animationDuration?: number
  className?: string
}

const PercentPieChart: React.FC<PercentPieChartProps> = ({
  value,
  label,
  startAngle = 90,
  margin = 3,
  innerRadius = 50,
  outterRadius = 60,
  animationBegin = 250,
  animationDuration = 800,
  className,
}) => {
  const data = [{ name: '', value: 100 }]
  const angleValue = (value / 100) * -360
  const showSecondLine = value < 100 - margin

  return (
    <div className="relative flex size-full">
      <ResponsiveContainer width="100%">
        <PieChart>
          <Pie
            data={data}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={innerRadius}
            outerRadius={outterRadius}
            startAngle={startAngle}
            endAngle={angleValue + startAngle}
            fill="rgb(148, 163, 184)"
            animationBegin={animationBegin}
            animationDuration={animationDuration}
          />
          { showSecondLine && (
            <Pie
              data={data}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius={innerRadius}
              outerRadius={outterRadius}
              startAngle={angleValue - margin + startAngle}
              endAngle={-360 + margin + startAngle}
              fill="rgba(148, 163, 184, .2)"
              animationBegin={animationBegin}
              animationDuration={animationDuration}
            />
          ) }
        </PieChart>
      </ResponsiveContainer>
      <div className={mergeClassName(
        'absolute flex h-full w-full items-center justify-center',
        className,
      )}
      >
        { label ?? `${value}%` }
      </div>
    </div>
  )
}

export default PercentPieChart
