import GoogleIcon from '@mui/icons-material/Google'
import MicrosoftIcon from '@mui/icons-material/Microsoft'
import GenericIcon from '@mui/icons-material/CalendarMonth'

export const useExternalEventLogo = (source: string): React.FC<{ className?: string }> => {
  switch (source.toLowerCase()) {
    case 'calendar.google.com':
      return GoogleIcon
    case 'outlook.live.com':
      return MicrosoftIcon
    default:
      return GenericIcon
  }
}
