import ContainedPage from '../../../components/layout/ContainedPage'
import PageContent from '../../../components/layout/PageContent'
import { protectAdminPage } from '../../../modules/users'
import UsersAndCompaniesStats from './UsersAndCompaniesStats'
import MovesStats from './MovesStats'

const AdminDashboardPage: React.FC = () => {
  return (
    <ContainedPage>
      <PageContent wide>
        <div className="my-4 flex flex-col gap-y-2">
          <UsersAndCompaniesStats />
          <MovesStats />
        </div>
      </PageContent>
    </ContainedPage>

  )
}

export default protectAdminPage(AdminDashboardPage)
