import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  type CompanyBranchPayload,
  type CompanyBranch,
} from '../../../../modules/companies'
import {
  Form,
  Input,
  Label,
  SubmitButton,
} from '../../../form'
import useValidationSchema from './validationSchema'
import { companyBranchToFormData, formatFormOutput } from './formDataBuilder'
import { type InitialRatingValues } from './InitialRatingForm.type'

type InitialRatingFormProps = {
  onSubmit: (payload: CompanyBranchPayload) => Promise<any>
  companyBranch?: CompanyBranch
}

const InitialRatingForm: React.FC<InitialRatingFormProps> = ({
  onSubmit,
  companyBranch,
}) => {
  const { t } = useTranslation()

  const schema = useValidationSchema()
  const form = useForm<InitialRatingValues>({
    resolver: yupResolver(schema),
    defaultValues: companyBranchToFormData(companyBranch),
    mode: 'onTouched',
  })

  const formatBeforeSubmit = async (values: InitialRatingValues) => await onSubmit(formatFormOutput(values))

  return (
    <Form form={form} onSubmit={formatBeforeSubmit}>
      <div className="flex flex-col justify-between gap-y-4 lg:flex-row">
        <div className="flex flex-col gap-x-4 gap-y-2 lg:flex-row">
          <Label
            label={t('forms.fields.companyInitialRating.label')}
            labelClassName="flex gap-x-2"
            className="pt-2"
          >
            <Input
              name="initialRating"
              size="small"
              className="!w-[120px]"
            />
          </Label>

          <Label
            label={t('forms.fields.companyInitialNbRatings.label')}
            labelClassName="flex gap-x-2"
            className="pt-2"
          >
            <Input
              name="initialNbRatings"
              size="small"
              className="!w-[120px]"
            />
          </Label>
        </div>

        <div>
          <SubmitButton>
            { t('actions.save') }
          </SubmitButton>
        </div>
      </div>
    </Form>
  )
}

export default InitialRatingForm
