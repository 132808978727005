import TimeAgo from '../../TimeAgo'
import SmallValue from './SmallValue'

type DateValueProps = {
  date?: Date | string | null
}

const TimeAgoValue: React.FC<DateValueProps> = ({
  date,
}) => {
  if (!date) {
    return <SmallValue>—</SmallValue>
  }
  return (
    <TimeAgo date={date} live={false} />
  )
}

export default TimeAgoValue
