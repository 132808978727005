import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/AddCircleOutline'
import RemoveIcon from '@mui/icons-material/CancelOutlined'

import {
  type CompanyBranch,
  type UpdateImportedCalendarsPayload,
} from '../../../../modules/companies'
import {
  Form,
  Input,
  Label,
  SubmitButton,
} from '../../../form'
import {
  Modal,
  ModalDescription,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '../../../Modal'
import Spinner from '../../../Spinner'
import useValidationSchema from './validationSchema'
import { formatFormOutput, importedCalendarsToFormData } from './formDataBuilder'
import { type ImportCalendarsFormValues } from './ImportCalendarsForm.types'

type ImportCalendarsFormProps = {
  open: boolean
  onClose: () => void
  onSubmit: (payload: UpdateImportedCalendarsPayload) => Promise<any>
  companyBranch?: CompanyBranch
}

const ImportCalendarsForm: React.FC<ImportCalendarsFormProps> = ({
  open,
  onClose,
  onSubmit,
  companyBranch,
}) => {
  const { t } = useTranslation()
  const schema = useValidationSchema()
  const form = useForm<ImportCalendarsFormValues>({
    resolver: yupResolver(schema),
    defaultValues: importedCalendarsToFormData(companyBranch?.externalEvents?.importedCalendars),
  })
  const {
    fields,
    append,
    remove,
  } = useFieldArray({ control: form.control, name: 'importedCalendars' })

  const [loading, setLoading] = useState(false)
  const maxItems = 3

  /**
   * add new row
   */
  const addRow = () => {
    append({ value: '' })
  }

  const handleSubmit = async (payload: ImportCalendarsFormValues) => {
    try {
      setLoading(true)
      await onSubmit(formatFormOutput(payload))
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      open={open}
      className="lg:max-w-[550px]"
      showCloseIcon={false}
    >
      <ModalHeader>
        <ModalTitle>
          { t('forms.companyBranch.importCalendars.title') }
        </ModalTitle>
      </ModalHeader>
      <Form
        form={form}
        onSubmit={handleSubmit}
      >

        <ModalDescription className="my-4 max-h-[calc(100dvh-185px)] text-base">

          { loading && <Spinner floating backdrop /> }

          { t('forms.companyBranch.importCalendars.description') }

          <div className="my-4">
            { fields.map((field, index) => (
              <div
                key={field.id}
                className="my-2 flex items-start gap-x-1"
              >
                <Label
                  label={(
                    <div className="min-w-[65px] pt-3">
                      { t('forms.companyBranch.importCalendars.fieldLabel', { position: index + 1 }) }
                    </div>
                  )}
                  labelClassName="flex grow gap-x-4"
                  className="whitespace-nowrap"
                >
                  <Input
                    name={`importedCalendars.${index}.value`}
                    size="small"
                  />
                </Label>

                <IconButton
                  color="error"
                  onClick={() => { remove(index) }}
                >
                  <RemoveIcon />
                </IconButton>
              </div>
            )) }
          </div>

          <Button
            color="secondary"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={addRow}
            size="small"
            disabled={fields.length >= maxItems}
          >
            { t('actions.add') }
          </Button>

        </ModalDescription>
        <ModalFooter>
          <Button
            variant="contained"
            color="secondary"
            onClick={onClose}
          >
            { t('actions.cancel') }
          </Button>

          <SubmitButton>
            { t('actions.save') }
          </SubmitButton>
        </ModalFooter>
      </Form>
    </Modal>

  )
}

export default ImportCalendarsForm
