import { type TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { ChargeDescription, ChargeUnit } from '../../../../../modules/invoices'
import {
  calculateNumberOfTrucks,
} from '../../../../../modules/quotes'
import {
  ProtectiveMaterial,
  SpecialItem,
  SpecialService,
  Truck,
} from '../../../../../modules/companies'
import { rowTypeByDescription } from './ChargeRow.const'
import { RowType } from './ChargeRow.enum'
import { type MoveSize, type ChargeFormValue } from '../ChargesFormContent.type'

/**
 * get row type based on row description
 */
export const getRowType = (description: ChargeDescription) => {
  return rowTypeByDescription[description] ?? RowType.Custom
}

/**
 * get item default value based on move size
 */
export const getDefaultValues = (moveSize?: MoveSize, type?: RowType): Omit<ChargeFormValue, 'categoryAndDescription'> => {
  const units = getUnits(type)
  const defaultUnit = units[0]?.value
  const taxable = type ? showTaxable(type) : false

  const defaultValues = {
    quantity: '1',
    unitPrice: '0.00',
    item: '',
    unit: defaultUnit as ChargeUnit | 'units',
    taxable,
  }

  switch (type) {
    case RowType.Labour:
    case RowType.Stairs:
      return {
        ...defaultValues,
        item: String(moveSize?.nbMen ?? 2),
      }

    case RowType.Truck:
    case RowType.Overnight:
      return {
        ...defaultValues,
        item: String(calculateNumberOfTrucks(moveSize?.nbTrucks ?? []) ?? 1),
      }

    case RowType.TruckFixedFee:
      return {
        ...defaultValues,
        item: Object.values(moveSize?.nbTrucks ?? {})?.[0]?.key,
      }

    case RowType.SpecialService:
      return {
        ...defaultValues,
        item: Object.values(SpecialService)[0],
      }

    case RowType.SpecialItem:
      return {
        ...defaultValues,
        item: Object.values(SpecialItem)[0],
      }

    case RowType.ProtectiveMaterial:
      return {
        ...defaultValues,
        item: Object.values(ProtectiveMaterial)[0],
      }

    default:
      return defaultValues
  }
}

/**
 * get item options based on row description
 */
export const useItemOptions = (rowType: RowType) => {
  const { t } = useTranslation()

  switch (rowType) {
    /* nb men options */
    case RowType.Labour:
    case RowType.Stairs:
      return Array.from({ length: 10 }).map((_, index) => ({
        value: String(index + 1),
        label: t('nbMen', { count: index + 1 }),
      }))

    /* nb truck options */
    case RowType.Truck:
    case RowType.Overnight:

      return Array.from({ length: 10 }).map((_, index) => ({
        value: String(index + 1),
        label: t('nbTrucks', { count: index + 1 }),
      }))

    case RowType.TruckFixedFee:
      return Object.values(Truck).map(truck => ({
        value: truck,
        label: t(`trucks.${truck}.title`),
      }))

    case RowType.SpecialService:
      return Object.values(SpecialService).map(specialService => ({
        value: specialService,
        label: t(`specialServices.${specialService}.title`),
      }))

    case RowType.SpecialItem:
      return Object.values(SpecialItem).map(specialItem => ({
        value: specialItem,
        label: t(`specialItems.${specialItem}.title`),
      }))

    case RowType.ProtectiveMaterial:
      return Object.values(ProtectiveMaterial).map(protectiveMaterial => ({
        value: protectiveMaterial,
        label: t(`protectiveMaterial.${protectiveMaterial}.title`),
      }))
  }
}

/**
 * get available units for each type
 */
export const getUnits = (type?: RowType, t?: TFunction) => {
  const hoursOption = {
    value: ChargeUnit.hour,
    label: t?.('units.hour'),
  }

  const kmOption = {
    value: ChargeUnit.km,
    label: t?.('units.km'),
  }

  const unitOption = {
    value: 'units',
    label: t?.('units.units'),
  }

  const nightsOption = {
    value: undefined,
    label: t?.('units.nights'),
  }

  const stairsOption = {
    value: undefined,
    label: t?.('units.stairs'),
  }

  switch (type) {
    case RowType.Labour:
      return [hoursOption]

    case RowType.Truck:
      return [kmOption]

    case RowType.Overnight:
      return [nightsOption]

    case RowType.Stairs:
      return [stairsOption]

    case RowType.Custom:
      return [unitOption, hoursOption, kmOption]

    default:
      return []
  }
}

/**
 * get item options based on row description
 */
export const useUnits = (type: RowType) => {
  const { t } = useTranslation()
  return getUnits(type, t)
}

export const showTaxable = (type: RowType) => {
  return type === RowType.Custom
}

/**
 * when editAll is set to false, this is used to select fields that are available
 */
export const isEditable = (description: ChargeDescription) => {
  switch (description) {
    case ChargeDescription.customCharge:
    case ChargeDescription.customChargeMw:
      return true
    default:
      return false
  }
}
