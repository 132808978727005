import { useEffect } from 'react'

import { useConfig } from '../../config'
import { useNotifications } from '../../companies'
import { useFetchPendingRequestsNotifications } from './jobRequests.hooks'

const JobRequestsFetcher: React.FC = () => {
  const fetchFrequency = useConfig<number>('jobRequests.fetchFrequency')

  const { listening } = useNotifications()
  const fetchPendingJobRequestsNotifications = useFetchPendingRequestsNotifications()

  useEffect(() => {
    const run = () => {
      if (!listening) {
        return
      }
      fetchPendingJobRequestsNotifications().catch(console.error)
    }

    let interval: NodeJS.Timeout | undefined
    clearInterval(interval)

    if (listening) {
      run()
      interval = setInterval(run, fetchFrequency)
    }
    return () => { clearInterval(interval) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listening])

  return null
}

export default JobRequestsFetcher
