import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { useUpdatePasswordAction, type UpdatePasswordPayload } from '../../../../../modules/users'
import { SubmitButtonsContainer } from '../../../../form'
import UpdatePasswordForm from '../../../../forms/users/UpdatePasswordForm'
import Modal from '../../../../MuiModal'
import ServerError from '../../../../errors/ServerError'

type UpdatePasswordModalProps = {
  open: boolean
  onClose?: () => void
}

const UpdatePasswordModal: React.FC<UpdatePasswordModalProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation()
  const [success, setsuccess] = useState(false)
  const [error, setError] = useState<unknown>()
  const { updatePassword } = useUpdatePasswordAction()

  const closeModal = () => {
    setsuccess(false)
    setError(undefined)
    onClose?.()
  }

  const onSubmit = async (payload: UpdatePasswordPayload) => {
    try {
      await updatePassword({
        password: payload.password,
        newPassword: payload.newPassword,
      })
      setsuccess(true)
    } catch (error) {
      setError(error)
    }
  }

  return (
    <Modal
      width={450}
      open={open}
      onClose={closeModal}
    >
      <Typography variant="h2">
        { t('pages.users.profile.security.changePassword.title') }
      </Typography>

      { success
        ? (
          <>
            <Alert severity="success">
              { t('pages.users.profile.security.changePassword.success') }
            </Alert>
            <SubmitButtonsContainer style={{ padding: '2rem 0 0' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={closeModal}
              >
                { t('actions.close') }
              </Button>
            </SubmitButtonsContainer>
          </>
          )
        : (
          <>
            { error && <ServerError error={error} /> }
            <UpdatePasswordForm
              onSubmit={onSubmit}
              onClose={closeModal}
            />
          </>
          ) }
    </Modal>
  )
}

export default UpdatePasswordModal
