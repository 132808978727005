import { ChargeCategory, ChargeDescription } from './invoices.types'

export const chargeCategoryChild = {
  [ChargeCategory.labour]: [
    ChargeDescription.movingLabour,
    ChargeDescription.packingLabour,
    ChargeDescription.transportLabour,
    ChargeDescription.customCharge,
  ],

  [ChargeCategory.tripAndTravel]: [
    ChargeDescription.travelLabour,
    ChargeDescription.travelTruckFeePerKilometer,
    ChargeDescription.transportTruckFeePerKilometer,
    ChargeDescription.fixedTruckFee,
    ChargeDescription.customCharge,
  ],

  [ChargeCategory.additionalCharges]: [
    ChargeDescription.additionalChargesOvernight,
    ChargeDescription.additionalChargesStairs,
    ChargeDescription.additionalChargesSpecialServices,
    ChargeDescription.additionalChargesSpecialItems,
    ChargeDescription.customCharge,
    ChargeDescription.customChargeMw,
  ],

  [ChargeCategory.protectiveMaterial]: [
    ChargeDescription.protectiveMaterialProduct,
    ChargeDescription.customCharge,
  ],
}
