import { type EventWrapperProps } from 'react-big-calendar'

import { type CalendarRuleEvent } from '../Calendar.type'

interface EventWrapperPropsWithChildren extends EventWrapperProps<CalendarRuleEvent> {
  children?: React.ReactNode
}

const EventWrapper: React.FC<EventWrapperPropsWithChildren> = ({ event, children }) => {
  const { color, order } = event.resource ?? {}

  return (
    <div className="relative">
      <div
        className="absolute top-0 flex h-[15px] w-full items-center rounded-md border border-white px-2 child:font-sans child:text-[0.9rem] child:text-white md:h-[25px] md:border-2 lg:h-[35px]"
        style={{
          zIndex: order,
          background: color,
        }}
      >
        { children }
      </div>
    </div>
  )
}

export default EventWrapper
