import { useState } from 'react'
import {
  type ReportInterval,
  ReportIntervalType,
  type ReportIntervalValue,
} from './adminAccounting.types'
import * as utils from './adminAccounting.utils'

/**
 * use current interval value
 */
export const useReportInterval = () => {
  const defaultValue = utils.getSavedReportIntervalValue() ?? { type: ReportIntervalType.MonthToDate }
  const [value, setValue] = useState<ReportIntervalValue>(defaultValue)

  const set = (value: ReportIntervalValue) => {
    utils.setSavedReportIntervalValue(value)
    setValue(value)
  }

  return [value, set] as [typeof value, typeof set]
}

/**
 * get interval (from + end dates) from an interval value
 */
export const useGetReportInterval = () => {
  return (value: ReportIntervalValue): ReportInterval => {
    switch (value.type) {
      case ReportIntervalType.YearToDate:
        return utils.getYearToDateInterval()
      case ReportIntervalType.MonthToDate:
        return utils.getMonthToDateInterval()
      case ReportIntervalType.PreviousMonth:
        return utils.getPreviousMonthInterval()
      case ReportIntervalType.Custom:
        return value.interval
    }
  }
}
