import { useQuery, useLazyQuery, useMutation } from '@apollo/client'

import { type QueryResult, type PaginatedQueryResult, type PaginatedQueryResultData } from '../../graphQl'
import { type AdminUser } from './adminUsers.models'
import { type UpdateUserAdminPayload, type UsersPaginatedQuery } from './adminUsers.types'
import * as queries from './adminUsers.queries'
import * as mutations from './adminUsers.mutations'

/**
 * returns given user detail
 */
export const useUser = (userId: string, { reload = false, ...queryArgs } = {}): QueryResult<AdminUser | undefined> => {
  const fetchPolicy = reload ? 'network-only' : 'cache-first'

  const { data, networkStatus, loading, error, ...queryProps } = useQuery(
    queries.getUser,
    {
      variables: { userId },
      fetchPolicy,
      ...queryArgs,
    },
  )

  return {
    data: data?.admin_getUser,
    loading: (loading && networkStatus === 1),
    error,
    ...queryProps,
  }
}

type UseUsersHookResult = {
  fetchUsers: (variables: UsersPaginatedQuery) => Promise<PaginatedQueryResultData<AdminUser>>
} & PaginatedQueryResult<AdminUser>

/**
 * returns all users
 * query optimized for data grid
 */
export const useUsers = ({ reload = false, ...queryArgs } = {}): UseUsersHookResult => {
  const fetchPolicy = reload ? 'network-only' : 'cache-first'

  const [fetch, { data, previousData, networkStatus, loading, error, ...queryProps }] = useLazyQuery(
    queries.getUsers,
    {
      fetchPolicy,
      ...queryArgs,
    },
  )
  const fetchUsers = async (variables?: UsersPaginatedQuery) => {
    const { data } = await fetch({ variables })
    return data?.admin_getUsers
  }

  return {
    fetchUsers,
    data: (data ?? previousData)?.admin_getUsers,
    loading: (loading && networkStatus === 1),
    error,
    ...queryProps,
  }
}

/**
 * update current user profile
 */
export const useUpdateUserAction = () => {
  const [updateUser, { data, loading, ...mutationProps }] = useMutation(mutations.updateUser)

  return {
    updateUser: async (userId: string, payload: UpdateUserAdminPayload) => {
      const { data } = await updateUser({
        variables: {
          userId,
          payload,
        },
      })

      return data.admin_updateUser
    },
    data: data?.admin_updateUser,
    loading,
    ...mutationProps,
  }
}

/**
 * delete multiple users
 */
export const useDeleteUsersAction = () => {
  const [deleteUsers, { data, loading, ...mutationProps }] = useMutation(mutations.deleteUsers)

  return {
    deleteUsers: async (ids: string[]) => {
      const { data } = await deleteUsers({
        variables: {
          ids,
        },
      })

      return data.admin_deleteUsers
    },
    data: data?.admin_deleteUsers,
    loading,
    ...mutationProps,
  }
}

/**
 * restore multiple users
 */
export const useRestoreUsersAction = () => {
  const [restoreUsers, { data, loading, ...mutationProps }] = useMutation(mutations.restoreUsers)

  return {
    restoreUsers: async (ids: string[]) => {
      const { data } = await restoreUsers({
        variables: {
          ids,
        },
      })

      return data.admin_restoreUsers
    },
    data: data?.admin_restoreUsers,
    loading,
    ...mutationProps,
  }
}
