import { useEffect } from 'react'

import Route from '../../../app/Route.enum'
import { protectPage } from '../../../modules/users'
import { useCurrentCompanyBranch } from '../../../modules/companies'
import { useNavigate } from '../../../modules/navigation'
import ContainedPage from '../../../components/layout/ContainedPage/ContainedPage'
import Spinner from '../../../components/Spinner'
import { Error404Page } from '../../errors'
import JobRequestsPageContent from './JobRequestsPageContent'

const JobRequestsPage: React.FC = () => {
  const { replace } = useNavigate()

  const {
    data: currentCompanyBranch,
    error: currentCompanyBranchError,
    loading: currentCompanyBranchLoading,
  } = useCurrentCompanyBranch()

  const companyReady = (currentCompanyBranch?.setupProgress ?? 0) === 100

  useEffect(() => {
    if (!!currentCompanyBranchError || currentCompanyBranchLoading) {
      return
    }
    if (!companyReady) {
      replace(Route.BranchSettings)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyReady, currentCompanyBranchError, currentCompanyBranchLoading])

  if (currentCompanyBranchLoading) {
    return <ContainedPage><Spinner floating /></ContainedPage>
  }

  if (!!currentCompanyBranchError || !currentCompanyBranch?.approved) {
    return <Error404Page />
  }

  if (!companyReady || !currentCompanyBranch) {
    return null
  }

  return (
    <ContainedPage>
      <JobRequestsPageContent />
    </ContainedPage>
  )
}

export default protectPage(JobRequestsPage)
