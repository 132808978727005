import { createContext, useContext } from 'react'
import { type NotificationsContextData } from './notifications.types'

export const NotificationsContext = createContext<NotificationsContextData | null>(null)

export type NotificationsProviderProps = {
  children: React.ReactNode
} & NotificationsContextData

export const NotificationsProvider: React.FC<NotificationsProviderProps> = ({
  children,
  ...contextData
}) => {
  return (
    <NotificationsContext.Provider
      value={contextData}
    >
      { children }
    </NotificationsContext.Provider>
  )
}

export const useNotificationsContext = (): NotificationsContextData => {
  const context = useContext(NotificationsContext)
  if (!context) {
    throw new Error('Missing notifications context. Did you forget to use <CompanyBranchNotifications /> ?')
  }
  return context
}
