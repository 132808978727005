import { useEffect } from 'react'

import { useLocation } from '../core/navigation.hooks'

/**
 * this scroll to top when we change page (like regular routing)
 */
const ScrollRestauration: React.FC = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
    document.getElementById('scrollable-body')?.scrollTo(0, 0)
  }, [pathname])

  return null
}

export default ScrollRestauration
