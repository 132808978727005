import { useQuery, useMutation } from '@apollo/client'

import { type QueryResult } from '../../graphQl'
import { type User } from './users.models'
import * as queries from './users.queries'
import * as mutations from './users.mutations'
import {
  type RegisterPayload,
  type UpdateUserPayload,
  type UpdatePasswordPayload,
  type VerifyEmailPayload,
  type DeleteAccountPayload,
} from './users.types'
import { useAuthenticationState } from './authentication'
import { Permission, usePermission } from './permissions'

/**
 * returns current user profile
 */
export const useCurrentUser = ({ reload = false, skip = false, ...queryArgs } = {}): QueryResult<User | undefined> => {
  const { isAuthenticated } = useAuthenticationState()
  skip = skip || !isAuthenticated

  /* istanbul ignore next ; ignore data reloading */
  const fetchPolicy = reload ? 'network-only' : 'cache-first'

  const { data, networkStatus, loading, error, ...queryProps } = useQuery(
    queries.getUser,
    {
      skip,
      fetchPolicy,
      ...queryArgs,
    },
  )

  return {
    data: data?.getUser,
    loading: (loading && networkStatus === 1),
    error,
    ...queryProps,
  }
}

/**
 * helper hook to know if use is admin or partner
 */
export const useCurrentUserAccountType = () => {
  return {
    isAdmin: usePermission(Permission.adminAccess),
    isPartner: usePermission(Permission.partnerAccess),
  }
}

/**
 * returns a partner invitation
 */
export const usePartnerInvitation = (invitationId?: string, { reload = false, skip = false, ...queryArgs } = {}): QueryResult<User | undefined> => {
  /* istanbul ignore next ; ignore data reloading */
  const fetchPolicy = reload ? 'network-only' : 'cache-first'

  const { data, networkStatus, loading, error, ...queryProps } = useQuery(
    queries.getPartnerInvitation,
    {
      skip,
      variables: { invitationId },
      fetchPolicy,
      ...queryArgs,
    },
  )

  return {
    data: data?.getPartnerInvitation,
    loading: (loading && networkStatus === 1),
    error,
    ...queryProps,
  }
}

/**
 * register a user
 */
export const useRegisterAction = () => {
  const [register, { data, loading, ...mutationProps }] = useMutation(mutations.register)

  return {
    register: async (payload: RegisterPayload) => {
      const { data } = await register({
        variables: {
          payload,
        },
      })

      return data.registerPartner
    },
    data: data?.registerPartner,
    loading,
    ...mutationProps,
  }
}

/**
 * update current user profile
 */
export const useUpdateUserAction = () => {
  const [updateUser, { data, loading, ...mutationProps }] = useMutation(mutations.updatePartner)

  return {
    updateUser: async (payload: UpdateUserPayload) => {
      const { data } = await updateUser({
        variables: {
          payload,
        },
      })

      return data.updatePartner
    },
    data: data?.updatePartner,
    loading,
    ...mutationProps,
  }
}

/**
 * update current user language
 */
export const useUpdateUserLanguage = () => {
  const [updateLanguage, { data, loading, ...mutationProps }] = useMutation(mutations.updateLanguage)

  return {
    updateLanguage: async () => {
      const { data } = await updateLanguage()

      return data.updateLanguage
    },
    data: data?.updateLanguage,
    loading,
    ...mutationProps,
  }
}

/**
 * update a user's password
 */
export const useUpdatePasswordAction = () => {
  const [updatePassword, { data, loading, ...mutationProps }] = useMutation(mutations.updatePassword)

  return {
    updatePassword: async (payload: UpdatePasswordPayload) => {
      const { data } = await updatePassword({
        variables: {
          payload,
        },
      })

      return data.updatePassword
    },
    data: data?.updatePassword,
    loading,
    ...mutationProps,
  }
}

/**
 * verify email
 */
export const useVerifyEmailAction = () => {
  const [verifyEmail, { data, loading, ...mutationProps }] = useMutation(mutations.verifyEmail)

  return {
    verifyEmail: async (payload: VerifyEmailPayload) => {
      const { data } = await verifyEmail({
        variables: {
          payload,
        },
      })
      return data.verifyEmail
    },
    data: data?.verifyEmail,
    loading,
    ...mutationProps,
  }
}

/**
 * resend email verification link
 */
export const useResendVerificationEmailAction = () => {
  const [resendVerificationEmail, { data, loading, ...mutationProps }] = useMutation(mutations.resendVerificationEmail)

  return {
    resendVerificationEmail: async () => {
      const { data } = await resendVerificationEmail()
      return !!data.resendVerificationEmail
    },
    data: !!data?.resendVerificationEmail,
    loading,
    ...mutationProps,
  }
}

/**
 * Request account deletion
 */
export const useDeleteAccountRequestAction = () => {
  const [deleteAccountRequest, { data, loading, ...mutationProps }] = useMutation(mutations.deleteAccountRequest)

  return {
    deleteAccountRequest: async () => {
      const { data } = await deleteAccountRequest()
      return data.deleteAccountRequest
    },
    data: data?.deleteAccountRequest,
    loading,
    ...mutationProps,
  }
}

/**
 * delete the user's account
 */
export const useDeleteAccountAction = () => {
  const [deleteAccount, { data, loading, ...mutationProps }] = useMutation(mutations.deleteAccount)

  return {
    deleteAccount: async (payload: DeleteAccountPayload) => {
      const { data } = await deleteAccount({
        variables: {
          payload,
        },
      })

      return data.deleteAccount
    },
    data: data?.deleteAccount,
    loading,
    ...mutationProps,
  }
}
