import { useConfig } from '../../modules/config'

// generated with : https://www.termsofusegenerator.net

const FrenchContent: React.FC = () => {
  const appFriendlyUrl = useConfig('app.appFriendlyUrl')
  const fullCompanyName = useConfig('app.fullCompanyName')

  return (
    <>
      <h2>Conditions d'utilisation du site Web</h2>

      <p>Version 1.0</p>

      <p>La plateforme partenaire de MovingWaldo accessible au { appFriendlyUrl } est une œuvre protégée par des droits d'auteur appartenant à { fullCompanyName }. Certaines fonctionnalités du site peuvent être soumises à des directives, conditions ou règles supplémentaires, qui seront publiées sur le site en relation avec ces fonctionnalités. Si vous êtes déjà un partenaire actif de { fullCompanyName }, veuillez également vous référer à vos accords de partenariat ou contacter sales@movingwaldo.com.</p>

      <h2>Introduction</h2>

      <p>{ fullCompanyName } («nous», «notre» ou MovingWaldo) exploite le site web { appFriendlyUrl } (le 'Site') par lequel nous offrons la plateforme partenaire de MovingWaldo. Si vous souhaitez utiliser le site comme un visiteur ou vous inscrire (devenir un 'utilisateur'), indépendamment du fait que vous utilisez un de nos services, s'il vous plaît lire attentivement les conditions d'utilisations qui suivent (les «Conditions d'utilisation» ou le «Contrat») et la politique de confidentialité de la plateforme partenaire de MovingWaldo (disponible à https://partners.movingwaldo.com/privacy-policy). Le présent accord énonce les modalités et conditions applicables à l'accès et l'utilisation du site (peu importe si vous êtes un visiteur ou un utilisateur), de même que les modalités et les conditions sous lesquelles nous offrons nos services.</p>

      <h2>Opération du site web</h2>

      <p>Le site web est exploité par nous, au Canada. Nous ne prétendons en aucun cas que certaines sections du site web sont appropriées ou peuvent être utilisées à l'extérieur du Canada. Les gens qui choisissent d'utiliser le site web le font à leurs propres risques et ils sont tenus de se conformer à toutes les lois locales.</p>

      <h2>Confidentialité et renseignements personnels</h2>

      <p>Pour plus d'informations à propos de la protection des informations personnelles des utilisateurs de { fullCompanyName }, veuillez vous référer à notre politique de confidentialité, qui est incluse par référence dans le présent accord. La politique de confidentialité explique comment nous traitons les renseignements personnels des utilisateurs lorsque ceux-ci accèdent au site ou utilisent les services. La politique de confidentialité peut être mise à jour de temps à autre à la discrétion de { fullCompanyName }. Les changements à la politique de confidentialité seront en vigueur dès leur publication sur le site.</p>

      <h2>Acceptation des termes</h2>

      <p>En utilisant le site ou en utilisant l'un de nos services, vous acceptez d'être lié par les termes et conditions du présent accord et la politique de confidentialité de la plateforme partenaire de MovingWaldo. Vous ne pouvez pas utiliser le site ou commander des services si vous n'êtes pas d'âge légal pour conclure un contrat avec { fullCompanyName }. Si vous acceptez cette entente, vous déclarez que vous avez la capacité d'y être lié ou, si vous agissez au nom d'une personne ou d'une entité, que vous avez le pouvoir d'y lier cette personne ou entité.</p>

      <h2>Modifications apportées à cet accord</h2>

      <p>{ fullCompanyName } peut modifier le présent accord à tout moment, avec ou sans préavis, en affichant des conditions d'utilisation sur son site web. { fullCompanyName } indiquera clairement la date de la dernière modification à la fin de cette page. La version la plus récente des Conditions d'utilisation remplacera toutes les versions précédentes. Il est de votre responsabilité de vérifier périodiquement la présente convention pour tous changements. Par la présente vous acceptez d'être lié par les modifications futures à moins que vous informiez { fullCompanyName } que vous n'êtes plus en accord avec les termes d'utilisation du Site. Si vous le faites, votre compte utilisateur sera résilié et vous ne serez plus en mesure d'utiliser le site ou commander des services.</p>

      <h2>Informations sur le compte d'utilisateur</h2>

      <p>En ouvrant un compte sur la plateforme partenaire de MovingWaldo, vous pourrez choisir une combinaison de mot de passe et adresse courriel qui vous permettront d'accéder au site en tant qu'utilisateur. Si vous étiez à partager cette information avec des tiers, { fullCompanyName } ne dispose d'aucun moyen de garder vos informations privées de ces tierces parties. Par conséquent, vous convenez que vous êtes responsable du maintien de la confidentialité de ces informations. Dans tous les cas, vous êtes lié par les conditions d'utilisation de la plateforme partenaire de MovingWaldo de garder l'information confidentielle et de connexion pour votre usage personnel seulement. Si vous devenez conscient de toute utilisation non autorisée des informations de votre compte, vous devez nous en informer dans les plus brefs délais à l'aide du formulaire au bas de cette page.</p>

      <h2>Votre utilisation du site et des services</h2>

      <p>Vous êtes seulement en droit d'accéder au site et utiliser les services à des fins licites. Vous devez fournir des informations exactes, à jour et complètes. Vous ne devez pas déformer vos informations ou utiliser les informations de toute autre personne sans son consentement exprès. Toute activité frauduleuse du site pourra faire l'objet de poursuites civiles et pénales. L'accès et l'utilisation du site et des services peuvent être interrompus de temps à autre pour des raisons quelconques, y compris, sans limitation, la défaillance de l'équipement, les mises à jour périodiques, l'entretien, la réparation du site, ou pour toutes autres raisons jugées raisonnables à la discrétion de { fullCompanyName }.</p>

      <p>Vous acceptez que { fullCompanyName } puisse utiliser vos commentaires, suggestions ou idées lors de futures modifications aux services et lors de campagnes publicitaires ou de marketing. Ces évaluations ou commentaires sont envoyés par courriel à { fullCompanyName } ou saisis sur le site web d'une tierce partie.</p>

      <p>Vous comprenez et acceptez que nous puissions vérifier et supprimer tout contenu que vous fournissez, notamment les messages, les identifiants, noms de société ou de profils à notre discrétion si ceux-ci violent cette entente, ou sont choquants, illégaux, inexacts ou qui pourrait violer les droits d'autrui.</p>

      <h2>Services de { fullCompanyName }</h2>

      <p>{ fullCompanyName } se réserve le droit, à tout moment, de modifier ou d'interrompre, temporairement ou définitivement, les services avec ou sans préavis. Comme indiqué ci-dessous, vous acceptez que { fullCompanyName } ne soit pas responsable envers vous ou tout tiers pour toute modification, suspension, panne ou interruption des services.</p>

      <p>Les services de { fullCompanyName } comprennent, mais ne sont pas limités à:</p>

      <h3>La plateforme partenaire de MovingWaldo</h3>

      <p>La mission de la plateforme partenaire de MovingWaldo de accessible au { appFriendlyUrl } est d'aider les entreprises de déménagement à optimiser la réservation et la gestion de leurs services de déménagement et d'emballage ("vos services"), afin qu'elles puissent se concentrer à offrir un travail de déménagement de la meilleure qualité possible à des prix compétitifs pour les clients.</p>

      <p>{ fullCompanyName } se présente comme une place de marché pour les services de déménagement, offrant aux clients un endroit sûr pour réserver des déménageurs dignes de confiance à un prix équitable.</p>

      <p>Une fois que vous aurez créé un compte sur la plateforme partenaire de MovingWaldo et que vous aurez terminé le processus d'intégration et d'approbation, vous rejoindrez notre réseau exclusif d'entreprises de déménagement. Nous représenterons alors votre entreprise auprès de nos clients intéressés par des services de déménagement.</p>

      <p>Au cours du processus d'intégration, { fullCompanyName } recueillera des informations sur votre entreprise et les services que vous proposez. Entre autres, nous utiliserons les informations que vous nous fournissez pour calculer des devis de déménagement et d'emballage. Ces devis seront présentés à nos clients en votre nom. Si vous souhaitez obtenir des informations techniques supplémentaires, veuillez nous envoyer un message à l'adresse suivante : sales@movingwaldo.com.</p>

      <h2>Terme</h2>

      <p>Vous pouvez résilier ce contrat et votre compte sur la plateforme partenaire de MovingWaldo à tout moment et pour toute raison en informant { fullCompanyName } à l'aide du formulaire de contact au bas de cette page. Nous pouvons résilier le présent contrat avec vous, résilier votre compte et choisir de ne pas mettre en œuvre nos services pour vous à tout moment et pour toute raison, à compter de l'envoi de préavis à l'adresse courriel que vous avez fournie pour votre compte.</p>

      <h2>Droits de propriété intellectuelle de { fullCompanyName }</h2>

      <p>Le contenu du site, notamment son aspect, la convivialité (par exemple, textes, graphiques, images, logos et icônes), les photographies, le contenue, les avis, les logiciels, ou des systèmes brevetables sont protégés sous droits d'auteur, marques et autres lois. L'information exclusive qui se retrouve sur la plateforme partenaire de MovingWaldo appartient exclusivement à { fullCompanyName }. Vous ne pouvez pas copier, modifier, publier, transmettre, distribuer, représenter, afficher ou vendre une telle information. { fullCompanyName } vous accorde le droit d'afficher et utiliser le site soumis à ces termes. Toute distribution, réimpression ou reproduction électronique du contenu du site web de { fullCompanyName } en tout ou en partie à d'autres fins est expressément interdite sans notre autorisation écrite préalable.</p>

      <h2>Avertissements</h2>

      <p>{ fullCompanyName }, ainsi que la personne ou l'entreprise qui vous a recommandé à la plateforme partenaire de MovingWaldo, ne fait aucune déclaration ou garantie, expresse ou implicite, concernant l'exactitude, la fiabilité, la sécurité ou l'exhaustivité du contenu sur le site ou des services. { fullCompanyName } décline toute responsabilité pour toute erreur, omission, interruption, suppression, défaut, panne de communication, vol ou destruction, accès non autorisé à, ou l'altération et l'utilisation de l'information de l'utilisateur. { fullCompanyName } n'est pas responsable de tout problème ou défaillance technique de tout réseau ou des lignes téléphoniques, des systèmes informatiques, des serveurs ou fournisseurs, le matériel informatique, de logiciels, de courrier électronique sur compte, ou de problèmes techniques ou de congestion sur Internet ou une combinaison de ceux-ci, y compris les dommages aux visiteurs du site, les utilisateurs ou à l'ordinateur de toute autre personne liée à ou découlant de la participation ou du téléchargement de matériel dans le cadre du web et/ou en relation avec les Services.</p>

      <p>En aucun cas, { fullCompanyName } ne peut être responsable de toute perte ou dommage, résultant de l'utilisation de quelqu'un du Site ou des Services. Le site web et les services sont fournis 'tels quels' (dans la mesure autorisée par la loi). { fullCompanyName } décline expressément toute garantie ou condition d'aucune sorte, expresse ou implicite, y compris, sans s'y limiter, les garanties implicites de titre, de non-contrefaçon, marchande et d'adéquation à un usage particulier. { fullCompanyName } ne donne aucune garantie que le site sera exempt d'erreur et que l'accès y sera continu et ininterrompu. { fullCompanyName } ne peut pas garantir et ne promet aucun résultat spécifique de l'utilisation du site et/ou des services. Si vous décidez de vous inscrire comme utilisateur ou d'utiliser les Services, vous le faites à vos risques et périls. { fullCompanyName } n'est en aucun cas responsable de la conduite, en ligne ou hors ligne, de tout utilisateur.</p>

      <h2>Informations générales</h2>

      <p>Vous acceptez qu'aucune relation de coentreprise, de partenariat, d'emploi ou ne soit né entre l'utilisateur et { fullCompanyName } résultant de ce document ou de toute utilisation du site web. Vous ne pouvez pas céder un droit, intérêt ou avantage prévu par ces Conditions d'utilisation ou par le biais du site web sans notre consentement écrit préalable exprès.</p>

      <h2>Limitation de responsabilité</h2>

      <p>En aucun cas, { fullCompanyName }, ou la personne ou l'entreprise qui vous a référé à la plateforme partenaire de MovingWaldo, n'est responsable d'un visiteur du site, d'un utilisateur ou d'un tiers pour les dommages directs, indirects, accessoires, spéciaux ou punitifs (mais découlant, y compris négligence), y compris les pertes de bénéfices découlant de, ou liés à l'utilisation ou la mauvaise utilisation du site ou des services ou une partie de celui-ci. Notre site web contient des liens vers d'autres sites qui sont détenus et exploités par des tiers. Ces liens vous permettront de quitter notre site. Les sites liés ne sont pas sous notre contrôle et nous ne sommes pas responsables du contenu de ceux-ci ou de tout lien contenu dans un site lié, ni des changements ou mises à jour de ces sites. Nous ne sommes pas responsables de la diffusion web ou de toute autre forme de transmission reçue d'un site lié. Nous fournissons les liens pour votre commodité. Nous ne cautionnons pas le site ou son utilisation ou de son contenu.</p>

      <h2>Votre indemnisation de { fullCompanyName }</h2>

      <p>Vous devez défendre, indemniser et exonérer { fullCompanyName } et ses dirigeants, administrateurs, actionnaires et employés, de et contre toutes réclamations et dépenses, y compris, mais sans s'y limiter les frais d'avocat, en tout ou en partie, découlant de ou attribuables à une violation du présent accord par vous.</p>

      <h2>Réclamations ou contestations</h2>

      <p>En cas de réclamation, différend ou litige de cette convention, le Site et/ou les Services, vous convenez que vous et nous allons résoudre toute réclamation conformément au présent paragraphe. Cet accord sera régi à tous égards par les lois de la province du Québec, sans égard à ses conflits de principes de droit. En ce qui concerne les litiges ou réclamations, vous reconnaissez et comprenez que la compétence exclusive sur toute cause d'action découlant du présent accord ou les services seront dans la province ou tribunal fédéral situés dans ou à proximité de la ville de Montréal, Québec. Vous acceptez de vous soumettre à la compétence de ces tribunaux, et vous renoncez à toutes les défenses d'un manque de compétence personnelle et forum non commodes en ce qui concerne le lieu et la compétence dans la province et des tribunaux fédéraux situés dans ou à proximité de la ville de Montréal, Québec.</p>

      <h2>Divers</h2>

      <p>Si une partie quelconque du présent accord est jugée illégale, nulle ou non exécutoire par un arbitre ou un tribunal de juridiction compétente, le présent accord dans son ensemble ne doit pas être considéré comme illégal, nul ou non exécutoire, mais uniquement la partie du présent accord qui est jugée illégale, nulle ou inapplicable sera frappée du présent accord.</p>
    </>
  )
}

export default FrenchContent
