/* eslint-disable @typescript-eslint/no-misused-promises */
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import DeleteIcon from '@mui/icons-material/Delete'
import RestoreIcon from '@mui/icons-material/RestoreFromTrash'

import ContainedPage from '../../../components/layout/ContainedPage'
import { Permission, useCurrentUser, usePermission } from '../../../modules/users'
import {
  useDeleteUsersAction,
  useRestoreUsersAction,
  useUser,
} from '../../../modules/adminUsers'
import { capitalizeFirstLetter } from '../../../utils/string'
import Spinner from '../../../components/Spinner'
import ServerError from '../../../components/errors/ServerError'
import Paper from '../../../components/Paper'
import Link from '../../../components/Link'
import ProfileAvatar from '../../../components/users/ProfileAvatar/ProfileAvatar'
import ConfirmationModal, { useConfirmation } from '../../../components/ConfirmationModal'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import PageTitle from '../../../components/layout/PageTitle'
import PageContent from '../../../components/layout/PageContent'
import Route from '../../../app/Route.enum'
import UserStatus from './UserStatus'

const FloatingProfileAvatarContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: 25,
  '& > div': {
    position: 'absolute',
    marginTop: -95,
    marginLeft: -10,
    border: 'solid 4px #fff',
    boxShadow: '5px 4px 9px rgba(0,0,0,0.2)',
    background: '#bdbdbd',
  },
}))

const TopActionsContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: -45,
  right: 25,
  [theme.breakpoints.down('md')]: {
    position: 'static',
    margin: '0.5rem 0 1rem 0',
  },
}))

const Header: React.FC = () => {
  const { t } = useTranslation()
  const { userId } = useParams()
  const { confirm, modalProps } = useConfirmation()
  const { data: user, loading, error } = useUser(userId as string)
  const { data: currentUser } = useCurrentUser()
  const { deleteUsers } = useDeleteUsersAction()
  const { restoreUsers } = useRestoreUsersAction()
  const formatError = useErrorFormatter()
  const canEditUsers = usePermission(Permission.canEditUsers)
  const canPromoteUsers = usePermission(Permission.canPromoteUsers)
  const adminProfile = user && Array.isArray(user?.roles) && user.roles.length > 0
  const selfProfile = userId === currentUser?.id
  const canDeleteOrRestoreUser = canEditUsers && !selfProfile && (adminProfile ? canPromoteUsers : true)

  if (loading || !userId) {
    return <ContainedPage><Spinner floating /></ContainedPage>
  }
  if (!!error || !user) {
    return <ContainedPage><ServerError error={error} /></ContainedPage>
  }

  const identification = user.firstName ? `${String(capitalizeFirstLetter(user.firstName))} ${String(capitalizeFirstLetter(user.lastName))}` : user.email
  const isDeleted = !!user.deletedAt

  const onDelete = async () => {
    if (!await confirm({
      title: t('pages.admin.users.actions.delete.confirmation.title_one'),
      question: t('pages.admin.users.actions.delete.confirmation.question_one'),
      danger: true,
    })) {
      return false
    }

    await toast.promise(
      deleteUsers([userId]),
      {
        pending: t('pages.admin.users.actions.delete.pending_one'),
        success: t('pages.admin.users.actions.delete.success_one'),
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
  }

  const onRestore = async () => {
    if (!await confirm({
      title: t('pages.admin.users.actions.restore.confirmation.title_one'),
      question: t('pages.admin.users.actions.restore.confirmation.question_one'),
    })) {
      return false
    }

    await toast.promise(
      restoreUsers([userId]),
      {
        pending: t('pages.admin.users.actions.restore.pending_one'),
        success: t('pages.admin.users.actions.restore.success_one'),
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
  }

  return (
    <>
      <PageTitle wide>
        <Link to={Route.AdminUsers} className="hidden lg:block">
          <div className="ml-[-5px] text-base/loose underline">
            <ChevronLeftIcon />{ t('pages.admin.users.title') }
          </div>
        </Link>
        { identification }
      </PageTitle>
      <PageContent wide className="py-8">

        <Paper>

          <div className="ml-[-90px] mt-[18px] flex justify-center lg:ml-0 lg:mt-0 lg:block">
            <FloatingProfileAvatarContainer>
              <ProfileAvatar user={user} size={110} />
            </FloatingProfileAvatarContainer>
          </div>

          { canDeleteOrRestoreUser && (
            <TopActionsContainer>
              { isDeleted
                ? (
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    startIcon={<RestoreIcon />}
                    onClick={onRestore}
                  >
                    { t('actions.restore') }
                  </Button>
                  )
                : (
                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={onDelete}
                  >
                    { t('actions.delete') }
                  </Button>
                  ) }
            </TopActionsContainer>
          ) }

          <UserStatus user={user} />
        </Paper>
      </PageContent>

      <ConfirmationModal {...modalProps} />
    </>
  )
}

export default Header
