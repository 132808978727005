import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import MuiButton from '@mui/material/Button'

import { useNavigate } from '../../../modules/navigation'
import Route from '../../../app/Route.enum'

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  padding: '2.5rem 5rem',
  boxSizing: 'border-box',
  color: theme.palette.primary.contrastText,
  '& *': {
    color: theme.palette.primary.contrastText,
  },
  [theme.breakpoints.down('lg')]: {
    padding: '2.5rem',
  },
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    textAlign: 'center',
  },
}))

const Button = styled(MuiButton)(({ theme }) => ({
  borderColor: theme.palette.primary.contrastText,
  color: theme.palette.primary.contrastText,
}))

const LoginSecondaryPanel: React.FC = () => {
  const { t } = useTranslation()
  const { navigate } = useNavigate()

  return (
    <Container>
      <Box>
        <Typography
          variant="h2"
          fontSize="2.5rem"
          lineHeight="2.5rem"
          fontWeight={400}
          marginBottom="1rem"
          // color="primary"
          className="!text-neutral-700"
        >
          { t('pages.users.login.secondaryPanel.title') }
        </Typography>
        <Typography
          marginBottom="2rem"
          className="!text-neutral-600"
        >
          { t('pages.users.login.secondaryPanel.description') }
        </Typography>
        <Button
          variant="outlined"
          size="large"
          onClick={() => { navigate(Route.SignUp) }}
          className="!border-neutral-600 !text-neutral-600"
        >
          { t('pages.users.login.secondaryPanel.signUpButton') }
        </Button>
      </Box>
    </Container>
  )
}

export default LoginSecondaryPanel
