import { useTranslation } from 'react-i18next'
import { set } from 'date-fns/set'
import { endOfMonth } from 'date-fns/endOfMonth'
import { isPast } from 'date-fns/isPast'
import { differenceInMonths } from 'date-fns/differenceInMonths'

import { type PaymentMethod } from '../../../modules/payment'
import { capitalizeFirstLetter } from '../../../utils/string'

export const useFormattedCreditCard = (paymentMethod: PaymentMethod) => {
  const { t } = useTranslation()
  const brand = t(
    `payment.creditCardBrand.${paymentMethod.brand}`,
    { defaultValue: capitalizeFirstLetter(paymentMethod.brand) },
  )
  const months: string[] = Object.values(
    t('date.monthsShort', { returnObjects: true }),
  )

  const expirationDate = endOfMonth(set(new Date(), {
    year: paymentMethod.expirationYear + 2000,
    month: paymentMethod.expirationMonth - 1,
    date: 1,
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  }))
  const formattedDate = `${months[paymentMethod.expirationMonth - 1]} ${paymentMethod.expirationYear + 2000}`

  const expired = isPast(expirationDate)
  const expiresSoon = expired ? false : differenceInMonths(expirationDate, new Date()) <= 1

  return {
    brand,
    expirationDate,
    formattedDate,
    expired,
    expiresSoon,
  }
}
