import { styled } from '@mui/material/styles'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

const TrueIcon = styled(CheckIcon)(({ theme }) => ({
  color: theme.palette.success.main,
}))

const FalseIcon = styled(CloseIcon)(({ theme }) => ({
  opacity: 0.2,
}))

type BooleanValueProps = {
  value: boolean
}

const BooleanValue: React.FC<BooleanValueProps> = ({ value }) => {
  return value ? <TrueIcon /> : <FalseIcon />
}

export default BooleanValue
