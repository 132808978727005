import * as Yup from 'yup'

import { type UpdateUserPayload } from '../../../../modules/users'
import { usePhoneNumberValidator } from '../../../../components/form/fields/PhoneNumberField/validator'
import usePasswordValidator from '../../../../components/form/fields/PasswordField/usePasswordValidator'

const useValidationSchema = () => {
  const validatePhoneNumber = usePhoneNumberValidator()
  const validatePassword = usePasswordValidator()

  return Yup.object<Partial<UpdateUserPayload>>({
    email: Yup.string().required().email(),
    password: validatePassword({ required: false }),
    phoneNumber: validatePhoneNumber({ required: true }),
    position: Yup.string().required(),
  })
}

export default useValidationSchema
