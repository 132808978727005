import { mergeClassName } from '../../utils/mergeClassName'

type SeparatorProps = {
  completed?: boolean
  warning?: boolean
  nextCompleted?: boolean
  nextWarning?: boolean
}

const Separator: React.FC<SeparatorProps> = ({
  completed = false,
  warning = false,
  nextCompleted = false,
  nextWarning = false,
}) => {
  return (
    <div className="absolute right-0 top-0 hidden h-full w-5 lg:block" aria-hidden="true">
      <svg
        className={mergeClassName(
          'h-full w-full text-gray-300 bg-white',
          nextCompleted && 'bg-green-50',
          nextWarning && 'bg-orange-50',

        )}
        viewBox="0 0 22 80"
        fill="none"
        preserveAspectRatio="none"
      >
        <path
          d="M0 -2L20 40L0 82"
          vectorEffect="non-scaling-stroke"
          stroke="currentcolor"
          strokeLinejoin="round"
          className={mergeClassName(
            'fill-white',
            completed && 'fill-green-50',
            warning && 'fill-orange-50',
          )}
        />
      </svg>
    </div>
  )
}

export default Separator
