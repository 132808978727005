import { useTranslation } from 'react-i18next'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

import { type FullMove } from '../../../../modules/adminMoves'
import Route from '../../../../app/Route.enum'
import { capitalizeFirstLetter } from '../../../../utils/string'
import Link from '../../../../components/Link'
import MoveStatusTag from '../../../../components/move/MoveStatusTag'

type HeaderProps = {
  move: FullMove
}

const Header: React.FC<HeaderProps> = ({
  move,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Link to={Route.AdminMoves} className="hidden lg:inline">
        <span className="ml-[-5px] text-base/loose underline">
          <ChevronLeftIcon />{ t('pages.admin.moves.title') }
        </span>
      </Link>
      <div className="flex flex-col gap-x-4 2xl:flex-row 2xl:items-center">
        <span>
          { capitalizeFirstLetter(move.user.firstName, true) }
          { ' ' }
          { capitalizeFirstLetter(move.user.lastName, true) }
        </span>

        <div>
          <MoveStatusTag move={move} />
        </div>
      </div>
    </>
  )
}

export default Header
