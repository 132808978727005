import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import Route from '../../../app/Route.enum'
import { useLocation, useNavigate, useParams } from '../../../modules/navigation'
import { useCurrentCompanyBranch } from '../../../modules/companies'
import { useBreakpoint } from '../../../modules/theme'
import ContainedPage from '../../../components/layout/ContainedPage/ContainedPage'
import MobileContentMenu, { MobileContentMenuLink } from '../../../components/MobileContentMenu'
import {
  CompanyBranchTopContent,
  steps,
  checkForLandingPage,
  checkForInvalidStep,
  getCompletedSteps,
  getNextIncompleteStep,
} from '../../../components/companyBranch'
import { Error404Page } from '../../errors'
import Spinner from '../../../components/Spinner/Spinner'
import { protectPage } from '../../../modules/users'

const CompanyBranchSettingsPage: React.FC = () => {
  const { t } = useTranslation()
  const isLargeScreen = useBreakpoint('lg')
  const { companyId } = useParams()
  const { replace } = useNavigate()
  const { pathname } = useLocation()
  const {
    data: currentCompanyBranch,
    error: currentCompanyBranchError,
    loading: currentCompanyBranchLoading,
  } = useCurrentCompanyBranch()

  const isLandingPage = checkForLandingPage(pathname, !!companyId)
  const isInvalidStep = checkForInvalidStep(pathname, currentCompanyBranch)
  const shouldShowMobileContentMenu = isLandingPage && !isLargeScreen
  const progress = currentCompanyBranch?.setupProgress ?? 0
  const setupInProgress = progress < 100
  const shouldRedirectToStep = isInvalidStep || (isLandingPage && (isLargeScreen || progress === 0))
  const completedSteps = getCompletedSteps(currentCompanyBranch)
  const nextIncompleteStep = getNextIncompleteStep(currentCompanyBranch)

  /**
   * if required, redirect user to a step
   */
  useEffect(() => {
    if (!!currentCompanyBranchError || currentCompanyBranchLoading) {
      return
    }
    if (shouldRedirectToStep) {
      replace(nextIncompleteStep?.route ?? Route.BranchSettingsLegal)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompanyBranchError, currentCompanyBranchLoading, shouldRedirectToStep])

  if (currentCompanyBranchLoading) {
    return <ContainedPage><Spinner floating /></ContainedPage>
  }

  if (currentCompanyBranchError) {
    return <Error404Page />
  }

  return (
    <>
      <Outlet />
      { shouldShowMobileContentMenu && (
        <ContainedPage topContent={<CompanyBranchTopContent />}>
          <MobileContentMenu>

            { Object.entries(steps).map(([step, { route }]) => (
              <MobileContentMenuLink
                key={step}
                to={route}
                completed={setupInProgress && completedSteps[step]}
                disabled={setupInProgress && (!completedSteps[step] && nextIncompleteStep?.route !== route)}
              >
                { t(`pages.companyBranch.menu.${step}`) }
              </MobileContentMenuLink>
            )) }

          </MobileContentMenu>
        </ContainedPage>
      ) }
    </>
  )
}

export default protectPage(CompanyBranchSettingsPage)
