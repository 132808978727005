import { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useFormState, useWatch } from 'react-hook-form'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import EditIcon from '@mui/icons-material/Edit'

import PlaceAutoCompleteControl from './PlaceAutoCompleteControl'
import { type GoogleMapsPlace, type Place as PlaceModel } from '../../../../common/place'
import Place from './Place'
import { mergeClassName } from '../../../../utils/mergeClassName'
import AddressFields from './AddressFields'
import get from '../../../../utils/get'

type PlaceFieldProps = {
  name: string
  className?: string
}

const PlaceField: React.FC<PlaceFieldProps> = ({
  name,
  className,
}) => {
  const { t } = useTranslation()
  const form = useFormContext()
  const place = useWatch({ name })
  const initialPlace = useRef<PlaceModel | undefined>(place)

  const initialAddress = initialPlace.current && 'country' in initialPlace.current
    ? {
        street: initialPlace.current.street,
        apartment: initialPlace.current.apartment,
        city: initialPlace.current.city,
        region: initialPlace.current.region,
        postalCode: initialPlace.current.postalCode,
        country: initialPlace.current.country,
      }
    : undefined

  const initialGoogleMapPlace = initialPlace.current && 'placeId' in initialPlace.current && initialPlace.current.placeId
    ? {
        placeId: initialPlace.current.placeId,
        placeTextLine1: initialPlace.current.placeTextLine1,
        placeTextLine2: initialPlace.current.placeTextLine2,
      }
    : undefined

  const defaultCustomAddress = !!place && !place.placeId
  const defaultEditing = !place || defaultCustomAddress

  const { errors: formErrors, submitCount } = useFormState()
  const controlError = get(formErrors, name)
  const errors = controlError?.root ?? controlError
  const shouldValidate = submitCount > 0
  const [editing, setEditing] = useState(defaultEditing)
  const [isCustomAddress, setIsCustomAddress] = useState(defaultCustomAddress)

  const handleSelectPlace = (place: GoogleMapsPlace) => {
    form.unregister(name)
    form.setValue(name, place, { shouldValidate: true })
    setEditing(false)
  }

  const handleCustomAddress = () => {
    setIsCustomAddress(true)
    form.setValue(name, initialAddress, { shouldValidate })
  }

  const handleSearchAddress = () => {
    setIsCustomAddress(false)
    form.setValue(name, initialGoogleMapPlace, { shouldValidate })
  }

  const handleClearPlace = () => {
    form.unregister(name)
    form.setValue(name, initialPlace.current, { shouldValidate })
  }

  const handleEdit = () => {
    setEditing(true)
  }

  const showPlace = !editing && !!place
  const showAddressField = editing && isCustomAddress
  const showPlaceAutocomplete = editing && !isCustomAddress

  return (
    <>
      { showPlace && (
        <div className={mergeClassName(
          'mt-6 flex flex-col items-center justify-between gap-4 rounded border border-neutral-300 p-4 lg:flex-row lg:gap-2',
          className,
        )}
        >
          <Place place={place} />
          <div>
            <Button
              startIcon={<EditIcon className="-mr-1 ml-1" />}
              size="small"
              variant="outlined"
              color="secondary"
              onClick={handleEdit}
              className="scale-90"
            >
              { t('actions.edit') }
            </Button>
          </div>
        </div>
      ) }

      <div className={mergeClassName(!showPlaceAutocomplete && 'hidden')}>
        <PlaceAutoCompleteControl
          onSelect={handleSelectPlace}
          onClear={handleClearPlace}
          editing={showPlaceAutocomplete && !!place}
          onCustomAddressClick={handleCustomAddress}
          initialValue={initialGoogleMapPlace}
        />
        <div className="mt-1 cursor-pointer text-neutral-500 underline lg:hidden" onClick={handleCustomAddress}>
          { t('components.formFields.PlaceField.enterManually') }
        </div>
      </div>

      { showAddressField && (
        <div className="flex flex-col">
          <AddressFields name={name} />
          <div className="mt-1 cursor-pointer text-right text-neutral-500 underline" onClick={handleSearchAddress}>
            { t('components.formFields.PlaceField.searchAddress') }
          </div>
        </div>
      ) }

      { errors?.message && typeof errors.message === 'string' && (
        <Alert severity="error" className="mt-3">{ errors.message }</Alert>
      ) }
    </>
  )
}

export default PlaceField
