export enum ACTION_TYPES {
  SET_IS_LOGGING_OUT = 'SET_IS_LOGGING_OUT',
}

export const setIsLoggingOut = (isLoggingOut: boolean) => ({
  type: ACTION_TYPES.SET_IS_LOGGING_OUT as const,
  isLoggingOut,
})

export type Actions = ReturnType<typeof setIsLoggingOut>
