import * as Yup from 'yup'

import { type InitialRatingValues } from './InitialRatingForm.type'
import { useTranslation } from 'react-i18next'

const useValidationSchema = () => {
  const { t } = useTranslation()

  return Yup.object<InitialRatingValues>({
    initialRating: Yup.string().test((value, { createError }) => {
      if (value === '' || value === undefined) {
        return true
      }
      const numericValue = parseFloat(String(value))
      if (numericValue <= 0 || numericValue > 5) {
        return createError({ message: t('formValidation.defaultShort') })
      }
      return true
    }),
    initialNbRatings: Yup.string().test((value, { createError }) => {
      if (value === '' || value === undefined) {
        return true
      }
      const numericValue = parseInt(String(value))
      if (numericValue <= 0) {
        return createError({ message: t('formValidation.defaultShort') })
      }
      return true
    }),
  })
}

export default useValidationSchema
