import { useTranslation } from 'react-i18next'

import { mergeClassName } from '../../../../../utils/mergeClassName'
import {
  Label,
  SingleCheckbox,
  PlaceField,
  IntegerInput,
  AddressFields,
} from '../../../../form'

type LocationFieldsProps = {
  name: string
  requireDetails?: boolean
  specificAddresses?: boolean
  children?: React.ReactNode
}

const LocationFields: React.FC<LocationFieldsProps> = ({
  name,
  requireDetails = false,
  specificAddresses = false,
  children,
}) => {
  const { t } = useTranslation()

  return (
    <div className={mergeClassName(
      'h-full rounded-md border p-4 shadow lg:border-none',
    )}
    >
      { children }

      { specificAddresses
        ? (
          <AddressFields name={`${name}.address`} />
          )
        : (
          <PlaceField
            name={`${name}.address`}
            className="mt-0"
          />
          ) }

      <div className="mt-2 flex justify-between">

        <div className="flex flex-wrap items-center gap-3">
          <Label>
            { t('forms.fields.nbFlightsOfStairs.label') }
          </Label>

          <IntegerInput
            name={`${name}.nbStairs`}
            min={0}
            max={9}
          />
        </div>

        { requireDetails && (
          <div className="-mr-4">
            <SingleCheckbox
              name={`${name}.elevatorReservationRequired`}
              label={t('forms.fields.elevatorReservationRequired.label')}
            />
          </div>
        ) }
      </div>
    </div>
  )
}

export default LocationFields
