import { useTranslation } from 'react-i18next'
import InputAdornment from '@mui/material/InputAdornment'

import { ArrivalWindow } from '../../../../../modules/companies'
import Paper from '../../../../Paper'
import {
  DateTime,
  Input,
  Label,
  SelectWithCheckboxes,
  SingleCheckbox,
} from '../../../../form'

const DateTimeFormContent: React.FC = () => {
  const { t } = useTranslation()

  const arrivalWindowsOptions = Object.values(ArrivalWindow).map(arrivalWindow => ({
    value: arrivalWindow,
    label: t(`arrivalWindows.${arrivalWindow}.title`),
  }))

  return (
    <>
      <h2 className="mb-2 font-body2 text-lg font-bold lg:my-4">
        { t('forms.quotesRequest.dateAndTime.title') }
      </h2>
      <Paper>
        <div className="mb-4 flex gap-4">
          <Label label={t('forms.fields.movingDate.label')}>
            <DateTime name="movingDate" />
          </Label>
          <div className="pt-6">
            <SingleCheckbox
              name="flexibleDate"
              label={(
                <div className="leading-tight">
                  { t('forms.quotesRequest.dateAndTime.flexibleDateLabel') }
                </div>
              )}
            />
          </div>
        </div>

        <Label label={t('forms.fields.preferredArrivalWindow.label')}>
          <SelectWithCheckboxes
            name="preferredTime"
            options={arrivalWindowsOptions}
            emptyLabel={t('forms.fields.preferredArrivalWindow.emptyLabel')}
          />
        </Label>

        <Label
          label={t('forms.fields.overnightFees.label')}
          className="mt-4"
        >
          <Input
            name="overnightFees"
            size="small"
            inputProps={{
              maxLength: 2,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  { t('units.night_s') }
                </InputAdornment>
              ),
            }}
          />
        </Label>
      </Paper>
    </>
  )
}

export default DateTimeFormContent
