import { gql } from '@apollo/client'

import { companyAndBranchesFields, companyBranchFields } from './companies.queries'

export const createCompany = gql`
mutation CreatePartnerCompany($payload: CompanyDto!) {
  createCompany(payload: $payload) {
    ${companyAndBranchesFields}
  }
}
`

export const updateCompany = gql`
mutation UpdatePartnerCompany($payload: CompanyDto!, $onBehalfCompanyId: String) {
  updateCompany(payload: $payload, onBehalfCompanyId: $onBehalfCompanyId) {
    ${companyAndBranchesFields}
  }
}
`

export const updateCompanyBranch = gql`
mutation UpdateCompanyBranch($branchId: String!, $payload: UpdateCompanyBranchDto!) {
  updateCompanyBranch(branchId: $branchId, payload: $payload) {
    ${companyBranchFields}
  }
}
`

export const deleteLegalDocuments = gql`
mutation DeleteLegalDocuments($branchId: String!, $fileIds: [String!]!) {
  deleteLegalDocuments(branchId: $branchId, fileIds: $fileIds) {
    ${companyBranchFields}
  }
}
`
