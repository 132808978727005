import { useTranslation } from 'react-i18next'

import Route from '../../../app/Route.enum'
import PageContainer from './PageContainer'
import { CompanyBranchMobileHeader, Step } from '../../../components/companyBranch'
import { useSubmitAction } from '../../../components/forms/companyBranch/useSubmitAction'
import { useCurrentCompanyBranch } from '../../../modules/companies'
import ProtectiveMaterialForm from '../../../components/forms/companyBranch/ProtectiveMaterialForm/ProtectiveMaterialForm'
import { protectPage } from '../../../modules/users'

const CompanyBranchSettingsProtectiveMaterialPage: React.FC = () => {
  const { t } = useTranslation()
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()

  const onSubmit = useSubmitAction({
    step: Step.ProtectiveMaterial,
    initialData: currentCompanyBranch,
  })

  return (
    <PageContainer
      mobileHeader={(
        <CompanyBranchMobileHeader
          title={String(t('pages.companyBranch.menu.protectiveMaterial'))}
          backlinkTo={Route.BranchSettings}
        />
      )}
    >
      <ProtectiveMaterialForm
        companyBranch={currentCompanyBranch}
        onSubmit={onSubmit}
      />
    </PageContainer>
  )
}

export default protectPage(CompanyBranchSettingsProtectiveMaterialPage)
