import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  type CompanyBranch,
  type CompanyBranchProtectiveMaterial,
  ProtectiveMaterial,
  protectiveMaterialIcons,
  useCompanyBranchCurrency,
} from '../../../../modules/companies'
import { Form, CardCheckboxPriceGroup, CardLabel, Label } from '../../../form'
import useValidationSchema from './validationSchema'
import Section from '../../../Section'
import Paper from '../../../Paper/Paper'
import { CompanyBranchProtectiveMaterialToFormData, formatFormOutput } from './formDataBuilder'
import SubmitButton from '../SubmitButton'
import { type ProtectiveMaterialFormValues } from './ProtectiveMaterialForm.type'

type ProtectiveMaterialFormProps = {
  onSubmit: (payload: CompanyBranchProtectiveMaterial) => Promise<any>
  companyBranch?: CompanyBranch
}

const ProtectiveMaterialForm: React.FC<ProtectiveMaterialFormProps> = ({
  onSubmit,
  companyBranch,
}) => {
  const { t } = useTranslation()
  const schema = useValidationSchema()
  const form = useForm<ProtectiveMaterialFormValues>({
    resolver: yupResolver(schema),
    defaultValues: CompanyBranchProtectiveMaterialToFormData(companyBranch?.protectiveMaterial),
    mode: 'onTouched',
  })
  const currency = useCompanyBranchCurrency(companyBranch)

  const formatBeforeSubmit = async (values: ProtectiveMaterialFormValues) => await onSubmit(formatFormOutput(values))

  const productsOptions = Object.fromEntries(Object.values(ProtectiveMaterial).map(protectiveMaterial => ([
    protectiveMaterial,
    <CardLabel
      key={protectiveMaterial}
      title={t(`protectiveMaterial.${protectiveMaterial}.title`)}
      subtitle={t(`protectiveMaterial.${protectiveMaterial}.subtitle`, { defaultValue: '' })}
      Icon={protectiveMaterialIcons[protectiveMaterial]}
    />,
  ])))

  return (
    <Form form={form} onSubmit={formatBeforeSubmit}>
      <Section>
        { t('forms.companyBranch.protectiveMaterial.products.title') }
      </Section>
      <Paper className="bg-zinc-50">
        <div className="mb-4">
          <Label>
            { t('forms.companyBranch.protectiveMaterial.products.description') }
          </Label>
        </div>
        <CardCheckboxPriceGroup
          name="products"
          options={productsOptions}
          companyBranch={companyBranch}
          zeroDollarMessage={t('forms.companyBranch.protectiveMaterial.products.noExtraCost')}
          allowDecimal
          currency={currency}
        />
      </Paper>

      <SubmitButton companyBranch={companyBranch} />
    </Form>
  )
}

export default ProtectiveMaterialForm
