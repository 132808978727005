import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { type Place } from '../../../../common/place'

const CA_POSTAL_CODE_REGEX = /^[a-z][0-9][a-z] ?[0-9][a-z][0-9]$/i
const US_ZIP_CODE_REGEX = /^[0-9]{5}$/

export const usePlaceValidator = () => {
  const { t } = useTranslation()
  return Yup.object().test((value, { createError }) => {
    const place = value as Place

    /* make sure there is a place */
    if (!place) {
      return createError({ message: t('formValidation.required') })
    }

    /* if we have a google maps place, no need to validate an address */
    if ('placeId' in place) {
      return true
    }

    /* validate custom address */
    const hasCityAndRegion = !!place.city && !!place.region

    if (!place.postalCode && !hasCityAndRegion) {
      return createError({
        message: t('components.formFields.PlaceField.validations.incompleteAddress'),
      })
    }

    if (place.postalCode) {
      const country = place.country ?? 'CA'
      const regex = place.country.toUpperCase() === 'CA' ? CA_POSTAL_CODE_REGEX : US_ZIP_CODE_REGEX

      if (!regex.test(place.postalCode)) {
        if (country === 'US') {
          return createError({
            message: t('components.formFields.PlaceField.validations.invalidUsZipCode'),
          })
        } else {
          return createError({
            message: t('components.formFields.PlaceField.validations.invalidCanadianPostalCode'),
          })
        }
      }
    }

    return true
  })
}

export const useAddressValidation = () => {
  const { t } = useTranslation()

  const getPostalCodeRegex = (country: string, schema = Yup.string()) => country?.toUpperCase() === 'US'
    ? schema.matches(US_ZIP_CODE_REGEX, t('components.formFields.PlaceField.validations.invalidUsZipCode'))
    : schema.matches(CA_POSTAL_CODE_REGEX, t('components.formFields.PlaceField.validations.invalidCanadianPostalCode'))

  const postalCodeValidation = Yup.string().required().when(
    'country',
    ([country], schema) => getPostalCodeRegex(country, schema),
  )

  return Yup.object().shape({
    street: Yup.string().required(),
    city: Yup.string().required(),
    region: Yup.string().required(),
    postalCode: postalCodeValidation,
  })
}
