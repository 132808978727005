import { useState, useEffect } from 'react'
import { Droppable, type DroppableProps } from 'react-beautiful-dnd'

/**
 * react-beautiful-dnd is not compatible with React strict mode out of the box
 * This fixed Droppable component come from a GH issue by @Meligy
 * https://github.com/atlassian/react-beautiful-dnd/issues/2399#issuecomment-1175638194
 */
export const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false)

  useEffect(() => {
    const animation = requestAnimationFrame(() => { setEnabled(true) })

    return () => {
      cancelAnimationFrame(animation)
      setEnabled(false)
    }
  }, [])

  if (!enabled) {
    return null
  }

  return <Droppable {...props}>{ children }</Droppable>
}

export default StrictModeDroppable
