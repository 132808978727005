import { type FullMove } from '../../../../../modules/adminMoves'
import ActionsDropdown from '../ActionsDropdown'

type MobileTopSectionProps = {
  move: FullMove
}

const MobileTopSection: React.FC<MobileTopSectionProps> = ({ move }) => {
  return (
    <div className="mb-8">
      <ActionsDropdown move={move} />
    </div>
  )
}

export default MobileTopSection
