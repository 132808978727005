import { Fragment } from 'react'
import { Menu as HLMenu, Transition, type MenuButtonProps } from '@headlessui/react'

import { mergeClassName } from '../../utils/mergeClassName'

type MenuProps = {
  children?: React.ReactNode
  buttonContent?: React.ReactNode
} & MenuButtonProps<any>

const Menu: React.FC<MenuProps> = ({
  children,
  buttonContent,
  className,
  ...buttonProps
}) => {
  return (
    <HLMenu as="div" className="relative mx-2">
      <HLMenu.Button
        className={mergeClassName('flex max-w-xs items-center rounded-full text-sm focus:outline-none', className)}
        {...buttonProps}
      >
        { buttonContent }
      </HLMenu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <HLMenu.Items className="absolute right-0 z-50 mt-2 origin-top-right divide-y divide-gray-200 rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none">
          { children }
        </HLMenu.Items>
      </Transition>
    </HLMenu>
  )
}

export default Menu
