import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { toZonedTime } from 'date-fns-tz'
import Alert from '@mui/material/Alert'

import { type FullMove } from '../../../modules/adminMoves'
import { capitalizeFirstLetter } from '../../../utils/string'
import Checkpoint, { CheckpointType } from './Checkpoint'

const ONE_HOUR_MS = 60 * 60 * 1000

type MoveInfo = Pick<FullMove, 'originTimezone' | 'timeline' | 'stops'>

type MoveTimelineProps = {
  move: MoveInfo
}

const MoveTimeline: React.FC<MoveTimelineProps> = ({
  move,
}) => {
  const timeline = move.timeline
  const timezone = move.originTimezone

  const { t } = useTranslation()
  const now = new Date()

  if (!timeline || !timezone) {
    return null
  }

  const customerTimeZoneDiff = (
    toZonedTime(now, timezone).getTime() - now.getTime()
  ) / ONE_HOUR_MS

  return (
    <>
      { customerTimeZoneDiff !== 0 && (
        <Alert severity="info">
          { t(customerTimeZoneDiff > 0
            ? 'quotes.timeline.timezoneInfo.diffMinus'
            : 'quotes.timeline.timezoneInfo.diffPlus', {
            timezone,
            diff: Math.abs(customerTimeZoneDiff),
          }) }
        </Alert>
      ) }
      <div className="flex justify-center p-2 pl-6 lg:block lg:p-6 lg:pl-9">
        <ol className="relative border-l">
          { Object.entries(timeline)
            .filter(([_, time]) => !!time)
            .map(([step, time]) => {
              if (Array.isArray(time)) {
                /* stops */

                return time.map((time, index) => {
                  const description = move.stops?.[index]?.description
                  const stopDuration = move.stops?.[index]?.stopDuration
                  if (!description) {
                    return null
                  }

                  const label = (
                    <>
                      { capitalizeFirstLetter(description) }
                      { stopDuration && (
                        <span className="ml-2 inline-block text-xs text-neutral-400">
                          (~{ stopDuration } { t('units.hour') })
                        </span>
                      ) }
                    </>
                  )

                  const customerTime = toZonedTime(new Date(time), timezone)
                  const key = `stop-${index}`
                  return (
                    <Fragment key={key}>
                      <Checkpoint
                        type={CheckpointType.Location}
                        label={label}
                        time={customerTime}
                      />
                      <Checkpoint
                        type={CheckpointType.Transport}
                      />
                    </Fragment>
                  )
                })
              }

              const customerTime = toZonedTime(new Date(time), timezone)
              const label = t(`quotes.timeline.steps.${step}`, { defaultValue: '' })

              const isLocation = ['originArrivalTime', 'destinationArrivalTime'].includes(step)

              if (!label) {
                return null
              }

              return (
                <Fragment key={step}>
                  <Checkpoint
                    type={isLocation ? CheckpointType.Location : CheckpointType.Action}
                    label={label}
                    time={customerTime}
                    highlighted={isLocation}
                  />
                  { step === 'originDepartureTime' && (
                    <Checkpoint
                      type={CheckpointType.Transport}
                    />
                  ) }
                </Fragment>
              )
            }) }

        </ol>
      </div>
    </>
  )
}

export default MoveTimeline
