import MuiToggleButton, { type ToggleButtonProps as MuiToggleButtonProps } from '@mui/material/ToggleButton'

import { mergeClassName } from '../../utils/mergeClassName'

type ToggleButtonProps = {
  value?: any
  icon?: React.ReactNode
} & Omit<MuiToggleButtonProps, 'value'>

const ToggleButton: React.FC<ToggleButtonProps> = ({
  value = '',
  icon,
  className,
  children,
  ...props
}) => {
  return (
    <MuiToggleButton
      {...props}
      value={value}
    >
      <div className={mergeClassName(
        'mx-2 flex items-center gap-x-2 font-sans text-sm md:text-base normal-case leading-4 lg:text-lg lg:leading-[1.1rem]',
        className,
      )}
      >
        { icon }
        { children }
      </div>
    </MuiToggleButton>
  )
}

export default ToggleButton
