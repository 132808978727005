/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import DeleteIcon from '@mui/icons-material/Delete'

import { type Quote } from '../../../modules/quotes'
import QuoteComponent from './Quote'
import DraggableList from '../../DraggableList'
import Spinner from '../../Spinner'
import { type PromoCode } from '../../../modules/adminPromoCodes'

type QuotesProps = {
  quotes: Quote[]
  promoCode?: PromoCode
  onOrderChange?: (ids: string[]) => void | Promise<void>
  onDelete?: (ids: string[]) => void | Promise<void>
  loading?: boolean
  selectedBranch?: string
  ignoreBest?: boolean
}

/**
 * @deprecated use new Quotes component
 */
const Quotes: React.FC<QuotesProps> = ({
  quotes,
  promoCode,
  onOrderChange,
  onDelete,
  loading,
  selectedBranch,
  ignoreBest,
}) => {
  const { t } = useTranslation()
  const deletable = !!onDelete
  const supportLoading = typeof loading !== 'undefined'
  const showTopBar = deletable || supportLoading
  const [selectedIds, setSelectedIds] = useState<string[]>([])

  const allSelected = selectedIds.length === quotes.length

  const onSelect = (id: string) => {
    const index = selectedIds.indexOf(id)
    if (index === -1) {
      setSelectedIds([...selectedIds, id])
    } else {
      const newSelectedIds = [...selectedIds]
      newSelectedIds.splice(index, 1)
      setSelectedIds(newSelectedIds)
    }
  }

  const deleteSelected = async () => {
    await onDelete?.(selectedIds)?.catch(console.error)
    setSelectedIds([])
  }

  const deleteOne = async (id: string) => {
    await onDelete?.([id])?.catch(console.error)
    setSelectedIds([])
  }

  const toggleSelectAll = () => {
    const newSelection = allSelected ? [] : quotes.map(({ id }) => id)
    setSelectedIds(newSelection)
  }

  return (
    <>
      { showTopBar && (
        <div className="sticky top-[90px] z-[2] mb-1 mt-[-30px] bg-white md:top-[54px]">
          <div className="relative mr-1 min-h-[30px] py-2 md:py-4">
            { deletable && (
              <>
                <div className="inline pr-2">
                  <Checkbox
                    checked={allSelected}
                    onChange={toggleSelectAll}
                  />
                </div>
                <Button
                  variant="contained"
                  color="error"
                  disabled={selectedIds.length === 0}
                  onClick={deleteSelected}
                  size="small"
                  startIcon={<DeleteIcon />}
                >
                  { t('components.quotes.deleteSelected') }
                </Button>
              </>
            ) }
            { loading && (
              <div className="absolute right-0 top-2">
                <Spinner small />
              </div>
            ) }
          </div>
        </div>
      ) }

      <DraggableList
        name="quotes"
        data={quotes}
        onOrderChange={onOrderChange}
        disabled={!onOrderChange}
        className="flex flex-col gap-4"
      >
        { ({ id: renderId, isDragging }) => {
          const quote = quotes.find(({ id }) => id === renderId)
          if (!quote) {
            return null
          }
          return (
            <QuoteComponent
              quote={quote}
              promoCode={promoCode}
              isDragging={isDragging}
              onSelect={deletable ? () => { onSelect(quote.id) } : undefined}
              onDelete={deletable ? async () => { await deleteOne(quote.id) } : undefined}
              selected={deletable ? selectedIds.includes(quote.id) : undefined}
              customerSelected={!!selectedBranch && quote.companyBranch.id === selectedBranch}
              ignoreBest={ignoreBest}
            />
          )
        } }
      </DraggableList>
    </>
  )
}

export default Quotes
