import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import UserIcon from '@mui/icons-material/PersonOutlined'
import QuoteIcon from '@mui/icons-material/RequestQuoteOutlined'
import TruckIcon from '@mui/icons-material/LocalShippingOutlined'

import { type JobForPartner } from '../../../modules/jobs'
import SidebarItem from './SidebarItem'
import Customer from './Customer'
import Estimate from './Estimate'
import Jobs from './Jobs'

type JobDetailProps = {
  job: JobForPartner
}

const JobDetail: React.FC<JobDetailProps> = ({
  job,
}) => {
  const { t } = useTranslation()
  const estimateSection = useRef<HTMLDivElement>(null)
  const customerSection = useRef<HTMLDivElement>(null)
  const jobsSection = useRef<HTMLDivElement>(null)

  const scrollToSection = (object: React.RefObject<HTMLDivElement>) => {
    if (!object.current) {
      return
    }
    object.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div className="mr-4 mt-4 flex gap-x-4 pl-3 lg:pl-0">
      <div className="grow pt-4 child:-mt-4">
        <div ref={customerSection}>
          <Customer job={job} />
        </div>

        <div ref={estimateSection}>
          <Estimate job={job} />
        </div>

        <div ref={jobsSection}>
          <Jobs job={job} />
        </div>
      </div>

      <div className="shink-0 hidden lg:block">
        <div className="sticky top-4 flex flex-col gap-y-4 child:cursor-pointer">
          <div onClick={() => { scrollToSection(customerSection) }}>
            <SidebarItem icon={<UserIcon />}>
              { t('pages.companyBranch.job.sidebar.customer') }
            </SidebarItem>
          </div>

          <div onClick={() => { scrollToSection(estimateSection) }}>
            <SidebarItem icon={<QuoteIcon />}>
              { t('pages.companyBranch.job.sidebar.estimate') }
            </SidebarItem>
          </div>

          <div onClick={() => { scrollToSection(jobsSection) }}>
            <SidebarItem icon={<TruckIcon />}>
              { t('pages.companyBranch.job.sidebar.move') }
            </SidebarItem>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobDetail
