import { styled, lighten, darken } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

const GradientUnderline = styled(Typography)(({ theme }) => ({
  position: 'relative',
  display: 'inline-block',
  '&::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: -6,
    width: '100%',
    height: 2,
    background: `linear-gradient(135deg, ${lighten(theme.palette.primary.main, 0.2)} 0%, ${darken(theme.palette.primary.main, 0.2)} 100%);`,
  },
}))

export default GradientUnderline
