import { useTranslation } from 'react-i18next'
import SourceIcon from '@mui/icons-material/PublicOutlined'
import PlatformIcon from '@mui/icons-material/MonitorOutlined'

import { type FullMove } from '../../../../../modules/adminMoves'
import { capitalizeFirstLetter } from '../../../../../utils/string'
import Paper from '../../../../../components/Paper'

type SourceBoxProps = {
  move: FullMove
}

const SourceBox: React.FC<SourceBoxProps> = ({
  move,
}) => {
  const { t } = useTranslation()
  const utms = [move.utmSource, move.utmMedium, move.utmCampaign].filter(utm => !!utm).join(', ')

  return (
    <Paper
      title={t('pages.admin.move.sidebar.source.title')}
      titleIcon={<SourceIcon />}
      compact
    >
      <div className="flex gap-x-4">

        <PlatformIcon className="mt-1 !text-[22px] text-gray-400" />

        <div>
          <div className="text-lg text-neutral-700">
            { capitalizeFirstLetter(move.platform ?? 'MovingWaldo') }
          </div>

          { utms && (
            <div className="text-sm text-neutral-400">
              { utms }
            </div>
          ) }
        </div>
      </div>
    </Paper>
  )
}

export default SourceBox
