import TopSources from './TopSources'
import AcceptedQuotesValue from './AcceptedQuotesValue'
import TopCompanies from './TopCompanies'
import Conversions from './Conversions'

const MovesStats: React.FC = () => {
  return (
    <div>
      <AcceptedQuotesValue />

      <div className="mt-8 grid grid-cols-1 gap-x-5 xl:grid-cols-3">

        <Conversions />
        <TopCompanies />
        <TopSources />

      </div>
    </div>
  )
}

export default MovesStats
