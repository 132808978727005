import { useTranslation } from 'react-i18next'
import CalendarIcon from '@mui/icons-material/CalendarMonthOutlined'

import { type LightJobRequest } from '../../../modules/jobRequests'
import { useDateFormatter } from '../../../utils/date'

type MovingDateProps = {
  jobRequest: LightJobRequest
}

const MovingDate: React.FC<MovingDateProps> = ({ jobRequest }) => {
  const { t } = useTranslation()
  const formatDate = useDateFormatter()

  return (
    <div className="flex justify-center gap-2 text-center lg:min-w-[220px] lg:justify-start lg:text-left">
      <CalendarIcon className="mt-1 !text-[18px] text-neutral-400" />
      <div>
        <div className="text-lg text-neutral-500">
          { formatDate(jobRequest.movingDate) }
        </div>

        { jobRequest.movingTime && (
          <span className="text-sm text-neutral-400">
            { t(`arrivalWindows.${jobRequest.movingTime}.shortTitle`, { defaultValue: jobRequest.movingTime }) }
          </span>
        ) }
      </div>
    </div>
  )
}

export default MovingDate
