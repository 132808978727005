import { useTranslation } from 'react-i18next'

import { SpecialItem, SpecialService } from '../../../../../modules/companies'
import Paper from '../../../../Paper'
import { CollapsableIntegerCollectionField } from '../../../../form'

const SpecialItemsAndServicesFormContent: React.FC = () => {
  const { t } = useTranslation()

  const specialServicesOptions = Object.values(SpecialService).map(specialService => ({
    value: specialService,
    label: t(`specialServices.${specialService}.title`, { count: 2 }),
  }))

  const specialItemsOptions = Object.values(SpecialItem).map(specialItem => ({
    value: specialItem,
    label: t(`specialItems.${specialItem}.title`, { count: 2 }),
  }))

  return (
    <>
      <h2 className="mb-2 font-body2 text-lg font-bold lg:my-4">
        { t('forms.quotesRequest.specialServicesAndItems.title') }
      </h2>
      <Paper>
        <div className="mb-4 font-body2 font-semibold">
          { t('forms.quotesRequest.specialServicesAndItems.specialServices') }
        </div>

        <CollapsableIntegerCollectionField
          name="specialServices"
          options={specialServicesOptions}
          getLabel={(key, value) => t(`specialServices.${key}.title`, { count: value })}
          emptyValue={t('forms.quotesRequest.specialServicesAndItems.noSpecialServices')}
          max={99}
        />

        <div className="mb-4 mt-12 font-body2 font-semibold">
          { t('forms.quotesRequest.specialServicesAndItems.specialItems') }
        </div>

        <CollapsableIntegerCollectionField
          name="specialItems"
          options={specialItemsOptions}
          getLabel={(key, value) => t(`specialItems.${key}.title`, { count: value })}
          emptyValue={t('forms.quotesRequest.specialServicesAndItems.noSpecialItems')}
          max={99}
        />
      </Paper>
    </>
  )
}

export default SpecialItemsAndServicesFormContent
