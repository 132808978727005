import { ReactComponent as RegularLogo } from '../../assets/logo.svg'
import { ReactComponent as DarkLogo } from '../../assets/logoDark.svg'
import { useThemeState } from '../../modules/theme'

type LogoProps = React.SVGProps<SVGSVGElement> & {
  title?: string
}

const Logo: React.FC<LogoProps> = (props) => {
  const { darkModeEnabled } = useThemeState()

  if (darkModeEnabled) {
    return (
      <DarkLogo {...props} />
    )
  }
  return (
    <RegularLogo {...props} />
  )
}

export default Logo
