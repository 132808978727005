import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import DeleteIcon from '@mui/icons-material/Delete'
import RestoreIcon from '@mui/icons-material/RestoreFromTrash'
import EditIcon from '@mui/icons-material/Edit'

import { Permission, useCurrentUser, usePermission } from '../../../modules/users'
import {
  useDeleteUsersAction,
  useRestoreUsersAction,
  type AdminUser,
} from '../../../modules/adminUsers'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import { useConfirmation } from '../../../components/ConfirmationModal'

/**
 * returns the list of actions available inside of the data grid
 */
export const useActions = (users?: AdminUser[]) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { confirm, modalProps } = useConfirmation()
  const { data: currentUser } = useCurrentUser()
  const formatError = useErrorFormatter()
  const { deleteUsers } = useDeleteUsersAction()
  const { restoreUsers } = useRestoreUsersAction()
  const canEditUsers = usePermission(Permission.canEditUsers)
  const canPromoteUsers = usePermission(Permission.canPromoteUsers)

  if (!canEditUsers) {
    return {}
  }

  /**
   * edit user ; go to user's detail page
   */
  const handleEdit = (id: string) => { navigate(`/users/${id}`) }

  /**
   * delete user
   *  - show confirmation modal
   *  - show deletion results in a toast
   */
  const handleDelete = async (ids: string | string[]) => {
    ids = Array.isArray(ids) ? ids : [ids]
    const count = ids.length

    const title = t('pages.admin.users.actions.delete.confirmation.title', { count })
    const question = t('pages.admin.users.actions.delete.confirmation.question', { count })

    if (!await confirm({ title, question, danger: true })) {
      return false
    }

    const pending = t('pages.admin.users.actions.delete.pending', { count })
    const success = t('pages.admin.users.actions.delete.success', { count })

    await toast.promise(
      deleteUsers(ids),
      {
        pending,
        success,
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
  }

  /**
   * restore user
   *  - show confirmation modal
   *  - show restoration results in a toast
   */
  const handleRestore = async (ids: string | string[]) => {
    ids = Array.isArray(ids) ? ids : [ids]
    const count = ids.length

    const title = t('pages.admin.users.actions.restore.confirmation.title', { count })
    const question = t('pages.admin.users.actions.restore.confirmation.question', { count })

    if (!await confirm({ title, question })) {
      return false
    }

    const pending = t('pages.admin.users.actions.restore.pending', { count })
    const success = t('pages.admin.users.actions.restore.success', { count })

    await toast.promise(
      restoreUsers(ids),
      {
        pending,
        success,
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
  }

  /* format actions for data grid */

  const editAction = {
    label: t('actions.edit'),
    icon: EditIcon,
    callback: handleEdit,
    filter: (ids: string[]) => {
      const selected = users?.filter(user => ids.includes(user.id)) ?? []
      const elligibles = selected?.filter(user => {
        if (user.id === currentUser?.id) {
          return true
        }
        if (!canPromoteUsers && user.roles?.length > 0) {
          return false
        }
        return true
      })
      return elligibles?.length > 0 && selected?.length === elligibles?.length
    },
  }

  const deleteAction = {
    label: t('actions.delete'),
    icon: DeleteIcon,
    danger: true,
    callback: handleDelete,
    bulkCallback: handleDelete,
    filter: (ids: string[]) => {
      const selected = users?.filter(user => ids.includes(user.id)) ?? []
      const elligibles = selected?.filter(user => {
        if (!canPromoteUsers && user.roles?.length > 0) {
          return false
        }
        return !user.deletedAt && user.id !== currentUser?.id
      })
      return elligibles?.length > 0 && selected?.length === elligibles?.length
    },
  }

  const restoreAction = {
    label: t('actions.restore'),
    icon: RestoreIcon,
    callback: handleRestore,
    bulkCallback: handleRestore,
    filter: (ids: string[]) => {
      const selected = users?.filter(user => ids.includes(user.id)) ?? []
      const elligibles = selected?.filter(user => {
        if (!canPromoteUsers && user.roles?.length > 0) {
          return false
        }
        return !!user.deletedAt
      })
      return elligibles?.length > 0 && selected?.length === elligibles?.length
    },
  }

  /* returns actions and the props needed to render the confirmation modal */

  return {
    actions: [
      editAction,
      deleteAction,
      restoreAction,
    ],
    modalProps,
  }
}
