import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

const CA_POSTAL_CODE_REGEX = /^[a-z][0-9][a-z] ?[0-9][a-z][0-9]$/i
const US_ZIP_CODE_REGEX = /^[0-9]{5}$/

type validatePostalCodeProps = {
  country: string
  postalCode: string
}

export const validatePostalCode = ({ country, postalCode }: validatePostalCodeProps) => {
  const regex = country.toUpperCase() === 'CA' ? CA_POSTAL_CODE_REGEX : US_ZIP_CODE_REGEX
  return regex.test(postalCode)
}

/**
 * sometimes, we need to force a country on an address form.
 * if country is not provided, the actual country form value will be use to validate the postal code
 */
export const useAddressValidation = (country?: string) => {
  const { t } = useTranslation()

  let postalCodeValidation

  const getPostalCodeRegex = (country: string, schema = Yup.string()) => country?.toUpperCase() === 'US'
    ? schema.matches(US_ZIP_CODE_REGEX, t('components.formFields.AddressField.validations.invalidUsZipCode'))
    : schema.matches(CA_POSTAL_CODE_REGEX, t('components.formFields.AddressField.validations.invalidCanadianPostalCode'))

  if (country) {
    postalCodeValidation = getPostalCodeRegex(country)
  } else {
    postalCodeValidation = Yup.string().when(
      'country',
      ([country], schema) => getPostalCodeRegex(country, schema),
    )
  }

  return Yup.object().shape({
    street: Yup.string().required(),
    city: Yup.string().required(),
    region: Yup.string().required(),
    postalCode: postalCodeValidation,
  })
}
