import { useState, useEffect } from 'react'
import Cookies from 'js-cookie'

import { useConfig } from '../../config'
import { useExtendSessionAction } from '../core'

const COOKIE_POLLING_RATE_MS = 3000

const SessionExtender: React.FC = () => {
  const [sessionExpiration, setSessionExpiration] = useState<number>()
  const authenticatedCookieName = useConfig('users.authentication.authenticatedCookieName')
  const autoRenewThresholdSeconds = useConfig<number>('users.authentication.autoRenewThresholdSeconds')
  const { extendSession } = useExtendSessionAction()

  /**
   * Poll session expiration from cookie
   */
  useEffect(() => {
    const pollingInterval = setInterval(() => {
      const newExpiration = Number(Cookies.get(authenticatedCookieName))
      if (newExpiration > 0) {
        setSessionExpiration(newExpiration)
      }
    }, COOKIE_POLLING_RATE_MS)
    return () => { clearTimeout(pollingInterval) }
  }, [authenticatedCookieName])

  /**
   * Set a timeout to extend the session
   */
  useEffect(() => {
    if (!sessionExpiration) {
      return
    }
    const ttl = sessionExpiration - Date.now()
    const renewTime = ttl - (autoRenewThresholdSeconds * 1000)

    if (renewTime <= 0) {
      return
    }

    const renewTimeout = setTimeout(() => {
      extendSession().catch(console.error)
    }, renewTime)
    return () => { clearTimeout(renewTimeout) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionExpiration, autoRenewThresholdSeconds])

  return null
}

export default SessionExtender
