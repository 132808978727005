import { useEffect } from 'react'

import Spinner from '../../../components/Spinner'
import { useNavigate } from '../../navigation'
import { useAuthenticationState } from '../core'
import Route from '../../../app/Route.enum'

type ProtectedPageProps = {
  Page: React.FC
}

const VisitorOnlyPage: React.FC<ProtectedPageProps> = ({ Page }) => {
  const { isAuthenticated } = useAuthenticationState()
  const { navigate } = useNavigate()

  useEffect(() => {
    if (isAuthenticated) {
      navigate(Route.Dashboard)
    }
  }, [isAuthenticated, navigate])

  if (!isAuthenticated) {
    return <Page />
  } else {
    return <Spinner floating />
  }
}

export const visitorPage = (page: React.FC): React.FC => {
  const visitorOnlyPage = () => <VisitorOnlyPage Page={page} />
  return visitorOnlyPage
}
