import { type Role } from '../../../../modules/users'
import { RoleItem } from './RoleItem'

/**
 * return true if given role is already included by parent roles
 */
export const isIncluded = (roles: Role[], role: Role, currentRoles: string[]) => {
  const otherRoles = roles.filter(({ key }) => key !== role.key)
  const currentPermissions = otherRoles
    .filter(({ key }) => currentRoles.includes(key))
    .map(({ permissions }) => permissions)
    .flat()

  const includedPermissions = role.permissions.filter(p => currentPermissions.includes(p))
  return includedPermissions.length === role.permissions.length
}

/**
 * format options to show roles structure
 */
export const getRolesOptions = (roles?: Role[], currentRoles?: string[]) => {
  if (!roles) {
    return []
  }
  const processed: string[] = []
  const options: any = []

  const add = (role: Role, level = 0) => {
    if (processed.includes(role.key)) {
      return
    }
    processed.push(role.key)
    const included = isIncluded(roles, role, currentRoles ?? [])

    options.push({
      value: role.key,
      label: (<RoleItem><strong>{ role.name }</strong>{ role.description }</RoleItem>),
      props: {
        style: { marginLeft: 25 * level },
        disabled: included,
        indeterminate: included,
      },
    })
    roles.filter(r => r.parent === role.key).forEach(r => { add(r, level + 1) })
  }

  roles.forEach(r => { add(r, 0) })
  return options
}
