import { useTranslation } from 'react-i18next'

import {
  type UpdateImportedCalendarsPayload,
  useCurrentCompanyBranch,
  useExternalEvents,
  useSetImportedCalendarsAction,
} from '../../../modules/companies'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import { errorNotification, successNotification } from '../../../components/ToastNotifications'
import ImportCalendarsForm from '../../../components/forms/companyBranch/ImportCalendarsForm'

type ImportCalendarModalProps = {
  open: boolean
  onClose: () => void
}

const ImportCalendarModal: React.FC<ImportCalendarModalProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation()
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()
  const { refetch: reloadExternalEvents } = useExternalEvents()
  const { setImportedCalendars } = useSetImportedCalendarsAction()
  const formatError = useErrorFormatter()

  if (!currentCompanyBranch) {
    return null
  }

  const onSubmit = async (payload: UpdateImportedCalendarsPayload) => {
    try {
      await setImportedCalendars(payload)
      reloadExternalEvents && await reloadExternalEvents()
      onClose()
      successNotification(t('pages.companyBranch.jobs.importCalendarModal.updateSuccess'))
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  return (
    <ImportCalendarsForm
      open={open}
      onClose={onClose}
      companyBranch={currentCompanyBranch}
      onSubmit={onSubmit}
    />
  )
}

export default ImportCalendarModal
