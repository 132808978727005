import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { validatePassword, validateOptionalPassword } from './passwordValidator'

type PasswordValidatorOptions = {
  required?: boolean
}

const usePasswordValidator = () => {
  const { t } = useTranslation()

  return ({ required = false }: PasswordValidatorOptions = {}) => {
    const validator = required
      ? Yup.string().required(t('formValidation.required'))
      : Yup.string()

    return validator.test({
      name: 'validate-new-password',
      test: required ? validatePassword : validateOptionalPassword,
      message: String(t('forms.fields.password.errors.passwordTooWeak')),
    })
  }
}

export default usePasswordValidator
