import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormState } from 'react-hook-form'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'

import {
  Label,
  DateTime,
  SingleCheckbox,
  SelectWithCheckboxes,
  AddressField,
  Input,
  QuantityCollectionField,
} from '../../../form'
import { Cell, Row } from '../../../cell'
import { ArrivalWindow, ProtectiveMaterial, SpecialItem, SpecialService, Truck } from '../../../../modules/companies'
import Paper from '../../../Paper'

export const initialData = {
  flexibleDate: true,
  nbMen: '2',
  estimatedMovingLabourTime: '8',
  estimatedPackingLabourTime: '0',
  origin: { nbStairs: '0' },
  destination: { nbStairs: '0' },
}

const QuotesRequestFormContent: React.FC = () => {
  const { t } = useTranslation()
  const { defaultValues } = useFormState()

  const hasSpecialServices = Object.keys(defaultValues?.specialServices ?? {}).length > 0
  const hasSpecialItems = Object.keys(defaultValues?.specialItems ?? {}).length > 0
  const hasProtectiveMaterial = Object.keys(defaultValues?.protectiveMaterial ?? {}).length > 0

  const [showSpecialServices, setShowSpecialServices] = useState(hasSpecialServices || hasSpecialItems)
  const [showProtectiveMaterial, setShowProtectiveMaterial] = useState(hasProtectiveMaterial)

  const arrivalWindowsOptions = Object.values(ArrivalWindow).map(arrivalWindow => ({
    value: arrivalWindow,
    label: t(`arrivalWindows.${arrivalWindow}.title`),
  }))

  const trucksOptions = Object.values(Truck).map(truck => ({
    value: truck,
    label: t(`trucks.${truck}.title`),
  }))

  const specialServicesOptions = Object.values(SpecialService).map(specialService => ({
    value: specialService,
    label: t(`specialServices.${specialService}.title`),
  }))

  const specialItemsOptions = Object.values(SpecialItem).map(specialItem => ({
    value: specialItem,
    label: t(`specialItems.${specialItem}.title`),
  }))

  const protectiveMaterialOptions = Object.values(ProtectiveMaterial).map(protectiveMaterial => ({
    value: protectiveMaterial,
    label: t(`protectiveMaterial.${protectiveMaterial}.title`),
  }))

  return (
    <>
      <div className="flex flex-col lg:flex-row lg:gap-4">
        <div className="flex-1 lg:max-w-[400px]">
          <h2 className="mb-2 font-body2 text-lg font-bold lg:my-4">
            { t('forms.quotesRequest.dateAndTime.title') }
          </h2>
          <Paper>
            <div className="mb-4 flex gap-4">
              <Label label={t('forms.fields.movingDate.label')}>
                <DateTime name="movingDate" />
              </Label>
              <div className="pt-6">
                <SingleCheckbox
                  name="flexibleDate"
                  label={(
                    <div className="leading-tight">
                      { t('forms.quotesRequest.dateAndTime.flexibleDateLabel') }
                    </div>
                  )}
                />
              </div>
            </div>

            <Label label={t('forms.fields.preferredArrivalWindow.label')}>
              <SelectWithCheckboxes
                name="preferredTime"
                options={arrivalWindowsOptions}
                emptyLabel={t('forms.fields.preferredArrivalWindow.emptyLabel')}
              />
            </Label>

            <Label
              label={t('forms.fields.overnightFees.label')}
              className="mt-4"
            >
              <Input
                name="overnightFees"
                size="small"
                inputProps={{
                  maxLength: 2,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      { t('units.night_s') }
                    </InputAdornment>
                  ),
                }}
              />
            </Label>
          </Paper>
        </div>

        <div>
          <h2 className="mb-2 font-body2 text-lg font-bold lg:my-4">
            { t('forms.quotesRequest.nbMen.title') }
          </h2>
          <Paper>
            <Label label={t('forms.fields.nbMen.label')}>
              <Input
                name="nbMen"
                type="number"
                size="small"
                inputProps={{ min: 1, max: 99 }}
              />
            </Label>

            <Label
              label={t('forms.fields.estimatedMovingLabourTime.label')}
              className="mt-4"
            >
              <Input
                name="estimatedMovingLabourTime"
                size="small"
                inputProps={{
                  maxLength: 4,
                }}
                className="lg:max-w-[275px]"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      { t('units.hour_s') }
                    </InputAdornment>
                  ),
                }}
              />
            </Label>

            <Label
              label={t('forms.fields.estimatedPackingLabourTime.label')}
              className="mt-4"
            >
              <Input
                name="estimatedPackingLabourTime"
                size="small"
                inputProps={{
                  maxLength: 4,
                }}
                className="lg:max-w-[275px]"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      { t('units.hour_s') }
                    </InputAdornment>
                  ),
                }}
              />
            </Label>
          </Paper>
        </div>

        <div className="flex-1 lg:max-w-[710px]">
          <h2 className="mb-2 font-body2 text-lg font-bold lg:my-4">
            { t('forms.quotesRequest.nbTrucks.title') }
          </h2>
          <Paper>
            <QuantityCollectionField
              name="nbTrucks"
              options={trucksOptions}
              compact
            />
          </Paper>
        </div>
      </div>

      <Row>
        <Cell>
          <h2 className="mb-2 font-body2 text-lg font-bold lg:my-4">
            { t('forms.quotesRequest.origin.title') }
          </h2>
          <Paper>
            <AddressField name="origin.address" />

            <hr className="mb-6 mt-8" />

            <Label label={t('forms.fields.nbFlightsOfStairs.label')}>
              <Input
                name="origin.nbStairs"
                type="number"
                size="small"
                InputProps={{ className: '!w-[150px]' }}
                inputProps={{ min: 0, max: 9 }}
              />
            </Label>
          </Paper>
        </Cell>

        <Cell>
          <h2 className="mb-2 font-body2 text-lg font-bold lg:my-4">
            { t('forms.quotesRequest.destination.title') }
          </h2>
          <Paper>
            <AddressField name="destination.address" />

            <hr className="mb-6 mt-8" />

            <Label label={t('forms.fields.nbFlightsOfStairs.label')}>
              <Input
                name="destination.nbStairs"
                type="number"
                size="small"
                InputProps={{ className: '!w-[150px]' }}
                inputProps={{ min: 0, max: 9 }}
              />
            </Label>
          </Paper>
        </Cell>
      </Row>

      <Row>
        <Cell>

          <h2 className="mb-2 font-body2 text-lg font-bold lg:my-4">
            { t('forms.quotesRequest.specialServicesAndItems.title') }
          </h2>
          <Paper>
            { !showSpecialServices && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => { setShowSpecialServices(true) }}
              >
                { t('actions.add') }
              </Button>
            ) }
            <div className={!showSpecialServices ? 'h-0 opacity-0' : undefined}>
              <div className="mb-2 ml-2 font-body2 font-semibold">
                { t('forms.quotesRequest.specialServicesAndItems.specialServices') }
              </div>

              <QuantityCollectionField
                name="specialServices"
                options={specialServicesOptions}
                compact
                optionClassName="lg:w-[calc(50%-0.5rem)]"
                labelClassName="whitespace-normal text-left"
              />

              <div className="mb-2 ml-2 mt-6 font-body2 font-semibold">
                { t('forms.quotesRequest.specialServicesAndItems.specialItems') }
              </div>

              <QuantityCollectionField
                name="specialItems"
                options={specialItemsOptions}
                compact
                optionClassName="lg:w-[calc(50%-0.5rem)]"
                labelClassName="whitespace-normal text-left"
              />
            </div>
          </Paper>

        </Cell>

        <Cell>
          <h2 className="mb-2 font-body2 text-lg font-bold lg:my-4">
            { t('forms.quotesRequest.protectiveMaterial.title') }
          </h2>
          <Paper>
            { !showProtectiveMaterial && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => { setShowProtectiveMaterial(true) }}
              >
                { t('actions.add') }
              </Button>
            ) }
            <div className={!showProtectiveMaterial ? 'h-0 opacity-0' : undefined}>
              <QuantityCollectionField
                name="protectiveMaterial"
                options={protectiveMaterialOptions}
                compact
                optionClassName="lg:w-[calc(50%-0.5rem)]"
                labelClassName="whitespace-normal text-left"
              />
            </div>
          </Paper>
        </Cell>
      </Row>
    </>
  )
}

export default QuotesRequestFormContent
