import { useMemo } from 'react'

import { type CalendarRule, type CalendarRuleEvent } from '../Calendar.type'

const useCalendarRuleEvents = (rules?: CalendarRule[]): CalendarRuleEvent[] | undefined => {
  return useMemo(() => {
    return rules?.map(rule => ({
      title: rule.title,
      start: rule.start,
      end: rule.end ?? rule.start,
      allDay: true,
      resource: {
        id: rule.id,
        color: rule.color,
        order: rule.priority,
      },
    }))
  }, [rules])
}

export default useCalendarRuleEvents
