/* eslint-disable @typescript-eslint/no-misused-promises */
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFieldArray, useFormContext } from 'react-hook-form'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/AddCircleOutline'

import {
  type ChargeCategory,
  ChargeDescription,
} from '../../../../modules/invoices'
import { type PromoCode } from '../../../../modules/adminPromoCodes'
import { type Currency } from '../../../../common/amount'
import { mergeClassName } from '../../../../utils/mergeClassName'
import { getDefaultValues } from './ChargeRow/ChargeRow.utils'
import { RowType } from './ChargeRow/ChargeRow.enum'
import { sortFieldsByCategory } from './ChargesFormContent.utils'
import { type Step, steps } from '../../../companyBranch'
import Subtotal from './Subtotal'
import {
  type MoveSize,
  type ChargesFormValue,
} from './ChargesFormContent.type'
import ChargeRow from './ChargeRow'

type ChargesFormContentProps = {
  currency: Currency
  editAll?: boolean
  promoCode?: PromoCode
  moveSize?: MoveSize
  minRows?: number
  showSubtotal?: boolean
}

const ChargesFormContent: React.FC<ChargesFormContentProps> = ({
  currency,
  editAll = true,
  promoCode,
  moveSize,
  minRows = 0,
  showSubtotal = true,
}) => {
  const { t } = useTranslation()

  const form = useFormContext<ChargesFormValue>()
  const {
    fields,
    append,
    remove,
    move,
  } = useFieldArray({ control: form.control, name: 'charges' })
  const [autoFocus, setAutoFocus] = useState(false)

  const nbRows = fields.length

  /* sort fields by category and keep original rhf index */
  const rowsByCategory = sortFieldsByCategory(fields)

  /**
   * add new row
   */
  const addRow = (category: ChargeCategory) => {
    setAutoFocus(true)
    append({
      categoryAndDescription: `${category}.${ChargeDescription.customCharge}`,
      ...getDefaultValues(moveSize, RowType.Custom),
    }, {
      shouldFocus: false,
    })
  }

  /**
   * this force a refresh on the sorting when a category is changed
   */
  const handleCategoryChange = (index: number) => {
    setAutoFocus(true)
    const newPosition = nbRows - 1
    move(index, newPosition)
  }

  return (
    <table className="w-full">
      <tbody>
        <tr className="text-sm text-neutral-400">
          <td>
            { t('forms.invoice.charges.columns.typeOfCharge') }
          </td>
          <td>
            { t('forms.invoice.charges.columns.description') }
          </td>
          <td>
            { t('forms.invoice.charges.columns.quantity') }
          </td>
          <td>
            { t('forms.invoice.charges.columns.unitPrice') }
          </td>
          <td>
            { t('forms.invoice.charges.columns.taxable') }
          </td>
          <td className="px-4 text-right">
            { t('forms.invoice.charges.columns.subtotal') }
          </td>
          <td />
        </tr>

        { Object.entries(rowsByCategory).map(([rawCategory, rows], index) => {
          const category = rawCategory as ChargeCategory
          const CategoryIcon = steps[category as unknown as Step]?.Icon
          const categoryName = t(`quotes.pricing.categories.${category}`)

          return (
            <Fragment key={category}>
              <tr>
                <td
                  colSpan={7}
                  className={mergeClassName(
                    'pt-6 font-sans text-lg font-bold text-neutral-600',
                    index === 0 && 'pt-2',
                  )}
                >
                  { CategoryIcon && <CategoryIcon /> }
                  { ' ' }
                  { categoryName }
                </td>
              </tr>
              { rows.map(([field, index]) => (
                <ChargeRow
                  key={field.id}
                  index={index}
                  currency={currency}
                  moveSize={moveSize}
                  onDelete={nbRows > minRows ? () => { remove(index) } : undefined}
                  onCategoryChange={() => { handleCategoryChange(index) }}
                  autoFocus={autoFocus}
                  editAll={editAll}
                />
              )) }
              <tr>
                <td colSpan={5} className="pt-1">
                  <Button
                    color="secondary"
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => { addRow(category) }}
                    size="small"
                  >
                    { t('actions.add') }
                  </Button>
                </td>
                <td className="whitespace-nowrap px-4 text-right text-neutral-800">
                  <Subtotal
                    category={category}
                    currency={currency}
                  />
                </td>
                <td />
              </tr>
            </Fragment>
          )
        }) }

        { showSubtotal && (
          <tr>
            <td colSpan={5} />

            <td className="px-4 text-right">
              { promoCode && (
                <div className="whitespace-nowrap text-base font-bold text-neutral-300 line-through">
                  <Subtotal
                    currency={currency}
                  />
                </div>
              ) }

              <div className="whitespace-nowrap text-lg font-bold text-neutral-900">
                <Subtotal
                  currency={currency}
                  promoCode={promoCode}
                  min={0}
                />
              </div>
            </td>

            <td />

          </tr>
        ) }
      </tbody>
    </table>
  )
}

export default ChargesFormContent
