import { type LazyQueryHookOptions, useLazyQuery } from '@apollo/client'

import { type QueryResult } from '../../graphQl'
import { type DetailedQuoteRequest } from '../../adminMoves'
import { type QuotesRequestPayload, type QuotesResponse } from './quotes.types'
import * as queries from './quotes.queries'
import { ignoreTimezone } from '../../../utils/date'

const QUOTE_REQUEST_KEY = 'ib:quotesRequestV2'

/**
 * save quotes request for the current session
 */
export const useSaveQuotesRequestAction = () => {
  return (quotesRequest: Omit<DetailedQuoteRequest, 'residenceType'>) => {
    sessionStorage.setItem(
      QUOTE_REQUEST_KEY,
      JSON.stringify({
        ...quotesRequest,
        residenceType: undefined,
      }))
  }
}

/**
 * get the current quotes request if any
 */
export const useQuotesRequest = (): DetailedQuoteRequest | undefined => {
  try {
    const request = sessionStorage.getItem(QUOTE_REQUEST_KEY)
    if (request) {
      const parsedRequest = JSON.parse(request)
      if (parsedRequest.movingDate) {
        parsedRequest.movingDate = ignoreTimezone(parsedRequest.movingDate)
      }
      return parsedRequest
    }
  } catch (error) {
    console.error(error)
  }
}

type FetchQuotesOptions = { reload: boolean } & LazyQueryHookOptions

type UseQuotesHookResult = {
  fetchQuotes: (quotesRequest: QuotesRequestPayload, options?: FetchQuotesOptions) => Promise<QueryResult<QuotesResponse>>
} & QueryResult<QuotesResponse>

/**
 * fetch quotes (lazy query)
 */
export const useQuotes = (): UseQuotesHookResult => {
  const [fetch, { data, networkStatus, loading, error, ...queryProps }] = useLazyQuery(
    queries.getDetailedQuotes,
  )
  const fetchQuotes = async (quotesRequest?: QuotesRequestPayload, { reload = false, ...queryArgs } = {}) => {
    const fetchPolicy = reload ? 'network-only' : 'cache-first'
    const { data, error } = await fetch({
      variables: { quotesRequest },
      fetchPolicy,
      ...queryArgs,
    })

    if (error) {
      throw new Error(error.message)
    }
    return data?.admin_getDetailedQuotes
  }

  return {
    fetchQuotes,
    data: data?.admin_getDetailedQuotes,
    loading,
    error,
    ...queryProps,
  }
}
