import { mergeClassName } from '../../../utils/mergeClassName'
import Paper from '../../Paper'

type CalendarSidebarProps = {
  children?: React.ReactNode
  compact?: boolean
  className?: string
}

const CalendarSidebar: React.FC<CalendarSidebarProps> = ({
  children,
  compact = false,
  className,
}) => {
  return (
    <Paper
      className={mergeClassName(
        'mb-0 3xl:mb-0 shrink-0 pb-3 child:p-0 xl:w-[400px] lg:pb-0 child:lg:p-0 2xl:w-[500px] xl:h-[calc(100vh-465px)] overflow-auto',
        compact && 'xl:w-auto 2xl:w-auto child:lg:pb-3',
        className,
      )}
    >
      { children }
    </Paper>
  )
}

export default CalendarSidebar
