import { type DashboardMetricsCollection, toNumbers } from '../../modules/dashboard'
import { mergeClassName } from '../../utils/mergeClassName'
import MinimalistAreaChart from './MinimalistAreaChart'

type CardProps = {
  history?: DashboardMetricsCollection
  icon?: React.ReactNode
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const Card: React.FC<CardProps> = ({
  history,
  icon,
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={mergeClassName(
        'relative overflow-hidden flex items-baseline gap-x-4 gap-y-2 px-4 py-10 sm:px-6 xl:px-8 border rounded-lg shadow cursor-default',
        className,
      )}
      {...props}
    >
      { icon && (
        <div className="text-gray-300 child:!text-[24px] sm:-ml-2">
          { icon }
        </div>
      ) }

      <div className="h-full grow">
        { children }
      </div>

      { history && (
        <div className="absolute -left-px bottom-0 z-[-1] h-3/5 w-[calc(100%+2px)] opacity-40 hover:opacity-60">
          <MinimalistAreaChart
            data={toNumbers(history)}
          />
        </div>
      ) }

    </div>
  )
}

export default Card
