import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { type CompanyInvitationFormStep1Payload } from './CompanyInvitationFormStep1.type'
import { Form, Input, SubmitButton } from '../../../../form'
import useValidationSchema from './validationSchema'

type CompanyInvitationFormStep1Props = {
  onSubmit: (payload: CompanyInvitationFormStep1Payload) => Promise<any>
}

const CompanyInvitationFormStep1: React.FC<CompanyInvitationFormStep1Props> = ({
  onSubmit,
}) => {
  const { t } = useTranslation()
  const schema = useValidationSchema()

  const form = useForm({
    resolver: yupResolver(schema),
  })

  return (
    <Form form={form} onSubmit={onSubmit}>
      <h3 className="mb-2 font-body2 text-lg">
        { t('pages.admin.companies.branchPanel.addUserTitle') }
      </h3>

      <div className="flex w-full flex-col lg:flex-row lg:gap-2">
        <div className="w-full">
          <Input
            name="email"
            size="small"
            placeholder={t('forms.fields.email.label')}
          />
        </div>
        <div className="shrink-0 justify-end py-4 lg:py-0">
          <SubmitButton>
            { t('actions.invitePartner') }
          </SubmitButton>
        </div>
      </div>
    </Form>
  )
}

export default CompanyInvitationFormStep1
