import { type Event } from 'react-big-calendar'

export type CalendarContextType = {
  availabilities?: CalendarAvailability[]
  content?: CalendarContent[]
  view?: string
  eventsByDay?: Record<string, number>
}

export type CalendarRule = {
  id: string
  title?: React.ReactNode
  start: Date
  end?: Date
  color: string
  priority: number
}

export interface CalendarRuleEvent extends Event {
  resource: {
    id: string
    color: string
    order: number
  }
}

export enum CalendarAvailabilityRange {
  Am = 'am',
  Pm = 'pm',
  Day = 'day',
}

export type CalendarAvailability = {
  id: string
  title?: React.ReactNode
  start: Date
  end?: Date
  range: CalendarAvailabilityRange
}

export type CalendarContent = {
  date: Date
  content?: React.ReactNode
}
