import { mergeClassName } from '../../../utils/mergeClassName'

type CalendarSidebarSubtitleProps = {
  children?: React.ReactNode
  className?: string
}

const CalendarSidebarSubtitle: React.FC<CalendarSidebarSubtitleProps> = ({
  className,
  children,
}) => {
  return (
    <div className={mergeClassName(
      'my-4 border-b px-4 font-body2 text-sm text-gray-600',
      className,
    )}
    >
      { children }
    </div>
  )
}

export default CalendarSidebarSubtitle
