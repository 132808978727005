import { type Reducer } from 'redux'
import { persistReducer as reduxPersistReducer } from 'redux-persist'
import { type PersistedState } from 'redux-persist/es/types'
import localStorageStorage from 'redux-persist/lib/storage'

type PersistRecucerOptions = {
  key: string
  version: number
  persistFields?: string[]
  migrate?: (state: any, currentVersion?: number) => Promise<any>
}

const performMigration = (migrate: any) => async (state: PersistedState, currentVersion: number) => {
  const version = state?._persist.version
  /* istanbul ignore if  */
  if (currentVersion === version) {
    return state
  }

  return migrate(state, version)
}

const persistReducer = (
  reducer: Reducer<any, any>,
  {
    key,
    version,
    persistFields,
    migrate,
  }: PersistRecucerOptions,
) => reduxPersistReducer(
  {
    storage: localStorageStorage,
    key,
    version,
    whitelist: persistFields,
    migrate: migrate ? performMigration(migrate) : undefined,
  },
  reducer,
)

export default persistReducer
