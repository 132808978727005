import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { useRecordValidation } from '../../../../utils/validation'
import { type ServicesFormValues } from './ServicesForm.type'

const useValidationSchema = () => {
  const { t } = useTranslation()
  const recordValidation = useRecordValidation()

  return Yup.object<ServicesFormValues>({
    services: Yup
      .array()
      .min(1, t('forms.companyBranch.services.validationErrors.youMustSelectAtLEastOneService'))
      .required(t('forms.companyBranch.services.validationErrors.youMustSelectAtLEastOneService')),
    nbTrucks: recordValidation(
      Yup.string().optional().matches(
        /^[0-9]{0,2}$/,
        t('forms.companyBranch.services.validationErrors.invalidTruckQuantity'),
      ),
    ),
    nbTrucksTotal: Yup.number()
      .min(1, t('forms.companyBranch.services.validationErrors.invalidTotalTrucks'))
      .required(t('forms.companyBranch.services.validationErrors.invalidTotalTrucks')),
    arrivalWindows: Yup
      .array()
      .min(1, t('forms.companyBranch.services.validationErrors.youMustSelectAtLEastOneWindowArrival'))
      .required(t('forms.companyBranch.services.validationErrors.youMustSelectAtLEastOneWindowArrival')),
  }) as Yup.ObjectSchema<ServicesFormValues, ServicesFormValues>
}

export default useValidationSchema
