import CheckIcon from '@mui/icons-material/CheckCircleOutlineOutlined'

import { mergeClassName } from '../../../utils/mergeClassName'
import Link, { type LinkProps } from '../../Link/Link'

type SidebarMenuLinkProps = {
  Icon?: React.FC<{ className?: string }>
  completed?: boolean
  disabled?: boolean
} & LinkProps

const SidebarMenuLink: React.FC<SidebarMenuLinkProps> = ({
  children,
  Icon,
  completed = false,
  className,
  ...linkProps
}) => {
  return (
    <Link
      {...linkProps}
      className={mergeClassName(
        'flex items-center gap-2 p-2 text-gray-600 hover:text-gray-800 lg:text-lg/snug [&.current]:font-bold [&.current]:text-black',
        completed && 'text-green-900 [&.current]:text-green-900',
        className,
      )}
    >
      { Icon && <Icon className="!text-[18px] text-gray-400" /> }
      { children }
      { completed && <CheckIcon className="ml-1 !text-[20px] text-green-600" /> }
    </Link>
  )
}

export default SidebarMenuLink
