import { useTranslation } from 'react-i18next'
import Chip from '@mui/material/Chip'

import { mergeClassName } from '../../utils/mergeClassName'
import { JobRequestStatus } from '../../modules/jobRequests'

type JobRequestStatusTagProps = {
  jobRequestStatus: JobRequestStatus
  className?: string
}

const JobRequestStatusTag: React.FC<JobRequestStatusTagProps> = ({
  jobRequestStatus,
  className,
}) => {
  const { t } = useTranslation()
  const statusTitle = t(`jobRequest.status.${jobRequestStatus}`)

  return (
    <Chip
      className={mergeClassName(
        jobRequestStatus === JobRequestStatus.Pending && '!bg-orange-300',
        jobRequestStatus === JobRequestStatus.Expired && '!bg-red-800 !text-white',
        jobRequestStatus === JobRequestStatus.Cancelled && '!bg-orange-600 !text-orange-100',
        jobRequestStatus === JobRequestStatus.Accepted && '!bg-green-800 !text-white',
        jobRequestStatus === JobRequestStatus.Declined && '!bg-red-800 !text-white',
        className,
      )}
      label={statusTitle}
      size="small"
    />
  )
}

export default JobRequestStatusTag
