/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { type FullPromoCode, type PromoCodePayload } from '../../../../modules/adminPromoCodes'

export const promoCodeToFormData = (promoCode: FullPromoCode): PromoCodePayload => {
  return {
    code: promoCode.code,
    value: promoCode.value,
    type: promoCode.type,
  }
}

export const formatFornOutput = (data: PromoCodePayload): PromoCodePayload => {
  return {
    ...data,
    value: parseFloat(String(data.value)),
  }
}
