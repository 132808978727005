export enum RuleTemporalityType {
  Date = 'date',
  DateRange = 'dateRange',
  RecurrentRange = 'recurrentRange',
  EveryWeekDay = 'everyWeekDay',
}

export type RuleTemporalityDate = {
  type: RuleTemporalityType.Date
  date: Date
}

export type RuleTemporalityDateRange = {
  type: RuleTemporalityType.DateRange
  from: Date
  to: Date
}

export type RuleTemporalityRecurrentRange<T = number> = {
  type: RuleTemporalityType.RecurrentRange
  fromDay: T
  toDay: T
  months?: T[]
}

export type RuleTemporalityEveryWeekDay<T = number> = {
  type: RuleTemporalityType.EveryWeekDay
  days: T[] // ISO-8601 ; monday = 1, ...
  months?: T[]
}

export type RuleTemporality<T = number> = {
  type: RuleTemporalityType
} & RuleTemporalityDate | RuleTemporalityDateRange | RuleTemporalityRecurrentRange<T> | RuleTemporalityEveryWeekDay<T>

export enum RuleTemporalityRange {
  Am = 'am',
  Pm = 'pm',
  Day = 'day',
}

export type AvailabilityRule<T = number> = {
  id: string
  title?: string
  temporality: RuleTemporality<T>
  range: RuleTemporalityRange
}

export type PricingRule<T = number> = {
  id: string
  title?: string
  temporality: RuleTemporality<T>
  priority: number

  movingLabour: T[]
  movingLabourExtraMen: T
  movingLabourSingleMan?: T

  packingLabour?: T[]
  packingLabourExtraMen?: T
  packingLabourSingleMan?: T
}
