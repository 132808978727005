import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { mergeClassName } from '../../utils/mergeClassName'
import { formatNumber } from '../../utils/number'

type CardMetricDiffProps = {
  diff?: number
  lowerIsBetter?: boolean
} & Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'children'>

const CardMetricDiff: React.FC<CardMetricDiffProps> = ({
  diff,
  lowerIsBetter,
  className,
  ...props
}) => {
  if (diff === undefined) {
    return null
  }

  const neutral = diff === 0
  const positive = lowerIsBetter ? diff < 0 : diff > 0
  const negative = !neutral && !positive

  return (
    <div
      className={mergeClassName(
        'flex items-center bg-white/50 text-lg pr-4',
        positive && 'text-green-700',
        negative && 'text-red-700',
        className,
      )}
    >
      { positive && (<ArrowUpIcon className="!text-[32px]" />) }
      { negative && (<ArrowDownIcon className="!text-[32px]" />) }

      { positive && (
        <span>
          + { formatNumber(diff) } %
        </span>
      ) }
      { negative && (
        <span>
          - { formatNumber(Math.abs(diff)) } %
        </span>
      ) }
    </div>
  )
}

export default CardMetricDiff
