import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/AddCircleOutline'
import RemoveIcon from '@mui/icons-material/CancelOutlined'
import SingleManIcon from '@mui/icons-material/PersonOutlineOutlined'
import MenIcon from '@mui/icons-material/GroupOutlined'
import ExtraMenIcon from '@mui/icons-material/GroupAddOutlined'

import {
  type CompanyBranch,
  type CompanyBranchPricing,
  useCompanyBranchCurrency,
} from '../../../../modules/companies'
import { ToggleSwitch } from '../../../form'
import Paper from '../../../Paper/Paper'
import PricesInput from '../../../companyBranch/PricesInput'
import { MAX_ROWS } from './LabourForm.const'
import { getCurrencyDetails } from '../../../../utils/money/getCurrencyDetails'

type PricingValues = Array<CompanyBranchPricing<string>>

type PricingByMenProps = {
  name: string
  companyBranch?: CompanyBranch
}

const PricingByMen: React.FC<PricingByMenProps> = ({
  name,
  companyBranch,
}) => {
  const supportSingleManName = `${name}SupportSingleMan`
  const singleManName = `${name}SingleMan`
  const extraMenName = `${name}ExtraMen`

  const { t } = useTranslation()
  const form = useFormContext()
  const pricingValues = useWatch({ name }) as PricingValues
  const supportSingleMan = useWatch({ name: supportSingleManName }) as boolean
  const nbRows = pricingValues?.length ?? 0
  const currency = useCompanyBranchCurrency(companyBranch)
  const currencySymbol = getCurrencyDetails(currency).symbol

  useEffect(() => {
    if (!supportSingleMan) {
      form.unregister(singleManName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportSingleMan])

  const addRow = () => {
    const values = [...pricingValues]
    values.push({ basePrice: '' })
    form.setValue(name, values)
  }

  const removeLastRow = () => {
    form.unregister(`${name}.${nbRows - 1}`)
  }

  return (
    <Paper className="bg-zinc-50">
      <Paper className="mb-6">
        <ToggleSwitch
          name={supportSingleManName}
          label={(
            <h3 className="font-body2 text-[1.2rem] font-light leading-7">
              <SingleManIcon className="mr-2 text-gray-400" />
              { t('forms.companyBranch.labour.singleMan') }
            </h3>
          )}
        />

        { supportSingleMan && (
          <div className="mt-4">
            <PricesInput
              companyBranch={companyBranch}
              prefixName={singleManName}
              basePriceRequired
              endAdornment={t('units.pricePerHour', { currencySymbol })}
            />
          </div>
        ) }
      </Paper>

      { pricingValues?.map((_, index) => {
        const nbMen = index + 2

        return (
          <Paper key={nbMen} className="mb-6">
            <h3 className="mb-4 font-body2 text-[1.2rem] font-light leading-7">
              <MenIcon className="mr-2 text-gray-400" />
              { t('forms.companyBranch.labour.men', { count: nbMen }) }
              { index === nbRows - 1 && nbMen !== 2 && (
                <IconButton
                  color="error"
                  onClick={removeLastRow}
                >
                  <RemoveIcon />
                </IconButton>
              ) }
            </h3>
            <PricesInput
              companyBranch={companyBranch}
              prefixName={`${name}.${index}`}
              basePriceRequired
              endAdornment={t('units.pricePerHour', { currencySymbol })}
            />
          </Paper>
        )
      }) }

      <div className="mb-6">
        <Button
          color="secondary"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={addRow}
          disabled={nbRows >= MAX_ROWS}
        >
          { t('forms.companyBranch.labour.addRow') }
        </Button>
      </div>

      <Paper>
        <h3 className="mb-4 font-body2 text-[1.2rem] font-light leading-7">
          <ExtraMenIcon className="mr-2 text-gray-400" />
          { t('forms.companyBranch.labour.extraMen', { count: nbRows + 1 }) }
        </h3>
        <PricesInput
          companyBranch={companyBranch}
          prefixName={extraMenName}
          basePriceRequired
          endAdornment={t('units.pricePerHour', { currencySymbol })}
        />
      </Paper>
    </Paper>
  )
}

export default PricingByMen
