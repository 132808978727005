import { useFormContext, useWatch } from 'react-hook-form'
import Checkbox from '@mui/material/Checkbox'
import Alert from '@mui/material/Alert'

import { mergeClassName } from '../../../../utils/mergeClassName'

export type CardCheckboxGroupProps = {
  name: string
  options: Record<string, React.ReactNode>
  className?: string
}

const CardCheckboxGroup: React.FC<CardCheckboxGroupProps> = ({
  name,
  options,
  className,
}) => {
  const { setValue, formState: { errors, submitCount, defaultValues } } = useFormContext()
  const value: string[] = useWatch({ name }) ?? []
  const formErrors = errors[name]
  const editing = !!defaultValues?.[name]
  const shouldValidate = submitCount > 0 || editing

  const onClick = (selectedValue: string) => {
    if (value.includes(selectedValue)) {
      setValue(name, [...value.filter(v => v !== selectedValue)], { shouldValidate })
    } else {
      setValue(name, [...value, selectedValue], { shouldValidate })
    }
  }

  return (
    <>
      <div className={mergeClassName(
        'flex flex-col md:flex-row md:flex-wrap gap-4 lg:gap-6',
        className,
      )}
      >
        { Object.entries(options).map(([name, label]) => {
          const checked = value.includes(name)
          return (
            <label
              key={name}
              className={mergeClassName(
                'flex md:basis-[calc(50%-0.5rem)] lg:basis-[calc(50%-0.75rem)] items-center rounded-md bg-gray-100 p-4 text-gray-600 cursor-pointer',
                checked && 'bg-zinc-200 text-black child:text-black',
              )}
            >
              <Checkbox
                checked={checked}
                onClick={() => { onClick(name) }}
              />
              { label }
            </label>
          )
        }) }
      </div>

      { typeof formErrors?.message === 'string' && (
        <div className="mt-4 lg:mt-6">
          <Alert severity="error">
            { formErrors.message }
          </Alert>
        </div>
      ) }
    </>
  )
}

export default CardCheckboxGroup
