import { useTranslation } from 'react-i18next'

import { type FullMove } from '../../../modules/adminMoves'
import { type FullMoveForPartner, useMoveState } from '../../../modules/moves'
import { formatPhoneNumber } from '../../../common/phoneNumber'
import { capitalizeFirstLetter } from '../../../utils/string'
import { useDateFormatter } from '../../../utils/date'
import { Cell, Label, Row, Value } from '../../cell'
import Link, { LinkVariant } from '../../Link'
import TimeAgo from '../../TimeAgo'

type CustomerDetailsProps = {
  move: FullMove | FullMoveForPartner
}

const CustomerDetails: React.FC<CustomerDetailsProps> = ({
  move,
}) => {
  const { t } = useTranslation()
  const formatDate = useDateFormatter()
  const {
    timezoneDiff,
    customerTime,
  } = useMoveState().forMove(move)

  const adminView = 'email' in move.user

  return (
    <>
      <Row>
        <Cell>

          <Label>
            { t('move.customer.fullName') }
          </Label>
          <Value>
            { capitalizeFirstLetter(move.user.firstName, true) }
            { ' ' }
            { capitalizeFirstLetter(move.user.lastName, true) }
          </Value>
        </Cell>

        { 'email' in move.user && (
          <Cell>
            <Label>
              { t('forms.fields.email.label') }
            </Label>
            <Value>
              <Link to={`mailto:${move.user.email}`} variant={LinkVariant.Primary}>
                { move.user.email }
              </Link>
            </Value>
          </Cell>
        ) }

        <Cell>
          <Label>
            { t('forms.fields.phoneNumber.label') }
          </Label>
          <Value>
            <Link to={`tel:${move.user.phoneNumber.number}`} variant={LinkVariant.Primary}>
              { formatPhoneNumber(move.user.phoneNumber) }
            </Link>
          </Value>
        </Cell>

        <Cell>
          <Label>
            { t('move.customer.language') }
          </Label>
          { move.user.language && (
            <Value>
              { t(`languages.${move.user.language}`) }
            </Value>
          ) }
        </Cell>

        { !adminView && <Cell /> }

      </Row>
      <Row>
        { 'createdAt' in move && (
          <Cell>
            <Label>
              { t('move.customer.createdAt') }
            </Label>
            <Value>
              { formatDate(move.createdAt) }
              <span className="inline-block pl-2 text-sm text-neutral-400">
                (<TimeAgo date={move.createdAt} />)
              </span>
            </Value>
          </Cell>
        ) }

        <Cell>
          <Label>
            { t('move.customer.timezone.label') }
          </Label>

          <Value>
            { timezoneDiff === 0
              ? (
                <span className="italic text-gray-500">
                  { t('move.customer.timezone.sameAsYou') }
                </span>
                )
              : (
                <>
                  { move.originTimezone }
                  { ' ' }
                  <span className="text-sm text-gray-400">
                    (
                    { t('move.customer.timezone.currentTime') }
                    { ': ' }
                    { customerTime })
                  </span>
                </>
                ) }
          </Value>
        </Cell>

        <Cell />
        <Cell />
      </Row>
    </>
  )
}

export default CustomerDetails
