import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import Alert from '@mui/material/Alert'

import { Input } from '../../../form'
import { mergeClassName } from '../../../../utils/mergeClassName'

type QuantityCollectionFieldProps = {
  name: string
  options: Array<{ value: string, label: string }>
  compact?: boolean
  className?: string
  optionClassName?: string
  labelClassName?: string
  inputClassName?: string
}

/**
 * @deprecated use IntegerCollectionField
 */
const QuantityCollectionField: React.FC<QuantityCollectionFieldProps> = ({
  name,
  options,
  compact = false,
  className,
  optionClassName,
  labelClassName,
  inputClassName,
}) => {
  const { formState: { errors, defaultValues, touchedFields }, setValue } = useFormContext()
  const totalName = `${name}Total`
  const values = useWatch({ name })
  const currentTotal = useWatch({ name: totalName })

  const controlError = errors[name]
  const totalError = errors[totalName]
  const hasInvalidValues = Object.keys(controlError ?? {}).length > 2
  const editing = !!defaultValues?.[name]
  const touched = touchedFields[name]
  const shouldValidate = touched || editing

  /**
   * Global quantity validation
   * we populate a temporary field to trigger yup global validation
   */
  useEffect(() => {
    if (!shouldValidate || hasInvalidValues) {
      return
    }
    const total = Object.values(values ?? {}).reduce((acc: number, curr: any) => {
      if (!curr) {
        return acc
      }
      return acc + parseInt(curr ?? '')
    }, 0)

    if (total !== currentTotal) {
      setValue(totalName, total, { shouldValidate })
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, hasInvalidValues, shouldValidate, currentTotal])

  return (
    <>
      <div className={mergeClassName(
        'flex flex-col gap-4 md:flex-row md:flex-wrap lg:gap-6',
        compact && 'gap-2 lg:gap-3',
        className,
      )}
      >
        { options.map(({ value, label }) => (
          <div
            key={value}
            className={mergeClassName(
              'flex items-center justify-between rounded-md bg-gray-100 p-4 px-6 md:basis-[calc(50%-0.5rem)] lg:basis-[calc(50%-0.75rem)]',
              compact && 'p-1 pl-4 pr-3 basis-auto lg:basis-auto lg:w-[200px]',
              optionClassName,
            )}
          >
            <div className={mergeClassName(
              'whitespace-nowrap pr-6 text-right text-lg',
              compact && 'text-sm text-gray-600',
              labelClassName,
            )}
            >
              { label }
            </div>
            <Input
              name={`${name}.${value}`}
              size="small"
              margin="dense"
              fullWidth={false}
              className={mergeClassName(
                'w-16 shrink-0',
                compact && 'w-[45px]',
                inputClassName,
              )}
              placeholder="0"
              inputProps={{
                className: 'text-center !px-0',
                maxLength: 2,
              }}
            />
          </div>
        )) }
      </div>

      { typeof totalError?.message === 'string' && (
        <div className="mt-4 lg:mt-6">
          <Alert severity="error">
            { totalError?.message }
          </Alert>
        </div>
      ) }
    </>
  )
}

export default QuantityCollectionField
