import {
  FileIcon as ReactFileIcon,
  type FileIconProps as ReactFileIconProps,
  defaultStyles,
  type DefaultExtensionType,
} from 'react-file-icon'

type FileIconProps = {
  filename: string
  className?: string
}

const FileIcon: React.FC<FileIconProps> = ({
  filename,
  className,
}) => {
  const extension = filename.split('.').reverse()[0].toLowerCase()

  const getIconsProps = (): Partial<ReactFileIconProps> => {
    const props = defaultStyles[extension as DefaultExtensionType] ?? {}
    props.labelUppercase = true

    switch (extension) {
      case 'bmp':
      case 'gif':
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'svg':
      case 'webp':
        props.type = 'image'
        props.extension = props.extension === 'jpeg' ? 'jpg' : extension
        props.color = '#d5d5d5'
        break
      case 'pdf':
        props.color = '#d5d5d5'
        break
    }

    return props
  }

  return (
    <span className={className}>
      <ReactFileIcon
        extension={extension}
        {...getIconsProps()}
      />
    </span>
  )
}

export default FileIcon
