import { type TabProps } from '@mui/material/Tab'

type AddButtonWrapperProps = Omit<TabProps, 'children'> & {
  children?: React.ReactNode
}

const AddButtonWrapper: React.FC<AddButtonWrapperProps> = ({ children }) => {
  return (
    <div className="relative h-[25px] w-[100px] pl-6">
      <div className="absolute top-[14px]">
        { children }
      </div>
    </div>
  )
}

export default AddButtonWrapper
