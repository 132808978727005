import { mergeClassName } from '../../../utils/mergeClassName'

type CalendarSidebarNoDataProps = {
  children?: React.ReactNode
  className?: string
}

const CalendarSidebarNoData: React.FC<CalendarSidebarNoDataProps> = ({
  className,
  children,
}) => {
  return (
    <div className={mergeClassName(
      'px-3 py-4 text-center text-sm text-gray-400',
      className,
    )}
    >
      { children }
    </div>
  )
}

export default CalendarSidebarNoData
