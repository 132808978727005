import { useOnBehalfState } from '../../modules/companies'
import { protectPage, useCurrentUserAccountType } from '../../modules/users'
import AdminDashboardPage from '../admin/dashboard/AdminDashboardPage'
import PartnerDashboardPage from './PartnerDashboardPage'

const DashboardPage: React.FC = () => {
  const { isPartner, isAdmin } = useCurrentUserAccountType()
  const { onBehalf } = useOnBehalfState()

  if (!isPartner && !isAdmin) {
    return null
  }

  return (isAdmin && !onBehalf) ? <AdminDashboardPage /> : <PartnerDashboardPage />
}

export default protectPage(DashboardPage)
