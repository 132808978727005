import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, useWatch, useFormState } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'

import { Form, PasswordField, SubmitButton, SubmitButtonsContainer } from '../../../form'
import { type ResetPasswordFormPayload } from './UpdatePasswordFormPayload.type'
import useValidationSchema from './validationSchema'

type ResetPasswordFormProps = {
  onSubmit: (payload: ResetPasswordFormPayload) => Promise<any>
  onClose?: () => void
}

const UpdatePasswordForm: React.FC<ResetPasswordFormProps> = ({
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation()
  const schema = useValidationSchema()
  const form = useForm({ resolver: yupResolver(schema) })
  const { submitCount } = useFormState({ control: form.control })
  const [newPassword, newPasswordConfirmation] = useWatch({ control: form.control, name: ['newPassword', 'newPasswordConfirmation'] })

  /**
   * trigger validation on "new password confirmation" when "new password" is updated
   * required since a field can become valid without changing
   */
  useEffect(() => {
    if (submitCount < 1) {
      return
    }
    form.setValue('newPasswordConfirmation', newPasswordConfirmation, { shouldValidate: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitCount, newPassword])

  return (
    <Form form={form} onSubmit={onSubmit}>
      <PasswordField
        name="password"
        label={t('forms.fields.currentPassword.label')}
        variant="standard"
        margin="normal"
        autoFocus
      />

      <PasswordField
        showValidations
        name="newPassword"
        label={t('forms.fields.newPassword.label')}
        variant="standard"
        margin="normal"
      />

      <PasswordField
        name="newPasswordConfirmation"
        label={t('forms.fields.passwordConfirmation.label')}
        variant="standard"
        margin="normal"
      />

      <SubmitButtonsContainer>
        { onClose && <Button onClick={onClose}>{ t('actions.cancel') }</Button> }
        <SubmitButton>{ t('actions.save') }</SubmitButton>
      </SubmitButtonsContainer>
    </Form>
  )
}

export default UpdatePasswordForm
