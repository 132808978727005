import { gql } from '@apollo/client'
import { commonQuotesFields, resolvedRequestFields } from '../../quotes/core/quotes.queries'
import { companyRatingFields } from '../../companies/core/companies.queries'

export const quotesFields = `
${commonQuotesFields}
customized
companyBranch {
  id
  company {
    id
    identification {
      name {
        en
        fr
      }
    }
    logo {
      en
    }
  }
  tripAndTravel {
    trucksOriginAddress {
      city
      region
    }
  }
  labour {
    minLabourTime
  }
  ${companyRatingFields}
}
alternativeDates {
  date
  subtotal {
    price
    currency
  }
  dateAvailable
  timeAvailable
  availableAm
  availablePm
}
best
`

export const getDetailedQuotes = gql`
query Admin_getDetailedQuotes($quotesRequest: QuotesRequestDto!) {
  admin_getDetailedQuotes(quotesRequest: $quotesRequest) {
    resolvedRequest {
      ${resolvedRequestFields}
    }
    quotes {
      ${quotesFields}
    }
  }
}
`
