import * as Yup from 'yup'

import { type LabourFormValues } from './LabourForm.type'
import { usePricesValidation } from '../../../../modules/companies'

type UseValidationSchemaOptions = {
  hasPackingService?: boolean
}

const useValidationSchema = ({
  hasPackingService = false,
}: UseValidationSchemaOptions = {}) => {
  const pricesValidation = usePricesValidation()

  const labourPricesValidation = (schema?: any) => pricesValidation({
    basePriceRequired: true,
    allowDecimal: false,
    allowZero: false,
    schema,
  })

  let shape: Yup.ObjectShape = {
    movingLabour: Yup.array().of(labourPricesValidation()),
    movingLabourSupportSingleMan: Yup.boolean(),
    movingLabourSingleMan: Yup.object().when('movingLabourSupportSingleMan', {
      is: true,
      then: (schema: any) => labourPricesValidation(schema),
    }),
    movingLabourExtraMen: labourPricesValidation(),

    minLabourTime: Yup.number()
      .required()
      .min(1)
      .max(9.9),
  }

  if (hasPackingService) {
    shape = {
      ...shape,
      packingLabour: Yup.array().of(labourPricesValidation()),
      packingLabourSupportSingleMan: Yup.boolean(),
      packingLabourSingleMan: Yup.object().when('packingLabourSupportSingleMan', {
        is: true,
        then: (schema: any) => labourPricesValidation(schema),
      }),
      packingLabourExtraMen: labourPricesValidation(),
    }
  }

  return Yup.object(shape) as Yup.ObjectSchema<LabourFormValues, LabourFormValues>
}

export default useValidationSchema
