import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '@mui/material/Alert'

import FloatingTwoColumnsPage from '../../../components/layout/FloatingTwoColumnsPage'
import { type LoginPayload, useLoginAction, visitorPage } from '../../../modules/users'
import LoginForm from '../../../components/forms/users/LoginForm'
import LoginTotpForm from '../../../components/forms/users/LoginTotpForm'
import ServerError from '../../../components/errors/ServerError'
import LoginSecondaryPanel from '../../../components/users/authentication/LoginSecondaryPanel'
import Title from '../../../components/users/authentication/Title'
import { useNavigate, useQueryString } from '../../../modules/navigation'
import { type LoginTotpFormPayload } from '../../../components/forms/users/LoginTotpForm/LoginTotpFormPayload.type'
import { useConfig } from '../../../modules/config'
import Route from '../../../app/Route.enum'

const LoginPage: React.FC = () => {
  const { t } = useTranslation()
  const { login, error, loading } = useLoginAction()
  const { navigate } = useNavigate()
  let defaultPage = useConfig<string>('users.authentication.defaultPage', { defaultValue: Route.Dashboard })
  const loginRedirectionUrlKey = useConfig('users.authentication.loginRedirectionUrlKey')
  const redirectionPath = sessionStorage?.getItem(loginRedirectionUrlKey)
  if (redirectionPath) {
    defaultPage = redirectionPath
    sessionStorage.removeItem(loginRedirectionUrlKey)
  }

  const sessionExpired = Boolean(useQueryString('expired'))
  const [sessionExpiredWarning, setSessionExpiredWarning] = useState(sessionExpired)
  const [mfaRequired, setMfaRequired] = useState(false)
  const [pendingCredentials, setPendingCredentials] = useState<LoginPayload>()

  useEffect(() => {
    if (!!error || loading) {
      setSessionExpiredWarning(false)
    }
  }, [error, loading])

  const onLoginSubmit = async (data: LoginPayload) => {
    try {
      const { mfaRequired, ready } = await login(data)
      if (mfaRequired) {
        setMfaRequired(true)
        setPendingCredentials(data)
        return
      }

      if (ready) {
        navigate(defaultPage)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onTotpSubmit = async ({ token }: LoginTotpFormPayload) => {
    try {
      if (!pendingCredentials) {
        return
      }
      if (await login({
        ...pendingCredentials,
        totp: token,
      })) {
        navigate(defaultPage)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <FloatingTwoColumnsPage secondaryPanelContent={<LoginSecondaryPanel />}>
      <Title>{ t('pages.users.login.title') }</Title>
      { sessionExpiredWarning && <Alert severity="warning">{ t('pages.users.login.errors.expiredSession') }</Alert> }
      { error && <ServerError error={error} /> }

      { mfaRequired
        ? <LoginTotpForm onSubmit={onTotpSubmit} />
        : <LoginForm onSubmit={onLoginSubmit} /> }

    </FloatingTwoColumnsPage>
  )
}

export default visitorPage(LoginPage)
