import { useTranslation } from 'react-i18next'

import Route from '../../../app/Route.enum'
import PageContainer from './PageContainer'
import { CompanyBranchMobileHeader, Step } from '../../../components/companyBranch'
import { useCurrentCompanyBranch } from '../../../modules/companies'
import { useSubmitAction } from '../../../components/forms/companyBranch/useSubmitAction'
import TripAndTravelForm from '../../../components/forms/companyBranch/TripAndTravelForm/TripAndTravelForm'
import { protectPage } from '../../../modules/users'

const CompanyBranchSettingsTripAndTravelPage: React.FC = () => {
  const { t } = useTranslation()
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()

  const onSubmit = useSubmitAction({
    step: Step.TripAndTravel,
    initialData: currentCompanyBranch,
  })

  return (
    <PageContainer
      mobileHeader={(
        <CompanyBranchMobileHeader
          title={String(t('pages.companyBranch.menu.tripAndTravel'))}
          backlinkTo={Route.BranchSettings}
        />
      )}
    >
      <TripAndTravelForm
        companyBranch={currentCompanyBranch}
        onSubmit={onSubmit}
      />
    </PageContainer>
  )
}

export default protectPage(CompanyBranchSettingsTripAndTravelPage)
