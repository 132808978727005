import { removeTypeNameFromObject } from '../../../utils/graphql'
import { type FullMove } from './adminMoves.models'

export const buildFullMove = (
  incomingData?: any,
): FullMove | undefined => {
  if (!incomingData) {
    return
  }

  return removeTypeNameFromObject<FullMove>(incomingData)
}
