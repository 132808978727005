import { useTranslation } from 'react-i18next'
import { useFormContext, useFormState, useWatch } from 'react-hook-form'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'

import {
  ResidenceAndRoomsType,
  ResidenceType,
  Room,
  boxesQuantities,
  defaultBoxesPerResidence,
  furnitureRatio as furnitureRatioValues,
  roomsByResidenceMatrix,
} from '../../../../../modules/quotes'
import Paper from '../../../../Paper'
import {
  CollapsableIntegerCollectionField,
  Label,
  Select,
} from '../../../../form'
import { type QuotesRequestFormValues } from '../QuotesRequestForm.type'
import { hasSameQuantities } from '../utils'

type MoveContentFormContentProps = {
  requireDetails?: boolean
}

const MoveContentFormContent: React.FC<MoveContentFormContentProps> = ({
  requireDetails = false,
}) => {
  const { t } = useTranslation()
  const { setValue } = useFormContext()
  const { defaultValues } = useFormState()
  const editing = !!defaultValues?.movingDate

  const residenceAndRoomsType = useWatch<QuotesRequestFormValues>({ name: 'residenceType' }) as ResidenceAndRoomsType
  const nbBoxes = useWatch({ name: 'nbBoxes' })
  const residenceRooms = useWatch<QuotesRequestFormValues>({ name: 'residenceRooms' }) ?? {}

  const hasCustomNbBoxes = !editing &&
    residenceAndRoomsType &&
    defaultBoxesPerResidence[residenceAndRoomsType] !== nbBoxes

  const hasCustomRooms = !editing &&
  residenceAndRoomsType &&
  !hasSameQuantities(residenceRooms, roomsByResidenceMatrix[residenceAndRoomsType])

  const residenceAndRoomsTypeOptions = Object.values(ResidenceAndRoomsType).map((residenceAndRoomsType) => ({
    value: residenceAndRoomsType,
    label: t(`residenceAndRoomsType.${residenceAndRoomsType}.title`),
  }))

  const residenceTypeOptions = Object.values(ResidenceType).map((residenceType) => ({
    value: residenceType,
    label: t(`residenceType.${residenceType}.title`),
  }))

  const nbBoxesOptions = [
    { value: '0', label: t('nbBoxes.0.title') },
    ...Object.values(boxesQuantities).map((nbBoxes) => ({
      value: String(nbBoxes),
      label: t(`nbBoxes.${nbBoxes}.title`),
    })),
  ]

  const furnitureRatioOptions = [
    { value: '0', label: t('furnitureRatio.0.title') },
    ...Object.values(furnitureRatioValues).map((furnitureRatio) => ({
      value: String(furnitureRatio),
      label: t(`furnitureRatio.${furnitureRatio}.title`),
    })),
  ]

  const residenceRoomsOptions = Object.values(Room).map(room => ({
    value: room,
    label: t(`rooms.${room}.title`, { count: 2 }),
  }))

  const applyDefaultNbBoxes = () => {
    setValue('nbBoxes', defaultBoxesPerResidence[residenceAndRoomsType])
  }

  const applyDefaultRooms = () => {
    setValue('residenceRooms', roomsByResidenceMatrix[residenceAndRoomsType])
  }

  return (
    <>
      <h2 className="mb-2 font-body2 text-lg font-bold lg:my-4">
        { t('forms.quotesRequest.moveContent.title') }
      </h2>
      <Paper>
        { !requireDetails && (
          <div className="mb-4">
            <Alert severity="info">
              { t('forms.quotesRequest.moveContent.estimateInfo') }
            </Alert>
          </div>
        ) }
        <div className="flex flex-col gap-4">
          <Label
            label={t('residenceType.title')}
            required={requireDetails}
          >
            <Select
              name="residenceType"
              options={(editing && requireDetails) ? residenceTypeOptions : residenceAndRoomsTypeOptions}
              size="small"
              allowEmpty
            />
          </Label>

          <Label
            label={(
              <div className="align-center flex justify-between">
                { t('nbBoxes.title') }
                { hasCustomNbBoxes && (
                  <Button
                    color="warning"
                    size="small"
                    className="!ml-2 !px-2 !py-0 !normal-case !underline"
                    onClick={applyDefaultNbBoxes}
                  >
                    { t('actions.useEstimateValue') }
                  </Button>
                ) }
              </div>
            )}
          >
            <Select
              name="nbBoxes"
              options={nbBoxesOptions}
              size="small"
            />
          </Label>

          <Label
            label={t('furnitureRatio.title')}
          >
            <Select
              name="furnitureRatio"
              options={furnitureRatioOptions}
              size="small"
            />
          </Label>

          <Label
            label={(
              <div className="align-center flex justify-between">
                { t('rooms.title') }
                { hasCustomRooms && (
                  <Button
                    color="warning"
                    size="small"
                    className="!ml-2 !px-2 !py-0 !normal-case !underline"
                    onClick={applyDefaultRooms}
                  >
                    { t('actions.useEstimateValues') }
                  </Button>
                ) }
              </div>
            )}
          />
          <div>
            <CollapsableIntegerCollectionField
              name="residenceRooms"
              options={residenceRoomsOptions}
              getLabel={(key, value) => t(`rooms.${key}.title`, { count: value })}
              emptyValue={t('forms.quotesRequest.moveContent.noRooms')}
              max={99}
            />
          </div>

        </div>
      </Paper>
    </>
  )
}

export default MoveContentFormContent
