import { type Amount } from '../../common/amount'
import { mergeClassName } from '../../utils/mergeClassName'
import { formatPrice, type FormatPriceOptions } from '../../utils/money'

type PriceProps = {
  amount: Amount
  className?: string
} & FormatPriceOptions

const Price: React.FC<PriceProps> = ({
  amount,
  compact,
  showDecimals = true,
  showCurrency,
  className,
}) => {
  const formattedPrice = formatPrice(amount, { compact, showDecimals, showCurrency })
  const parts = formattedPrice.split('.')

  return (
    <div className={mergeClassName(
      'inline-flex whitespace-nowrap',
      className,
    )}
    >
      { parts[0] }
      { showDecimals && (
        <span className={compact ? undefined : 'text-sm'}>.{ parts[1] }</span>
      ) }
    </div>
  )
}

export default Price
