/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { recordToKeyValueMap } from '../../../../common/keyValueMap'
import { type Truck } from '../../../companies'
import { trucksVolume } from '../values/trucksVolume'

export const estimateNbTrucks = (volume: number) => {
  let remainingVolume = volume
  const selectedTrucks: Partial<Record<Truck, number>> = {}

  const volumes = Object.entries(trucksVolume) as Array<[Truck, number]>
  const biggestTruck = volumes[volumes.length - 1][0]

  while (remainingVolume > 0) {
    for (const [truck, volume] of volumes) {
      if (volume >= remainingVolume || truck === biggestTruck) {
        remainingVolume -= volume
        if (!selectedTrucks[truck]) {
          selectedTrucks[truck] = 0
        }
        selectedTrucks[truck]!++
        break
      }
    }
  }

  return recordToKeyValueMap<Truck, number>(selectedTrucks)
}
