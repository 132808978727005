import { useState, useEffect } from 'react'
import { DragDropContext, Draggable, type DropResult } from 'react-beautiful-dnd'

import Droppable from './Droppable'
import { type ObjectsWithId } from './Draggable.types'
import { getIds, reorder } from './utils'

type RenderProps = {
  id: string
  isDragging: boolean
}

type DraggableListProps = {
  name: string
  data: ObjectsWithId
  onOrderChange?: (ids: string[]) => void | Promise<void>
  disabled?: boolean
  className: string
  children: (props: RenderProps) => React.ReactNode
}

const DraggableList: React.FC<DraggableListProps> = ({
  name,
  data,
  children,
  onOrderChange,
  disabled = false,
  className,
}) => {
  const [displayIds, setDisplayIds] = useState(getIds(data))

  /**
   * reset state if incoming data change
   */
  useEffect(() => {
    setDisplayIds(getIds(data))
  }, [data])

  /**
   * apply new order
   */
  const dragEnded = (result: DropResult) => {
    if (!result.destination) {
      return
    }
    const reorderedIds = reorder(
      displayIds,
      result.source.index,
      result.destination.index,
    )
    const originalPosition = displayIds
    setDisplayIds(reorderedIds)

    onOrderChange?.(reorderedIds)?.catch(() => {
      setDisplayIds(originalPosition)
    })
  }

  return (
    <DragDropContext onDragEnd={dragEnded}>
      <Droppable
        droppableId={name}
        isDropDisabled={disabled}
      >
        { (provided, snapshot) => (
          <div
            className={className}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >

            { displayIds.map((id, index) => {
              return (
                <Draggable
                  key={id}
                  index={index}
                  draggableId={id}
                  isDragDisabled={disabled}
                >
                  { (provided, snapshot) => {
                    const item = children({
                      id,
                      isDragging: snapshot.isDragging,
                    })
                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        { item }
                      </div>
                    )
                  } }
                </Draggable>
              )
            }) }
            { provided.placeholder }
          </div>
        ) }
      </Droppable>
    </DragDropContext>
  )
}

export default DraggableList
