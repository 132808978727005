import { gql } from '@apollo/client'
import { quotesFields } from '../../adminQuotes/core/quotes.queries'
import { resolvedRequestFields } from '../../quotes/core/quotes.queries'
import { userFields } from '../../adminUsers/core/adminUsers.queries'

export const requestFields = `
movingDate
  flexibleDate
  preferredTime
  overnightFees
  nbMen
  estimatedMovingLabourTime
  estimatedPackingLabourTime
  nbTrucks {
    key
    value
  }
  origin {
    address {
      country
      street
      apartment
      city
      region
      postalCode
    }
    nbStairs
  }
  destination {
    address {
      country
      street
      apartment
      city
      region
      postalCode
    }
    nbStairs
  }
  specialServices {
    key
    value
  }
  specialItems {
    key
    value
  }
  protectiveMaterial {
    key
    value
  }
`

export const prospectFields = `
id
name
createdAt
updatedAt
createdBy
agent {
  ${userFields}
}
request {
  ${requestFields}
  ${resolvedRequestFields}
}
quotes {
  ${quotesFields}
}
`

export const prospectsFields = `
id
name
createdAt
updatedAt
createdBy
request {
  ${requestFields}
  ${resolvedRequestFields}
}
agent {
  id
  firstName
  lastName
}
`

export const getProspects = gql`
query Admin_getProspects($query: PaginatedQuery, $onlyMine: Boolean) {
  admin_getProspects(query: $query, onlyMine: $onlyMine) {
    data {
      ${prospectsFields}
    }
    count
    hasMore
  }
}
`

export const getProspect = gql`
query Admin_getProspect($prospectId: String!) {
  admin_getProspect(id: $prospectId) {
    ${prospectFields}
  }
}
`
