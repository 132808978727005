import { createTheme, lighten } from '@mui/material/styles'

// https://mui.com/customization/default-theme/

declare module '@mui/material/styles' {
  interface Palette {
    primaryControl: Palette['primary']
    mainBackground: Palette['primary']
    contentBackground: Palette['primary']
  }

  interface PaletteOptions {
    primaryControl: PaletteOptions['primary']
    mainBackground: PaletteOptions['primary']
    contentBackground: PaletteOptions['primary']
  }
}

export const getTheme = (darkMode = false) => {
  const MAIN_BACKGROUND = darkMode ? '#0f0f0f' : '#fff'
  const CONTENT_BACKGROUND = darkMode ? '#1f1f1f' : '#eee'
  const TEXT_COLOR = darkMode ? '#d9d9d9' : '#000'
  const PRIMARY_CONTRAST_TEXT_COLOR = darkMode ? '#d9d9d9' : '#FFF'
  const SECONDARY_CONTRAST_TEXT_COLOR = darkMode ? '#0f0f0f' : '#FFF'

  const PRIMARY_COLOR = '#fa372c'
  const SECONDARY_COLOR = '#64748b' // #919191
  const PRIMARY_CONTROL_COLOR = '#000'
  const PRIMARY_FONT = 'Inter, sans-serif'
  const SECONDARY_FONT = '"Montserrat", sans-serif'

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : undefined,
      primary: {
        main: PRIMARY_COLOR,
        contrastText: PRIMARY_CONTRAST_TEXT_COLOR,
      },
      secondary: {
        main: SECONDARY_COLOR,
        contrastText: SECONDARY_CONTRAST_TEXT_COLOR,
      },
      primaryControl: {
        main: PRIMARY_CONTROL_COLOR,
      },
      mainBackground: {
        main: MAIN_BACKGROUND,
        light: lighten(MAIN_BACKGROUND, 0.2),
        contrastText: TEXT_COLOR,
      },
      contentBackground: {
        main: CONTENT_BACKGROUND,
      },
      text: {
        primary: TEXT_COLOR,
      },
    },
    shape: {
      borderRadius: 5,
    },
    typography: {
      fontFamily: PRIMARY_FONT,
      allVariants: {
        fontFamily: PRIMARY_FONT,
      },
      body1: {
        fontFamily: PRIMARY_FONT,
      },
      body2: {
        fontFamily: SECONDARY_FONT,
      },
      h1: {
        fontFamily: SECONDARY_FONT,
        fontSize: '4.25rem',
        marginBottom: '1.5rem',
        lineHeight: '4.25rem',
      },
      h2: {
        fontFamily: SECONDARY_FONT,
        fontSize: '1.6rem',
        lineHeight: '1.8rem',
        marginBottom: '1.5rem',
      },
      h3: {
        fontFamily: SECONDARY_FONT,
        fontSize: '1.25rem',
        lineHeight: '1.3rem',
        marginBottom: '1.5rem',
      },
      h4: {
        fontFamily: SECONDARY_FONT,
      },
      h5: {
        fontFamily: SECONDARY_FONT,
      },
      h6: {
        fontFamily: SECONDARY_FONT,
      },
    },
    components: {
      MuiTextField: {
        defaultProps: {
          color: 'primaryControl' as any,
        },
      },
      MuiSelect: {
        defaultProps: {
          MenuProps: {
            sx: {
              '&& .Mui-selected, && .Mui-selected:hover': {
                backgroundColor: `${SECONDARY_COLOR}!important`,
                color: `${SECONDARY_CONTRAST_TEXT_COLOR}!important`,
              },
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 40,
          },
        },
      },
    },
  })

  return theme
}
