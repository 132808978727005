import { mergeClassName } from '../../../utils/mergeClassName'

type CalendarSidebarListItemProps = {
  children?: React.ReactNode
  compact?: boolean
  className?: string
}

const CalendarSidebarListItem: React.FC<CalendarSidebarListItemProps> = ({
  children,
  compact = false,
  className,
}) => {
  return (
    <div className={mergeClassName(
      'mx-4 my-2 flex items-center gap-1',
      compact && 'my-1',
      className,
    )}
    >
      { children }
    </div>
  )
}

export default CalendarSidebarListItem
