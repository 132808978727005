export enum QuoteConstraint {
  unavailableDate = 'unavailableDate',
  unavailablePreferredTime = 'unavailablePreferredTime',
  notEnoughTrucks = 'notEnoughTrucks',
  doesNotOfferedSingleManService = 'doesNotOfferedSingleManService',
  additionalServicesNotOffered = 'additionalServicesNotOffered',
  overnightNotOffered = 'overnightNotOffered',
  specialServicesNotOffered = 'specialServicesNotOffered',
  specialItemsNotSupported = 'specialItemsNotSupported',
  protectiveMaterialNotOffered = 'protectiveMaterialNotOffered',
}

export enum ResidenceAndRoomsType {
  Studio = 'studio',
  Apartment1Bedroom = 'apartment1Bedroom',
  Apartment2Bedroom = 'apartment2Bedroom',
  Apartment3Bedroom = 'apartment3Bedroom',
  Apartment4Bedroom = 'apartment4Bedroom',
  House1Bedroom = 'house1Bedroom',
  House2Bedroom = 'house2Bedroom',
  House3Bedroom = 'house3Bedroom',
  House4Bedroom = 'house4Bedroom',
  AssistedLiving = 'assistedLiving',
  OfficeSpace = 'officeSpace',
  StorageUnit5By5 = 'storageUnit5By5',
  StorageUnit5By10 = 'storageUnit5By10',
  StorageUnit10By20 = 'storageUnit10By20',
}

export enum ResidenceType {
  Studio = 'studio',
  Apartment = 'apartment',
  House = 'house',
  AssistedLiving = 'assistedLiving',
  OfficeSpace = 'officeSpace',
  StorageUnit5By5 = 'storageUnit5By5',
  StorageUnit5By10 = 'storageUnit5By10',
  StorageUnit10By20 = 'storageUnit10By20',
}

export enum Room {
  Bedroom = 'bedroom',
  LivingRoom = 'livingRoom',
  Kitchen = 'kitchen',
  Bathroom = 'bathroom',
  HomeOffice = 'homeOffice',
  DiningRoom = 'diningRoom',
  Hall = 'hall',
  LaundryRoom = 'laundryRoom',
  HomeGym = 'homeGym',
  Attic = 'attic',
  Garage = 'garage',
  Shed = 'shed',
  Outdoor = 'outdoor',
  StorageRoom = 'storageRoom',
  Workshop = 'workshop',
}
