import { useTranslation } from 'react-i18next'
import Alert from '@mui/material/Alert'

import { type InvoiceEstimate } from '../../../../modules/invoices'
import { SingleCheckbox } from '../../../form'
import Paper from '../../../Paper'
import InfoTooltip from '../../../InfoTooltip'

type AutoCaptureProps = {
  invoiceEstimate: InvoiceEstimate
}

const AutoCapture: React.FC<AutoCaptureProps> = ({
  invoiceEstimate,
}) => {
  const { t } = useTranslation()
  const fieldName = 'autoCapture'

  if (invoiceEstimate.total.price < 0) {
    return (
      <Alert severity="info">
        { t('forms.invoice.sidebar.autoCollect.refundInfo') }
      </Alert>
    )
  }

  return (
    <Paper className="3xl:mt-8">
      <SingleCheckbox
        name={fieldName}
        label={(
          <>
            { t('forms.invoice.sidebar.autoCollect.checkboxLabel') }
            <div className="ml-1 inline-block scale-90">
              <InfoTooltip
                message={t('forms.invoice.sidebar.autoCollect.checkboxLabelInfo')}
              />
            </div>
          </>
        )}
      />
    </Paper>
  )
}

export default AutoCapture
