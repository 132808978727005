import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'

import { Form, SixDigitsCodeField, SubmitButton, SubmitButtonsContainer } from '../../../form'
import useValidationSchema from './validationSchema'
import { type DeleteAccountPayload } from '../../../../modules/users'

type DeleteAccountFormProps = {
  onSubmit: (payload: DeleteAccountPayload) => Promise<any>
  onClose?: () => void
}

const DeleteAccountForm: React.FC<DeleteAccountFormProps> = ({
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation()
  const schema = useValidationSchema()
  const form = useForm({ resolver: yupResolver(schema) })

  return (
    <Form form={form} onSubmit={onSubmit}>
      <SixDigitsCodeField
        name="token"
        label={t('forms.fields.code.label')}
      />

      <SubmitButtonsContainer>
        { onClose && <Button onClick={onClose} color="error">{ t('actions.cancel') }</Button> }
        <SubmitButton color="error">{ t('actions.deleteAccount') }</SubmitButton>
      </SubmitButtonsContainer>
    </Form>
  )
}

export default DeleteAccountForm
