/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'

import { type Quote } from '../../../../modules/quotes'
import { type QuotePayload } from '../../../../modules/adminMoves'
import { type PromoCode } from '../../../../modules/adminPromoCodes'
import { Form, SubmitButton } from '../../../form'
import { type QuoteDetailFormValue } from './QuoteDetailsForm.type'
import {
  quoteDetailsToFormData,
  formatFormOutput,
} from './formDataBuilder'
import Paper from '../../../Paper'
import Summary from './Summary'
import useValidationSchema from './validationSchema'
import EditAll from './EditAll'
import ChargesFormContent from '../../invoice/ChargesFormContent'

type QuoteDetailsFormProps = {
  quote: Quote
  promoCode?: PromoCode
  onSubmit: (data: QuotePayload) => Promise<void> | void
  onCancel?: () => Promise<void> | void
}

const QuoteDetailsForm: React.FC<QuoteDetailsFormProps> = ({
  quote,
  promoCode,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation()
  const schema = useValidationSchema()
  const form = useForm<QuoteDetailFormValue>({
    resolver: yupResolver(schema),
    defaultValues: quoteDetailsToFormData(quote.details),
    mode: 'onTouched',
  })

  const [editAll, setEditAll] = useState(false)

  /**
   * format data and submit form
   */
  const handleSubmit = async (data: QuoteDetailFormValue) => {
    const currency = quote.subtotal.currency
    await onSubmit(formatFormOutput(data, currency))
  }

  return (
    <Form
      form={form}
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col justify-between gap-x-8 3xl:flex-row">

        <Paper className="max-w-full grow overflow-x-auto">
          <h2 className="mb-8 font-body2 text-2xl font-semibold text-slate-700">
            { t('forms.move.quote.quoteDetails') }
          </h2>

          <ChargesFormContent
            currency={quote.subtotal.currency}
            editAll={editAll}
            promoCode={promoCode}
            moveSize={{
              nbMen: quote.resolvedNbMen,
              nbTrucks: quote.resolvedNbTrucks,
            }}
            minRows={1}
          />
        </Paper>

        <div className="shrink-0">
          <div className="3xl:sticky 3xl:top-0 3xl:w-[400px]">
            <Paper>
              <h2 className="mb-8 font-body2 text-2xl font-semibold text-slate-700">
                { t('forms.move.quote.summary.title') }
              </h2>

              <Summary
                quote={quote}
                promoCode={promoCode}
              />
            </Paper>

            <Paper className="3xl:mt-8">
              <EditAll
                value={editAll}
                onChange={setEditAll}
              />
            </Paper>
          </div>
        </div>
      </div>

      <div className="mt-8 flex justify-end gap-3">
        { onCancel && (
          <Button
            variant="contained"
            color="secondary"
            onClick={onCancel}
          >
            { t('actions.cancel') }
          </Button>
        ) }
        <SubmitButton size="large">
          { t('actions.create') }
        </SubmitButton>
      </div>
    </Form>
  )
}

export default QuoteDetailsForm
