import { gql } from '@apollo/client'

export const promoCodeFields = `
id
createdAt
deletedAt
code
value
type
`

export const getPromoCodes = gql`
query Admin_getPromoCodes($query: PaginatedQuery) {
  admin_getPromoCodes(query: $query) {
    count
    data {
      ${promoCodeFields}
    }
    hasMore
  }
}
`

export const getPromoCode = gql`
query Admin_getPromoCode($promoCodeId: String!) {
  admin_getPromoCode(promoCodeId: $promoCodeId) {
    ${promoCodeFields}
  }
}
`
