import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import Chip from '@mui/material/Chip'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import TruckIcon from '@mui/icons-material/LocalShippingOutlined'
import CalendarIcon from '@mui/icons-material/CalendarMonthOutlined'
import PricingIcon from '@mui/icons-material/MonetizationOnOutlined'
import PlayIcon from '@mui/icons-material/PlayCircleFilled'
import PauseIcon from '@mui/icons-material/PauseCircleFilled'

import ContainedPage from '../../../components/layout/ContainedPage'
import PageTitle from '../../../components/layout/PageTitle'
import PageContent from '../../../components/layout/PageContent'
import { Permission, protectPage, usePermission } from '../../../modules/users'
import {
  useCurrentCompany,
  useCurrentCompanyBranch,
  useNotifications,
  useOnBehalfState,
  useUpdateCompanyBranchAction,
} from '../../../modules/companies'
import Spinner from '../../../components/Spinner/Spinner'
import { useNavigate } from '../../../modules/navigation'
import Route from '../../../app/Route.enum'
import { useTranslatedValue } from '../../../common/translation'
import CreateCompany from './CreateCompany'
import Paper from '../../../components/Paper'
import { Cell, Label, Row, Value } from '../../../components/cell'
import ServerError from '../../../components/errors/ServerError'
import ConfirmationModal from '../../../components/ConfirmationModal'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import { errorNotification, successNotification } from '../../../components/ToastNotifications'
import PendingJobRequests from './PendingJobRequests'
import UpcomingJobs from './UpcomingJobs'
import JobReadyToBeClosed from './JobReadyToBeClosed'

const PartnerDashboardPage: React.FC = () => {
  const { t } = useTranslation()
  const { onBehalf } = useOnBehalfState()
  const { data: currentCompany, loading } = useCurrentCompany()
  const { data: currentcompanyBranch, error: currentCompanyBranchError } = useCurrentCompanyBranch()
  const { startListening, stopListening } = useNotifications()
  const { updateCompanyBranch } = useUpdateCompanyBranchAction()
  const { navigate } = useNavigate()
  const getTranslation = useTranslatedValue()
  const formatError = useErrorFormatter()
  const canManageOwnJobRequests = usePermission(Permission.canManageOwnJobRequests)
  const canManageOwnJobs = usePermission(Permission.canManageOwnJobs)

  const currentBranchCompleted = (currentcompanyBranch?.setupProgress ?? 0) === 100
  const shouldListenNotifications = canManageOwnJobRequests && currentcompanyBranch?.approved

  /* when the current branche is not fully configured, redirect to the form */
  useEffect(() => {
    if (currentcompanyBranch && !currentBranchCompleted) {
      navigate(Route.BranchSettings, { branchId: currentcompanyBranch.id })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentcompanyBranch, currentBranchCompleted])

  /**
   * start/stop listening for notifications when entering/leaving dashboard
   */
  useEffect(() => {
    if (!shouldListenNotifications || !currentcompanyBranch?.id) {
      return
    }
    startListening(currentcompanyBranch.id)
    return () => {
      stopListening()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldListenNotifications, currentcompanyBranch?.id])

  if (currentCompanyBranchError) {
    return (
      <ContainedPage>
        <ServerError error={currentCompanyBranchError} />
      </ContainedPage>
    )
  }
  if (loading || (currentcompanyBranch && !currentBranchCompleted)) {
    return (
      <ContainedPage>
        <Spinner floating />
      </ContainedPage>
    )
  }

  if (!currentCompany) {
    return onBehalf ? null : <CreateCompany />
  }

  if (currentCompany.branches.length === 0) {
    return (
      <ContainedPage>
        <Alert severity="error">
          <div className="text-base">
            <strong>{ t('pages.partnerDashboard.companyDeletedAlert.title') }</strong><br />
            { t('pages.partnerDashboard.companyDeletedAlert.description') }
          </div>
        </Alert>
      </ContainedPage>
    )
  }

  /**
   * pause company branch
   */
  const handlePauseBranch = async () => {
    try {
      await updateCompanyBranch({ active: false })
      successNotification(t('pages.partnerDashboard.actions.pauseCompany.success'))
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  /**
   * resume company branch
   */
  const handleResumeBranch = async () => {
    try {
      await updateCompanyBranch({ active: true })
      successNotification(t('pages.partnerDashboard.actions.resumeCompany.success'))
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  return (
    <ContainedPage>
      <PageTitle>
        <div className="flex flex-col items-center gap-4 lg:flex-row">
          { currentCompany?.logo?.en && (
            <img src={currentCompany?.logo?.en} className="max-h-[50px] lg:max-h-none lg:max-w-[75px]" alt="" />
          ) }
          { getTranslation(currentCompany.identification.name) }
        </div>
      </PageTitle>

      <PageContent className="pb-4 pt-2">
        <div className="mb-8 flex flex-wrap justify-center gap-2 pt-6 lg:justify-start lg:gap-4 lg:pt-0">
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<TruckIcon />}
            onClick={() => { navigate(Route.BranchSettingsLandingPage) }}
          >
            { t('pages.partnerDashboard.actions.editCompanyProfile') }
          </Button>

          { canManageOwnJobs && currentcompanyBranch?.approved && (
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<CalendarIcon />}
              onClick={() => { navigate(Route.JobsLandingPage) }}
            >
              { t('pages.partnerDashboard.actions.jobs') }
            </Button>
          ) }

          <Button
            variant="outlined"
            color="secondary"
            startIcon={<PricingIcon />}
            onClick={() => { navigate(Route.BranchAvailabilityLandingPage) }}
          >
            { t('pages.partnerDashboard.actions.setPricingAvailability') }
          </Button>
        </div>

        <Paper className="child:lg:pt-4">
          <Row>
            <Cell>
              <Label className="text-md">
                { t('pages.partnerDashboard.approbation') }
              </Label>
              { currentcompanyBranch?.approved
                ? (
                  <Value className="py-3">
                    <Chip
                      className="!bg-lime-400"
                      label={t('status.approved')}
                      size="small"
                    />
                  </Value>
                  )
                : (
                  <Value className="py-3">
                    <Chip
                      className="!bg-orange-400 !text-orange-100"
                      label={t('status.pending')}
                      size="small"
                    />
                  </Value>
                  ) }

            </Cell>

            <Cell>
              <Label className="text-md">
                { t('pages.partnerDashboard.companyProfileCompletion') }
              </Label>
              <Value className="py-3">
                <div className="flex items-center gap-2 text-xl text-green-800">
                  <CheckCircleIcon />
                  { t('pages.partnerDashboard.completed') }
                  { ' ' }
                  (100%)
                </div>
              </Value>
            </Cell>
          </Row>

          { currentcompanyBranch?.approved && (
            <Row>
              <Cell>
                <Label className="text-md">
                  { t('pages.partnerDashboard.status') }
                </Label>
                { currentcompanyBranch?.active
                  ? (
                    <Value className="py-3">
                      <div className="flex items-center gap-4">

                        <Chip
                          className="!bg-lime-400"
                          label={t('status.active')}
                          size="small"
                        />

                        <ConfirmationModal
                          title={t('pages.partnerDashboard.actions.pauseCompany.confirmation.title')}
                          question={(
                            <>
                              <p>
                                { t('pages.partnerDashboard.actions.pauseCompany.confirmation.question') }
                              </p>
                              <div className="my-4">
                                <Alert severity="info">
                                  <div className="text-sm">
                                    { t('pages.partnerDashboard.actions.pauseCompany.confirmation.tip') }
                                  </div>
                                </Alert>
                              </div>
                            </>
                          )}
                          danger
                          onConfirm={handlePauseBranch}
                        >
                          <Button
                            variant="outlined"
                            color="secondary"
                            size="small"
                            startIcon={<PauseIcon />}
                          >
                            { t('pages.partnerDashboard.actions.pauseCompany.label') }
                          </Button>
                        </ConfirmationModal>
                      </div>
                    </Value>
                    )
                  : (
                    <Value className="py-3">
                      <div className="flex items-center gap-4">
                        <Chip
                          className="!bg-orange-400 !text-orange-100"
                          label={t('status.paused')}
                          size="small"
                        />

                        <ConfirmationModal
                          title={t('pages.partnerDashboard.actions.resumeCompany.confirmation.title')}
                          question={t('pages.partnerDashboard.actions.resumeCompany.confirmation.question')}
                          onConfirm={handleResumeBranch}
                        >
                          <Button
                            variant="outlined"
                            color="secondary"
                            size="small"
                            startIcon={<PlayIcon />}
                          >
                            { t('pages.partnerDashboard.actions.resumeCompany.label') }
                          </Button>
                        </ConfirmationModal>
                      </div>
                    </Value>
                    ) }
              </Cell>
            </Row>
          ) }

          { !currentcompanyBranch?.approved && (
            <div className="mt-4">
              <Alert severity="info" className="!bg-sky-100/30">
                <div className="text-base">
                  <strong>
                    { t('pages.partnerDashboard.approbationAlert.title') }
                  </strong><br />
                  { t('pages.partnerDashboard.approbationAlert.description') }
                </div>
              </Alert>
            </div>
          ) }
        </Paper>

        <PendingJobRequests />

        <JobReadyToBeClosed />

        <UpcomingJobs />

      </PageContent>
    </ContainedPage>
  )
}

export default protectPage(PartnerDashboardPage)
