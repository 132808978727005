import { recordToKeyValueMap, keyValueMapToRecord } from '../../../../common/keyValueMap'
import {
  type CompanyBranchProtectiveMaterial,
  pricingAsString,
  pricingAsNumber,
} from '../../../../modules/companies'
import { type ProtectiveMaterialFormValues } from './ProtectiveMaterialForm.type'

export const CompanyBranchProtectiveMaterialToFormData = (companyBranchProtectiveMaterial?: CompanyBranchProtectiveMaterial):
ProtectiveMaterialFormValues => {
  return {
    products: keyValueMapToRecord(companyBranchProtectiveMaterial?.products, pricingAsString),
  }
}

export const formatFormOutput = (formData: ProtectiveMaterialFormValues): CompanyBranchProtectiveMaterial => {
  return {
    products: recordToKeyValueMap(
      formData.products,
      pricingAsNumber,
    ),
  }
}
