import { useTranslation, Trans } from 'react-i18next'
import Alert from '@mui/material/Alert'

import FloatingPage from '../../../components/layout/FloatingPage'
import Title from '../../../components/users/authentication/Title'
import { useParams } from '../../../modules/navigation'
import { validateToken } from '../../../utils/token'
import { validateUuid } from '../../../utils/uuid'
import { useResetPasswordAction, visitorPage } from '../../../modules/users'
import ServerError from '../../../components/errors/ServerError'
import ResetPasswordForm from '../../../components/forms/users/ResetPasswordForm'
import Link from '../../../components/Link'
import { Error404Page } from '../../errors'
import { type ResetPasswordFormPayload } from '../../../components/forms/users/ResetPasswordForm/ResetPasswordFormPayload.type'
import Route from '../../../app/Route.enum'

const ResetPasswordPage: React.FC = () => {
  const { t } = useTranslation()
  const { resetPassword, data: success, error } = useResetPasswordAction()
  const { token: fullToken } = useParams()
  const userId = fullToken ? fullToken.substring(0, 36) : undefined
  const token = fullToken ? fullToken.substring(37) : undefined
  const validToken = validateUuid(userId) && validateToken(token)

  if (!validToken || !userId || !token) {
    return <Error404Page />
  }

  const onSubmit = async (payload: ResetPasswordFormPayload) => {
    return await resetPassword({
      userId,
      token,
      password: payload.password,
    })
  }

  return (
    <FloatingPage>
      <Title>{ t('pages.users.resetPassword.title') }</Title>
      { error && <div className="mt-4"><ServerError error={error} /></div> }
      { success
        ? (
          <div className="mt-8">
            <Alert severity="success">
              <Trans i18nKey="pages.users.resetPassword.success">
                <Link to={Route.Login} variant="primary" />
              </Trans>
            </Alert>
          </div>
          )
        : (
          <ResetPasswordForm onSubmit={onSubmit} />
          ) }
    </FloatingPage>
  )
}

export default visitorPage(ResetPasswordPage)
