import './style.css'
import { mergeClassName } from '../../../../utils/mergeClassName'

type LiProps = React.HTMLAttributes<HTMLLIElement>

const PlaceLine: React.FC<LiProps> = ({ className, ...props }) => {
  return (
    <li
      {...props}
      className={mergeClassName(
        'place-option font-sans font-base',
        className,
      )}
    />
  )
}

export default PlaceLine
