import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import { mergeClassName } from '../../utils/mergeClassName'

type ExpandableMessageProps = {
  message: string
  openByDefault?: boolean
  showAll?: boolean
}

const ExpandableMessage: React.FC<ExpandableMessageProps> = ({
  message,
  openByDefault = false,
  showAll,
}) => {
  const { t } = useTranslation()

  const hasSpaces = message.includes('\n')
  const isSmall = message.length < 75
  showAll ??= !hasSpaces && isSmall

  const [showDetails, setShowDetails] = useState(showAll || openByDefault)

  const handleToggleDetailsClick = () => {
    setShowDetails(!showDetails)
  }

  return (

    <>
      <div className={mergeClassName(
        'h-[50px] relative overflow-hidden mt-4',
        showDetails && 'h-auto overflow-visible shadow-none mb-4',
      )}
      >
        <div className="ml-4 whitespace-pre-wrap border-l pl-8 text-neutral-600">
          { message }
        </div>

        { !showDetails && (
          <div className="absolute inset-x-[-15px] inset-y-0 shadow-[inset_white_0_-35px_10px]" />
        ) }
      </div>

      { !showAll && (
        <div className="ml-4">
          { showDetails
            ? (
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                onClick={handleToggleDetailsClick}
                startIcon={<ArrowUpIcon />}
              >
                { t('actions.hideDetails') }
              </Button>
              )
            : (
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                onClick={handleToggleDetailsClick}
                startIcon={<ArrowDownIcon />}
              >
                { t('actions.showDetails') }
              </Button>
              ) }
        </div>
      ) }
    </>

  )
}

export default ExpandableMessage
