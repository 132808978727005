import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import SummaryIcon from '@mui/icons-material/InfoOutlined'
import HomeIcon from '@mui/icons-material/HomeWorkOutlined'
import MapIcon from '@mui/icons-material/MapOutlined'
import AgentIcon from '@mui/icons-material/HeadsetMicOutlined'

import { type FullMove } from '../../../../../modules/adminMoves'
import { useMoveState } from '../../../../../modules/moves'
import { ResidenceType, Room } from '../../../../../modules/quotes'
import { Permission, usePermission } from '../../../../../modules/users'
import Route from '../../../../../app/Route.enum'
import { formatNumber } from '../../../../../utils/number'
import { capitalizeFirstLetter } from '../../../../../utils/string'
import { mergeClassName } from '../../../../../utils/mergeClassName'
import { useDateFormatter } from '../../../../../utils/date'
import Paper from '../../../../../components/Paper'
import Price from '../../../../../components/Price'
import Link, { LinkVariant } from '../../../../../components/Link'

type SummaryBoxProps = {
  move: FullMove
  onEstimateClick?: () => void
  className?: string
}

const SummaryBox: React.FC<SummaryBoxProps> = ({
  move,
  onEstimateClick,
  className,
}) => {
  const { t } = useTranslation()
  const formatDate = useDateFormatter()
  const {
    moveDate,
    selectedQuote,
    hasCustomQuote,
    selectedCompanyName,
    selectedCompanyLogo,
    subtotal,
  } = useMoveState().forMove(move)
  const canReadUsers = usePermission(Permission.canReadUsers)

  const residenceDescription = useMemo(() => {
    const nbBedRooms = move.residenceRooms?.find(room => room.key === Room.Bedroom)?.value ?? 0

    if (move.residenceType === ResidenceType.Apartment) {
      return t('apartmentBedroom', { count: nbBedRooms })
    }
    if (move.residenceType === ResidenceType.House) {
      return t('houseBedroom', { count: nbBedRooms })
    }
    if (move.residenceType === ResidenceType.Studio && nbBedRooms > 1) {
      return `${t('residenceType.studio.title')} (${t('rooms.bedroom.title', { count: nbBedRooms })})`
    }
    return t(`residenceType.${move.residenceType}.title`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [move])

  const originCityOrRegion = useMemo(() => {
    if ('city' in move.origin.address) {
      return capitalizeFirstLetter(move.origin.address.city, true)
    }
    if ('region' in move.origin.address) {
      return capitalizeFirstLetter(move.origin.address.region, true)
    }
  }, [move])

  const agentIdentification = useMemo(() => {
    if (!move.agent) {
      return null
    }
    return (
      <div>
        { capitalizeFirstLetter(move.agent.firstName) }
        { ' ' }
        { capitalizeFirstLetter(move.agent.lastName) }
      </div>
    )
  }, [move.agent])

  return (
    <Paper
      title={t('pages.admin.move.sidebar.summary.title')}
      titleIcon={<SummaryIcon />}
      compact
      className={className}
    >

      { move.agent && (
        <>
          <div className="flex gap-x-4">

            <AgentIcon className="mt-1 !text-[22px] text-gray-400" />

            { t('pages.admin.move.sidebar.summary.agent') }:

            { canReadUsers
              ? (
                <Link
                  to={Route.AdminUser}
                  params={{ userId: move.agent.id }}
                  variant={LinkVariant.Underlined}
                >
                  { agentIdentification }
                </Link>
                )
              : agentIdentification }

          </div>
          <hr className="my-4" />
        </>
      ) }

      <div className="flex gap-x-4">

        <HomeIcon className="mt-1 !text-[22px] text-gray-400" />

        <div>
          <div className="text-lg text-neutral-700">
            { residenceDescription }
          </div>

          <div className="text-neutral-500">
            { formatNumber(move.volumeCubicFeet) }
            { ' ' }
            { t('units.cubicFeet') }
            { ' ' }
            <span className="text-sm text-neutral-400">
              ({ formatNumber(move.weightPounds) }
              { ' ' }
              { t('units.pounds') })
            </span>
          </div>
        </div>

      </div>

      <div className="mt-2 flex gap-x-4">

        <MapIcon className="mt-1 !text-[22px] text-gray-400" />

        <div>
          <div className="text-lg text-neutral-700">
            { originCityOrRegion && (
              <>
                { originCityOrRegion }
                { ', ' }
              </>
            ) }
            <span className="text-sm text-gray-400">
              { formatDate(moveDate) }
            </span>
          </div>
        </div>
      </div>

      { selectedQuote && selectedCompanyName && subtotal && (
        <div
          className={mergeClassName(
            'mt-4 flex flex-col rounded-md border border-neutral-200',
            onEstimateClick && 'cursor-pointer',
          )}
          onClick={onEstimateClick}
        >
          <div className="flex gap-x-6 p-4">
            { selectedCompanyLogo && (
              <div className="flex w-[50px] shrink-0 items-center justify-center">
                <img
                  src={selectedCompanyLogo}
                  alt=""
                  className="max-h-[50px]"
                />
              </div>
            ) }
            <div>
              <div>
                <div className="text-base text-neutral-700">
                  { selectedCompanyName }
                </div>
              </div>
              <div>
                <div className="mt-1 text-xl text-neutral-700">
                  <span className="font-bold text-neutral-600">
                    <Price amount={subtotal} />
                  </span>
                  { ' ' }
                </div>
              </div>
            </div>
          </div>
          { hasCustomQuote && (
            <div className="flex items-center gap-x-2 border-y bg-slate-50 px-4 py-2 text-sm leading-tight text-gray-700">
              <span className="flex size-4 shrink-0 items-center justify-center rounded-full bg-slate-500 text-white">
                <AgentIcon className="!text-[12px]" />
              </span>
              { t('pages.admin.move.sidebar.summary.customQuote') }
            </div>
          ) }
        </div>
      ) }
    </Paper>
  )
}

export default SummaryBox
