import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import PauseIcon from '@mui/icons-material/PauseCircleFilled'

import { Cell, Label, Row, Value } from '../../../../components/cell'
import { type FullCompanyBranch } from '../../../../modules/adminCompanies'
import { formatDateTime, useDateFormatter } from '../../../../utils/date'
import LegalInformation from './LegalInformation'
import { mergeClassName } from '../../../../utils/mergeClassName'
import { getIndividualActions } from '../../../../components/dataGrid/DataGrid.util'
import { type Action } from '../../../../components/dataGrid'

type CompanyTabProps = {
  companyBranch: FullCompanyBranch
  actions?: Action[]
}

const CompanyTab: React.FC<CompanyTabProps> = ({
  companyBranch,
  actions,
}) => {
  const { t } = useTranslation()
  const formatDate = useDateFormatter()

  const individualActions = getIndividualActions(actions ?? [])
    .filter(({ filter }) => !filter || filter([companyBranch.id]))

  return (
    <div className="px-3">
      <div className="mb-2 flex flex-wrap gap-3">
        { individualActions.map(({ label, icon: Icon, callback, danger }) => (
          <Button
            key={label}
            onClick={(event) => { event.stopPropagation(); callback?.(companyBranch.id) }}
            variant="contained"
            color={danger ? 'error' : 'secondary'}
            size="small"
            startIcon={<Icon />}
            className="!leading-4 md:!leading-6"
          >
            { label }
          </Button>
        )) }
      </div>

      <Row>
        <Cell>
          <Label>
            { t('pages.admin.companies.branchPanel.createdAt') }
          </Label>
          <Value title={formatDateTime(companyBranch.createdAt)}>
            { formatDate(companyBranch.createdAt) }
          </Value>
        </Cell>

        { companyBranch.deletedAt
          ? (
            <Cell>
              <Label>
                { t('pages.admin.companies.branchPanel.deletedAt') }
              </Label>
              <Value title={formatDateTime(companyBranch.deletedAt)}>
                { formatDate(companyBranch.deletedAt) }
              </Value>
            </Cell>
            )
          : (
            <Cell>
              <Label>
                { t('pages.admin.companies.branchPanel.updatedAt') }
              </Label>
              <Value title={formatDateTime(companyBranch.updatedAt)}>
                { formatDate(companyBranch.updatedAt) }
              </Value>
            </Cell>
            ) }
      </Row>

      { !companyBranch.deletedAt && (
        <Row>
          <Cell>
            <Label>
              { t('pages.admin.companies.branchPanel.approvalStatus') }
            </Label>
            <Value
              className={mergeClassName(companyBranch.approved ? 'text-green-800' : 'text-red-800')}
            >
              { companyBranch.approved ? <CheckIcon /> : <CloseIcon /> }
              <span className="uppercase">
                { companyBranch.approved && t('status.approved') }
                {
                !companyBranch.approved &&
                companyBranch.setupProgress === 100 &&
                t('status.pending')
              }
                {
              !companyBranch.approved &&
              companyBranch.setupProgress < 100 &&
              (
                <>
                  { t('status.incomplete') }
                  { ' ' }
                  ({ companyBranch.setupProgress }%)
                </>
              )
              }
              </span>
            </Value>
          </Cell>
          <Cell>
            <Label>
              { t('pages.admin.companies.branchPanel.activeStatus') }
            </Label>
            <Value
              className={mergeClassName(companyBranch.active ? 'text-green-800' : 'text-orange-500')}
            >
              { companyBranch.active ? <CheckIcon /> : <PauseIcon /> }
              <span className="uppercase">
                { companyBranch.active ? t('status.active') : t('status.paused') }
              </span>
            </Value>
          </Cell>
        </Row>
      ) }
      <LegalInformation companyBranch={companyBranch} />
    </div>
  )
}

export default CompanyTab
