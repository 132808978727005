import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import usePasswordValidator from '../../../form/fields/PasswordField/usePasswordValidator'

const useValidationSchema = () => {
  const { t } = useTranslation()
  const validatePassword = usePasswordValidator()

  return Yup.object({
    password: validatePassword({ required: true }),
    passwordConfirmation: Yup.string().required().oneOf([Yup.ref('password')], String(t('forms.fields.password.errors.passwordsMustMatch'))),
  })
}

export default useValidationSchema
