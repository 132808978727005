import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'

import Modal from '../../../../MuiModal'
import Step1 from './Step1'
import Step2 from './Step2'

export type QrCodeData = {
  secret: string
  qrCode: string
}

type EnableTwoFactorModalProps = {
  open: boolean
  onClose?: () => void
}

const EnableTwoFactorModal: React.FC<EnableTwoFactorModalProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation()
  const [step, setStep] = useState(1)
  const [qrCodeData, setQrCodeData] = useState<QrCodeData>()
  const [success, setSuccess] = useState(false)
  const nbSteps = 2

  const onStepComplete = () => {
    if (step === nbSteps) {
      setSuccess(true)
      return
    }
    setStep(step + 1)
  }

  const closeModal = () => {
    setStep(1)
    setQrCodeData(undefined)
    setSuccess(false)
    onClose?.()
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return <Step1 onComplete={onStepComplete} onClose={closeModal} step={step} nbSteps={nbSteps} setQrCodeData={setQrCodeData} />
      case 2:
        if (!qrCodeData) {
          return null
        }
        return <Step2 onComplete={onStepComplete} onClose={closeModal} step={step} nbSteps={nbSteps} qrCodeData={qrCodeData} />
      default:
        return null
    }
  }

  return (
    <Modal
      width={450}
      open={open}
      onClose={closeModal}
    >
      <Typography variant="h2">
        { t('pages.users.profile.security.mfa.modal.enableMfaTitle') }
      </Typography>

      { success
        ? (
          <>
            <Alert severity="success">
              { t('pages.users.profile.security.mfa.modal.enabledSuccess') }
            </Alert>
            <br />
            <Button variant="contained" color="secondary" onClick={closeModal}>
              { t('actions.close') }
            </Button>
          </>
          )
        : renderStep() }
    </Modal>
  )
}

export default EnableTwoFactorModal
