import { type ReportInterval } from '../../../modules/adminAccounting'
import { mergeClassName } from '../../../utils/mergeClassName'
import Paper, { type PaperProps } from '../../../components/Paper'
import ReportIntervalDates from './ReportIntervalDates'

type ReportButtonProps = {
  reportName: React.ReactNode
  reportDescription?: React.ReactNode
  Icon?: React.FC<{ className?: string }>
  interval?: ReportInterval
  onClick?: () => void
} & PaperProps

const ReportButton: React.FC<ReportButtonProps> = ({
  reportName,
  reportDescription,
  Icon,
  interval,
  ...props
}) => {
  return (
    <Paper
      className={mergeClassName(
        '!m-0 w-full cursor-pointer rounded-2xl px-2 py-8 md:w-[calc(50%-16px)] lg:w-[calc(33.33333%-22px)] xl:pr-8 2xl:pl-4 child:h-full',
        'transition-all duration-200 hover:scale-[1.01] hover:shadow-xl hover:border-gray-300',
      )}
      {...props}
    >
      <div className="flex h-full items-center gap-x-6">
        <div className="flex items-center">
          { Icon && (<Icon className="!text-[36px] text-neutral-300" />) }
        </div>
        <div className="w-full">
          <div className="text-xl">
            { reportName }
          </div>
          { interval && (
            <div className="mb-4 mt-1 pb-2 font-sans text-sm text-neutral-600 ">
              <ReportIntervalDates interval={interval} />
            </div>
          ) }
          { reportDescription && (
            <div className="mt-1 text-slate-400">
              { reportDescription }
            </div>
          ) }
        </div>
      </div>
    </Paper>
  )
}

export default ReportButton
