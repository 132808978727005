import { type Quote } from '../quotes.models'
import { type ConstraintsFilter } from '../quotes.types'
import { getCheaperQuotePrice } from './quotes.utils'

/**
 * cheapest price on top (date doesn't matter)
 */
export const byCheapestPrice = (quotes: Quote[]) => {
  const quotesCheapestPrice: Array<[Quote, number]> = quotes.map(quote => ([
    quote,
    getCheaperQuotePrice(quote)?.price ?? quote.subtotal.price,
  ]))

  quotesCheapestPrice.sort(([a, aCheapest], [b, bCheapest]) => {
    return (
      aCheapest - bCheapest
    )
  })
  return quotesCheapestPrice.map(([quote]) => quote)
}

/**
 * cheapest price on top for the request date
 */
export const byPrice = (quotes: Quote[]) => {
  quotes.sort((a, b) => {
    return (
      a.subtotal.price - b.subtotal.price
    )
  })
  return quotes
}

/**
 * less constraints on top
 */
export const byConstraints = (quotes: Quote[], filter?: ConstraintsFilter) => {
  quotes.sort((a, b) => {
    const constraintsA = (filter?.(a) ?? a.constraints)?.length ?? 0
    const constraintsB = (filter?.(b) ?? a.constraints)?.length ?? 0

    return (
      constraintsA - constraintsB
    )
  })
  return quotes
}
