import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { dateFnsLocalizer } from 'react-big-calendar'
import { enCA } from 'date-fns/locale/en-CA'
import { frCA } from 'date-fns/locale/fr-CA'
import { format } from 'date-fns/format'
import { parse } from 'date-fns/parse'
import { startOfWeek } from 'date-fns/startOfWeek'
import { getDay } from 'date-fns/getDay'

import { useLanguage } from '../../modules/i18n'

const useCalendarLanguageSettings = () => {
  const { t } = useTranslation()
  const language = useLanguage()

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales: {
      en: enCA,
      fr: frCA,
    },
  })

  const messages = useMemo(() => ({
    week: t('components.calendar.week'),
    work_week: t('components.calendar.workWeek'),
    day: t('components.calendar.day'),
    month: t('components.calendar.month'),
    previous: t('components.calendar.previous'),
    next: t('components.calendar.next'),
    today: t('components.calendar.today'),
    showMore: (count: number) => t('components.calendar.showMore', { count }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [language])

  return {
    localizer,
    culture: language,
    messages,
  }
}

export default useCalendarLanguageSettings
