import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import ErrorIcon from '@mui/icons-material/Warning'

import { useAuthenticationState } from '../../modules/users'
import { useMonitoring } from '../../modules/monitoring'
import Route from '../../app/Route.enum'
import ContainedPage from '../../components/layout/ContainedPage'
import Link from '../../components/Link'
import FloatingPage from '../../components/layout/FloatingPage/FloatingPage'

const Error404Page: React.FC = () => {
  const { t } = useTranslation()
  const { isAuthenticated } = useAuthenticationState()
  const { logWarn } = useMonitoring()

  const Wrapper = isAuthenticated ? ContainedPage : FloatingPage

  useEffect(() => {
    logWarn(`Error 404 : ${window.location.href}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrapper>
      <div className={clsx(
        isAuthenticated ? 'flex min-h-[calc(100dvh-250px)] flex-col items-center justify-center px-6 text-center lg:min-h-0 lg:pt-[10vh]' : 'text-center',
      )}
      >
        <ErrorIcon className="!text-[5rem] text-destructive" />
        <br />
        <h1 className="mb-3 font-body2 text-4xl lg:text-6xl">{ t('pages.404.title') }</h1>
        <h2 className="mb-6 whitespace-pre-line font-body2 text-lg lg:text-2xl">{ t('pages.404.subtitle') }</h2>
        <Link to={Route.Dashboard} variant="primary">{ t('actions.backToHomepage') }</Link>
      </div>
    </Wrapper>
  )
}

export default Error404Page
