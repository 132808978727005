import { gql } from '@apollo/client'

export const exportSalesReport = gql`
query ExportSalesReport($dateRange: DateRangeFilter!) {
  admin_exportSalesReport(dateRange: $dateRange)
}
`

export const exportCustomersBalanceReport = gql`
query ExportCustomersBalanceReport($dateRange: DateRangeFilter!) {
  admin_exportCustomersBalanceReport(dateRange: $dateRange)
}
`
