import ConversionRow from './ConversionRow'
import { useAdminDashboardMetrics } from '../../../../modules/dashboard'
import { Card } from '../../../../components/card'
import { useTranslation } from 'react-i18next'

const Conversions: React.FC = () => {
  const { t } = useTranslation()
  const { data: dashboardMetrics } = useAdminDashboardMetrics()

  const conversionMetrics = dashboardMetrics?.moves?.lastMonthConversions

  if (!conversionMetrics) {
    return null
  }

  const quoteAcceptedRatio = conversionMetrics.receivedQuotes
    ? Math.round((
      conversionMetrics.receivedQuotes === 0
        ? 0
        : conversionMetrics.acceptedQuotes / conversionMetrics.receivedQuotes
    ) * 100)
    : 0

  const meetingBookedRatio = conversionMetrics.agentRequired
    ? Math.round((
      conversionMetrics.agentRequired === 0
        ? 0
        : conversionMetrics.meetingBooked / conversionMetrics.agentRequired
    ) * 100)
    : 0

  return (
    <div className="flex flex-col">
      <h2 className="mb-4 mt-5 text-xl leading-6 text-gray-900">
        { t('pages.admin.dashboard.conversions.title') }
        { ' ' }
        <span className="text-base text-gray-500">(
          { t('pages.admin.dashboard.last30Days') }
          )
        </span>
      </h2>

      <Card className="grow xl:col-span-2 xl:p-8">

        <div className="flex justify-center">

          <div className="flex flex-col">

            <h3 className="mb-2 mt-4 text-center text-lg leading-6 text-gray-800 xl:text-left">
              { t('pages.admin.dashboard.conversions.acceptedQuote.title') }
            </h3>

            <ConversionRow
              value={quoteAcceptedRatio}
              stats={{
                receivedQuotes: {
                  label: t('pages.admin.dashboard.conversions.acceptedQuote.receivedQuotes'),
                  value: conversionMetrics.receivedQuotes,
                },
                acceptedQuotes: {
                  label: t('pages.admin.dashboard.conversions.acceptedQuote.acceptedQuote'),
                  value: conversionMetrics.acceptedQuotes,
                },
              }}
            />

            <h3 className="mb-2 mt-4 text-center text-lg leading-6 text-gray-800 xl:text-left">
              { t('pages.admin.dashboard.conversions.meetingBooked.title') }
            </h3>

            <ConversionRow
              value={meetingBookedRatio}
              stats={{
                receivedQuotes: {
                  label: t('pages.admin.dashboard.conversions.meetingBooked.agentRequired'),
                  value: conversionMetrics.agentRequired,
                },
                acceptedQuotes: {
                  label: t('pages.admin.dashboard.conversions.meetingBooked.meetingsBooked'),
                  value: conversionMetrics.meetingBooked,
                },
              }}
            />
          </div>
        </div>
      </Card>
    </div>
  )
}

export default Conversions
