import { useEffect } from 'react'

import Spinner from '../../../components/Spinner'
import { useNavigate } from '../../navigation'
import { useAuthenticationState } from '../core/authentication'
import EmailVerificationGuard from './EmailVerificationGuard'
import Route from '../../../app/Route.enum'
import { useLocation } from 'react-router-dom'
import { useConfig } from '../../config'

type ProtectedPageProps = {
  Page: React.FC
}

const ProtectedPage: React.FC<ProtectedPageProps> = ({ Page }) => {
  const { isAuthenticated } = useAuthenticationState()
  const { navigate } = useNavigate()
  const { pathname } = useLocation()
  const loginRedirectionUrlKey = useConfig('users.authentication.loginRedirectionUrlKey')

  useEffect(() => {
    if (!isAuthenticated) {
      if (sessionStorage) {
        sessionStorage.setItem(loginRedirectionUrlKey, pathname)
      }
      navigate(Route.Login)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, navigate])

  if (isAuthenticated) {
    return <EmailVerificationGuard><Page /></EmailVerificationGuard>
  } else {
    return <Spinner floating />
  }
}

export const protectPage = (page: React.FC): React.FC => {
  const protectedPage = () => <ProtectedPage Page={page} />
  return protectedPage
}
