import { Fragment } from 'react'
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react'
import CloseIcon from '@mui/icons-material/Close'

import { mergeClassName } from '../../utils/mergeClassName'

type CompanyBranchPanelProps = {
  open: boolean
  appear?: boolean
  onClose: () => void
  className?: string
  large?: boolean
  header?: React.ReactNode
  children?: React.ReactNode
}

const CompanyBranchPanel: React.FC<CompanyBranchPanelProps> = ({
  open,
  appear = false,
  onClose,
  className,
  large = false,
  header,
  children,
}) => {
  return (
    <Transition
      show={open}
      appear={appear}
      as={Fragment}
    >
      <Dialog
        as="div"
        className="fixed top-[60px] z-50"
        onClose={onClose}
      >
        { /* backdrop */ }
        <TransitionChild
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 top-[60px] bg-gray-600/50 backdrop-blur-sm" />
        </TransitionChild>

        <TransitionChild
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <DialogPanel
            className={mergeClassName(
              'fixed right-0 mr-[5px] mt-[5px] md:mr-[15px] md:mt-[15px] inline-flex h-[calc(100dvh-70px)] md:h-[calc(100dvh-90px)] w-[calc(100dvw-10px)] md:w-[calc(100dvw-30px)] overflow-y-auto rounded-md border-t bg-white lg:m-0 lg:h-[calc(100dvh-60px)] lg:w-[800px] lg:max-w-[90vw] lg:rounded-r-none',
              large && 'lg:w-[1250px]',
            )}
          >
            <div className="fixed right-[15px] top-[80px] md:right-[25px] md:top-[90px] lg:right-2 lg:top-[75px]">
              <CloseIcon
                className="cursor-pointer !text-[32px]"
                onClick={onClose}
              />
            </div>
            <div className="flex size-full flex-col px-0 py-3 lg:p-3">
              { header }

              { /* hack to prevent headless auto-focus */ }
              { /* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */ }
              <a href="#" className="absolute opacity-0" onClick={event => { event.preventDefault() }} />
              <div
                className={mergeClassName(
                  'h-full grow overflow-auto',
                  className,
                )}
              >
                { children }
              </div>
            </div>

          </DialogPanel>
        </TransitionChild>
      </Dialog>
    </Transition>
  )
}

export default CompanyBranchPanel
