import {
  type CompanyBranchSeasonsSeason,
  type CompanyBranchSeasons,
  Season,
} from '../../../../modules/companies'
import { type SeasonsFormValues } from './SeasonsForm.type'

const companyBranchSeasonsSeasonToFormData = ({ enabled, from, to }: CompanyBranchSeasonsSeason): CompanyBranchSeasonsSeason<string> => {
  return {
    enabled,
    from: from ? { day: String(from.day), month: String(from.month) } : undefined,
    to: to ? { day: String(to.day), month: String(to.month) } : undefined,
  }
}

export const companyBranchSeasonsToFormData = (companyBranchSeasons: CompanyBranchSeasons): Partial<SeasonsFormValues> => {
  return Object.fromEntries(
    Object
      .entries(companyBranchSeasons)
      .filter(([v]) => !v.startsWith('_'))
      .map(([season, seasonData]) => ([season, companyBranchSeasonsSeasonToFormData(seasonData)])),
  )
}

export const formatFormOutput = (formData: Partial<SeasonsFormValues>): CompanyBranchSeasons => {
  const seasonsEntries = Object.values(Season).map(season => (
    [
      season,
      {
        enabled: !!formData[season]?.enabled,
        from: {
          day: formData[season]?.from?.day ? parseInt(formData[season]?.from?.day ?? '') : undefined,
          month: formData[season]?.from?.month ? parseInt(formData[season]?.from?.month ?? '') : undefined,
        },
        to: {
          day: formData[season]?.to?.day ? parseInt(formData[season]?.to?.day ?? '') : undefined,
          month: formData[season]?.to?.month ? parseInt(formData[season]?.to?.month ?? '') : undefined,
        },
      },
    ]
  ))
  return Object.fromEntries(seasonsEntries)
}
