import { Canvg } from 'canvg'
import { FileType } from '../FilesDropzone/fileType.enum'

/**
 * this uses Canvg library to convert svg into png
 * Canvg resize the image and respect the aspect ratio
 * We only run this on image without size
 */
const handleSvg = async (file: File): Promise<File | string> => await new Promise((resolve) => {
  const fallback = () => { resolve(file) }

  if (file.type !== FileType.SVG) {
    fallback(); return
  }

  const reader = new FileReader()
  reader.onload = (readerEvent) => {
    if (!readerEvent.target) {
      fallback(); return
    }

    const image = new Image()
    image.onload = () => {
      if (image.width && image.height) {
        /* only handle svg without size */
        fallback(); return
      }

      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')

      if (!context || !readerEvent.target) {
        fallback(); return
      }

      Canvg.from(context, readerEvent.target.result as string).then(canvg => {
        canvg.resize(300, 300, 'xMidYMid meet')
        canvg.render({
          enableRedraw: true,
        }).then(() => {
          resolve(canvas.toDataURL('image/png'))
        }).catch(fallback)
      }).catch(fallback)
    }
    image.src = readerEvent.target.result as string
  }
  reader.readAsDataURL(file)
})

export default handleSvg
