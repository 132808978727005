/* eslint-disable @typescript-eslint/no-misused-promises */
import { useTranslation } from 'react-i18next'
import PromoCodeIcon from '@mui/icons-material/LocalOfferOutlined'
import CancelIcon from '@mui/icons-material/CancelOutlined'

import { type FullMove } from '../../../../../modules/adminMoves'
import { useMoveState } from '../../../../../modules/moves'
import { usePromoCodeLabel } from '../../../../../modules/adminPromoCodes'
import Paper from '../../../../../components/Paper'
import ApplyPromoCodeForm from '../../../../../components/forms/promoCodes/ApplyPromoCodeForm'
import ConfirmationModal from '../../../../../components/ConfirmationModal'
import { ActionName, useActions } from '../../useActions'
import MoveActionButton from '../../MoveActionButton'

type PromoCodeBoxProps = {
  move: FullMove
}

const PromoCodeBox: React.FC<PromoCodeBoxProps> = ({
  move,
}) => {
  const { t } = useTranslation()
  const {
    canApplyPromoCode,
    promoCode,
    currency,
  } = useMoveState().forMove(move)

  const { actions, modalProps } = useActions(move)
  const applyPromoCode = actions[ActionName.ApplyPromoCode].callback

  const getLabel = usePromoCodeLabel()

  return (
    <Paper
      title={t('pages.admin.move.sidebar.promoCode.title')}
      titleIcon={<PromoCodeIcon />}
      compact
    >
      { promoCode &&
          (
            <>
              <div className="flex items-center gap-x-4 rounded-md border p-4 text-green-700">
                <span className="ml-2 flex size-8 shrink-0 items-center justify-center rounded-full bg-green-700 text-white child:!text-[20px]">
                  <PromoCodeIcon />
                </span>

                <div>
                  { promoCode.code }
                  <div className="font-body2 text-sm font-bold text-green-600 child:gap-x-1">
                    { getLabel(promoCode, { standalone: true, currency }) }
                  </div>
                </div>
              </div>

              <MoveActionButton
                move={move}
                actionName={ActionName.RemovePromoCode}
                overrideAction={{
                  Icon: CancelIcon,
                  label: t('actions.remove'),
                }}
                className="!mt-4"
              />
            </>
          ) }

      { !promoCode && canApplyPromoCode && (
        <div>
          <ApplyPromoCodeForm
            onSubmit={applyPromoCode}
          />
        </div>
      ) }

      { !promoCode && !canApplyPromoCode && (
        <div className="inline-flex items-center gap-x-4">

          <PromoCodeIcon className="mt-1 !text-[22px] text-gray-400" />

          <div className="text-neutral-400">
            { t('pages.admin.move.sidebar.promoCode.noPromoCode') }
          </div>
        </div>
      ) }

      <ConfirmationModal {...modalProps} />
    </Paper>
  )
}

export default PromoCodeBox
