import { type JobRequestForPartner } from '../../../modules/jobRequests'
import { getMoveDate } from '../../../modules/moves/core/moveState/moveState.utils'
import JobsTabs from '../../../components/move/JobsTabs'
import Job from './Job'

type JobsProps = {
  jobRequest: JobRequestForPartner
}

const Jobs: React.FC<JobsProps> = ({
  jobRequest,
}) => {
  return (
    <>
      <JobsTabs
        move={jobRequest.move}
        date={getMoveDate(jobRequest.quote.date, jobRequest.move.originTimezone)}
      />
      <Job jobRequest={jobRequest} />
    </>
  )
}

export default Jobs
