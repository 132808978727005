import ContainedPage, { type ContainedPageProps } from '../ContainedPage'
import PageContent from '../PageContent/PageContent'

type LeftSidebarPageProps = {
  sidebar: React.ReactNode
} & ContainedPageProps

const LeftSidebarPage: React.FC<LeftSidebarPageProps> = ({
  sidebar,
  children,
  ...pageProps
}) => {
  return (
    <ContainedPage {...pageProps}>
      <PageContent>
        <div className="flex">
          <div className="hidden shrink-0 flex-col lg:flex lg:w-[325px] xl:w-[400px]">
            <div className="sticky top-[10px] mt-[10px]">
              { sidebar }
            </div>
          </div>
          <div className="grow py-8 lg:py-6">
            { children }
          </div>
        </div>
      </PageContent>
    </ContainedPage>
  )
}

export default LeftSidebarPage
