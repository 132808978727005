import { type TypographyProps } from '@mui/material/Typography'

import GradientUnderline from '../../GradientUnderline'

const Title: React.FC<TypographyProps> = (props) => {
  return (
    <GradientUnderline
      variant="h1"
      fontSize="1.8rem"
      lineHeight="2.4rem"
      margin="2rem 0 1rem"
      {...props}
    />
  )
}

export default Title
