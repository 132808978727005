import { useTranslation } from 'react-i18next'
import IdentificationIcon from '@mui/icons-material/Person'
import SecurityIcon from '@mui/icons-material/Security'
import deleteAccountIcon from '@mui/icons-material/PersonOff'
import LogoutIcon from '@mui/icons-material/Logout'

import { useCurrentUser, useLogoutAction } from '../../../modules/users'
import SidebarMenu, { SidebarMenuLink } from '../../layout/SidebarMenu'
import Route from '../../../app/Route.enum'

const ProfileSidebar: React.FC = () => {
  const { t } = useTranslation()
  const { data: user } = useCurrentUser()
  const { logout } = useLogoutAction()

  const title = user?.firstName ? `${user.firstName} ${String(user?.lastName)}` : undefined

  const onLogout = (event: React.MouseEvent) => {
    event.preventDefault()
    logout()
  }

  return (
    <SidebarMenu title={title}>
      <SidebarMenuLink to={Route.ProfileIdentification} Icon={IdentificationIcon}>
        { t('pages.users.profile.identification.title') }
      </SidebarMenuLink>

      <SidebarMenuLink to={Route.ProfileSecurity} Icon={SecurityIcon}>
        { t('pages.users.profile.security.title') }
      </SidebarMenuLink>

      <SidebarMenuLink to={Route.ProfileDeleteAccount} Icon={deleteAccountIcon}>
        { t('pages.users.profile.deleteAccount.title') }
      </SidebarMenuLink>

      <SidebarMenuLink to="#" onClick={onLogout} Icon={LogoutIcon}>
        { t('header.accountMenu.logout') }
      </SidebarMenuLink>
    </SidebarMenu>
  )
}

export default ProfileSidebar
