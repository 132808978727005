import { Views, type EventProps } from 'react-big-calendar'

import { type Event } from './EventCalendar.types'
import { useCalendar } from '../CalendarContext'

const EventBox: React.FC<EventProps<Event>> = ({ event }) => {
  const { title, content } = event
  const { view } = useCalendar()
  const isMonthlyView = view === Views.MONTH

  return (
    <>
      { title }
      { !isMonthlyView && content }
    </>
  )
}

export default EventBox
