import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import UserIcon from '@mui/icons-material/AccountCircleOutlined'
import NewPartnersIcon from '@mui/icons-material/PersonAddAltOutlined'
import AllPartnersIcon from '@mui/icons-material/GroupsOutlined'
import CompaniesIcon from '@mui/icons-material/BusinessOutlined'

import {
  cumulativeData,
  fillDays,
  getMetricCollectionSum,
  useAdminDashboardMetrics,
} from '../../../modules/dashboard'
import Spinner from '../../../components/Spinner'
import ServerError from '../../../components/errors/ServerError'
import { Cards, Card, CardTitle, CardMetric } from '../../../components/card'
import { formatNumber } from '../../../utils/number'

const UsersAndCompaniesStats: React.FC = () => {
  const { t } = useTranslation()
  const { data: dashboardMetrics, loading, error } = useAdminDashboardMetrics({ reload: true })
  const usersMetrics = dashboardMetrics?.users
  const companiesMetrics = dashboardMetrics?.companies

  const newPartners = usersMetrics?.registeredPartners ? getMetricCollectionSum(usersMetrics?.registeredPartners) : 0
  const totalPartners = usersMetrics?.totalPartners ?? 0
  const totalActiveBranches = companiesMetrics?.activeBranches ?? 0

  const partnerHistory = useMemo(() => {
    if (!usersMetrics) {
      return []
    }
    const initialValue = Math.max(totalPartners - newPartners, 0)
    return cumulativeData(fillDays(usersMetrics.registeredPartners, 30), initialValue)
  }, [usersMetrics, newPartners, totalPartners])

  return (
    <div>
      { loading && <Spinner /> }
      { error && <ServerError error={error} /> }

      { usersMetrics && (
        <div>

          <h2 className="mt-5 text-xl leading-6 text-gray-900">
            { t('pages.admin.dashboard.usersAndCompanies.title') }
          </h2>

          <Cards className="mt-3">
            <Card
              icon={<UserIcon />}
            >
              <CardTitle>
                { t('pages.admin.dashboard.usersAndCompanies.currentlyOnline') }
              </CardTitle>
              <CardMetric>
                { formatNumber(usersMetrics.currentlyOnline) }
              </CardMetric>
            </Card>

            <Card
              history={partnerHistory}
              icon={<NewPartnersIcon />}
            >
              <CardTitle>
                { t('pages.admin.dashboard.usersAndCompanies.newPartners') }
              </CardTitle>
              <CardMetric>
                { formatNumber(newPartners) }
              </CardMetric>
            </Card>

            <Card
              icon={<AllPartnersIcon />}
            >
              <CardTitle>
                { t('pages.admin.dashboard.usersAndCompanies.totalPartners') }
              </CardTitle>
              <CardMetric>
                { formatNumber(totalPartners) }
              </CardMetric>
            </Card>

            <Card
              icon={<CompaniesIcon />}
            >
              <CardTitle>
                { t('pages.admin.dashboard.usersAndCompanies.activeCompanies') }
              </CardTitle>
              <CardMetric>
                { formatNumber(totalActiveBranches) }
              </CardMetric>
            </Card>
          </Cards>
        </div>
      ) }

    </div>
  )
}

export default UsersAndCompaniesStats
