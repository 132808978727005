import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { type CommunicationFormValues } from './CommunicationForm.type'

const useValidationSchema = () => {
  const { t } = useTranslation()

  const validateEmailCC = Yup.string().test((value, { createError, options, parent }) => {
    if (value) {
      for (const email of value.split(',')) {
        if (!Yup.string().email().isValidSync(email.trim())) {
          return createError({ message: t('formValidation.email') })
        }
      }
    }

    return true
  })

  return Yup.object<CommunicationFormValues>({
    bookingEmailCC: validateEmailCC,
  }) as Yup.ObjectSchema<CommunicationFormValues, CommunicationFormValues>
}

export default useValidationSchema
