import { useTranslation } from 'react-i18next'

import { ReactComponent as AnimatedLogo } from './AnimatedLogo.svg'
import './style.scss'

const QuotesSpinner: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className="flex size-full flex-col items-center justify-center py-12">
      <div className="relative h-[68px] w-[120px]">
        <div className="spinner-boxes">
          <div className="box box1" />
          <div className="box box2" />
          <div className="box box3" />
          <div className="box box4" />
        </div>
      </div>
      <div className="animated-logo w-[75vw] py-2 md:w-[350px]">
        <AnimatedLogo />
      </div>
      <div className="text-center font-body2 text-lg uppercase leading-tight text-slate-400">
        { t('pages.admin.quotes.loadingSlogan') }
      </div>
    </div>
  )
}

export default QuotesSpinner
