import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

const VALID_PHONE_REGEX = /^\+?[0-9]{11}$/i

type ValidatorOptions = {
  required?: boolean
}

export const usePhoneNumberValidator = () => {
  const { t } = useTranslation()

  return ({ required = false }: ValidatorOptions = {}) => {
    const numberValidation = Yup
      .string()
      .matches(VALID_PHONE_REGEX, t('formValidation.phone'))

    return Yup.object({
      number: required
        ? numberValidation.required(t('formValidation.required'))
        : numberValidation,
    })
  }
}
