import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, useWatch, useFormState } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Box from '@mui/material/Box'

import {
  Form,
  Input,
  PasswordField,
  PhoneNumberField,
  SubmitButton,
} from '../../../form'
import { type RegisterPayload } from '../../../../modules/users'
import Link from '../../../Link'
import { type RegisterFormPayload } from './RegisterFormPayload.type'
import useValidationSchema from './validationSchema'
import Route from '../../../../app/Route.enum'
import { Label, Value } from '../../../cell'

type RegisterFormProps = {
  onSubmit: (payload: RegisterPayload) => Promise<any>
  initialData?: Partial<RegisterFormPayload>
  emailReadOnly?: boolean
}

const RegisterForm: React.FC<RegisterFormProps> = ({
  onSubmit,
  initialData,
  emailReadOnly = false,
}) => {
  const { t } = useTranslation()
  const schema = useValidationSchema()
  const form = useForm<RegisterFormPayload>({
    resolver: yupResolver(schema),
    defaultValues: initialData,
  })
  const { submitCount } = useFormState({ control: form.control })
  const [password, passwordConfirmation] = useWatch({ control: form.control, name: ['password', 'passwordConfirmation'] })

  const preSubmit = async (data: RegisterFormPayload) => {
    const formattedData = { ...data }
    delete (formattedData as any).passwordConfirmation
    return await onSubmit(formattedData)
  }

  /**
   * trigger validation on "new password confirmation" when "new password" is updated
   * required since a field can become valid without changing
   */
  useEffect(() => {
    if (submitCount < 1) {
      return
    }
    form.setValue('passwordConfirmation', passwordConfirmation, { shouldValidate: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitCount, password])

  return (
    <Form form={form} onSubmit={preSubmit}>
      <Box my={2}><Link to={Route.Login} variant="primary">{ t('pages.users.register.alreadyHaveAnAccount') }</Link></Box>

      { emailReadOnly
        ? (
          <>
            <Label>{ t('forms.fields.email.label') }</Label>
            <Value>{ initialData?.email }</Value>
          </>
          )
        : (
          <Input
            name="email"
            label={t('forms.fields.email.label')}
            variant="standard"
            margin="normal"
            required
          />
          ) }

      <PasswordField
        showValidations
        name="password"
        label={t('forms.fields.password.label')}
        variant="standard"
        margin="normal"
        required
      />

      <PasswordField
        name="passwordConfirmation"
        label={t('forms.fields.passwordConfirmation.label')}
        variant="standard"
        margin="normal"
        required
      />

      <Input
        name="firstName"
        label={t('forms.fields.firstName.label')}
        variant="standard"
        margin="normal"
        required
      />

      <Input
        name="lastName"
        label={t('forms.fields.lastName.label')}
        variant="standard"
        margin="normal"
        required
      />

      <div className="mt-3">
        <PhoneNumberField
          name="phoneNumber"
          label={String(t('forms.fields.phoneNumber.label'))}
          variant="standard"
          required
        />
      </div>

      <Input
        name="position"
        label={t('forms.fields.position.label')}
        variant="standard"
        margin="normal"
        required
      />

      <Box mt={4}>
        <SubmitButton size="large">{ t('actions.signUp') }</SubmitButton>
        <Link
          to={Route.Dashboard}
          className="ml-2 mt-6 block lg:ml-8 lg:mt-0 lg:inline-block"
          variant="primary"
        >
          { t('actions.cancel') }
        </Link>
      </Box>
    </Form>
  )
}

export default RegisterForm
