/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import ArrowdIcon from '@mui/icons-material/ArrowForward'
import TruckIcon from '@mui/icons-material/LocalShippingOutlined'
import MenIcons from '@mui/icons-material/PeopleOutlineOutlined'
import CalendarIcon from '@mui/icons-material/CalendarMonthOutlined'

import { type QuotesRequestPayload } from '../../../modules/adminQuotes'
import { useDateFormatter, ignoreTimezone } from '../../../utils/date'
import PageContent from '../../layout/PageContent'

type QuoteTopBarProps = {
  quotesRequest?: QuotesRequestPayload
  onGetQuotes?: () => Promise<void>
  onEdit?: () => void | Promise<void>
  onSubmit?: () => void | Promise<void>
  ctaLabel?: string
  wide?: boolean
}

const QuoteTopBar: React.FC<QuoteTopBarProps> = ({
  quotesRequest,
  onGetQuotes,
  onEdit,
  ctaLabel,
  wide = false,
}) => {
  const { t } = useTranslation()
  const formatDate = useDateFormatter()

  const getNbTrucks = () => {
    if (!quotesRequest) {
      return 0
    }
    return quotesRequest.nbTrucks.reduce((acc, curr) => {
      return acc + curr.value
    }, 0)
  }

  if (!quotesRequest) {
    return null
  }

  return (
    <div className="sticky top-0 z-[3] border-b border-zinc-200 bg-stone-100 py-1 shadow-neutral-200/80 lg:border-b-0 lg:shadow-md">
      <PageContent wide={wide}>

        <div className="flex flex-col items-center justify-between gap-1 lg:flex-row lg:gap-4">

          <div className="flex h-[40px] flex-wrap items-center justify-center gap-2 overflow-hidden py-2 md:h-auto md:overflow-visible lg:justify-start lg:gap-4">
            <div className="flex items-center gap-1 whitespace-nowrap rounded border bg-white px-1 font-body2 text-sm text-neutral-500 lg:gap-3 lg:px-3 lg:text-lg">

              { ('country' in quotesRequest.origin.address)
                ? (
                  <div>
                    { quotesRequest.origin.address?.postalCode ? quotesRequest.origin.address.postalCode.substring(0, 3).toUpperCase() : quotesRequest.origin.address.city }
                  </div>
                  )
                : (
                  <div className="max-w-[300px] truncate">
                    { quotesRequest.origin.address.placeTextLine1 }
                  </div>
                  ) }

              <ArrowdIcon className="!text-neutral-400" />

              { ('country' in quotesRequest.destination.address)
                ? (
                  <div>
                    { quotesRequest.destination.address.postalCode ? quotesRequest.destination.address.postalCode.substring(0, 3).toUpperCase() : quotesRequest.destination.address.city }
                  </div>
                  )
                : (
                  <div className="max-w-[300px] truncate">
                    { quotesRequest.destination.address.placeTextLine1 }
                  </div>
                  ) }

            </div>

            <div className="whitespace-nowrap rounded border bg-white px-1 text-sm text-neutral-500 lg:px-3 lg:text-lg">
              <TruckIcon className="!text-neutral-400" /> { getNbTrucks() }
            </div>

            <div className="whitespace-nowrap rounded border bg-white px-1 text-sm text-neutral-500 lg:px-3 lg:text-lg">
              <MenIcons className="!text-neutral-400" /> { quotesRequest.nbMen }
            </div>

            <div className="flex items-center gap-2 whitespace-nowrap rounded border bg-white p-[2px] text-sm text-neutral-500 lg:px-2 lg:py-0 lg:text-lg">
              <CalendarIcon className="!text-[20px] !text-neutral-400" />
              <span>
                { formatDate(ignoreTimezone(quotesRequest.movingDate), {
                  showYear: false,
                  shortMonth: true,
                }) }
              </span>
            </div>
          </div>

          <div className="flex grow items-center justify-between gap-2 pb-2 lg:pb-0">
            { onEdit && (
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={onEdit}
                >
                  { t('actions.edit') }
                </Button>
              </div>
            ) }

            { onGetQuotes && ctaLabel && (
              <Button
                variant="contained"
                onClick={onGetQuotes}
                className="!leading-tight lg:!leading-7"
              >
                { ctaLabel }
              </Button>
            ) }
          </div>
        </div>
      </PageContent>
    </div>
  )
}

export default QuoteTopBar
