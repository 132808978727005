import { useTranslation } from 'react-i18next'

import { Input } from '../../../form'
import SelectMonths from './SelectMonths'

type TemporalityRecurrentRangeFormProps = {
  prefixName: string
}

const TemporalityRecurrentRangeForm: React.FC<TemporalityRecurrentRangeFormProps> = ({
  prefixName,
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex w-full flex-col gap-2 lg:flex-row">
      <div className="flex gap-2">
        <div className="flex gap-2">
          <div className="w-[30px] shrink-0 pt-2 text-right lg:w-auto lg:text-left">
            { t('date.from') }
          </div>
          <Input
            name={`${prefixName}.fromDay`}
            size="small"
            style={{ width: 60 }}
            inputProps={{ maxLength: 2, className: 'text-center' }}
            InputProps={{ className: 'text-center bg-white' }}
          />
        </div>

        <div className="flex gap-2">
          <div className="pt-2">
            { t('date.to') }
          </div>
          <Input
            name={`${prefixName}.toDay`}
            size="small"
            style={{ width: 60 }}
            inputProps={{ maxLength: 2, className: 'text-center bg-white' }}
            InputProps={{ className: 'text-center bg-white' }}
          />
          <div className="pt-2">
            ({ t('forms.companyBranch.availability.temporality.inclusive') })
          </div>
        </div>
      </div>

      <div className="flex gap-2 lg:w-full">
        <div className="w-[30px] shrink-0 pt-2 text-right lg:w-auto lg:text-left">
          { t('forms.companyBranch.availability.temporality.for') }
        </div>
        <div className="w-full">
          <SelectMonths name={`${prefixName}.months`} className="bg-white" />
        </div>
      </div>
    </div>
  )
}

export default TemporalityRecurrentRangeForm
