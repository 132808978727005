import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { grey } from '@mui/material/colors'
import Box from '@mui/material/Box'
import SettingsIcon from '@heroicons/react/24/outline/Cog6ToothIcon'
import LogoutIcon from '@mui/icons-material/Logout'

import { useCurrentUser, useLogoutAction } from '../../../modules/users'
import ProfileAvatar from '../../users/ProfileAvatar/ProfileAvatar'
import MobileMenuLink from './MobileMenuLink'
import { Link } from 'react-router-dom'
import Route from '../../../app/Route.enum'
import InstallPwaMobileLink from './InstallPwaMobileLink'

const IdentificationContainerLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
}))

const IdentificationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  boxSizing: 'border-box',
  padding: '2rem 1rem 0.75rem',
}))

const IdentificationContent = styled(Box)(({ theme }) => ({
  width: '100%',
  marginLeft: '1rem',
  fontFamily: theme.typography.fontFamily,
  fontSize: '0.9rem',
  lineHeight: '1.2rem',
  color: grey[600],
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}))

const MobileMenuProfile: React.FC = () => {
  const { t } = useTranslation()
  const { data: user } = useCurrentUser()
  const { logout } = useLogoutAction()

  if (!user) {
    return null
  }

  const onLogout = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    logout()
  }

  return (
    <>
      <IdentificationContainerLink to={Route.Profile}>
        <IdentificationContainer
          data-testid="user-identification-container"
        >
          <ProfileAvatar />
          <IdentificationContent>
            { (!!user.firstName || !!user.lastName) && (
              <div>
                { user.firstName } { user.lastName }
              </div>
            ) }
            { user.email }
          </IdentificationContent>
        </IdentificationContainer>
      </IdentificationContainerLink>

      <MobileMenuLink
        to={Route.Profile}
        Icon={SettingsIcon}
      >
        { t('header.accountMenu.manageMyAccount') }
      </MobileMenuLink>

      <InstallPwaMobileLink>{ t('header.mobileMenu.install') }</InstallPwaMobileLink>

      <MobileMenuLink
        to="#"
        onClick={onLogout}
        Icon={LogoutIcon}
      >
        { t('header.accountMenu.logout') }
      </MobileMenuLink>
    </>
  )
}

export default MobileMenuProfile
