import { gql } from '@apollo/client'

import { userFields } from './adminUsers.queries'

export const updateUser = gql`
mutation Admin_updateUser($userId: String!, $payload: UpdateUserAdminDto!) {
  admin_updateUser(userId: $userId, payload: $payload){
    ${userFields}
  }
}
`

export const deleteUsers = gql`
mutation Admin_deleteUsers($ids: [String!]!) {
  admin_deleteUsers(ids: $ids){
    ${userFields}
  }
}

`
export const restoreUsers = gql`
mutation Admin_restroreUsers($ids: [String!]!) {
  admin_restoreUsers(ids: $ids){
    ${userFields}
  }
}
`
