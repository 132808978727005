import { ResidenceAndRoomsType, Room } from '../quotes.enum'

type ResidenceRoomMatrix = Partial<Record<Room, number>>

export const roomsByResidenceMatrix: Partial<Record<ResidenceAndRoomsType, ResidenceRoomMatrix>> = {
  [ResidenceAndRoomsType.Studio]: {
    [Room.Bedroom]: 1,
    [Room.Kitchen]: 1,
    [Room.Bathroom]: 1,
  },
  [ResidenceAndRoomsType.Apartment1Bedroom]: {
    [Room.Bedroom]: 1,
    [Room.LivingRoom]: 1,
    [Room.Kitchen]: 1,
    [Room.Bathroom]: 1,
  },
  [ResidenceAndRoomsType.Apartment2Bedroom]: {
    [Room.Bedroom]: 2,
    [Room.LivingRoom]: 1,
    [Room.Kitchen]: 1,
    [Room.Bathroom]: 1,
    [Room.DiningRoom]: 1,
    [Room.Hall]: 1,
  },
  [ResidenceAndRoomsType.Apartment3Bedroom]: {
    [Room.Bedroom]: 3,
    [Room.LivingRoom]: 1,
    [Room.Kitchen]: 1,
    [Room.Bathroom]: 1,
    [Room.DiningRoom]: 1,
    [Room.Hall]: 1,
  },
  [ResidenceAndRoomsType.Apartment4Bedroom]: {
    [Room.Bedroom]: 4,
    [Room.LivingRoom]: 1,
    [Room.Kitchen]: 1,
    [Room.Bathroom]: 2,
    [Room.HomeOffice]: 1,
    [Room.DiningRoom]: 1,
    [Room.Hall]: 1,
  },
  [ResidenceAndRoomsType.House1Bedroom]: {
    [Room.Bedroom]: 1,
    [Room.LivingRoom]: 1,
    [Room.Kitchen]: 1,
    [Room.Bathroom]: 1,
    [Room.DiningRoom]: 1,
    [Room.Hall]: 1,
    [Room.Outdoor]: 1,
  },
  [ResidenceAndRoomsType.House2Bedroom]: {
    [Room.Bedroom]: 2,
    [Room.LivingRoom]: 2,
    [Room.Kitchen]: 1,
    [Room.Bathroom]: 1,
    [Room.DiningRoom]: 1,
    [Room.Hall]: 1,
    [Room.Outdoor]: 1,
  },
  [ResidenceAndRoomsType.House3Bedroom]: {
    [Room.Bedroom]: 3,
    [Room.LivingRoom]: 2,
    [Room.Kitchen]: 1,
    [Room.Bathroom]: 1,
    [Room.DiningRoom]: 1,
    [Room.Hall]: 1,
    [Room.Outdoor]: 1,
  },
  [ResidenceAndRoomsType.House4Bedroom]: {
    [Room.Bedroom]: 4,
    [Room.LivingRoom]: 2,
    [Room.Kitchen]: 1,
    [Room.Bathroom]: 1,
    [Room.DiningRoom]: 1,
    [Room.Hall]: 1,
    [Room.Outdoor]: 1,
    [Room.StorageRoom]: 1,
  },
  [ResidenceAndRoomsType.AssistedLiving]: {
    [Room.Bedroom]: 1,
    [Room.LivingRoom]: 1,
    [Room.Bathroom]: 1,
  },
  [ResidenceAndRoomsType.OfficeSpace]: {
    [Room.Bedroom]: 1,
    [Room.LivingRoom]: 1,
    [Room.Kitchen]: 1,
    [Room.StorageRoom]: 2,
  },
}
