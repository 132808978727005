import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'

import ServerError from '../../../components/errors/ServerError'
import Spinner from '../../../components/Spinner'
import { useCurrentUser, protectPage } from '../../../modules/users'
import Paper from '../../../components/Paper'
import MobileHeader from '../../../components/layout/MobileHeader/MobileHeader'
import Section from '../../../components/Section'
import DeleteAccountModal from '../../../components/users/profile/deleteAccount/DeleteAccountModal'
import Route from '../../../app/Route.enum'
import PageContainer from './PageContainer'

const DeleteAccountPage: React.FC = () => {
  const { t } = useTranslation()
  const { loading, error } = useCurrentUser()
  const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false)

  if (loading || error) {
    return (
      <PageContainer>
        { loading
          ? (
            <Spinner floating />
            )
          : (
            <ServerError error={error} />
            ) }
      </PageContainer>
    )
  }

  return (
    <PageContainer
      mobileHeader={<MobileHeader title={String(t('pages.users.profile.deleteAccount.title'))} backlinkTo={Route.Profile} />}
    >
      <Section>
        { t('pages.users.profile.deleteAccount.title') }
      </Section>
      <Paper>
        <DeleteAccountModal
          open={deleteAccountModalOpen}
          onClose={() => { setDeleteAccountModalOpen(false) }}
        />
        <Button
          variant="contained"
          color="error"
          size="small"
          onClick={() => { setDeleteAccountModalOpen(true) }}
          sx={{ my: 2 }}
        >
          { t('pages.users.profile.deleteAccount.cta') }
        </Button>
      </Paper>
    </PageContainer>
  )
}

export default protectPage(DeleteAccountPage)
