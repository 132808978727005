import { useTranslation } from 'react-i18next'
import Alert from '@mui/material/Alert'

import { useConfig } from '../../modules/config'
import { useNavigate } from '../../modules/navigation'
import FloatingPage from '../../components/layout/FloatingPage'
import RegisterForm from '../../components/forms/users/RegisterForm'
import ServerError from '../../components/errors/ServerError'
import { type RegisterPayload, usePartnerInvitation, useRegisterAction, visitorPage } from '../../modules/users'
import Title from '../../components/users/authentication/Title'
import Route from '../../app/Route.enum'
import { useParams } from 'react-router-dom'
import { validateUuid } from '../../utils/uuid'
import Spinner from '../../components/Spinner'
import { Error404Page } from '../errors'

const PartnerInvitationPage: React.FC = () => {
  const { t } = useTranslation()
  const { invitationId } = useParams()
  const validToken = validateUuid(invitationId)
  const defaultPage = useConfig('users.authentication.defaultPage', { defaultValue: Route.Dashboard })
  const invitation = usePartnerInvitation(invitationId, { skip: !validToken })
  const { register, error } = useRegisterAction()
  const { navigate } = useNavigate()

  const onSubmit = async (data: RegisterPayload) => {
    try {
      await register(data)
      navigate(defaultPage)
    } catch (error) {
      console.error(error)
    }
  }

  if (!validToken) {
    return <Error404Page />
  }

  if (invitation.loading) {
    return (
      <FloatingPage>
        <Spinner floating />
      </FloatingPage>
    )
  }

  if (invitation.error) {
    return (
      <FloatingPage>
        <Alert severity="error">
          We could not find the invitation or the invitation is no longer valid.
        </Alert>
      </FloatingPage>
    )
  }

  return (
    <FloatingPage>
      <Title>{ t('pages.users.register.title') }</Title>
      { error && <ServerError error={error} /> }
      <RegisterForm
        onSubmit={onSubmit}
        initialData={{
          email: invitation.data?.email,
          firstName: invitation.data?.firstName,
          lastName: invitation.data?.lastName,
          invitationId,
        }}
        emailReadOnly
      />
    </FloatingPage>
  )
}

export default visitorPage(PartnerInvitationPage)
