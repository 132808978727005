import { useTranslation } from 'react-i18next'
import LogoutIcon from '@mui/icons-material/Logout'
import SettingsIcon from '@heroicons/react/24/outline/Cog6ToothIcon'

import MenuItem from './MenuItem'
import Route from '../../../app/Route.enum'
import { useLogoutAction } from '../../../modules/users'
import { mergeClassName } from '../../../utils/mergeClassName'

type MenuProps = {
  children?: React.ReactNode
  secondaryMenu?: boolean
  adminStyle?: boolean
}

const Menu: React.FC<MenuProps> = ({
  children,
  secondaryMenu = false,
  adminStyle = false,
}) => {
  const { t } = useTranslation()
  const { logout } = useLogoutAction()

  return (
    <div className={mergeClassName(
      'hidden w-[75px] shrink-0 flex-col justify-between overflow-y-auto border-r border-zinc-200 lg:flex',
      adminStyle && 'bg-zinc-600',
    )}
    >
      <div>
        { children }
      </div>
      { !secondaryMenu && (
        <div>

          <MenuItem
            to={Route.ProfileIdentification}
            label={t('menu.settings')}
            Icon={SettingsIcon}
            adminStyle={adminStyle}
            matchRegex={/^\/profile\//}
          />

          <MenuItem
            to="#"
            label={t('menu.logout')}
            Icon={LogoutIcon}
            onClick={logout}
            adminStyle={adminStyle}
          />
        </div>
      ) }
    </div>
  )
}

export default Menu
