import { useTranslation } from 'react-i18next'

import { useConfig } from '../../../modules/config'
import Link from '../../Link/Link'
import Route from '../../../app/Route.enum'

const Footer: React.FC = () => {
  const { t } = useTranslation()
  const companyName = useConfig('app.fullCompanyName')

  return (
    <div
      className="flex grow flex-col items-center justify-center border-t border-zinc-200 bg-stone-50 py-6 text-zinc-600 lg:flex-row lg:justify-end lg:gap-3 lg:px-4 lg:py-2 lg:text-sm"
    >
      <span>{ t('footer.copyright', {
        companyName,
        currentYear: new Date().getFullYear(),
      }) }
      </span>
      <Link to={Route.TermsOfUse} variant="underlined">{ t('footer.termsOfUse') }</Link>
      <Link to={Route.PrivacyPolicy} variant="underlined">{ t('footer.privacyPolicy') }</Link>
    </div>
  )
}

export default Footer
