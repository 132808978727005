import MarkerIcon from '@mui/icons-material/RoomOutlined'

import { mergeClassName } from '../../../utils/mergeClassName'
import { type Place, formatPlace } from '../../../common/place'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

type AddressOrPlaceProps = {
  location?: Place
} & Omit<ReactDiv, 'children'>

const AddressOrPlace: React.FC<AddressOrPlaceProps> = ({
  location,
  className,
  ...props
}) => {
  const formattedPlace = formatPlace(location, true)
  if (!formattedPlace) {
    return null
  }

  return (
    <div
      className={mergeClassName(
        'inline-flex items-center gap-2 lg:gap-4',
        className,
      )}
      {...props}
    >
      <MarkerIcon color="primary" />
      <div className="flex flex-col gap-y-2 font-sans text-xl leading-[22px] text-neutral-700 md:text-2xl md:leading-tight lg:gap-y-0 [&_div:last-of-type]:text-base [&_div:last-of-type]:text-neutral-400">
        { formattedPlace }
      </div>
    </div>
  )
}

export default AddressOrPlace
