import { useConfig } from '../../modules/config'

// generated with : https://www.gdprprivacypolicy.net/

const EnglishContent: React.FC = () => {
  const appFriendlyUrl = useConfig('app.appFriendlyUrl')
  const fullCompanyName = useConfig('app.fullCompanyName')

  return (
    <>
      <h2>Introduction</h2>
      <p>The mission of MovingWaldo's partner platform, accessible at { appFriendlyUrl } is to help moving companies streamline the booking and management of their moving and packing services (“your services”), so you can focus on offering the best quality moving labour at competitive pricing to customers.</p>

      <h2>How it works</h2>
      <p>{ fullCompanyName } promotes itself as a marketplace for moving services, offering customers a safe place to book trustworthy movers at a fair price.</p>
      <p>Once you have opened an account with MovingWaldo's partner platform and completed the onboarding and approbation process with us, you will join our exclusive network of moving companies. We will then represent your company towards our customers that are interested in moving services. </p>
      <p>During the onboarding process, { fullCompanyName } will collect information about your company and the services you offer. Among other things, we will use the information that you provide to calculate moving and packing quotes. These quotes will be presented to our customers on your behalf.</p>
      <p>If you would like additional technical information, please send us a message at sales@movingwaldo.com.</p>

      <h2>Information we share</h2>
      <p>We do not share information that we collect publicly or with customers unless one of the following circumstances applies:</p>
      <ol>
        <li>To implement
          <p>In order to implement the service that we offer, we may need to share the information that we have collected with customers. In such situations, we may share only such information as is necessary to complete the ordered service. </p>
        </li>
        <li>To promote your company
          <p>In order to promote your company and its services to our customer base, we may need to share the information that we have collected. Such information may be shared on our websites and our partner's websites, by email or by other means of communication. </p>
        </li>
        <li>To promote { fullCompanyName } and its services.
          <p>{ fullCompanyName } may use information that you provide, such as your logo or the services that you offer, in order to promote our own services and bring new customers to our marketplace. We may do so by advertising your company's information on our websites, our partners' websites, advertisement platforms, or through any other channels of communication. </p>
        </li>
        <li>For legal
          <p>We will share your personal information with companies, organizations or individuals outside of{ fullCompanyName }, if we believe doing so is necessary to: Meet any applicable law, regulation, legal process or enforceable governmental request. Detect, prevent, or otherwise address fraud, security or technical issues. </p>
        </li>
        <li>For aggregate
          <p>We may share aggregated, non-personally identifiable information publicly. For example, we may share information publicly to show statistics about { fullCompanyName }, our services, and the moving industry. </p>
        </li>
      </ol>

      <h2>Information we collect</h2>
      <p>Without limiting ourselves to this list, here are some information we may require to perform such services: </p>
      <ul>
        <li>Name and general contact information for the legal owner of the company</li>
        <li>Legal name of the company, its address and contact information</li>
        <li>Business license and registration</li>
        <li>Tax identification numbers</li>
        <li>Certificate of insurance</li>
        <li>Certificate of specific accreditations</li>
        <li>Banking information</li>
        <li>Lists of products and services and their respective pricing and availabilities</li>
        <li>Company logo and brand assets</li>
        <li>Other</li>
      </ul>

      <h2>General Data Protection Regulation (GDPR)</h2>
      <p>We are a Data Controller of your information.</p>

      <p>{ fullCompanyName } legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Information we collect and the specific context in which we collect the information:</p>
      <ul>
        <li>{ fullCompanyName } needs to perform a contract with you</li>
        <li>You have given { fullCompanyName } permission to do so</li>
        <li>Processing your personal information is in { fullCompanyName } legitimate interests</li>
        <li>{ fullCompanyName } needs to comply with the law</li>
      </ul>

      <p>{ fullCompanyName } will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.</p>

      <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. If you wish to be informed what Personal Information we hold about you and if you want it to be removed from our systems, please contact us.</p>

      <p>In certain circumstances, you have the following data protection rights:</p>
      <ul>
        <li>The right to access, update or to delete the information we have on you.</li>
        <li>The right of rectification.</li>
        <li>The right to object.</li>
        <li>The right of restriction.</li>
        <li>The right to data portability</li>
        <li>The right to withdraw consent</li>
      </ul>

      <h2>Log Files</h2>

      <p>MovingWaldo's partner platform follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>

      <h2>Cookies and Web Beacons</h2>

      <p>Like any other website, MovingWaldo's partner platform uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>

      <h2>Children's Information</h2>

      <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>

      <p>MovingWaldo's partner platform does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>

      <h2>Online Privacy Policy Only</h2>

      <p>Our Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in MovingWaldo's partner platform. This policy is not applicable to any information collected offline or via channels other than this website.</p>

      <h2>Consent</h2>

      <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
    </>
  )
}

export default EnglishContent
