import { useTranslation } from 'react-i18next'

import { Select, type SelectProps } from '../..'

const months: Record<number, string> = {
  1: 'january',
  2: 'february',
  3: 'march',
  4: 'april',
  5: 'may',
  6: 'june',
  7: 'july',
  8: 'august',
  9: 'september',
  10: 'october',
  11: 'november',
  12: 'december',
}

const MonthSelect: React.FC<Omit<SelectProps, 'options'>> = (
  selectProps,
) => {
  const { t } = useTranslation()
  const monthsOptions = Array.from({ length: 12 }).map((_, index) => ({
    value: String(index + 1),
    label: t(`date.months.${months[index + 1]}`),
  }))

  return (
    <Select
      {...selectProps}
      options={monthsOptions}
    />
  )
}

export default MonthSelect
