import { type Amount } from '../../../common/amount'
import { ensurePositivePrice, multiply, subtract } from '../../../utils/money'
import { PromoCodeType } from './promoCodes.enums'
import { type PromoCode } from './promoCodes.models'

/**
 * apply promocode to a given amount
 */
export const applyPromoCode = (amount: Amount, promoCode?: PromoCode): Amount => {
  if (!promoCode) {
    return { ...amount }
  }
  switch (promoCode.type) {
    case PromoCodeType.Amount:
      return ensurePositivePrice(subtract(amount, promoCode.value))
    case PromoCodeType.Percentage: {
      const rebate = multiply(amount, promoCode.value / 100)
      return ensurePositivePrice(subtract(amount, rebate))
    }
    default:
      return { ...amount }
  }
}
