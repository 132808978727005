import { type KeyValueMap } from '../../../common/keyValueMap'
import {
  type QuoteConstraint,
  type ResidenceAndRoomsType,
  type ResidenceType,
  type Room,
} from './quotes.enum'
import {
  type SpecialItem,
  type Truck,
} from '../../companies'
import { type Quote } from './quotes.models'

export type MoveDetails = {
  residenceAndRoomsType: ResidenceAndRoomsType
  nbBoxes?: number | null
  furnitureRatio?: number | null
  nbAppliancesAndMore?: number
}

export type MoveSizeDetails = {
  residenceType?: ResidenceType
  nbBoxes?: number | null
  furnitureRatio?: number | null
  residenceRooms?: KeyValueMap<Room, number> | null
  specialItems?: KeyValueMap<SpecialItem, number> | null
}

export type MoveSizeEstimate = {
  volumeCubicFeet: number
  weightPounds: number
  nbMen: number
  estimatedMovingLabourTime: number
  nbTrucks: KeyValueMap<Truck, number>
}

export enum QuotesView {
  best = 'best',
  cheapest = 'cheapest',
}

export type ConstraintsFilter = (quote: Quote) => QuoteConstraint[]

export type QuotesViewHandler = {
  sort: (quotes: Quote[]) => Quote[]
  getConstraints?: ConstraintsFilter
}
