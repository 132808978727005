/* eslint-disable @typescript-eslint/no-misused-promises */

import { type Quote, useQuotesView, useSortedQuote } from '../../../modules/quotes'
import { type PromoCode } from '../../../modules/adminPromoCodes'
import QuoteComponent from './Quote'

type QuotesProps = {
  quotes: Quote[]
  promoCode?: PromoCode
  selectedBranch?: string
  ignoreBest?: boolean
  renderCta?: (id: string) => React.ReactNode
}

const Quotes: React.FC<QuotesProps> = ({
  quotes,
  promoCode,
  selectedBranch,
  ignoreBest,
  renderCta,
}) => {
  const filteredQuotes = useSortedQuote(quotes)
  const quotesView = useQuotesView()

  return (
    <div
      key={quotesView}
      className="flex flex-col gap-8"
    >
      { filteredQuotes.map((quote, index) => (
        <QuoteComponent
          key={quote.id}
          quote={quote}
          promoCode={promoCode}
          customerSelected={!!selectedBranch && quote.companyBranch.id === selectedBranch}
          best={!ignoreBest && index === 0}
        >
          { renderCta?.(quote.id) }
        </QuoteComponent>
      )) }
    </div>
  )
}

export default Quotes
