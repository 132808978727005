import {
  type InvoicePayload,
  type Charge,
} from '../../../../modules/invoices'
import { type Currency } from '../../../../common/amount'
import { chargesToFormData, formatChargesFormOutput } from '../../invoice/ChargesFormContent'
import { type InvoiceFormValue } from './InvoiceForm.type'

export const invoiceToFormData = (
  charges: Charge[] = [],
): InvoiceFormValue => {
  return {
    ...chargesToFormData(charges),
    tip: '0',
    autoCapture: true,
  }
}

export const formatFormOutput = (
  data: InvoiceFormValue,
  currency: Currency,
): Pick<InvoicePayload, 'details' | 'tip' | 'autoCapture'> => {
  const tip = parseFloat(data.tip ?? '0')
  return {
    details: formatChargesFormOutput(data, currency),
    tip: tip > 0 ? { price: tip, currency } : undefined,
    autoCapture: data.autoCapture,
  }
}
