import { useTranslation } from 'react-i18next'

import { type FullMove } from '../../../../../modules/adminMoves'
import { useMoveState } from '../../../../../modules/moves'
import Paper from '../../../../../components/Paper'
import { Quotes } from '../../../../../components/quotes'
import TimeAgo from '../../../../../components/TimeAgo'
import MoveActionButton from '../../MoveActionButton'
import { ActionName } from '../../useActions'

type QuotesBoxProps = {
  move: FullMove
}

const QuotesBox: React.FC<QuotesBoxProps> = ({
  move,
}) => {
  const { t } = useTranslation()
  const {
    lastQuoteRefresh,
  } = useMoveState().forMove(move)

  const renderCta = (quoteId: string) => {
    return (
      <MoveActionButton
        move={move}
        actionName={ActionName.SelectAndCustomizeQuoteAction}
        args={quoteId}
        className="w-full max-w-[300px] !normal-case md:w-auto"
      />
    )
  }

  return (
    <Paper>
      <div className="mb-8 flex items-center justify-end gap-x-4">
        { lastQuoteRefresh && (
          <span className="text-sm text-gray-400">
            { t('pages.admin.move.quotes.generated') }
            { ': ' }
            <TimeAgo date={lastQuoteRefresh} />
          </span>
        ) }

        <MoveActionButton
          move={move}
          actionName={ActionName.RefreshQuotes}
        />
      </div>

      { move.quotes?.length
        ? (
          <Quotes
            quotes={move.quotes}
            promoCode={move.appliedPromoCode ?? move.promoCode}
            selectedBranch={move.selectedBranch}
            ignoreBest
            renderCta={renderCta}
          />
          )
        : (
            t('pages.admin.move.quotes.noQuotes')
          ) }
    </Paper>
  )
}

export default QuotesBox
