/* eslint-disable @typescript-eslint/no-misused-promises */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DayPicker, type DateRange as PickerDateRange } from 'react-day-picker'
import Button from '@mui/material/Button'
import 'react-day-picker/dist/style.css'

import { useDateFnsLocale } from '../../utils/date'
import { Modal, ModalDescription, ModalHeader, ModalTitle } from '../Modal'

export type DateRange = {
  from: Date
  to?: Date
}

type DateRangeModalProps = {
  open: boolean
  onClose: () => void | Promise<void>
  onSelect: (dateRange: DateRange) => void | Promise<void>
  currentRange?: DateRange
}

const DateRangeModal: React.FC<DateRangeModalProps> = ({
  open,
  onClose,
  onSelect,
  currentRange,
}) => {
  const { t } = useTranslation()
  const [selection, setSelection] = useState<PickerDateRange | undefined>(currentRange)
  const locale = useDateFnsLocale()

  useEffect(() => {
    setSelection(currentRange)
  }, [currentRange])

  const handleCancel = async () => {
    setSelection(undefined)
    await onClose()
  }

  const handleConfirm = async () => {
    if (!selection?.from) {
      return
    }
    const range = { ...selection }
    setSelection(undefined)
    await onSelect(range as DateRange)
  }

  return (
    <Modal
      open={open}
      className="lg:w-[350px]"
      onClose={onClose}
    >
      <ModalHeader>
        <ModalTitle>
          { t('pages.admin.moves.table.filters.dateRange.modal.title') }
        </ModalTitle>
      </ModalHeader>
      <ModalDescription className="my-4 max-h-[calc(100dvh-185px)]">
        <DayPicker
          mode="range"
          selected={selection}
          onSelect={setSelection}
          locale={locale}
        />

        <div className="my-2 flex justify-end gap-x-4">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCancel}
          >
            { t('actions.cancel') }
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            disabled={!selection?.from}
          >
            { t('actions.save') }
          </Button>
        </div>
      </ModalDescription>
    </Modal>
  )
}

export default DateRangeModal
