import { useTranslation } from 'react-i18next'
import { format } from 'date-fns/format'

const ONE_MINUTE_SECONDS = 60
const ONE_HOUR_SECONDS = ONE_MINUTE_SECONDS * 60
const ONE_DAY_SECONDS = ONE_HOUR_SECONDS * 24

export const useTimeFormatter = () => {
  const { t } = useTranslation()

  const formatTime = (date?: any) => {
    if (!date) {
      return undefined
    }
    if (typeof date === 'string') {
      date = new Date(date)
    }
    return format(date, 'H:mm')
  }

  const formatSeconds = (seconds: number) => {
    const nbDays = Math.floor(seconds / ONE_DAY_SECONDS)
    const nbHours = Math.floor(
      (seconds - (nbDays * ONE_DAY_SECONDS)) / ONE_HOUR_SECONDS,
    )
    let nbMinutes = String(Math.floor(
      (seconds - (nbDays * ONE_DAY_SECONDS) - (nbHours * ONE_HOUR_SECONDS)) / ONE_MINUTE_SECONDS,
    ))
    if (nbHours > 0) {
      nbMinutes = nbMinutes.padStart(2, '0')
    }

    const parts = []

    if (nbDays > 0) {
      parts.push(`${nbDays}${t('time.days')}`)
    }

    if (nbHours > 0) {
      parts.push(`${nbHours}${t('time.hours')}${nbMinutes}`)
    } else {
      parts.push(`${nbMinutes ?? 1}${t('time.mins')}`)
    }

    return parts.join(' ')
  }

  return { formatTime, formatSeconds }
}
