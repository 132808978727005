import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'

import { type CompanyInvitationFormStep2Payload } from './CompanyInvitationFormStep2.type'
import { Form, Input, Label, Select, SubmitButton } from '../../../../form'
import { Cell, Label as DataLabel, Row, Value } from '../../../../cell'

type CompanyInvitationFormStep2Props = {
  email: string
  onSubmit: (payload: CompanyInvitationFormStep2Payload) => Promise<any>
  onCancel?: () => void
}

const CompanyInvitationFormStep2: React.FC<CompanyInvitationFormStep2Props> = ({
  email,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation()

  return (
    <Form onSubmit={onSubmit}>
      <h3 className="mb-2 font-body2 text-lg">
        { t('pages.admin.companies.branchPanel.invitePartnerTitle') }
      </h3>

      <Row>
        <Cell>
          <DataLabel>{ t('forms.fields.email.label') }</DataLabel>
          <Value>{ email }</Value>
        </Cell>
        <Cell>
          <Label label={t('forms.fields.preferredLanguage.label')}>
            <Select
              name="preferredLanguage"
              options={[
                { value: 'en', label: t('languages.en') },
                { value: 'fr', label: t('languages.fr') },
              ]}
              size="small"
            />
          </Label>
        </Cell>
      </Row>

      <Row>
        <Cell>
          <Label label={t('forms.fields.firstName.label')}>
            <Input
              name="firstName"
              size="small"
            />
          </Label>
        </Cell>

        <Cell>
          <Label label={t('forms.fields.lastName.label')}>
            <Input
              name="lastName"
              size="small"
            />
          </Label>
        </Cell>
      </Row>

      <div className="flex justify-end gap-3 py-4 pt-8">
        { onCancel && (
          <Button
            variant="contained"
            color="secondary"
            onClick={onCancel}
          >
            { t('actions.cancel') }
          </Button>
        ) }
        <SubmitButton>
          { t('actions.invitePartner') }
        </SubmitButton>
      </div>
    </Form>
  )
}

export default CompanyInvitationFormStep2
