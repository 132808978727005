export enum ACTION_TYPES {
  SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS',
}

export const setValidationErrors = (validationErrors: string[]) => ({
  type: ACTION_TYPES.SET_VALIDATION_ERRORS as const,
  validationErrors,
})

export type Actions = ReturnType<typeof setValidationErrors>
