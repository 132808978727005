import { useTranslation } from 'react-i18next'

import { type TaxBreakdown } from '../../../../modules/taxes'
import { type InvoiceEstimate } from '../../../../modules/invoices'
import { formatPrice } from '../../../../utils/money'
import { mergeClassName } from '../../../../utils/mergeClassName'

type SummaryProps = {
  invoiceEstimate: InvoiceEstimate
}

const Summary: React.FC<SummaryProps> = ({
  invoiceEstimate,
}) => {
  const { t } = useTranslation()

  return (

    <>
      <div className="flex items-center justify-between">
        <div className="text-neutral-500">
          { t('forms.invoice.sidebar.summary.subtotal') }
        </div>

        <div className={mergeClassName(
          'text-right text-neutral-700',
          !invoiceEstimate.discount && 'font-bold text-neutral-800',
        )}
        >
          { formatPrice(invoiceEstimate.subtotal) }
        </div>
      </div>

      { invoiceEstimate.discount && invoiceEstimate.discount.price < 0 && (
        <div className="flex justify-between">
          <div className="text-neutral-500">
            { t('forms.invoice.sidebar.summary.discount') }
          </div>

          <div>
            <div className="text-right text-neutral-700">
              { formatPrice(invoiceEstimate.discount) }
            </div>
            <div className="text-right font-bold text-neutral-800">
              { formatPrice(
                {
                  price: invoiceEstimate.subtotal.price + invoiceEstimate.discount.price,
                  currency: invoiceEstimate.subtotal.currency,
                }) }
            </div>
          </div>
        </div>
      ) }

      { (invoiceEstimate.taxes?.length ?? 0) > 0 && (
        <div className="pt-4">
          { invoiceEstimate.taxes?.map((tax: TaxBreakdown) => (
            <div
              key={tax.tax}
              className="flex items-center justify-between"
            >
              <div className="text-neutral-500">
                { t(
                      `taxes_short.${tax.tax}_${String(tax.region?.toLowerCase())}`,
                      { defaultValue: t(`taxes_short.${tax.tax}`, { defaultValue: tax.tax.toUpperCase() }) },
                ) }
                { ' ' }
                <span className="text-xs text-neutral-400">
                  ( { tax.percentage }% )
                </span>
              </div>

              <div className="text-right text-neutral-700">
                { formatPrice(tax.total) }
              </div>
            </div>
          )) }
        </div>
      ) }

      { invoiceEstimate.tip && (invoiceEstimate.tip?.price ?? 0) > 0 && (

        <div className="flex justify-between pt-4">
          <div className="text-neutral-500">
            { t('forms.invoice.sidebar.summary.tip') }
          </div>

          <div>
            <div className="text-right text-neutral-700">
              { formatPrice(invoiceEstimate.tip) }
            </div>
          </div>
        </div>

      ) }

      <hr className="mb-2 mt-6" />

      <div className="flex items-center justify-between">
        <div className="text-neutral-500">
          { invoiceEstimate.total.price < 0
            ? t('forms.invoice.sidebar.summary.credit')
            : t('forms.invoice.sidebar.summary.total') }
        </div>

        <div className={mergeClassName(
          'text-right text-neutral-700 text-lg',
          invoiceEstimate.total.price > 0 && 'text-green-800',
          invoiceEstimate.total.price < 0 && 'text-red-700',
        )}
        >
          { formatPrice(invoiceEstimate.total, { showCurrency: true }) }
        </div>
      </div>
    </>

  )
}

export default Summary
