import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '@mui/material/Alert'

import FloatingPage from '../../../components/layout/FloatingPage'
import { visitorPage } from '../../../modules/users'
import Title from '../../../components/users/authentication/Title'
import Link from '../../../components/Link'
import { useQueryString } from '../../../modules/navigation'
import Route from '../../../app/Route.enum'

const LoginErrorPage: React.FC = () => {
  const { t } = useTranslation()
  const error = useQueryString('error')

  const errorMessage = useMemo(() => {
    if (error === 'INVALID_SOURCE') {
      return t('pages.users.login.errors.oauthInvalidSourceError')
    }
    if (error === 'DELETED_ACCOUNT') {
      return t('pages.users.login.errors.oauthDeletedAccountError')
    }
    return t('pages.users.login.errors.oauthLoginError')
  }, [error, t])

  return (
    <FloatingPage>
      <Title>{ t('pages.users.login.title') }</Title>

      <br /><br />

      <Alert severity="error">
        { errorMessage }
      </Alert>

      <br /><br />

      <Link to={Route.Login} variant="primary">
        { t('pages.users.login.tryAgain') }
      </Link>
    </FloatingPage>
  )
}

export default visitorPage(LoginErrorPage)
