import { useTranslation } from 'react-i18next'

import { type LightJobRequest } from '../../../modules/jobRequests'
import { calculateNumberOfTrucks } from '../../../modules/quotes'
import { capitalizeFirstLetter } from '../../../utils/string'

type JobRequestEntryProps = {
  jobRequest: LightJobRequest
}

const JobRequestEntry: React.FC<JobRequestEntryProps> = ({
  jobRequest,
}) => {
  const { t } = useTranslation()

  return (
    <div
      className="-mx-2 mt-2 h-full min-w-[250px] overflow-hidden bg-white/15 px-2 pt-2 font-sans text-base leading-tight "
    >

      <div className="ml-[-8px] mr-8 mt-[-3px] w-full bg-white/80 p-1 text-center text-xs uppercase text-orange-500">
        { t('pages.companyBranch.jobs.notYetAccepted') }
      </div>

      <ul className="mt-2 [&>li]:my-1">
        <li>
          <div className="pb-1 font-bold">
            { t('nbMen', { count: jobRequest.nbMen }) }
            { ' + ' }
            { t('nbTrucks', { count: calculateNumberOfTrucks(jobRequest.nbTrucks) }) }
          </div>
        </li>

        <li className="pb-1">
          { t(`residenceType.${jobRequest.residenceType}.title`, { defaultValue: jobRequest.residenceType }) }
          { ' ' }
          ({ jobRequest.volumeCubicFeet } { t('units.cubicFeet') })
        </li>

        <li className="hidden 2xl:block">
          <div className="flex flex-col 3xl:flex-row">
            <div className="w-[40px] shrink-0 text-sm opacity-80">
              { t('address.from') }
            </div>
            { capitalizeFirstLetter(jobRequest.originCity) }
            { ', ' }
            { jobRequest.originRegion.toLocaleUpperCase() }
          </div>
        </li>
        <li className="hidden 2xl:block">
          <div className="flex flex-col 3xl:flex-row">
            <div className="w-[40px] shrink-0 text-sm opacity-80">
              { t('address.to') }
            </div>
            { capitalizeFirstLetter(jobRequest.destinationCity) }
            { ', ' }
            { jobRequest.originRegion.toLocaleUpperCase() }
          </div>
        </li>
      </ul>
    </div>
  )
}

export default JobRequestEntry
