/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useTranslation } from 'react-i18next'

import { useDateFormatter } from '../../../utils/date'
import { type ArrivalWindow } from '../../companies'
import { calculateNumberOfTrucks } from '../../quotes'
import { type JobForPartner, type LightJob } from './jobs.type'

type SummaryData = {
  movingDate: Date
  movingTime: ArrivalWindow
  nbMen: number
  nbTrucks: number
}

/**
 * this returns a one line summary for a job
 * ex: September 27th, 2024 @ 8/9 am - 4 men + 1 truck
 */
export const useGetJobSummary = () => {
  const { t } = useTranslation()
  const formatDate = useDateFormatter()
  const getJobData = useGetJobRequestData()
  const getLightJobData = useGetLightJobRequestData()

  // eslint-disable-next-line react/display-name
  return (job: JobForPartner | LightJob) => {
    const data = 'move' in job ? getJobData(job) : getLightJobData(job)
    const {
      movingDate,
      movingTime,
      nbMen,
      nbTrucks,
    } = data

    /* when using compact mode, mimic the email subjects */
    const date = formatDate(movingDate)
    const time = movingTime
      ? `@ ${t(`arrivalWindows.${movingTime}.shortTitle`, { defaultValue: movingTime })}`
      : ''
    const men = t('nbMen', { count: nbMen })
    const truck = t('nbTrucks', { count: nbTrucks })

    return <>{ date } { time } - { men } + { truck }</>
  }
}

/**
 * get data from full job request models
 */
const useGetJobRequestData = () => {
  return (job: JobForPartner): SummaryData => {
    return {
      movingDate: job.date,
      movingTime: job.time,
      nbMen: job.quote.resolvedNbMen,
      nbTrucks: calculateNumberOfTrucks(job.quote.resolvedNbTrucks),
    }
  }
}

/**
 * get data from light job request object
 * light objects are used when fetching a collection
 */
const useGetLightJobRequestData = () => {
  return (job: LightJob): SummaryData => {
    return {
      movingDate: job.date,
      movingTime: job?.time,
      nbMen: job.nbMen,
      nbTrucks: calculateNumberOfTrucks(job.nbTrucks),
    }
  }
}
