import {
  type MoveDetails,
  type MoveSizeDetails,
} from '../quotes.types'
import { roomsByResidenceMatrix } from '../values/roomsByResidenceMatrix'
import { residenceTypeVolume } from '../values/residenceTypeVolume'
import { roomVolume } from '../values/roomVolume'
import { specialItemVolume } from '../values/specialItemVolume'
import { boxVolume } from '../quotes.const'
import { SpecialItem } from '../../../companies'
import { type Room } from '../quotes.enum'

/**
 * returns approximate volume based on form values
 */
export const estimatePreliminaryVolume = ({
  residenceAndRoomsType,
  nbBoxes = 0,
  furnitureRatio,
  nbAppliancesAndMore = 0,
}: MoveDetails) => {
  furnitureRatio ??= 100
  let volumeCubicFeet = 0

  /* boxes volume */
  volumeCubicFeet += (nbBoxes ?? 0) * boxVolume

  /* furniture volume */
  const rooms = Object.entries(roomsByResidenceMatrix[residenceAndRoomsType] ?? {})
  const furnitureVolume = rooms.reduce<number>((acc, curr) => {
    const [roomType, roomQuantity] = curr
    return acc + ((roomVolume?.[roomType as Room] ?? 0) * (roomQuantity ?? 0))
  }, 0)
  volumeCubicFeet += ((furnitureVolume ?? 0) / 100) * furnitureRatio

  /* known residence volume (storage units) */
  volumeCubicFeet += ((residenceTypeVolume[residenceAndRoomsType] ?? 0) / 100) * furnitureRatio

  /* appliances volume */
  volumeCubicFeet += (nbAppliancesAndMore ?? 0) * specialItemVolume[SpecialItem.Appliance] ?? 0

  return Math.ceil(volumeCubicFeet)
}

/**
 * returns accurate volume based on all move details
 */
export const estimateVolume = (move: MoveSizeDetails) => {
  let volumeCubicFeet = 0
  const furnitureRatio = move.furnitureRatio ?? 100

  /* boxes volume */
  volumeCubicFeet += (move.nbBoxes ?? 0) * boxVolume

  /* furniture volume */
  if (move.residenceRooms) {
    move.residenceRooms.forEach(({ key, value }) => {
      const volume = (((roomVolume[key] ?? 0) * (value ?? 0)) / 100) * furnitureRatio
      volumeCubicFeet += isNaN(volume) ? 0 : volume
    })
  }

  /* known residence volume (storage units) */
  if (move.residenceType) {
    volumeCubicFeet += ((residenceTypeVolume[move.residenceType] ?? 0) / 100) * furnitureRatio
  }

  /* special items volume */
  if (move.specialItems) {
    move.specialItems.forEach(({ key, value }) => {
      const volume = (specialItemVolume[key] ?? 0) * (value ?? 0)
      volumeCubicFeet += isNaN(volume) ? 0 : volume
    })
  }

  return Math.ceil(volumeCubicFeet)
}
