import { useMutation } from '@apollo/client'

import { type Amount } from '../../../common/amount'
import * as mutations from './invoices.mutations'
import {
  type InvoiceEstimate,
  type CalculateInvoicePayload,
  type InvoicePayload,
  type CreateInvoiceResponse,
  type Invoice,
} from './invoices.types'
import type Language from '../../../app/Language.enum'
import { useConfig } from '../../config'

type UseInvoiceLinkOptions = {
  moveId: string
  language: Language
}

/**
 * returns invoice link to down an invoice
 */
export const useGetInvoiceLink = () => {
  const apiUrl = useConfig('api.url')

  return (invoice: Invoice, {
    moveId,
    language,
  }: UseInvoiceLinkOptions) => {
    const invoiceId = invoice.id
    const invoiceNumber = invoice.invoiceNumber.toLowerCase()
    return `${apiUrl}/invoices/move/${moveId}/${invoiceId}/${invoiceNumber}-${language}.pdf`
  }
}

/**
 * returns the total amount from a collection of invoices
 */
export const useGetInvoicesTotalCharge = () => {
  return (invoices?: Invoice[]) => {
    if (!invoices) {
      return
    }
    return invoices.reduce<Amount | undefined>((acc, curr) => {
      if (!acc) {
        return curr.total
      }
      return {
        price: acc.price + curr.total.price,
        currency: acc.currency,
      }
    }, undefined)
  }
}

/**
 * calculate taxes and total (from MW to customer)
 */
export const useCalculateInvoiceAction = () => {
  const [calculateInvoice, { data, loading, ...mutationProps }] = useMutation(mutations.calculateInvoice)

  return {
    calculateInvoice: async (moveId: string, payload: CalculateInvoicePayload): Promise<InvoiceEstimate> => {
      const { data } = await calculateInvoice({
        variables: {
          moveId,
          payload,
        },
      })

      return data.calculateInvoice
    },
    data: data?.calculateInvoice as InvoiceEstimate | undefined,
    loading,
    ...mutationProps,
  }
}

/**
 * create a move invoice (from MW to customer)
 */
export const useCreateMoveInvoiveAction = () => {
  const [createMoveInvoice, { data, loading, ...mutationProps }] = useMutation(mutations.createMoveInvoice)

  return {
    createMoveInvoice: async (moveId: string, { autoCapture, ...payload }: InvoicePayload): Promise<CreateInvoiceResponse> => {
      const { data } = await createMoveInvoice({
        variables: {
          moveId,
          payload,
          autoCapture,
        },
      })

      return data.admin_createMoveInvoice
    },
    data: data?.admin_createMoveInvoice as CreateInvoiceResponse | undefined,
    loading,
    ...mutationProps,
  }
}
