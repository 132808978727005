import { useEffect, useMemo } from 'react'
import {
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form'

import {
  type ResidenceAndRoomsType,
  useMoveSizeEstimate,
  residenceTypeFromRooms,
  defaultBoxesPerResidence,
  roomsByResidenceMatrix,
  type ResidenceType,
  calculateNumberOfTrucks,
} from '../../../../modules/quotes'
import { Truck } from '../../../../modules/companies'
import { keyValueMapToRecord, recordToKeyValueMap } from '../../../../common/keyValueMap'
import { mergeClassName } from '../../../../utils/mergeClassName'
import { Cell, Row } from '../../../cell'
import DateTimeFormContent from './sections/DateTimeFormContent'
import AddressesFormContent from './sections/AddressesFormContent'
import MoveContentFormContent from './sections/MoveContentFormContent'
import MoveSizeAndRequirementsFormContent from './sections/MoveSizeAndRequirementsFormContent'
import NbTrucksFormContent from './sections/NbTrucksFormContent'
import SpecialItemsAndServicesFormContent from './sections/SpecialItemsAndServicesFormContent'
import ProtectiveMaterialFormContent from './sections/ProtectiveMaterialFormContent'
import { type QuotesRequestFormValues } from './QuotesRequestForm.type'

const trucksDefaultValues = Object.fromEntries(
  Object.values(Truck).map(truck => ([truck, '0'])),
)

/**
 * note:  it's important to set default values of text input with react-hook-form
 *        when using field array and append function. otherwise, these fields will
 *        be marked as dirty after append.
 */
export const defaultQuoteRequestValues = {
  flexibleDate: true,
  nbTrucks: trucksDefaultValues,
  nbTrucksTotal: 0,
  nbMen: '2',
  estimatedMovingLabourTime: '1',
  estimatedPackingLabourTime: '',
  residenceRooms: {},
  residenceRoomsTotal: 0,
  furnitureRatio: '100',
  volumeCubicFeet: '0',
}

type QuotesRequestFormContentProps = {
  customerIdentificationSection?: React.ReactNode
  requireDetails?: boolean
  specificAddresses?: boolean
  allowOverride?: boolean
}

const QuotesRequestFormContent: React.FC<QuotesRequestFormContentProps> = ({
  customerIdentificationSection,
  requireDetails = false,
  specificAddresses = false,
  allowOverride = false,
}) => {
  const { setValue } = useFormContext()
  const { dirtyFields, touchedFields, defaultValues } = useFormState()

  const editing = !!defaultValues?.movingDate

  /* watch fields to generate estimate */

  const residenceAndRoomsType = useWatch<QuotesRequestFormValues>({ name: 'residenceType' }) as ResidenceAndRoomsType
  const nbBoxes = useWatch({ name: 'nbBoxes' })
  const furnitureRatio = useWatch({ name: 'furnitureRatio' })
  const residenceRooms = recordToKeyValueMap(useWatch<QuotesRequestFormValues>({ name: 'residenceRooms' }) ?? {})
  const specialItems = recordToKeyValueMap(useWatch<QuotesRequestFormValues>({ name: 'specialItems' }) ?? {})

  /* calculate estimate */

  const residenceType = (editing && requireDetails)
    ? residenceAndRoomsType as unknown as ResidenceType
    : residenceTypeFromRooms[residenceAndRoomsType]

  const getEstimate = useMoveSizeEstimate()

  const estimate = useMemo(() => {
    return getEstimate({
      residenceType,
      nbBoxes,
      furnitureRatio,
      residenceRooms,
      specialItems,
    } as any)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    residenceType,
    nbBoxes,
    furnitureRatio,
    residenceRooms,
    specialItems,
  ])

  /**
   * when residence type is updated:
   *  - set nb boxes
   *  - set residence rooms
   */
  useEffect(() => {
    if (editing && !allowOverride) {
      return
    }
    if (!residenceAndRoomsType) {
      return
    }
    if (!touchedFields.nbBoxes) {
      setValue('nbBoxes', defaultBoxesPerResidence[residenceAndRoomsType])
    }
    if (!dirtyFields.residenceRooms) {
      setValue('residenceRooms', roomsByResidenceMatrix[residenceAndRoomsType])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [residenceAndRoomsType, editing])

  /**
   * apply estimate when possible
   */
  useEffect(() => {
    if (editing && !allowOverride) {
      return
    }
    if (!estimate.volumeCubicFeet) {
      return
    }
    if (!dirtyFields.nbMen) {
      setValue(
        'nbMen',
        estimate.nbMen,
        { shouldValidate: true },
      )
    }
    if (!dirtyFields.estimatedMovingLabourTime) {
      setValue(
        'estimatedMovingLabourTime',
        estimate.estimatedMovingLabourTime,
        { shouldValidate: true },
      )
    }
    if (!dirtyFields.volumeCubicFeet) {
      setValue(
        'volumeCubicFeet',
        estimate.volumeCubicFeet,
        { shouldValidate: estimate.volumeCubicFeet > 0 },
      )
    }
    if (!dirtyFields.nbTrucks) {
      setValue(
        'nbTrucks',
        keyValueMapToRecord(estimate.nbTrucks),
        { shouldValidate: true },
      )
      setValue(
        'nbTrucksTotal',
        calculateNumberOfTrucks(estimate.nbTrucks),
        { shouldValidate: true },
      )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimate.volumeCubicFeet, editing])

  return (
    <>
      <Row className="lg:flex-col 2xl:flex-row">
        { customerIdentificationSection && (
          <Cell>{ customerIdentificationSection }</Cell>
        ) }

        <Cell className={mergeClassName(
          '2xl:max-w-[450px] 2xl:flex-auto',
          !customerIdentificationSection && '2xl:max-w-[550px]',
        )}
        >
          <DateTimeFormContent />
        </Cell>

        <Cell className="2xl:max-w-[650px] 2xl:flex-auto">
          <AddressesFormContent
            requireDetails={requireDetails}
            specificAddresses={specificAddresses}
          />
        </Cell>
      </Row>
      <Row className="lg:flex-col 2xl:flex-row">
        <Cell className="2xl:w-[550px] 2xl:flex-initial">
          <MoveContentFormContent
            requireDetails={requireDetails}
          />
        </Cell>

        <Cell>
          <MoveSizeAndRequirementsFormContent
            estimate={estimate}
            requireDetails={requireDetails}
          />
        </Cell>

        <Cell className="2xl:w-[550px] 2xl:flex-initial">
          <NbTrucksFormContent
            estimate={estimate}
          />
        </Cell>
      </Row>

      <Row>
        <Cell>
          <SpecialItemsAndServicesFormContent />
        </Cell>

        <Cell>
          <ProtectiveMaterialFormContent />
        </Cell>
      </Row>
    </>
  )
}

export default QuotesRequestFormContent
