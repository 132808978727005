import { type Amount } from '../../common/amount'
import { roundPrice } from './money'
import { getCurrencyDetails } from './getCurrencyDetails'

type Arguments = Amount | number

/**
 * add amounts of the same currency
 */
export const add = (base: Amount, ...args: Arguments[]): Amount => {
  validateCurrencies([base, ...args])
  return {
    price: reduce(base, args, (a, b) => a + b),
    currency: base.currency,
  }
}

/**
 * subtract amounts of the same currency
 */
export const subtract = (base: Amount, ...args: Arguments[]): Amount => {
  validateCurrencies([base, ...args])
  return {
    price: reduce(base, args, (a, b) => a - b),
    currency: base.currency,
  }
}

/**
 * multiply amounts of the same currency
 */
export const multiply = (base: Amount, ...args: Arguments[]): Amount => {
  validateCurrencies([base, ...args])
  return {
    price: reduce(base, args, (a, b) => a * b),
    currency: base.currency,
  }
}

/**
 * divide amounts of the same currency
 */
export const divide = (base: Amount, ...args: Arguments[]): Amount => {
  validateCurrencies([base, ...args])
  return {
    price: reduce(base, args, (a, b) => a / b),
    currency: base.currency,
  }
}

/**
 * helper to easily reduce a set of arguments (Amount or number)
 */
const reduce = (
  base: Amount,
  args: Arguments[],
  callback: (a: number, b: number) => number,
): number => {
  const { nbDecimals } = getCurrencyDetails(base.currency)
  return args.reduce<number>((acc, curr) => {
    const item = typeof curr === 'number' ? curr : curr.price
    return roundPrice(callback(acc, item), nbDecimals)
  }, base.price)
}

/**
 * throw an error if provided arguments have different currencies
 */
const validateCurrencies = (args: Arguments[]) => {
  const withCurrency = args.filter((argument) => typeof argument !== 'number')
  const currencies = new Set(withCurrency.map((argument) => argument.currency))
  if (currencies.size > 1) {
    throw new Error(
      'Operations can only occured with amount of the same currency',
    )
  }
}
