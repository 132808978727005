import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { Form, SixDigitsCodeField, SubmitButton } from '../../../form'
import Link from '../../../Link'
import useValidationSchema from './validationSchema'
import { type LoginTotpFormPayload } from './LoginTotpFormPayload.type'
import Route from '../../../../app/Route.enum'

type LoginTotpFormProps = {
  onSubmit: (payload: LoginTotpFormPayload) => Promise<any>
}

const LoginTotpForm: React.FC<LoginTotpFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation()
  const schema = useValidationSchema()
  const form = useForm({ resolver: yupResolver(schema) })

  return (
    <Form form={form} onSubmit={onSubmit}>

      <Box mt={2}>
        <Typography>
          { t('forms.loginTotp.description') }
        </Typography>
      </Box>

      <Box my={3}>
        <SixDigitsCodeField
          name="token"
          label={t('forms.fields.totp.label')}
        />
      </Box>

      <SubmitButton size="large">
        { t('actions.login') }
      </SubmitButton>
      <Link
        to={Route.Dashboard}
        className="ml-2 mt-6 block lg:ml-8 lg:mt-0 lg:inline-block"
        variant="primary"
      >
        { t('actions.cancel') }
      </Link>

    </Form>
  )
}

export default LoginTotpForm
