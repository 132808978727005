import { recordToKeyValueMap, keyValueMapToRecord } from '../../../../common/keyValueMap'
import {
  type CompanyBranchAdditionalCharges,
  pricingAsString,
  pricingAsNumber,
  type CompanyBranchPricing,
} from '../../../../modules/companies'
import { type AdditionalChargesFormValues } from './AdditionalChargesForm.type'

const pricingIntAsString = (pricing?: CompanyBranchPricing<number>) => {
  return pricingAsString(pricing, { asInteger: true })
}

export const CompanyBranchAdditionalChargesToFormData = (companyBranchAdditionalCharge?: CompanyBranchAdditionalCharges):
AdditionalChargesFormValues => {
  return {
    overnight: pricingIntAsString(companyBranchAdditionalCharge?.overnight),
    stairs: pricingIntAsString(companyBranchAdditionalCharge?.stairs),
    specialServices: keyValueMapToRecord(companyBranchAdditionalCharge?.specialServices, pricingIntAsString),
    specialItems: keyValueMapToRecord(companyBranchAdditionalCharge?.specialItems, pricingIntAsString),
  }
}

export const formatFormOutput = (formData: AdditionalChargesFormValues): CompanyBranchAdditionalCharges => {
  return {
    overnight: pricingAsNumber(formData.overnight),
    stairs: pricingAsNumber(formData.stairs),
    specialServices: recordToKeyValueMap(
      formData.specialServices,
      pricingAsNumber,
    ),
    specialItems: recordToKeyValueMap(
      formData.specialItems,
      pricingAsNumber,
    ),
  }
}
