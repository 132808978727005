import { useTranslation } from 'react-i18next'
import Alert from '@mui/material/Alert'

import FloatingTwoColumnsPage from '../../../components/layout/FloatingTwoColumnsPage'
import Title from '../../../components/users/authentication/Title'
import LoginSecondaryPanel from '../../../components/users/authentication/LoginSecondaryPanel'
import { useResetPasswordRequestAction, visitorPage } from '../../../modules/users'
import ServerError from '../../../components/errors/ServerError'
import ResetPasswordRequestForm from '../../../components/forms/users/ResetPasswordRequestForm'
import Link from '../../../components/Link'
import Route from '../../../app/Route.enum'

const ResetPasswordRequestPage: React.FC = () => {
  const { t } = useTranslation()
  const { resetPasswordRequest, data: success, error } = useResetPasswordRequestAction()

  return (
    <FloatingTwoColumnsPage secondaryPanelContent={<LoginSecondaryPanel />}>
      <Title>{ t('pages.users.resetPasswordRequest.title') }</Title>
      { !success && (
        <div className="pb-4 pt-8 text-gray-500">
          { t('pages.users.resetPasswordRequest.description') }
        </div>
      ) }

      { error && <ServerError error={error} /> }
      { success
        ? (
          <div className="mt-8">
            <Alert severity="success">
              { t('pages.users.resetPasswordRequest.success') }
            </Alert>
            <br />
            <Link to={Route.Dashboard} variant="primary">{ t('actions.backToHomepage') }</Link>
          </div>
          )
        : (
          <ResetPasswordRequestForm onSubmit={resetPasswordRequest} />
          ) }
    </FloatingTwoColumnsPage>
  )
}

export default visitorPage(ResetPasswordRequestPage)
