import { useTranslation } from 'react-i18next'

import Route from '../../../app/Route.enum'
import { errorNotification, successNotification } from '../../../components/ToastNotifications'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import ContainedPage from '../../../components/layout/ContainedPage'
import PageContent from '../../../components/layout/PageContent'
import PageTitle from '../../../components/layout/PageTitle'
import { useNavigate } from '../../../modules/navigation'
import { Permission, protectAdminPage, usePermission } from '../../../modules/users'
import { Error403Page } from '../../errors'
import { type PromoCodePayload, useCreatePromoCodeAction } from '../../../modules/adminPromoCodes'
import PromoCodeForm from '../../../components/forms/promoCodes/PromoCodeForm'

const CreatePromoCodePage: React.FC = () => {
  const { t } = useTranslation()
  const { createPromoCode } = useCreatePromoCodeAction()
  const formatError = useErrorFormatter()
  const { navigate } = useNavigate()
  const canManagePromoCodes = usePermission(Permission.canManagePromoCodes)

  const onSubmit = async (values: PromoCodePayload) => {
    try {
      await createPromoCode(values)
      successNotification('Promocode created')
      navigate(Route.PromoCodes)
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  if (!canManagePromoCodes) {
    return <Error403Page />
  }

  return (
    <ContainedPage>
      <PageTitle>
        { t('pages.admin.promoCodes.create.title') }
      </PageTitle>
      <PageContent className="py-8">
        <PromoCodeForm onSubmit={onSubmit} backlink={Route.PromoCodes} />
      </PageContent>
    </ContainedPage>
  )
}

export default protectAdminPage(CreatePromoCodePage)
