import COUNTRIES, { DEFAULT_COUNTRY } from './countries.const'
import REGIONS from './regions.const'

export const useCountries = () => COUNTRIES
export const useRegions = (country: keyof typeof REGIONS) => REGIONS[country]

export const useCountry = () => {
  // todo : use CloudFront header to set the default country ?
  return DEFAULT_COUNTRY
}
