import * as Yup from 'yup'

import { useUserValidationShape } from '../moveForm/validationSchema'
import { type EditMoveCustomerFormValues } from './EditMoveCustomerForm.type'

const useValidationSchema = () => {
  const userValidationShape = useUserValidationShape()
  return Yup.object().shape(userValidationShape) as Yup.ObjectSchema<EditMoveCustomerFormValues, EditMoveCustomerFormValues>
}

export default useValidationSchema
