import CfDateTime, { type DateTimeProps as CFDateTimeProps } from '@concrete-form/mui/DateTime/DateTime'

import Label from '../../Label'
import { mergeClassName } from '../../../../utils/mergeClassName'

const MwControlledDateTime = CfDateTime as React.FC<any>

export type DateTimeProps = CFDateTimeProps & Record<string, any>

const DateTime: React.FC<DateTimeProps> = ({
  label,
  required,
  size = 'small',
  ...inputProps
}) => {
  return (
    <Label
      required={required}
      label={label}
      className={mergeClassName(!label && 'p-0')}
    >
      <MwControlledDateTime
        slotProps={{
          textField: {
            size,
          },
        }}
        desktopModeMediaQuery=".never"
        closeOnSelect
        toolbarTitle={null}
        toolbarPlaceholder={null}
        {...inputProps}
      />
    </Label>
  )
}

export default DateTime
