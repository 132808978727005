import MuiTab, { type TabProps as MuiTabProps } from '@mui/material/Tab'
import { mergeClassName } from '../../utils/mergeClassName'

const Tab: React.FC<MuiTabProps> = ({ className, ...muiTabProps }) => {
  return (
    <MuiTab
      className={mergeClassName(
        '!mx-1 !rounded-t-lg !border !border-b-0 !border-solid !border-zinc-200 !bg-white !px-8 first:!ml-0 last:!mr-0 lg:!mx-2 !normal-case disabled:!text-gray-300',
        className,
      )}
      {...muiTabProps}
    />
  )
}

export default Tab
