export enum MoveStatus {
  NotSupported = 'notSupported',
  AgentMeetingBooked = 'agentMeetingBooked',
  QuoteAccepted = 'quoteAccepted',
  MoveApprovedByAgent = 'moveApprovedByAgent',
  JobAcceptedByPartner = 'jobAcceptedByPartner',
  Abandoned = 'abandoned',
  Selected = 'selected',
  Hot = 'hot',
  Pending = 'pending',
}
