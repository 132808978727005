import { useTranslation } from 'react-i18next'

import { useCurrentCompanyBranch } from '../../modules/companies'
import SidebarMenu, { SidebarMenuLink } from '../../components/layout/SidebarMenu'
import { steps, getCompletedSteps, getNextIncompleteStep } from '.'

const CompanyBranchSidebar: React.FC = () => {
  const { t } = useTranslation()
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()
  const setupInProgress = (currentCompanyBranch?.setupProgress ?? 0) < 100
  const completedSteps = getCompletedSteps(currentCompanyBranch)
  const nextIncompleteStep = getNextIncompleteStep(currentCompanyBranch)

  return (
    <SidebarMenu className="bg-white">

      { Object.entries(steps).map(([step, { route, Icon }]) => (
        <SidebarMenuLink
          key={step}
          to={route}
          Icon={Icon}
          completed={setupInProgress && completedSteps[step]}
          disabled={setupInProgress && (!completedSteps[step] && nextIncompleteStep?.route !== route)}
        >
          { t(`pages.companyBranch.menu.${step}`) }
        </SidebarMenuLink>
      )) }

    </SidebarMenu>
  )
}

export default CompanyBranchSidebar
