import { formatDate } from '../../../utils/date'

type DateValueProps = {
  format?: string
  date?: Date | string | null
}

const DateValue: React.FC<DateValueProps> = ({
  format,
  date,
}) => {
  return (
    <>{ formatDate(date, format) }</>
  )
}

export default DateValue
