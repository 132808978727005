/* eslint-disable @typescript-eslint/no-non-null-assertion */
import LabourIcon from '@mui/icons-material/GroupOutlined'

import Paper from '../../../../components/Paper'
import { useTranslation } from 'react-i18next'
import { type CompanyBranch, useCompanyBranchCurrency, useLabourPricing } from '../../../../modules/companies'
import Price from '../../../../components/Price'

type DateLabourPricingProps = {
  companyBranch: CompanyBranch
  date: Date
}

const DateLabourPricing: React.FC<DateLabourPricingProps> = ({
  companyBranch,
  date,
}) => {
  const { t } = useTranslation()
  const getLabourPricing = useLabourPricing()
  const currency = useCompanyBranchCurrency(companyBranch)

  const labourPricing = getLabourPricing({
    companyBranch,
    date,
  })

  const showSingleMan = !!labourPricing.movingLabourSingleMan || !!labourPricing.packingLabourSingleMan
  const maxNbMen = Math.max(
    labourPricing.movingLabour.length,
    labourPricing.packingLabour?.length ?? 0,
  )

  const renderPrice = (price?: number) => {
    if (!price) {
      return <div className="text-gray-300">-</div>
    }
    return (
      <Price
        amount={{
          price,
          currency,
        }}
        showDecimals={false}
        compact
      />
    )
  }

  return (
    <Paper className="mb-4 shrink-0 child:p-0 lg:w-auto lg:pb-0 child:lg:p-0 2xl:w-auto">
      <div className="overflow-auto  p-3">
        <table className="w-full">
          <thead>
            <tr>
              <th className="w-full text-right">
                <LabourIcon />
              </th>
              { showSingleMan && <th>1</th> }
              { Array.from({ length: maxNbMen }).map((_, index) => (
                <th
                  key={`nbMen:${index + 2}`}
                  className="text-right font-normal"
                >
                  { index + 2 }
                </th>
              )) }
              <th className="text-right font-normal">
                { t('pages.companyBranch.availability.ruleModal.extra') }
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                { t('pages.companyBranch.availability.ruleModal.moving') }
              </td>
              { showSingleMan && (
                <td className="pl-4 text-right font-bold">
                  { renderPrice(labourPricing.movingLabourSingleMan) }
                </td>
              ) }
              { Array.from({ length: maxNbMen }).map((_, index) => (
                <td
                  key={`nbMen:${index + 2}`}
                  className="pl-4 text-right font-bold"
                >
                  { renderPrice(labourPricing.movingLabour[index]) }
                </td>
              )) }
              <td className="whitespace-nowrap pl-4 text-right font-bold">
                +{ renderPrice(labourPricing.movingLabourExtraMen) }
              </td>
            </tr>

            { labourPricing.packingLabour && (
              <tr>
                <td>
                  { t('pages.companyBranch.availability.ruleModal.packing') }
                </td>
                { showSingleMan && (
                  <td className="pl-4 text-right font-bold">
                    { renderPrice(labourPricing.packingLabourSingleMan) }
                  </td>
                ) }
                { Array.from({ length: maxNbMen }).map((_, index) => (
                  <td
                    key={`nbMen:${index + 2}`}
                    className="pl-4 text-right font-bold"
                  >
                    { renderPrice(labourPricing.packingLabour?.[index]) }
                  </td>
                )) }
                <td className="whitespace-nowrap pl-4 text-right font-bold">
                  +{ renderPrice(labourPricing.packingLabourExtraMen) }
                </td>
              </tr>
            ) }

          </tbody>
        </table>
      </div>
    </Paper>
  )
}

export default DateLabourPricing
