import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'

import ServerError from '../../../../errors/ServerError'
import EnableTwoFactorForm from '../../../../forms/users/EnableTwoFactorForm'
import { type EnableTwoFactorFormPayload } from '../../../../forms/users/EnableTwoFactorForm/EnableTwoFactorFormPayload.type'
import { useEnableMfaAction } from '../../../../../modules/users'
import { type QrCodeData } from './EnableTwoFactorModal'

type Step2Props = {
  onComplete: () => void
  onClose: () => void
  step: number
  nbSteps: number
  qrCodeData: QrCodeData
}

const Step2: React.FC<Step2Props> = ({
  onComplete,
  onClose,
  step,
  nbSteps,
  qrCodeData,
}) => {
  const { t } = useTranslation()
  const { enableMfa, error } = useEnableMfaAction()

  const onSubmit = async (data: EnableTwoFactorFormPayload) => {
    try {
      await enableMfa(data)
      onComplete()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Typography variant="h3">
        { t('pages.users.profile.security.mfa.modal.step2Title', {
          step,
          nbSteps,
        }) }
      </Typography>
      <Typography>
        { t('pages.users.profile.security.mfa.modal.step2Message') }
      </Typography>

      <Box p="2rem" textAlign="center">
        <img alt="" src={qrCodeData.qrCode} />
      </Box>

      <Alert severity="info">
        { t('pages.users.profile.security.mfa.modal.cannotScanQrCode', { key: qrCodeData.secret }) }
      </Alert>

      { error && <Box mt="1rem"><ServerError error={error} /></Box> }

      <EnableTwoFactorForm onSubmit={onSubmit} onClose={onClose} />
    </>
  )
}

export default Step2
