import { gql } from '@apollo/client'
import { jobRequestFieldsForPartner } from './jobRequests.queries'

export const acceptJobRequest = gql`
mutation acceptJobRequest($jobRequestId: String!, $payload: AcceptJobRequestDto) {
  acceptJobRequest(jobRequestId: $jobRequestId, payload: $payload) {
    ${jobRequestFieldsForPartner}
  }
}
`

export const declineJobRequest = gql`
mutation declineJobRequest($jobRequestId: String!, $payload: DeclineJobRequestDto) {
  declineJobRequest(jobRequestId: $jobRequestId, payload: $payload) {
    ${jobRequestFieldsForPartner}
  }
}
`
