import {
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client'

import {
  type PaginatedQueryResult,
  type PaginatedQueryResultData,
  type QueryResult,
} from '../../graphQl'
import {
  type JobRequestForPartner,
  type LightJobRequest,
} from './jobRequests.models'
import * as queries from './jobRequests.queries'
import * as mutations from './jobRequests.mutations'
import {
  useCurrentCompanyBranch,
  useNotifications,
} from '../../companies'
import {
  type AcceptJobRequestPayload,
  type DeclineJobRequestPayload,
  type JobRequestsPaginatedQuery,
} from './jobRequests.types'
import { useConfig } from '../../config'
import { JobRequestStatus } from './jobRequests.enums'

type FetchJobRequestsOptions = {
  reload?: boolean
  companyBranchId?: string
}

type UseJobRequestsHookResult = {
  fetchJobRequests: (variables: JobRequestsPaginatedQuery, options?: FetchJobRequestsOptions) => Promise<PaginatedQueryResultData<LightJobRequest>>
} & PaginatedQueryResult<LightJobRequest>

/**
 * returns all job requests for a given company branch
 * query optimized for data table
 */
export const useJobRequests = ({ reload = false, ...queryArgs } = {}): UseJobRequestsHookResult => {
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()
  const fetchPolicy = reload ? 'network-only' : 'cache-first'

  const [fetch, { data, previousData, networkStatus, loading, error, ...queryProps }] = useLazyQuery(
    queries.getJobRequests,
    {
      fetchPolicy,
      ...queryArgs,
    },
  )
  const fetchJobRequests = async (query: JobRequestsPaginatedQuery = {}, {
    reload = false,
    companyBranchId,
  }: FetchJobRequestsOptions = {}) => {
    const fetchPolicy = reload ? 'network-only' : 'cache-first'
    const { data } = await fetch({
      variables: {
        companyBranchId: companyBranchId ?? currentCompanyBranch?.id,
        ...query,
      },
      fetchPolicy,
    })
    return data?.getJobRequests
  }

  return {
    fetchJobRequests,
    data: (data ?? previousData)?.getJobRequests,
    loading: (loading && networkStatus === 1),
    error,
    ...queryProps,
  }
}

/**
 * get a job request by id
 */
export const useJobRequest = (jobRequestId?: string, { reload = false, ...queryArgs } = {}): QueryResult<JobRequestForPartner | undefined> => {
  const fetchPolicy = reload ? 'network-only' : 'cache-first'

  const { data, networkStatus, loading, error, ...queryProps } = useQuery(
    queries.getJobRequest,
    {
      skip: !jobRequestId,
      variables: { jobRequestId },
      fetchPolicy,
      ...queryArgs,
    },
  )

  return {
    data: data?.getJobRequest,
    loading: (loading && networkStatus === 1),
    error,
    ...queryProps,
  }
}

/**
 * this will fetch the pending job requests for the current company branch
 */
export const useFetchPendingRequestsNotifications = () => {
  const pendingPreviewLimit = useConfig<number>('jobRequests.pendingPreviewLimit')
  const { listening, set } = useNotifications()
  const { fetchJobRequests } = useJobRequests()

  return async () => {
    if (!listening) {
      return
    }
    const key = `${listening}:pendingRequestsNotifications`

    const data = await fetchJobRequests({
      query: {
        nbResultsPerPage: pendingPreviewLimit,
        page: 1,
      },
      status: JobRequestStatus.Pending,
    }, {
      reload: true,
      companyBranchId: listening,
    })
    set(key, data)
  }
}

/**
 * return the pending request notifications globally fetch in the app
 */
export const usePendingRequestsNotifications = (companyBranchId?: string): PaginatedQueryResultData<LightJobRequest> | undefined => {
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()
  const { get } = useNotifications()
  const id = companyBranchId ?? currentCompanyBranch?.id
  if (!id) {
    return
  }
  const key = `${id}:pendingRequestsNotifications`
  return get(key)
}

/**
 * accept a job request
 */
export const useAcceptJobRequestAction = () => {
  const [acceptJobRequest, { data, loading, ...mutationProps }] = useMutation(mutations.acceptJobRequest)

  return {
    acceptJobRequest: async (jobRequestId: string, payload: AcceptJobRequestPayload) => {
      const { data } = await acceptJobRequest({
        variables: {
          jobRequestId,
          payload,
        },
      })

      return data.acceptJobRequest
    },
    data: data?.acceptJobRequest,
    loading,
    ...mutationProps,
  }
}

/**
 * decline a job request
 */
export const useDeclineJobRequestAction = () => {
  const [declineJobRequest, { data, loading, ...mutationProps }] = useMutation(mutations.declineJobRequest)

  return {
    declineJobRequest: async (jobRequestId: string, payload: DeclineJobRequestPayload) => {
      const { data } = await declineJobRequest({
        variables: {
          jobRequestId,
          payload,
        },
      })

      return data.declineJobRequest
    },
    data: data?.declineJobRequest,
    loading,
    ...mutationProps,
  }
}
