import {
  type ApolloQueryResult,
  type OperationVariables,
} from '@apollo/client'

export type QueryResult<T> = {
  data: T
  loading: boolean
  called: boolean
  error?: Error
  refetch?: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<T>>
}

export type PaginatedQueryResultData<T> = {
  count: number
  hasMore: boolean
  data: T[]
}

export type PaginatedQueryResult<T> = QueryResult<PaginatedQueryResultData<T>>

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type PaginatedQuery = {
  nbResultsPerPage?: number
  page?: number
  sortField?: string
  sortDirection?: SortDirection
  search?: string
  excludeDeleted?: boolean
}
