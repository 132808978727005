import {
  type ToastContent,
  type ToastOptions,
  toast,
} from 'react-toastify'
import { error } from '../../modules/monitoring'

export const infoNotification = (message: ToastContent, options?: ToastOptions) => {
  toast.info(message, options)
}

export const successNotification = (message: ToastContent, options?: ToastOptions) => {
  toast.success(message, options)
}

export const warningNotification = (message: ToastContent, options?: ToastOptions) => {
  toast.warning(message, options)
}

export const errorNotification = (message: ToastContent, options?: ToastOptions) => {
  error(message as any)
  toast.error(message, options)
}
