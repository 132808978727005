import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { type FullCompanyBranch } from '../../../../modules/adminCompanies'
import { escape } from '../../../../utils/html'

type WarehouseTabProps = {
  companyBranch: FullCompanyBranch
}

const WarehouseTab: React.FC<WarehouseTabProps> = ({ companyBranch }) => {
  const { t } = useTranslation()
  const mapRef = useRef(null)
  const map = useRef<any>()
  const marker = useRef<any>()
  const infoWindow = useRef<any>()

  const [longitude, latitude] = companyBranch.tripAndTravel?.trucksOriginCoordinates?.coordinates ?? []

  useEffect(() => {
    if (!longitude || !latitude) {
      return
    }
    if (!mapRef.current) {
      return
    }
    const Map = (window as any).google.maps.Map
    const Marker = (window as any).google.maps.Marker
    const InfoWindow = (window as any).google.maps.InfoWindow

    const coordinates = { lat: latitude, lng: longitude }

    map.current = new Map(mapRef.current, {
      center: coordinates,
      zoom: 12,
    })

    marker.current = new Marker({
      position: coordinates,
      map: map.current,
    })

    infoWindow.current = new InfoWindow({
      content: escape(t('pages.admin.companies.branchPanel.warehouseMarker', {
        companyName: companyBranch.company.identification.name.en,
      })),
    })

    infoWindow.current.open(map.current, marker.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, longitude, latitude])

  if (!longitude || !latitude) {
    return null
  }

  return (
    <div ref={mapRef} className="h-[calc(100dvh-240px)] w-full" />
  )
}

export default WarehouseTab
