import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import config from '../../../config'

const { i18n: i18nConfig } = config

const locales = i18nConfig?.locales ?? {}
const availableLanguages = Object.keys(locales)
const defaultLanguage = i18nConfig?.defaultLanguage ?? availableLanguages[0]
const defaultNs = i18nConfig?.defaultNs ?? 'global'

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: availableLanguages,
    nonExplicitSupportedLngs: true,
    resources: locales,
    fallbackLng: defaultLanguage,
    defaultNS: defaultNs,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
