import Files from '../../../../components/Files/Files'
import { type CompanyBranch, useLegalFileUrlGenerator } from '../../../../modules/companies'

type FilesTabProps = {
  companyBranch: CompanyBranch
}

const FilesTab: React.FC<FilesTabProps> = ({
  companyBranch,
}) => {
  const getLegalFileUrl = useLegalFileUrlGenerator(companyBranch)
  return (
    <div className="px-2">
      <Files
        files={companyBranch?.legalFiles}
        getUrl={getLegalFileUrl}
      />
    </div>
  )
}

export default FilesTab
