import { styled } from '@mui/material/styles'
import MuiModal, { type ModalProps as MuiModalProps } from '@mui/material/Modal'
import Box from '@mui/material/Box'

const ModalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100dvh',
  padding: '5vh 5vw',
  boxSizing: 'border-box',
}))

const ModalContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  background: theme.palette.background.paper,
  borderRadius: 5,
  overflow: 'hidden',
  padding: '2rem 1rem',
  [theme.breakpoints.down('md')]: {
    width: '95vw',
  },
}))

const ScrollableContent = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: '1rem',
  overflow: 'auto',
  scrollbarWidth: 'thin',
}))

export type ModalProps = {
  width?: number
  children?: React.ReactNode
} & Omit<MuiModalProps, 'children'>

const Modal: React.FC<ModalProps> = ({
  children,
  width = 500,
  ...props
}) => {
  const onBackdropClick = (event: React.MouseEvent<HTMLElement>) => {
    if (event.target instanceof HTMLElement && event.target.dataset.component === 'backdrop') {
      props.onClose?.(event, 'backdropClick')
    }
  }

  return (
    <MuiModal
      {...props}
    >
      <div
        className="bg-background/80 backdrop-blur-sm transition-all duration-100"
        onClick={onBackdropClick}
      >
        <ModalContainer data-component="backdrop">
          <ModalContent
            width={width}
          >
            <ScrollableContent>
              { children }
            </ScrollableContent>
          </ModalContent>
        </ModalContainer>
      </div>
    </MuiModal>
  )
}

export default Modal
