import { useTranslation } from 'react-i18next'

import Spinner from '../../components/Spinner'
import { errorNotification, successNotification } from '../../components/ToastNotifications'
import CompanyForm from '../../components/forms/companies/CompanyForm'
import ContainedPage from '../../components/layout/ContainedPage'
import PageContent from '../../components/layout/PageContent'
import PageTitle from '../../components/layout/PageTitle'
import { type CompanyPayload, useCurrentCompany, useCurrentCompanyBranchName, useOnBehalfState, useUpdateCompanyAction } from '../../modules/companies'
import { Error404Page, Error500Page } from '../errors'
import { useNavigate, useQueryString } from '../../modules/navigation'
import Route from '../../app/Route.enum'
import { useErrorFormatter } from '../../components/errors/useErrorFormatter'
import Paper from '../../components/Paper'

const EditCompanyPage: React.FC = () => {
  const { t } = useTranslation()
  const currentBranchName = useCurrentCompanyBranchName()
  const { data: currentCompany, loading, error } = useCurrentCompany()
  const { onBehalf } = useOnBehalfState()
  const { updateCompany } = useUpdateCompanyAction()
  const { navigate } = useNavigate()
  const formatError = useErrorFormatter()
  const from = useQueryString('from')

  const getNextRoute = () => {
    if (from === 'settings') {
      return Route.BranchSettingsLandingPage
    }
    return onBehalf ? Route.CompaniesOnBehalf : Route.Dashboard
  }

  const handleSubmit = async (values: CompanyPayload) => {
    try {
      await updateCompany(values)
      successNotification(t('forms.companyBranch.success'))
      navigate(getNextRoute())
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  if (loading) {
    return <ContainedPage><Spinner /></ContainedPage>
  }

  if (!currentCompany) {
    return <Error404Page />
  }

  if (error) {
    return <Error500Page />
  }

  return (
    <ContainedPage>
      <PageTitle>
        { currentBranchName }
      </PageTitle>
      <PageContent>
        <Paper>
          <CompanyForm
            onSubmit={handleSubmit}
            data={currentCompany}
            backlink={getNextRoute()}
          />
        </Paper>
      </PageContent>
    </ContainedPage>
  )
}

export default EditCompanyPage
