import { toZonedTime } from 'date-fns-tz'

import { type MovePayload, type FullMove } from '../../../../modules/adminMoves'
import { type CreateMoveFormValues, type EditMoveFormValues } from './MoveForm.type'
import {
  quotesRequestToFormData,
  formatFormOutput as formatQuotesRequest,
} from '../../quotes/QuotesRequestForm/formDataBuilder'

export const moveToEditFormData = (
  move: Omit<FullMove, 'user'>,
): EditMoveFormValues => {
  return quotesRequestToFormData({
    /* quote request */
    movingDate: move.originTimezone
      ? toZonedTime(move.movingDate, move.originTimezone)
      : new Date(move.movingDate),
    flexibleDate: move.flexibleDate,
    preferredTime: move.preferredTime,
    overnightFees: move.overnightFees,
    origin: move.origin,
    destination: move.destination,
    stops: move.stops,
    nbTrucks: move.nbTrucks,
    nbMen: move.nbMen,
    estimatedMovingLabourTime: move.estimatedMovingLabourTime,
    estimatedPackingLabourTime: move.estimatedPackingLabourTime,
    specialServices: move.specialServices,
    specialItems: move.specialItems,
    protectiveMaterial: move.protectiveMaterial,

    /* details */

    residenceType: move.residenceType,
    residenceRooms: move.residenceRooms,
    nbBoxes: move.nbBoxes,
    furnitureRatio: move.furnitureRatio,
    volumeCubicFeet: move.volumeCubicFeet,
    weightPounds: move.weightPounds,
  })
}

export const moveToCreateFormData = (
  move: FullMove,
): CreateMoveFormValues => {
  const { user, ...moveData } = move

  return {
    ...moveToEditFormData(moveData),
    user,
  }
}

export const formatEditFormOutput = (data: EditMoveFormValues): Omit<MovePayload, 'user'> => {
  const [quoteRequest, quoteRequestDetails] = formatQuotesRequest(data)
  return {
    ...quoteRequest,
    ...quoteRequestDetails,
  }
}

export const formatCreateFormOutput = (data: CreateMoveFormValues): MovePayload => {
  const { user, ...move } = data
  return {
    ...formatEditFormOutput(move),
    user,
  }
}
