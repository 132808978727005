import { type LightJob } from '../../../modules/jobs'
import { mergeClassName } from '../../../utils/mergeClassName'
import JobStatusTag from '../JobStatusTag'

type StatusProps = {
  job: LightJob
}

const Status: React.FC<StatusProps> = ({
  job,
}) => {
  return (
    <div className={mergeClassName(
      'text-center lg:min-w-[200px] lg:text-left',
    )}
    >
      <JobStatusTag jobStatus={job.status} />
    </div>
  )
}

export default Status
