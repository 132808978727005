import { useRef, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

import Header from './Header'
import Footer from './Footer'
import Spinner from '../../Spinner'
import { useDataGridContext } from '../DataGridContext'
import { type CustomFiltersProps } from '../DataGrid.type'
import ToggleColumnsMenu from '../ToggleColumnsMenu'
import { useVisibleColumns } from '../useToggleColumns'
import { clsx } from 'clsx'

const GridContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  minHeight: 500,
  maxHeight: 'calc(100dvh - 400px)',
  boxSizing: 'border-box',

  [theme.breakpoints.down('md')]: {
    padding: '0.75rem 0',
    margin: '0 0.75rem',
  },

  '&.no-min-height': {
    minHeight: 0,
  },

  '&.no-table': {
    minHeight: 0,
    maxHeight: 'none',
  },
}))

const GridHeader = styled(Box)(({ theme }) => ({
  position: 'relative',
  flexShrink: 0,
}))

const GridContent = styled(Box)(({ theme }) => ({
  position: 'relative',
  flexGrow: 1,
  minHeight: 185,
  overflow: 'auto',
  '& > table': {
    width: '100%',
    borderCollapse: 'collapse',
    whiteSpace: 'nowrap',
  },

  '&.no-min-height': {
    minHeight: 0,
  },

  '&.no-table': {
    minHeight: 0,
    overflow: 'visible',
  },
}))

const GridFooter = styled(Box)(({ theme }) => ({
  flexShrink: 0,
}))

const HeaderShadow = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  left: 0,
  zIndex: 10,
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 60,
    boxShadow: '0px 3px 5px rgba(0,0,0,0.10)',
  },
}))

const SpinnerContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  background: 'rgba(255, 255, 255, .7)',
  zIndex: 20,
}))

type ContainerProps = {
  children?: React.ReactNode
  customFilters?: React.FC<CustomFiltersProps>
  isTable?: boolean
  isStatic?: boolean
  disableMinHeight?: boolean
}

const Grid: React.FC<ContainerProps> = ({
  children,
  customFilters,
  isTable = true,
  isStatic = false,
  disableMinHeight = false,
}) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const { loading } = useDataGridContext()
  const visibleColumns = useVisibleColumns()

  /**
   * scroll to top when updating the data
   */
  useEffect(() => {
    if (!contentRef.current) {
      return
    }
    contentRef.current.scrollTo(0, 0)
  }, [loading, contentRef])

  /**
   * scroll to left when updating columns to fix overshoot
   */
  useEffect(() => {
    if (!contentRef.current) {
      return
    }
    contentRef.current.scrollTo({ left: 0 })
  }, [contentRef, visibleColumns])

  return (
    <GridContainer className={clsx(
      disableMinHeight && 'no-min-height',
      !isTable && 'no-table',
    )}
    >
      { !isStatic && (
        <GridHeader>
          <Header customFilters={customFilters} />

          { isTable && (
            <div className="absolute bottom-[-52px] right-px z-[19]">
              <ToggleColumnsMenu />
            </div>
          ) }
        </GridHeader>
      ) }

      <GridContent
        ref={contentRef}
        className={clsx(
          disableMinHeight && 'no-min-height',
          !isTable && 'no-table',
        )}
      >
        { loading && (<SpinnerContainer><Spinner /></SpinnerContainer>) }
        { isTable && (<HeaderShadow />) }

        { children }
      </GridContent>

      { !isStatic && (
        <GridFooter>
          <Footer />
        </GridFooter>
      ) }
    </GridContainer>
  )
}

export default Grid
