import { styled, lighten, darken } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const RoleItem = styled(Box)(({ theme }) => ({
  '& > strong': {
    display: 'block',
    color: theme.palette.text.primary,
    fontStyle: 'normal',
  },
  marginBottom: '0.75rem',
  fontStyle: 'italic',
  color: theme.palette.mode === 'dark' ? darken(theme.palette.text.primary, 0.4) : lighten(theme.palette.text.primary, 0.4),
}))
