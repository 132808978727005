import { ResidenceAndRoomsType } from '../quotes.enum'

export const defaultBoxesPerResidence: Record<ResidenceAndRoomsType, number> = {
  [ResidenceAndRoomsType.Studio]: 20,
  [ResidenceAndRoomsType.Apartment1Bedroom]: 40,
  [ResidenceAndRoomsType.Apartment2Bedroom]: 40,
  [ResidenceAndRoomsType.Apartment3Bedroom]: 120,
  [ResidenceAndRoomsType.Apartment4Bedroom]: 160,
  [ResidenceAndRoomsType.House1Bedroom]: 60,
  [ResidenceAndRoomsType.House2Bedroom]: 120,
  [ResidenceAndRoomsType.House3Bedroom]: 160,
  [ResidenceAndRoomsType.House4Bedroom]: 200,
  [ResidenceAndRoomsType.AssistedLiving]: 20,
  [ResidenceAndRoomsType.OfficeSpace]: 120,
  [ResidenceAndRoomsType.StorageUnit5By5]: 20,
  [ResidenceAndRoomsType.StorageUnit5By10]: 40,
  [ResidenceAndRoomsType.StorageUnit10By20]: 60,
}
