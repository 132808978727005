import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useWatch } from 'react-hook-form'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import { type SelectChangeEvent } from '@mui/material/Select'

import { Select } from '../../../form'
import { capitalizeFirstLetter } from '../../../../utils/string'

type SelectWeekDaysProps = {
  name: string
  className?: string
}

const SelectWeekDays: React.FC<SelectWeekDaysProps> = ({
  name,
  className,
}) => {
  const { t } = useTranslation()
  const [values, setValues] = useState<string[]>([])
  const formValues = useWatch({ name })

  useEffect(() => {
    setValues(formValues)
  }, [formValues])

  const monthNames = Object.values(t('date.weekDays', { returnObjects: true }))
  const daysOptions = monthNames.map((month: string, index) => ({
    value: String(index + 1),
    label: capitalizeFirstLetter(month),
  }))

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setValues(event.target.value as string[])
  }

  return (
    <Select
      name={name}
      size="small"
      multiple
      renderValue={(selected: any) => (
        <div className="flex w-full flex-wrap gap-1">
          { selected.length === 0 && (
            <div className="text-gray-400">
              { t('forms.companyBranch.availability.temporality.select') }
            </div>
          ) }
          { selected
            .sort((a: string, b: string) => parseInt(a) - parseInt(b))
            .map((value: string) => {
              const label = daysOptions.find(o => o.value === value)?.label
              return (
                <Chip key={value} label={label} size="small" />
              )
            }) }
        </div>
      )}
      displayEmpty
      onChange={handleChange}
      className={className}
    >
      { daysOptions.map(option => {
        const checked = !!values?.includes(option.value)
        return (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox
              checked={checked}
              style={checked ? { color: '#fff' } : undefined}
              size="small"
            />
            <ListItemText primary={option.label} />
          </MenuItem>
        )
      }) }
    </Select>
  )
}

export default SelectWeekDays
