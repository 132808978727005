import { mergeClassName } from '../../utils/mergeClassName'

type PaperTitleProps = {
  children?: React.ReactNode
  icon?: React.ReactNode
  className?: string
}

const PaperTitle: React.FC<PaperTitleProps> = ({
  icon,
  children,
  className,
}) => {
  return (
    <h2 className={mergeClassName(
      'flex gap-x-[0.35rem] text-base font-sans font-semibold neutral-700 bg-neutral-50/40 px-4 py-2 text-slate-700 border-b rounded-t-md',
      className,
    )}
    >
      { icon && (
        <div className="text-neutral-400 child:!text-[16px]">
          { icon }
        </div>
      ) }
      { children }
    </h2>
  )
}

export default PaperTitle
