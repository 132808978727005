import { mergeClassName } from '../../utils/mergeClassName'
import { type Step } from './Step.type'
import StepComponent from './Step'
import Separator from './Separator'

type StepsProps = {
  steps: Step[]
  className?: string
  fullWidth?: string
  compact?: boolean
}

const Steps: React.FC<StepsProps> = ({
  steps,
  className,
  fullWidth = true,
  compact = false,
}) => {
  return (
    <div className={mergeClassName(
      'border bg-white',
      className,
    )}
    >
      <ul
        className={mergeClassName(
          'divide-gray-300 lg:inline-flex lg:divide-y-0',
          fullWidth && 'lg:flex',
        )}
      >
        { steps.map((step, index) => {
          const completed = !!step.completed
          const warning = !!step.warning
          const nextCompleted = !!steps[index + 1]?.completed
          const nextWarning = !!steps[index + 1]?.warning
          const showSeparator = index !== steps.length - 1

          return (
          // eslint-disable-next-line react/no-array-index-key
            <li key={index} className="relative lg:flex lg:flex-1">
              <StepComponent
                {...step}
                compact={compact}
              />

              { showSeparator && (
                <Separator
                  completed={completed}
                  warning={warning}
                  nextCompleted={nextCompleted}
                  nextWarning={nextWarning}
                />
              ) }
            </li>
          )
        }) }
      </ul>
    </div>
  )
}

export default Steps
