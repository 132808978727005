import { useTranslation } from 'react-i18next'
import MessageIcon from '@mui/icons-material/ChatOutlined'

import { type FullMove } from '../../../../../modules/adminMoves'
import Paper from '../../../../../components/Paper'
import ExpandableMessage from '../../../../../components/ExpandableMessage'

type CustomerMessageBoxProps = {
  move: FullMove
}

const CustomerMessageBox: React.FC<CustomerMessageBoxProps> = ({
  move,
}) => {
  const { t } = useTranslation()
  if (!move.quoteAccepted?.message) {
    return null
  }

  return (
    <Paper>
      <h2 className="mb-4 font-body2 text-2xl font-semibold text-slate-700">
        <MessageIcon />
        { ' ' }
        { t('pages.admin.move.details.customerMessage.title') }
      </h2>

      <ExpandableMessage message={move.quoteAccepted.message} />
    </Paper>
  )
}

export default CustomerMessageBox
