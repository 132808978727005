import { useTranslation } from 'react-i18next'

import { type LightJobRequest } from '../../../modules/jobRequests'
import { mergeClassName } from '../../../utils/mergeClassName'
import Price from '../../Price'

type EstimateProps = {
  jobRequest: LightJobRequest
  compact?: boolean
}

const Estimate: React.FC<EstimateProps> = ({
  jobRequest,
  compact = false,
}) => {
  const { t } = useTranslation()

  return (
    <div className={mergeClassName(
      'text-center lg:min-w-[220px] lg:text-left 2xl:text-right',
      compact && 'lg:text-right',
    )}
    >
      <div className={mergeClassName(
        'text-2xl',
        compact && 'text-xl',
      )}
      >
        <Price amount={jobRequest.subtotal} />
      </div>

      { !compact && (
        <div>
          <span className="text-sm text-neutral-400">
            { t('jobRequest.beforeTaxesAndDiscounts') }
          </span>
        </div>
      ) }
    </div>
  )
}

export default Estimate
