import Rating, { type RatingProps } from './Rating'
import { Source } from './Rating.types'

const MovingWaldoRating: React.FC<Omit<RatingProps, 'source'>> = (props) => {
  return (
    <Rating
      {...props}
      source={Source.MovingWaldo}
    />
  )
}

export default MovingWaldoRating
