import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import {
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/AddCircleOutline'
import RemoveIcon from '@mui/icons-material/CancelOutlined'

import {
  Input,
} from '../../../../form'
import { type QuotesRequestStopsFormValues } from '../QuotesRequestForm.type'
import LocationFields from './LocationFields'

type StopsProps = {
  requireDetails?: boolean
  specificAddresses?: boolean
}

const Stops: React.FC<StopsProps> = ({
  requireDetails = false,
  specificAddresses = false,
}) => {
  const name = 'stops'

  const { t } = useTranslation()
  const form = useFormContext()
  const { errors } = useFormState()
  const stops: QuotesRequestStopsFormValues[] = useWatch({ name }) ?? []

  const nbStops = stops.length
  const hasErrors = !!errors[name]

  /**
   * add a stop
   */
  const addStop = () => {
    form.setValue(name, [
      ...stops.map(stop => ({
        ...stop,
        id: uuid(),
      })),
      {
        id: uuid(),
        description: '',
        stopDuration: '',
      },
    ])
  }

  /**
   * remove a stop
   */
  const removeStop = (index: number) => {
    const newStops = [...stops.map(stop => ({
      ...stop,
      id: uuid(),
    }))]
    newStops.splice(index, 1)
    form.setValue(name, newStops, { shouldValidate: hasErrors })
  }

  return (
    <div className="mt-4">

      { stops.map((stop, index) => (
        <div
          key={stop.id}
          className="mb-4"
        >
          <div className="mb-4 flex items-center font-sans text-lg font-bold">
            { t('forms.quotesRequest.addresses.stopTitle', { index: index + 1 }) }
            <IconButton
              color="error"
              onClick={() => { removeStop(index) }}
            >
              <RemoveIcon />
            </IconButton>
          </div>

          <LocationFields
            name={`stops.${index}`}
            requireDetails={requireDetails}
            specificAddresses={specificAddresses}
          >
            <div className="mb-2 flex flex-col gap-2 md:flex-row">
              <div className="grow">
                <Input
                  name={`stops.${index}.description`}
                  placeholder={t('forms.fields.stopDescription.label')}
                  size="small"
                />
              </div>

              <div className="w-[150px] shrink-0">
                <Input
                  name={`stops.${index}.stopDuration`}
                  size="small"
                  inputProps={{
                    maxLength: 4,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        { t('units.hour_s') }
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </LocationFields>
        </div>
      )) }

      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={addStop}
        disabled={nbStops >= 3}
        startIcon={<AddIcon />}
      >
        { t('forms.quotesRequest.addresses.addStop') }
      </Button>
    </div>
  )
}

export default Stops
