import { useState } from 'react'

import { type FullCompany, useGetInvitationUserIdAction } from '../../../../modules/adminCompanies'
import { type CompanyInvitationFormStep1Payload } from './step1/CompanyInvitationFormStep1.type'
import CompanyInvitationFormStep1 from './step1/CompanyInvitationFormStep1'
import { useErrorFormatter } from '../../../errors/useErrorFormatter'
import { errorNotification } from '../../../ToastNotifications'
import { type InvitePartnerPayload } from '../../../../modules/adminCompanies/core/adminCompanies.type'
import CompanyInvitationFormStep2 from './step2/CompanyInvitationFormStep2'
import { type CompanyInvitationFormStep2Payload } from './step2/CompanyInvitationFormStep2.type'

type CompanyInvitationFormProps = {
  company: FullCompany
  onSubmit: (payload: InvitePartnerPayload) => Promise<any>
}

const CompanyInvitationForm: React.FC<CompanyInvitationFormProps> = ({
  onSubmit,
}) => {
  const [inviteEmail, setInviteEmail] = useState<string>()
  const formatError = useErrorFormatter()

  const { getInvitationUserId } = useGetInvitationUserIdAction()

  const onSubmitStep1 = async ({ email }: CompanyInvitationFormStep1Payload) => {
    try {
      const user = await getInvitationUserId(email)
      if (!user) {
        setInviteEmail(email)
        return
      }
      return await onSubmit({ email })
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  const onSubmitStep2 = async (payload: CompanyInvitationFormStep2Payload) => {
    if (!inviteEmail) {
      return
    }
    return await onSubmit({ ...payload, email: inviteEmail })
  }

  return (
    <div className="py-3">
      { inviteEmail === undefined
        ? (
          <CompanyInvitationFormStep1 onSubmit={onSubmitStep1} />
          )
        : (
          <CompanyInvitationFormStep2
            email={inviteEmail}
            onSubmit={onSubmitStep2}
            onCancel={() => { setInviteEmail(undefined) }}
          />
          ) }

    </div>
  )
}

export default CompanyInvitationForm
