import { ReactComponent as MovingWaldoLogo } from '../../assets/logo-small.svg'
import { ReactComponent as GoogleLogo } from '../../assets/providers/google-simple.svg'
import { mergeClassName } from '../../utils/mergeClassName'
import { Source } from './Rating.types'

type RatingSourceLogoProps = {
  source?: Source
  className?: string
}

const RatingSourceLogo: React.FC<RatingSourceLogoProps> = ({
  source,
  className,
}) => {
  const getLogo = () => {
    switch (source) {
      case Source.MovingWaldo:
        return (
          <MovingWaldoLogo className="" />
        )
      case Source.Google:
        return (
          <GoogleLogo className="scale-90" />
        )
    }
  }

  const logo = getLogo()
  if (!logo) {
    return null
  }

  return (
    <div className={mergeClassName(
      'size-[24px] overflow-hidden rounded-full',
      className,
    )}
    >
      { logo }
    </div>
  )
}

export default RatingSourceLogo
