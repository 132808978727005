import { isSameDay } from 'date-fns/isSameDay'

import { useCalendar } from './CalendarContext'

const useDateContent = (givenDate: Date) => {
  const { content = [] } = useCalendar()
  const dateContent = content.find(({ date }) => isSameDay(date, givenDate))
  return dateContent?.content
}

export default useDateContent
