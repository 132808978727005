import { useEffect } from 'react'
import Alert from '@mui/material/Alert'

import { useMonitoring } from '../../modules/monitoring'
import { useErrorFormatter } from './useErrorFormatter'

type ServerErrorProps = {
  error: any
}

const ServerError: React.FC<ServerErrorProps> = ({ error }) => {
  const formatErrorMessage = useErrorFormatter()
  const { error: monitorError } = useMonitoring()

  useEffect(() => {
    monitorError(error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Alert severity="error">
      { formatErrorMessage(error) }
    </Alert>
  )
}

export default ServerError
