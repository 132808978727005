import { SpecialItem } from '../../../companies'

export const specialItemVolume: Record<SpecialItem, number> = {
  [SpecialItem.Appliance]: 25,
  [SpecialItem.LargeAppliance]: 35,
  [SpecialItem.CommercialFreezer]: 75,
  [SpecialItem.SportEquipment]: 25,
  [SpecialItem.UprightPiano]: 35,
  [SpecialItem.BabyGrandPiano]: 50,
  [SpecialItem.GrandPiano]: 100,
  [SpecialItem.PoolTable]: 200,
  [SpecialItem.MarbleTableTop]: 25,
  [SpecialItem.HeavyItem200]: 30,
  [SpecialItem.HeavyItem300]: 50,
  [SpecialItem.HeavyItem400]: 75,
}
