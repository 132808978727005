import { format } from 'date-fns/format'
import JobRequestIcon from '@mui/icons-material/MoveToInboxOutlined'

import Route from '../../../../app/Route.enum'
import { type LightJobRequest } from '../../../../modules/jobRequests'
import { CalendarSidebarListItem } from '../../../../components/calendar'
import Link, { LinkVariant } from '../../../../components/Link'

type JobRequestEventItemProps = {
  jobRequest: LightJobRequest
}

const JobRequestEventItem: React.FC<JobRequestEventItemProps> = ({
  jobRequest,
}) => {
  return (
    <CalendarSidebarListItem>
      <div className="flex grow items-center gap-2 text-sm">
        <div className="size-[16px] rounded" style={{ backgroundColor: '#f2d88d' }} />
        <div className="flex items-center gap-x-2 text-neutral-600">
          <JobRequestIcon className="!text-[18px]" />
          <span className="text-xs text-neutral-400">
            { format(jobRequest.movingDate, 'MM/dd') }
          </span>
          <Link
            to={Route.JobRequest}
            params={{ jobRequestId: jobRequest.id }}
            variant={LinkVariant.Underlined}
          >
            <span>
              { jobRequest.customerName }
            </span>
          </Link>
        </div>
      </div>
    </CalendarSidebarListItem>
  )
}

export default JobRequestEventItem
