export enum RowType {
  Labour,
  Truck,
  TruckFixedFee,
  Overnight,
  Stairs,
  SpecialService,
  SpecialItem,
  ProtectiveMaterial,
  Custom
}
