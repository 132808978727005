import HangerIcon from '@mui/icons-material/Checkroom'
import BedIcon from '@mui/icons-material/Hotel'
import BoxIcon from '@mui/icons-material/Inventory'
import TvIcon from '@mui/icons-material/Tv'
import PaperIcon from '@mui/icons-material/Note'
import LabelIcon from '@mui/icons-material/Label'
import TapeIcon from '@mui/icons-material/ViewInAr'
import BubbleIcon from '@mui/icons-material/BubbleChart'

import { ProtectiveMaterial } from './ProtectiveMaterial.enum'
import { type Icon } from '../../../../common/icon/Icon.type'

export const protectiveMaterialIcons: Record<ProtectiveMaterial, Icon> = {
  [ProtectiveMaterial.WardrobeBoxRent]: HangerIcon,
  [ProtectiveMaterial.WardrobeBoxBuy]: HangerIcon,
  [ProtectiveMaterial.MattressBagQueen]: BedIcon,
  [ProtectiveMaterial.MattressBagsKing]: BedIcon,
  [ProtectiveMaterial.BoxSmall]: BoxIcon,
  [ProtectiveMaterial.BoxMedium]: BoxIcon,
  [ProtectiveMaterial.BoxesLarge]: BoxIcon,
  [ProtectiveMaterial.DishBarrel]: BoxIcon,
  [ProtectiveMaterial.BookBox]: BoxIcon,
  [ProtectiveMaterial.TVBox32in]: TvIcon,
  [ProtectiveMaterial.TVBox40in]: TvIcon,
  [ProtectiveMaterial.TVBox46in]: TvIcon,
  [ProtectiveMaterial.TVBox55in]: TvIcon,
  [ProtectiveMaterial.TVBox60in]: TvIcon,
  [ProtectiveMaterial.TVBox72in]: TvIcon,
  [ProtectiveMaterial.BubbleWrapRoll]: PaperIcon,
  [ProtectiveMaterial.ArtBox]: BoxIcon,
  [ProtectiveMaterial.BoxLabels]: LabelIcon,
  [ProtectiveMaterial.PackingPaper]: PaperIcon,
  [ProtectiveMaterial.ShrinkWrap]: PaperIcon,
  [ProtectiveMaterial.Tape]: TapeIcon,
  [ProtectiveMaterial.PackingPeanuts]: BubbleIcon,
}
