import { type CloseJobForPartnerPayload, type JobForPartner } from '../../../../modules/jobs'
import {
  ChargeCategory,
  ChargeDescription,
} from '../../../../modules/invoices'
import { type CloseJobFormValues } from './CloseJobForm.types'

/**
 * find a charge in the quote and returns a string value
 * returns undefined if this charge is not present in the quote
 */
const getChargeFromQuote = (
  job: JobForPartner,
  category: ChargeCategory,
  description: ChargeDescription,
): string | undefined => {
  const charge = job.quote.details.find((charge) =>
    charge.pricingCategory === category && charge.description === description,
  )?.quantity
  return !charge ? undefined : String(charge)
}

/**
 * returns form data for a given job
 */
export const jobToFormData = (job: JobForPartner): CloseJobFormValues => ({
  movingLabour: getChargeFromQuote(job, ChargeCategory.labour, ChargeDescription.movingLabour),
  transportLabour: getChargeFromQuote(job, ChargeCategory.labour, ChargeDescription.transportLabour),
  packingLabour: getChargeFromQuote(job, ChargeCategory.labour, ChargeDescription.packingLabour),

  travelTime: getChargeFromQuote(job, ChargeCategory.tripAndTravel, ChargeDescription.travelLabour),
  travelDistance: getChargeFromQuote(job, ChargeCategory.tripAndTravel, ChargeDescription.travelTruckFeePerKilometer),
  transportDistance: getChargeFromQuote(job, ChargeCategory.tripAndTravel, ChargeDescription.transportTruckFeePerKilometer),

  nbStairs: getChargeFromQuote(job, ChargeCategory.additionalCharges, ChargeDescription.additionalChargesStairs),
  confirmation: false,
})

const stringToFloat = (value?: string) => {
  if (value === undefined || value === '') {
    return undefined
  }
  return parseFloat(value)
}

/**
 * format form output
 */
export const formatFormOutput = (formData: CloseJobFormValues): CloseJobForPartnerPayload => {
  return {
    movingLabour: stringToFloat(formData.movingLabour),
    transportLabour: stringToFloat(formData.transportLabour),
    packingLabour: stringToFloat(formData.packingLabour),

    travelTime: stringToFloat(formData.travelTime),
    travelDistance: stringToFloat(formData.travelDistance),
    transportDistance: stringToFloat(formData.transportDistance),

    nbStairs: stringToFloat(formData.nbStairs),
  }
}
