import { clsx } from 'clsx'
import ValidIcon from '@mui/icons-material/Check'
import InvalidIcon from '@mui/icons-material/Close'

type ValidationProps = {
  valid: boolean
  children?: React.ReactNode
}

const Validation: React.FC<ValidationProps> = ({
  valid,
  children,
}) => {
  const iconClassName = clsx(
    'mr-2 !text-[14px] text-red-600',
    valid && '!text-green-600',
  )

  return (
    <div className={clsx(
      valid && 'font-bold',
    )}
    >
      { valid ? <ValidIcon className={iconClassName} /> : <InvalidIcon className={iconClassName} /> }
      { children }
    </div>
  )
}

export default Validation
