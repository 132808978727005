/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { useDeleteAccountRequestAction, useDeleteAccountAction, type DeleteAccountPayload } from '../../../../modules/users'
import { SubmitButtonsContainer } from '../../../form'
import Modal from '../../../MuiModal'
import ServerError from '../../../errors/ServerError'
import DeleteAccountForm from '../../../forms/users/DeleteAccountForm'
import Spinner from '../../../Spinner'
import Route from '../../../../app/Route.enum'

type DeleteAccountModalProps = {
  open: boolean
  onClose?: () => void
}

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation()
  const [receivedCode, setReceivedCode] = useState(false)
  const [success, setsuccess] = useState(false)
  const [error, setError] = useState<unknown>()
  const { deleteAccountRequest, loading: sendingCode } = useDeleteAccountRequestAction()
  const { deleteAccount } = useDeleteAccountAction()

  const closeModal = () => {
    if (success) {
      document.location = Route.Dashboard
      return
    }
    setsuccess(false)
    setError(undefined)
    onClose?.()
  }

  const sendCode = async () => {
    try {
      await deleteAccountRequest()
      setReceivedCode(true)
    } catch (error) {
      setError(error)
    }
  }

  const onSubmit = async (payload: DeleteAccountPayload) => {
    try {
      await deleteAccount(payload)
      setsuccess(true)
    } catch (error) {
      setError(error)
    }
  }

  if (!receivedCode) {
    return (
      <Modal
        width={450}
        open={open}
        onClose={closeModal}
      >
        <>
          <Typography variant="h2">
            { t('pages.users.profile.deleteAccount.title') }
          </Typography>

          { error && <ServerError error={error} /> }

          { !error && (
            <Alert severity="error">
              { t('pages.users.profile.deleteAccount.warning') }
            </Alert>
          ) }

          <SubmitButtonsContainer style={{ padding: '2rem 0 0' }}>
            <Button onClick={onClose} color="error">{ t('actions.cancel') }</Button>
            <Button
              color="error"
              variant="contained"
              onClick={sendCode}
              disabled={sendingCode}
            >
              { sendingCode && (
                <Spinner
                  small
                  style={{ color: '#fff', marginRight: '0.5rem' }}
                />
              ) }
              { t('actions.deleteAccount') }
            </Button>
          </SubmitButtonsContainer>

        </>
      </Modal>
    )
  }

  return (
    <Modal
      width={450}
      open={open}
      onClose={closeModal}
    >
      <Typography variant="h2">
        { t('pages.users.profile.deleteAccount.title') }
      </Typography>

      { success
        ? (
          <>
            <Alert severity="success">
              { t('pages.users.profile.deleteAccount.success') }
            </Alert>
            <SubmitButtonsContainer style={{ padding: '2rem 0 0' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={closeModal}
              >
                { t('actions.close') }
              </Button>
            </SubmitButtonsContainer>
          </>
          )
        : (
          <>
            { error && <ServerError error={error} /> }

            { !error && (
              <Alert severity="error">
                { t('pages.users.profile.deleteAccount.warningStep2') }
              </Alert>
            ) }

            <DeleteAccountForm
              onSubmit={onSubmit}
              onClose={closeModal}
            />
          </>
          ) }
    </Modal>
  )
}

export default DeleteAccountModal
