import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import WarningIcon from '@mui/icons-material/WarningAmber'
import MarkerIcon from '@mui/icons-material/LocationOnOutlined'
import MapIcon from '@mui/icons-material/MapOutlined'
import DurationIcon from '@mui/icons-material/UpdateOutlined'
import CalendarIcon from '@mui/icons-material/CalendarMonth'
import DeleteIcon from '@mui/icons-material/Delete'

import { type Quote } from '../../../modules/quotes'
import {
  type PromoCode,
  useQuoteSubtotalWithPromoCode,
} from '../../../modules/adminPromoCodes'
import { useTranslatedValue } from '../../../common/translation'
import { mergeClassName } from '../../../utils/mergeClassName'
import { capitalizeFirstLetter } from '../../../utils/string'
import { useTimeFormatter } from '../../../utils/time'
import { formatNumber } from '../../../utils/number'
import {
  useDateFormatter,
  ignoreTimezone,
} from '../../../utils/date'
import Tooltip from '../../Tooltip'
import Price from '../../Price'
import QuoteDetails from './QuoteDetails'
import AlternativeDates from './AlternativeDates'
import QuoteConstraints from './QuoteConstraints'

const ONE_KILOMETER_METERS = 1000

type QuoteComponentProps = {
  quote: Quote
  promoCode?: PromoCode
  isDragging?: boolean
  selected?: boolean
  onSelect?: () => void
  onDelete?: () => void
  presentationMode?: boolean
  customerSelected?: boolean
  ignoreBest?: boolean
}

const QuoteComponent: React.FC<QuoteComponentProps> = ({
  quote,
  promoCode,
  isDragging = false,
  selected = false,
  onSelect,
  onDelete,
  presentationMode = false,
  customerSelected = false,
  ignoreBest = false,
}) => {
  const selectable = !!onSelect
  const deletable = !!onDelete
  const best = !!quote.best && !presentationMode && !ignoreBest

  const { t } = useTranslation()
  const [open, setOpen] = useState(presentationMode || customerSelected)
  const { formatSeconds } = useTimeFormatter()
  const formatDate = useDateFormatter()
  const getTranslation = useTranslatedValue()

  const quoteSubtotalWithPromoCode = useQuoteSubtotalWithPromoCode(quote, promoCode)

  const totalDistance = parseFloat((quote.totalDistance / ONE_KILOMETER_METERS).toFixed(1))

  const onClick = () => {
    if (presentationMode) {
      return
    }
    if (open) {
      close(); return
    }
    setOpen(true)
  }

  const close = () => {
    setOpen(false)
  }

  return (
    <div className="flex gap-2">
      { selectable && (
        <div className="pt-8">
          <Checkbox
            checked={selected}
            onChange={onSelect}
          />
        </div>
      ) }
      <div
        className={mergeClassName(
          'bg-white relative grow rounded-md border shadow transition-all duration-200 hover:scale-[1.01] hover:shadow-xl',
          !presentationMode && 'cursor-pointer',
          open && 'scale-[1.01] shadow-xl border-2 border-gray-300',
          isDragging && 'scale-[1.01] shadow-2xl',
          (best || customerSelected) && 'border-2 border-gray-600',
        )}
        onClick={onClick}
      >

        { (best || customerSelected) && (
          <div className="absolute -left-1 -top-1 size-16 overflow-hidden">
            <div className="absolute left-[-25px] top-[10px] w-[100px] -rotate-45 bg-gray-600 py-1 text-center text-xs text-white">
              { customerSelected ? t('quotes.quote.selected') : t('quotes.quote.best') }
            </div>
          </div>
        ) }

        <div className="flex justify-between">

          <div className="py-6 pl-8 md:pl-8">
            <div className="flex flex-col gap-6 sm:flex-row">

              { quote.companyBranch.company?.logo?.en && (
                <div className="flex w-[60px] shrink-0 items-center justify-center">
                  <img
                    src={quote.companyBranch.company?.logo?.en}
                    alt=""
                    className="max-h-[50px]"
                  />
                </div>
              ) }

              <div>
                <div className="font-body2 text-lg font-bold uppercase leading-5 sm:text-2xl sm:font-normal">
                  { capitalizeFirstLetter(
                    getTranslation(quote.companyBranch.company?.identification.name),
                  ) }
                </div>
                <div className="mt-2 flex flex-wrap gap-4">
                  <div className="flex items-center gap-1 text-sm text-neutral-400">
                    <MarkerIcon className="!text-[20px]" />
                    { capitalizeFirstLetter(quote.companyBranch.tripAndTravel?.trucksOriginAddress.city) },
                    { ' ' }
                    { quote.companyBranch.tripAndTravel?.trucksOriginAddress.region }
                  </div>
                  <div className="hidden items-center gap-1 text-sm text-neutral-400 sm:flex">
                    <MapIcon className="!text-[20px]" />
                    { (quote.straightLineDistance / 1000).toFixed(1) }
                    { ' ' }
                    { t('units.km') }
                  </div>
                  { quote.forAlternativeDate && (
                    <div className="flex items-center gap-1 text-sm text-yellow-700">
                      <CalendarIcon className="!text-[20px]" />
                      { formatDate(ignoreTimezone(quote.date), { showYear: false, shortMonth: true, shortDay: true }) }
                    </div>
                  ) }
                </div>
              </div>
            </div>

          </div>

          <div className="flex flex-col-reverse items-center justify-center md:flex-row md:gap-4">
            { quote.constraints && (quote.constraints?.length ?? 0) > 0 && !presentationMode && (
              <div className={mergeClassName(
                'w-full pb-2 pr-2 text-right md:w-auto md:p-0 md:text-left',
                open && 'hidden 2xl:block',
              )}
              >
                <Tooltip
                  tooltip={(
                    <ul
                      className={quote.constraints.length > 1 ? 'list-disc pl-2' : ''}
                    >
                      { quote.constraints.map((constraint) => (
                        <li key={constraint}>
                          { t(`quotes.constraintsLegacy.${constraint}`) }<br />
                        </li>
                      )) }
                    </ul>
                  )}
                  className="bg-yellow-700 text-white"
                >
                  <div className="flex items-center gap-1 rounded bg-orange-100 px-3 py-1 text-sm text-yellow-700">
                    { quote.constraints.length } <WarningIcon className="!text-[22px] !text-yellow-700" />
                  </div>
                </Tooltip>
              </div>
            ) }

            <div className="py-6 pl-2 pr-4 md:pl-0 md:pr-10">
              <div className={mergeClassName(
                'flex items-center gap-x-8 font-body2 text-2xl sm:text-3xl md:text-4xl text-neutral-500 w-full md:min-w-[150px] justify-end whitespace-nowrap',
                (best || customerSelected) && 'text-gray-700',
              )}
              >
                { promoCode && quoteSubtotalWithPromoCode && (
                  <div className="text-2xl text-neutral-300 child:line-through">
                    <Price amount={quote.subtotal} />
                  </div>
                ) }

                <Price amount={quoteSubtotalWithPromoCode ?? quote.subtotal} />
              </div>
              <div className="pr-5 text-right text-sm text-slate-400">
                ({ t('quotes.quote.plusTaxes') })
              </div>
            </div>
          </div>
        </div>

        { open && (
          <div className="border-t border-dashed border-gray-300 px-3 pb-6 pt-2 2xl:px-10">

            <div className="pt-3 2xl:hidden">
              { (quote.constraints?.length ?? 0) > 0 && (
                <QuoteConstraints quote={quote} />
              ) }
            </div>

            <div className="flex flex-col-reverse justify-between gap-2 2xl:flex-row 2xl:gap-6">
              <div className="flex flex-col justify-between md:pt-4 ">
                <div>

                  <div className="hidden 2xl:block">
                    { (quote.constraints?.length ?? 0) > 0 && (
                      <QuoteConstraints quote={quote} />
                    ) }
                  </div>

                  <div className="mb-12 flex justify-around gap-4 sm:gap-6 md:gap-8 2xl:justify-normal">
                    <div>
                      <div className="mb-2 pt-4 text-sm font-bold leading-tight text-gray-600 sm:mb-0">
                        <MapIcon />
                        { ' ' }
                        { t('quotes.quote.totalDistance') }
                      </div>
                      <div className="text-gray-500 sm:pl-6">
                        { formatNumber(totalDistance) }
                        { ' ' }
                        { t('units.km') }
                      </div>
                    </div>

                    <div>
                      <div className="mb-2 pt-4 text-sm font-bold leading-tight text-gray-600 sm:mb-0">
                        <DurationIcon />
                        { ' ' }
                        { t('quotes.quote.totalDuration') }
                      </div>
                      <div className="text-gray-500 sm:pl-6">
                        { formatSeconds(quote.totalDuration) }
                      </div>
                    </div>
                  </div>
                </div>

                { quote.alternativeDates?.length && (
                  <AlternativeDates
                    quote={quote}
                    promoCode={promoCode}
                    className="mb-5"
                  />
                ) }

              </div>

              <div className="2xl:w-1/2 2xl:min-w-[500px]">
                <QuoteDetails
                  quote={quote}
                  promoCode={promoCode}
                  forceOpen={presentationMode}
                />
              </div>
            </div>

          </div>
        ) }
      </div>

      { deletable && (
        <div className="hidden pt-8 md:block">
          <IconButton
            onClick={onDelete}
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ) }

    </div>
  )
}

export default QuoteComponent
