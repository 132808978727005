import { useTranslation } from 'react-i18next'
import { differenceInDays } from 'date-fns/differenceInDays'

import { type ReportInterval } from '../../../modules/adminAccounting'
import { useDateFormatter } from '../../../utils/date'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

type ReportIntervalDatesProps = {
  interval: ReportInterval
} & ReactDiv

const ReportIntervalDates: React.FC<ReportIntervalDatesProps> = ({
  interval,
  ...props
}) => {
  const { t } = useTranslation()
  const formatDate = useDateFormatter()
  const nbDays = differenceInDays(interval.end, interval.start) + 1

  return (
    <div {...props}>
      <span className="whitespace-nowrap">
        { formatDate(interval.start, { shortMonth: true }) }
      </span>
      { ' - ' }
      <span className="whitespace-nowrap">
        { formatDate(interval.end, { shortMonth: true }) }
      </span>
      { ' ' }
      <span className="whitespace-nowrap text-neutral-400">
        (
        { t('date.nbDays', { count: nbDays }) }
        )
      </span>
    </div>
  )
}

export default ReportIntervalDates
