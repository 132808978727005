import { type FullMove } from '../../../modules/adminMoves'
import { useMoveState } from '../../../modules/moves'
import { ActionName } from './useActions'

/**
 * return the next logical actions based on move state
 */
export const useNextAction = (move: FullMove) => {
  const {
    nbQuotes,
    isAssigned,
    isActive,
    isAcceptedByCustomer,
    canApproveMove,
  } = useMoveState().forMove(move)

  if (!isAssigned) {
    return ActionName.AssignAgentToMove
  }
  if (nbQuotes === 0) {
    return ActionName.RefreshQuotes
  }
  if (canApproveMove) {
    return ActionName.ApproveMove
  }
  if (isActive && !isAcceptedByCustomer) {
    return ActionName.SendQuoteEmail
  }

  return ActionName.SendToSmartMoving
}
