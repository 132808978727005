import { Room } from '../quotes.enum'

export const roomVolume: Record<Room, number> = {
  [Room.Bedroom]: 100,
  [Room.LivingRoom]: 100,
  [Room.Kitchen]: 100,
  [Room.Bathroom]: 25,
  [Room.HomeOffice]: 75,
  [Room.DiningRoom]: 100,
  [Room.Hall]: 25,
  [Room.LaundryRoom]: 25,
  [Room.HomeGym]: 200,
  [Room.Attic]: 100,
  [Room.Garage]: 100,
  [Room.Shed]: 150,
  [Room.Outdoor]: 150,
  [Room.StorageRoom]: 100,
  [Room.Workshop]: 75,
}
