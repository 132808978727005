import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/AddCircleOutline'
import UserIcon from '@mui/icons-material/Person'

import Route from '../../../app/Route.enum'
import ContainedPage from '../../../components/layout/ContainedPage'
import PageContent from '../../../components/layout/PageContent'
import PageTitle from '../../../components/layout/PageTitle'
import { Permission, protectAdminPage, usePermission } from '../../../modules/users'
import { useNavigate } from '../../../modules/navigation'
import { useProspects } from '../../../modules/adminProspects/core/prospects.hooks'
import {
  Cell,
  Column,
  DataGrid,
  DateValue,
  type GridQuery,
  Row,
  SmallValue,
} from '../../../components/dataGrid'
import { SortDirection } from '../../../modules/graphQl'
import { capitalizeFirstLetter } from '../../../utils/string'
import Link, { LinkVariant } from '../../../components/Link'
import ServerError from '../../../components/errors/ServerError'
import { useActions } from './useActions'
import CustomFilters, { ONLY_MINE_FILTER } from './CustomFilters'
import ConfirmationModal from '../../../components/ConfirmationModal'
import { type AdminUser } from '../../../modules/adminUsers'
import { type Address } from '../../../common/address/address.model'
import InfoTooltip from '../../../components/InfoTooltip'

const ProspectsPage: React.FC = () => {
  const { t } = useTranslation()
  const { navigate } = useNavigate()
  const { fetchProspects, data: prospects, error: prospectsErrors } = useProspects({ reload: true })
  const { actions, modalProps } = useActions(prospects?.data)

  const onQueryUpdate = useCallback(async ({ custom, ...query }: GridQuery) => {
    const onlyMine = custom?.[ONLY_MINE_FILTER]
    return await fetchProspects({ query, onlyMine })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleRowClick = (id: string, newTab = false) => {
    navigate(Route.Prospect, { prospectId: id }, newTab)
  }

  const canReadUsers = usePermission(Permission.canReadUsers)

  const renderUser = (user: AdminUser) => {
    const identification = (
      <>
        <UserIcon className="mr-2 !text-[18px] text-slate-500" />

        { capitalizeFirstLetter(user.firstName) }
        { ' ' }
        { capitalizeFirstLetter(user.lastName) }
      </>
    )
    return (
      canReadUsers
        ? (
          <Link
            to={Route.AdminUser}
            params={{ userId: user.id }}
            variant={LinkVariant.Primary}
            onClick={event => { event.stopPropagation() }}
          >
            { identification }
          </Link>
          )
        : identification
    )
  }

  if (prospectsErrors) {
    return <ContainedPage><ServerError error={prospectsErrors} /></ContainedPage>
  }

  return (
    <ContainedPage>
      <PageTitle fullWidth>
        <div className="flex flex-col justify-between gap-4 lg:flex-row">

          { t('pages.admin.prospects.title') }

          <div className="flex items-center gap-x-2 pr-2">
            <InfoTooltip message="Create a customer instead" />

            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
              disabled
            >
              { t('actions.create') }
            </Button>
          </div>
        </div>
      </PageTitle>

      <PageContent fullWidth className="px-3 py-8 pr-4">
        <DataGrid
          name="prospects"
          count={prospects?.count ?? 0}
          onQueryUpdate={onQueryUpdate}
          initialQuery={{ sortField: 'createdAt', sortDirection: SortDirection.DESC }}
          actions={actions}
          customFilters={CustomFilters}
          saveCustomFilterValues={[ONLY_MINE_FILTER]}
          columns={(
            <>
              <Column name="createdAt" sortable>
                { t('pages.admin.prospects.table.columns.createdAt') }
              </Column>
              <Column name="updatedAt" sortable>
                { t('pages.admin.prospects.table.columns.updatedAt') }
              </Column>
              <Column name="name" sortable>
                { t('pages.admin.prospects.table.columns.name') }
              </Column>
              <Column name="request.origin.address.city" sortable>
                { t('pages.admin.prospects.table.columns.originCity') }
              </Column>
              <Column name="request.destination.address.city" sortable>
                { t('pages.admin.prospects.table.columns.destinationCity') }
              </Column>
              <Column name="agent.firstName" sortable>
                { t('pages.admin.prospects.table.columns.createdBy') }
              </Column>
            </>
          )}
        >
          { prospects?.data?.map(prospect => (
            <Row
              key={prospect.id}
              id={prospect.id}
              onClick={handleRowClick}
            >
              <Cell><SmallValue><DateValue date={prospect.createdAt} /></SmallValue></Cell>

              <Cell><SmallValue><DateValue date={prospect.updatedAt} /></SmallValue></Cell>

              <Cell expand>
                { capitalizeFirstLetter(prospect.name) }
              </Cell>

              <Cell>
                { capitalizeFirstLetter((prospect.request.origin.address as Address).city) }
              </Cell>

              <Cell>
                { capitalizeFirstLetter((prospect.request.destination.address as Address).city) }
              </Cell>

              <Cell>{ renderUser(prospect.agent) }</Cell>
            </Row>
          )) }
        </DataGrid>
      </PageContent>

      { modalProps && <ConfirmationModal {...modalProps} /> }
    </ContainedPage>
  )
}

export default protectAdminPage(ProspectsPage)
