import { useTranslation } from 'react-i18next'

import { useConfig } from '../../modules/config'
import { useNavigate } from '../../modules/navigation'
import FloatingPage from '../../components/layout/FloatingPage'
import RegisterForm from '../../components/forms/users/RegisterForm'
import ServerError from '../../components/errors/ServerError'
import { type RegisterPayload, useRegisterAction, visitorPage } from '../../modules/users'
import Title from '../../components/users/authentication/Title'
import Route from '../../app/Route.enum'

const RegisterPage: React.FC = () => {
  const defaultPage = useConfig('users.authentication.defaultPage', { defaultValue: Route.Dashboard })
  const { t } = useTranslation()
  const { register, error } = useRegisterAction()
  const { navigate } = useNavigate()

  const onSubmit = async (data: RegisterPayload) => {
    try {
      await register(data)
      navigate(defaultPage)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <FloatingPage>
      <Title>{ t('pages.users.register.title') }</Title>
      { error && <ServerError error={error} /> }
      <RegisterForm onSubmit={onSubmit} />
    </FloatingPage>
  )
}

export default visitorPage(RegisterPage)
