import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import { type AdminUser } from '../../../../modules/adminUsers'
import { Row, Cell, Label, Value } from '../../../../components/cell'
import Paper from '../../../../components/Paper'
import Privileges from './Privileges'
import Authentication from './Authentication'
import { mergeClassName } from '../../../../utils/mergeClassName'

type ReadOnlyUserProps = {
  user: AdminUser
}

const ReadOnlyUser: React.FC<ReadOnlyUserProps> = ({ user }) => {
  const { t } = useTranslation()

  return (
    <>
      <Paper>
        <Typography variant="h2">
          { t('pages.admin.user.personalInfo') }
        </Typography>
        <Row>
          <Cell>

            <Label>
              { t('forms.fields.firstName.label') }
            </Label>
            <Value>{ user.firstName }</Value>
          </Cell>

          <Cell>
            <Label>
              { t('forms.fields.lastName.label') }
            </Label>
            <Value>{ user.lastName }</Value>
          </Cell>
        </Row>

        <Row>
          <Cell>
            <Label>
              { t('forms.fields.email.label') }
            </Label>
            <Value>{ user.email }</Value>
            <Value
              className={mergeClassName(user.emailVerified ? 'text-green-800' : 'text-red-800')}
            >
              { user.emailVerified
                ? <><CheckIcon /> { t('status.verified') }</>
                : <><CloseIcon /> { t('status.unverified') }</> }
            </Value>
          </Cell>

          <Cell>
            <Label>
              { t('forms.fields.photoUrl.label') }
            </Label>
            <Value>{ user.photo ?? '—' }</Value>
          </Cell>
        </Row>
      </Paper>

      <Paper>
        <Typography variant="h2" mb={1}>
          { t('pages.admin.user.authentication') }
        </Typography>
        <Authentication user={user} />
      </Paper>

      <Paper>
        <Typography variant="h2" mb={1}>
          { t('pages.admin.user.privileges') }
        </Typography>
        <Privileges user={user} />
      </Paper>
    </>
  )
}

export default ReadOnlyUser
