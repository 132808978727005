import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useDateFormatter } from '../../../../utils/date'

const useSelectedDateFormatted = (selectedDates?: { start: Date, end?: Date }) => {
  const { t } = useTranslation()
  const formatDate = useDateFormatter()

  return useMemo(() => {
    if (!selectedDates) {
      return
    }
    if (selectedDates.start && selectedDates.end) {
      const formattedStart = formatDate(selectedDates.start, { showYear: false })
      const formattedEnd = formatDate(selectedDates.end, { showYear: false })
      return (
        <>{ t('date.from') } { formattedStart } { t('date.to') } { formattedEnd }</>
      )
    }
    return formatDate(selectedDates.start)
  }, [selectedDates, formatDate, t])
}

export default useSelectedDateFormatted
