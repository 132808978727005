import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'

import { type CustomConfirmationFormProps } from '../../../ConfirmationModal/ConfirmationModal.types'
import { Form, Label, SubmitButton, Textarea } from '../../../form'
import { ModalDescription, ModalFooter } from '../../../Modal'
import { type FullMove } from '../../../../modules/adminMoves'

type ApproveMoveConfirmationFormProps = CustomConfirmationFormProps & {
  move: FullMove
}

const ApproveMoveConfirmationForm: React.FC<ApproveMoveConfirmationFormProps> = ({
  move,
  onConfirm,
  onDecline,
}) => {
  const { t } = useTranslation()
  const form = useForm({
    defaultValues: {
      message: move?.latestJobRequest?.message ?? '',
    },
  })

  const onSubmit = async (values: { message?: string }) => {
    onConfirm(values)
  }

  return (
    <Form form={form} onSubmit={onSubmit}>
      <ModalDescription>
        <div className="mb-4 text-base">
          <div className="mb-4">
            { t('pages.admin.move.actions.approveMove.confirmation.question') }
          </div>

          <Label label={t('forms.fields.agentMessageToThePartner.label')}>
            <Textarea
              name="message"
              maxRows={20}
            />
          </Label>
        </div>
      </ModalDescription>
      <ModalFooter>
        <div>
          <Button
            variant="outlined"
            color="secondary"
            onClick={onDecline}
            size="medium"
          >
            { t('actions.cancel') }
          </Button>
        </div>
        <div>
          <SubmitButton
            variant="contained"
            size="medium"
          >
            { t('actions.confirm') }
          </SubmitButton>
        </div>
      </ModalFooter>
    </Form>
  )
}

export default ApproveMoveConfirmationForm
