import {
  type UpdateImportedCalendarsPayload,
} from '../../../../modules/companies'
import { type ImportCalendarsFormValues } from './ImportCalendarsForm.types'

/**
 * prepare data for form
 */
export const importedCalendarsToFormData = (importedCalendars: string[] = []):
ImportCalendarsFormValues => {
  return {
    importedCalendars: !importedCalendars?.length
      ? [{ value: '' }]
      : importedCalendars.map((importedCalendar) => ({
        value: importedCalendar,
      })),
  }
}

/**
 * format form output
 */
export const formatFormOutput = (formData: ImportCalendarsFormValues): UpdateImportedCalendarsPayload => {
  return {
    importedCalendars: formData.importedCalendars.map(({ value }) => value),
  }
}
