import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { type Interval } from 'date-fns'
import { format } from 'date-fns/format'
import { parseISO } from 'date-fns/parseISO'
import { startOfMonth } from 'date-fns/startOfMonth'
import { endOfMonth } from 'date-fns/endOfMonth'
import Switch from '@mui/material/Switch'
import MoneyIcon from '@mui/icons-material/MonetizationOnRounded'
import CalendarBusyIcon from '@mui/icons-material/EventBusy'

import ContainedPage from '../../../components/layout/ContainedPage/ContainedPage'
import { useCurrentCompanyBranch } from '../../../modules/companies'
import Spinner from '../../../components/Spinner/Spinner'
import { Error404Page } from '../../errors'
import { useNavigate, useQueryString } from '../../../modules/navigation'
import Route from '../../../app/Route.enum'
import Calendar from './Calendar'
import PageTitle from '../../../components/layout/PageTitle/PageTitle'
import PageContent from '../../../components/layout/PageContent/PageContent'
import RuleModal, { RuleType } from './RuleModal'
import RuleList from './RuleList'
import Toolbar, { ToggleOption, ToolBarActionButton } from './Toolbar'
import { protectPage } from '../../../modules/users'
import { useShowAvailabilities } from './useShowAvailabilities'

const getDefaultDate = (date?: string) => {
  if (!date) {
    return
  }
  try {
    return parseISO(date)
  } catch (error) {}
}

const CompanyBranchServicesPage: React.FC = () => {
  const { t } = useTranslation()
  const { replace } = useNavigate()

  const defaultDate = getDefaultDate(useQueryString('date')) ?? new Date()
  const showRulesByDefault = useQueryString('showRules') === '1'

  const [showPricingRules, setShowPricingRules] = useState(showRulesByDefault)
  const [showAvailabilities, setShowAvailabilities] = useShowAvailabilities()
  const [currentPeriod, setCurrentPeriod] = useState<Interval>({ start: startOfMonth(defaultDate), end: endOfMonth(defaultDate) })
  const [ruleModalOpen, setRuleModalOpen] = useState(false)
  const [selectedDates, setSelectedDates] = useState<{ start: Date, end?: Date }>()
  const [selectedCreationType, setSelectedCreationType] = useState<RuleType | null>(null)
  const [selectedRuleId, setSelectedRuleId] = useState<string | null>()
  const {
    data: currentCompanyBranch,
    error: currentCompanyBranchError,
    loading: currentCompanyBranchLoading,
  } = useCurrentCompanyBranch()

  const companyReady = (currentCompanyBranch?.setupProgress ?? 0) === 100

  const onToggleOptionChange = (selectedOption: ToggleOption) => {
    const currentMonth = format(currentPeriod.start, 'yyyy-MM-dd')

    switch (selectedOption) {
      case ToggleOption.Prices:
      case ToggleOption.PricingRules:
        setShowPricingRules(selectedOption === ToggleOption.PricingRules)
        break
      case ToggleOption.Jobs:
        replace(`${Route.Jobs}?date=${currentMonth}`)
    }
  }

  const onSelectDates = (start: Date, end?: Date) => {
    setSelectedRuleId(null)
    setSelectedCreationType(null)
    setSelectedDates({ start, end })
    setTimeout(() => {
      // fixme: find a cleaner solution
      setRuleModalOpen(true)
    }, 50)
  }

  const onCreateNewRule = (type: RuleType) => {
    setSelectedRuleId(null)
    setSelectedCreationType(type)
    setSelectedDates(undefined)
    setTimeout(() => {
      // fixme: find a cleaner solution
      setRuleModalOpen(true)
    }, 50)
  }

  const onSelectRule = (id: string) => {
    setSelectedRuleId(id)
    setRuleModalOpen(true)
  }

  useEffect(() => {
    if (!!currentCompanyBranchError || currentCompanyBranchLoading) {
      return
    }
    if (!companyReady) {
      replace(Route.BranchSettings)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyReady, currentCompanyBranchError, currentCompanyBranchLoading])

  if (currentCompanyBranchLoading) {
    return <ContainedPage><Spinner floating /></ContainedPage>
  }

  if (currentCompanyBranchError) {
    return <Error404Page />
  }

  if (!companyReady || !currentCompanyBranch) {
    return null
  }

  return (
    <ContainedPage>
      <RuleModal
        open={ruleModalOpen}
        onClose={() => { setRuleModalOpen(false) }}
        selectedRuleId={selectedRuleId}
        selectedDates={selectedDates}
        creationType={selectedCreationType}
        onEditRule={onSelectRule}
      />

      <PageTitle
        fullWidth
        className="mb-0 lg:mb-0"
      >
        { t('pages.companyBranch.availability.title') }
      </PageTitle>

      <Toolbar
        selectedOption={showPricingRules ? ToggleOption.PricingRules : ToggleOption.Prices}
        onChange={onToggleOptionChange}
        extraOptions={(
          <div className="hidden xl:flex">
            <label className="mb-0 cursor-pointer lg:-mb-1">
              <Switch
                checked={showAvailabilities}
                onChange={(_, checked) => { setShowAvailabilities(checked) }}
              />
              { t('pages.companyBranch.availability.header.showAvailabilities') }
            </label>
          </div>
        )}
      >
        <>
          <ToolBarActionButton
            icon={<MoneyIcon />}
            onClick={() => { onCreateNewRule(RuleType.Pricing) }}
          >
            { t('pages.companyBranch.availability.header.setPricing') }
          </ToolBarActionButton>

          <ToolBarActionButton
            icon={<CalendarBusyIcon />}
            onClick={() => { onCreateNewRule(RuleType.Availability) }}
          >
            { t('pages.companyBranch.availability.header.setAvailability') }
          </ToolBarActionButton>
        </>
      </Toolbar>

      <PageContent fullWidth className="py-8">
        <div className="flex flex-col gap-6 xl:flex-row">
          <div className="flex max-w-none flex-1 lg:max-w-[calc(100vw-245px)] xl:max-w-[calc(100vw-670px)] 2xl:max-w-[calc(100vw-770px)]">
            <Calendar
              defaultDate={defaultDate}
              companyBranch={currentCompanyBranch}
              availabilityRules={currentCompanyBranch?.availabilityRules ?? []}
              pricingRules={currentCompanyBranch?.pricingRules ?? []}
              showAvailabilities={showAvailabilities}
              showRules={showPricingRules}
              currentPeriod={currentPeriod}
              onSelectDates={onSelectDates}
              onSelectRule={onSelectRule}
              onPeriodChange={setCurrentPeriod}
            />
          </div>

          <RuleList
            period={currentPeriod}
            availabilityRules={currentCompanyBranch?.availabilityRules ?? []}
            pricingRules={currentCompanyBranch?.pricingRules ?? []}
            onEditRule={onSelectRule}
          />
        </div>

      </PageContent>
    </ContainedPage>
  )
}

export default protectPage(CompanyBranchServicesPage)
