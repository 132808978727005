import { useConfig } from '../../config'
import FloatingPage from '../../../components/layout/FloatingPage'
import { useCurrentUser } from '../../users'
import VerifyYourEmail from '../../../components/users/VerifyYourEmail'
import Spinner from '../../../components/Spinner'

type EmailVerificationGuardProps = {
  children: React.ReactElement
}

const EmailVerificationGuard: React.FC<EmailVerificationGuardProps> = ({ children }) => {
  const emailVerificationRequired = useConfig('users.authentication.emailVerificationRequired', { defaultValue: false })
  const { data: user } = useCurrentUser()
  const emailVerified = user?.emailVerified ?? false

  if (!emailVerificationRequired) {
    return children
  }

  if (!user) {
    return <Spinner floating />
  }

  if (!emailVerified) {
    return <FloatingPage><VerifyYourEmail /></FloatingPage>
  }

  return children
}

export default EmailVerificationGuard
