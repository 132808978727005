import { isWithinInterval } from 'date-fns/isWithinInterval'
import { set } from 'date-fns/set'
import { subMilliseconds } from 'date-fns/subMilliseconds'

import { useCalendar } from './CalendarContext'
import { CalendarAvailabilityRange } from './Calendar.type'

const getUnavailableState = (ranges: CalendarAvailabilityRange[]) => {
  if (ranges.includes(CalendarAvailabilityRange.Day)) {
    return CalendarAvailabilityRange.Day
  }
  if (ranges.includes(CalendarAvailabilityRange.Am) && ranges.includes(CalendarAvailabilityRange.Pm)) {
    return CalendarAvailabilityRange.Day
  }
  if (ranges.includes(CalendarAvailabilityRange.Am)) {
    return CalendarAvailabilityRange.Am
  }
  return CalendarAvailabilityRange.Pm
}

const useDateAvailability = (date: Date) => {
  const { availabilities = [] } = useCalendar()

  const ranges = availabilities.filter(({ start, end }) => {
    try {
      return isWithinInterval(
        date,
        {
          start,
          end: end ? subMilliseconds(set(end, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }), 1) : start,
        },
      )
    } catch (error) {
      return false
    }
  }).map(({ range }) => range)

  return {
    unavailable: ranges.length > 0 ? getUnavailableState(ranges) : undefined,
  }
}

export default useDateAvailability
