import { useTranslation } from 'react-i18next'
import Chip from '@mui/material/Chip'

import { mergeClassName } from '../../utils/mergeClassName'
import { JobStatus } from '../../modules/jobs'

type JobStatusTagProps = {
  jobStatus: JobStatus
  className?: string
}

const JobStatusTag: React.FC<JobStatusTagProps> = ({
  jobStatus,
  className,
}) => {
  const { t } = useTranslation()
  const statusTitle = t(`job.status.${jobStatus}`)

  return (
    <Chip
      className={mergeClassName(
        jobStatus === JobStatus.Pending && '!bg-lime-400',
        jobStatus === JobStatus.Cancelled && '!bg-orange-600 !text-orange-100',
        jobStatus === JobStatus.Completed && '!bg-green-200 !text-green-800',
        jobStatus === JobStatus.Closed && '!bg-green-800 !text-white',
        className,
      )}
      label={statusTitle}
      size="small"
    />
  )
}

export default JobStatusTag
