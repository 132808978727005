/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { type Interval } from 'date-fns'
import { isWithinInterval } from 'date-fns/isWithinInterval'
import { parseISO } from 'date-fns/parseISO'
import { format } from 'date-fns/format'
import { startOfMonth } from 'date-fns/startOfMonth'
import { endOfMonth } from 'date-fns/endOfMonth'
import Switch from '@mui/material/Switch'
import CalendarIcon from '@mui/icons-material/CalendarMonth'
import ExportCalendarIcon from '@mui/icons-material/CloudUploadOutlined'

import {
  useNavigate,
  useQueryString,
} from '../../../modules/navigation'
import {
  getLightJobRange,
  JobStatus,
  useJobs,
} from '../../../modules/jobs'
import { useExternalEvents } from '../../../modules/companies'
import { usePendingRequestsNotifications } from '../../../modules/jobRequests'
import Route from '../../../app/Route.enum'
import PageTitle from '../../../components/layout/PageTitle'
import PageContent from '../../../components/layout/PageContent'
import { type Event } from '../../../components/calendar/EventCalendar/EventCalendar.types'
import Toolbar, { ToggleOption, ToolBarActionButton } from '../availability/Toolbar'
import Calendar from './Calendar'
import JobList from './JobList'
import JobTitle from './JobTitle'
import JobRequestTitle from './JobRequestTitle'
import JobEntry from './JobEntry'
import JobRequestEntry from './JobRequestEntry'
import ExternalEventTitle from './ExternalEventTitle'
import { useShowExternalEvents } from './useShowExternalEvents'
import ExportCalendarModal from './ExportCalendarModal'
import ImportCalendarsModal from './ImportCalendarsModal'

const getDefaultDate = (date?: string) => {
  if (!date) {
    return
  }
  try {
    return parseISO(date)
  } catch (error) {}
}

const JobsPageContent: React.FC = () => {
  const { t } = useTranslation()
  const { replace } = useNavigate()
  const { data: externalEvents } = useExternalEvents()
  const pendingJobRequests = usePendingRequestsNotifications()
  const { fetchJobs, data: jobs } = useJobs()
  const defaultDate = getDefaultDate(useQueryString('date')) ?? new Date()
  const [currentPeriod, setCurrentPeriod] = useState<Interval>({
    start: startOfMonth(defaultDate),
    end: endOfMonth(defaultDate),
  })
  const [showExternalEvents, setShowExternalEvents] = useShowExternalEvents()
  const [exportModalOpen, setExportModalOpen] = useState(false)
  const [importModalOpen, setImportModalOpen] = useState(false)

  const fetchEvents = async (from: Date, to: Date) => {
    await fetchJobs(
      {
        dateRangeFilter: {
          from,
          to,
        },
        status: [
          JobStatus.Pending,
          JobStatus.Completed,
          JobStatus.Closed,
        ],
      },
      {
        reload: true,
      },
    )
  }

  const filteredJobs = jobs?.filter(job => isWithinInterval(job.date, currentPeriod))
  filteredJobs?.sort((a, b) => {
    const dateA = new Date(a.date)
    const dateB = new Date(b.date)
    if (dateA < dateB) { return -1 }
    if (dateA > dateB) { return 1 }
    return 0
  })

  const filteredJobRequests = pendingJobRequests?.data?.filter(jobRequest => isWithinInterval(jobRequest.movingDate, currentPeriod))
  filteredJobRequests?.sort((a, b) => {
    const dateA = new Date(a.movingDate)
    const dateB = new Date(b.movingDate)
    if (dateA < dateB) { return -1 }
    if (dateA > dateB) { return 1 }
    return 0
  })

  const filteredExternalEvents = externalEvents?.filter(externalEvent => isWithinInterval(externalEvent.start, currentPeriod))
  filteredExternalEvents?.sort((a, b) => {
    const dateA = new Date(a.start)
    const dateB = new Date(b.start)
    if (dateA < dateB) { return -1 }
    if (dateA > dateB) { return 1 }
    return 0
  })

  const events = useMemo(() => {
    const calendarEvents: Event[] = []
    jobs?.forEach(job => {
      calendarEvents.push({
        id: job.id,
        title: <JobTitle>{ job.customerName }</JobTitle>,
        content: <JobEntry job={job} />,
        ...getLightJobRange(job),
        backgroundColor: '#0e4c71',
        textColor: '#fff',
      })
    })

    pendingJobRequests?.data?.forEach(jobRequest => {
      calendarEvents.push({
        id: jobRequest.id,
        title: <JobRequestTitle>{ jobRequest.customerName }</JobRequestTitle>,
        content: <JobRequestEntry jobRequest={jobRequest} />,
        ...getLightJobRange({
          date: jobRequest.movingDate,
          time: jobRequest.movingTime!,
          moveDurationSeconds: jobRequest.moveDurationSeconds,
        }),
        backgroundColor: '#f2d88d',
        textColor: '#614802',
        pending: true,
      })
    })

    if (showExternalEvents) {
      externalEvents?.forEach(externalEvent => {
        calendarEvents.push({
          title: (
            <ExternalEventTitle source={externalEvent.source}>
              { externalEvent.title }
            </ExternalEventTitle>
          ),
          start: new Date(externalEvent.start),
          end: new Date(externalEvent.end),
          backgroundColor: '#93a7d0',
          textColor: '#fff',
        })
      })
    }

    return calendarEvents
  }, [jobs, pendingJobRequests, externalEvents, showExternalEvents])

  const onToggleOptionChange = (selectedOption: ToggleOption) => {
    const currentMonth = format(currentPeriod.start, 'yyyy-MM-dd')
    switch (selectedOption) {
      case ToggleOption.Prices:
        replace(`${Route.BranchAvailability}?date=${currentMonth}`)
        break
      case ToggleOption.PricingRules:
        replace(`${Route.BranchAvailability}?date=${currentMonth}&showRules=1`)
        break
    }
  }

  return (
    <>
      <ExportCalendarModal
        open={exportModalOpen}
        onClose={() => { setExportModalOpen(false) }}
      />
      <ImportCalendarsModal
        open={importModalOpen}
        onClose={() => { setImportModalOpen(false) }}
      />

      <PageTitle
        fullWidth
        className="mb-0 lg:mb-0"
      >
        { t('pages.companyBranch.jobs.title') }
      </PageTitle>

      <Toolbar
        selectedOption={ToggleOption.Jobs}
        onChange={onToggleOptionChange}
        extraOptions={(externalEvents?.length ?? 0) > 1 && (
          <div className="hidden xl:flex">
            <label className="mb-0 cursor-pointer lg:-mb-1">
              <Switch
                checked={showExternalEvents}
                onChange={(_, checked) => { setShowExternalEvents(checked) }}
              />
              { t('pages.companyBranch.jobs.header.showExternalEvents') }
            </label>
          </div>
        )}
      >
        <ToolBarActionButton
          icon={<CalendarIcon />}
          onClick={() => { setImportModalOpen(true) }}
        >
          { t('pages.companyBranch.jobs.header.myCalendars') }
        </ToolBarActionButton>

        <ToolBarActionButton
          icon={<ExportCalendarIcon />}
          onClick={() => { setExportModalOpen(true) }}
        >
          { t('pages.companyBranch.jobs.header.exportCalendar') }
        </ToolBarActionButton>
      </Toolbar>

      <PageContent fullWidth className="pt-8">
        <div className="flex flex-col gap-6 xl:flex-row">
          <div className="flex max-w-none flex-1 lg:max-w-[calc(100vw-245px)] xl:max-w-[calc(100vw-670px)] 2xl:max-w-[calc(100vw-770px)]">
            <Calendar
              events={events}
              defaultDate={defaultDate}
              fetchEvents={fetchEvents}
              onPeriodChange={setCurrentPeriod}
            />
          </div>

          <JobList
            jobs={filteredJobs}
            jobRequests={filteredJobRequests}
            externalEvents={filteredExternalEvents}
          />
        </div>

      </PageContent>
    </>
  )
}

export default JobsPageContent
