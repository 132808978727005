import { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDropzone, type FileRejection } from 'react-dropzone'
import Button from '@mui/material/Button'
import UploadIcon from '@mui/icons-material/UploadFileOutlined'

import { mergeClassName } from '../../utils/mergeClassName'
import { type FileType, allFileTypes } from './fileType.enum'
import { errorNotification } from '../ToastNotifications'
import Spinner from '../Spinner'

type FilesDropzoneProps = {
  onUpload: (files: File[]) => void | Promise<void>
  onUploading?: (uploading: boolean) => void
  children?: React.ReactNode
  maxFiles?: number
  fileTypes?: FileType[]
  compact?: boolean
}

const FilesDropzone: React.FC<FilesDropzoneProps> = ({
  onUpload,
  onUploading,
  maxFiles = 1,
  compact = false,
  children,
}) => {
  const { t } = useTranslation()
  const [uploading, setUploading] = useState(false)
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (uploading || acceptedFiles.length === 0) {
      return
    }
    if (acceptedFiles.length > maxFiles) {
      errorNotification(t(
        'components.filesDropzone.errors.maxFiles',
        { count: maxFiles },
      ))
      return
    }

    setUploading(true)
    onUploading?.(true)

    Promise.resolve(onUpload(acceptedFiles))
      ?.catch(console.error)
      ?.finally(() => {
        setUploading(false)
        onUploading?.(false)
      },
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onUpload, uploading, onUploading, maxFiles])

  const onDropRejected = (rejections: FileRejection[]) => {
    const extensions = rejections.map(({ file }) => file.name.split('.').reverse()[0])
    errorNotification(t(
      'components.filesDropzone.errors.invalidExtensions',
      { extensions: extensions.join(', ') },
    ))
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: Object.fromEntries(allFileTypes.map(type => ([type, []]))),
  })

  return (
    <div className="relative">
      { uploading && (
        <div className="absolute inset-0 z-[5] bg-white/50">
          <Spinner expanded />
        </div>
      ) }
      <div {...getRootProps()}>
        <div
          className={mergeClassName(
            'flex flex-col md:flex-row border border-dashed border-gray-500 bg-gray-100 rounded-md p-4 md:p-8 justify-center items-center gap-4 min-h-[100px] text-neutral-600',
            isDragActive && 'bg-zinc-200',
            compact && 'md:flex-col md:p-4',
          )}
        >
          <input {...getInputProps()} />

          <div className={mergeClassName(
            'flex flex-col items-center gap-2 md:flex-row md:items-start',
            compact && 'md:flex-col md:items-center',
          )}
          >
            <UploadIcon />
            <div className="leading-tight">

              { isDragActive
                ? t(
                  'components.filesDropzone.dropHereOr',
                  { count: maxFiles },
                )
                : t(
                  'components.filesDropzone.dragAndDropHere',
                  { count: maxFiles },
                ) }
            </div>
          </div>
          { !isDragActive && (
            <Button variant="contained" size="small" color="secondary">
              <div className="leading-tight">
                { t(
                  'components.filesDropzone.selectFile',
                  {
                    count: maxFiles,
                  },
                ) }
              </div>
            </Button>
          ) }
        </div>
        <div
          onClick={event => { event.stopPropagation() }}
          className="relative"
        >
          { isDragActive && (
            <div className="absolute inset-0 rounded-md bg-zinc-200/40" />
          ) }
          { children }
        </div>
      </div>
    </div>
  )
}

export default FilesDropzone
