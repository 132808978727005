import * as Yup from 'yup'

import { type CompanyInvitationFormStep1Payload } from './CompanyInvitationFormStep1.type'

const useValidationSchema = () => {
  return Yup.object<CompanyInvitationFormStep1Payload>({
    email: Yup.string().required().email(),
  })
}

export default useValidationSchema
