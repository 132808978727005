import {
  ResponsiveContainer,
  BarChart as ReChartBarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  CartesianGrid,
} from 'recharts'

type BarDefinition = {
  key: string
  label: string
  color: string
  order?: number
}

export type BarChartData = {
  label: string
  value: number
  count?: number
  previous?: number
  previousCount?: number
}

type BarChartProps = {
  data: BarChartData[]
  bars: BarDefinition[]
  xAxisLabel?: string[]
  valueFormatter?: (value: number) => string
  labelFormatter?: (value: string) => string
  xAxisFormatter?: (value: string) => string
  countFormatter?: (value?: number) => string | undefined
}

const BarChart: React.FC<BarChartProps> = ({
  data,
  bars,
  valueFormatter,
  labelFormatter,
  xAxisFormatter,
  countFormatter,
}) => {
  const formatTooltipValue = (value: number, label: string, { payload }: any) => {
    const count: number | undefined = payload.count
    let displayedValue = valueFormatter ? valueFormatter(value) : String(value)
    if (count) {
      const displayedCount = countFormatter ? countFormatter(count) : count
      if (displayedCount) {
        displayedValue += ` (${displayedCount})`
      }
    }
    return [
      displayedValue,
      label,
    ]
  }

  return (
    <ResponsiveContainer width="100%">
      <ReChartBarChart
        data={data}
        barGap={0}
      >
        <CartesianGrid
          strokeDasharray="2"
          opacity={0.6}
        />
        <XAxis
          dataKey="label"
          tickFormatter={xAxisFormatter}
        />
        <YAxis
          tickFormatter={valueFormatter}
          width={80}
        />
        <Tooltip
          formatter={formatTooltipValue}
          labelFormatter={labelFormatter}
          cursor={{ fill: '#dadada' }}
        />
        <Legend />
        { bars.map(({ key, label, color, order }) => (
          <Bar
            key={key}
            dataKey={key}
            fill={color}
            name={label}
            maxBarSize={20}
            stackId={order}
          />
        )) }
      </ReChartBarChart>
    </ResponsiveContainer>
  )
}

export default BarChart
