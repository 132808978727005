import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { type SeasonsFormValues, computedName } from './SeasonsForm.type'
import { type CompanyBranchSeasons, Season } from '../../../../modules/companies'
import { validSeasonValues, validSeasonsOverlap } from './SeasonsForm.utils'

const useValidationSchema = () => {
  const { t } = useTranslation()

  const validation = Yup
    .mixed<CompanyBranchSeasons>()
    .test(
      'validLowSeason',
      t('forms.companyBranch.seasons.validationErrors.invalidLowSeasonValues'),
      (value) => {
        return value ? validSeasonValues(value[Season.Low]) : true
      })
    .test(
      'validHighSeason',
      t('forms.companyBranch.seasons.validationErrors.invalidHighSeasonValues'),
      (value) => {
        return value ? validSeasonValues(value[Season.High]) : true
      })
    .test(
      'validSeasonsOverlap',
      t('forms.companyBranch.seasons.validationErrors.seasonsCannotOverlap'),
      (value) => {
        return value ? validSeasonsOverlap(value) : true
      })

  return Yup.object<SeasonsFormValues>({
    [computedName]: validation,
  }) as Yup.ObjectSchema<SeasonsFormValues, SeasonsFormValues>
}

export default useValidationSchema
