import { gql } from '@apollo/client'
import { promoCodeFields } from './promoCodes.queries'

export const createPromoCode = gql`
mutation Admin_createPromoCode($payload: CreatePromoCodeDto!) {
  admin_createPromoCode(payload: $payload) {
    ${promoCodeFields}
  }
}
`

export const updatePromoCode = gql`
mutation Admin_updatePromoCode($id: String!, $payload: UpdatePromoCodeDto!) {
  admin_updatePromoCode(id:$id, payload: $payload) {
    ${promoCodeFields}
  }
}
`

export const deletePromoCodes = gql`
mutation Admin_deletePromoCodes($ids: [String!]!) {
  admin_deletePromoCodes(ids: $ids) {
    ${promoCodeFields}
  }
}
`

export const restorePromoCodes = gql`
mutation Admin_restorePromoCodes($ids: [String!]!) {
  admin_restorePromoCodes(ids: $ids) {
    ${promoCodeFields}
  }
}
`
