import { type Action, type GridQuery } from './DataGrid.type'

const LOCALSTORAGE_NAMESPACE = 'DataGrid'

const getLocalStorageName = (name: string) => `${LOCALSTORAGE_NAMESPACE}:${name}`

/**
 * get saved data grid query from localstorage
 */
export const getSavedQuery = (name?: string) => {
  if (!localStorage || !name) {
    return
  }
  const saved = localStorage.getItem(getLocalStorageName(name))
  if (!saved) {
    return
  }
  try {
    return JSON.parse(saved)
  } catch (error) {}
}

/**
 * save some of the query in localstorage
 */
export const saveQuery = (
  query: GridQuery,
  name?: string,
  saveCustomFilterValues?: string[],
) => {
  if (!localStorage || !name) {
    return
  }

  let custom

  if (
    Array.isArray(saveCustomFilterValues) &&
    saveCustomFilterValues.length > 0 &&
    !!query.custom
  ) {
    custom = Object.fromEntries(Object.entries(query.custom).filter(([key]) => saveCustomFilterValues.includes(key)))
  }

  localStorage.setItem(getLocalStorageName(name), JSON.stringify({
    nbResultsPerPage: query.nbResultsPerPage,
    sortField: query.sortField,
    sortDirection: query.sortDirection,
    excludeDeleted: query.excludeDeleted,
    custom,
  }))
}

/**
 * get saved visible columns
 */
export const getSavedVisibleColumns = (name?: string) => {
  if (!localStorage || !name) {
    return
  }
  const columnItem = `${getLocalStorageName(name)}:columns`
  const saved = localStorage.getItem(columnItem)
  if (!saved) {
    return
  }
  try {
    return JSON.parse(saved)
  } catch (error) {}
}

/**
 * save visible columns settings
 */
export const saveVisibleColumns = (
  visibleColumns: string[],
  name?: string,
) => {
  if (!localStorage || !name) {
    return
  }

  const columnItem = `${getLocalStorageName(name)}:columns`
  localStorage.setItem(columnItem, JSON.stringify(visibleColumns))
}

export const getIndividualActions = (actions: Action[]) => {
  return actions.filter(action => !!action.callback)
}

export const getBulkActions = (actions: Action[]) => {
  return actions.filter(action => !!action.bulkCallback)
}
