import { type Amount } from '../../common/amount'

/**
 * ensure amount is greater or equal to 0
 */
export const ensurePositivePrice = (amount: Amount): Amount => {
  return {
    ...amount,
    price: Math.max(amount.price, 0),
  }
}
