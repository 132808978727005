import * as Yup from 'yup'

import { type QuoteDetailFormValue } from './QuoteDetailsForm.type'
import useChargesValidation from '../../invoice/ChargesFormContent/validator'

const useValidationSchema = () => {
  const chargesValidation = useChargesValidation()

  return Yup.object({
    charges: chargesValidation,
  }) as Yup.ObjectSchema<QuoteDetailFormValue, QuoteDetailFormValue>
}

export default useValidationSchema
