import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CalendarIcon from '@mui/icons-material/CalendarMonthOutlined'

import {
  ReportIntervalType,
  useGetReportInterval,
  type ReportIntervalValue,
} from '../../../modules/adminAccounting'
import PageContent from '../../../components/layout/PageContent'
import ToggleButtonGroup, { ToggleButton } from '../../../components/ToggleButtonGroup'
import DateRangeModal, { type DateRange } from '../../../components/DateRangeModal'
import ReportIntervalDates from './ReportIntervalDates'

type DatesSelectorProps = {
  value: ReportIntervalValue
  onChange: (value: ReportIntervalValue) => void
}

const DatesSelector: React.FC<DatesSelectorProps> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation()
  const [showDateRangePicker, setShowDateRangePicker] = useState(false)
  const [dateRangeValue, setDateRangeValue] = useState<DateRange>()
  const interval = useGetReportInterval()(value)

  const handleChangeReportType = (type: ReportIntervalType) => {
    if (!type && value.type) {
      handleChangeReportType(value.type)
      return
    }
    if (type === ReportIntervalType.Custom) {
      setShowDateRangePicker(true)
      return
    }
    onChange({ type })
  }

  const onSelectCustomDateRange = (dateRange: DateRange) => {
    if (!dateRange) {
      return
    }
    setDateRangeValue(dateRange)
    setShowDateRangePicker(false)
    onChange({
      type: ReportIntervalType.Custom,
      interval: {
        start: dateRange.from,
        end: dateRange.to ?? dateRange.from,
      },
    })
  }

  return (
    <>
      <DateRangeModal
        open={showDateRangePicker}
        onClose={() => { setShowDateRangePicker(false) }}
        currentRange={dateRangeValue}
        onSelect={onSelectCustomDateRange}
      />
      <div className="sticky top-0 z-[3] border-b border-zinc-200 bg-stone-100 py-2 shadow-neutral-200/80 lg:border-b-0 lg:shadow-md">
        <PageContent>
          <div className="flex items-center justify-between">
            <ToggleButtonGroup
              exclusive
              value={value.type}
              onChange={(event, value) => { handleChangeReportType(value) }}
            >
              <ToggleButton value={ReportIntervalType.YearToDate}>
                { t('pages.admin.accounting.dateSelector.yearToDate') }
              </ToggleButton>

              <ToggleButton
                value={ReportIntervalType.MonthToDate}
              >
                { t('pages.admin.accounting.dateSelector.monthToDate') }
              </ToggleButton>

              <ToggleButton
                value={ReportIntervalType.PreviousMonth}
              >
                { t('pages.admin.accounting.dateSelector.previousMonth') }
              </ToggleButton>

              <ToggleButton
                value={ReportIntervalType.Custom}
                icon={<CalendarIcon />}
              >
                { t('pages.admin.accounting.dateSelector.custom') }
              </ToggleButton>
            </ToggleButtonGroup>

            <div className="hidden gap-x-2 lg:flex">
              <ReportIntervalDates interval={interval} />
            </div>

          </div>
        </PageContent>
      </div>
    </>
  )
}

export default DatesSelector
