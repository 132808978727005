import { mergeClassName } from '../../utils/mergeClassName'

type ModalDescriptionProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const ModalDescription: React.FC<ModalDescriptionProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={mergeClassName(
        'text-sm text-muted-foreground flex flex-grow overflow-auto px-6',
        className,
      )}
      {...props}
    >
      <div className="w-full">
        { children }
      </div>
    </div>
  )
}

export default ModalDescription
