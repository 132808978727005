import TrueIcon from '@mui/icons-material/Check'
import FalseIcon from '@mui/icons-material/Close'

import { mergeClassName } from '../../utils/mergeClassName'
import Value, { type ValueProps } from './Value'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
export type BooleanValueProps = Omit<ReactDiv, 'children'> & {
  value?: boolean
  labelTrue?: React.ReactNode
  labelFalse?: React.ReactNode
  labelWrapper?: React.FC<ValueProps>
  iconClassName?: string
}

const BooleanValue: React.FC<BooleanValueProps> = ({
  className,
  value = false,
  labelTrue,
  labelFalse,
  labelWrapper: Wrapper = Value,
  iconClassName,
  ...props
}) => {
  const label = value ? labelTrue : labelFalse
  const Icon = value ? TrueIcon : FalseIcon

  return (
    <div
      className={mergeClassName(
        'flex gap-2 items-center child:text-base child:leading-5',
        value && 'child:text-green-800',
        !value && 'child:text-gray-400',
        className,
      )}
      {...props}
    >
      <Icon
        className={mergeClassName(
          '!text-[18px]',
          iconClassName,
        )}
      />
      { label && (
        <Wrapper>
          { label }
        </Wrapper>
      ) }
    </div>
  )
}

export default BooleanValue
