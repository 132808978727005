import { useTranslation } from 'react-i18next'

import Route from '../../../app/Route.enum'
import { useNavigate } from '../../../modules/navigation'
import { type MovePayload, useCreateMoveAction } from '../../../modules/adminMoves'
import { protectAdminPage } from '../../../modules/users'
import { errorNotification, successNotification } from '../../../components/ToastNotifications'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import { CreateMoveForm } from '../../../components/forms/moves/moveForm'
import ContainedPage from '../../../components/layout/ContainedPage'
import PageContent from '../../../components/layout/PageContent'
import PageTitle from '../../../components/layout/PageTitle'
import { QuotesSpinner } from '../../../components/quotesLegacy'

const CreateMovePage: React.FC = () => {
  const { t } = useTranslation()
  const { navigate } = useNavigate()
  const { createMove } = useCreateMoveAction()
  const formatError = useErrorFormatter()

  const creatingMove = false

  const onSubmit = async (payload: MovePayload) => {
    try {
      const move = await createMove(payload)
      successNotification(t('pages.admin.move.create.createSuccess'))
      navigate(Route.AdminMove, { moveId: move.id })
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  return (
    <ContainedPage>
      <PageTitle>
        { t('pages.admin.move.create.title') }
      </PageTitle>
      <PageContent>

        { creatingMove
          ? <QuotesSpinner />
          : (
            <CreateMoveForm
              onSubmit={onSubmit}
              onCancel={() => { navigate(Route.AdminMoves) }}
            />
            ) }

      </PageContent>
    </ContainedPage>
  )
}

export default protectAdminPage(CreateMovePage)
