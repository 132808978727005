export const invoiceEstimateFields = `
subtotal {
  price
  currency
}
discount {
  price
  currency
}
taxes {
  tax
  percentage
  number
  baseAmount {
    price
    currency
  }
  total {
    price
    currency
  }
  region
}
tip {
  price
  currency
}
total {
  price
  currency
}
`

export const invoiceCharge = `
pricingCategory
description
overrideDescription
item
quantity
unit
unitPrice {
  price
  currency
}
subtotal {
  price
  currency
}
taxable
`

export const invoiceFields = `
id
invoiceNumber
createdAt
invoiceDate
category
promoCode {
  code
  type
  value
}
details {
  ${invoiceCharge}
}
${invoiceEstimateFields}
`
