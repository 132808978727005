export const roundPrice = (amount: number, nbDigits = 2) => {
  if (amount === Infinity || isNaN(amount)) {
    return 0
  }
  const base = Math.pow(10, nbDigits)
  if (amount < 0) {
    const rounded =
      Math.round((Math.abs(amount) + Number.EPSILON) * base) / base
    return rounded * -1
  } else {
    return Math.round((amount + Number.EPSILON) * base) / base
  }
}
