import LeftSidebarPage from '../../../components/layout/LeftSidebarPage/LeftSidebarPage'
import { CompanyBranchSidebar, CompanyBranchTopContent } from '../../../components/companyBranch'

type PageContainerProps = {
  children?: React.ReactNode
  mobileHeader?: React.ReactNode
}

const PageContainer: React.FC<PageContainerProps> = ({
  children,
  mobileHeader,
}) => {
  return (
    <LeftSidebarPage
      topContent={<CompanyBranchTopContent />}
      sidebar={<CompanyBranchSidebar />}
      mobileHeader={mobileHeader}
    >
      { children }
    </LeftSidebarPage>
  )
}

export default PageContainer
