import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

import Link from '../Link'
import ServerError from '../errors/ServerError'
import Spinner from '../Spinner'
import { useResendVerificationEmailAction } from '../../modules/users'

const VerifyYourEmail: React.FC = () => {
  const { t } = useTranslation()
  const { resendVerificationEmail, data: success, loading, error } = useResendVerificationEmailAction()

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    resendVerificationEmail().catch(() => {})
  }

  return (
    <>
      <Typography variant="h1" fontSize="3.5rem" lineHeight="3.5rem">{ t('pages.users.verifyYourEmail.title') }</Typography>
      <Typography variant="h2" fontSize="1rem" fontWeight="bold">{ t('pages.users.verifyYourEmail.subtitle') }</Typography>

      <Typography mb={3}>{ t('pages.users.verifyYourEmail.description') }</Typography>

      { error && <Box mb={2}><ServerError error={error} /></Box> }

      { success && (
        <Box mb={2}>
          <Alert severity="success">
            { t('pages.users.verifyYourEmail.success') }
          </Alert>
        </Box>
      ) }

      { loading && (
        <Box mb={1}>
          <Spinner small /> <Typography component="span" style={{ color: '#999', textDecoration: 'underline' }}>{ t('pages.users.verifyYourEmail.resendEmail') }...</Typography>
        </Box>
      ) }

      { !success && !loading && (
        <Box mb={1}>
          <Link to="#" onClick={onClick} variant="primary">
            { t('pages.users.verifyYourEmail.resendEmail') }
          </Link>
        </Box>
      ) }
    </>
  )
}

export default VerifyYourEmail
