import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import { type AdminUser } from '../../../../modules/adminUsers'
import { Row, Cell, Label, Value } from '../../../../components/cell'
import { mergeClassName } from '../../../../utils/mergeClassName'

type AuthenticationProps = {
  user: AdminUser
}

const Authentication: React.FC<AuthenticationProps> = ({ user }) => {
  return (
    <Row>
      <Cell>
        <Label>Two-factor authentication</Label>
        <Value
          className={mergeClassName(user.mfaEnabled ? 'text-green-800' : 'text-red-800')}
        >
          { user.mfaEnabled ? <><CheckIcon /> Enabled</> : <><CloseIcon />Not enabled</> }
        </Value>
      </Cell>
      <Cell />
    </Row>
  )
}

export default Authentication
