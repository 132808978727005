export enum SpecialItem {
  Appliance = 'appliance',
  LargeAppliance = 'largeAppliance',
  CommercialFreezer = 'commercialFreezer',
  SportEquipment = 'sportEquipment',
  UprightPiano = 'uprightPiano',
  BabyGrandPiano = 'babyGrandPiano',
  GrandPiano = 'grandPiano',
  PoolTable = 'poolTable',
  MarbleTableTop = 'marbleTableTop',
  HeavyItem200 = 'heavyItem200',
  HeavyItem300 = 'heavyItem300',
  HeavyItem400 = 'heavyItem400',
}
