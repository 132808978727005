export const NB_RESULTS_PER_PAGE_OPTIONS = [25, 50, 100]
export const DEFAULT_NB_RESULTS_PER_PAGE = 50

export const DEFAULT_QUERY = {
  nbResultsPerPage: DEFAULT_NB_RESULTS_PER_PAGE,
  page: 1,
  sortField: undefined,
  sortDirection: undefined,
  search: undefined,
  excludeDeleted: true,
}
