import { gql } from '@apollo/client'
import { companyBranchFields, companyFields } from '../../companies/core/companies.queries'
import { userFields } from '../../adminUsers/core/adminUsers.queries'

export const fullCompanyFields = `
  ${companyFields}
  primaryContact {
    ${userFields}
  }
  pendingInvitation {
    id
    createdAt
    email
    firstName
    lastName
  }
  logo {
    en
  }
`

export const fullCompanyBranchFields = `
${companyBranchFields}
company {
  ${fullCompanyFields}
}
`

export const getCompaniesBranches = gql`
query Admin_getCompaniesBranches($query: PaginatedQuery) {
  admin_getCompaniesBranches(query: $query) {
    data {
      ${fullCompanyBranchFields}
    }
    count
    hasMore
  }
}
`

export const getCompaniesBranchesCoordinates = gql`
query Admin_getCompaniesBranchesCoordinates {
  admin_getCompaniesBranchesCoordinates {
    id
    companyId
    companyName
    coordinates {
      coordinates
      type
    }
    radius
  }
}
`

export const exportCompaniesForBlog = gql`
query ExportCompaniesForBlog {
  admin_exportCompaniesForBlog
}
`
