import { useTranslation } from 'react-i18next'

import { Permission, usePermission } from '../../../../modules/users'
import { type FullCompanyBranch } from '../../../../modules/adminCompanies'
import InitialRatingForm from '../../../../components/forms/companyBranch/InitialRatingForm'
import Paper from '../../../../components/Paper'
import { type CompanyBranchPayload, useUpdateCompanyBranchAction } from '../../../../modules/companies'
import { errorNotification, successNotification } from '../../../../components/ToastNotifications'
import { useErrorFormatter } from '../../../../components/errors/useErrorFormatter'

type RatingsTabProps = {
  companyBranch: FullCompanyBranch
}

const RatingsTab: React.FC<RatingsTabProps> = ({ companyBranch }) => {
  const { t } = useTranslation()
  const canManageCompanies = usePermission(Permission.canManageCompanies)
  const { updateCompanyBranch } = useUpdateCompanyBranchAction()
  const formatError = useErrorFormatter()

  const onSubmit = async (values: CompanyBranchPayload) => {
    try {
      await updateCompanyBranch(values, companyBranch.id)
      successNotification(t('forms.companyBranch.success'))
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  return (
    <div>
      { canManageCompanies && (
        <Paper compact>
          <InitialRatingForm
            companyBranch={companyBranch}
            onSubmit={onSubmit}
          />
        </Paper>
      ) }
    </div>
  )
}

export default RatingsTab
