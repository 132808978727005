import { type Interval } from 'date-fns'

import Route from '../../../app/Route.enum'
import { useNavigate } from '../../../modules/navigation'
import { usePendingRequestsNotifications } from '../../../modules/jobRequests'
import EventCalendar from '../../../components/calendar/EventCalendar'
import { type Event } from '../../../components/calendar/EventCalendar/EventCalendar.types'

type CalendarProps = {
  events: Event[]
  defaultDate?: Date
  fetchEvents: (fromDate: Date, endDate: Date) => Promise<void>
  onPeriodChange?: (period: Interval) => void
}

const Calendar: React.FC<CalendarProps> = ({
  events,
  fetchEvents,
  defaultDate,
  onPeriodChange,
}) => {
  const { navigate } = useNavigate()
  const pendingJobRequests = usePendingRequestsNotifications()

  const handleEventClick = (id: string) => {
    const isJobRequest = !!pendingJobRequests?.data?.find((jobRequest) => jobRequest.id === id)
    if (isJobRequest) {
      navigate(Route.JobRequest, { jobRequestId: id })
      return
    }
    navigate(Route.Job, { jobId: id })
  }

  return (
    <EventCalendar
      fetchEvents={fetchEvents}
      events={events}
      onEventClick={handleEventClick}
      defaultDate={defaultDate}
      onPeriodChange={onPeriodChange}
    />
  )
}

export default Calendar
