import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'

import { Form, SixDigitsCodeField, SubmitButton, SubmitButtonsContainer } from '../../../form'
import useValidationSchema from './validationSchema'
import { type DisableTwoFactorFormPayload } from './DisableTwoFactorFormPayload.type'

type DisableTwoFactorFormProps = {
  onSubmit: (payload: DisableTwoFactorFormPayload) => Promise<any>
  onClose?: () => void
}

const DisableTwoFactorForm: React.FC<DisableTwoFactorFormProps> = ({
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation()
  const schema = useValidationSchema()
  const form = useForm({ resolver: yupResolver(schema) })

  return (
    <Form form={form} onSubmit={onSubmit}>
      <SixDigitsCodeField
        name="totp"
        label={t('forms.fields.code.label')}
      />

      <SubmitButtonsContainer>
        { onClose && <Button onClick={onClose}>{ t('actions.cancel') }</Button> }
        <SubmitButton>{ t('actions.next') }</SubmitButton>
      </SubmitButtonsContainer>
    </Form>
  )
}

export default DisableTwoFactorForm
