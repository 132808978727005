import * as Yup from 'yup'

import useQuotesRequestValidationSchema from '../../quotes/QuotesRequestForm/validationSchema'
import { usePhoneNumberValidator } from '../../../form/fields/PhoneNumberField/validator'

export const useUserValidationShape = () => {
  const validatePhoneNumber = usePhoneNumberValidator()

  return {
    user: Yup.object().shape({
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
      email: Yup.string().required().email(),
      phoneNumber: validatePhoneNumber({ required: true }),
    }),
  }
}

type UseValidationSchemaOptions = {
  includeUser?: boolean
}

const useValidationSchema = <T extends Yup.AnyObject>({ includeUser = true }: UseValidationSchemaOptions = {}): Yup.ObjectSchema<T, T> => {
  const userValidation = useUserValidationShape()
  const quotesRequestValidation = useQuotesRequestValidationSchema({
    requireDetails: true,
  })

  if (includeUser) {
    return quotesRequestValidation.shape(userValidation) as unknown as Yup.ObjectSchema<T, T>
  }

  return quotesRequestValidation as unknown as Yup.ObjectSchema<T, T>
}

export default useValidationSchema
