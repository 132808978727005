import { useTranslation } from 'react-i18next'

import { type CompanyBranch } from '../../../modules/companies'
import { SubmitButton as Button } from '../../form'

type SubmitButtonProps = {
  companyBranch?: CompanyBranch
  disabled?: boolean
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  companyBranch,
  disabled = false,
}) => {
  const { t } = useTranslation()

  const setupInProgress = (companyBranch?.setupProgress ?? 0) < 100

  return (
    <div className="mt-2 text-right">
      <Button
        size="large"
        disabled={disabled}
      >
        { setupInProgress ? t('actions.next') : t('actions.save') }
      </Button>
    </div>
  )
}

export default SubmitButton
