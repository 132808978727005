import CheckIcon from '@mui/icons-material/Check'
import IncompleteIcon from '@mui/icons-material/Close'
import WaitingIcon from '@mui/icons-material/QueryBuilder'

import { mergeClassName } from '../../utils/mergeClassName'
import { type Step as StepType } from './Step.type'

type StepProps = StepType & {
  className?: string
  forceVisibleDetails?: boolean
  compact?: boolean
}

const Step: React.FC<StepProps> = ({
  name,
  details,
  completed = false,
  warning = false,
  forceVisibleDetails = false,
  compact = false,
  className,
}) => {
  return (
    <div className={mergeClassName(
      'group flex w-full items-center bg-white rounded-t-md',
      completed && 'bg-green-50',
      warning && 'bg-orange-50',
      className,
    )}
    >
      <span className={mergeClassName(
        'my-2 flex items-center px-4 text-sm font-medium lg:pr-6 lg:pl-4 lg:py-4',
        compact && 'py-2 lg:py-2',
        forceVisibleDetails && 'py-2 lg:pl-6',
      )}
      >
        <span className={mergeClassName(
          'flex h-4 w-4 lg:h-7 lg:w-7 shrink-0 items-center justify-center rounded-full bg-gray-300 text-white',
          completed && 'bg-green-600',
          warning && 'bg-orange-300',
        )}
        >
          { completed && !warning && (
            <CheckIcon className="!text-[12px] lg:!text-[18px]" />
          ) }

          { !completed && !warning && (
            <IncompleteIcon className="!text-[12px] lg:!text-[18px]" />
          ) }

          { warning && (
            <WaitingIcon className="!text-[12px] lg:!text-[18px]" />
          ) }
        </span>
        <div>
          <div className={mergeClassName(
            'ml-4 text-[0.9rem] font-medium text-gray-700 leading-tight',
            completed && 'text-green-700',
            warning && 'text-orange-500',
          )}
          >
            { name }
          </div>
          { details && (
            <div className={mergeClassName(
              'ml-4 hidden text-sm font-normal text-gray-400 lg:block',
              forceVisibleDetails && 'block',
            )}
            >
              { details }
            </div>
          ) }
        </div>
      </span>
    </div>
  )
}

export default Step
