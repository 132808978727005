import { gql } from '@apollo/client'

export const userFields = `
id
createdAt
updatedAt
deletedAt
lastConnexion
externalId
externalProvider
roles
mfaEnabled
email
emailVerified
firstName
lastName
photo
phoneNumber {
  number
  extension
}
position
`

export const getCurrentUser = gql`
query Admin_getCurrentUser {
  admin_getCurrentUser {
    ${userFields}
  }
}
`

export const getUser = gql`
query Admin_getUser($userId: String!) {
  admin_getUser(userId: $userId) {
    ${userFields}
  }
}
`

export const getUsers = gql`
query Admin_getUsers($query: PaginatedQuery, $adminOnly: Boolean) {
  admin_getUsers(query: $query, adminOnly: $adminOnly) {
    data {
      ${userFields}
    }
    count
    hasMore
  }
}
`
