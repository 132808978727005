import { type FullMove } from '../../../../../modules/adminMoves'
import EstimateBox from './EstimateBox'

type EstimateTabProps = {
  move: FullMove
}

const EstimateTab: React.FC<EstimateTabProps> = ({
  move,
}) => {
  return (
    <EstimateBox move={move} />
  )
}

export default EstimateTab
