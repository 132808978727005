import { ReactComponent as LogoGeneric } from '../../../assets/creditCardLogos/generic.svg'
import { ReactComponent as LogoAmex } from '../../../assets/creditCardLogos/amex.svg'
import { ReactComponent as LogoMastercard } from '../../../assets/creditCardLogos/mastercard.svg'
import { ReactComponent as LogoVisa } from '../../../assets/creditCardLogos/visa.svg'

type CreditCardLogoProps = {
  brand: string
} & React.SVGProps<SVGSVGElement>

const CreditCardLogo: React.FC<CreditCardLogoProps> = ({
  brand,
  ...props
}) => {
  const Logo = (() => {
    switch (brand) {
      case 'amex':
      case 'americanexpress':
      case 'american-express':
        return LogoAmex
      case 'mastercard':
        return LogoMastercard
      case 'visa':
        return LogoVisa
      default:
        return LogoGeneric
    }
  })()

  return <Logo {...props} />
}

export default CreditCardLogo
