import { mergeClassName } from '../../utils/mergeClassName'

type ModalHeaderProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const ModalHeader: React.FC<ModalHeaderProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={mergeClassName(
        'flex flex-col space-y-1.5 text-center lg:text-left px-6',
        className,
      )}
      {...props}
    >
      { children }
    </div>
  )
}

export default ModalHeader
