import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import InputAdornment from '@mui/material/InputAdornment'
import MinusIcon from '@mui/icons-material/RemoveCircleOutline'
import PlusIcon from '@mui/icons-material/AddCircleOutline'

import { Input } from '../../'
import './style.css'

type IntegerInputProps = {
  name: string
  min?: number
  max?: number
}

const IntegerInput: React.FC<IntegerInputProps> = ({
  name,
  min = 0,
  max = Infinity,
}) => {
  const { setValue } = useFormContext()
  const value = useWatch({ name }) ?? 0

  /**
   * clamp value when changed
   */
  useEffect(() => {
    if (!value) {
      setValue(name, min, { shouldValidate: true })
    }
    const rangeValue = Math.min(Math.max(parseInt(value), min), max)

    if (value !== rangeValue) {
      setValue(
        name,
        rangeValue,
        { shouldValidate: true },
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  /**
   * remove 1 to integer
   */
  const handleRemove = () => {
    const newValue = Math.max(parseInt(value) - 1, min)
    setValue(
      name,
      isNaN(newValue) ? min : newValue,
      { shouldValidate: true, shouldDirty: true, shouldTouch: true },
    )
  }

  /**
   * add 1 to integer
   */
  const handleAdd = () => {
    const newValue = Math.min((parseInt(value) ?? 0) + 1, max)
    setValue(
      name,
      isNaN(newValue) ? (min + 1) : newValue,
      { shouldValidate: true, shouldDirty: true, shouldTouch: true },
    )
  }

  return (
    <div className="input-number flex items-center gap-2">
      <Input
        type="number"
        name={name}
        style={{ width: 110 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <button type="button" onClick={handleRemove}>
                <MinusIcon className="!text-[18px] text-slate-500" />
              </button>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <button type="button" onClick={handleAdd}>
                <PlusIcon className="!text-[18px] text-slate-500" />
              </button>
            </InputAdornment>
          ),
        }}
        size="small"
        className="-mx-2 [&input]:!text-center"
      />
    </div>
  )
}

export default IntegerInput
