import ReactTimeAgo, { type ReactTimeagoProps } from 'react-timeago'
// @ts-expect-error ; missing definition on their library
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
// @ts-expect-error ; missing definition on their library
import defaultFrenchStrings from 'react-timeago/lib/language-strings/fr'

import { useLanguage } from '../../modules/i18n'
import { useMemo } from 'react'
import Language from '../../app/Language.enum'
import { capitalizeFirstLetter } from '../../utils/string'

const frenchStrings = Object.fromEntries(
  Object
    .entries(defaultFrenchStrings as Record<string, string>)
    .map(([key, value]) => ([
      key,
      capitalizeFirstLetter(value.replace('environ', '')),
    ])),
)

const TimeAgo: React.FC<ReactTimeagoProps<any>> = (props) => {
  const language = useLanguage()

  const formatter = useMemo(() => {
    if (language === Language.Fr) {
      return buildFormatter(frenchStrings)
    }
  }, [language])

  return (
    <ReactTimeAgo {...props} formatter={formatter} />
  )
}

export default TimeAgo
