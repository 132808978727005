import { useTranslation } from 'react-i18next'
import { type Interval } from 'date-fns'
import { isSameDay } from 'date-fns/isSameDay'

import { useDateFormatter } from '../../../../../utils/date'
import { type RuleTemporality, RuleTemporalityType } from '../availabilityAndPricing.models'
import { withinInterval } from '../date.util'
import { type TemporalityHandler } from './temporalityRules.type'

const useDateRuleHandler = (): TemporalityHandler => {
  const { t } = useTranslation()
  const formatDate = useDateFormatter()

  return (rule: RuleTemporality) => {
    if (rule.type !== RuleTemporalityType.Date) {
      return
    }

    return {
      filter: (period: Interval) => {
        if (!rule.date) {
          return false
        }
        return withinInterval(rule.date, period)
      },

      isRuleActiveForDate: (date: Date) => {
        if (!rule.date) {
          return false
        }
        return isSameDay(rule.date, date)
      },

      getCalendarEntries: () => {
        if (!rule.date) {
          return
        }
        return [rule.date]
      },

      getTitle: () => {
        if (!rule.date) {
          return
        }
        return (
          <>
            { t('date.datePrefix') } { formatDate(rule.date) }
          </>
        )
      },

      getColor: () => '#017c82',

      isRecurrent: () => false,
    }
  }
}

export default useDateRuleHandler
