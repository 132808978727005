import * as Yup from 'yup'

import { type ImportCalendarsFormValues } from './ImportCalendarsForm.types'

const useValidationSchema = () => {
  return Yup.object<ImportCalendarsFormValues>({
    importedCalendars: Yup.array().of(
      Yup.object({
        value: Yup.string().required().url(),
      }),
    ),
  }) as Yup.ObjectSchema<ImportCalendarsFormValues, ImportCalendarsFormValues>
}

export default useValidationSchema
