/**
 * removes top and bottom transparent pixels
 * this receive and returns a canvas
 */
export const trimVerticalPixels = (canvas: HTMLCanvasElement) => {
  const copyCanvas = document.createElement('canvas')
  const copyCanvasCtx = copyCanvas.getContext('2d')

  const ctx = canvas.getContext('2d')
  if (!ctx || !copyCanvasCtx) {
    return canvas
  }
  const pixels = ctx.getImageData(0, 0, canvas.width, canvas.height)
  const length = pixels.data.length
  const bound: Record<string, number | null> = {
    top: null,
    bottom: null,
  }

  let y
  for (let i = 0; i < length; i += 4) {
    if (pixels.data[i + 3] !== 0) {
      y = ~~((i / 4) / canvas.width)
      bound.top ??= y
      bound.bottom ??= y
      if (bound.bottom < y) {
        bound.bottom = y
      }
    }
  }

  if (!bound.bottom || !bound.top) {
    return canvas
  }

  const trimHeight = bound.bottom - bound.top
  const trim = ctx.getImageData(0, bound.top, canvas.width, trimHeight)

  copyCanvasCtx.canvas.width = canvas.width
  copyCanvasCtx.canvas.height = trimHeight
  copyCanvasCtx.putImageData(trim, 0, 0)

  return copyCanvas
}
