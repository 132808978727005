import { DateTime } from '../../../form'

type TemporalityDateFormProps = {
  prefixName: string
}

const TemporalityDateForm: React.FC<TemporalityDateFormProps> = ({
  prefixName,
}) => {
  return (
    <DateTime
      name={`${prefixName}.date`}
      slotProps={{
        textField: {
          size: 'small',
          style: { width: 135 },
          InputProps: { className: 'bg-white' },
        },
      }}
    />
  )
}

export default TemporalityDateForm
