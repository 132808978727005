import { useTranslation } from 'react-i18next'
import { toZonedTime } from 'date-fns-tz'
import Alert from '@mui/material/Alert'
import MarkerIcon from '@mui/icons-material/RoomOutlined'
import TimerIcon from '@mui/icons-material/TimerOutlined'
import TruckIcon from '@mui/icons-material/LocalShippingOutlined'
import DurationIcon from '@mui/icons-material/UpdateOutlined'

import { type ResolvedRequest } from '../../../modules/quotes'
import { useTimeFormatter } from '../../../utils/time'
import { mergeClassName } from '../../../utils/mergeClassName'
import Paper from '../../Paper'

const ONE_HOUR_MS = 60 * 60 * 1000

type QuotesSidebarProps = {
  request: ResolvedRequest
}

const QuotesSidebar: React.FC<QuotesSidebarProps> = ({
  request,
}) => {
  const { t } = useTranslation()
  const { formatTime, formatSeconds } = useTimeFormatter()
  const now = new Date()
  const customerTimeZoneDiff = (
    toZonedTime(now, request.originTimezone).getTime() - now.getTime()
  ) / ONE_HOUR_MS

  return (
    <Paper className="sticky top-[80px] z-[-1] mb-0 shrink-0 child:p-0 lg:w-[300px] lg:pb-0 child:lg:p-0 2xl:w-[400px]">
      <div className="mb-4 px-4 py-2 text-center font-body2 shadow-md">
        { t('pages.admin.quotes.sidebar.moveDetails.title') }
      </div>
      <div className="p-2 text-center lg:px-6 lg:py-4 lg:text-left">

        <div className="flex flex-col gap-6">
          <div>
            <div className="font-semibold text-gray-500">
              <MarkerIcon />
              { ' ' }
              { t('pages.admin.quotes.sidebar.moveDetails.moveDistance') }
              { ' ' }
              <span className="text-sm font-normal">(
                { t('pages.admin.quotes.sidebar.moveDetails.originToDestination') }
                )
              </span>
            </div>
            <div className="text-gray-400 lg:pl-6">
              <strong>
                { (request.moveLeg.distanceMeters / 1000).toFixed(1) } { t('units.km') }
              </strong>
              { ' ' }
              ({ t('time.timeWithTraffic', {
                time: formatSeconds(
                  request.moveLeg.durationInTrafficSeconds ?? request.moveLeg.durationSeconds,
                ),
              }) })
            </div>
          </div>

          <div>
            <div className="font-semibold text-gray-500">
              <DurationIcon />
              { ' ' }
              { t('pages.admin.quotes.sidebar.moveDetails.totalMoveDuration') }
            </div>
            <div className="text-gray-400 lg:pl-6">
              <strong>
                { formatSeconds(request.moveDurationSeconds) }
              </strong>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-px mt-4 border-t px-4 py-2 text-center font-body2 shadow-md">
        { t('pages.admin.quotes.sidebar.timeline.title') }
      </div>

      <div className="mb-4">
        { customerTimeZoneDiff !== 0 && (
          <Alert severity="info">
            { t(customerTimeZoneDiff > 0
              ? 'quotes.timeline.timezoneInfo.diffMinus'
              : 'quotes.timeline.timezoneInfo.diffPlus', {
              timezone: request.originTimezone,
              diff: Math.abs(customerTimeZoneDiff),
            }) }
          </Alert>
        ) }
      </div>

      <div className="flex justify-center p-2 pl-6 lg:block lg:p-6 lg:pl-9">
        <ol className="relative border-l">

          { Object.entries(request.timeline)
            .filter(([_, time]) => !!time)
            .map(([step, time]) => {
              if (Array.isArray(time)) {
                return null
              }
              const customerTime = toZonedTime(new Date(time), request.originTimezone)
              const label = t(`quotes.timeline.steps.${step}`, { defaultValue: '' })

              const isNewPosition = ['originArrivalTime', 'destinationArrivalTime'].includes(step)

              if (!label) {
                return null
              }
              return (
                <li key={step} className="mb-4 ml-6">
                  <span className={mergeClassName(
                    'absolute -left-3 flex h-6 w-6 items-center justify-center rounded-full bg-slate-200 ring-8 ring-white',
                    isNewPosition && 'bg-slate-400 text-gray-100',
                  )}
                  >
                    { isNewPosition
                      ? (
                        <MarkerIcon className="!text-[18px]" />
                        )
                      : (
                        <TimerIcon className="!text-[18px]" />
                        ) }

                  </span>
                  <time className="mb-1 flex items-center text-lg font-semibold text-gray-500">
                    { formatTime(customerTime) }
                  </time>
                  <p className="pb-2 text-base font-normal text-gray-400">
                    { label }
                  </p>
                  { step === 'originDepartureTime' && (
                    <div
                      className="flex h-24 items-center text-zinc-400"
                    >
                      <div className="absolute -left-px h-24 w-px border-l border-dashed border-zinc-400 bg-white" />
                      <TruckIcon />
                    </div>
                  ) }
                </li>
              )
            }) }

        </ol>
      </div>
    </Paper>
  )
}

export default QuotesSidebar
