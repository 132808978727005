import {
  type VariantProps,
  cva,
} from 'class-variance-authority'

import { mergeClassName } from '../../../../../utils/mergeClassName'

export enum Variant {
  Light = 'light',
  Default = 'default',
  Emphasis = 'emphasis',
}

export enum MetricVariant {
  Neutral = 'neutral',
  Negative = 'negative',
  Positive = 'positive',
}

const accountingMetricVariants = cva(
  'flex w-full flex-1 gap-x-4 rounded-md p-6',
  {
    variants: {
      variant: {
        [Variant.Light as string]: 'bg-transparent',
        [Variant.Default as string]: 'bg-slate-100/80',
        [Variant.Emphasis as string]: 'bg-slate-100 [&_.metric-label]:text-neutral-700 [&_.metric-value]:text-2xl [&_.metric-value]:text-neutral-800',
      },
      metricVariant: {
        [MetricVariant.Neutral as string]: '[&_.metric-bar]:bg-neutral-300',
        [MetricVariant.Positive as string]: '[&_.metric-bar]:bg-green-300',
        [MetricVariant.Negative as string]: '[&_.metric-bar]:bg-orange-400',
      },
    },
    defaultVariants: {
      variant: Variant.Default,
      metricVariant: MetricVariant.Neutral,
    },
  },
)

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

type AccountingMetricProps = {
  label?: React.ReactNode
  className?: string
  children?: React.ReactNode
} & VariantProps<typeof accountingMetricVariants> & ReactDiv

const AccountingMetric: React.FC<AccountingMetricProps> = ({
  variant,
  metricVariant,
  label,
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={mergeClassName(
        accountingMetricVariants({
          variant,
          metricVariant,
        }),
        className,
      )}
      {...props}
    >
      <div className="metric-bar w-[10px] rounded" />
      <div>
        <div className="metric-label text-sm text-neutral-600">
          { label }
        </div>
        <div className="metric-value text-xl font-bold text-neutral-600">
          { children }
        </div>
      </div>
    </div>
  )
}

export default AccountingMetric
