/* eslint-disable @typescript-eslint/no-misused-promises */
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import useValidationSchema from './validationSchema'
import { Form, Input, SubmitButton } from '../../../form'

type PromoCodeFormProps = {
  onSubmit: (promoCode: string | null) => Promise<void> | void
}

const PromoCodeForm: React.FC<PromoCodeFormProps> = ({
  onSubmit,
}) => {
  const { t } = useTranslation()
  const schema = useValidationSchema()
  const form = useForm({
    resolver: yupResolver(schema),
  })

  return (
    <Form
      form={form}
      formProps={{
        className: 'w-full',
      }}
      onSubmit={async (data: { promoCode: string }) => { await onSubmit(data.promoCode) }}
    >

      <div className="flex">
        <Input
          name="promoCode"
          size="small"
          className="child:!rounded-r-none"
        />
        <div>
          <SubmitButton
            size="small"
            className="!h-[40px] !rounded-l-none !rounded-r-[4px] !border-l-0 !bg-neutral-400 !shadow-none"
          >
            { t('actions.apply') }
          </SubmitButton>
        </div>
      </div>

    </Form>
  )
}

export default PromoCodeForm
