import { useTranslation } from 'react-i18next'

import { ChargeDescription } from '../../invoices'
import { estimateMoveSize, estimatePreliminaryMoveSize } from './estimators/moveEstimator'
import { type MoveDetails, type MoveSizeDetails, type MoveSizeEstimate } from './quotes.types'

/*
 * the "item" value is based on the pricing description
 */
export const useQuoteItemValueFormatter = () => {
  const { t } = useTranslation()

  return (description: ChargeDescription, value?: string) => {
    switch (description) {
      case ChargeDescription.movingLabour:
      case ChargeDescription.packingLabour:
      case ChargeDescription.travelLabour:
      case ChargeDescription.transportLabour:
      case ChargeDescription.additionalChargesStairs:
      {
        const nbMen = parseInt(value ?? '0')
        if (nbMen === 0) {
          return
        }
        return t('quotes.pricing.item.man', { count: nbMen })
      }
      case ChargeDescription.travelTruckFeePerKilometer:
      case ChargeDescription.transportTruckFeePerKilometer:
      case ChargeDescription.additionalChargesOvernight:
      {
        const nbTrucks = parseInt(value ?? '0')
        if (nbTrucks < 2) {
          return
        }
        return t('quotes.pricing.item.truck', { count: nbTrucks })
      }
      case ChargeDescription.fixedTruckFee:
      {
        if (!value) {
          return
        }
        return t(`trucks.${value}.title`)
      }
      case ChargeDescription.additionalChargesSpecialServices:
      {
        if (!value) {
          return
        }
        return t(`specialServices.${value}.title`)
      }
      case ChargeDescription.additionalChargesSpecialItems:
      {
        if (!value) {
          return
        }
        return t(`specialItems.${value}.title`)
      }
      case ChargeDescription.protectiveMaterialProduct:
      {
        if (!value) {
          return
        }
        return t(`protectiveMaterial.${value}.title`)
      }
      default:
        return value
    }
  }
}

/*
 * return true if we need to use a description on the quote details line
 * with:      Moving Labour (4 men)
 * without:   Mattress bag
 */
export const useShowDescription = (description: ChargeDescription) => {
  const noDescriptionNeeded = [
    ChargeDescription.protectiveMaterialProduct,
    ChargeDescription.customCharge,
    ChargeDescription.customChargeMw,
  ]
  return !noDescriptionNeeded.includes(description)
}

/**
 * estimate preliminary move size based move entity
 */
export const usePreliminaryMoveSizeEstimate = () => {
  return (moveDetails: MoveDetails): MoveSizeEstimate => estimatePreliminaryMoveSize(moveDetails)
}

/**
 * estimate move size based on form input
 */
export const useMoveSizeEstimate = () => {
  return (move: MoveSizeDetails): MoveSizeEstimate => estimateMoveSize(move)
}
