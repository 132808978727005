import { type Interval } from 'date-fns'
import { areIntervalsOverlapping } from 'date-fns/areIntervalsOverlapping'

import {
  type CompanyBranchSeasonsSeason,
  type CompanyBranchSeasons,
  Season,
} from '../../../../modules/companies'

type TimeValues = {
  from: { day: number, month: number }
  to: { day: number, month: number }
}

const nbDaysPerMonth: Record<number, number> = {
  1: 31,
  2: 29,
  3: 31,
  4: 30,
  5: 31,
  6: 30,
  7: 31,
  8: 31,
  9: 30,
  10: 31,
  11: 30,
  12: 31,
}

export const validSeasonValues = ({ enabled, from, to }: CompanyBranchSeasonsSeason) => {
  if (!enabled) {
    return true
  }
  for (const toValidate of [from, to]) {
    if (!toValidate) {
      return false
    }
    const { day, month } = toValidate
    if (!day || !month) {
      return false
    }
    if (day > nbDaysPerMonth[month]) {
      return false
    }
  }
  return true
}

export const validSeasonsOverlap = ({ [Season.Low]: low, [Season.High]: high }: CompanyBranchSeasons) => {
  if (!low.enabled || !high.enabled) {
    return true
  }

  const intervals = [
    ...toDateIntervals(low as TimeValues),
    ...toDateIntervals(high as TimeValues),
  ].flat()

  try {
    return !areMultipleIntervalsOverlapping(intervals)
  } catch (error) {
    return false
  }
}

const areMultipleIntervalsOverlapping = (intervals: Interval[]) => {
  for (const left of intervals) {
    for (const right of intervals) {
      if (left === right) {
        continue
      }
      if (areIntervalsOverlapping(left, right)) {
        return true
      }
    }
  }
  return false
}

const toDateInterval = ({ from, to }: TimeValues) => {
  return {
    start: new Date(2000, from.month - 1, from.day, 0, 0, 0, 0),
    end: new Date(2000, to.month - 1, to.day, 0, 0, 0, 0),
  }
}

const toDateIntervals = ({ from, to }: TimeValues) => {
  if (to.month >= from.month) {
    return [toDateInterval({ from, to })]
  }
  return [
    toDateInterval({ from, to: { day: 31, month: 12 } }),
    toDateInterval({ from: { day: 1, month: 1 }, to }),
  ]
}
