import { menByVolume } from '../quotes.const'

export const estimateNbMen = (volume: number) => {
  for (const [maxVolume, nbMen] of menByVolume) {
    if (volume <= maxVolume) {
      return nbMen
    }
  }
  return 2
}
