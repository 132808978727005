import { Children } from 'react'

import Value, { type ValueProps } from './Value'
import { mergeClassName } from '../../utils/mergeClassName'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export type ValuesProps = Omit<ReactDiv, 'children'> & {
  children?: React.ReactNode[]
  childrenClassName?: string
  childrenWrapper?: React.FC<ValueProps>
}

const Values: React.FC<ValuesProps> = ({
  className,
  childrenClassName,
  childrenWrapper: Wrapper = Value,
  children,
  ...props
}) => {
  if (!children && !Array.isArray(children)) {
    return (<Value />)
  }

  return (
    <div className={mergeClassName(
      'flex flex-wrap gap-2 mt-1',
      className,
    )}
    >
      { Children.map(children, child => (
        <div
          className={mergeClassName(
            'bg-slate-200 text-slate-800 rounded-md px-2 py-1 child:text-sm',
            childrenClassName,
          )}
          {...props}
        >
          <Wrapper>
            { child }
          </Wrapper>
        </div>
      )) }
    </div>
  )
}

export default Values
