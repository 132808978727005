import { type Charge } from '../../invoices'
import { type PaginatedQuery } from '../../graphQl'
import { type QuotesRequestPayload } from '../../adminQuotes'
import { type PhoneNumber } from '../../../common/phoneNumber'
import { type KeyValueMap } from '../../../common/keyValueMap'
import { type ResidenceType, type Room } from '../../quotes'

export type MovesPaginatedQuery = {
  query?: PaginatedQuery
  showPastMoves?: boolean
  customersFilter?: CustomersFilterValue
  assignedFilter?: AssignedFilterValue
  dateRangeFilter?: DateRangeFilterValue
}

export enum CustomersFilterValue {
  All = 'all',
  AgentRequired = 'agentRequired',
  QuoteSelected = 'quoteSelected',
  QuoteAccepted = 'quoteAccepted',
}

export enum AssignedFilterValue {
  All = 'all',
  OnlyMine = 'onlyMine',
  OnlyMineOrUnassigned = 'onlyMineOrUnassigned',
}

export enum DepositStatus {
  pending = 'pending',
  paid = 'paid',
}

export type DateRangeFilterValue = {
  from: Date
  to?: Date
}

export type MoveCustomerPayload = {
  email: string
  firstName: string
  lastName: string
  phoneNumber: PhoneNumber
  language?: string
}

export type MoveDetailsPayload<TNumber = number> = {
  residenceType: ResidenceType
  residenceRooms?: KeyValueMap<Room, number>
  nbBoxes?: TNumber
  furnitureRatio?: TNumber
  volumeCubicFeet?: TNumber
  weightPounds?: TNumber
}

export type MovePayload<TNumber = number> = {
  user: MoveCustomerPayload
} & QuotesRequestPayload<TNumber> & MoveDetailsPayload<TNumber>

export type DetailedQuoteRequest = QuotesRequestPayload & MoveDetailsPayload

export type QuotePayload = {
  details?: Array<Omit<Charge, 'subtotal'>>
}

export type ApproveMovePayload = {
  message?: string
}
