import type Address from './Address.type'

type AddressComponent = {
  long_name: string
  short_name: string
  types: string[]
}

export const getAddressComponents = (components: AddressComponent[], type: string, long: boolean) => {
  const component = components.find(component => component.types.includes(type))
  /* istanbul ignore if ; not testing invalid api response */
  if (!component) {
    return undefined
  }
  return long ? component.long_name : component.short_name
}

export const getAddressDetails = (components: AddressComponent[]): Address => ({
  street: [
    getAddressComponents(components, 'street_number', false),
    getAddressComponents(components, 'route', true),
  ].join(' '),
  city: getAddressComponents(components, 'locality', true),
  region: getAddressComponents(components, 'administrative_area_level_1', false),
  postalCode: getAddressComponents(components, 'postal_code', false),
})
