import { useEffect, useState } from 'react'
import {
  Calendar as BigCalendar,
  type View,
  Views,
  type EventPropGetter,
} from 'react-big-calendar'
import Color from 'color'
import { type Interval } from 'date-fns'
import { subMonths } from 'date-fns/subMonths'
import { addMonths } from 'date-fns/addMonths'
import { startOfMonth } from 'date-fns/startOfMonth'
import { endOfMonth } from 'date-fns/endOfMonth'
import { endOfDay } from 'date-fns/endOfDay'

import '../style.css'
import { useBreakpoint } from '../../../modules/theme'
import { mergeClassName } from '../../../utils/mergeClassName'
import Spinner from '../../Spinner'
import { CalendarContextProvider } from '../CalendarContext'
import useCalendarLanguageSettings from '../useCalendarLanguageSettings'
import DateCellBg from '../DateCellBg'
import MonthHeader from '../MonthHeader'
import { getNbEventsByDay } from './utils'
import { type Event } from './EventCalendar.types'
import DateCell from './DateCell'
import EventBox from './EventBox'
import EventWrapper from './EventWrapper'

type DateRange = {
  start: Date
  end: Date
}

type EventsCalendarProps = {
  events?: Event[]
  fetchEvents: (fromDate: Date, endDate: Date) => Promise<void>
  onEventClick?: (id: string) => void
  onPeriodChange?: (period: Interval) => void
  defaultDate?: Date
  className?: string
}

const EventCalendar: React.FC<EventsCalendarProps> = ({
  events = [],
  fetchEvents,
  onEventClick,
  onPeriodChange,
  defaultDate,
  className,
}) => {
  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState<Date>(defaultDate ?? new Date())
  const [currentPeriod, setCurrentPeriod] = useState<Date>()
  const [view, setView] = useState<string>(Views.MONTH)

  const isLargeScreen = useBreakpoint('lg')
  const languageSettings = useCalendarLanguageSettings()

  /**
   * get the range of date that we want to fetch
   * we're fetching 3 months to cover months or weeks overlaps
   */
  const getDateRange = (referenceDate: Date): DateRange => {
    const start = subMonths(startOfMonth(referenceDate), 1)
    const end = addMonths(endOfMonth(referenceDate), 1)
    return { start, end }
  }

  /**
   * fetch events
   */
  const getEvents = async ({ start, end }: DateRange) => {
    setLoading(true)
    try {
      await fetchEvents(start, end)
    } finally {
      setLoading(false)
    }
  }

  /**
   * fetch initial events
   */
  useEffect(() => {
    if (!currentPeriod) {
      getEvents(
        getDateRange(new Date()),
      ).catch(console.error)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPeriod])

  /**
   * week view is not supported on small device
   * making sure week view is not enabled on mobile when resizing
   */
  useEffect(() => {
    if (!isLargeScreen && view === Views.WEEK) {
      setView(Views.DAY)
    }
  }, [isLargeScreen, view])

  /**
   * fetch events when we change month
   */
  const onNavigate = async (date: Date) => {
    setDate(date)
    const { start, end } = getDateRange(date)
    const differentMonth = currentPeriod?.getTime() !== start.getTime()

    if (differentMonth) {
      setCurrentPeriod(start)
      await getEvents({ start, end })
    }
  }

  /**
   * call function when the displayed range is updated
   * ignore the overlapping months for the monthly view
   */
  const onRangeChange = (date: Date[] | DateRange, currentView?: View) => {
    currentView ??= view as View

    if (!onPeriodChange) {
      return
    }

    let start = Array.isArray(date) ? date[0] : date.start
    const end = endOfDay(Array.isArray(date) ? date[date.length - 1] : date.end)

    if (currentView === Views.MONTH) {
      if (start.getDate() > 7) {
        start = addMonths(start, 1)
      }
      onPeriodChange({
        start: startOfMonth(start),
        end: endOfMonth(start),
      })
      return
    }
    onPeriodChange({
      start,
      end,
    })
  }

  /**
   * Due to a bug in the calendar lib, we need to manually
   * set date and view when using a callback for onDrillDown
   * https://github.com/jquense/react-big-calendar/issues/1900
   */
  const onDrillDown = (date: Date, view: View) => {
    setDate(date)
    setView(view)
    onRangeChange([date], view)
  }

  /**
   * generate props for the events
   */
  const eventPropGetter: EventPropGetter<Event> = (event: any) => {
    const {
      id,
      backgroundColor = '#64748b',
      textColor = '#fff',
      pending = false,
    } = event
    const monthlyView = view === Views.MONTH
    const className = mergeClassName(
      'relative text-base font-sans !outline-none',
      !id && 'cursor-default',
      pending && 'before:content-[\'\'] before:size-full before:absolute before:left-0 before:top-0 before:pattern-diagonal-lines before:pattern-white before:pattern-bg-transparent before:pattern-size-6 before:pattern-opacity-20',
      pending && !monthlyView && 'before:z-[-1]',
    )

    if (monthlyView) {
      return {
        style: {
          backgroundColor: Color(backgroundColor).toString(),
          color: Color(textColor).toString(),
        },
        className,
      }
    }
    return {
      style: {
        border: 'solid white 1px',
        borderColor: Color(backgroundColor).darken(0.3).toString(),
        backgroundColor: Color(backgroundColor).fade(0.05).toString(),
        color: Color(textColor).toString(),
      },
      className: mergeClassName(
        className,
        'backdrop-blur shadow-white/10 shadow-inner p-2',
      ),
    }
  }

  return (
    <div className="relative w-full">
      { loading && (
        <div className="absolute left-0 top-0 z-50 flex size-full items-center justify-center bg-white/60">
          <Spinner />
        </div>
      ) }
      <CalendarContextProvider
        view={view}
        eventsByDay={getNbEventsByDay(events)}
      >
        <BigCalendar
          {...languageSettings}
          events={events}
          startAccessor="start"
          endAccessor="end"
          showMultiDayTimes
          view={view as any}
          views={[Views.DAY, Views.WEEK, Views.MONTH]}
          onView={setView}
          eventPropGetter={eventPropGetter}
          tooltipAccessor={null}
          components={{
            eventWrapper: EventWrapper,
            event: EventBox,
            dateCellWrapper: DateCellBg,
            month: {
              header: MonthHeader,
              dateHeader: DateCell,
            },
          }}
          className={mergeClassName(
            'eventCalendar w-full min-h-[550px] xl:min-h-[calc(100dvh-550px)]',
            className,
          )}
          onSelectEvent={onEventClick
            ? ({ id }) => { id && onEventClick(id) }
            : undefined}
          onNavigate={onNavigate}
          date={date}
          onRangeChange={onRangeChange}
          onDrillDown={onDrillDown}
        />
      </CalendarContextProvider>
    </div>
  )
}

export default EventCalendar
