import { useTranslation } from 'react-i18next'
import { Currency } from '../../../../common/amount'
import HorizontalBarList, { HorizontalBarItem } from '../../../../components/HorizontalBarList'
import { Card } from '../../../../components/card'
import { useAdminDashboardMetrics } from '../../../../modules/dashboard'
import { formatPrice } from '../../../../utils/money'

const TopSources: React.FC = () => {
  const { t } = useTranslation()
  const { data: dashboardMetrics } = useAdminDashboardMetrics()

  const topSourcesMetrics = dashboardMetrics?.moves?.topSources

  if (!topSourcesMetrics) {
    return null
  }

  const total = topSourcesMetrics?.reduce((acc, curr) => acc + curr.revenue, 0) ?? 0

  return (
    <div className="flex flex-col">
      <h2 className="mb-4 mt-5 text-xl leading-6 text-gray-900">
        { t('pages.admin.dashboard.topSources.title') }
        { ' ' }
        <span className="text-base text-gray-500">(
          { t('pages.admin.dashboard.last30Days') }
          )
        </span>
      </h2>

      <Card className="grow xl:col-span-2 xl:p-8">

        <HorizontalBarList className="m-2 overflow-y-auto pr-2 xl:max-h-[415px]">

          { topSourcesMetrics?.map(({ label, count, revenue }) => {
            const [source, medium, campaign] = label.split(' - ')

            return (
              <HorizontalBarItem
                key={label}
                value={(revenue / total) * 100}
                details={(
                  <>
                    { count }
                    { ' / ' }
                    { formatPrice(
                      {
                        price: revenue,
                        currency: Currency.CAD,
                      },
                      {
                        showDecimals: false,
                        compact: true,
                      },
                    ) }
                  </>
                )}
              >
                { source }
                { ' - ' }
                <span className="font-normal">
                  { medium }
                  { ' - ' }
                  { campaign }
                </span>
              </HorizontalBarItem>
            )
          }) }

        </HorizontalBarList>
      </Card>
    </div>
  )
}

export default TopSources
