import * as Yup from 'yup'
import { usePhoneNumberValidator } from '../../../form/fields/PhoneNumberField/validator'

const useValidationSchema = () => {
  const validatePhoneNumber = usePhoneNumberValidator()

  return Yup.object({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    phoneNumber: validatePhoneNumber({ required: true }),
    position: Yup.string().required(),
  }) as any
}

export default useValidationSchema
