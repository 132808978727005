export enum Truck {
  Trucks16ft = 'trucks16ft',
  Trucks20ft = 'trucks20ft',
  Trucks22ft = 'trucks22ft',
  Trucks24ft = 'trucks24ft',
  Trucks26ft = 'trucks26ft',
  Trucks28ft = 'trucks28ft',
  Trucks30ft = 'trucks30ft',
  Trucks36ft = 'trucks36ft',
  Trailers53ft = 'trailers53ft',
}
