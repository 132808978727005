import { useTranslation } from 'react-i18next'
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress'

import PageContent from '../layout/PageContent/PageContent'
import { useCurrentCompanyBranch } from '../../modules/companies'

const CompanyBranchProgress: React.FC = () => {
  const { t } = useTranslation()

  const { data: currentCompanyBranch } = useCurrentCompanyBranch()

  if (!currentCompanyBranch || currentCompanyBranch.setupProgress === 100) {
    return null
  }

  return (
    <div className="border-b border-zinc-200 bg-stone-100 py-1">
      <PageContent>

        <div className="flex items-center justify-center gap-4 lg:justify-start">

          <div className="relative inline-flex">
            <CircularProgress
              variant="determinate"
              value={100}
              sx={{
                opacity: 0.2,
              }}
              thickness={4}
            />
            <CircularProgress
              variant="determinate"
              value={currentCompanyBranch.setupProgress}
              sx={{
                position: 'absolute',
                left: 0,
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: 'round',
                },
              }}
              thickness={4}
            />
          </div>

          <div>
            { t('pages.companyBranch.setupProgress') }
            { ' ' }
            <strong>{ currentCompanyBranch.setupProgress }%</strong>
          </div>

        </div>
      </PageContent>
    </div>
  )
}

export default CompanyBranchProgress
