/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { type PromoCode, useGetSubtotalWithPromoCode } from '../../../../modules/adminPromoCodes'
import { type Quote, type QuoteAlternativeDate } from '../../../../modules/quotes'
import { ignoreTimezone } from '../../../../utils/date'
import DateBox from './DateBox'

type AlternativeDateProps = {
  quote: Quote
  promoCode?: PromoCode
  alternativeDate: QuoteAlternativeDate
}

const AlternativeDate: React.FC<AlternativeDateProps> = ({
  quote,
  promoCode,
  alternativeDate,
}) => {
  const priceWithPromoCode = useGetSubtotalWithPromoCode()
  const date = ignoreTimezone(alternativeDate.date)!

  const basePrice = priceWithPromoCode(quote.subtotal, promoCode).price
  const alternativePrice = priceWithPromoCode(alternativeDate.subtotal, promoCode).price

  const getClassName = () => {
    if (!alternativeDate.dateAvailable) {
      return 'bg-red-300'
    }
    if (alternativePrice < basePrice) {
      return 'bg-green-200'
    }

    if (alternativePrice > basePrice) {
      return 'bg-red-100'
    }
  }

  return (
    <DateBox
      key={date.getTime()}
      date={date}
      price={alternativePrice}
      className={getClassName()}
      floatingNode={!alternativeDate.dateAvailable && (
        <div className="before:absolute before:left-[30%] before:top-[-30%] before:h-[200%] before:w-px before:rotate-45 before:bg-red-400 before:content-[''] after:absolute after:right-[30%] after:top-[-30%] after:h-[200%] after:w-px after:-rotate-45 after:bg-red-400 after:content-['']" />
      )}
    />
  )
}

export default AlternativeDate
