import { useTranslation } from 'react-i18next'
import MarkerIcon from '@mui/icons-material/RoomOutlined'
import DurationIcon from '@mui/icons-material/UpdateOutlined'

import { type FullMove } from '../../../modules/adminMoves'
import { useTimeFormatter } from '../../../utils/time'
import { mergeClassName } from '../../../utils/mergeClassName'
import Paper from '../../Paper'
import MoveTimeline from '../../move/MoveTimeline'

type MoveInfo = Pick<FullMove, 'originTimezone' | 'timeline' | 'moveLeg' | 'moveDurationSeconds' | 'stops'>

type QuotesSidebarProps = {
  move: MoveInfo
  className?: string
}

const QuotesSidebar: React.FC<QuotesSidebarProps> = ({
  move,
  className,
}) => {
  const { t } = useTranslation()
  const { formatSeconds } = useTimeFormatter()
  if (!move.timeline ||
    !move.moveLeg ||
    !move.moveDurationSeconds) {
    return null
  }

  return (
    <Paper className={mergeClassName(
      'mb-0 shrink-0 child:p-0 lg:w-[300px] lg:pb-0 child:lg:p-0 2xl:w-[400px]',
      className,
    )}
    >
      <div className="mb-4 px-4 py-2 text-center font-body2 shadow-md">
        { t('pages.admin.quotes.sidebar.moveDetails.title') }
      </div>
      <div className="p-2 text-center lg:px-6 lg:py-4 lg:text-left">

        <div className="flex flex-col gap-6">
          <div>
            <div className="font-semibold text-gray-500">
              <MarkerIcon />
              { ' ' }
              { t('pages.admin.quotes.sidebar.moveDetails.moveDistance') }
              { ' ' }
              <span className="text-sm font-normal">(
                { t('pages.admin.quotes.sidebar.moveDetails.originToDestination') }
                )
              </span>
            </div>
            <div className="text-gray-400 lg:pl-6">
              <strong>
                { (move.moveLeg.distanceMeters / 1000).toFixed(1) } { t('units.km') }
              </strong>
              { ' ' }
              ({ t('time.timeWithTraffic', {
                time: formatSeconds(
                  move.moveLeg.durationInTrafficSeconds ?? move.moveLeg.durationSeconds,
                ),
              }) })
            </div>
          </div>

          <div>
            <div className="font-semibold text-gray-500">
              <DurationIcon />
              { ' ' }
              { t('pages.admin.quotes.sidebar.moveDetails.totalMoveDuration') }
            </div>
            <div className="text-gray-400 lg:pl-6">
              <strong>
                { formatSeconds(move.moveDurationSeconds) }
              </strong>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-px mt-4 border-t px-4 py-2 text-center font-body2 shadow-md">
        { t('pages.admin.quotes.sidebar.timeline.title') }
      </div>

      { move.timeline && (
        <div className="mb-4">
          <MoveTimeline
            move={move}
          />
        </div>
      ) }

    </Paper>
  )
}

export default QuotesSidebar
