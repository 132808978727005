import { useMemo } from 'react'
import { useQuery } from '@apollo/client'

import { type QueryResult } from '../../../graphQl'
import { useAuthenticationState, useCurrentUser } from '..'
import { getPermissionsInRoles, hasPermission } from './permissions.util'
import * as queries from './permissions.queries'
import { type Role } from './permissions.models'

/**
 * returns all roles
 */
export const useRoles = ({ reload = false, skip = false, ...queryArgs } = {}): QueryResult<Role[] | undefined> => {
  const { isAuthenticated } = useAuthenticationState()
  skip = skip || !isAuthenticated

  const fetchPolicy = reload ? 'network-only' : 'cache-first'

  const { data, networkStatus, loading, error, ...queryProps } = useQuery(
    queries.getRoles,
    {
      skip,
      fetchPolicy,
      ...queryArgs,
    },
  )

  return {
    data: data?.getRoles,
    loading: (loading && networkStatus === 1),
    error,
    ...queryProps,
  }
}

export const usePermission = (permission: string) => {
  const { data: user } = useCurrentUser()
  const { data: roles = [] } = useRoles()
  const userRoles = user?.roles
  const permissions = useMemo(() => getPermissionsInRoles(roles, userRoles ?? []), [roles, userRoles])

  return hasPermission(permissions, permission)
}
