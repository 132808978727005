import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import EditIcon from '@mui/icons-material/Edit'
import TruckIcon from '@mui/icons-material/LocalShippingOutlined'

import { type FullMove } from '../../../../../modules/adminMoves'
import { useMoveState } from '../../../../../modules/moves'
import Paper from '../../../../../components/Paper'
import JobDetails from '../../../../../components/move/JobDetails'
import EditJobModal from './EditJobModal'

type JobBoxProps = {
  move: FullMove
}

const JobBox: React.FC<JobBoxProps> = ({
  move,
}) => {
  const { t } = useTranslation()
  const { canEditJobs } = useMoveState().forMove(move)

  const [editing, setEditing] = useState(false)

  return (
    <>
      <EditJobModal
        move={move}
        open={editing}
        onClose={() => { setEditing(false) }}
      />
      <Paper className="rounded-tl-none">
        <div className="mb-4">

          <div className="flex justify-between">
            <h2 className="mb-4 font-body2 text-2xl font-semibold text-slate-700">
              <TruckIcon />
              { ' ' }
              { t('pages.admin.move.details.jobs.job.moveDetails.title') }
            </h2>

            <div>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<EditIcon />}
                onClick={() => { setEditing(true) }}
                disabled={!canEditJobs}
                className="!normal-case"
              >
                { t('actions.edit') }
              </Button>
            </div>
          </div>

          <JobDetails move={move} />
        </div>
      </Paper>
    </>
  )
}

export default JobBox
