import { useMemo } from 'react'

import {
  type DashboardMetricsRevenueCollection,
  fillDays,
  fillMonths,
  getCountValue,
  getMetricCollectionSum,
} from '../../../../modules/dashboard'

/**
 * calculate all information needed for revenue
 */
export const useRevenue = (rawData?: DashboardMetricsRevenueCollection, monthlyView = false) => {
  const length = monthlyView ? 30 : 13

  const revenueHistory = useMemo(() => {
    if (!rawData) {
      return
    }

    const data = monthlyView ? fillDays(rawData, length) : fillMonths(rawData, length)

    return data.map(({ label, count, revenue }) => ({
      label,
      value: revenue,
      count,
    }))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData])

  if (!revenueHistory) {
    return
  }

  const countHistory = getCountValue(revenueHistory)
  const totalRevenue = getMetricCollectionSum(revenueHistory)
  const count = getMetricCollectionSum(countHistory)
  const average = count === 0 ? 0 : totalRevenue / count

  return {
    revenueHistory,
    countHistory,
    totalRevenue,
    count,
    average,
  }
}

export type Revenue = ReturnType<typeof useRevenue>
