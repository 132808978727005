import { useTranslation } from 'react-i18next'

import {
  type FullMove,
  type MovePayload,
  useUpdateMoveAction,
} from '../../../../../modules/adminMoves'
import { Modal, ModalDescription, ModalHeader, ModalTitle } from '../../../../../components/Modal'
import { errorNotification, successNotification } from '../../../../../components/ToastNotifications'
import { useErrorFormatter } from '../../../../../components/errors/useErrorFormatter'
import { EditMoveForm } from '../../../../../components/forms/moves/moveForm'

type EditJobModalProps = {
  move: FullMove
  open: boolean
  onClose: () => void
}

const EditJobModal: React.FC<EditJobModalProps> = ({
  move,
  open,
  onClose,
}) => {
  const { t } = useTranslation()
  const { updateMove } = useUpdateMoveAction()
  const formatError = useErrorFormatter()

  const onSubmit = async (payload: Omit<MovePayload, 'user'>) => {
    try {
      await updateMove(move.id, payload)
      successNotification(t('pages.admin.move.details.jobs.editJobModal.success'))
      onClose()
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="lg:max-w-screen-3xl"
    >
      <ModalHeader>
        <ModalTitle>
          { t('pages.admin.move.details.jobs.editJobModal.title') }
        </ModalTitle>
      </ModalHeader>
      <ModalDescription>

        <EditMoveForm
          onSubmit={onSubmit}
          onCancel={onClose}
          data={move}
        />

      </ModalDescription>
    </Modal>
  )
}

export default EditJobModal
