import ChevronDownIcon from '@heroicons/react/24/outline/ChevronDownIcon'

import {
  useCurrentCompany,
  useCurrentCompanyBranchName,
  useOnBehalfState,
} from '../../../modules/companies'
import { useTranslation } from 'react-i18next'
import { Permission, usePermission } from '../../../modules/users'

const CurrentCompanyBranch: React.FC = () => {
  const { t } = useTranslation()
  const currentBranchName = useCurrentCompanyBranchName()
  const { data: currentCompany } = useCurrentCompany()
  const canAccessPartnerDashboard = usePermission(Permission.canManageCompanies)

  let { onBehalf } = useOnBehalfState()
  onBehalf &&= canAccessPartnerDashboard

  if (!onBehalf) {
    // since we only have one branch per company, hide this for partners
    return null
  }

  const hasMultipleBranches = (currentCompany?.branches?.length ?? 0) > 1

  return (
    <div className="flex min-h-[45px] items-center justify-between bg-zinc-800 text-white">
      <div className="flex grow justify-center gap-2 truncate font-body2 text-sm font-light uppercase lg:justify-start lg:pl-6">
        <div className="max-w-[calc(100vw-60px)] truncate lg:max-w-[calc(100vw-120px)]">
          { onBehalf && <span className="pr-2 text-xs lowercase">{ t('header.onBehalfOf') }</span> }
          { currentBranchName }
        </div>
        { hasMultipleBranches && <ChevronDownIcon className="w-[14px]" /> }
      </div>
    </div>
  )
}

export default CurrentCompanyBranch
