import { type Quote } from '../quotes.models'
import { type QuotesViewHandler } from '../quotes.types'

const sort = (quotes: Quote[]) => {
  return quotes
}

const viewHandler: QuotesViewHandler = {
  sort,
}

export default viewHandler
