import { isPast } from 'date-fns/isPast'
import { addMilliseconds } from 'date-fns/addMilliseconds'

import { useConfig } from '../../../modules/config'
import { JobRequestStatus, type LightJobRequest } from '../../../modules/jobRequests'
import { mergeClassName } from '../../../utils/mergeClassName'
import TimeAgo from '../../TimeAgo'
import { JobRequestStatusTag } from '..'

type StatusProps = {
  jobRequest: LightJobRequest
  compact?: boolean
}

const Status: React.FC<StatusProps> = ({
  jobRequest,
  compact = false,
}) => {
  const jobRequestTtl = useConfig<number>('jobRequests.ttl')

  const expireAt = addMilliseconds(new Date(jobRequest.createdAt), jobRequestTtl)

  return (
    <div className={mergeClassName(
      'text-center lg:min-w-[250px] lg:text-left',
      compact && 'lg:min-w-[200px]',
    )}
    >
      <JobRequestStatusTag
        jobRequestStatus={jobRequest.status}
      />
      { !compact && jobRequest.status === JobRequestStatus.Pending && !isPast(expireAt) && (
        <div className="ml-1 mt-1 text-sm text-neutral-400">
          Expire: <TimeAgo date={expireAt} />
        </div>
      ) }
    </div>
  )
}

export default Status
