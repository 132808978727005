import { useMemo } from 'react'
import { type Interval } from 'date-fns'

import {
  type CompanyBranch,
  useRuleCalendarDataBuilder,
} from '../../../modules/companies'
import {
  type AvailabilityRule,
  type PricingRule,
} from '../../../modules/companies/core/availabilityAndPricing/availabilityAndPricing.models'
import RuleCalendar from '../../../components/calendar/RuleCalendar/RuleCalendar'

type CalendarProps = {
  companyBranch: CompanyBranch
  availabilityRules: AvailabilityRule[]
  pricingRules: PricingRule[]
  showAvailabilities: boolean
  showRules: boolean
  currentPeriod: Interval
  defaultDate?: Date
  onSelectDates?: (start: Date, end?: Date) => void
  onSelectRule?: (id: string) => void
  onPeriodChange?: (period: Interval) => void
}

const Calendar: React.FC<CalendarProps> = ({
  companyBranch,
  availabilityRules,
  pricingRules,
  showAvailabilities,
  showRules,
  currentPeriod,
  defaultDate,
  onSelectDates,
  onSelectRule,
  onPeriodChange,
}) => {
  const buildCalendarData = useRuleCalendarDataBuilder()

  const [calendarAvailabilities, calendarRules, calendarContent] = useMemo(() => {
    return buildCalendarData({
      companyBranch,
      period: currentPeriod,
      availabilityRules,
      pricingRules,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPeriod, availabilityRules, pricingRules])

  return (
    <RuleCalendar
      rules={calendarRules}
      availabilities={calendarAvailabilities}
      content={calendarContent}
      showRules={showRules}
      showAvailabilities={showAvailabilities}
      defaultDate={defaultDate}
      onSelectDates={onSelectDates}
      onSelectRule={onSelectRule}
      onPeriodChange={onPeriodChange}
    />
  )
}

export default Calendar
