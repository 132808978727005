import { useMemo } from 'react'
import Avatar, { type AvatarProps } from '@mui/material/Avatar'
import md5 from 'md5'

import { type User, useCurrentUser } from '../../../modules/users'

type ProfileAvatarProps = {
  user?: User
  size?: number
  showLetters?: boolean
} & Omit<AvatarProps, 'size'>

const ProfileAvatar: React.FC<ProfileAvatarProps> = ({
  user,
  size = 40,
  showLetters = true,
  ...avatarProps
}) => {
  const { data: currentUser } = useCurrentUser()

  if (!user) {
    user = currentUser
  }

  const photoUrl = useMemo(() => {
    if (!user?.email) {
      return
    }
    if (user?.photo) {
      return user.photo
    }
    const hash = md5(user.email.trim().toLowerCase())
    return `//www.gravatar.com/avatar/${String(hash)}?default=404&size=${size}`
  }, [
    user?.photo,
    user?.email,
    size,
  ])

  const userInitials = useMemo(() => {
    if (!user?.firstName) {
      return
    }
    return String(user?.firstName).substring(0, 1).toLocaleUpperCase()
  }, [
    user?.firstName,
  ])

  return (
    <Avatar
      src={photoUrl}
      sx={{ width: size, height: size }}
      {...avatarProps}
      alt={showLetters ? userInitials : undefined}
    />
  )
}

export default ProfileAvatar
