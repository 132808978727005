import { I18nextProvider } from 'react-i18next'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ApolloProvider } from '@apollo/client'
import CssBaseline from '@mui/material/CssBaseline'
import { BrowserRouter, Routes, Route as ReactRouterRoute } from 'react-router-dom'

import MuiDatePickerProvider from './MuiDatePickerProvider'
import store, { persistor } from '../store'
import i18n from '../modules/i18n'
import { ThemeProvider } from '../modules/theme'
import client from '../modules/graphQl/core/client'
import { ScrollRestauration } from '../modules/navigation'
import { SessionExtender } from '../modules/users'
import { GoogleAnalytics } from '../modules/analytics'
import { GoogleMapProvider } from '../modules/googleMaps'
import ErrorBoundary from '../components/errors/ErrorBoundary'
import Route from './Route.enum'
import AppGate from './AppGate'
import { getRelativeRoute } from '../utils/routing/routing'
import { ToastContainer } from '../components/ToastNotifications'
import { Error403Page, Error404Page, Error500Page } from '../pages/errors'
import DashboardPage from '../pages/dashboard'
import TermsOfUsePage from '../pages/termsOfUse'
import PrivacyPolicyPage from '../pages/privacyPolicy'
import {
  RegisterPage,
  EmailVerificationPage,
  LoginPage,
  LoginMfaPage,
  LoginErrorPage,
  ResetPasswordRequestPage,
  ResetPasswordPage,
  ProfilePage,
  IdentificationPage,
  SecurityPage,
  DeleteAccountPage,
  PartnerInvitationPage,
} from '../pages/users'
import { EditCompanyPage } from '../pages/company'
import {
  CompanyBranchSettingsLandingPage,
  CompanyBranchSettingsPage,
  CompanyBranchSettingsLegalPage,
  CompanyBranchSettingsServicesPage,
  CompanyBranchSettingsSeasonsPage,
  CompanyBranchSettingsLabourPage,
  CompanyBranchSettingsTripAndTravelPage,
  CompanyBranchSettingsAdditionalChargesPage,
  CompanyBranchSettingsProtectiveMaterialPage,
  CompanyBranchSettingsCommunicationPage,
  CompanyBranchAvailabilityLandingPage,
  CompanyBranchAvailabilityPage,
  CompanyBranchSettingsCompletedPage,
  JobRequestsLandingPage,
  JobRequestsPage,
  JobRequestPage,
  JobsLandingPage,
  JobsPage,
  JobPage,
  CloseJobPage,
} from '../pages/companyBranch'
import {
  UsersPage,
  UserPage,
  MovesPage,
  MovePage,
  CreateMovePage,
  CompaniesPage,
  CreateCompanyPage,
  CompaniesBranchesMapPage,
  GetQuotesPage,
  ProspectsPage,
  ProspectPage,
  EditProspectPage,
  PromoCodesPage,
  CreatePromoCodePage,
  EditPromoCodePage,
  AccountingPage,
} from '../pages/admin'
import LanguagePersister from './LanguagePersister'
import CompanyWrapper from '../pages/company/CompanyWrapper'

const App: React.FC = () => {
  return (
    <GoogleMapProvider>
      <I18nextProvider i18n={i18n}>
        <MuiDatePickerProvider>
          <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              { () => (
                <ThemeProvider>
                  <ApolloProvider client={client}>
                    <CssBaseline />

                    <BrowserRouter>
                      <ErrorBoundary>
                        <AppGate>
                          <Routes>
                            <ReactRouterRoute path={Route.Dashboard} element={<DashboardPage />} />
                            <ReactRouterRoute path={Route.TermsOfUse} element={<TermsOfUsePage />} />
                            <ReactRouterRoute path={Route.PrivacyPolicy} element={<PrivacyPolicyPage />} />

                            <ReactRouterRoute path={Route.SignUp} element={<RegisterPage />} />
                            <ReactRouterRoute path={Route.EmailVerification} element={<EmailVerificationPage />} />
                            <ReactRouterRoute path={Route.Login} element={<LoginPage />} />
                            <ReactRouterRoute path={Route.LoginMfa} element={<LoginMfaPage />} />
                            <ReactRouterRoute path={Route.LoginError} element={<LoginErrorPage />} />
                            <ReactRouterRoute path={Route.ResetPasswordRequest} element={<ResetPasswordRequestPage />} />
                            <ReactRouterRoute path={Route.ResetPassword} element={<ResetPasswordPage />} />

                            <ReactRouterRoute path={Route.PartnerInvitation} element={<PartnerInvitationPage />} />

                            <ReactRouterRoute path={Route.Profile} element={<ProfilePage />}>
                              <ReactRouterRoute
                                path={getRelativeRoute(Route.ProfileIdentification, Route.Profile)}
                                element={<IdentificationPage />}
                              />
                              <ReactRouterRoute
                                path={getRelativeRoute(Route.ProfileSecurity, Route.Profile)}
                                element={<SecurityPage />}
                              />
                              <ReactRouterRoute
                                path={getRelativeRoute(Route.ProfileDeleteAccount, Route.Profile)}
                                element={<DeleteAccountPage />}
                              />
                            </ReactRouterRoute>

                            <ReactRouterRoute path={Route.BranchSettingsLandingPage} element={<CompanyBranchSettingsLandingPage />} />
                            <ReactRouterRoute path={Route.BranchAvailabilityLandingPage} element={<CompanyBranchAvailabilityLandingPage />} />
                            <ReactRouterRoute path={Route.JobRequestsLandingPage} element={<JobRequestsLandingPage />} />
                            <ReactRouterRoute path={Route.JobsLandingPage} element={<JobsLandingPage />} />

                            <ReactRouterRoute path={Route.Companies} element={<CompanyWrapper />}>
                              <ReactRouterRoute path="" element={<CompaniesPage />} />
                              <ReactRouterRoute path={Route.CreateCompany} element={<CreateCompanyPage />} />
                              <ReactRouterRoute path={Route.CompaniesMap} element={<CompaniesBranchesMapPage />} />

                              <ReactRouterRoute path={Route.CompaniesOnBehalf}>
                                <ReactRouterRoute path="" element={<DashboardPage />} />

                                <ReactRouterRoute
                                  path={getRelativeRoute(Route.EditCompany, Route.CompaniesOnBehalf)}
                                  element={<EditCompanyPage />}
                                />

                                <ReactRouterRoute
                                  path={getRelativeRoute(Route.Branches, Route.CompaniesOnBehalf)}
                                >
                                  <ReactRouterRoute
                                    path={getRelativeRoute(Route.BranchSettings, Route.Branches)}
                                    element={<CompanyBranchSettingsPage />}
                                  >
                                    <ReactRouterRoute
                                      path={getRelativeRoute(Route.BranchSettingsLegal, Route.BranchSettings)}
                                      element={<CompanyBranchSettingsLegalPage />}
                                    />
                                    <ReactRouterRoute
                                      path={getRelativeRoute(Route.BranchSettingsServices, Route.BranchSettings)}
                                      element={<CompanyBranchSettingsServicesPage />}
                                    />
                                    <ReactRouterRoute
                                      path={getRelativeRoute(Route.BranchSettingsSeasons, Route.BranchSettings)}
                                      element={<CompanyBranchSettingsSeasonsPage />}
                                    />
                                    <ReactRouterRoute
                                      path={getRelativeRoute(Route.BranchSettingsLabour, Route.BranchSettings)}
                                      element={<CompanyBranchSettingsLabourPage />}
                                    />
                                    <ReactRouterRoute
                                      path={getRelativeRoute(Route.BranchSettingsTripAndTravel, Route.BranchSettings)}
                                      element={<CompanyBranchSettingsTripAndTravelPage />}
                                    />
                                    <ReactRouterRoute
                                      path={getRelativeRoute(Route.BranchSettingsAdditionalCharges, Route.BranchSettings)}
                                      element={<CompanyBranchSettingsAdditionalChargesPage />}
                                    />
                                    <ReactRouterRoute
                                      path={getRelativeRoute(Route.BranchSettingsProtecticeMaterial, Route.BranchSettings)}
                                      element={<CompanyBranchSettingsProtectiveMaterialPage />}
                                    />
                                    <ReactRouterRoute
                                      path={getRelativeRoute(Route.BranchSettingsCommunication, Route.BranchSettings)}
                                      element={<CompanyBranchSettingsCommunicationPage />}
                                    />
                                    <ReactRouterRoute
                                      path={getRelativeRoute(Route.BranchSettingsCompleted, Route.BranchSettings)}
                                      element={<CompanyBranchSettingsCompletedPage />}
                                    />
                                  </ReactRouterRoute>

                                  <ReactRouterRoute
                                    path={getRelativeRoute(Route.BranchAvailability, Route.Branches)}
                                    element={<CompanyBranchAvailabilityPage />}
                                  />

                                  <ReactRouterRoute
                                    path={getRelativeRoute(Route.JobRequests, Route.Branches)}
                                    element={<JobRequestsPage />}
                                  />
                                  <ReactRouterRoute
                                    path={getRelativeRoute(Route.JobRequest, Route.Branches)}
                                    element={<JobRequestPage />}
                                  />

                                  <ReactRouterRoute
                                    path={getRelativeRoute(Route.Jobs, Route.Branches)}
                                    element={<JobsPage />}
                                  >
                                    <ReactRouterRoute
                                      path={getRelativeRoute(Route.Job, Route.Jobs)}
                                      element={<JobPage />}
                                    />
                                  </ReactRouterRoute>

                                  <ReactRouterRoute
                                    path={getRelativeRoute(Route.CloseJob, Route.Branches)}
                                    element={<CloseJobPage />}
                                  />

                                </ReactRouterRoute>

                              </ReactRouterRoute>
                            </ReactRouterRoute>

                            <ReactRouterRoute path={Route.AdminUsers} element={<UsersPage />} />
                            <ReactRouterRoute path={Route.AdminUser} element={<UserPage />} />

                            <ReactRouterRoute path={Route.AdminMoves} element={<MovesPage />} />
                            <ReactRouterRoute path={Route.AdminCreateMove} element={<CreateMovePage />} />
                            <ReactRouterRoute path={Route.AdminMove} element={<MovePage />} />

                            <ReactRouterRoute path={Route.GetQuotes} element={<GetQuotesPage />} />

                            <ReactRouterRoute path={Route.Prospects} element={<ProspectsPage />} />
                            <ReactRouterRoute path={Route.Prospect} element={<ProspectPage />} />
                            <ReactRouterRoute path={Route.EditProspect} element={<EditProspectPage />} />

                            <ReactRouterRoute path={Route.PromoCodes} element={<PromoCodesPage />} />
                            <ReactRouterRoute path={Route.CreatePromoCode} element={<CreatePromoCodePage />} />
                            <ReactRouterRoute path={Route.EditPromoCode} element={<EditPromoCodePage />} />

                            <ReactRouterRoute path={Route.Accounting} element={<AccountingPage />} />

                            <ReactRouterRoute path="/403" element={<Error403Page />} />
                            <ReactRouterRoute path="/500" element={<Error500Page />} />
                            <ReactRouterRoute path="*" element={<Error404Page />} />
                          </Routes>

                          <LanguagePersister />
                        </AppGate>

                        <GoogleAnalytics />
                        <SessionExtender />
                        <ScrollRestauration />
                        <ToastContainer />
                      </ErrorBoundary>
                    </BrowserRouter>
                  </ApolloProvider>
                </ThemeProvider>
              ) }
            </PersistGate>
          </ReduxProvider>
        </MuiDatePickerProvider>
      </I18nextProvider>
    </GoogleMapProvider>
  )
}

export default App
