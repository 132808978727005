import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'

import {
  Form,
  SubmitButton,
} from '../../../form'
import useValidationSchema from './validationSchema'
import { type EditMoveCustomerFormValues } from './EditMoveCustomerForm.type'
import { type MovePayload } from '../../../../modules/adminMoves'
import MoveFormCustomerFormContent from '../moveForm/MoveFormCustomerFormContent'

type EditMoveCustomerFormProps = {
  onSubmit: (payload: Pick<MovePayload, 'user'>) => Promise<any>
  onCancel?: () => void
  data: Pick<MovePayload, 'user'>
}

const EditMoveCustomerForm: React.FC<EditMoveCustomerFormProps> = ({
  onSubmit,
  onCancel,
  data,
}) => {
  const { t } = useTranslation()

  const schema = useValidationSchema()
  const form = useForm<EditMoveCustomerFormValues>({
    resolver: yupResolver(schema),
    defaultValues: data,
  })

  return (
    <Form form={form} onSubmit={onSubmit}>

      <MoveFormCustomerFormContent />

      <div className="mt-8 flex justify-end gap-3">
        { onCancel && (
          <Button
            variant="contained"
            color="secondary"
            onClick={onCancel}
          >
            { t('actions.cancel') }
          </Button>
        ) }
        <SubmitButton size="large">
          { t('actions.update') }
        </SubmitButton>
      </div>
    </Form>
  )
}

export default EditMoveCustomerForm
