import * as Yup from 'yup'

import { type AvailabilityRuleFormValues } from './AvailabilityRuleForm.type'
import useTemporalityValidationSchema from '../TemporalityForm/validationSchema'

const useValidationSchema = () => {
  const temporalityValidation = useTemporalityValidationSchema()

  return Yup.object({
    temporality: temporalityValidation,
  }) as Yup.ObjectSchema<AvailabilityRuleFormValues, AvailabilityRuleFormValues>
}

export default useValidationSchema
