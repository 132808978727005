import { mergeClassName } from '../../utils/mergeClassName'

type ModalContentProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const ModalContent: React.FC<ModalContentProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={mergeClassName(
        'fixed w-full grid gap-4 rounded-b-lg border bg-background py-6 shadow-lg animate-in data-[state=open]:fade-in-90 data-[state=open]:slide-in-from-bottom-10 max-w-[95vw] md:max-w-[768px] lg:max-w-[1024px] sm:rounded-lg sm:zoom-in-90 data-[state=open]:sm:slide-in-from-bottom-0 max-h-[calc(100dvh-2rem)] cursor-default',
        className,
      )}
      {...props}
    >
      { children }
    </div>
  )
}

export default ModalContent
