import { type User, type UpdateUserPayload } from '../../../../modules/users'

export const userToFormData = (user?: User): Partial<UpdateUserPayload> => {
  if (!user) {
    return {}
  }
  return {
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNumber: {
      number: user.phoneNumber.number,
      extension: user.phoneNumber.extension,
    },
    position: user.position,
  }
}

export const formatFormOutput = (formData: UpdateUserPayload): UpdateUserPayload => {
  return {
    firstName: formData.firstName,
    lastName: formData.lastName,
    phoneNumber: formData.phoneNumber,
    position: formData.position,
  }
}
