import { useTranslation } from 'react-i18next'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

import Spinner from '../../../components/Spinner'
import ServerError from '../../../components/errors/ServerError'
import ContainedPage from '../../../components/layout/ContainedPage'
import PageContent from '../../../components/layout/PageContent'
import PageTitle from '../../../components/layout/PageTitle'
import { useNavigate, useParams } from '../../../modules/navigation'
import { useProspect, useUpdateProspectAction } from '../../../modules/adminProspects/core/prospects.hooks'
import { protectAdminPage } from '../../../modules/users'
import { validateUuid } from '../../../utils/uuid'
import { Error404Page } from '../../errors'
import Route from '../../../app/Route.enum'
import Link from '../../../components/Link'
import { capitalizeFirstLetter } from '../../../utils/string'
import ProspectForm from '../../../components/forms/prospects/ProspectForm'
import { QuotesSpinner, QuotesTopBar } from '../../../components/quotesLegacy'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import { type ProspectPayload } from '../../../modules/adminProspects'
import { errorNotification, successNotification } from '../../../components/ToastNotifications'

const EditProspectPage: React.FC = () => {
  const { t } = useTranslation()
  const { prospectId } = useParams()
  const { navigate } = useNavigate()
  const validId = validateUuid(prospectId)
  const { data: prospect, loading, error } = useProspect(prospectId ?? '', { skip: !validId })
  const { updateProspect, loading: updatingProspect } = useUpdateProspectAction()
  const formatError = useErrorFormatter()

  const onSubmit = async (payload: ProspectPayload) => {
    if (!prospectId) {
      return
    }
    try {
      await updateProspect(prospectId, payload)
      successNotification('Prospect updated')
      navigate(Route.Prospect, { prospectId })
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  if (!validId || !prospectId) {
    return <Error404Page />
  }

  if (error) {
    return (
      <ContainedPage>
        <ServerError error={error} />
      </ContainedPage>
    )
  }

  if (loading || !prospect) {
    return <ContainedPage><Spinner /></ContainedPage>
  }

  return (
    <ContainedPage>
      <PageTitle className="lg:mb-0">
        <Link to={Route.Prospect} params={{ prospectId }} className="hidden lg:block">
          <div className="ml-[-5px] text-base/loose underline">
            <ChevronLeftIcon /> { t('actions.goBack') }
          </div>
        </Link>
        { capitalizeFirstLetter(prospect.name) }
      </PageTitle>

      <QuotesTopBar
        quotesRequest={prospect.request}
      />

      <PageContent className="px-3 py-8 pr-4">

        { updatingProspect
          ? <QuotesSpinner />
          : (
            <ProspectForm
              data={prospect}
              onSubmit={onSubmit}
              onCancel={() => { navigate(Route.Prospect, { prospectId }) }}
            />
            ) }

      </PageContent>
    </ContainedPage>
  )
}

export default protectAdminPage(EditProspectPage)
