import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { type CompanyBranch, useUpdateCompanyBranchAction } from '../../../modules/companies'
import { useNavigate } from '../../../modules/navigation'
import { type Step, steps, useNextStep } from '../../companyBranch'
import { errorNotification, successNotification } from '../../ToastNotifications'
import { useErrorFormatter } from '../../errors/useErrorFormatter'
import Route from '../../../app/Route.enum'

type UseSubmitActionProps = {
  step: Step
  initialData?: CompanyBranch
}

export const useSubmitAction = <T,>({ step, initialData }: UseSubmitActionProps) => {
  const { t } = useTranslation()
  const { navigate } = useNavigate()
  const setupProgress = useRef(initialData?.setupProgress ?? 0)
  const { updateCompanyBranch } = useUpdateCompanyBranchAction()
  const formatError = useErrorFormatter()
  const nextStep = useNextStep()

  const setupInProgress = (setupProgress.current) < 100
  const branchProperty = steps[step].branchProperty

  return async (values: T) => {
    try {
      const updatedBranch = await updateCompanyBranch({ [branchProperty]: values })
      const newCompletionValue = updatedBranch?.setupProgress ?? 0

      successNotification(t('forms.companyBranch.success'))

      if (setupInProgress) {
        if (newCompletionValue === 100) {
          navigate(Route.BranchSettingsCompleted)
          return
        }
        if (nextStep?.route) {
          navigate(nextStep.route)
        }
      }
    } catch (error) {
      errorNotification(formatError(error))
    }
  }
}
