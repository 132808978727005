import { mergeClassName } from '../../utils/mergeClassName'

type ReactTr = React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>

export type RowProps = {
  disableHover?: boolean
  borderLess?: boolean
} & ReactTr

const Row: React.FC<RowProps> = ({
  className,
  children,
  disableHover = false,
  borderLess = false,
  ...props
}) => {
  return (
    <tr
      className={mergeClassName(
        !disableHover && 'hover:bg-gray-50',
        !borderLess && 'border-b',
        className,
      )}
      {...props}
    >
      { children }
    </tr>
  )
}

export default Row
