import { useConfig } from '../../modules/config'

// generated with : https://www.gdprprivacypolicy.net/

const FrenchContent: React.FC = () => {
  const appFriendlyUrl = useConfig('app.appFriendlyUrl')
  const fullCompanyName = useConfig('app.fullCompanyName')

  return (
    <>
      <h2>Introduction</h2>
      <p>La mission de la plateforme partenaire de MovingWaldo (accessible au { appFriendlyUrl }) est d'aider les entreprises de déménagement à optimiser la réservation et la gestion de leurs services de déménagement et d'emballage ("vos services"), afin qu'elles puissent se concentrer à offrir un travail de déménagement de la meilleure qualité possible à des prix compétitifs pour les clients.</p>

      <h2>Comment ça fonctionne ?</h2>
      <p>{ fullCompanyName } se présente comme une place de marché pour les services de déménagement, offrant aux clients un endroit sûr pour réserver des déménageurs dignes de confiance à un prix équitable.</p>
      <p>Une fois que vous aurez créé un compte sur la plateforme partenaire de MovingWaldo et que vous aurez terminé le processus d'intégration et d'approbation, vous rejoindrez notre réseau exclusif d'entreprises de déménagement. Nous représenterons alors votre entreprise auprès de nos clients intéressés par des services de déménagement. </p>
      <p>Au cours du processus d'intégration, { fullCompanyName } recueillera des informations sur votre entreprise et les services que vous proposez. Entre autres, nous utiliserons les informations que vous nous fournissez pour calculer des devis de déménagement et d'emballage. Ces devis seront présentés à nos clients en votre nom.</p>
      <p>Si vous souhaitez obtenir des informations techniques supplémentaires, veuillez nous envoyer un message à l'adresse suivante : sales@movingwaldo.com.</p>

      <h2>Informations que nous partageons</h2>
      <p>Nous ne partageons pas les informations que nous recueillons publiquement ou avec nos clients, sauf dans l'une des circonstances suivantes :</p>
      <ol>
        <li>Pour implémenter
          <p>Afin de mettre en œuvre les services que nous offrons chez { fullCompanyName }, nous pouvons avoir besoin de partager les informations que nous avons collectées avec les clients. Dans de telles situations, nous ne pouvons partager que les informations nécessaires à la réalisation du service commandé. </p>
        </li>
        <li>Pour promouvoir votre entreprise
          <p>Afin de promouvoir votre entreprise et ses services auprès de notre clientèle, nous pouvons être amenés à partager les informations que nous avons recueillies. Ces informations peuvent être partagées sur nos sites web et ceux de nos partenaires, par courrier électronique ou par d'autres moyens de communication. </p>
        </li>
        <li>Pour promouvoir { fullCompanyName } et ses services.
          <p>{ fullCompanyName } peut utiliser les informations que vous fournissez, telles que votre logo ou les services que vous offrez, afin de promouvoir nos propres services et d'attirer de nouveaux clients sur notre place de marché. Nous pouvons le faire en publiant les informations relatives à votre entreprise sur notre site web, sur les sites web de nos partenaires, dans des publicités ou par le biais de tout autre canal de communication. </p>
        </li>
        <li>Pour des raisons juridiques
          <p>Nous partagerons vos informations personnelles avec des sociétés, des organisations ou des individus extérieurs à { fullCompanyName }. si nous pensons que cela est nécessaire pour : Respecter toute loi, réglementation, procédure légale ou demande gouvernementale applicable, ou pour détecter, prévenir ou traiter la fraude, la sécurité ou les problèmes techniques. </p>
        </li>
        <li>Les données agrégées
          <p>Nous pouvons partager publiquement des informations agrégées et non personnellement identifiables. Par exemple, nous pouvons partager des informations publiquement pour montrer des statistiques sur { fullCompanyName }, sur nos services, et sur l'industrie du déménagement. </p>
        </li>
      </ol>

      <h2>Informations que nous recueillons</h2>
      <p>Sans se limiter à cette liste, voici quelques informations dont nous pourrions avoir besoin pour fournir ces services :</p>
      <ul>
        <li>Nom et coordonnées générales du propriétaire légal de l'entreprise</li>
        <li>Nom légal de l'entreprise, son adresse et ses coordonnées</li>
        <li>Licence d'exploitation et enregistrement </li>
        <li>Numéro d'identification fiscale</li>
        <li>Certificat d'assurance</li>
        <li>Certificat d'accréditation spécifique</li>
        <li>Informations bancaires</li>
        <li>Listes de produits et de services et leurs prix et disponibilités respectifs</li>
        <li>Logo de l'entreprise et actifs de la marque</li>
        <li>Autres informations</li>
      </ul>

      <h2>Règlement général sur la protection des données (RGPD)</h2>
      <p>Nous sommes un contrôleur de données de vos informations.</p>

      <p>{ fullCompanyName } la base juridique pour la collecte et l'utilisation des informations personnelles décrites dans la présente politique de confidentialité dépend des informations personnelles que nous collectons et du contexte spécifique dans lequel nous collectons les informations :</p>
      <ul>
        <li>{ fullCompanyName } doit exécuter un contrat avec vous</li>
        <li>Vous avez autorisé { fullCompanyName } à le faire</li>
        <li>Le traitement de vos informations personnelles est dans l'intérêt légitime de { fullCompanyName }</li>
        <li>{ fullCompanyName } doit se conformer à la loi</li>
      </ul>

      <p>{ fullCompanyName } ne conservera vos informations personnelles que le temps nécessaire aux fins énoncées dans la présente politique de confidentialité. Nous conserverons et utiliserons vos informations dans la mesure nécessaire pour nous conformer à nos obligations légales, résoudre les litiges et appliquer nos politiques.</p>

      <p>Si vous résidez dans l'Espace économique européen (EEE), vous disposez de certains droits en matière de protection des données. Si vous souhaitez être informé des informations personnelles que nous détenons à votre sujet et si vous souhaitez qu'elles soient supprimées de nos systèmes, veuillez nous contacter.</p>

      <p>Dans certaines circonstances, vous disposez des droits suivants en matière de protection des données :</p>
      <ul>
        <li>Le droit d'accéder, de mettre à jour ou de supprimer les informations que nous avons sur vous.</li>
        <li>Le droit de rectification.</li>
        <li>Le droit d'opposition.</li>
        <li>Le droit de restriction.</li>
        <li>Le droit à la portabilité des données</li>
        <li>Le droit de retirer son consentement</li>
      </ul>

      <h2>Fichiers journaux</h2>

      <p>La plateforme partenaire de MovingWaldo suit une procédure standard d'utilisation des fichiers journaux. Ces fichiers enregistrent les visiteurs lorsqu'ils visitent des sites Web. Toutes les sociétés d'hébergement le font et font partie de l'analyse des services d'hébergement. Les informations collectées par les fichiers journaux incluent les adresses IP (protocole Internet), le type de navigateur, le fournisseur d'accès Internet (FAI), l'horodatage, les pages de renvoi/sortie et éventuellement le nombre de clics. Ceux-ci ne sont liés à aucune information personnellement identifiable. Le but de l'information est d'analyser les tendances, d'administrer le site, de suivre les mouvements des utilisateurs sur le site Web et de recueillir des informations démographiques.</p>

      <h2>Cookies et balises Web</h2>

      <p>Comme tout autre site Web, la plateforme partenaire de MovingWaldo utilise des "cookies". Ces cookies sont utilisés pour stocker des informations, y compris les préférences des visiteurs et les pages du site Web auxquelles le visiteur a accédé ou visité. Les informations sont utilisées pour optimiser l'expérience des utilisateurs en personnalisant le contenu de notre page Web en fonction du type de navigateur des visiteurs et/ou d'autres informations.</p>

      <h2>Informations sur les enfants</h2>

      <p>Une autre partie de notre priorité est d'ajouter une protection aux enfants lorsqu'ils utilisent Internet. Nous encourageons les parents et les tuteurs à observer, participer et/ou surveiller et guider leur activité en ligne.</p>

      <p>La plateforme partenaire de MovingWaldo ne collecte sciemment aucune information personnelle identifiable d'enfants de moins de 13 ans. Si vous pensez que votre enfant a fourni ce type d'informations sur notre site Web, nous vous encourageons vivement à nous contacter immédiatement et nous vous faisons de notre mieux pour supprimer rapidement ces informations de nos dossiers.</p>

      <h2>Politique de confidentialité en ligne uniquement</h2>

      <p>Notre politique de confidentialité s'applique uniquement à nos activités en ligne et est valable pour les visiteurs de notre site Web en ce qui concerne les informations qu'ils ont partagées et/ou collectées dans la plateforme partenaire de MovingWaldo. Cette politique ne s'applique pas aux informations collectées hors ligne ou via des canaux autres que ce site Web.</p>

      <h2>Consentement</h2>

      <p>En utilisant notre site Web, vous consentez par la présente à notre politique de confidentialité et acceptez ses conditions.</p>
    </>
  )
}

export default FrenchContent
