import { type DashboardMetricsRevenue } from '../../../../modules/dashboard'
import { type Revenue } from './useRevenue'

export const useRevenueDiff = (now?: Revenue, previous?: DashboardMetricsRevenue) => {
  if (
    !previous ||
    !now ||
    previous.revenue === undefined ||
    now.totalRevenue === undefined
  ) {
    return
  }

  const revenuePercent = !previous.revenue ? undefined : Math.round((now.totalRevenue / previous.revenue) * 100) - 100

  const countPercent = !previous.count ? undefined : Math.round((now.count / previous.count) * 100) - 100

  const previousAverage = !previous.count ? undefined : previous.revenue / previous.count

  const averagePercent = !previousAverage ? undefined : Math.round((now.average / previousAverage) * 100) - 100

  return {
    revenuePercent,
    countPercent,
    averagePercent,
  }
}
