import { type Currency } from '../../../../common/amount'
import { type Charge } from '../../../../modules/invoices'
import { type QuotePayload } from '../../../../modules/adminMoves'
import { type QuoteDetailFormValue } from './QuoteDetailsForm.type'
import { chargesToFormData, formatChargesFormOutput } from '../../invoice/ChargesFormContent'

export const quoteDetailsToFormData = (
  quoteDetails: Charge[],
): QuoteDetailFormValue => {
  return { ...chargesToFormData(quoteDetails) }
}

export const formatFormOutput = (data: QuoteDetailFormValue, currency: Currency): QuotePayload => ({
  details: formatChargesFormOutput(data, currency),
})
