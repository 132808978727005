import { useTranslation } from 'react-i18next'
import PaymentIcon from '@mui/icons-material/PaidOutlined'

import { type FullMove } from '../../../../../modules/adminMoves'
import Paper from '../../../../../components/Paper'
import PaymentsTable from './PaymentsTable'

type PaymentsBoxProps = {
  move: FullMove
}

const PaymentsBox: React.FC<PaymentsBoxProps> = ({
  move,
}) => {
  const { t } = useTranslation()

  return (
    <Paper>
      <h2 className="mb-4 flex items-center gap-x-2 font-body2 text-2xl font-semibold text-slate-700 ">
        <PaymentIcon />
        <div>
          { t('pages.admin.move.accounting.payments.title') }
        </div>
      </h2>

      <div className="mb-6">
        <PaymentsTable move={move} />
      </div>
    </Paper>
  )
}

export default PaymentsBox
