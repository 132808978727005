import { useTranslation } from 'react-i18next'

import Paper from '../../../../Paper'
import Stops from './Stops'
import LocationFields from './LocationFields'

type AddressesFormContentProps = {
  requireDetails?: boolean
  specificAddresses?: boolean
}

const AddressesFormContent: React.FC<AddressesFormContentProps> = ({
  requireDetails = false,
  specificAddresses = false,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <h2 className="mb-2 font-body2 text-lg font-bold lg:my-4">
        { t('forms.quotesRequest.addresses.title') }
      </h2>
      <Paper>

        <div className="flex flex-col gap-y-4">

          { ['origin', 'destination'].map(address => {
            const isOrigin = address === 'origin'
            const isDestination = address === 'destination'

            return (
              <div key={address}>
                <div className="mb-4 font-sans text-lg font-bold">
                  { isOrigin && t('forms.quotesRequest.origin.title') }
                  { isDestination && t('forms.quotesRequest.destination.title') }
                </div>

                <LocationFields
                  name={address}
                  requireDetails={requireDetails}
                  specificAddresses={specificAddresses}
                />

                { isOrigin && (
                  <Stops
                    requireDetails={requireDetails}
                    specificAddresses={specificAddresses}
                  />
                ) }
              </div>
            )
          }) }

        </div>
      </Paper>
    </>
  )
}

export default AddressesFormContent
