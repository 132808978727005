/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  type CompanyBranchLabour,
  type CompanyBranchPricing,
  pricingAsString,
  pricingAsNumber,
} from '../../../../modules/companies'
import { type LabourFormValues } from './LabourForm.type'

const pricingIntAsString = (pricing?: CompanyBranchPricing<number>) => {
  return pricingAsString(pricing, { asInteger: true })!
}

const pricingAsInt = (pricing?: CompanyBranchPricing<string>) => {
  return pricingAsNumber(pricing)!
}

export const companyBranchLabourToFormData = (companyBranchLabour: CompanyBranchLabour): LabourFormValues => {
  return {
    movingLabour: companyBranchLabour.movingLabour.map(pricingIntAsString),
    movingLabourSingleMan: pricingIntAsString(companyBranchLabour.movingLabourSingleMan),
    movingLabourExtraMen: pricingIntAsString(companyBranchLabour.movingLabourExtraMen),
    movingLabourSupportSingleMan: !!companyBranchLabour?.movingLabourSingleMan?.basePrice,

    packingLabour: companyBranchLabour.packingLabour?.map(pricingIntAsString),
    packingLabourSingleMan: pricingIntAsString(companyBranchLabour.packingLabourSingleMan),
    packingLabourExtraMen: pricingIntAsString(companyBranchLabour.packingLabourExtraMen),
    packingLabourSupportSingleMan: !!companyBranchLabour?.packingLabourSingleMan?.basePrice,

    minLabourTime: String(companyBranchLabour.minLabourTime),
  }
}

export const formatFormOutput = (formData: LabourFormValues): CompanyBranchLabour => {
  const movingLabour = formData.movingLabour.filter(({ basePrice }) => !!basePrice)
  const packingLabour = formData.packingLabour?.filter(({ basePrice }) => !!basePrice)
  return {
    movingLabour: movingLabour.map(pricingAsInt),
    movingLabourSingleMan: pricingAsInt(formData.movingLabourSingleMan),
    movingLabourExtraMen: pricingAsInt(formData.movingLabourExtraMen),

    packingLabour: packingLabour?.map(pricingAsInt),
    packingLabourSingleMan: pricingAsInt(formData.packingLabourSingleMan),
    packingLabourExtraMen: pricingAsInt(formData.packingLabourExtraMen),

    minLabourTime: parseFloat(formData.minLabourTime),
  }
}
