import { useTranslation } from 'react-i18next'

import { type LightJob } from '../../../modules/jobs'
import { calculateNumberOfTrucks } from '../../../modules/quotes'
import { capitalizeFirstLetter } from '../../../utils/string'

type JobEntryProps = {
  job: LightJob
}

const JobEntry: React.FC<JobEntryProps> = ({
  job,
}) => {
  const { t } = useTranslation()

  return (
    <div
      className="-mx-2 mt-2 h-full min-w-[250px] overflow-hidden bg-white/15 px-2 pt-2 font-sans text-base leading-tight "
    >

      <ul className="[&>li]:my-1">
        <li>
          <div className="pb-1 font-bold">
            { t('nbMen', { count: job.nbMen }) }
            { ' + ' }
            { t('nbTrucks', { count: calculateNumberOfTrucks(job.nbTrucks) }) }
          </div>
        </li>

        <li className="pb-1">
          { t(`residenceType.${job.residenceType}.title`, { defaultValue: job.residenceType }) }
          { ' ' }
          ({ job.volumeCubicFeet } { t('units.cubicFeet') })
        </li>

        <li className="hidden 2xl:block">
          <div className="flex flex-col 3xl:flex-row">
            <div className="w-[40px] shrink-0 text-sm opacity-80">
              { t('address.from') }
            </div>
            { capitalizeFirstLetter(job.originCity) }
            { ', ' }
            { job.originRegion.toLocaleUpperCase() }
          </div>
        </li>
        <li className="hidden 2xl:block">
          <div className="flex flex-col 3xl:flex-row">
            <div className="w-[40px] shrink-0 text-sm opacity-80">
              { t('address.to') }
            </div>
            { capitalizeFirstLetter(job.destinationCity) }
            { ', ' }
            { job.originRegion.toLocaleUpperCase() }
          </div>
        </li>
      </ul>
    </div>
  )
}

export default JobEntry
