import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import JobIcon from '@mui/icons-material/AccountCircle'
import InvoiceIcon from '@mui/icons-material/RequestQuoteOutlined'

import { protectPage } from '../../../modules/users'
import { useDirectAccess, useNavigate, useParams } from '../../../modules/navigation'
import { type JobForPartner, JobStatus, useJob } from '../../../modules/jobs'
import Route from '../../../app/Route.enum'
import { capitalizeFirstLetter } from '../../../utils/string'
import SidePanel from '../../../components/SidePanel'
import Spinner from '../../../components/Spinner'
import ServerError from '../../../components/errors/ServerError'
import JobStatusTag from '../../../components/job/JobStatusTag'
import JobDetail from './JobDetail'

const JobPage: React.FC = () => {
  const { t } = useTranslation()
  const { jobId } = useParams()
  const { replace, navigate } = useNavigate()
  const { accessedDirectly } = useDirectAccess()
  const { data: job, loading, error } = useJob(jobId)
  const readyToBeClosed = job?.status === JobStatus.Completed

  const closeSidebar = () => {
    replace(Route.Jobs)
  }

  const getCustomerFullName = (job: JobForPartner) => {
    return [
      capitalizeFirstLetter(job.move.user.firstName),
      capitalizeFirstLetter(job.move.user.lastName),
    ].join(' ')
  }

  return (
    <SidePanel
      open
      appear={!accessedDirectly}
      onClose={closeSidebar}
      large
      header={job && (
        <div className="mr-8 flex items-center gap-x-4 border-b pb-4 pl-2 font-body2 text-2xl lg:pl-0">
          <div className="flex items-center gap-4">
            <div>
              <JobIcon className="ml-1 mr-2 text-neutral-500" />
              { ' ' }
              { getCustomerFullName(job) }
            </div>
            <JobStatusTag jobStatus={job.status} />
          </div>
          { readyToBeClosed && (
            <div className="mr-4">
              <Button
                variant="contained"
                color="success"
                startIcon={<InvoiceIcon />}
                size="small"
                onClick={() => { navigate(Route.CloseJob) }}
              >
                { t('actions.closeJob') }
              </Button>
            </div>
          ) }
        </div>
      )}
    >
      { loading && <Spinner /> }
      { error && <div className="my-4"><ServerError error={error} /></div> }

      { job && <JobDetail job={job} /> }

    </SidePanel>
  )
}

export default protectPage(JobPage)
