export enum ACTION_TYPES {
  THEME_SET_DARK_MODE = 'THEME_SET_DARK_MODE',
}

export const setDarkMode = (darkModeEnabled: boolean) => ({
  type: ACTION_TYPES.THEME_SET_DARK_MODE as const,
  darkModeEnabled,
})

export type Actions = ReturnType<typeof setDarkMode>
