import { useTranslation } from 'react-i18next'
import { type Interval } from 'date-fns'
import { addDays } from 'date-fns/addDays'
import { isWithinInterval } from 'date-fns/isWithinInterval'

import { useDateFormatter } from '../../../../../utils/date'
import { type RuleTemporality, RuleTemporalityType } from '../availabilityAndPricing.models'
import { type TemporalityHandler } from './temporalityRules.type'
import { areIntervalsOverlappingInclusive } from '../date.util'

const useDateRangeRuleHandler = (): TemporalityHandler => {
  const formatDate = useDateFormatter()
  const { t } = useTranslation()

  return (rule: RuleTemporality) => {
    if (rule.type !== RuleTemporalityType.DateRange) {
      return
    }

    return {
      filter: (period: Interval) => {
        if (!rule.from || !rule.to) {
          return false
        }
        return areIntervalsOverlappingInclusive(
          { start: rule.from, end: rule.to },
          period,
        )
      },

      isRuleActiveForDate: (date: Date) => {
        if (!rule.from || !rule.to) {
          return false
        }

        try {
          return isWithinInterval(date, {
            start: rule.from,
            end: rule.to,
          })
        } catch (error) {
          return false
        }
      },

      getCalendarEntries: () => {
        if (!rule.from || !rule.to) {
          return
        }
        return [{ start: rule.from, end: addDays(rule.to, 1) }]
      },

      getTitle: () => {
        if (!rule.from || !rule.to) {
          return
        }
        return (
          <>
            { t('date.from') } { formatDate(rule.from) } { t('date.to') } { formatDate(rule.to) }
          </>
        )
      },

      getColor: () => '#086482',

      isRecurrent: () => false,
    }
  }
}

export default useDateRangeRuleHandler
