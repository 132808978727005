import { addHours } from 'date-fns/addHours'
import { addSeconds } from 'date-fns/addSeconds'

import {
  type ArrivalWindow,
  getArrivalWindowValue,
} from '../../companies'
import {
  type JobForPartner,
  type LightJob,
} from './jobs.type'

type JobLike = {
  date: Date
  time: ArrivalWindow
  moveDurationSeconds: number
}

/**
 * get start + end date for a given light job
 */
export const getLightJobRange = (job: JobLike) => {
  const startTime = getArrivalWindowValue(job.time)
  const start = addHours(job.date, startTime)
  const end = addSeconds(start, job.moveDurationSeconds)
  return { start, end }
}

export const sortJobs = <T extends JobForPartner | LightJob>(jobs: T[], direction: 'ASC' | 'DESC' = 'ASC'): T[] => {
  const isAsc = direction === 'ASC'
  const sorted = [...jobs]

  sorted.sort((a, b) => {
    const dateA = new Date(a.date)
    const dateB = new Date(b.date)
    const timeA = getArrivalWindowValue(a.time)
    const timeB = getArrivalWindowValue(b.time)

    if (dateA > dateB) {
      return isAsc ? 1 : -1
    }
    if (dateA < dateB) {
      return isAsc ? -1 : 1
    }
    if (timeA > timeB) {
      return isAsc ? 1 : -1
    }
    if (timeA < timeB) {
      return isAsc ? -1 : 1
    }
    return 0
  })

  return sorted
}
