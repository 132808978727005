import { type Amount } from '../../../common/amount'

export enum Tax {
  Gst = 'gst',
  Hst = 'hst',
  Pst = 'pst',
  Ein = 'ein',
}

export type TaxBreakdown = {
  tax: Tax
  percentage: number
  number: string
  baseAmount: Amount
  total: Amount
  region?: string
}
