import * as Yup from 'yup'

import { usePricesValidation } from '../../../../modules/companies'
import { useRecordValidation } from '../../../../utils/validation'
import { type ProtectiveMaterialFormValues } from './ProtectiveMaterialForm.type'

const useValidationSchema = () => {
  const recordValidation = useRecordValidation()
  const pricesValidation = usePricesValidation()

  return Yup.object<ProtectiveMaterialFormValues>({
    products: recordValidation(pricesValidation({
      basePriceRequired: true,
      allowDecimal: true,
      allowZero: true,
    })),
  })
}

export default useValidationSchema
