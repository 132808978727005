import PhoneIcon from '@mui/icons-material/PhoneAndroidOutlined'

import MobileMenuLink from './MobileMenuLink'

type InstallPWAMobileLinkProps = {
  children?: React.ReactNode
}

const InstallPwaMobileLink: React.FC<InstallPWAMobileLinkProps> = ({ children }) => {
  const beforeinstallpromptEvent = (window as any).beforeinstallpromptEvent

  const onClick = (event: React.MouseEvent) => {
    event.preventDefault()
    beforeinstallpromptEvent.prompt()
  }

  if (!beforeinstallpromptEvent) {
    return null
  }
  return (
    <MobileMenuLink
      to="#"
      onClick={onClick}
      Icon={PhoneIcon}
    >
      { children }
    </MobileMenuLink>
  )
}

export default InstallPwaMobileLink
