import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'

import {
  type DetailedQuoteRequest,
  type MoveDetailsPayload,
} from '../../../../modules/adminMoves'
import { type QuotesRequestPayload } from '../../../../modules/adminQuotes'
import {
  Form,
  SubmitButton,
} from '../../../form'
import useValidationSchema from './validationSchema'
import { quotesRequestToFormData, formatFormOutput } from './formDataBuilder'
import { type QuotesRequestFormValues } from './QuotesRequestForm.type'
import QuotesRequestFormContent, { defaultQuoteRequestValues } from './QuotesRequestFormContent'

type QuotesRequestFormProps = {
  onSubmit: (payload: [QuotesRequestPayload, MoveDetailsPayload]) => Promise<any>
  onCancel?: () => void
  data?: DetailedQuoteRequest
  allowOverride?: boolean
}

const QuotesRequestForm: React.FC<QuotesRequestFormProps> = ({
  onSubmit,
  onCancel,
  data,
  allowOverride = false,
}) => {
  const { t } = useTranslation()
  const schema = useValidationSchema()
  const form = useForm<QuotesRequestFormValues>({
    resolver: yupResolver(schema),
    defaultValues: data ? quotesRequestToFormData(data) : defaultQuoteRequestValues,
  })

  const onFormSubmit = async (data: QuotesRequestFormValues) => {
    await onSubmit(formatFormOutput(data))
  }

  return (
    <Form form={form} onSubmit={onFormSubmit}>
      <QuotesRequestFormContent
        allowOverride={allowOverride}
      />

      <div className="mt-8 flex justify-end gap-3">
        { onCancel && (
          <Button
            variant="contained"
            color="secondary"
            onClick={onCancel}
          >
            { t('actions.cancel') }
          </Button>
        ) }
        <SubmitButton size="large">
          { t('actions.getQuotes') }
        </SubmitButton>
      </div>
    </Form>
  )
}

export default QuotesRequestForm
