import { subDays } from 'date-fns/subDays'
import { subMonths } from 'date-fns/subMonths'

import { type DashboardMetricsCollection } from './dashboard.models'

/**
 * returns the sum for the given history data
 */
export const getMetricCollectionSum = (data: DashboardMetricsCollection) => {
  return data.reduce((acc, { value }) => acc + (value ?? 0), 0)
}

/**
 * fill missing values with 0
 */
export const toNumbers = (data: DashboardMetricsCollection) => {
  return data.map(({ value }) => value ?? 0)
}

/**
 * fill missing day values with 0
 */
export const fillDays = <T extends DashboardMetricsCollection>(data: T, nbDays: number): T => {
  const today = new Date()

  return Array.from({ length: nbDays }).map((_, index) => {
    const refDate = subDays(today, (nbDays - (index + 1)))
    const day = refDate.getDate()
    const month = refDate.getMonth() + 1
    const dayLabel = `${String(day).padStart(2, '0')}-${String(month).padStart(2, '0')}`
    const item = data.find(({ label }) => label === dayLabel)
    return {
      ...item,
      label: dayLabel,
      value: item?.value ?? 0,
    }
  }) as T
}

/**
 * fill missing month values with 0
 */
export const fillMonths = <T extends DashboardMetricsCollection>(data: T, nbMonths = 12): T => {
  const today = new Date()

  return Array.from({ length: nbMonths }).map((_, index) => {
    const refDate = subMonths(today, (nbMonths - (index + 1)))
    const month = refDate.getMonth() + 1
    const year = refDate.getFullYear()
    const monthLabel = `${String(year)}-${String(month).padStart(2, '0')}`
    const item = data.find(({ label }) => label === monthLabel)
    return {
      ...item,
      label: monthLabel,
      value: item?.value ?? 0,
    }
  }) as T
}

/**
 * cumulate new total with given values
 */
export const cumulativeData = (data: DashboardMetricsCollection, initialValue = 0) => {
  let total = initialValue
  return data.map(({ label, value = 0 }) => {
    total += value
    return {
      label,
      value: total,
    }
  })
}

type WithLabelAndCount = Array<{ label: string, count: number }>

export const getCountValue = (data: WithLabelAndCount): DashboardMetricsCollection => data.map(({ label, count }) => ({
  label,
  value: count,
}))
