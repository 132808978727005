import { type AdminUser, type UpdateUserAdminPayload } from '../../../../modules/adminUsers'

type UserToFormDataOptions = {
  canEditUsersCredentials?: boolean
  canPromoteUsers?: boolean
}

export const userToFormData = (user: AdminUser, {
  canEditUsersCredentials = false,
  canPromoteUsers = false,
}: UserToFormDataOptions = {}): UpdateUserAdminPayload => {
  return {
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNumber: {
      number: user.phoneNumber.number,
      extension: user.phoneNumber.extension,
    },
    position: user.position,
    email: user.email,
    emailVerified: user.emailVerified,
    photo: user.photo,
    mfaEnabled: canEditUsersCredentials ? user.mfaEnabled : undefined,
    roles: canPromoteUsers ? user.roles : undefined,
  }
}

export const formatFormOutput = (formData: UpdateUserAdminPayload): UpdateUserAdminPayload => {
  return {
    ...formData,
    firstName: formData.firstName,
    lastName: formData.lastName,
    phoneNumber: formData.phoneNumber,
    position: formData.position,
    photo: formData?.photo?.trim() === '' ? null : formData?.photo,
    password: formData?.password?.trim() === '' ? undefined : formData?.password,
  }
}
