import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/AddCircleOutline'

import { type FullMove } from '../../../../../modules/adminMoves'
import CustomerBox from './CustomerBox'
import JobBox from './JobBox'
import JobRequestBox from './JobRequestBox'
import CustomerMessageBox from './CustomerMessageBox'
import JobsTabs from '../../../../../components/move/JobsTabs'
import PartnerJobs from './PartnerJobs'

type DetailsTabProps = {
  move: FullMove
}

const DetailsTab: React.FC<DetailsTabProps> = ({
  move,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <CustomerBox move={move} />

      <JobRequestBox move={move} />

      <PartnerJobs move={move} />

      <CustomerMessageBox move={move} />

      <JobsTabs
        move={move}
        addButton={(
          <Button
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<AddIcon />}
            disabled
            className="!normal-case"
          >
            { t('actions.add') }
          </Button>
        )}
      />

      <JobBox move={move} />
    </>
  )
}

export default DetailsTab
