import { useCurrentCompanyBranch } from '../../../../modules/companies'
import { RuleType } from './RuleModal'

export const useRuleById = (id?: string | null) => {
  const { data: companyBranch } = useCurrentCompanyBranch()

  if (!id) {
    return
  }
  const availabilityRule = companyBranch?.availabilityRules?.find(r => r.id === id)
  if (availabilityRule) {
    return {
      type: RuleType.Availability,
      rule: availabilityRule,
    }
  }

  const pricingRule = companyBranch?.pricingRules?.find(r => r.id === id)
  if (pricingRule) {
    return {
      type: RuleType.Pricing,
      rule: pricingRule,
    }
  }
}
