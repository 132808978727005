export enum PaymentGatewayName {
  stripe = 'stripe',
}

export enum PaymentStatus {
  succeeded = 'succeeded',
  pending = 'pending',
  failed = 'failed',
  refunded = 'refunded',
  partiallyRefunded = 'partiallyRefunded',
}

export enum DisputeStatus {
  pending = 'pending',
  actionNeeded = 'actionNeeded',
  closed = 'closed',
  won = 'won',
  lost = 'lost',
}
