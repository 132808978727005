import JobIcon from '@mui/icons-material/AccountCircle'

type JobTitleProps = {
  children?: React.ReactNode
}

const JobTitle: React.FC<JobTitleProps> = ({ children }) => (
  <div className="flex items-center">
    <div className="hidden lg:block">
      <JobIcon className="-mt-1 mr-1 !text-[16px]" />
    </div>
    <div className="!text-sm !leading-tight lg:text-base">
      { children }
    </div>
  </div>
)

export default JobTitle
