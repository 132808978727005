import * as Yup from 'yup'

export const useRecordValidation = () => {
  return (schema: Yup.AnySchema) => {
    return Yup.lazy(record => {
      const shapeEntries = Object.entries(record ?? {}).map(([key]) => ([
        key,
        schema,
      ]))
      return Yup.object(
        Object.fromEntries(shapeEntries ?? []),
      )
    })
  }
}
