import { type RuleTemporality, RuleTemporalityType } from '../availabilityAndPricing.models'
import useDateRuleHandler from './date.temporalityHandler'
import useDateRangeRuleHandler from './dateRange.temporalityHandler'
import useEveryWeekDayRuleHandler from './everyWeekDay.temporalityHandler'
import useRecurrentRangeDayRuleHandler from './recurrentRange.temporalityHandler'
import { type TemporalityHandler } from './temporalityRules.type'

export const useTemporalityRuleHandler = () => {
  const dateHandler = useDateRuleHandler()
  const dateRangeHandler = useDateRangeRuleHandler()
  const everyWeekDayHandler = useEveryWeekDayRuleHandler()
  const recurrentRangeHandler = useRecurrentRangeDayRuleHandler()

  return (temporalityRule: RuleTemporality): ReturnType<TemporalityHandler> | undefined => {
    switch (temporalityRule.type) {
      case RuleTemporalityType.Date:
        return dateHandler(temporalityRule)
      case RuleTemporalityType.DateRange:
        return dateRangeHandler(temporalityRule)
      case RuleTemporalityType.EveryWeekDay:
        return everyWeekDayHandler(temporalityRule)
      case RuleTemporalityType.RecurrentRange:
        return recurrentRangeHandler(temporalityRule)
    }
  }
}
