import { format } from 'date-fns/format'

import { type ExternalEvent } from '../../../../modules/companies'
import { CalendarSidebarListItem } from '../../../../components/calendar'
import { useExternalEventLogo } from '../useExternalEventLogo'

type ExternalEventItemProps = {
  externalEvent: ExternalEvent
}

const ExternalEventItem: React.FC<ExternalEventItemProps> = ({
  externalEvent,
}) => {
  const Logo = useExternalEventLogo(externalEvent.source)
  return (
    <CalendarSidebarListItem>
      <div className="flex grow items-center gap-2 text-sm">
        <div className="size-[16px] rounded" style={{ backgroundColor: '#93a7d0' }} />
        <div className="flex items-center gap-x-2 text-neutral-600">
          <Logo className="!text-[18px]" />
          <span className="text-xs text-neutral-400">
            { format(externalEvent.start, 'MM/dd') }
          </span>
          <span>
            { externalEvent.title }
          </span>
        </div>
      </div>
    </CalendarSidebarListItem>
  )
}

export default ExternalEventItem
