import { gql } from '@apollo/client'

export const userFields = `
id
email
emailVerified
firstName
lastName
phoneNumber {
  number
  extension
}
position
language
mfaEnabled
photo
externalProvider
`

export const userFieldsForPartner = `
firstName
lastName
phoneNumber {
  number
  extension
}
language
`

export const getUser = gql`
query GetUser {
  getUser {
    ${userFields}
    roles
  }
}
`

export const getPartnerInvitation = gql`
query GetPartnerInvitation($invitationId: String!) {
  getPartnerInvitation(invitationId: $invitationId) {
    firstName
    lastName
    email
  }
}
`
