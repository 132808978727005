import { useEffect } from 'react'
import ReactGA from 'react-ga4'

import { useEffectOnce } from '../../../utils/useEffectOnce'
import { useConfig } from '../../config'
import { useLanguage } from '../../i18n'
import { useCurrentUser } from '../../users'

const GoogleAnalytics: React.FC = () => {
  const { data: currentUser } = useCurrentUser()
  const measurementId = useConfig('googleAnalytics.measurementId')
  const language = useLanguage()

  /**
   * Initialyze Google Analytics
   */
  useEffectOnce(() => {
    if (!measurementId) {
      return
    }
    ReactGA.initialize(measurementId)
  })

  /**
   * Set language
   */
  useEffect(() => {
    if (!measurementId) {
      return
    }
    ReactGA.set({ language })
  }, [language, measurementId])

  /**
   * Set userid
   */
  useEffect(() => {
    if (!currentUser?.id) {
      return
    }
    ReactGA.set({ userId: currentUser.id })
  }, [currentUser])

  return null
}

export default GoogleAnalytics
