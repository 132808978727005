import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import ServerError from '../../../components/errors/ServerError'
import Spinner from '../../../components/Spinner'
import { useCurrentUser, protectPage } from '../../../modules/users'
import Paper from '../../../components/Paper'
import MobileHeader from '../../../components/layout/MobileHeader/MobileHeader'
import Section from '../../../components/Section'
import UpdatePasswordModal from '../../../components/users/profile/security/UpdatePasswordModal'
import EnableTwoFactorModal from '../../../components/users/profile/security/EnableTwoFactorModal'
import DisableTwoFactorModal from '../../../components/users/profile/security/DisableTwoFactorModal'
import { capitalizeFirstLetter } from '../../../utils/string'
import Route from '../../../app/Route.enum'
import PageContainer from './PageContainer'

const SecurityPage: React.FC = () => {
  const { t } = useTranslation()
  const { data: user, loading, error } = useCurrentUser()
  const [updatePasswordModalOpen, setUpdatePasswordModalOpen] = useState(false)
  const [enableTwoFactorModalOpen, setEnableTwoFactorModalOpen] = useState(false)
  const [disableTwoFactorModalOpen, setDisableTwoFactorModalOpen] = useState(false)

  const externalProvider = user?.externalProvider

  const handleTwoFactorSwitchChange = () => {
    if (user?.mfaEnabled) {
      setDisableTwoFactorModalOpen(true)
    } else {
      setEnableTwoFactorModalOpen(true)
    }
  }

  if (loading || error) {
    return (
      <PageContainer>
        { loading
          ? (
            <Spinner floating />
            )
          : (
            <ServerError error={error} />
            ) }

      </PageContainer>
    )
  }

  return (
    <PageContainer
      mobileHeader={<MobileHeader title={String(t('pages.users.profile.security.title'))} backlinkTo={Route.Profile} />}
    >
      <Section>
        { t('pages.users.profile.security.changePassword.title') }
      </Section>
      <Paper>
        <UpdatePasswordModal
          open={updatePasswordModalOpen}
          onClose={() => { setUpdatePasswordModalOpen(false) }}
        />
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => { setUpdatePasswordModalOpen(true) }}
          sx={{ my: 2 }}
          disabled={!!externalProvider}
        >
          { t('pages.users.profile.security.changePassword.cta') }
        </Button>

        { externalProvider && (
          <Alert severity="info">
            { t('pages.users.profile.security.changePassword.externalProvider', { externalProvider: capitalizeFirstLetter(externalProvider) }) }
          </Alert>
        ) }
      </Paper>

      <EnableTwoFactorModal
        open={enableTwoFactorModalOpen}
        onClose={() => { setEnableTwoFactorModalOpen(false) }}
      />

      <DisableTwoFactorModal
        open={disableTwoFactorModalOpen}
        onClose={() => { setDisableTwoFactorModalOpen(false) }}
      />

      <Section>
        { t('pages.users.profile.security.mfa.title') }
      </Section>
      <Paper>
        <Alert severity="info">
          <strong>{ t('pages.users.profile.security.mfa.info.title') }</strong><br />
          { t('pages.users.profile.security.mfa.info.content') }
        </Alert>
        <br />
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={user?.mfaEnabled} onChange={handleTwoFactorSwitchChange} />}
            label={
            user?.mfaEnabled ? t('status.currentlyEnabled') : t('status.currentlyDisabled')
          }
          />
        </FormGroup>
      </Paper>
    </PageContainer>
  )
}

export default protectPage(SecurityPage)
