import * as Yup from 'yup'
import { type LoginPayload } from '../../../../modules/users'

const useValidationSchema = () => {
  return Yup.object({
    email: Yup.string().required().email(),
    password: Yup.string().required(),
  }) as Yup.ObjectSchema<LoginPayload, LoginPayload>
}

export default useValidationSchema
