import { format } from 'date-fns/format'

import { type Event } from './EventCalendar.types'

/**
 * calculate the number of events for each day of the month
 */
export const getNbEventsByDay = (events: Event[]) => {
  const byDay: Record<string, number> = {}

  events
    .filter((event) => !!event.id)
    .forEach((event) => {
      const day = format(event.start, 'yyyy-MM-dd')
      if (!byDay[day]) {
        byDay[day] = 0
      }
      byDay[day]++
    })

  return byDay
}
