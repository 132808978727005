/* eslint-disable @typescript-eslint/no-misused-promises */
import { useTranslation } from 'react-i18next'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import ConfirmationModal from '../../../../components/ConfirmationModal'
import { CalendarSidebarListItem } from '../../../../components/calendar'

type RuleProps = {
  children: React.ReactNode
  onEdit?: () => void | Promise<void>
  onDelete?: () => void | Promise<void>
  compact?: boolean
}

const Rule: React.FC<RuleProps> = ({
  children,
  onEdit,
  onDelete,
  compact = false,
}) => {
  const { t } = useTranslation()

  return (
    <CalendarSidebarListItem compact={compact}>
      { children }
      <div className="flex">
        <EditIcon className="cursor-pointer text-gray-600" onClick={onEdit} />
        <ConfirmationModal
          question={t('pages.companyBranch.availability.ruleList.deleteConfirmation')}
          onConfirm={onDelete}
          danger
        >
          <DeleteIcon className="cursor-pointer text-red-800" />
        </ConfirmationModal>
      </div>
    </CalendarSidebarListItem>
  )
}

export default Rule
