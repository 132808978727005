import { useDataGridContext } from '../DataGridContext'
import Header from './Header'
import NoResults from './NoResults'

type TableProps = {
  columns?: React.ReactNode
  children?: React.ReactNode
}

const Table: React.FC<TableProps> = ({
  columns,
  children,
}) => {
  const { count } = useDataGridContext()

  return (
    <>
      <table>
        <Header>
          { columns }
        </Header>
        <tbody>
          { children }
        </tbody>
      </table>
      { count === 0 && <NoResults /> }
    </>
  )
}

export default Table
