type SidebarItemProps = {
  icon?: React.ReactNode
  children?: React.ReactNode
}

const SidebarItem: React.FC<SidebarItemProps> = ({
  icon,
  children,
}) => {
  return (
    <div className="flex size-[75px] flex-col items-center justify-center rounded-xl bg-slate-700 text-white hover:bg-slate-800">
      <div className="!text-[24px]">
        { icon }
      </div>
      <span className="font-sans text-xs">
        { children }
      </span>
    </div>

  )
}

export default SidebarItem
