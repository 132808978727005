enum Step {
  Legal = 'legal',
  Services = 'services',
  Seasons = 'seasons',
  Labour = 'labour',
  TripAndTravel = 'tripAndTravel',
  AdditionalCharges = 'additionalCharges',
  ProtectiveMaterial = 'protectiveMaterial',
  Communication = 'communication',
}

export default Step
