import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import { Transition } from '@headlessui/react'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined'

import { type MoveSizeEstimate } from '../../../../../modules/quotes'
import Paper from '../../../../Paper'
import {
  Input,
  Label,
} from '../../../../form'

type MoveSizeAndRequirementsFormContentProps = {
  estimate?: MoveSizeEstimate
  requireDetails?: boolean
}

const MoveSizeAndRequirementsFormContent: React.FC<MoveSizeAndRequirementsFormContentProps> = ({
  estimate,
  requireDetails = false,
}) => {
  const { t } = useTranslation()

  const { setValue } = useFormContext()
  const nbMen = useWatch({ name: 'nbMen' })
  const estimatedMovingLabourTime = useWatch({ name: 'estimatedMovingLabourTime' })
  const volumeCubicFeet = useWatch({ name: 'volumeCubicFeet' })

  const hasCustomNbMen = estimate?.nbMen
    ? parseInt(nbMen) !== estimate?.nbMen
    : false

  const hasCustomMovingLabour = estimate?.estimatedMovingLabourTime
    ? parseFloat(estimatedMovingLabourTime) !== estimate?.estimatedMovingLabourTime
    : false

  const hasCustomVolume = estimate?.volumeCubicFeet
    ? parseInt(volumeCubicFeet) !== estimate?.volumeCubicFeet
    : false

  const hasCustomValues = hasCustomNbMen || hasCustomMovingLabour || hasCustomVolume

  const applyEstimateValues = () => {
    setValue('nbMen', String(estimate?.nbMen ?? 0))
    setValue('estimatedMovingLabourTime', String(estimate?.estimatedMovingLabourTime ?? 0))
    setValue('volumeCubicFeet', String(estimate?.volumeCubicFeet ?? 0))
  }

  return (
    <>
      <h2 className="mb-2 font-body2 text-lg font-bold lg:my-4">
        { t('forms.quotesRequest.sizeAndRequirements.title') }
      </h2>
      <Paper>
        <div className="flex flex-col gap-y-4">
          <Label
            label={(
              <>
                { t('forms.fields.nbMen.label') }
                { estimate?.nbMen && (
                  <span className="text-sm text-gray-400">
                    { ' ' }
                    ({ t('forms.quotesRequest.sizeAndRequirements.estimated') }:
                    { ' ' }
                    <strong>{ estimate.nbMen }</strong>
                    )
                  </span>
                ) }
              </>
            )}
          >
            <Input
              name="nbMen"
              type="number"
              size="small"
              inputProps={{ min: 1, max: 99 }}
            />
          </Label>

          <Label
            label={(
              <>
                { t('forms.fields.estimatedMovingLabourTime.label') }
                { estimate?.estimatedMovingLabourTime && (
                  <span className="text-sm text-gray-400">
                    { ' ' }
                    ({ t('forms.quotesRequest.sizeAndRequirements.estimated') }:
                    { ' ' }
                    <strong>
                      { estimate.estimatedMovingLabourTime }
                      { t('units.hour') }
                    </strong>)
                  </span>
                ) }
              </>
            )}
          >
            <Input
              name="estimatedMovingLabourTime"
              size="small"
              inputProps={{
                maxLength: 4,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    { t('units.hour_s') }
                  </InputAdornment>
                ),
              }}
            />
          </Label>

          <Label
            label={t('forms.fields.estimatedPackingLabourTime.label')}
          >
            <Input
              name="estimatedPackingLabourTime"
              size="small"
              inputProps={{
                maxLength: 4,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    { t('units.hour_s') }
                  </InputAdornment>
                ),
              }}
            />
          </Label>

          { requireDetails && (
            <Label
              label={(
                <>
                  { t('forms.fields.estimatedVolume.label') }
                  { estimate?.estimatedMovingLabourTime && (
                    <span className="text-sm text-gray-400">
                      { ' ' }
                      ({ t('forms.quotesRequest.sizeAndRequirements.estimated') }:
                      { ' ' }
                      <strong>
                        { estimate.volumeCubicFeet }
                        { ' ' }
                        { t('units.cubicFeet') }
                      </strong>)
                    </span>
                  ) }
                </>
              )}
            >
              <Input
                name="volumeCubicFeet"
                size="small"
                inputProps={{
                  maxLength: 4,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      { t('units.cubicFeet') }
                    </InputAdornment>
                  ),
                }}
              />
            </Label>
          ) }
        </div>

        <Transition.Root show={hasCustomValues}>
          <Transition.Child
            enter="transition-opacity duration-0 delay-50"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Button
              fullWidth
              variant="contained"
              color="warning"
              startIcon={<SyncOutlinedIcon />}
              size="small"
              className="!mt-6 !normal-case"
              onClick={applyEstimateValues}
            >
              { t('actions.useEstimateValues') }
            </Button>
          </Transition.Child>
        </Transition.Root>
      </Paper>
    </>
  )
}

export default MoveSizeAndRequirementsFormContent
