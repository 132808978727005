import { mergeClassName } from '../../utils/mergeClassName'

type CardTitleProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>

const CardTitle: React.FC<CardTitleProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <dt
      className={mergeClassName(
        'text-sm font-medium leading-6 text-gray-500',
        className,
      )}
      {...props}
    >
      { children }
    </dt>
  )
}

export default CardTitle
