import { useLazyQuery, useMutation, useQuery, useApolloClient } from '@apollo/client'

import { type PaginatedQueryResult, type PaginatedQueryResultData, type QueryResult } from '../../graphQl'
import * as queries from './adminMoves.queries'
import * as mutations from './adminMoves.mutations'
import { type LightMove } from './adminMoves.models'
import { type ApproveMovePayload, type MovePayload, type MovesPaginatedQuery, type QuotePayload } from './adminMoves.types'
import { buildFullMove } from './adminMoves.builder'

type UseMovesHookResult = {
  fetchMoves: (variables: MovesPaginatedQuery) => Promise<PaginatedQueryResultData<LightMove>>
} & PaginatedQueryResult<LightMove>

/**
 * returns all companies branches
 * query optimized for data table
 */
export const useMoves = ({ reload = false, ...queryArgs } = {}): UseMovesHookResult => {
  const fetchPolicy = reload ? 'network-only' : 'cache-first'

  const [fetch, { data, previousData, networkStatus, loading, error, ...queryProps }] = useLazyQuery(
    queries.getMoves,
    {
      fetchPolicy,
      ...queryArgs,
    },
  )
  const fetchMoves = async (variables?: MovesPaginatedQuery) => {
    const { data } = await fetch({ variables })
    return data?.admin_getMoves
  }

  return {
    fetchMoves,
    data: (data ?? previousData)?.admin_getMoves,
    loading: (loading && networkStatus === 1),
    error,
    ...queryProps,
  }
}

/**
 * returns full move details
 * fixme : replace any with proper type
 */
export const useMove = (moveId?: string, { reload = false, ...queryArgs } = {}): QueryResult<any | undefined> => {
  const fetchPolicy = reload ? 'network-only' : 'cache-first'

  const { data, networkStatus, loading, error, ...queryProps } = useQuery(
    queries.getMove,
    {
      skip: !moveId,
      variables: { moveId },
      fetchPolicy,
      ...queryArgs,
    },
  )

  return {
    data: buildFullMove(data?.admin_getMove),
    loading: (loading && networkStatus === 1),
    error,
    ...queryProps,
  }
}

/**
 * create a move
 */
export const useCreateMoveAction = () => {
  const [createMove, { data, loading, ...mutationProps }] = useMutation(mutations.createMove)

  return {
    createMove: async (payload: MovePayload) => {
      const { data } = await createMove({
        variables: {
          payload,
        },
      })

      return data.admin_createMove
    },
    data: data?.admin_createMove,
    loading,
    ...mutationProps,
  }
}

/**
 * update a move
 */
export const useUpdateMoveAction = () => {
  const [updateMove, { data, loading, ...mutationProps }] = useMutation(mutations.updateMove)

  return {
    updateMove: async (moveId: string, payload: Partial<MovePayload>) => {
      const { data } = await updateMove({
        variables: {
          moveId,
          payload,
        },
      })

      return data.admin_updateMove
    },
    data: data?.admin_updateMove,
    loading,
    ...mutationProps,
  }
}

/**
 * assign agent to move
 */
export const useAssignAgentToMoveAction = () => {
  const [assignAgentToMove, { data, loading, ...mutationProps }] = useMutation(mutations.assignAgentToMove)

  return {
    assignAgentToMove: async (moveId: string) => {
      const { data } = await assignAgentToMove({
        variables: {
          moveId,
        },
      })

      return data.admin_assignAgentToMove
    },
    data: data?.admin_assignAgentToMove,
    loading,
    ...mutationProps,
  }
}

/**
 * unassign agent to move
 */
export const useUnassignAgentToMoveAction = () => {
  const apolloClient = useApolloClient()
  const [unassignAgentToMove, { data, loading, ...mutationProps }] = useMutation(mutations.unassignAgentToMove)

  return {
    unassignAgentToMove: async (moveId: string) => {
      const { data } = await unassignAgentToMove({
        variables: {
          moveId,
        },
      })

      /* also update light move in order to update the datagrid */

      try {
        apolloClient.cache.modify<LightMove>({
          id: apolloClient.cache.identify({
            __typename: 'LightMove',
            id: moveId,
          }),
          fields: {
            agent: (_, { DELETE }) => DELETE,
          },
        })
      } catch (error) {
        console.error(error)
      }

      return data.admin_unassignAgentToMove
    },
    data: data?.admin_unassignAgentToMove,
    loading,
    ...mutationProps,
  }
}

/**
 * send move to SmartMoving
 */
export const useSendMoveToSmartMovingAction = () => {
  const [sendMoveToSmartMoving, { data, loading, ...mutationProps }] = useMutation(mutations.sendMoveToSmartMoving)

  return {
    sendMoveToSmartMoving: async (moveId: string) => {
      const { data } = await sendMoveToSmartMoving({
        variables: {
          moveId,
        },
      })

      return data.admin_sendMoveToSmartMoving
    },
    data: data?.admin_sendMoveToSmartMoving,
    loading,
    ...mutationProps,
  }
}

/**
 * refresh quotes
 */
export const useRefreshQuotesAction = () => {
  const [refreshQuotes, { data, loading, ...mutationProps }] = useMutation(mutations.refreshQuotes)

  return {
    refreshQuotes: async (moveId: string) => {
      const { data } = await refreshQuotes({
        variables: {
          moveId,
        },
      })

      return data.admin_refreshQuotes
    },
    data: data?.admin_refreshQuotes,
    loading,
    ...mutationProps,
  }
}

/**
 * cancel selected quote
 */
export const useCancelSelectedQuoteAction = () => {
  const [cancelSelectedQuote, { data, loading, ...mutationProps }] = useMutation(mutations.cancelSelectedQuote)

  return {
    cancelSelectedQuote: async (moveId: string) => {
      const { data } = await cancelSelectedQuote({
        variables: {
          moveId,
        },
      })

      return data.admin_cancelSelectedQuote
    },
    data: data?.admin_cancelSelectedQuote,
    loading,
    ...mutationProps,
  }
}

/**
 * select/unselect a quote
 */
export const useApplyPromoCodeAction = () => {
  const [applyPromoCode, { data, loading, ...mutationProps }] = useMutation(mutations.applyPromoCode)

  return {
    applyPromoCode: async (moveId: string, promoCode: string | null) => {
      const { data } = await applyPromoCode({
        variables: {
          moveId,
          promoCode,
        },
      })

      return buildFullMove(data.admin_applyPromoCode)
    },
    data: buildFullMove(data?.admin_applyPromoCode),
    loading,
    ...mutationProps,
  }
}

/**
 * select and customize a quote
 */
export const useSelectBranchAction = () => {
  const [selectBranch, { data, loading, ...mutationProps }] = useMutation(mutations.selectBranch)

  return {
    selectbranch: async (moveId: string, branchId: string) => {
      const { data } = await selectBranch({
        variables: {
          moveId,
          branchId,
        },
      })

      return buildFullMove(data.admin_selectBranch)
    },
    data: buildFullMove(data?.admin_selectBranch),
    loading,
    ...mutationProps,
  }
}

/**
 * edit quote
 */
export const useEditQuoteAction = () => {
  const [editQuote, { data, loading, ...mutationProps }] = useMutation(mutations.editQuote)

  return {
    editQuote: async (moveId: string, payload: QuotePayload) => {
      const { data } = await editQuote({
        variables: {
          moveId,
          payload,
        },
      })

      return buildFullMove(data.admin_editQuote)
    },
    data: buildFullMove(data?.admin_editQuote),
    loading,
    ...mutationProps,
  }
}

/**
 * send email reminder to customer
 */
export const useSendQuoteByEmailAction = () => {
  const [sendQuoteByEmail, { data, loading, ...mutationProps }] = useMutation(mutations.sendQuoteByEmail)

  return {
    sendQuoteByEmail: async (moveId: string) => {
      await sendQuoteByEmail({
        variables: {
          moveId,
        },
      })
      return true
    },
    loading,
    ...mutationProps,
  }
}

/**
 * approve move
 */
export const useApproveMoveAction = () => {
  const [approveMove, { data, loading, ...mutationProps }] = useMutation(mutations.approveMove)

  return {
    approveMove: async (moveId: string, payload: ApproveMovePayload) => {
      const { data } = await approveMove({
        variables: {
          moveId,
          payload,
        },
      })

      return data.admin_approveMove
    },
    data: data?.admin_approveMove,
    loading,
    ...mutationProps,
  }
}

/**
 * cancel a pending job request
 */
export const useCancelJobRequestAction = () => {
  const [cancelJobRequest, { data, loading, ...mutationProps }] = useMutation(mutations.cancelJobRequest)

  return {
    cancelJobRequest: async (moveId: string) => {
      const { data } = await cancelJobRequest({
        variables: {
          moveId,
        },
      })

      return data.admin_cancelJobRequest
    },
    data: data?.admin_cancelJobRequest,
    loading,
    ...mutationProps,
  }
}

/**
 * cancel a job
 */
export const useCancelJobAction = () => {
  const [cancelJob, { data, loading, ...mutationProps }] = useMutation(mutations.cancelJob)

  return {
    cancelJob: async (moveId: string, jobId: string) => {
      const { data } = await cancelJob({
        variables: {
          moveId,
          jobId,
        },
      })

      return data.admin_cancelJob
    },
    data: data?.admin_cancelJob,
    loading,
    ...mutationProps,
  }
}

/**
 * collect total customer balance on his credit card
 */
export const useCollectTotalBalanceAction = () => {
  const [collectTotalBalance, { data, loading, ...mutationProps }] = useMutation(mutations.collectTotalBalance)

  return {
    collectTotalBalance: async (moveId: string) => {
      const { data } = await collectTotalBalance({
        variables: {
          moveId,
        },
      })

      return data.admin_collectTotalBalance
    },
    data: data?.admin_collectTotalBalance,
    loading,
    ...mutationProps,
  }
}

/**
 * send an email to the customer with a link to pay his balance
 */
export const useSendPaymentRequestEmailAction = () => {
  const [sendPaymentRequestEmail, { data, loading, ...mutationProps }] = useMutation(mutations.sendPaymentRequestEmail)

  return {
    sendPaymentRequestEmail: async (moveId: string) => {
      const { data } = await sendPaymentRequestEmail({
        variables: {
          moveId,
        },
      })

      return data.admin_sendPaymentRequestEmail
    },
    data: data?.admin_sendPaymentRequestEmail,
    loading,
    ...mutationProps,
  }
}
