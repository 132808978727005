/* eslint-disable @typescript-eslint/no-misused-promises */
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'

import { useConfig } from '../../../modules/config'
import {
  useCurrentCompanyBranch,
  useSetCalendarExportEnabledAction,
  useUpdateCalendarUrlAction,
} from '../../../modules/companies'
import {
  Modal,
  ModalDescription,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '../../../components/Modal'
import Spinner from '../../../components/Spinner'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import { errorNotification, successNotification } from '../../../components/ToastNotifications'
import CopyableText from '../../../components/CopyableText'
import ConfirmationModal from '../../../components/ConfirmationModal'

type ExportCalendarModalProps = {
  open: boolean
  onClose: () => void
}

const ExportCalendarModal: React.FC<ExportCalendarModalProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation()
  const apiUrl = useConfig('api.url')
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()
  const { setCalendarExportEnabled, loading: changingStatus } = useSetCalendarExportEnabledAction()
  const { updateCalendarUrl, loading: updatingUrl } = useUpdateCalendarUrlAction()
  const formatError = useErrorFormatter()

  const loading = changingStatus || updatingUrl

  if (!currentCompanyBranch) {
    return null
  }

  const exportEnabled = currentCompanyBranch.externalEvents?.exportEnabled ?? false
  const calendarUrl = currentCompanyBranch.externalEvents?.calendarUrl ? `${apiUrl}${currentCompanyBranch.externalEvents?.calendarUrl}` : undefined

  const handleEnabledChange = async () => {
    try {
      await setCalendarExportEnabled(!exportEnabled)
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  const handleRefreshLink = async () => {
    try {
      await updateCalendarUrl()
      successNotification(t('pages.companyBranch.jobs.exportCalendarModal.refreshLinkSuccess'))
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  return (
    <Modal
      open={open}
      className="lg:max-w-[550px]"
      onClose={onClose}
    >
      <ModalHeader>
        <ModalTitle>
          { t('pages.companyBranch.jobs.exportCalendarModal.title') }
        </ModalTitle>
      </ModalHeader>
      <ModalDescription className="my-4 max-h-[calc(100dvh-185px)] text-base">

        { loading && <Spinner floating backdrop /> }

        <div className="mb-4">
          { t('pages.companyBranch.jobs.exportCalendarModal.description') }
          { ' ' }
          <span className="font-bold">
            { t('pages.companyBranch.jobs.exportCalendarModal.doNotShare') }
          </span>
        </div>

        <label className="cursor-pointer">
          <Switch
            checked={exportEnabled}
            onChange={handleEnabledChange}
          />
          { exportEnabled ? t('status.currentlyEnabled') : t('status.currentlyDisabled') }
        </label>

        { exportEnabled && calendarUrl && (
          <CopyableText
            text={calendarUrl}
            className="mt-2 text-sm"
          />
        ) }

      </ModalDescription>
      <ModalFooter className="justify-between">
        <ConfirmationModal
          title={t('pages.companyBranch.jobs.exportCalendarModal.refreshLinkConfirmation.title')}
          question={t('pages.companyBranch.jobs.exportCalendarModal.refreshLinkConfirmation.question')}
          onConfirm={handleRefreshLink}
          danger
        >
          <Button
            variant="outlined"
            color="secondary"
            disabled={!exportEnabled}
          >
            { t('pages.companyBranch.jobs.exportCalendarModal.refreshSecretLink') }
          </Button>
        </ConfirmationModal>

        <Button
          variant="contained"
          color="secondary"
          onClick={onClose}
        >
          { t('actions.close') }
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ExportCalendarModal
