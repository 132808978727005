import { mergeClassName } from '../../utils/mergeClassName'
import Header, { type HeaderProps } from './Header'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
type ReactTable = React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableElement>, HTMLTableElement>
type ReactTBody = React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>

export type TableProps = {
  columns: React.ReactNode
  children: React.ReactNode
  tableProps?: ReactTable
  headerProps?: HeaderProps
  tbodyProps?: ReactTBody
} & ReactDiv

const Table: React.FC<TableProps> = ({
  columns,
  children,
  className,
  tableProps,
  headerProps,
  tbodyProps,
  ...props
}) => {
  return (
    <div
      className={mergeClassName(
        'relative w-full overflow-x-auto',
        className,
      )}
      {...props}
    >
      <div className="left-0 top-0 z-10 after:absolute after:left-0 after:top-0 after:h-12 after:w-full after:shadow-[0_2px_4px_rgba(0,0,0,0.12)] after:content-['']" />

      <table
        className={mergeClassName(
          'w-full',
          tableProps?.className,
        )}
        {...tableProps}
      >
        <Header {...headerProps}>
          { columns }
        </Header>
        <tbody {...tbodyProps}>
          { children }
        </tbody>
      </table>
    </div>
  )
}

export default Table
