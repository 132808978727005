import PackingIcon from '@mui/icons-material/Inventory'
import StorageIcon from '@mui/icons-material/Warehouse'

import { AdditionalService } from './AdditionalService.enum'
import { type Icon } from '../../../../common/icon/Icon.type'

export const additionalServiceIcons: Record<AdditionalService, Icon> = {
  [AdditionalService.Packing]: PackingIcon,
  [AdditionalService.Storage]: StorageIcon,
}
