import { useMutation } from '@apollo/client'
import Cookies from 'js-cookie'

import * as actions from '../users.actions'
import * as selectors from '../users.selectors'
import * as mutations from './authentication.mutations'
import {
  type LoginPayload,
  type ExternalMfaLoginPayload,
  type ResetPasswordRequestPayload,
  type ResetPasswordPayload,
  type EnableMfaGetQrCodePayload,
  type EnableMfaPayload,
  type DisableMfaPayload,
} from './authentication.types'
import { useDispatch, useSelector } from 'react-redux'
import { useConfig } from '../../../config'
import Route from '../../../../app/Route.enum'
import { Permission } from '../permissions'

/**
 * return user's authentication state
 */
export const useAuthenticationState = () => {
  const authenticatedCookieName = useConfig('users.authentication.authenticatedCookieName')

  return {
    isAuthenticated: Number(Cookies.get(authenticatedCookieName)) > 0,
    isLoggingOut: useSelector(selectors.isLoggingOut),
  }
}

/**
 * login action
 */
export const useLoginAction = () => {
  const [login, { data, ...mutationProps }] = useMutation(mutations.login)

  return {
    login: async (payload: LoginPayload) => {
      const { data } = await login({
        variables: {
          payload,
          requiredOneOfPermissions: [Permission.adminAccess, Permission.partnerAccess],
        },
      })

      return data?.login
    },
    data: data?.login,
    ...mutationProps,
  }
}

/**
 * oauth user mfa second step login
 */
export const useExternalMfaLoginAction = () => {
  const [externalMfaLogin, { data, ...mutationProps }] = useMutation(mutations.externalMfaLogin)

  return {
    externalMfaLogin: async (payload: ExternalMfaLoginPayload) => {
      const { data } = await externalMfaLogin({
        variables: {
          payload,
        },
      })

      return data.externalMfaLogin
    },
    data: data?.externalMfaLogin,
    ...mutationProps,
  }
}

/**
 * log out
 */
export const useLogoutAction = () => {
  const [logout, { data, ...mutationProps }] = useMutation(mutations.logout)
  const dispatch = useDispatch()
  const authenticatedCookieName = useConfig('users.authentication.authenticatedCookieName')

  return {
    logout: () => {
      dispatch(actions.setIsLoggingOut(true))
      logout().catch(() => {
        Cookies.remove(authenticatedCookieName)
        document.location = Route.Dashboard
      }).finally(() => {
        document.location = Route.Dashboard
      })
      return true
    },
    ...mutationProps,
  }
}

/**
 * extend session
 */
export const useExtendSessionAction = () => {
  const [extendSession, { data, ...mutationProps }] = useMutation(mutations.extendSession)

  return {
    extendSession: async () => {
      const { data } = await extendSession()
      return data.extendSession
    },
    data: data?.extendSession,
    ...mutationProps,
  }
}

/**
 * request a reset password link
 */
export const useResetPasswordRequestAction = () => {
  const [resetPasswordRequest, { data, loading, ...mutationProps }] = useMutation(mutations.resetPasswordRequest)

  return {
    resetPasswordRequest: async (payload: ResetPasswordRequestPayload) => {
      const { data } = await resetPasswordRequest({
        variables: {
          payload,
        },
      })
      return !!data.resetPasswordRequest
    },
    data: !!data?.resetPasswordRequest,
    loading,
    ...mutationProps,
  }
}

/**
 * reset password with token received from email
 */
export const useResetPasswordAction = () => {
  const [resetPassword, { data, loading, ...mutationProps }] = useMutation(mutations.resetPassword)

  return {
    resetPassword: async (payload: ResetPasswordPayload) => {
      const { data } = await resetPassword({
        variables: {
          payload,
        },
      })
      return !!data.resetPassword
    },
    data: !!data?.resetPassword,
    loading,
    ...mutationProps,
  }
}

/**
 * Enable mfa ; send verification email
 */
export const useEnableMfaVerifyAccountAction = () => {
  const [enableMfaVerifyAccount, { data, loading, ...mutationProps }] = useMutation(mutations.enableMfaVerifyAccount)

  return {
    enableMfaVerifyAccount: async () => {
      const { data } = await enableMfaVerifyAccount()
      return data.enableMfaVerifyAccount
    },
    data: data?.enableMfaVerifyAccount,
    loading,
    ...mutationProps,
  }
}

/**
 * Enable mfa ; get QR Code
 */
export const useEnableMfaGetQrCodeAction = () => {
  const [enableMfaGetQrCode, { data, loading, ...mutationProps }] = useMutation(mutations.enableMfaGetQrCode)

  return {
    enableMfaGetQrCode: async (payload: EnableMfaGetQrCodePayload) => {
      const { data } = await enableMfaGetQrCode({
        variables: {
          payload,
        },
      })

      return data.enableMfaGetQrCode
    },
    data: data?.enableMfaGetQrCode,
    loading,
    ...mutationProps,
  }
}

/**
 * Enable mfa ; final step
 */
export const useEnableMfaAction = () => {
  const [enableMfa, { data, loading, ...mutationProps }] = useMutation(mutations.enableMfa)

  return {
    enableMfa: async (payload: EnableMfaPayload) => {
      const { data } = await enableMfa({
        variables: {
          payload,
        },
      })

      return data.enableMfa
    },
    data: data?.enableMfa,
    loading,
    ...mutationProps,
  }
}

/**
 * Disable mfa
 */
export const useDisableMfaAction = () => {
  const [disableMfa, { data, loading, ...mutationProps }] = useMutation(mutations.disableMfa)

  return {
    disableMfa: async (payload: DisableMfaPayload) => {
      const { data } = await disableMfa({
        variables: {
          payload,
        },
      })

      return data.disableMfa
    },
    data: data?.disableMfa,
    loading,
    ...mutationProps,
  }
}
