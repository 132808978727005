import { useMemo } from 'react'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'

import { useRoles } from '../../../../modules/users'
import { type AdminUser } from '../../../../modules/adminUsers'
import { Row, Cell } from '../../../../components/cell'

type PrivilegesProps = {
  user: AdminUser
}

const Privileges: React.FC<PrivilegesProps> = ({ user }) => {
  const { data: roles } = useRoles()
  const rolesName = useMemo(() => {
    return Object.fromEntries((roles ?? []).map(({ key, name }) => ([key, name])))
  }, [roles])

  return (
    <Row>
      <Cell>
        <Box display="flex" gap="0.5rem">
          { user.roles?.length > 0
            ? user.roles.map(role => (
              <Chip key={role} label={rolesName[role] ?? role} />
            ))
            : '—' }
        </Box>
      </Cell>
    </Row>
  )
}

export default Privileges
