import { useTranslation } from 'react-i18next'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import { type CustomFiltersProps } from '../../../components/dataGrid'

export const ONLY_MINE_FILTER = 'onlyMine'

/**
 * custom filters to render on top of the DataGrid component
 */
const CustomFilters: React.FC<CustomFiltersProps> = ({
  values,
  setValues,
  disabled,
}) => {
  const { t } = useTranslation()
  const onlyMine = values[ONLY_MINE_FILTER] ?? false

  const onClick = () => {
    setValues({
      ...values,
      [ONLY_MINE_FILTER]: !onlyMine,
    })
  }

  return (
    <div className="hidden md:block">
      <FormControlLabel
        control={(
          <Switch
            checked={onlyMine}
            onClick={onClick}
            disabled={disabled}
          />
        )}
        label={t('pages.admin.prospects.table.filters.onlyMine')}
      />
    </div>
  )
}

export default CustomFilters
