import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import DeleteIcon from '@mui/icons-material/Delete'
import OpenIcon from '@mui/icons-material/FolderShared'
import EditIcon from '@mui/icons-material/Edit'

import Route from '../../../app/Route.enum'
import { useNavigate } from '../../../modules/navigation'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import { useConfirmation } from '../../../components/ConfirmationModal'
import { type Prospect } from '../../../modules/adminProspects/core/prospects.models'
import { useDeleteProspectsAction } from '../../../modules/adminProspects/core/prospects.hooks'

/**
 * returns the list of actions available inside of the data grid
 */
export const useActions = (prospects?: Prospect[]) => {
  const { t } = useTranslation()
  const formatError = useErrorFormatter()
  const { deleteProspects } = useDeleteProspectsAction()
  const { navigate } = useNavigate()
  const { confirm, modalProps } = useConfirmation()

  /**
   * delete prospects
   *  - show confirmation modal
   *  - show results in a toast
   */
  const handleDelete = async (ids: string | string[]) => {
    ids = Array.isArray(ids) ? ids : [ids]
    const count = ids.length

    const title = t('pages.admin.prospects.actions.delete.confirmation.title', { count })
    const question = t('pages.admin.prospects.actions.delete.confirmation.question', { count })

    if (!await confirm({ title, question, danger: true })) {
      return false
    }

    const pending = t('pages.admin.prospects.actions.delete.pending', { count })
    const success = t('pages.admin.prospects.actions.delete.success', { count })

    await toast.promise(
      deleteProspects(ids),
      {
        pending,
        success,
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
  }

  /**
   * edit prospect
   */
  const handleEdit = (id: string) => {
    navigate(Route.EditProspect, { prospectId: id })
  }

  /**
   * go to prospect's detail page
   */
  const handleOpen = (id: string) => {
    navigate(Route.Prospect, { prospectId: id })
  }

  /* format actions for data grid */

  const editAction = {
    label: t('actions.edit'),
    icon: EditIcon,
    callback: handleEdit,
  }

  const deleteAction = {
    label: t('actions.delete'),
    icon: DeleteIcon,
    danger: true,
    callback: handleDelete,
    bulkCallback: handleDelete,
  }

  const openAction = {
    label: t('actions.open'),
    icon: OpenIcon,
    callback: handleOpen,
  }

  /* returns actions and the props needed to render the confirmation modal */

  return {
    actions: [
      editAction,
      deleteAction,
      openAction,
    ],
    modalProps,
  }
}
