import { gql } from '@apollo/client'

import { prospectFields } from './prospects.queries'

export const deleteProspects = gql`
mutation Admin_deleteProspects($ids: [String!]!) {
  admin_deleteProspects(ids: $ids)
}
`

export const updateProspect = gql`
mutation Admin_updateProspect($prospectId: String!, $payload: UpdateProspectDto!) {
  admin_updateProspect(prospectId: $prospectId, payload: $payload) {
    ${prospectFields}
  }
}
`

export const refreshProspectQuotes = gql`
mutation Admin_refreshProspectQuotes($id: String!) {
  admin_refreshProspectQuotes(id: $id) {
    ${prospectFields}
  }
}
`

export const rearrangeProspectQuotes = gql`
mutation Admin_rearrangeProspectQuotes($prospectId: String!, $quotesIds: [String!]!) {
  admin_rearrangeProspectQuotes(prospectId: $prospectId, quotesIds: $quotesIds) {
    ${prospectFields}
  }
}
`
