import 'symbol-observable'
import React from 'react'
import ReactDOM from 'react-dom/client'
import setupYupGenericLocale from '@concrete-form/core/yup/setupYupGenericLocale'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import './newrelic'
import './index.css'
import App from './app/App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'

setupYupGenericLocale()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

/* use register() to allow pwa and unregister to disable it */
serviceWorkerRegistration.unregister()

window.addEventListener('beforeinstallprompt', (event: any) => {
  event.preventDefault()
  ;(window as any).beforeinstallpromptEvent = event
})

reportWebVitals()
