import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useFormState, useWatch } from 'react-hook-form'

import { DateTime } from '../../../form'

type TemporalityDateRangeFormProps = {
  prefixName: string
}

const TemporalityDateRangeForm: React.FC<TemporalityDateRangeFormProps> = ({
  prefixName,
}) => {
  const { t } = useTranslation()

  const fromName = `${prefixName}.from`
  const toName = `${prefixName}.to`
  const form = useFormContext()
  const { submitCount } = useFormState()
  const from = useWatch({ name: fromName })

  /**
   * trigger validation on "to" confirmation" when "from" is updated
   * required since a field can become valid without changing
   */
  useEffect(() => {
    if (submitCount < 1) {
      return
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    form.trigger(toName)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitCount, from])

  return (
    <div className="flex flex-col items-center gap-2 lg:flex-row">
      <div className="flex w-full items-center gap-2">
        <div className="w-[30px] lg:w-auto">
          { t('date.from') }
        </div>
        <DateTime
          name={fromName}
          slotProps={{
            textField: {
              fullWidth: false,
              size: 'small',
              style: { width: 135 },
              InputProps: { className: 'bg-white' },
            },
          }}
        />
      </div>

      <div className="flex w-full items-center gap-2">
        <div className="w-[30px] lg:w-auto">
          { t('date.to') }
        </div>
        <DateTime
          name={toName}
          slotProps={{
            textField: {
              fullWidth: false,
              size: 'small',
              style: { width: 135 },
              InputProps: { className: 'bg-white' },
            },
          }}
        />
        ({ t('forms.companyBranch.availability.temporality.inclusive') })
      </div>
    </div>
  )
}

export default TemporalityDateRangeForm
