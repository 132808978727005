import { type EventProps } from 'react-big-calendar'
import MoneyIcon from '@mui/icons-material/MonetizationOnRounded'

import { type CalendarRuleEvent } from '../Calendar.type'

const EventBox: React.FC<EventProps<CalendarRuleEvent>> = ({ title }) => {
  return (
    <div className="flex items-center pb-[2px]">
      <div className="hidden lg:block">
        <MoneyIcon className="mr-1 !text-[18px]" />
      </div>
      <span className="hidden md:inline">
        { title }
      </span>
    </div>
  )
}

export default EventBox
