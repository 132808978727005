import { format } from 'date-fns/format'
import JobIcon from '@mui/icons-material/AccountCircle'

import { type LightJob } from '../../../../modules/jobs'
import { CalendarSidebarListItem } from '../../../../components/calendar'
import Link, { LinkVariant } from '../../../../components/Link'
import Route from '../../../../app/Route.enum'

type JobItemProps = {
  job: LightJob
}

const JobItem: React.FC<JobItemProps> = ({
  job,
}) => {
  return (
    <CalendarSidebarListItem>
      <div className="flex grow items-center gap-2 text-sm">
        <div className="size-[16px] rounded" style={{ backgroundColor: '#0e4c71' }} />
        <div className="flex items-center gap-x-2 text-neutral-600">
          <JobIcon className="!text-[20px]" />
          <span className="text-xs text-neutral-400">
            { format(job.date, 'MM/dd') }
          </span>
          <Link
            to={Route.Job}
            params={{ jobId: job.id }}
            variant={LinkVariant.Underlined}
          >
            <span>
              { job.customerName }
            </span>
          </Link>
        </div>
      </div>
    </CalendarSidebarListItem>
  )
}

export default JobItem
