import { useState, useRef } from 'react'

import { type Render } from './ConfirmationModal.types'

type ConfirmOptions = {
  question?: string | React.ReactNode
  title?: React.ReactNode
  danger?: boolean
  render?: Render
}

export const useConfirmation = () => {
  const [open, setOpen] = useState(false)
  const [question, setQuestion] = useState<React.ReactNode>()
  const [render, setRender] = useState<Render>()
  const [title, setTitle] = useState<React.ReactNode>()
  const [danger, setDanger] = useState<boolean>()
  const promise = useRef<(args?: any) => any>()

  const onConfirm = (values?: any) => {
    setOpen(false)
    if (promise.current) {
      promise.current(values ?? true)
    }
  }

  const onDecline = () => {
    setOpen(false)
    if (promise.current) {
      promise.current(false)
    }
  }

  const confirm = async ({
    question,
    title,
    danger,
    render: renderCallback,
  }: ConfirmOptions): Promise<any> => {
    if (renderCallback) {
      setQuestion(undefined)
      setRender(() => renderCallback)
    } else {
      setQuestion(question)
      setRender(undefined)
    }

    setTitle(title)
    setDanger(danger)
    setOpen(true)

    return await new Promise((resolve) => {
      promise.current = resolve
    })
  }

  return {
    confirm,
    modalProps: {
      title,
      question,
      render,
      open,
      danger,
      onConfirm,
      onDecline,
    },
  }
}
