import defaultTailwindConfig from 'tailwindcss/defaultConfig'
import { useMediaQuery } from 'react-responsive'
import { useDispatch/*, useSelector */ } from 'react-redux'

// import * as selectors from './theme.selectors'
import * as actions from './theme.actions'

export const useThemeState = () => {
  return {
    // darkModeEnabled: useSelector(selectors.darkModeEnabled),
    // todo : implement dark theme
    darkModeEnabled: false,
  }
}

export const useThemeDarkModeSwitcherAction = () => {
  const dispatch = useDispatch()
  const { darkModeEnabled } = useThemeState()

  return {
    switchDarkMode: () => dispatch(actions.setDarkMode(!darkModeEnabled)),
  }
}

export const useBreakpoint = (breakpointKey: string) => {
  const breakpoints = (defaultTailwindConfig.theme?.screens as Record<string, string>) ?? {}
  return useMediaQuery({
    query: breakpoints ? `(min-width: ${breakpoints[breakpointKey]})` : '',
  })
}
