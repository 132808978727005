import { useTranslation } from 'react-i18next'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import RuleIcon from '@mui/icons-material/Rule'
import PricingIcon from '@mui/icons-material/MonetizationOnOutlined'
import JobIcon from '@mui/icons-material/AccountCircleOutlined'

import { useBreakpoint } from '../../../../modules/theme'
import PageContent from '../../../../components/layout/PageContent'
import { mergeClassName } from '../../../../utils/mergeClassName'
import { Permission, usePermission } from '../../../../modules/users'
import { useCurrentCompanyBranch } from '../../../../modules/companies'

export enum ToggleOption {
  Jobs = 'jobs',
  Prices = 'prices',
  PricingRules = 'pricingRules',
}

type Option = {
  label?: React.ReactNode
  icon?: React.ReactNode
}

type ToolbarProps = {
  selectedOption?: ToggleOption
  onChange?: (selectedOption: ToggleOption) => void
  extraOptions?: React.ReactNode
  children?: React.ReactNode
}

const Toolbar: React.FC<ToolbarProps> = ({
  selectedOption,
  onChange,
  extraOptions,
  children,
}) => {
  const { t } = useTranslation()
  const isLargeScreen = useBreakpoint('lg')
  const canManageOwnJobs = usePermission(Permission.canManageOwnJobs)
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()

  const options: Partial<Record<ToggleOption, Option>> = {}

  if (canManageOwnJobs && currentCompanyBranch?.approved) {
    options[ToggleOption.Jobs] = {
      label: t('pages.companyBranch.availability.header.jobs'),
      icon: <JobIcon className="!text-[18px]" />,
    }
  }

  options[ToggleOption.Prices] = {
    label: t('pages.companyBranch.availability.header.prices'),
    icon: <PricingIcon className="!text-[18px]" />,
  }
  options[ToggleOption.PricingRules] = {
    label: t('pages.companyBranch.availability.header.pricingRules'),
    icon: <RuleIcon className="!text-[18px]" />,
  }

  return (
    <div className="border-b bg-stone-100 py-4">
      <PageContent fullWidth>
        <div className="flex flex-col-reverse items-center justify-between gap-y-4 lg:flex-row">
          <div className="flex w-full flex-col items-center border-t border-zinc-200 pt-4 lg:w-auto lg:flex-row lg:gap-3 lg:border-t-0 lg:pt-0">

            <ToggleButtonGroup
              size="small"
              className="!bg-white"
              fullWidth={!isLargeScreen}
            >
              {
                Object
                  .entries(options)
                  .map(([optionNameString, { label, icon }]) => {
                    const optionName = (optionNameString as unknown as ToggleOption)
                    const selected = selectedOption === optionName
                    return (
                      <ToggleButton
                        key={optionName}
                        value=""
                        selected={selected}
                        className={mergeClassName(
                          selected && '!bg-black !text-white',
                        )}
                        onClick={selected ? undefined : () => { onChange?.(optionName) }}
                      >
                        <div className="mx-2 flex items-center gap-x-2 font-sans text-base normal-case leading-4 lg:text-lg lg:leading-[1.1rem]">
                          { icon }
                          { label }
                        </div>
                      </ToggleButton>
                    )
                  })
              }
            </ToggleButtonGroup>

            { extraOptions }
          </div>
          <div className="flex justify-center gap-3 lg:justify-start lg:pb-0">
            { children }
          </div>
        </div>
      </PageContent>
    </div>
  )
}

export default Toolbar
