import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { type QuotesRequestFormValues } from './QuotesRequestForm.type'
import { useRecordValidation } from '../../../../utils/validation'
import { useAddressValidation } from '../../../form/fields/AddressField'
import { usePlaceValidator } from '../../../form/fields/PlaceField'

type useValidationSchemaOptions = {
  requireDetails?: boolean
  specificAddresses?: boolean
}

const useValidationSchema = ({
  requireDetails = false,
  specificAddresses = false,
}: useValidationSchemaOptions = {}) => {
  const { t } = useTranslation()
  const recordValidation = useRecordValidation()
  const placeValidator = usePlaceValidator()
  const addressValidation = useAddressValidation()

  const today = new Date()

  return Yup.object<QuotesRequestFormValues>().shape({
    movingDate: Yup.date().required().min(today, t('formValidation.dateMustBeInFuture')),
    overnightFees: Yup.string().optional().matches(
      /^[0-9]{0,2}$/,
      t('forms.companyBranch.services.validationErrors.invalidTruckQuantity'),
    ),
    residenceType: requireDetails ? Yup.string().required() : Yup.string().notRequired(),
    nbMen: Yup.number().required().min(1).max(99),
    estimatedMovingLabourTime: Yup.number().required().min(1).max(99),
    estimatedPackingLabourTime: Yup.string().when('estimatedPackingLabourTime', (value) => {
      if (value?.[0]?.length > 0) {
        return Yup.string().matches(/^([0-9]*\.)?[0-9]+$/, t('formValidation.default'))
      } else {
        return Yup.mixed().notRequired()
      }
    }),
    volumeCubicFeet: requireDetails ? Yup.number().required().min(1).max(9999) : Yup.number().notRequired(),
    nbTrucks: recordValidation(
      Yup.string().optional().matches(
        /^[0-9]{0,2}$/,
        t('forms.companyBranch.services.validationErrors.invalidTruckQuantity'),
      ),
    ),
    nbTrucksTotal: Yup.number()
      .min(1, t('forms.companyBranch.services.validationErrors.invalidTotalTrucks'))
      .required(t('forms.companyBranch.services.validationErrors.invalidTotalTrucks')),
    origin: Yup.object({
      address: specificAddresses ? addressValidation : placeValidator,
      nbStairs: Yup.string().matches(/^[0-9]?$/, t('formValidation.default')),
    }),
    destination: Yup.object({
      address: specificAddresses ? addressValidation : placeValidator,
      nbStairs: Yup.string().matches(/^[0-9]?$/, t('formValidation.default')),
    }),
    stops: Yup.array().of(Yup.object({
      address: specificAddresses ? addressValidation : placeValidator,
      nbStairs: Yup.string().matches(/^[0-9]?$/, t('formValidation.default')),
      description: Yup.string().required(),
      stopDuration: Yup.string().matches(/^([0-9](\.[0-9]{1,2})?)$/, t('formValidation.defaultShort')),
    })),
    residenceRooms: recordValidation(
      Yup.string().optional().matches(
        /^[0-9]{0,2}$/,
        t('forms.companyBranch.services.validationErrors.invalidTruckQuantity'),
      ),
    ),
    specialServices: recordValidation(
      Yup.string().optional().matches(
        /^[0-9]{0,2}$/,
        t('formValidation.default'),
      ),
    ),
    specialItems: recordValidation(
      Yup.string().optional().matches(
        /^[0-9]{0,2}$/,
        t('formValidation.default'),
      ),
    ),
    protectiveMaterial: recordValidation(
      Yup.string().optional().matches(
        /^[0-9]{0,2}$/,
        t('formValidation.default'),
      ),
    ),
  }, [
    ['estimatedPackingLabourTime', 'estimatedPackingLabourTime'],
  ]) as Yup.ObjectSchema<any, QuotesRequestFormValues>
}

export default useValidationSchema
