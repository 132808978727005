/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import AvatarEditor from 'react-avatar-editor'
import Button from '@mui/material/Button'
import Slider from '@mui/material/Slider'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import CancelIcon from '@mui/icons-material/CancelOutlined'

import {
  Modal,
  ModalDescription,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '../Modal'
import FilesDropzone from '../FilesDropzone'
import handleSvg from './handleSvg'
import { trimVerticalPixels } from './trimVerticalPixels'
import { FileType } from '../FilesDropzone/fileType.enum'
import Spinner from '../Spinner'

export type ImageOptions = {
  width: number
  height?: number
  trimVertically?: boolean
}

type UploadModalProps = {
  open: boolean
  onSubmit: (image: File) => Promise<void> | void
  onClose: () => void
  imageOptions: ImageOptions
}

const UploadModal: React.FC<UploadModalProps> = ({
  open,
  onSubmit,
  onClose,
  imageOptions,
}) => {
  const {
    width,
    height = imageOptions.width,
    trimVertically = true,
  } = imageOptions

  const { t } = useTranslation()
  const [filename, setFilename] = useState<string>()
  const [uploadedFile, setUploadedFile] = useState<File | string>()
  const [scale, setScale] = useState(1)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const editor = useRef<AvatarEditor>(null)

  const removeFileExtension = (filename: string) => {
    const parts = filename.split('.')
    if (parts.length > 1) {
      parts.pop()
    }
    return parts.join('.')
  }

  useEffect(() => {
    setScale(1)
    if (uploadedFile) {
      setError(false)
    }
  }, [uploadedFile])

  const close = () => {
    resetImage()
    onClose()
  }

  const resetImage = () => {
    setUploadedFile(undefined)
    setFilename(undefined)
  }

  /**
   * handle incoming image
   */
  const onUpload = async ([file]: File[]) => {
    setFilename(removeFileExtension(file.name))
    setUploadedFile(await handleSvg(file))
  }

  /**
   * handle invalid file
   */
  const onUploadError = () => {
    resetImage()
    setError(true)
  }

  /**
   * return results
   */
  const handleSubmit = async () => {
    try {
      setLoading(true)
      await onSubmit(await getResultFile())
    } finally {
      setLoading(false)
    }
  }

  /**
   * returns result file object
   */
  const getResultFile = async (): Promise<File> => {
    return await new Promise((resolve, reject) => {
      if (!editor.current) {
        reject(new Error()); return
      }
      let canvas = editor.current.getImageScaledToCanvas()
      if (trimVertically) {
        canvas = trimVerticalPixels(canvas)
      }
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error()); return
        }
        resolve(new File(
          [blob],
          (filename ?? String(Date.now())) + '.png',
          { type: FileType.PNG },
        ))
      }, FileType.PNG)
    })
  }

  return (
    <Modal
      open={open}
      className="lg:w-[350px]"
      showCloseIcon={false}
    >
      <ModalHeader>
        <ModalTitle>
          { t('components.imageHandler.uploadModal.title') }
        </ModalTitle>
      </ModalHeader>
      <ModalDescription className="my-4 max-h-[calc(100dvh-185px)]">

        { uploadedFile
          ? (
            <div className="flex justify-center">
              <div className="my-1 inline-block">
                <div className="relative bg-transparent-grid">
                  { !loading && (
                    <div className="absolute right-5 top-5">
                      <IconButton color="error" onClick={resetImage}>
                        <CancelIcon className="!text-[32px]" />
                      </IconButton>
                    </div>
                  ) }

                  <AvatarEditor
                    ref={editor}
                    image={uploadedFile}
                    width={width}
                    height={height}
                    border={25}
                    scale={scale}
                    onLoadFailure={onUploadError}
                  />
                </div>
                <div className="py-1">
                  <Slider
                    value={scale}
                    onChange={(_, value) => { setScale(value as number) }}
                    min={0}
                    max={2}
                    step={0.01}
                    disabled={loading}
                  />
                </div>
              </div>
            </div>
            )
          : (
            <>
              { error && (
                <div className="mb-2">
                  <Alert severity="error">
                    { t('components.imageHandler.uploadModal.uploadError') }
                  </Alert>
                </div>
              ) }
              <FilesDropzone
                onUpload={onUpload}
                compact
              />
            </>
            ) }

      </ModalDescription>
      <ModalFooter>
        <Button
          variant="contained"
          color="secondary"
          onClick={close}
          disabled={loading}
        >
          { t('actions.cancel') }
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!uploadedFile || loading}
        >
          { t('actions.upload') }

          { loading && (
            <div className="pl-2">
              <Spinner small />
            </div>
          ) }
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default UploadModal
