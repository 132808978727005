import {
  type Actions,
  ACTION_TYPES,
} from './users.actions'

export const key = 'users'

const initialState = {
  isLoggingOut: false,
}

export const reducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case ACTION_TYPES.SET_IS_LOGGING_OUT: {
      return {
        ...state,
        isLoggingOut: action.isLoggingOut,
      }
    }
    default:
      return state
  }
}
