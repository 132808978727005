export enum ProtectiveMaterial {
  WardrobeBoxRent = 'wardrobeBoxRent',
  WardrobeBoxBuy = 'wardrobeBoxBuy',
  MattressBagQueen = 'mattressBagQueen',
  MattressBagsKing = 'mattressBagsKing',
  BoxSmall = 'boxSmall',
  BoxMedium = 'boxMedium',
  BoxesLarge = 'boxesLarge',
  ArtBox = 'artBox',
  BookBox = 'bookBox',
  DishBarrel = 'dishBarrel',
  TVBox32in = 'tVBox32in',
  TVBox40in = 'tVBox40in',
  TVBox46in = 'tVBox46in',
  TVBox55in = 'tVBox55in',
  TVBox60in = 'tVBox60in',
  TVBox72in = 'tVBox72in',
  BubbleWrapRoll = 'bubbleWrapRoll',
  BoxLabels = 'boxLabels',
  PackingPaper = 'packingPaper',
  ShrinkWrap = 'shrinkWrap',
  Tape = 'tape',
  PackingPeanuts = 'packingPeanuts',
}
