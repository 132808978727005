type MobileContentMenuProps = {
  children?: React.ReactNode
}

const MobileContentMenu: React.FC<MobileContentMenuProps> = ({ children }) => {
  return (
    <div className="my-8 flex flex-col">
      { children }
    </div>
  )
}

export default MobileContentMenu
