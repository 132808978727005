import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import CelebrationIcon from '@mui/icons-material/Celebration'
import DashboardIcon from '@mui/icons-material/SpaceDashboardOutlined'
import AvailabilityIcon from '@mui/icons-material/CalendarMonthOutlined'

import { CompanyBranchTopContent } from '../../../components/companyBranch'
import ContainedPage from '../../../components/layout/ContainedPage'
import PageContent from '../../../components/layout/PageContent'
import Route from '../../../app/Route.enum'
import { useNavigate } from '../../../modules/navigation'
import { useCurrentCompanyBranch, useOnBehalfState } from '../../../modules/companies'
import { Error404Page } from '../../errors'
import { protectPage } from '../../../modules/users'

const CompanyBranchSettingsCompletedPage: React.FC = () => {
  const { t } = useTranslation()
  const { navigate } = useNavigate()
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()
  const { onBehalf } = useOnBehalfState()

  if ((currentCompanyBranch?.setupProgress ?? 0) < 100) {
    return <Error404Page />
  }

  return (
    <ContainedPage topContent={<CompanyBranchTopContent />}>
      <PageContent>
        <div className="my-8 rounded-3xl bg-green-100/50 px-4 py-8 text-center">
          <h1 className="font-body2 text-3xl text-green-700">
            <CelebrationIcon />
            { ' ' }
            { onBehalf
              ? t('pages.companyBranch.completed.onBehalf.title')
              : t('pages.companyBranch.completed.title') }
          </h1>

          <div className="my-5">
            { onBehalf
              ? (
                <p className="py-1">
                  { t('pages.companyBranch.completed.onBehalf.approvalMessage') }
                </p>
                )
              : (
                <>
                  <p className="py-1">
                    { t('pages.companyBranch.completed.approvalMessageLine1') }
                  </p>

                  <p className="py-1">
                    { t('pages.companyBranch.completed.approvalMessageLine2') }
                  </p>
                </>
                ) }

          </div>
        </div>
        <div className="text-center">

          { onBehalf
            ? (
              <div className="my-5">
                <br />
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DashboardIcon />}
                  onClick={() => { navigate(Route.CompaniesOnBehalf) }}
                >
                  { t('pages.companyBranch.completed.continueToDashboard') }
                </Button>
              </div>
              )
            : (
              <>
                <div className="my-5">
                  <strong>{ t('pages.companyBranch.completed.recommendedNext') }</strong><br />
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<AvailabilityIcon />}
                    size="large"
                    onClick={() => { navigate(Route.BranchAvailability) }}
                  >
                    { t('pages.companyBranch.completed.pricingAndAvailability') }
                  </Button>
                </div>

                <div className="my-5">
                  { t('pages.companyBranch.completed.or') }
                  <br />
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<DashboardIcon />}
                    onClick={() => { navigate(Route.Dashboard) }}
                  >
                    { t('pages.companyBranch.completed.continueToDashboard') }
                  </Button>
                </div>
              </>
              ) }

        </div>

      </PageContent>
    </ContainedPage>
  )
}

export default protectPage(CompanyBranchSettingsCompletedPage)
