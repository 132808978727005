import { useTranslation } from 'react-i18next'

import { Position, Tooltip, type TooltipProps } from '../../../Tooltip'
import * as validations from '../../../form/fields/PasswordField/passwordValidator'
import Validation from './Validation'
import { useBreakpoint } from '../../../../modules/theme'

type PasswordTooltipProps = {
  value: string
} & Omit<TooltipProps, 'tooltip'>

const PasswordTooltip: React.FC<PasswordTooltipProps> = ({
  value,
  children,
  ...tooltipProps
}) => {
  const isLargeScreen = useBreakpoint('lg')

  const position = isLargeScreen ? Position.Left : Position.Top
  const offset = position === Position.Top ? 0 : 40
  const className = position === Position.Top ? undefined : '-mt-[20px]'

  return (
    <Tooltip
      arrow
      {...tooltipProps}
      tooltip={<PasswordToolTipContent value={value} />}
      position={position}
      className={className}
      wrapperClassName="block"
      offset={offset}
    >
      { children }
    </Tooltip>
  )
}

const PasswordToolTipContent: React.FC<PasswordTooltipProps> = ({ value }) => {
  const { t } = useTranslation()

  return (
    <div className="p-2">
      <h1 className="mb-2 font-body2 text-lg">
        { t('forms.fields.password.validations.title') }
      </h1>
      <ul>
        <li>
          <Validation
            data-testid="hasCorrectLength"
            valid={validations.hasCorrectLength(value)}
          >
            { t('forms.fields.password.validations.length') }
          </Validation>
        </li>

        <li>
          <Validation
            data-testid="hasCapitalLetter"
            valid={validations.hasCapitalLetter(value)}
          >
            { t('forms.fields.password.validations.capitalLetter') }
          </Validation>
        </li>

        <li>
          <Validation
            data-testid="hasNumber"
            valid={validations.hasNumber(value)}
          >
            { t('forms.fields.password.validations.number') }
          </Validation>
        </li>

        <li>
          <Validation
            data-testid="hasSpecialCaracter"
            valid={validations.hasSpecialCaracter(value)}
          >
            { t('forms.fields.password.validations.specialCharacter') }
          </Validation>
        </li>
      </ul>
    </div>
  )
}

export default PasswordTooltip
