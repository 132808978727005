import { mergeClassName } from '../../../utils/mergeClassName'

type SidebarMenuProps = {
  title?: React.ReactNode
  children?: React.ReactNode
  className?: string
}

const SidebarMenu: React.FC<SidebarMenuProps> = ({
  title,
  className,
  children,
}) => {
  return (
    <div className="pr-8">
      { title && (
        <div className="h-[45px] overflow-hidden text-ellipsis pt-2 text-2xl">
          { title }
        </div>
      ) }

      <div className={mergeClassName(
        'mt-5 flex flex-col rounded-md border p-4',
        !title && 'mt-5',
        className,
      )}
      >
        { children }
      </div>
    </div>
  )
}

export default SidebarMenu
