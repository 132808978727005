import MuiModal from '@mui/material/Modal'
import { XMarkIcon } from '@heroicons/react/24/outline'

import ModalBackdrop from './ModalBackdrop'
import ModalContent from './ModalContent'
import { mergeClassName } from '../../utils/mergeClassName'

type ModalProps = {
  open: boolean
  onClose?: () => void
  showCloseIcon?: boolean
  className?: string
  children?: React.ReactNode
}

const Modal: React.FC<ModalProps> = ({
  open,
  onClose,
  showCloseIcon = true,
  className,
  children,
}) => {
  const onBackdropClick = (event: React.MouseEvent<HTMLElement>) => {
    if (event.target instanceof HTMLElement && event.target.dataset.component === 'backdrop') {
      onClose?.()
    }
  }

  return (
    <MuiModal
      open={open}
      onClose={() => onClose?.()}
      hideBackdrop
      className="z-[9999]"
    >
      <div>
        <ModalBackdrop
          onClick={onBackdropClick}
          data-state={open ? 'open' : 'closed'}
        >
          <div className="flex h-screen flex-col items-center justify-center px-[5-vw] py-[5vh]" data-component="backdrop">
            <ModalContent
              data-state={open ? 'open' : 'closed'}
              className={mergeClassName(
                'overflow-y-auto',
                className,
              )}
            >
              { showCloseIcon && (
                <div
                  className="absolute right-4 top-4 cursor-pointer opacity-70 transition-opacity hover:opacity-100"
                  data-state={open ? 'open' : 'closed'}
                >
                  <XMarkIcon className="size-6" onClick={onClose} />
                </div>
              ) }

              { children }
            </ModalContent>
          </div>
        </ModalBackdrop>
      </div>
    </MuiModal>
  )
}

export default Modal
