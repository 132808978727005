import { type FullMove } from '../../../../../modules/adminMoves'
import QuotesBox from './QuotesBox'

type QuotesTabProps = {
  move: FullMove
}

const QuotesTab: React.FC<QuotesTabProps> = ({
  move,
}) => {
  return (
    <QuotesBox move={move} />
  )
}

export default QuotesTab
