import { toZonedTime } from 'date-fns-tz'
import { differenceInMinutes } from 'date-fns/differenceInMinutes'
import { isPast } from 'date-fns/isPast'
import { isToday } from 'date-fns/isToday'
import { addHours } from 'date-fns/addHours'
import { format } from 'date-fns/format'

import { type FullMove } from '../../../adminMoves'
import { type FullMoveForPartner } from '../../../moves/core/moves.model'

const ONE_HOUR_MS = 60 * 60 * 1000

export const isDateWithin30Mins = (date: Date) => {
  return differenceInMinutes(new Date(), date) <= 30
}

export const dateHasPassed = (date: Date) => isPast(date) || isToday(date)

/**
 * get the move date according to the customer timezone
 */
export const getMoveDate = (date: Date, timezone?: string) => {
  if (!timezone) {
    return date
  }
  return toZonedTime(
    date,
    timezone,
  )
}

/**
 * get timezone diff (in hours) between active user and customer
 */
export const getTimezoneDiff = (timezone?: string) => {
  if (!timezone) {
    return 0
  }
  const now = new Date()
  return (
    toZonedTime(now, timezone).getTime() - now.getTime()
  ) / ONE_HOUR_MS
}

/**
 * current time with timezone diff applied
 */
export const getTimeWithTimeZoneDiff = (timeZoneDiffHours = 0) => {
  return format(addHours(new Date(), timeZoneDiffHours), 'H:mm')
}

/**
 * add missing fields to fullmoveForPartner
 */
export const convertToFullMove = (move: FullMoveForPartner | FullMove): FullMove => {
  return {
    id: '',
    createdAt: new Date(),
    locked: false,
    moveApprovedByAgent: true,
    invoices: [],
    payments: [],
    jobs: [],
    ...move,
    user: {
      email: '',
      ...move.user,
    },
  }
}
