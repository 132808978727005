import {
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client'

import {
  type PaginatedQuery,
  type PaginatedQueryResult,
  type PaginatedQueryResultData,
  type QueryResult,
} from '../../graphQl'
import { type Prospect } from './prospects.models'
import * as queries from './prospects.queries'
import * as mutations from './prospects.mutations'
import { type ProspectPayload } from './prospects.types'

export type ProspectsPaginatedQuery = {
  query?: PaginatedQuery
  onlyMine?: boolean
}

type UseProspectsHookResult = {
  fetchProspects: (variables: ProspectsPaginatedQuery) => Promise<PaginatedQueryResultData<Prospect>>
} & PaginatedQueryResult<Prospect>

/**
 * returns all prospects
 * query optimized for data table
 */
export const useProspects = ({ reload = false, ...queryArgs } = {}): UseProspectsHookResult => {
  const fetchPolicy = reload ? 'network-only' : 'cache-first'

  const [fetch, { data, previousData, networkStatus, loading, error, ...queryProps }] = useLazyQuery(
    queries.getProspects,
    {
      fetchPolicy,
      ...queryArgs,
    },
  )
  const fetchProspects = async (variables?: ProspectsPaginatedQuery) => {
    const { data } = await fetch({ variables })
    return data?.admin_getProspects
  }

  return {
    fetchProspects,
    data: (data ?? previousData)?.admin_getProspects,
    loading: (loading && networkStatus === 1),
    error,
    ...queryProps,
  }
}

/**
 * returns given prospect detail
 */
export const useProspect = (prospectId: string, { skip = false, reload = false, ...queryArgs } = {}): QueryResult<Prospect | undefined> => {
  const fetchPolicy = reload ? 'network-only' : 'cache-first'

  const { data, networkStatus, loading, error, ...queryProps } = useQuery(
    queries.getProspect,
    {
      skip,
      variables: { prospectId },
      fetchPolicy,
      ...queryArgs,
    },
  )

  return {
    data: data?.admin_getProspect,
    loading: (loading && networkStatus === 1),
    error,
    ...queryProps,
  }
}

/**
 * delete prospects permanently
 */
export const useDeleteProspectsAction = () => {
  const [deleteProspects, { data, loading, ...mutationProps }] = useMutation(mutations.deleteProspects)

  return {
    deleteProspects: async (ids: string[]) => {
      const { data } = await deleteProspects({
        variables: {
          ids,
        },
        update (cache) {
          ids.forEach(id => {
            const normalizedId = cache.identify({ id, __typename: 'Prospect' })
            cache.evict({ id: normalizedId })
          })
          cache.gc()
        },
      })
      return data.admin_deleteProspects
    },
    data: data?.admin_deleteProspects,
    loading,
    ...mutationProps,
  }
}

/**
 * update a prospect
 */
export const useUpdateProspectAction = () => {
  const [updateProspect, { data, loading, ...mutationProps }] = useMutation(mutations.updateProspect)

  return {
    updateProspect: async (prospectId: string, payload: ProspectPayload) => {
      const { data } = await updateProspect({
        variables: {
          prospectId,
          payload,
        },
      })

      return data.admin_updateProspect
    },
    data: data?.admin_updateProspect,
    loading,
    ...mutationProps,
  }
}

/**
 * create a prospect
 */
export const useRefreshProspectQuotesAction = () => {
  const [refreshProspectQuotes, { data, loading, ...mutationProps }] = useMutation(mutations.refreshProspectQuotes)

  return {
    refreshProspectQuotes: async (id: string) => {
      const { data } = await refreshProspectQuotes({
        variables: {
          id,
        },
      })

      return data.admin_refreshProspectQuotes
    },
    data: data?.admin_refreshProspectQuotes,
    loading,
    ...mutationProps,
  }
}

/**
 * create a prospect
 */
export const useRearrangeProspectQuotesAction = () => {
  const [rearrangeProspectQuotes, { data, loading, ...mutationProps }] = useMutation(mutations.rearrangeProspectQuotes)

  return {
    rearrangeProspectQuotes: async (prospectId: string, quotesIds: string[]) => {
      const { data } = await rearrangeProspectQuotes({
        variables: {
          prospectId,
          quotesIds,
        },
      })

      return data.admin_rearrangeProspectQuotes
    },
    data: data?.admin_rearrangeProspectQuotes,
    loading,
    ...mutationProps,
  }
}
