export enum Accreditation {
  Bbb = 'bbb',
  Cam = 'cam',
}

export enum BbbRating {
  'A+' = 'a_plus',
  'A' = 'a',
  'A-' = 'a_minus',
  'B+' = 'b_plus',
  'B' = 'b',
  'B-' = 'b_minus',
  'C+' = 'c_plus',
  'C' = 'c',
  'C-' = 'c_minus',
  'D+' = 'd_plus',
  'D' = 'd',
  'D-' = 'd_minus',
  'F' = 'f',
}
