import { type Role } from './permissions.models'

export const getPermissionsInRoles = (roles: Role[], userRoles: string[]) => {
  const permissions = userRoles.map(userRole => {
    const role = roles.find(role => role.key === userRole)
    return role?.permissions ?? []
  }).flat()

  return Array.from(new Set(permissions))
}

export const hasPermission = (userPermissions: string[], permission: string) => {
  return userPermissions.includes(permission)
}
