import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import Alert from '@mui/material/Alert'

import { mergeClassName } from '../../../../utils/mergeClassName'
import { IntegerInput } from '../..'

export type IntegerCollectionFieldProps = {
  name: string
  options: Array<{ value: string, label: string }>
  min?: number
  max?: number
  className?: string
  optionClassName?: string
  labelClassName?: string
  inputClassName?: string
  small?: boolean
}

const IntegerCollectionField: React.FC<IntegerCollectionFieldProps> = ({
  name,
  options,
  min = 0,
  max = 9,
  className,
  optionClassName,
  labelClassName,
  small = false,
}) => {
  const { formState: { errors, defaultValues, touchedFields }, setValue } = useFormContext()
  const totalName = `${name}Total`
  const values = useWatch({ name })
  const currentTotal = useWatch({ name: totalName })

  const controlError = errors[name]
  const totalError = errors[totalName]
  const hasInvalidValues = Object.keys(controlError ?? {}).length > 2
  const editing = !!defaultValues?.[name]
  const touched = touchedFields[name]
  const shouldValidate = touched || editing

  /**
   * Global quantity validation
   * we populate a temporary field to trigger yup global validation
   */
  useEffect(() => {
    if (!shouldValidate || hasInvalidValues) {
      return
    }
    const total = Object.values(values ?? {}).reduce((acc: number, curr: any) => {
      if (!curr) {
        return acc
      }
      return acc + parseInt(curr ?? '')
    }, 0)

    if (total !== currentTotal) {
      setValue(totalName, total, { shouldValidate })
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, hasInvalidValues, shouldValidate, currentTotal])

  return (
    <>
      <div className={mergeClassName(
        'flex flex-col gap-4 md:flex-row md:flex-wrap lg:gap-6 my-6',
        small && 'gap-1 lg:gap-2 my-0',
        className,
      )}
      >
        { options.map(({ value, label }) => {
          const active = parseInt(values?.[value]) > 0

          return (
            <div
              key={value}
              className={mergeClassName(
                'flex items-center justify-between rounded-md bg-neutral-50 text-neutral-500 py-3 px-4 md:basis-[calc(50%-0.75rem)] xl:basis-[calc(33.33%-1rem)]',
                small && 'text-sm px-2 py-1 xl:basis-[calc(50%-0.25rem)]',
                active && 'bg-zinc-200/75 text-black child:text-black',
                optionClassName,
              )}
            >
              <div className={mergeClassName(
                'pr-6 text-left',
                labelClassName,
              )}
              >
                { label }
              </div>
              <IntegerInput
                name={`${name}.${value}`}
                min={min}
                max={max}
              />
            </div>
          )
        }) }
      </div>

      { typeof totalError?.message === 'string' && (
        <div className="mt-4 lg:mt-6">
          <Alert severity="error">
            { totalError?.message }
          </Alert>
        </div>
      ) }
    </>
  )
}

export default IntegerCollectionField
