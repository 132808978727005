import { useExternalEventLogo } from './useExternalEventLogo'

type ExternalEventTitleProps = {
  children?: React.ReactNode
  source: string
}

const ExternalEventTitle: React.FC<ExternalEventTitleProps> = ({
  children,
  source,
}) => {
  const Logo = useExternalEventLogo(source)

  return (
    <div className="flex items-center">
      <div className="hidden lg:block">
        <Logo className="-mt-1 mr-1 !text-[16px]" />
      </div>
      <div className="!text-sm !leading-tight lg:text-base">
        { children }
      </div>
    </div>
  )
}

export default ExternalEventTitle
