import { gql } from '@apollo/client'

export const getExternalEvents = gql`
query GetExternalEvents($companyBranchId: String!) {
  getExternalEvents(companyBranchId: $companyBranchId) {
    start
    end
    title
    source
  }
}
`
