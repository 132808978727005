import { useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import BackIcon from '@mui/icons-material/ArrowBackIosOutlined'

import { useCompaniesBranchesCoordinates } from '../../../modules/adminCompanies'
import { protectAdminPage } from '../../../modules/users'
import ContainedPage from '../../../components/layout/ContainedPage/ContainedPage'
import PageTitle from '../../../components/layout/PageTitle/PageTitle'
import ServerError from '../../../components/errors/ServerError'
import Spinner from '../../../components/Spinner'
import { useNavigate } from '../../../modules/navigation'
import Route from '../../../app/Route.enum'

const CompaniesBranchesMapPage: React.FC = () => {
  const { t } = useTranslation()
  const coordinates = useCompaniesBranchesCoordinates()
  const { navigate } = useNavigate()
  const mapRef = useRef(null)
  const map = useRef<any>()
  const bound = useRef<any>()
  const markers = useRef<any>({})
  const circle = useRef<any>()
  const infoWindow = useRef<any>()

  useEffect(() => {
    if (!coordinates.data?.length) {
      return
    }
    if (!mapRef.current) {
      return
    }
    const Map = (window as any).google.maps.Map
    const LatLngBounds = (window as any).google.maps.LatLngBounds
    const Marker = (window as any).google.maps.Marker
    const InfoWindow = (window as any).google.maps.InfoWindow
    const Circle = (window as any).google.maps.Circle

    if (!map.current) {
      map.current = new Map(mapRef.current, {
        mapTypeId: 'roadmap',
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        styles: [
          {
            elementType: 'geometry',
            stylers: [
              {
                color: '#ececec',
              },
            ],
          },
          {
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#666666',
              },
            ],
          },
          {
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#f5f5f5',
              },
            ],
          },
          {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#bdbdbd',
              },
            ],
          },
          {
            featureType: 'administrative.country',
            elementType: 'geometry.stroke',
            stylers: [
              {
                color: '#a7a7a7',
              },
              {
                weight: 1,
              },
            ],
          },
          {
            featureType: 'administrative.province',
            elementType: 'geometry.stroke',
            stylers: [
              {
                color: '#c9c9c9',
              },
              {
                weight: 2,
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [
              {
                color: '#eeeeee',
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#757575',
              },
            ],
          },
          {
            featureType: 'poi.business',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [
              {
                color: '#e5e5e5',
              },
            ],
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#9e9e9e',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [
              {
                color: '#ffffff',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'road.arterial',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#757575',
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [
              {
                color: '#dadada',
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#616161',
              },
            ],
          },
          {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#9e9e9e',
              },
            ],
          },
          {
            featureType: 'transit',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'transit.line',
            elementType: 'geometry',
            stylers: [
              {
                color: '#e5e5e5',
              },
            ],
          },
          {
            featureType: 'transit.station',
            elementType: 'geometry',
            stylers: [
              {
                color: '#eeeeee',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [
              {
                color: '#c9c9c9',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#9e9e9e',
              },
            ],
          },
          {
            featureType: 'landscape.man_made',
            elementType: 'geometry',
            stylers: [
              {
                color: '#f5f5f5',
              },
            ],
          },
        ],
      })
      bound.current = new LatLngBounds()
      infoWindow.current = new InfoWindow()

      const deselect = () => {
        if (infoWindow.current) {
          infoWindow.current.close()
        }
        if (circle.current) {
          circle.current.setMap(null)
        }
      }

      coordinates.data.forEach(({
        id,
        companyName,
        coordinates: companyBranchCoordinates,
        radius,
      }) => {
        const [longitude, latitude] = companyBranchCoordinates.coordinates
        const position = { lat: latitude, lng: longitude }

        markers.current[id] = new Marker({
          position,
          map: map.current,
        })

        ;(window as any).google.maps.event.addListener(markers.current[id], 'click', ((marker) => {
          return () => {
            infoWindow.current.setContent(companyName)
            infoWindow.current.open(map.current, marker)

            if (circle.current) {
              circle.current.setMap(map.current)
              circle.current.setCenter(marker.position)
              circle.current.setRadius(radius * 1000)
            } else {
              circle.current = new Circle({
                strokeColor: '#fa372c',
                strokeOpacity: 0.8,
                strokeWeight: 1,
                fillColor: '#fa372c',
                fillOpacity: 0.15,
                map: map.current,
                center: marker.position,
                radius: radius * 1000,
              })

              ;(window as any).google.maps.event.addListener(circle.current, 'click', deselect)
            }
          }
        })(markers.current[id]))

        bound.current.extend(markers.current[id].position)
      })

      ;(window as any).google.maps.event.addListener(map.current, 'click', deselect)

      map.current.fitBounds(bound.current)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, coordinates.data])

  if (coordinates.error) {
    return <ContainedPage><ServerError error={coordinates.error} /></ContainedPage>
  }
  if (coordinates.loading) {
    return <ContainedPage><Spinner /></ContainedPage>
  }

  return (
    <ContainedPage>
      <PageTitle fullWidth className="lg:mb-0">
        <div className="flex flex-col justify-between gap-4 lg:flex-row">
          { t('pages.admin.companies.title') }

          <div>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<BackIcon />}
              onClick={() => { navigate(Route.Companies) }}
              className="!mr-4"
            >
              { t('actions.goBack') }
            </Button>
          </div>

        </div>
      </PageTitle>
      <div ref={mapRef} className="-mb-6 h-[calc(100dvh-234px)] w-full" />
    </ContainedPage>
  )
}

export default protectAdminPage(CompaniesBranchesMapPage)
