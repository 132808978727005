import JobRequestIcon from '@mui/icons-material/MoveToInboxOutlined'

type JobRequestTitleProps = {
  children?: React.ReactNode
}

const JobRequestTitle: React.FC<JobRequestTitleProps> = ({ children }) => (
  <div className="flex items-center">
    <div className="hidden lg:block">
      <JobRequestIcon className="-mt-1 mr-1 !text-[16px]" />
    </div>
    <div className="!text-sm !leading-tight lg:text-base">
      { children }
    </div>
  </div>
)

export default JobRequestTitle
