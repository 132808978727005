import { useRef, useState } from 'react'

import { type NotificationsContextData } from './notifications.types'
import { useNotificationsContext } from './notifications.context'

export const useNotificationsEngine = (): NotificationsContextData => {
  const [listening, setListening] = useState<string | null>(null)

  // const listening = useRef<string|null>(null)
  const timeout = useRef<NodeJS.Timeout | null>(null)
  const [notifications, setNotifications] = useState<Record<string, any>>({})

  /**
   * start listening on company branch id
   */
  const startListening = (companyBranchId: string) => {
    clearTimeout(timeout.current ?? undefined)
    timeout.current = null

    if (listening === companyBranchId) {
      return
    }
    setListening(companyBranchId)
  }

  /**
   * stop listening company branch
   */
  const stopListening = () => {
    clearTimeout(timeout.current ?? undefined)
    timeout.current = setTimeout(() => {
      setListening(null)
    }, 100)
  }

  /**
   * set notification data in the local store
   */
  const set = (key: string, data: any) => {
    setNotifications((values) => ({ ...values, [key]: data }))
  }

  /**
   * retrive notification data from the local store
   */
  const get = (key: string) => {
    return notifications[key]
  }

  return {
    listening,
    startListening,
    stopListening,
    set,
    get,
  }
}

/**
 * get notification context
 */
export const useNotifications = () => {
  return useNotificationsContext()
}
