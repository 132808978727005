import { type ConcreteFormProps, type Translator } from '@concrete-form/core'
import ConcreteForm from '@concrete-form/react-hook-form'
import { type UseFormReturn, type SubmitHandler } from 'react-hook-form'
import i18n from 'i18next'
import useBackendValidationErrors from './useBackendValidationErrors'

type ReactHookFormProps = {
  form?: UseFormReturn<any>
  onSubmit?: SubmitHandler<any>
} & ConcreteFormProps

const BackendErrorBinder: React.FC = () => {
  useBackendValidationErrors()
  return null
}

const translator: Translator = item => {
  if (typeof item === 'string') {
    return item
  }
  return i18n.t(`formValidation.${item.key}`, item.values ?? {})
}

/**
 * concrete-form wrapper
 *  - errors are translated by i18next
 *  - backend validation errors are associated to form fields if present
 */
const Form: React.FC<ReactHookFormProps> = ({
  children,
  ...formProps
}) => {
  return (
    <ConcreteForm {...formProps} translator={translator}>
      <BackendErrorBinder />
      { children }
    </ConcreteForm>
  )
}

export default Form
