import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { mergeClassName } from '../../../utils/mergeClassName'
import { type Quote } from '../../../modules/quotes'
import { type PromoCode } from '../../../modules/adminPromoCodes'
import QuoteHeader from './QuoteHeader'
import QuoteContent from './QuoteContent'

type QuoteComponentProps = {
  quote: Quote
  promoCode?: PromoCode
  presentationMode?: boolean
  customerSelected?: boolean
  best?: boolean
  className?: string
  children?: React.ReactNode
}

const QuoteComponent: React.FC<QuoteComponentProps> = ({
  quote,
  promoCode,
  presentationMode = false,
  customerSelected = false,
  best = false,
  className,
  children,
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(presentationMode || customerSelected)

  const highlighted = best || customerSelected

  const onClick = () => {
    if (presentationMode) {
      return
    }
    if (open) {
      close(); return
    }
    setOpen(true)
  }

  const close = () => {
    setOpen(false)
  }

  return (

    <div
      className={mergeClassName(
        'bg-white relative grow rounded-md border shadow transition-all duration-200 hover:scale-[1.01] hover:shadow-xl min-h-[170px]',
        !presentationMode && 'cursor-pointer',
        open && 'scale-[1.01] shadow-xl border-2 border-gray-300',
        highlighted && 'border-2 border-gray-600',
        className,
      )}
      onClick={onClick}
    >

      { highlighted && (
        <div className="absolute -left-1 -top-1 size-16 overflow-hidden">
          <div className="absolute left-[-25px] top-[10px] w-[100px] -rotate-45 bg-gray-600 py-1 text-center text-xs text-white">
            { customerSelected ? t('quotes.quote.selected') : t('quotes.quote.best') }
          </div>
        </div>
      ) }

      <QuoteHeader
        quote={quote}
        promoCode={promoCode}
        presentationMode={presentationMode}
        open={open}
        highlighted={highlighted}
      />

      { open && (
        <QuoteContent
          quote={quote}
          promoCode={promoCode}
          presentationMode={presentationMode}
        />
      ) }

      <div
        className={mergeClassName(
          'mb-2 md:mb-0 md:bg-gradient-to-tr from-white via-zinc-50/10 to-zinc-200/20',
          'py-3 px-4 rounded-b-md flex justify-center md:justify-end md:mt-0',
          !open && 'mt-6',
        )}
      >
        <div
          className="inline-flex"
          onClick={(event: React.MouseEvent) => { event.stopPropagation() }}
        >
          { children }
        </div>
      </div>

    </div>
  )
}

export default QuoteComponent
