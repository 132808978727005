import { mergeClassName } from '../../utils/mergeClassName'

type ReactTh = React.DetailedHTMLProps<React.ThHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>

export type ColumnProps = {
  expand?: boolean
  right?: boolean
  center?: boolean
  noWrap?: boolean
} & ReactTh

const Column: React.FC<ColumnProps> = ({
  className,
  children,
  expand = false,
  right = false,
  center = false,
  noWrap = true,
  ...props
}) => {
  return (
    <th
      className={mergeClassName(
        'text-left h-12 align-middle pt-3 pb-5 px-6 first:pl-4 last:pr-4 text-sm font-body2',
        expand && 'w-full',
        right && 'text-right',
        center && 'center',
        noWrap && 'whitespace-nowrap',
        className,
      )}
      {...props}
    >
      { children }
    </th>
  )
}

export default Column
