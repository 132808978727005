import { type Address } from './address.model'

export const formatAddress = (address?: Partial<Address>) => {
  if (!address?.street) {
    return
  }

  const parts = []
  if (address?.apartment) {
    parts.push(`${address.apartment} - ${address.street}`)
  } else {
    parts.push(address.street)
  }

  if (address?.city) {
    parts.push(address.city)
  }
  if (address?.region) {
    if (address?.country) {
      parts.push(`${address.region} (${address.country})`)
    } else {
      parts.push(address.region)
    }
  }
  if (address?.postalCode) {
    parts.push(address.postalCode)
  }

  return parts.join(', ')
}

export const formatAddressMultiLines = (address?: Partial<Address>, compact = false) => {
  if (!address?.street) {
    return
  }
  return (
    <>
      <div>
        { address.apartment && <>{ address.apartment } - </> }
        { address.street }
      </div>

      { compact
        ? (
          <div>
            { address.city }, { address.region }
            { ' ' }
            { address.postalCode }
          </div>
          )
        : (
          <>
            <div>{ address.city }, { address.region }</div>
            <div>{ address.postalCode }, { address.country }</div>
          </>
          ) }
    </>
  )
}
