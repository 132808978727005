import { type FullMove } from '../../../adminMoves'
import { MoveStatus } from './moveStatus.types'
import { useMoveState } from './moveState.hooks'

export const useMoveStatus = (move?: FullMove) => {
  const {
    isHot,
    nbQuotes,
    selectedQuote,
    isAcceptedByCustomer,
    isAcceptedByPartner,
    isApprovedByAgent,
    moveDateHasPassed,
  } = useMoveState().forMove(move)

  if (!move) {
    return
  }

  /* check for AgentMeetingBooked */
  if (move.calendlyScheduledEventUri) {
    return MoveStatus.AgentMeetingBooked
  }

  /* check for Abandoned */
  /* note: since we can't handle booked move yet, an agent approbation will prevent abandon */
  if (moveDateHasPassed && !isApprovedByAgent) {
    return MoveStatus.Abandoned
  }

  /* check for NotSupported */
  if (nbQuotes === 0) {
    return MoveStatus.NotSupported
  }

  /* check for JobAcceptedByPartner */
  if (isAcceptedByPartner) {
    return MoveStatus.JobAcceptedByPartner
  }

  /* check for isApprovedByAgent */
  if (isApprovedByAgent) {
    return MoveStatus.MoveApprovedByAgent
  }

  /* check for QuoteAccepted */
  if (isAcceptedByCustomer) {
    return MoveStatus.QuoteAccepted
  }

  /* check for Selected */
  if (selectedQuote) {
    return MoveStatus.Selected
  }

  /* check for Hot */
  if (isHot) {
    return MoveStatus.Hot
  }

  /* other state = pending */
  return MoveStatus.Pending
}
