import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete'
import { useWatch } from 'react-hook-form'
import MarkerIcon from '@mui/icons-material/RoomOutlined'
import { type AutocompleteChangeReason } from '@mui/material/Autocomplete'

import { Autocomplete, Label } from '../..'
import AddressLine from './AddressLine'
import type Address from './Address.type'
import { getAddressDetails } from './util'
import { useLanguage } from '../../../../modules/i18n'

const GOOGLE_MAP_API_DEBOUNCE = 300

type AddressAutocompleteControlProps = {
  name: string
  required?: boolean
  country: string
  onSelect: (address: Address) => void
}

const AddressAutocompleteControl: React.FC<AddressAutocompleteControlProps> = ({
  name,
  required,
  country,
  onSelect,
}) => {
  const { t } = useTranslation()
  const value = useWatch({ name })
  const [streetName, setStreetName] = useState(value?.street ?? '')
  const [loading, setLoading] = useState(false)
  const language = useLanguage()

  const {
    suggestions: { data: places },
    setValue: setPlaceValue,
  } = usePlacesAutocomplete({
    cacheKey: `country-${country}`,
    requestOptions: {
      componentRestrictions: {
        country: country.toLowerCase(),
      },
    },
    debounce: GOOGLE_MAP_API_DEBOUNCE,
  })

  const onInputChange = (_: unknown, newValue: string, reason: string) => {
    if (reason !== 'input') {
      return
    }
    setStreetName(newValue)
    setPlaceValue(newValue)
  }

  const onPlaceSelect = (event: React.SyntheticEvent<Element, Event>, newValue: any, reason: AutocompleteChangeReason, ...rest: any) => {
    event.preventDefault()
    const placeId = newValue?.place_id
    /* istanbul ignore if ; not testing invalid api response */
    if (!placeId) {
      return
    }
    setLoading(true)
    getDetails({ placeId, language } as any) // missing optionnal "language" parameters in "usePlacesAutocomplete"
      .then(details => {
        const components = typeof details !== 'string' && details.address_components
        /* istanbul ignore if ; not testing invalid api response  */
        if (!components) {
          return
        }
        const address = getAddressDetails(components)
        /* istanbul ignore if ; not testing invalid api response  */
        if (!address.street) {
          return
        }

        setStreetName(address.street)
        onSelect(address)
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Label label={t('components.formFields.AddressField.fields.streetAddress.label')} required={required}>
      <Autocomplete
        textFieldProps={{ required, inputProps: { maxLength: 255 } }}
        freeSolo
        filterSelectedOptions
        name={`${name}.street`}
        onInputChange={onInputChange}
        onChange={onPlaceSelect}
        disabled={loading}
        options={places}
        getOptionLabel={(data: any) => data?.description ?? streetName}
        renderOption={(props, data) => (
          <AddressLine {...props}>
            <MarkerIcon color="primary" sx={{ mr: 1 }} /> { data?.description }
          </AddressLine>
        )}
        size="small"
      />
    </Label>
  )
}

export default AddressAutocompleteControl
