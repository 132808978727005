import * as Yup from 'yup'

import { type PricingRuleFormValues } from './PricingRuleForm.type'
import useTemporalityValidationSchema from '../TemporalityForm/validationSchema'
import { usePriceValidation } from '../../../../modules/companies'

type UseValidationSchemaOptions = {
  hasPackingService?: boolean
}

const useValidationSchema = ({
  hasPackingService = false,
}: UseValidationSchemaOptions = {}) => {
  const temporalityValidation = useTemporalityValidationSchema()

  const getPriceValidator = usePriceValidation()
  const validatePrice = (required = true) => getPriceValidator({
    allowDecimal: false,
    allowZero: false,
    required,
    isBasePrice: true,
  })

  const labourPriceValidation = Yup.string().test((value, { createError, options, parent }) => {
    const index = parseInt((options as any)?.index ?? '0')
    const valuesAfter = parent.slice(index + 1).filter((v: string) => v !== '' && v !== undefined)
    const required = index === 0 || valuesAfter.length > 0
    try {
      validatePrice(required).validateSync(value)
    } catch (error) {
      return createError({ message: (error as any).message })
    }
    return true
  })

  let shape: Yup.ObjectShape = {
    temporality: temporalityValidation,
    movingLabour: Yup.array().of(labourPriceValidation),
    movingLabourExtraMen: validatePrice(true),
    movingLabourSingleMan: validatePrice(false),
  }

  if (hasPackingService) {
    shape = {
      ...shape,
      packingLabour: Yup.array().of(labourPriceValidation),
      packingLabourExtraMen: validatePrice(true),
      packingLabourSingleMan: validatePrice(false),
    }
  }

  return Yup.object(shape) as Yup.ObjectSchema<PricingRuleFormValues, PricingRuleFormValues>
}

export default useValidationSchema
