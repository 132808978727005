import { useTranslation } from 'react-i18next'
import AgentIcon from '@mui/icons-material/HeadsetMicOutlined'
import EditIcon from '@mui/icons-material/Edit'

import { type FullMove } from '../../../../../modules/adminMoves'
import { useMoveState } from '../../../../../modules/moves'
import { useDateFormatter } from '../../../../../utils/date'
import Paper from '../../../../../components/Paper'
import { Step } from '../../../../../components/Steps'
import { Quote } from '../../../../../components/quotes'
import MoveActionButton from '../../MoveActionButton'
import { ActionName } from '../../useActions'

type EstimateBoxProps = {
  move: FullMove
}

const EstimateBox: React.FC<EstimateBoxProps> = ({
  move,
}) => {
  const { t } = useTranslation()
  const formatDate = useDateFormatter()
  const {
    selectedQuote,
    hasCustomQuote,
    isAcceptedByCustomer,
    promoCode,
  } = useMoveState().forMove(move)

  if (!selectedQuote) {
    return null
  }

  return (
    <Paper className="child:p-0 child:lg:p-0">
      <Step
        name={isAcceptedByCustomer
          ? t('pages.admin.move.selectedQuote.accepted')
          : t('pages.admin.move.selectedQuote.notAccepted')}
        details={move.quoteAccepted?.date ? formatDate(move.quoteAccepted.date) : undefined}
        completed={isAcceptedByCustomer}
        className="border-b"
        forceVisibleDetails
      />

      { hasCustomQuote && (
        <div className="flex items-center gap-x-4 border-b bg-slate-50 p-4 leading-tight text-gray-700">
          <span className="flex size-4 shrink-0 items-center justify-center rounded-full bg-slate-500 text-white lg:ml-2 lg:size-8">
            <AgentIcon className="!text-[12px] lg:!text-[18px]" />
          </span>

          { t('pages.admin.move.selectedQuote.customQuote') }
        </div>
      ) }

      <div className="my-8 mr-6 flex items-center justify-end gap-x-4">
        <MoveActionButton
          move={move}
          actionName={ActionName.CancelSelectedQuote}
        />

        <MoveActionButton
          move={move}
          actionName={ActionName.SendQuoteEmail}
          overrideAction={{
            label: t('actions.sendByEmail'),
          }}
        />

        <MoveActionButton
          move={move}
          actionName={ActionName.SelectAndCustomizeQuoteAction}
          args={selectedQuote.id}
          overrideAction={{
            label: t('actions.edit'),
            Icon: EditIcon,
          }}
        />
      </div>

      <Quote
        quote={selectedQuote}
        promoCode={promoCode}
        presentationMode
        className="scale-1 hover:scale-1 border-none shadow-none hover:shadow-none"
      />
    </Paper>
  )
}

export default EstimateBox
