import ReactCountryFlag from 'react-country-flag'
import Language from '../../app/Language.enum'
import { useTranslation } from 'react-i18next'

type LanguageFlagProps = {
  language: Language
}

const LanguageFlag: React.FC<LanguageFlagProps> = ({
  language,
}) => {
  const { t } = useTranslation()

  const getCountryFromLanguage = () => {
    switch (language) {
      case Language.En:
        return 'US'

      case Language.Fr:
        return 'FR'

      default:
        return language
    }
  }

  return (
    <ReactCountryFlag
      countryCode={getCountryFromLanguage()}
      svg
      title={t(`languages.${language}`)}
    />
  )
}

export default LanguageFlag
