import { useTranslation } from 'react-i18next'
import LegalIcon from '@mui/icons-material/Policy'

import { Tax } from '../../../../modules/taxes'
import { type FullCompanyBranch } from '../../../../modules/adminCompanies'
import {
  Accreditation,
  BbbRating,
} from '../../../../modules/companies'
import { formatAddressMultiLines } from '../../../../common/address/formatAddress'
import { formatPhoneNumber } from '../../../../common/phoneNumber'
import { useDateFormatter } from '../../../../utils/date'
import { capitalizeFirstLetter } from '../../../../utils/string'
import Paper from '../../../../components/Paper'
import {
  Cell,
  Label,
  Row,
  Value,
} from '../../../../components/cell'
import { ReactComponent as BbbLogo } from '../../../../assets/bbb/logo.svg'
import { ReactComponent as CamLogo } from '../../../../assets/cam/logo.svg'
import Title from './Title'

const EMPTY_PLACEHOLDER = '—'

type LegalInformationProps = {
  companyBranch: FullCompanyBranch
}

const LegalInformation: React.FC<LegalInformationProps> = ({
  companyBranch,
}) => {
  const { t } = useTranslation()
  const formatDate = useDateFormatter()
  const legal = companyBranch.legal
  if (!legal) {
    return null
  }

  return (
    <>
      <Title><LegalIcon /> { t('pages.companyBranch.menu.legal') }</Title>

      <Paper>

        <Row>
          <Cell>
            <Label>{ t('forms.fields.companyLegalName.label') }</Label>
            <Value>{ capitalizeFirstLetter(legal.legalName) ?? EMPTY_PLACEHOLDER }</Value>
          </Cell>

          <Cell>
            <Label>{ t('forms.fields.companyNumber.label') }</Label>
            <Value>{ legal.companyNumber ? legal.companyNumber : EMPTY_PLACEHOLDER }</Value>
          </Cell>
        </Row>

        <Row>
          <Cell>
            <Label>{ t('forms.fields.incorporationDate.label') }</Label>
            <Value>{ legal.dateOfIncorporation ? formatDate(legal.dateOfIncorporation) : EMPTY_PLACEHOLDER }</Value>
          </Cell>
        </Row>

        <Row>
          <Cell>
            <div className="mb-4 mt-3 font-extrabold">
              { t('forms.companyBranch.legal.companyAddress') }
            </div>

            <Value>{ formatAddressMultiLines(legal.companyAddress) }</Value>
          </Cell>

          <Cell>
            <div className="mb-4 mt-3 font-extrabold">
              { t('forms.companyBranch.legal.legalOwner') }
            </div>

            <Label>{ t('forms.companyBranch.legal.legalOwnerName') }</Label>
            <Value>
              { capitalizeFirstLetter(legal.owner.firstName) }
              { ' ' }
              { capitalizeFirstLetter(legal.owner.lastName) }
            </Value>

            { legal.owner.phoneNumber && (
              <div className="mt-4">
                <Label>{ t('forms.fields.ownerPhoneNumber.label') }</Label>
                { formatPhoneNumber(legal.owner.phoneNumber) }
              </div>
            ) }
          </Cell>

        </Row>

        { (legal.taxes?.length ?? 0) > 0 && (
          <>
            <div className="mb-2 mt-4 font-extrabold">
              { t('forms.companyBranch.legal.taxNumbers') }
            </div>
            <Row>
              { legal.taxes?.filter(({ key, value }) => {
                if (!value) {
                  return false
                }
                if (legal.companyAddress.country === 'CA') {
                  return [Tax.Gst, Tax.Hst, Tax.Pst].includes(key)
                }
                if (legal.companyAddress.country === 'US') {
                  return [Tax.Ein].includes(key)
                }
                return false
              }).map(({ key, value }) => (
                <Cell key={key}>
                  <Label>{ t(`taxes.${key}`) }</Label>
                  <Value>{ value ?? EMPTY_PLACEHOLDER }</Value>
                </Cell>
              )) }
            </Row>
          </>
        ) }

        { (legal.accreditations?.length ?? 0) > 0 && (
          <>
            <div className="mb-3 mt-6 font-extrabold">
              { t('forms.companyBranch.legal.accreditations') }
            </div>
            <div className="flex items-center gap-5 pt-2">
              { legal.accreditations?.includes(Accreditation.Bbb) && (
                <div className="text-center">
                  <BbbLogo className="w-[40px]" />
                  { legal.bbbRating && Object.keys(BbbRating)[Object.values(BbbRating).indexOf(legal.bbbRating)] }
                </div>
              ) }
              { legal.accreditations?.includes(Accreditation.Bbb) && (
                <div className="pb-4">
                  <CamLogo className="w-[80px]" />
                </div>
              ) }
            </div>
          </>
        ) }

      </Paper>
    </>
  )
}

export default LegalInformation
