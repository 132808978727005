import { useTranslation } from 'react-i18next'
import WarningIcon from '@mui/icons-material/WarningAmber'

import { type Quote } from '../../../../modules/quotes'

type QuoteConstraintsProps = {
  quote: Quote
}

const QuoteConstraints: React.FC<QuoteConstraintsProps> = ({
  quote,
}) => {
  const { t } = useTranslation()

  return (
    <div className="mb-3 flex flex-col gap-2 2xl:mb-6">
      { quote.constraints?.map(constraint => (
        <div key={constraint} className="flex items-center gap-1 rounded bg-orange-100 px-3 py-1 text-sm text-yellow-700">
          <WarningIcon className="!text-[22px] !text-yellow-700" />
          { ' ' }
          { t(`quotes.constraintsLegacy.${constraint}`) }
        </div>
      )) }
    </div>
  )
}

export default QuoteConstraints
