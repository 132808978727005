import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import ContainedPage from '../../../components/layout/ContainedPage'
import { protectPage, useLogoutAction } from '../../../modules/users'
import ProfileHeader from '../../../components/users/profile/ProfileHeader'
import MobileHeader from '../../../components/layout/MobileHeader/MobileHeader'
import { useLocation, useNavigate } from '../../../modules/navigation'
import Route from '../../../app/Route.enum'
import { useBreakpoint } from '../../../modules/theme'
import { Outlet } from 'react-router-dom'
import MobileContentMenu, { MobileContentMenuLink } from '../../../components/MobileContentMenu'

const ProfilePage: React.FC = () => {
  const { t } = useTranslation()
  const isLargeScreen = useBreakpoint('lg')
  const { navigate } = useNavigate()
  const { pathname } = useLocation()
  const { logout } = useLogoutAction()

  const isLandingPage = pathname === Route.Profile
  const shouldShowMobileContentMenu = isLandingPage && !isLargeScreen
  const shouldRedirectToFirstStep = isLandingPage && isLargeScreen

  const onLogout = (event: React.MouseEvent) => {
    event.preventDefault()
    logout()
  }

  useEffect(() => {
    if (shouldRedirectToFirstStep) {
      navigate(Route.ProfileIdentification)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRedirectToFirstStep])

  return (
    <>
      <Outlet />
      { shouldShowMobileContentMenu && (
        <ContainedPage
          topContent={<ProfileHeader />}
          mobileHeader={<MobileHeader title={String(t('pages.users.profile.title'))} />}
        >
          <MobileContentMenu>
            <MobileContentMenuLink to={Route.ProfileIdentification}>
              { t('pages.users.profile.identification.title') }
            </MobileContentMenuLink>

            <MobileContentMenuLink to={Route.ProfileSecurity}>
              { t('pages.users.profile.security.title') }
            </MobileContentMenuLink>

            <MobileContentMenuLink to={Route.ProfileDeleteAccount}>
              { t('pages.users.profile.deleteAccount.title') }
            </MobileContentMenuLink>

            <MobileContentMenuLink to="#" onClick={onLogout} showChevron={false}>
              { t('header.accountMenu.logout') }
            </MobileContentMenuLink>
          </MobileContentMenu>
        </ContainedPage>
      ) }
    </>
  )
}

export default protectPage(ProfilePage)
