import { useTranslation } from 'react-i18next'

import { type LightJob } from '../../../../modules/jobs'
import { type ExternalEvent } from '../../../../modules/companies'
import { type LightJobRequest } from '../../../../modules/jobRequests'
import {
  CalendarSidebar,
  CalendarSidebarList,
  CalendarSidebarNoData,
  CalendarSidebarTitle,
} from '../../../../components/calendar'
import JobItem from './JobListItem'
import ExternalEventItem from './ExternalEventItem'
import JobRequestEventItem from './JobRequestEventItem'

type JobListProps = {
  jobs?: LightJob[]
  jobRequests?: LightJobRequest[]
  externalEvents?: ExternalEvent[]
}

const JobList: React.FC<JobListProps> = ({
  jobs = [],
  jobRequests = [],
  externalEvents = [],
}) => {
  const { t } = useTranslation()

  const getExternalEventKey = (externalEvent: ExternalEvent) => {
    return [
      externalEvent.source,
      new Date(externalEvent.start).toISOString(),
      new Date(externalEvent.end).toISOString(),
      String(externalEvent.title),
    ].join(':')
  }

  return (
    <CalendarSidebar>

      <CalendarSidebarTitle top>
        { t('pages.companyBranch.jobs.sidebar.jobs') }
      </CalendarSidebarTitle>

      {
        jobs.length > 0
          ? (
            <CalendarSidebarList>
              { jobs.map((job) => (
                <JobItem
                  key={job.id}
                  job={job}
                />
              )) }
            </CalendarSidebarList>
            )
          : (
            <CalendarSidebarNoData>
              { t('pages.companyBranch.jobs.sidebar.noJobs') }
            </CalendarSidebarNoData>
            )
      }

      { !!jobRequests.length && (
        <>
          <CalendarSidebarTitle>
            { t('pages.companyBranch.jobs.sidebar.jobRequests') }
          </CalendarSidebarTitle>

          <CalendarSidebarList>

            { jobRequests.map((jobRequest) => (
              <JobRequestEventItem
                key={jobRequest.id}
                jobRequest={jobRequest}
              />
            )) }

          </CalendarSidebarList>
        </>
      ) }

      { !!externalEvents.length && (
        <>
          <CalendarSidebarTitle>
            { t('pages.companyBranch.jobs.sidebar.yourCalendar') }
          </CalendarSidebarTitle>

          <CalendarSidebarList>

            { externalEvents.map((externalEvent) => (
              <ExternalEventItem
                key={getExternalEventKey(externalEvent)}
                externalEvent={externalEvent}
              />
            )) }

          </CalendarSidebarList>
        </>
      ) }

    </CalendarSidebar>
  )
}

export default JobList
