import { gql } from '@apollo/client'

export const getDashboardMetrics = gql`
query Admin_getAdminDashboardMetrics {
  admin_getAdminDashboardMetrics {
    users {
      currentlyOnline
      totalPartners
      registeredPartners {
        label
        value
      }
    }
    companies {
      activeBranches
    }
    moves {
      lastMonthAcceptedQuotesValue {
        count
        label
        revenue
      }
      lastYearAcceptedQuotesValue {
        count
        label
        revenue
      }
      previousMonthAcceptedQuotesValue {
        count
        revenue
      }
      topCompanies {
        count
        label
        revenue
      }
      topSources {
        count
        label
        revenue
      }
      lastMonthConversions {
        receivedQuotes
        acceptedQuotes
        agentRequired
        meetingBooked
      }
    }
  }
}
`
