import { useTranslation } from 'react-i18next'

export const useErrorFormatter = () => {
  const { t } = useTranslation()

  return (error?: any) => {
    if (!error?.message || /^Response not successful/.test(error.message)) {
      return t('errors.generic')
    }

    /* class-validator's errors */

    if (error.message === 'VALIDATION_ERROR') {
      return t('errors.validation')
    }

    /* error from graphql server */

    if ((error.graphQLErrors?.length ?? 0) > 0) {
      const hasInvalidGQL = !!error.graphQLErrors.find((error: any) => error.extensions?.code === 'BAD_USER_INPUT')
      const hasServerError = !!error.graphQLErrors.find((error: any) => error.extensions?.code === 'INTERNAL_SERVER_ERROR')

      if (hasInvalidGQL || hasServerError) {
        return t('errors.generic')
      }

      /* this is a user friendly/translated message */
      return error.message
    }

    /* fallback to a generic message */

    return t('errors.generic')
  }
}
