import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'

import { useNavigate } from '../../../modules/navigation'
import { protectAdminPage, useRoles } from '../../../modules/users'
import { useUsers } from '../../../modules/adminUsers'
import { SortDirection } from '../../../modules/graphQl'
import { capitalizeFirstLetter } from '../../../utils/string'
import ContainedPage from '../../../components/layout/ContainedPage'
import PageTitle from '../../../components/layout/PageTitle'
import Spinner from '../../../components/Spinner'
import ServerError from '../../../components/errors/ServerError'
import { useActions } from './useActions'
import CustomFilters, { ADMIN_ONLY_FILTER } from './CustomFilters'
import ConfirmationModal from '../../../components/ConfirmationModal'
import {
  DataGrid,
  Column,
  Row,
  Cell,
  BooleanValue,
  DateValue,
  TimeAgoValue,
  TagValue,
  type GridQuery,
  SmallValue,
} from '../../../components/dataGrid'
import PageContent from '../../../components/layout/PageContent'

const UsersPage: React.FC = () => {
  const { t } = useTranslation()
  const { navigate } = useNavigate()
  const { fetchUsers, data: users, error: usersErrors } = useUsers({ reload: true })
  const { data: roles, loading: loadingRoles, error: rolesErrors } = useRoles()
  const rolesName = useMemo(() => {
    return Object.fromEntries((roles ?? []).map(({ key, name }) => ([key, name])))
  }, [roles])
  const { actions, modalProps } = useActions(users?.data)

  const onQueryUpdate = useCallback(async ({ custom, ...query }: GridQuery) => {
    const adminOnly = custom?.[ADMIN_ONLY_FILTER]
    return await fetchUsers({ query, adminOnly })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loadingRoles) {
    return <ContainedPage><Spinner floating /></ContainedPage>
  }

  if (!!usersErrors || !!rolesErrors) {
    return (
      <ContainedPage>
        { usersErrors && <ServerError error={usersErrors} /> }
        { rolesErrors && <ServerError error={rolesErrors} /> }
      </ContainedPage>
    )
  }

  const handleRowClick = (id: string, newTab = false) => {
    navigate(`/users/${id}`, {}, newTab)
  }

  return (
    <ContainedPage>
      <PageTitle fullWidth>
        { t('pages.admin.users.title') }
      </PageTitle>
      <PageContent fullWidth className="py-8">

        <DataGrid
          name="users"
          count={users?.count ?? 0}
          onQueryUpdate={onQueryUpdate}
          showToggleDeleted
          initialQuery={{ sortField: 'createdAt', sortDirection: SortDirection.DESC }}
          actions={actions}
          customFilters={CustomFilters}
          saveCustomFilterValues={[ADMIN_ONLY_FILTER]}
          columns={(
            <>
              <Column name="createdAt" sortable>
                { t('pages.admin.users.table.columns.createdAt') }
              </Column>

              <Column name="firstName" sortable>
                { t('pages.admin.users.table.columns.firstName') }
              </Column>

              <Column name="lastName" sortable>
                { t('pages.admin.users.table.columns.lastName') }
              </Column>

              <Column name="email" sortable>
                { t('pages.admin.users.table.columns.email') }
              </Column>

              <Column name="emailVerified" sortable>
                { t('pages.admin.users.table.columns.emailVerified') }
              </Column>

              <Column name="mfaEnabled" sortable>
                { t('pages.admin.users.table.columns.mfa') }
              </Column>

              <Column name="lastConnexion" sortable>
                { t('pages.admin.users.table.columns.lastConnexion') }
              </Column>

              <Column name="roles" sortable>
                { t('pages.admin.users.table.columns.roles') }
              </Column>

              { /* <Column name="externalProvider" sortable>
                { t('pages.admin.users.table.columns.source') }
              </Column> */ }
            </>
          )}
        >
          { users?.data?.map(user => (
            <Row
              key={user.id}
              id={user.id}
              disabled={!!user.deletedAt}
              onClick={handleRowClick}
            >
              <Cell><SmallValue><DateValue date={user.createdAt} /></SmallValue></Cell>
              <Cell maxWidth={350}>{ capitalizeFirstLetter(user.firstName) }</Cell>
              <Cell maxWidth={350}>{ capitalizeFirstLetter(user.lastName) }</Cell>
              <Cell>{ user.email }</Cell>
              <Cell center><BooleanValue value={user.emailVerified} /></Cell>
              <Cell center><BooleanValue value={user.mfaEnabled} /></Cell>
              <Cell><SmallValue><TimeAgoValue date={user.lastConnexion} /></SmallValue></Cell>
              <Cell expand><TagValue value={user.roles.map(userRole => rolesName[userRole] ?? userRole)} /></Cell>
              { /* <Cell expand>{ user.externalProvider && capitalizeFirstLetter(user.externalProvider) }</Cell> */ }
            </Row>
          )) }
        </DataGrid>
      </PageContent>

      { modalProps && <ConfirmationModal {...modalProps} /> }
    </ContainedPage>
  )
}

export default protectAdminPage(UsersPage)
