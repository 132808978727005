import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import Language from '../../../../app/Language.enum'
import {
  Input,
  Label,
  PhoneNumberField,
  Select,
} from '../../../form'

import { mergeClassName } from '../../../../utils/mergeClassName'
import { Cell as CellComponent, Row as RowComponent } from '../../../cell'

type MoveFormCustomerFormContentProps = {
  compact?: boolean
  className?: string
}

const MoveFormCustomerFormContent: React.FC<MoveFormCustomerFormContentProps> = ({
  compact = false,
  className,
}) => {
  const { t } = useTranslation()

  const languageOptions = Object.values(Language).map(language => ({
    value: language,
    label: t(`languages.${language}`),
  }))

  const Row = compact ? Fragment : RowComponent
  const Cell = compact ? Fragment : CellComponent

  return (
    <div className={mergeClassName(
      compact && 'flex flex-col gap-y-4',
      className,
    )}
    >
      <Row>
        <Cell>
          <Label
            label={t('forms.fields.firstName.label')}
            required
          >
            <Input
              name="user.firstName"
              size="small"
              required
            />
          </Label>
        </Cell>

        <Cell>
          <Label
            label={t('forms.fields.lastName.label')}
            required
          >
            <Input
              name="user.lastName"
              size="small"
              required
            />
          </Label>
        </Cell>
      </Row>

      <Row>

        <Cell>
          <Label
            label={t('forms.fields.email.label')}
            required
          >
            <Input
              name="user.email"
              size="small"
            />
          </Label>
        </Cell>

        <Cell>
          <Label
            label={t('forms.fields.phoneNumber.label')}
            required
          >
            <PhoneNumberField
              name="user.phoneNumber"
              showExtension={false}
            />
          </Label>
        </Cell>
      </Row>

      <Row>
        <Cell>
          <Label
            label={t('forms.fields.language.label')}
            required
          >
            <Select
              name="user.language"
              options={languageOptions}
              size="small"
            />
          </Label>
        </Cell>
        <Cell />
      </Row>
    </div>
  )
}

export default MoveFormCustomerFormContent
