/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import PhotoIcon from '@mui/icons-material/InsertPhotoOutlined'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ResetIcon from '@mui/icons-material/RestartAlt'

import UploadModal, { type ImageOptions } from './UploadModal'

export type ImageHandlerProps = {
  imageOptions: ImageOptions
  onUpload: (file?: File) => Promise<void> | void
  onDelete?: () => Promise<void> | void
  image?: string
  displayWidth?: number
}

const ImageHandler: React.FC<ImageHandlerProps> = ({
  imageOptions,
  onUpload,
  onDelete,
  image,
  displayWidth,
}) => {
  displayWidth ??= imageOptions.width
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [temporaryImage, setTemporaryImage] = useState<string>()

  useEffect(() => {
    setTemporaryImage(undefined)
  }, [image])

  const handleUpload = async (file: File) => {
    setTemporaryImage(await getTemporaryImageData(file))
    await onUpload?.(file)
    setOpen(false)
  }

  const getTemporaryImageData = async (file: File): Promise<string | undefined> => await new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = (readerEvent) => { resolve(readerEvent.target?.result as string) }
    reader.readAsDataURL(file)
  })

  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation()
    onDelete?.()
  }

  const handleReset = async (event: React.MouseEvent) => {
    event.stopPropagation()
    setTemporaryImage(undefined)
    await onUpload?.(undefined)
  }

  const getImageToDisplay = () => {
    if (temporaryImage) {
      return temporaryImage
    }
    if (image) {
      return `${image}?d=${Date.now()}`
    }
  }
  const imageToDisplay = getImageToDisplay()

  const options = (
    <>
      <Button
        variant="contained"
        color="secondary"
        className="!min-w-0 !p-2"
        onClick={() => { setOpen(true) }}
      >
        <EditIcon className="!text-[24px]" />
      </Button>

      { onDelete && image && (
        <Button
          variant="contained"
          color="error"
          className="!min-w-0 !p-2"
          onClick={handleDelete}
        >
          <DeleteIcon className="!text-[24px]" />
        </Button>
      ) }

      { temporaryImage && (
        <Button
          variant="contained"
          color="error"
          className="!min-w-0 !p-2"
          onClick={handleReset}
        >
          <ResetIcon className="!text-[24px]" />
        </Button>
      ) }
    </>
  )

  return (
    <>
      <div
        className="relative my-2 cursor-pointer rounded-md border p-4"
        style={{ width: displayWidth }}
        onClick={() => { setOpen(true) }}
      >
        { imageToDisplay
          ? (
            <>
              <img src={imageToDisplay} alt="" />
              <div className="absolute inset-0 hidden items-center justify-center gap-4 opacity-0 transition-opacity hover:opacity-100 md:flex">
                { options }
              </div>
            </>
            )
          : (
            <div
              className="flex flex-col items-center justify-center text-gray-400"
              style={{ height: displayWidth / 2 }}
            >
              <PhotoIcon className="!text-[48px] " /><br />
              { t('components.imageHandler.addFile') }
            </div>
            ) }
      </div>
      <div
        style={{ width: displayWidth }}
        className="flex items-center justify-center gap-4 md:hidden"
      >
        { options }
      </div>
      <UploadModal
        onSubmit={handleUpload}
        onClose={() => { setOpen(false) }}
        open={open}
        imageOptions={imageOptions}
      />
    </>
  )
}

export default ImageHandler
