import { ToastContainer as ToastifyContainer, type ToastContainerProps as ToastifyToastContainerProps } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import './style.css'
import { useThemeState } from '../../modules/theme'

type ToastContainerProps = Omit<ToastifyToastContainerProps, 'theme'>

const ToastContainer: React.FC<ToastContainerProps> = (toastifyProps) => {
  const { darkModeEnabled } = useThemeState()
  return (
    <ToastifyContainer
      position="top-right"
      autoClose={5000}
      className="toastContainer"
      theme={darkModeEnabled ? 'dark' : 'light'}
      {...toastifyProps}
      toastStyle={{ borderRadius: 0 }}
      progressStyle={{ height: 3 }}
    />
  )
}

export default ToastContainer
