import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useFormState, useWatch } from 'react-hook-form'
import SingleManIcon from '@mui/icons-material/PersonOutlineOutlined'
import MenIcon from '@mui/icons-material/GroupOutlined'
import ExtraMenIcon from '@mui/icons-material/GroupAddOutlined'

import { PriceInput } from '../../../form'
import Paper from '../../../Paper/Paper'
import { useCurrentCompanyBranchCurrency } from '../../../../modules/companies'
import { getCurrencyDetails } from '../../../../utils/money/getCurrencyDetails'

type PricingOverrideByMenProps = {
  name: string
  nbRules: number
  supportSingleMan: boolean
}

const PricingOverrideByMen: React.FC<PricingOverrideByMenProps> = ({
  name,
  nbRules,
  supportSingleMan,
}) => {
  const singleManName = `${name}SingleMan`
  const extraMenName = `${name}ExtraMen`

  const form = useFormContext()
  const { submitCount } = useFormState()
  const labourValues = useWatch({ name })
  const currency = useCurrentCompanyBranchCurrency()
  const currencySymbol = getCurrencyDetails(currency).symbol

  /**
   * trigger validation on all labour prices when one is updated
   * required since a field can become required without changing
   */
  useEffect(() => {
    if (submitCount < 1) {
      return
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    form.trigger(name)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitCount, labourValues])

  const { t } = useTranslation()

  return (
    <Paper className="child:p-2 child:lg:p-4">

      { supportSingleMan && (
        <div className="mb-1 flex justify-between gap-2">
          <h3 className="flex shrink-0 grow items-center font-body2 text-[1rem] font-normal leading-7 sm:text-[1.2rem] sm:font-light">
            <SingleManIcon className="mr-2 text-gray-400" />
            { t('forms.companyBranch.labour.singleMan') }
          </h3>
          <div className="w-[125px] min-w-[125px] max-w-[220px] sm:w-auto">
            <PriceInput
              name={singleManName}
              endAdornment={t('units.pricePerHour', { currencySymbol })}
              allowDecimal={false}
              maxLength={3}
            />
          </div>
        </div>
      ) }

      { Array.from({ length: nbRules })?.map((_, index) => {
        const nbMen = index + 2

        return (
          <div key={nbMen} className="mb-1 flex justify-between gap-2">
            <h3 className="flex shrink-0 grow items-center font-body2 text-[1rem] font-normal leading-7 sm:text-[1.2rem] sm:font-light">
              <MenIcon className="mr-2 text-gray-400" />
              { t('forms.companyBranch.labour.men', { count: nbMen }) }
            </h3>
            <div className="w-[125px] min-w-[125px] max-w-[220px] sm:w-auto">
              <PriceInput
                name={`${name}.${index}`}
                endAdornment={t('units.pricePerHour', { currencySymbol })}
                allowDecimal={false}
                maxLength={3}
              />
            </div>
          </div>
        )
      }) }

      <div className="flex justify-between gap-2">
        <h3 className="mb-4 font-body2 text-[1rem] font-normal leading-7 sm:text-[1.2rem] sm:font-light">
          <ExtraMenIcon className="mr-2 text-gray-400" />
          { t('forms.companyBranch.labour.extraMen', { count: nbRules + 1 }) }
        </h3>
        <div className="w-[125px] min-w-[125px] max-w-[220px] sm:w-auto">
          <PriceInput
            name={extraMenName}
            endAdornment={t('units.pricePerHour', { currencySymbol })}
            allowDecimal={false}
            maxLength={3}
          />
        </div>
      </div>
    </Paper>
  )
}

export default PricingOverrideByMen
