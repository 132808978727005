import { useState } from 'react'
import { type RegisteredColumn } from './DataGrid.type'
import { useDataGridContext } from './DataGridContext'

/**
 * enable toggle columns feature on the datagrid
 */
export const useToggleColumns = (savedVisibleColumns: string[]) => {
  const [columns, setColumns] = useState<Record<string, RegisteredColumn>>({})
  const [visibleColumns, setVisibleColumns] = useState<string[]>(savedVisibleColumns)

  const registerColumn = (name: string, column: RegisteredColumn) => {
    setColumns((existingColumns) => ({ ...existingColumns, [name]: column }))
  }

  return {
    registerColumn,
    columns,
    visibleColumns,
    setVisibleColumns,
  }
}

/**
 * return the default list of visible columns
 */
export const useDefaultVisibleColumns = () => {
  const { columns } = useDataGridContext()
  return Object
    .entries(columns)
    .filter(
      ([, column]) => column.defaultVisible).map(([name]) => name,
    )
}

/**
 * return the current list of visible columns
 */
export const useVisibleColumns = () => {
  const { visibleColumns } = useDataGridContext()
  const defaultVisibleColumns = useDefaultVisibleColumns()

  return visibleColumns ?? defaultVisibleColumns
}
