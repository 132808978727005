import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

import Link from '../../Link'

export type MobileHeaderProps = {
  backlinkTo?: string
  title?: React.ReactNode
}

const MobileHeader: React.FC<MobileHeaderProps> = ({
  backlinkTo,
  title,
}) => {
  return (
    <div className="relative flex h-[45px] items-center justify-center">
      { backlinkTo && (
        <Link
          to={backlinkTo}
          className="absolute left-[11px] top-[11px] scale-[1.3]"
        >
          <ChevronLeftIcon />
        </Link>
      ) }
      <h1 className="font-body2 text-lg font-bold">{ title }</h1>
    </div>
  )
}

export default MobileHeader
