import MuiTabs, { type TabsProps as MuiTabsProps } from '@mui/material/Tabs'

const Tabs: React.FC<MuiTabsProps> = (muiTabsProps) => {
  return (
    <MuiTabs
      variant="scrollable"
      scrollButtons="auto"
      {...muiTabsProps}
    />
  )
}

export default Tabs
