import { useMemo } from 'react'
import { AreaChart, Area, ResponsiveContainer } from 'recharts'

type MinimalistAreaChartProps = {
  data: number[]
}

const MinimalistAreaChart: React.FC<MinimalistAreaChartProps> = ({
  data,
}) => {
  const chartStroke = 'rgba(148, 163, 184, .5)'
  const chartFill = 'rgba(148, 163, 184, .2)'
  const chartData = useMemo(() => {
    return data.map(value => ({ value }))
  }, [data])

  return (
    <ResponsiveContainer width="100%">
      <AreaChart
        data={chartData}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <Area
          type="monotone"
          animationDuration={1000}
          dataKey="value"
          stroke={chartStroke}
          strokeWidth={2}
          fill={chartFill}
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default MinimalistAreaChart
