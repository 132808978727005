import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

type LiProps = React.HTMLAttributes<HTMLLIElement>

const AddressLine: React.FC<LiProps> = styled((props: LiProps) => (
  <Box component="li" {...props} />
))(({ theme }) => ({
  fontSize: '0.9rem',
  background: 'transparent!important',
  '&:hover': {
    background: `${theme.palette.secondary.main}!important`,
  },
  '&:last-child': {
    position: 'relative',
    marginBottom: 30,
    overflow: 'visible',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: -25,
      right: 10,
      width: 144,
      height: 18,
      background: 'url("/logo-partners/powered_by_google.png") no-repeat bottom right',
      cursor: 'default',
    },
  },
}))

export default AddressLine
