import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import { useCurrentCompanyBranch, useNotifications } from '../../modules/companies'
import { Permission, usePermission } from '../../modules/users'

const CompanyWrapper: React.FC = () => {
  const { data: currentcompanyBranch } = useCurrentCompanyBranch()
  const { startListening, stopListening } = useNotifications()
  const canManageOwnJobRequests = usePermission(Permission.canManageOwnJobRequests)

  const shouldListen = canManageOwnJobRequests && currentcompanyBranch?.approved

  /**
   * start/stop listening for notifications when entering/leaving company pages
   */
  useEffect(() => {
    if (!shouldListen || !currentcompanyBranch?.id) {
      return
    }
    startListening(currentcompanyBranch.id)
    return () => {
      stopListening()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldListen, currentcompanyBranch?.id])

  return (
    <Outlet />
  )
}

export default CompanyWrapper
