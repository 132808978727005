import * as Yup from 'yup'

import { type PromoCodePayload } from '../../../../modules/adminPromoCodes'
import { usePriceValidation } from '../../../../modules/companies'

const useValidationSchema = () => {
  const validateValue = usePriceValidation()

  return Yup.object<PromoCodePayload>({
    code: Yup.string().required(),
    value: validateValue({
      allowZero: false,
      required: true,
    }),
  }) as Yup.ObjectSchema<PromoCodePayload, PromoCodePayload>
}

export default useValidationSchema
