import Rating, { type RatingProps } from './Rating'
import { Source } from './Rating.types'

const GoogleRating: React.FC<Omit<RatingProps, 'source'>> = (props) => {
  return (
    <Rating
      {...props}
      source={Source.Google}
    />
  )
}

export default GoogleRating
