import { useTranslation } from 'react-i18next'

import { useExternalMfaLoginAction, visitorPage } from '../../../modules/users'
import LoginTotpForm from '../../../components/forms/users/LoginTotpForm'
import ServerError from '../../../components/errors/ServerError'
import Title from '../../../components/users/authentication/Title'
import { useNavigate, useParams } from '../../../modules/navigation'
import { type LoginTotpFormPayload } from '../../../components/forms/users/LoginTotpForm/LoginTotpFormPayload.type'
import FloatingPage from '../../../components/layout/FloatingPage'
import { validateUuid } from '../../../utils/uuid'
import { validateToken } from '../../../utils/token'
import { Error404Page } from '../../errors'
import { useConfig } from '../../../modules/config'
import Route from '../../../app/Route.enum'

/**
 * Note: This page is required only for external user (ex: Google OAuth) with mfa
 */
const LoginMfaPage: React.FC = () => {
  const { t } = useTranslation()
  const { externalMfaLogin, error } = useExternalMfaLoginAction()
  const { navigate } = useNavigate()
  const defaultPage = useConfig('users.authentication.defaultPage', { defaultValue: Route.Dashboard })

  const { token: fullToken } = useParams()
  const userId = fullToken ? fullToken.substring(0, 36) : undefined
  const token = fullToken ? fullToken.substring(37) : undefined
  const validToken = validateUuid(userId) && validateToken(token)

  const onSubmit = async ({ token: totp }: LoginTotpFormPayload) => {
    if (!userId || !token) {
      return
    }
    try {
      if (await externalMfaLogin({
        userId,
        token,
        totp,
      })) {
        navigate(defaultPage)
      }
    } catch (error) {
      console.error(error)
    }
  }

  if (!validToken) {
    return <Error404Page />
  }

  return (
    <FloatingPage>
      <Title>{ t('pages.users.login.title') }</Title>
      { error && <ServerError error={error} /> }

      <LoginTotpForm onSubmit={onSubmit} />
    </FloatingPage>
  )
}

export default visitorPage(LoginMfaPage)
