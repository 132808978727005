import { mergeClassName } from '../../utils/mergeClassName'

type ReactThead = React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>
type ReactTr = React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>

export type HeaderProps = {
  trProps?: ReactTr
} & ReactThead

const Header: React.FC<HeaderProps> = ({
  children,
  trProps,
  ...props
}) => {
  return (
    <thead
      {...props}
    >
      <tr
        className={mergeClassName(
          'align-top',
          trProps?.className,
        )}
        {...trProps}
      >
        { children }
      </tr>
    </thead>
  )
}

export default Header
