import { mergeClassName } from '../../utils/mergeClassName'

type LabelProps = {
  label?: React.ReactNode
  className?: string
  labelClassName?: string
  children?: React.ReactNode
  required?: boolean
}

const Label: React.FC<LabelProps> = ({
  label,
  className,
  labelClassName,
  required = false,
  children,
}) => {
  return (
    <label className={labelClassName}>
      <div className={mergeClassName(
        'font-bold md:font-normal pb-1 text-[0.95rem] text-zinc-700',
        className,
      )}
      >
        { label }
        { required && <span className="font-body2"> *</span> }
      </div>
      { children }
    </label>
  )
}

export default Label
