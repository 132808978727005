import { useTranslation } from 'react-i18next'
import StairsIcon from '@mui/icons-material/StairsOutlined'
import ElevatorIcon from '@mui/icons-material/ElevatorOutlined'

import { type MovePlace } from '../../../modules/adminMoves'
import { mergeClassName } from '../../../utils/mergeClassName'
import AddressOrPlace from '../AddressOrPlace'

type LocationRowProps = {
  label: React.ReactNode
  location: MovePlace
}

const LocationRow: React.FC<LocationRowProps> = ({
  label,
  location,
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-x-4 rounded-md bg-slate-100/50 p-4 lg:px-8 xl:flex-row xl:items-center">
      <div className="mb-2 shrink-0 text-sm font-bold text-gray-500 xl:mb-0 xl:w-[200px]">
        { label }
      </div>
      <div className="mb-4 grow xl:mb-0">
        <AddressOrPlace location={location.address} />
      </div>

      <div className={mergeClassName(
        'flex lg:w-[150px] shrink-0 items-center gap-x-2 text-neutral-700',
        !location.nbStairs && 'text-neutral-300',
      )}
      >
        <StairsIcon className="!text-[22px]" />
        { t('nbStairs', { count: location.nbStairs }) }
      </div>

      <div className={mergeClassName(
        'flex lg:w-[250px] shrink-0 items-center gap-x-2 text-neutral-700 mb-2 xl:mb-0',
        !location.elevatorReservationRequired && 'text-neutral-300',
      )}
      >
        <ElevatorIcon className="!text-[22px]" />
        { location.elevatorReservationRequired
          ? t('move.jobs.job.locations.reservationRequired')
          : t('move.jobs.job.locations.reservationNotRequired') }
      </div>

    </div>
  )
}

export default LocationRow
