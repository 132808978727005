import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import MuiIconButton, { type IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton'

const SmallButton = styled(MuiIconButton)(({ theme }) => ({
  transform: 'scale(0.9)',
  marginTop: -5,
  marginBottom: 0,
  [theme.breakpoints.down('md')]: {
    transform: 'scale(1)',
  },
}))

type ButtonProps = {
  tooltip?: string
  danger?: boolean
} & Omit<MuiIconButtonProps, 'color'>

const Button: React.FC<ButtonProps> = ({
  children,
  tooltip,
  danger = false,
  ...buttonProps
}) => {
  const Button = (
    <SmallButton
      size="small"
      color={danger ? 'error' : undefined}
      {...buttonProps}
    >
      { children }
    </SmallButton>
  )

  return tooltip
    ? <Tooltip title={tooltip}>{ Button }</Tooltip>
    : Button
}

export default Button
