import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { addDays } from 'date-fns/addDays'

import { RuleTemporalityType } from '../../../../modules/companies/core/availabilityAndPricing/availabilityAndPricing.models'

const useTemporalityValidationSchema = () => {
  const { t } = useTranslation()

  const dateValidation = Yup.object({
    date: Yup.date().required(),
  })

  const dateRangeValidation = Yup.object({
    from: Yup.date().required(),
    to: Yup.date().required().when('from', ([from], schema) => {
      if (from) {
        return Yup.date()
          .min(addDays(from, 1), 'End date should be after start date')
      }
      return schema
    }),
  })

  const recurrentDateRangeValidation = Yup.object({
    fromDay: Yup.string().required().test({
      message: 'Invalid value',
      test: (v) => parseInt(v) >= 1 && parseInt(v) <= 31,
    }),
    toDay: Yup.string().required().test({
      message: 'Invalid value',
      test: (v) => parseInt(v) >= 1 && parseInt(v) <= 31,
    }),
  })

  const everyWeekDayValidation = Yup.object({
    days: Yup.array().required().min(1, t('formValidation.required')),
  })

  return Yup.lazy(({ type }) => {
    switch (type) {
      case RuleTemporalityType.Date:
        return dateValidation
      case RuleTemporalityType.DateRange:
        return dateRangeValidation
      case RuleTemporalityType.RecurrentRange:
        return recurrentDateRangeValidation
      case RuleTemporalityType.EveryWeekDay:
        return everyWeekDayValidation
    }
    return Yup.object()
  })
}

export default useTemporalityValidationSchema
