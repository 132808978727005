import { useCurrentUserAccountType } from '../core'
import { Error403Page } from '../../../pages/errors'
import { protectPage } from './ProtectedPage'

type ProtectedPageProps = {
  Page: React.FC
}

const AdminProtectedPage: React.FC<ProtectedPageProps> = ({ Page }) => {
  const { isAdmin } = useCurrentUserAccountType()

  if (isAdmin) {
    return <Page />
  } else {
    return <Error403Page />
  }
}

export const protectAdminPage = (page: React.FC): React.FC => {
  const adminProtectedPage = () => <AdminProtectedPage Page={page} />
  return protectPage(adminProtectedPage)
}
