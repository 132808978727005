import { useTranslation } from 'react-i18next'
import { Transition } from '@headlessui/react'
import Button from '@mui/material/Button'
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined'

import { Truck } from '../../../../../modules/companies'
import Paper from '../../../../Paper'
import { IntegerCollectionField } from '../../../../form'
import { useFormContext, useWatch } from 'react-hook-form'
import { type MoveSizeEstimate } from '../../../../../modules/quotes'
import { hasSameQuantities } from '../utils'
import { keyValueMapToRecord } from '../../../../../common/keyValueMap'

type NbTrucksFormContentProps = {
  estimate?: MoveSizeEstimate
}

const NbTrucksFormContent: React.FC<NbTrucksFormContentProps> = ({
  estimate,
}) => {
  const { t } = useTranslation()

  const { setValue } = useFormContext()
  const nbTrucks = useWatch({ name: 'nbTrucks' })

  const estimatedNbTrucks = keyValueMapToRecord(estimate?.nbTrucks)

  const hasCustomNbTrucks = (nbTrucks && estimatedNbTrucks)
    ? !hasSameQuantities(nbTrucks, estimatedNbTrucks)
    : false

  const nbTrucksOptions = Object.values(Truck).map(truck => ({
    value: truck,
    label: t(`trucks.${truck}.title`, { count: 2 }),
  }))

  const applyEstimateValues = () => {
    setValue('nbTrucks', estimatedNbTrucks)
  }

  return (
    <>
      <h2 className="mb-2 font-body2 text-lg font-bold lg:my-4">
        { t('forms.quotesRequest.nbTrucks.title') }
      </h2>
      <Paper>
        <IntegerCollectionField
          name="nbTrucks"
          options={nbTrucksOptions}
          max={9}
          small
        />
        <Transition.Root show={hasCustomNbTrucks}>
          <Transition.Child
            enter="transition-opacity duration-0 delay-50"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Button
              fullWidth
              variant="contained"
              color="warning"
              startIcon={<SyncOutlinedIcon />}
              size="small"
              className="!mt-6 !normal-case"
              onClick={applyEstimateValues}
            >
              { t('actions.useEstimateValues') }
            </Button>
          </Transition.Child>
        </Transition.Root>
      </Paper>
    </>
  )
}

export default NbTrucksFormContent
