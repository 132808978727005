export const hasCorrectLength = (password: string) => password?.length >= 8
export const hasCapitalLetter = (password: string) => /[A-Z]/.test(password)
export const hasNumber = (password: string) => /[0-9]/.test(password)
export const hasSpecialCaracter = (password: string) => /[^0-9a-z ]/i.test(password)

export const validateOptionalPassword = (password?: string) => {
  if (typeof password === 'undefined' || password === '') {
    return true
  }
  return validatePassword(password)
}

export const validatePassword = (password?: string) => {
  return typeof password === 'string' &&
  hasCorrectLength(password) &&
  hasCapitalLetter(password) &&
  hasNumber(password) &&
  hasSpecialCaracter(password)
}
