import { removeTypeNameFromObject } from '../../../utils/graphql'
import { type AvailabilityRule, type PricingRule, type RuleTemporality } from './availabilityAndPricing/availabilityAndPricing.models'
import { type Company, type CompanyBranch } from './companies.models'

const buildRuleTemporality = (data?: any): RuleTemporality => {
  if (data.date) {
    data.date = new Date(data.date)
  }
  if (data.from) {
    data.from = new Date(data.from)
  }
  if (data.to) {
    data.to = new Date(data.to)
  }
  return data
}

const buildAvailabilityRule = (data?: any): AvailabilityRule => {
  if (data.temporality) {
    data.temporality = buildRuleTemporality(data.temporality)
  }
  return data
}

const buildPricingRule = (data?: any): PricingRule => {
  if (data.temporality) {
    data.temporality = buildRuleTemporality(data.temporality)
  }
  return data
}

const buildCompanyBranch = (data?: any): CompanyBranch => {
  if (data?.legal?.dateOfIncorporation) {
    data.legal.dateOfIncorporation = new Date(data.legal.dateOfIncorporation)
  }
  if (data?.availabilityRules?.length) {
    data.availabilityRules = data.availabilityRules.map(buildAvailabilityRule)
  }
  if (data?.pricingRules?.length) {
    data.pricingRules = data.pricingRules.map(buildPricingRule)
  }
  return data
}

export const buildCompany = (
  incomingData?: any,
): Company | undefined => {
  if (!incomingData) {
    return
  }

  const data = removeTypeNameFromObject<Company>(incomingData)

  if (data?.branches?.length) {
    data.branches = data.branches.map(buildCompanyBranch)
  }
  return data
}
