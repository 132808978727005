import CustomerIcon from '@mui/icons-material/PersonOutlined'

import { useGetJobSummary, type LightJob } from '../../../modules/jobs'

type CustomerProps = {
  job: LightJob
}

const Customer: React.FC<CustomerProps> = ({
  job,
}) => {
  const summary = useGetJobSummary()(job)

  return (
    <div className="flex justify-center text-center lg:justify-start lg:text-left">
      <div className="flex flex-col flex-wrap items-center gap-x-6 lg:flex-row">
        <div className="flex gap-2 text-lg font-bold">
          <CustomerIcon className="mt-[5px] !text-[18px]" />
          { job.customerName }
        </div>
        <div className="text-neutral-600">
          { summary }
        </div>
      </div>
    </div>
  )
}

export default Customer
