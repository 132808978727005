import CustomerIcon from '@mui/icons-material/PersonOutlined'

import { type LightJobRequest, useGetJobRequestSummary } from '../../../modules/jobRequests'

type CustomerProps = {
  jobRequest: LightJobRequest
  compact?: boolean
}

const Customer: React.FC<CustomerProps> = ({
  jobRequest,
  compact = false,
}) => {
  const summary = useGetJobRequestSummary()(jobRequest)

  return (
    <div className="flex justify-center text-center lg:justify-start lg:text-left">
      <div className="flex flex-col flex-wrap items-center gap-x-6 lg:flex-row">
        <div className="flex gap-2 text-lg font-bold">
          <CustomerIcon className="mt-[5px] !text-[18px]" />
          { jobRequest.customerName }
        </div>
        { compact && (
          <div className="text-neutral-600">
            { summary }
          </div>
        ) }
      </div>
    </div>
  )
}

export default Customer
