import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { usePhoneNumberValidator } from '../../../form/fields/PhoneNumberField/validator'
import usePasswordValidator from '../../../form/fields/PasswordField/usePasswordValidator'
import { type RegisterFormPayload } from './RegisterFormPayload.type'

const useValidationSchema = () => {
  const { t } = useTranslation()
  const validatePhoneNumber = usePhoneNumberValidator()
  const validatePassword = usePasswordValidator()

  return Yup.object({
    email: Yup.string().required().email(),
    password: validatePassword({ required: true }),
    passwordConfirmation: Yup.string().required().oneOf([Yup.ref('password')], String(t('forms.fields.password.errors.passwordsMustMatch'))),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    phoneNumber: validatePhoneNumber({ required: true }),
    position: Yup.string().required(),
  }) as Yup.ObjectSchema<RegisterFormPayload, RegisterFormPayload>
}

export default useValidationSchema
