import { useTranslation } from 'react-i18next'

import Route from '../../../app/Route.enum'
import LegalForm from '../../../components/forms/companyBranch/LegalForm'
import PageContainer from './PageContainer'
import { useCurrentCompanyBranch, useUpdateCompanyBranchAction } from '../../../modules/companies'
import { useSubmitAction } from '../../../components/forms/companyBranch/useSubmitAction'
import { Step, CompanyBranchMobileHeader } from '../../../components/companyBranch'
import { protectPage } from '../../../modules/users'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import { errorNotification, successNotification, warningNotification } from '../../../components/ToastNotifications'

const CompanyBranchSettingsCompanyPage: React.FC = () => {
  const { t } = useTranslation()
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()
  const { updateCompanyBranch } = useUpdateCompanyBranchAction()
  const formatError = useErrorFormatter()

  const onSubmit = useSubmitAction({
    step: Step.Legal,
    initialData: currentCompanyBranch,
  })

  const onUpload = async (files: File[]) => {
    try {
      const updatedCompanyBranch = await updateCompanyBranch({ legalFiles: files })
      const uploadFilenames = files.map(({ name }) => name)
      const newFilesnames = updatedCompanyBranch.legalFiles?.map(({ filename }) => filename)
      const partialSuccess = uploadFilenames.find(filename => !newFilesnames?.includes(filename))

      if (partialSuccess) {
        warningNotification(t('pages.companyBranch.legal.uploadFiles.partialSuccess'))
      } else {
        successNotification(t(
          'pages.companyBranch.legal.uploadFiles.success',
          { count: files.length },
        ))
      }
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  return (
    <PageContainer
      mobileHeader={(
        <CompanyBranchMobileHeader
          title={String(t('pages.companyBranch.menu.legal'))}
          backlinkTo={Route.BranchSettings}
        />
      )}
    >
      <LegalForm
        companyBranch={currentCompanyBranch}
        onSubmit={onSubmit}
        onUpload={onUpload}
      />
    </PageContainer>
  )
}

export default protectPage(CompanyBranchSettingsCompanyPage)
