import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'

import Modal from '../../../../MuiModal'
import { type DisableTwoFactorFormPayload } from '../../../../forms/users/DisableTwoFactorForm/DisableTwoFactorFormPayload.type'
import DisableTwoFactorForm from '../../../../forms/users/DisableTwoFactorForm'
import { useDisableMfaAction } from '../../../../../modules/users'
import ServerError from '../../../../errors/ServerError'

type DisableTwoFactorModalProps = {
  open: boolean
  onClose?: () => void
}

const DisableTwoFactorModal: React.FC<DisableTwoFactorModalProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation()
  const { disableMfa, error } = useDisableMfaAction()
  const [success, setSuccess] = useState(false)

  const closeModal = () => {
    setSuccess(false)
    onClose?.()
  }

  const onSubmit = async (payload: DisableTwoFactorFormPayload) => {
    try {
      await disableMfa(payload)
      setSuccess(true)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Modal
      width={450}
      open={open}
      onClose={closeModal}
    >
      <Typography variant="h2">
        { t('pages.users.profile.security.mfa.modal.disableMfaTitle') }
      </Typography>

      { success
        ? (
          <>
            <Alert severity="success">
              { t('pages.users.profile.security.mfa.modal.disabledSuccess') }
            </Alert>
            <br />
            <Button variant="contained" color="secondary" onClick={closeModal}>
              { t('actions.close') }
            </Button>
          </>
          )
        : (
          <>
            <Alert severity="warning">
              { t('pages.users.profile.security.mfa.modal.disableMfaWarning') }
            </Alert>

            { error && <Box mt="1rem"><ServerError error={error} /></Box> }

            <DisableTwoFactorForm onSubmit={onSubmit} onClose={closeModal} />
          </>
          ) }
    </Modal>
  )
}

export default DisableTwoFactorModal
