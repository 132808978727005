import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import AccountBalanceIcon from '@mui/icons-material/AccountBalanceOutlined'
import InvoiceIcon from '@mui/icons-material/RequestQuoteOutlined'
import RefreshIcon from '@mui/icons-material/Refresh'

import { type FullMove } from '../../../../../modules/adminMoves'
import { useMoveState } from '../../../../../modules/moves'
import { formatPrice } from '../../../../../utils/money'
import Paper from '../../../../../components/Paper'
import { successNotification } from '../../../../../components/ToastNotifications'
import MoveActionButton from '../../MoveActionButton'
import { ActionName } from '../../useActions'
import CreateInvoiceModal from './CreateInvoiceModal'
import AccountingMetric, { MetricVariant, Variant } from './AccountingMetric'

type BalanceBoxProps = {
  move: FullMove
  refreshMove: () => Promise<void>
}

const BalanceBox: React.FC<BalanceBoxProps> = ({
  move,
  refreshMove,
}) => {
  const { t } = useTranslation()
  const [creatingInvoice, setCreatingInvoice] = useState(false)
  const [refreshingMove, setRefreshingMove] = useState(false)

  const handleRefreshMove = async () => {
    setRefreshingMove(true)
    try {
      await refreshMove()
      successNotification(t('pages.admin.move.accounting.balance.refreshMoveSuccess'))
    } finally {
      setRefreshingMove(false)
    }
  }

  const {
    totalInvoices,
    totalPayments,
    balance,
    canCreateInvoice,
  } = useMoveState(move)

  return (
    <>
      <CreateInvoiceModal
        move={move}
        open={creatingInvoice}
        onClose={() => { setCreatingInvoice(false) }}
      />
      <Paper>

        <div className="flex justify-between">

          <h2 className="mb-4 flex items-center gap-x-2 font-body2 text-2xl font-semibold text-slate-700 ">
            <AccountBalanceIcon />
            <div>
              { t('pages.admin.move.accounting.balance.title') }
            </div>

            <div>
              <IconButton
                onClick={handleRefreshMove}
                disabled={refreshingMove}
              >
                <RefreshIcon />
              </IconButton>
            </div>
          </h2>

          <div>
            <div className="flex gap-x-4">
              <Button
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<InvoiceIcon />}
                onClick={() => { setCreatingInvoice(true) }}
                className="!normal-case"
                disabled={!canCreateInvoice}
              >
                { t('actions.createInvoiceOrCredit') }
              </Button>

              <MoveActionButton
                move={move}
                actionName={ActionName.CollecteTotalBalance}
              />

              <MoveActionButton
                move={move}
                actionName={ActionName.AskForPayment}
              />
            </div>
          </div>
        </div>

        <div className="mt-2 flex flex-col items-center gap-x-8 py-2 lg:flex-row lg:justify-between">

          <AccountingMetric
            label={t('pages.admin.move.accounting.balance.invoices')}
          >
            { formatPrice(totalInvoices) }
          </AccountingMetric>

          <div>
            -
          </div>

          <AccountingMetric
            label={t('pages.admin.move.accounting.balance.payments')}
          >
            { formatPrice(totalPayments) }
          </AccountingMetric>

          <div>
            =
          </div>

          <AccountingMetric
            label={t('pages.admin.move.accounting.balance.balance')}
            variant={Variant.Emphasis}
            metricVariant={balance.price > 0 ? MetricVariant.Negative : MetricVariant.Positive}
          >
            { formatPrice(balance, { showCurrency: totalInvoices.price > 0 || totalPayments.price > 0 }) }
          </AccountingMetric>
        </div>
      </Paper>
    </>
  )
}

export default BalanceBox
