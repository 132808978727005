import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'

import {
  type FullMove,
  type MovePayload,
} from '../../../../modules/adminMoves'
import {
  Form,
  SubmitButton,
} from '../../../form'
import useValidationSchema from './validationSchema'
import {
  moveToEditFormData,
  formatEditFormOutput,
} from './formDataBuilder'
import { type EditMoveFormValues } from './MoveForm.type'
import {
  QuotesRequestFormContent,
  defaultQuoteRequestValues,
} from '../../quotes/QuotesRequestForm'

type EditMoveFormProps = {
  onSubmit: (payload: Omit<MovePayload, 'user'>) => Promise<any>
  onCancel?: () => void
  data?: FullMove
}

const EditMoveForm: React.FC<EditMoveFormProps> = ({
  onSubmit,
  onCancel,
  data,
}) => {
  const { t } = useTranslation()

  const schema = useValidationSchema<EditMoveFormValues>({ includeUser: false })
  const form = useForm<EditMoveFormValues>({
    resolver: yupResolver(schema),
    defaultValues: data
      ? moveToEditFormData(data)
      : {
          ...defaultQuoteRequestValues,
        },
  })
  const onFormSubmit = async (data: EditMoveFormValues) => await onSubmit(formatEditFormOutput(data))

  return (
    <Form
      form={form}
      onSubmit={onFormSubmit}
    >
      <div className="-mt-4 max-h-[calc(100dvh-235px)] overflow-auto px-4">
        <QuotesRequestFormContent
          requireDetails
        />
      </div>

      <div className="mb-1 mt-8 flex justify-end gap-3">
        { onCancel && (
          <Button
            variant="contained"
            color="secondary"
            onClick={onCancel}
          >
            { t('actions.cancel') }
          </Button>
        ) }
        <SubmitButton size="large">
          { t('actions.update') }
        </SubmitButton>
      </div>
    </Form>
  )
}

export default EditMoveForm
