import { useTranslation } from 'react-i18next'
import { styled, lighten } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { useDataGridContext } from '../DataGridContext'

const Container = styled(Box)(({ theme }) => ({
  padding: '3rem 0',
  textAlign: 'center',
  background: lighten(theme.palette.mainBackground.main, 0.1),
}))

const NoResults: React.FC = () => {
  const { t } = useTranslation()
  const { query, setQuery } = useDataGridContext()
  const hasSearchContent = !!query.search

  const resetFilter = () => {
    setQuery({
      ...query,
      search: '',
      custom: undefined,
      page: 1,
    })
  }

  return (
    <Container>
      { t('components.dataGrid.noResults') }
      <br />
      { hasSearchContent && (
        <Button
          variant="contained"
          color="secondary"
          onClick={resetFilter}
          size="small"
          sx={{ mt: 2 }}
        >
          { t('components.dataGrid.resetFilters') }
        </Button>
      ) }
    </Container>
  )
}

export default NoResults
