import { useTranslation } from 'react-i18next'
import Chip from '@mui/material/Chip'

import { type Payment, PaymentStatus } from '../../../modules/payment'
import { mergeClassName } from '../../../utils/mergeClassName'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

type PaymentStatusTagProps = {
  payment: Payment
} & ReactDiv

const PaymentStatusTag: React.FC<PaymentStatusTagProps> = ({
  payment,
  className,
  ...props
}) => {
  const { t } = useTranslation()

  if (!payment.status) {
    return null
  }

  const statusTitle = t(`payment.paymentStatus.${payment.status}`)

  return (
    <Chip
      className={mergeClassName(
        payment.status === PaymentStatus.succeeded && '!bg-green-800 !text-white',
        payment.status === PaymentStatus.pending && '!bg-orange-300',
        payment.status === PaymentStatus.failed && '!bg-red-800 !text-white',
        payment.status === PaymentStatus.refunded && '!bg-lime-300',
        payment.status === PaymentStatus.partiallyRefunded && '!bg-lime-300',
        className,
      )}
      label={statusTitle}
      size="small"
    />
  )
}

export default PaymentStatusTag
