import { type HeaderProps } from 'react-big-calendar'

const MonthHeader: React.FC<HeaderProps> = ({ label }) => {
  return (
    <>
      <div className="md:hidden">
        { label.substring(0, 1) }
      </div>
      <div className="hidden md:inline xl:hidden">
        { label.substring(0, 2) }
      </div>
      <div className="hidden xl:inline">
        { label.substring(0, 3) }
      </div>
    </>
  )
}

export default MonthHeader
