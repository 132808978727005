/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { usePricingRule } from '../availabilityAndPricing'
import { type CompanyBranch } from '../companies.models'
import { type Season, useSeason } from '../season'
import { type CompanyBranchLabourPricing } from './price.model'
import { type CompanyBranchPricing } from './CompanyBranchPricing'
import { AdditionalService } from '../additionalService'

/**
 * return price for the given season (fallback to base price if none)
 * if allow zero is true, we interpret zero as "free"
 * if allow zero is false, we use the base price when season price is "0"
 */
export const useSeasonalPricing = () => {
  return (
    price: CompanyBranchPricing,
    season?: Season,
    allowZero = false,
  ) => {
    if (!price) {
      return
    }
    const basePrice = price.basePrice
    if (!season) {
      return basePrice
    }
    const seasonPrice = price[season]
    if (allowZero && seasonPrice === 0) {
      return seasonPrice
    }
    return price[season] ?? basePrice
  }
}

type UsePricingOptions = {
  companyBranch: CompanyBranch
  date: Date
}

/**
 * get labour pricing for a given date
 * this take pricing rule and seasons into account
 */
export const useLabourPricing = () => {
  const getSeason = useSeason()
  const getSeasonalPricing = useSeasonalPricing()
  const getPricingRule = usePricingRule()

  return ({
    companyBranch,
    date,
  }: UsePricingOptions): CompanyBranchLabourPricing => {
    if (!companyBranch.services || !companyBranch.labour) {
      throw new Error('Incomplete moving profile')
    }

    /* check for active pricing rule */
    const offerPacking = companyBranch.services.additionalServices?.includes(AdditionalService.Packing) ?? false
    const pricingRule = getPricingRule(companyBranch, date)

    if (pricingRule) {
      return {
        movingLabour: pricingRule.movingLabour,
        movingLabourExtraMen: pricingRule.movingLabourExtraMen,
        movingLabourSingleMan: pricingRule.movingLabourSingleMan,
        packingLabour: offerPacking ? pricingRule.packingLabour : undefined,
        packingLabourSingleMan: offerPacking ? pricingRule.packingLabourSingleMan : undefined,
        packingLabourExtraMen: offerPacking ? pricingRule.packingLabourExtraMen : undefined,
      }
    }

    /* get seasonnal price */
    const season = getSeason(companyBranch, date)
    let movingLabourSingleMan, packingLabour, packingLabourSingleMan, packingLabourExtraMen

    /* moving */
    const movingLabour = companyBranch.labour.movingLabour.map((perMenPricing) => getSeasonalPricing(
      perMenPricing,
      season,
    )!)
    const movingLabourExtraMen = getSeasonalPricing(companyBranch.labour.movingLabourExtraMen, season)!
    if (companyBranch.labour.movingLabourSingleMan) {
      movingLabourSingleMan = getSeasonalPricing(companyBranch.labour.movingLabourSingleMan, season)
    }

    /* packing */
    if (offerPacking) {
      packingLabour = companyBranch.labour?.packingLabour?.map((perMenPricing) => getSeasonalPricing(
        perMenPricing,
        season,
      )!)
      if (companyBranch.labour.packingLabourExtraMen) {
        packingLabourExtraMen = getSeasonalPricing(companyBranch.labour.packingLabourExtraMen, season)
      }
      if (companyBranch.labour.packingLabourSingleMan) {
        packingLabourSingleMan = getSeasonalPricing(companyBranch.labour.packingLabourSingleMan, season)
      }
    }

    return {
      movingLabour,
      movingLabourExtraMen,
      movingLabourSingleMan,
      packingLabour,
      packingLabourSingleMan,
      packingLabourExtraMen,
    }
  }
}
