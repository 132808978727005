import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

import Spinner from '../../Spinner'
import { useAuthenticationState } from '../../../modules/users'

const Container = styled(Box)(({ theme }) => ({
  position: 'fixed',
  left: 0,
  top: 0,
  width: '100vw',
  height: '100dvh',
  background: 'rgba(255, 255, 255, 0.5)',
  zIndex: 10001,
}))

const LoggingOutSpinner: React.FC = () => {
  const { isLoggingOut } = useAuthenticationState()

  if (!isLoggingOut) {
    return null
  }

  return (
    <Container>
      <Spinner floating />
    </Container>
  )
}

export default LoggingOutSpinner
