import { useTranslation } from 'react-i18next'

import Route from '../../../app/Route.enum'
import { protectPage } from '../../../modules/users'
import { useNavigate, useParams } from '../../../modules/navigation'
import { type CloseJobForPartnerPayload, type JobForPartner, JobStatus, useCloseJobForPartnerAction, useGetJobSummary, useJob } from '../../../modules/jobs'
import { useOnBehalfState } from '../../../modules/companies'
import { capitalizeFirstLetter } from '../../../utils/string'
import Spinner from '../../../components/Spinner'
import ServerError from '../../../components/errors/ServerError'
import ContainedPage from '../../../components/layout/ContainedPage'
import PageTitle from '../../../components/layout/PageTitle'
import PageContent from '../../../components/layout/PageContent'
import CloseJobForm from '../../../components/forms/companyBranch/CloseJobForm'
import Paper from '../../../components/Paper'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import { errorNotification, successNotification } from '../../../components/ToastNotifications'
import { Error404Page } from '../../errors'

const CloseJobPage: React.FC = () => {
  const { t } = useTranslation()
  const { jobId } = useParams()
  const { navigate } = useNavigate()
  const { onBehalf } = useOnBehalfState()
  const { data: job, loading, error } = useJob(jobId)
  const getSummary = useGetJobSummary()
  const { closeJobForPartner, loading: closingJob } = useCloseJobForPartnerAction()
  const formatError = useErrorFormatter()

  const onSubmit = async (payload: CloseJobForPartnerPayload) => {
    if (!jobId) {
      return
    }
    try {
      await closeJobForPartner(jobId, payload)
      navigate(onBehalf ? Route.CompaniesOnBehalf : Route.Dashboard)
      successNotification(t('pages.companyBranch.closeJob.closedSuccess'))
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  const getCustomerFullName = (job: JobForPartner) => {
    return [
      capitalizeFirstLetter(job.move.user.firstName),
      capitalizeFirstLetter(job.move.user.lastName),
    ].join(' ')
  }

  if (!closingJob && job?.status && job.status !== JobStatus.Completed) {
    return <Error404Page />
  }

  if (error) {
    return (<ContainedPage><ServerError error={error} /></ContainedPage>)
  }

  if (loading || !job) {
    return (<ContainedPage><Spinner floating /></ContainedPage>)
  }

  return (
    <ContainedPage>

      <PageTitle>
        { t('pages.companyBranch.closeJob.title', { customerName: getCustomerFullName(job) }) }
        <div className="mt-2 text-lg text-neutral-600">
          { getSummary(job) }
        </div>
      </PageTitle>

      <PageContent>
        <Paper>
          <CloseJobForm
            job={job}
            onSubmit={onSubmit}
          />
        </Paper>
      </PageContent>
    </ContainedPage>
  )
}

export default protectPage(CloseJobPage)
