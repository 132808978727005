import FridgeIcon from '@mui/icons-material/Kitchen'
import SportEquipmentIcon from '@mui/icons-material/FitnessCenter'
import PianoIcon from '@mui/icons-material/Piano'
import PoolTableIcon from '@mui/icons-material/TableRestaurant'
import MarbleTableTop from '@mui/icons-material/TableBar'
import HeavyIcon from '@mui/icons-material/Scale'

import { SpecialItem } from './SpecialItem.enum'
import { type Icon } from '../../../../common/icon/Icon.type'

export const specialItemIcons: Record<SpecialItem, Icon> = {
  [SpecialItem.Appliance]: FridgeIcon,
  [SpecialItem.LargeAppliance]: FridgeIcon,
  [SpecialItem.CommercialFreezer]: FridgeIcon,
  [SpecialItem.SportEquipment]: SportEquipmentIcon,
  [SpecialItem.UprightPiano]: PianoIcon,
  [SpecialItem.BabyGrandPiano]: PianoIcon,
  [SpecialItem.GrandPiano]: PianoIcon,
  [SpecialItem.PoolTable]: PoolTableIcon,
  [SpecialItem.MarbleTableTop]: MarbleTableTop,
  [SpecialItem.HeavyItem200]: HeavyIcon,
  [SpecialItem.HeavyItem300]: HeavyIcon,
  [SpecialItem.HeavyItem400]: HeavyIcon,
}
