import { useWatch } from 'react-hook-form'

import { type ChargeCategory } from '../../../../modules/invoices'
import { type PromoCode, applyPromoCode } from '../../../../modules/adminPromoCodes'
import { type Currency } from '../../../../common/amount'
import { formatPrice } from '../../../../utils/money'
import { getSubtotal } from './ChargesFormContent.utils'
import { type ChargeFormValue } from './ChargesFormContent.type'

type SubtotalProps = {
  category?: ChargeCategory
  currency: Currency
  promoCode?: PromoCode
  min?: number
}

const Subtotal: React.FC<SubtotalProps> = ({
  category,
  currency,
  promoCode,
  min,
}) => {
  const charges = (useWatch({ name: 'charges' }) ?? []) as ChargeFormValue[]
  const categoryDetails = charges.filter((charge) => {
    if (!category) {
      return true
    }
    const [rowCategory] = charge.categoryAndDescription.split('.')
    return rowCategory === category
  })

  const price = applyPromoCode({ price: getSubtotal(categoryDetails), currency }, promoCode)
  if (min !== undefined) {
    price.price = Math.max(price.price, min)
  }

  return (
    <>
      { formatPrice(price) }
    </>
  )
}

export default Subtotal
