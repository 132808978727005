import { convertKmsToMiles } from '../../../../utils/conversion'
import { type FullMove } from '../../../adminMoves'
import { type FullMoveForPartner } from '../moves.model'

/**
 * if the move is calculated using the method "double drive time", this returns true
 * - move is in california
 * - move is intra-state
 * - move is within 100 miles
 */
export const shouldUseDoubleDriveTime = (move: FullMove | FullMoveForPartner) => {
  if (!('country' in move.origin.address) || !('country' in move.destination.address)) {
    return false
  }
  const isUs = move.origin.address?.country?.toUpperCase() === 'US'
  const originState = move.origin.address.region?.toUpperCase()
  const destinationState = move.destination.address?.region?.toUpperCase()
  const isIntraState = originState ? originState === destinationState : false
  const isCalifornia = originState === 'CA'
  const distanceMiles = convertKmsToMiles((move.moveLeg?.distanceMeters ?? 0) / 1000)
  const isLocal = distanceMiles <= 100

  return isUs && isIntraState && isCalifornia && isLocal
}
