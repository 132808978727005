import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useEffectOnce } from '../../utils/useEffectOnce'
import { useNavigate, useParams } from '../../modules/navigation'
import FloatingPage from '../../components/layout/FloatingPage'
import { validateToken } from '../../utils/token'
import { validateUuid } from '../../utils/uuid'
import { Error404Page } from '../errors'
import { useVerifyEmailAction } from '../../modules/users'
import ServerError from '../../components/errors/ServerError'
import Spinner from '../../components/Spinner'
import Route from '../../app/Route.enum'
import { successNotification } from '../../components/ToastNotifications'

const EmailVerificationPage: React.FC = () => {
  const { t } = useTranslation()
  const { verifyEmail, data, loading, error } = useVerifyEmailAction()
  const { token: fullToken } = useParams()
  const userId = fullToken ? fullToken.substring(0, 36) : undefined
  const token = fullToken ? fullToken.substring(37) : undefined
  const validToken = validateUuid(userId) && validateToken(token)
  const { replace } = useNavigate()

  const success = !!data

  useEffectOnce(() => {
    if (!validToken || !userId || !token) {
      return
    }
    verifyEmail({ userId, token }).catch(() => {})
  })

  useEffect(() => {
    if (success) {
      successNotification(t('pages.users.emailVerification.success'))
      replace(Route.Dashboard)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  if (!validToken) {
    return <Error404Page />
  }

  return (
    <FloatingPage>
      <h1 className="font-body2 text-4xl lg:text-5xl">{ t('pages.users.emailVerification.title') }</h1>

      <br />

      { (loading || success) && <Spinner /> }
      { error && <ServerError error={error} /> }

    </FloatingPage>
  )
}

export default EmailVerificationPage
