import { Select, type SelectProps } from '../..'

const DaySelect: React.FC<Omit<SelectProps, 'options'>> = (
  selectProps,
) => {
  const daysOptions = Array.from({ length: 31 }).map((_, index) => ({
    value: String(index + 1),
    label: String(index + 1).padStart(2, '0'),
  }))

  return (
    <Select
      {...selectProps}
      options={daysOptions}
    />
  )
}

export default DaySelect
