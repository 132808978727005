/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { keyValueMapToRecord, recordToKeyValueMap } from '../../../../common/keyValueMap'
import { type CompanyBranchServices } from '../../../../modules/companies'
import { type ServicesFormValues, type ServicesNbTrucksFormValues } from './ServicesForm.type'

export const CompanyBranchServiceToFormData = (companyBranchServices: CompanyBranchServices): ServicesFormValues => {
  return {
    services: companyBranchServices.services,
    additionalServices: companyBranchServices.additionalServices,
    nbTrucks: keyValueMapToRecord(
      companyBranchServices.nbTrucks,
      value => String(value),
    ),
    arrivalWindows: companyBranchServices.arrivalWindows,
  }
}

const filterEmptyTruckValues = (value: ServicesNbTrucksFormValues) => {
  return Object.fromEntries(Object.entries(value).filter(([_, value]) => !!value && value !== '0')) as ServicesNbTrucksFormValues
}

export const formatFormOutput = (formData: ServicesFormValues): CompanyBranchServices => {
  return {
    services: formData.services,
    additionalServices: formData.additionalServices,
    nbTrucks: recordToKeyValueMap(
      filterEmptyTruckValues(formData.nbTrucks),
      parseInt,
    )!,
    arrivalWindows: formData.arrivalWindows,
  }
}
