import { mergeClassName } from '../../utils/mergeClassName'

type ModalTitleProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLHeadingElement>

const ModalTitle: React.FC<ModalTitleProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <h2
      className={mergeClassName(
        'text-xl font-semibold leading-none tracking-tight',
        className,
      )}
      {...props}
    >
      { children }
    </h2>
  )
}

export default ModalTitle
