import { mergeClassName } from '../../utils/mergeClassName'
import PaperTitle from './PaperTitle'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export type PaperProps = {
  title?: React.ReactNode
  titleIcon?: React.ReactNode
  compact?: boolean
} & ReactDiv

const Paper: React.FC<PaperProps> = ({
  children,
  title,
  titleIcon,
  compact = false,
  className,
  ...props
}) => {
  const hasTitle = !!title
  return (
    <div
      className={mergeClassName(
        'mb-6 3xl:mb-8 rounded-md border border-zinc-200 bg-white shadow-md shadow-neutral-300/30',
        compact && 'mb-4 3xl:mb-6 shadow-sm ',
        className,
      )}
      {...props}
    >
      { hasTitle && (
        <PaperTitle icon={titleIcon}>{ title }</PaperTitle>
      ) }
      <div className={mergeClassName(
        'p-4 lg:p-8',
        compact && 'p-2 lg:p-6',
      )}
      >
        { children }
      </div>
    </div>
  )
}

export default Paper
