import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  type CompanyBranch,
  type CompanyBranchAdditionalCharges,
  SpecialService,
  specialServiceIcons,
  SpecialItem,
  specialItemIcons,
  useCompanyBranchCurrency,
} from '../../../../modules/companies'
import { Form, CardCheckboxPriceGroup, CardLabel, Label } from '../../../form'
import useValidationSchema from './validationSchema'
import Section from '../../../Section'
import Paper from '../../../Paper/Paper'
import { CompanyBranchAdditionalChargesToFormData, formatFormOutput } from './formDataBuilder'
import SubmitButton from '../SubmitButton'
import { type AdditionalChargesFormValues } from './AdditionalChargesForm.type'
import PricesInput from '../../../companyBranch/PricesInput'

type AdditionalChargesFormProps = {
  onSubmit: (payload: CompanyBranchAdditionalCharges) => Promise<any>
  companyBranch?: CompanyBranch
}

const AdditionalChargesForm: React.FC<AdditionalChargesFormProps> = ({
  onSubmit,
  companyBranch,
}) => {
  const { t } = useTranslation()
  const schema = useValidationSchema()
  const form = useForm<AdditionalChargesFormValues>({
    resolver: yupResolver(schema),
    defaultValues: CompanyBranchAdditionalChargesToFormData(companyBranch?.additionalCharges),
    mode: 'onTouched',
  })
  const currency = useCompanyBranchCurrency(companyBranch)

  const formatBeforeSubmit = async (values: AdditionalChargesFormValues) => await onSubmit(formatFormOutput(values))

  const specialServicesOptions = Object.fromEntries(Object.values(SpecialService).map(specialServices => ([
    specialServices,
    <CardLabel
      key={specialServices}
      title={t(`specialServices.${specialServices}.title`)}
      subtitle={t(`specialServices.${specialServices}.subtitle`, { defaultValue: '' })}
      Icon={specialServiceIcons[specialServices]}
    />,
  ])))

  const specialItemsOptions = Object.fromEntries(Object.values(SpecialItem).map(specialItem => ([
    specialItem,
    <CardLabel
      key={specialItem}
      title={t(`specialItems.${specialItem}.title`)}
      subtitle={t(`specialItems.${specialItem}.subtitle`, { defaultValue: '' })}
      Icon={specialItemIcons[specialItem]}
    />,
  ])))

  return (
    <Form form={form} onSubmit={formatBeforeSubmit}>
      <Section>
        { t('forms.companyBranch.additionalCharges.additionalCharges.title') }
      </Section>
      <Paper>
        <div className="mb-4">
          <Label>
            { t('forms.companyBranch.additionalCharges.specialServices.description') }
          </Label>
        </div>

        <Label
          label={t('forms.companyBranch.additionalCharges.additionalCharges.overnightLabel')}
        >
          <PricesInput
            prefixName="overnight"
            companyBranch={companyBranch}
            className="mt-2"
            maxLength={5}
            currency={currency}
          />
        </Label>

        <Label
          label={t('forms.companyBranch.additionalCharges.additionalCharges.stairsLabel')}
          className="mt-8 lg:mt-5"
        >
          <PricesInput
            prefixName="stairs"
            companyBranch={companyBranch}
            className="mt-2"
            maxLength={5}
            currency={currency}
          />
        </Label>
      </Paper>

      <Section>
        { t('forms.companyBranch.additionalCharges.specialServices.title') }
      </Section>
      <Paper className="bg-zinc-50">
        <div className="mb-4">
          <Label>
            { t('forms.companyBranch.additionalCharges.specialServices.description') }
          </Label>
        </div>
        <CardCheckboxPriceGroup
          name="specialServices"
          options={specialServicesOptions}
          companyBranch={companyBranch}
          zeroDollarMessage={t('forms.companyBranch.additionalCharges.specialServices.noExtraCost')}
          maxLength={5}
          currency={currency}
        />
      </Paper>

      <Section>
        { t('forms.companyBranch.additionalCharges.specialItems.title') }
      </Section>
      <Paper className="bg-zinc-50">
        <div className="mb-4">
          <Label>
            { t('forms.companyBranch.additionalCharges.specialItems.description') }
          </Label>
        </div>
        <CardCheckboxPriceGroup
          name="specialItems"
          options={specialItemsOptions}
          companyBranch={companyBranch}
          zeroDollarMessage={t('forms.companyBranch.additionalCharges.specialItems.noExtraCost')}
          maxLength={5}
          currency={currency}
        />
      </Paper>

      <SubmitButton companyBranch={companyBranch} />
    </Form>
  )
}

export default AdditionalChargesForm
