import { mergeClassName } from '../../utils/mergeClassName'

export type ValueProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const Label: React.FC<ValueProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={mergeClassName(
        'font-body2',
        className,
      )}
      {...props}
    >
      { children ?? '—' }
    </div>
  )
}

export default Label
