import {
  type CompanyBranchCommunication,
} from '../../../../modules/companies'
import { type CommunicationFormValues } from './CommunicationForm.type'

export const CompanyBranchCommunicationToFormData = (companyBranchCommunication?: CompanyBranchCommunication):
CommunicationFormValues => {
  return {
    sendBookingEmailsToPrimaryContact: companyBranchCommunication?.sendBookingEmailsToPrimaryContact ?? true,
    bookingEmailCC: companyBranchCommunication?.bookingEmailCC ?? '',
  }
}

export const formatFormOutput = (formData: CompanyBranchCommunication): CompanyBranchCommunication => {
  return formData
}
