import { useTranslation } from 'react-i18next'
import Route from '../../../app/Route.enum'
import { errorNotification, successNotification } from '../../../components/ToastNotifications'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import CompanyForm from '../../../components/forms/companies/CompanyForm'
import ContainedPage from '../../../components/layout/ContainedPage'
import PageContent from '../../../components/layout/PageContent'
import PageTitle from '../../../components/layout/PageTitle'
import { type CompanyPayload, useCreateCompanyAction } from '../../../modules/companies'
import { useNavigate } from '../../../modules/navigation'
import { Permission, protectAdminPage, usePermission } from '../../../modules/users'
import { Error403Page } from '../../errors'

const CreateCompanyPage: React.FC = () => {
  const { t } = useTranslation()
  const { createCompany } = useCreateCompanyAction()
  const formatError = useErrorFormatter()
  const { navigate } = useNavigate()
  const canManageCompanies = usePermission(Permission.canManageCompanies)

  const onSubmit = async (values: CompanyPayload) => {
    try {
      const result = await createCompany(values)
      successNotification('Company created')
      navigate(Route.BranchSettingsLandingPage, { companyId: result.id })
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  if (!canManageCompanies) {
    return <Error403Page />
  }

  return (
    <ContainedPage>
      <PageTitle>
        { t('pages.admin.companies.create.title') }
      </PageTitle>
      <PageContent className="py-8">
        <CompanyForm onSubmit={onSubmit} backlink={Route.Companies} />
      </PageContent>
    </ContainedPage>
  )
}

export default protectAdminPage(CreateCompanyPage)
