import { mergeClassName } from '../../../../utils/mergeClassName'

type TitleProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>

const Title: React.FC<TitleProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <h2
      className={mergeClassName(
        'font-body2 font-semibold text-xl mt-8 mb-6 text-slate-500',
        className,
      )}
      {...props}
    >
      { children }
    </h2>
  )
}

export default Title
