import config from '../../../config'

type UseConfigOptions<T> = {
  defaultValue?: T
}

export const useConfig = <T = string>(configPath: string, { defaultValue }: UseConfigOptions<T> = {}): T => {
  const configValue = configPath.split('.').reduce((o: any, i) => o?.[i], config)
  return configValue ?? defaultValue
}
