import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { type QuotesRequestFormValues } from './QuotesRequestForm.type'
import { useRecordValidation } from '../../../../utils/validation'
import { useAddressValidation } from '../../../form/fields/AddressField'

const useValidationSchema = () => {
  const { t } = useTranslation()
  const recordValidation = useRecordValidation()
  const addressValidtion = useAddressValidation()
  const today = new Date()

  return Yup.object<QuotesRequestFormValues>({
    movingDate: Yup.date().required().min(today, t('formValidation.dateMustBeInFuture')),
    overnightFees: Yup.string().optional().matches(
      /^[0-9]{0,2}$/,
      t('forms.companyBranch.services.validationErrors.invalidTruckQuantity'),
    ),
    nbMen: Yup.number().required().min(1).max(99),
    estimatedMovingLabourTime: Yup.number().required().min(1).max(99),
    estimatedPackingLabourTime: Yup.string().matches(/^([0-9]*\.)?[0-9]+$/, t('formValidation.default')),
    nbTrucks: recordValidation(
      Yup.string().optional().matches(
        /^[0-9]{0,2}$/,
        t('forms.companyBranch.services.validationErrors.invalidTruckQuantity'),
      ),
    ),
    nbTrucksTotal: Yup.number()
      .min(1, t('forms.companyBranch.services.validationErrors.invalidTotalTrucks'))
      .required(t('forms.companyBranch.services.validationErrors.invalidTotalTrucks')),
    origin: Yup.object({
      address: addressValidtion,
      nbStairs: Yup.string().matches(/^[0-9]?$/, t('formValidation.default')),
    }),
    destination: Yup.object({
      address: addressValidtion,
      nbStairs: Yup.string().matches(/^[0-9]?$/, t('formValidation.default')),
    }),
    specialServices: recordValidation(
      Yup.string().optional().matches(
        /^[0-9]{0,2}$/,
        t('formValidation.default'),
      ),
    ),
    specialItems: recordValidation(
      Yup.string().optional().matches(
        /^[0-9]{0,2}$/,
        t('formValidation.default'),
      ),
    ),
    protectiveMaterial: recordValidation(
      Yup.string().optional().matches(
        /^[0-9]{0,2}$/,
        t('formValidation.default'),
      ),
    ),
  }) as Yup.ObjectSchema<QuotesRequestFormValues, QuotesRequestFormValues>
}

export default useValidationSchema
