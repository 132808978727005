import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { grey } from '@mui/material/colors'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MuiMenu from '@mui/material/Menu'
import MuiMenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'

import { useCurrentUser, useLogoutAction } from '../../../modules/users'
import ProfileAvatar from '../../users/ProfileAvatar/ProfileAvatar'
import Link from '../../Link'
import { useNavigate } from '../../../modules/navigation'
import Route from '../../../app/Route.enum'

const Menu = styled(MuiMenu)(({ theme }) => ({
  marginTop: 70,
  zIndex: 10000,
  '& a': {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
}))

const MenuTitle = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontWeight: 'bold',
  fontSize: '0.9rem',
  padding: '0.5rem 1.25rem',
}))

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  fontSize: '0.9rem',
  padding: '0.5rem 1.25rem',
  '& > *': {
    textDecoration: 'none',
  },
}))

const IdentificationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '0.5rem 1.75rem 0.75rem 1.25rem',
  cursor: 'pointer',
}))

const IdentificationContent = styled(Box)(({ theme }) => ({
  marginLeft: '1rem',
  fontFamily: theme.typography.fontFamily,
  fontSize: '0.9rem',
  lineHeight: '1.2rem',
  color: grey[600],
}))

const ProfileMenu: React.FC = () => {
  const { t } = useTranslation()
  const { navigate } = useNavigate()
  const { logout } = useLogoutAction()
  const { data: user } = useCurrentUser()
  const [accountMenuElement, setAccountMenuElement] = useState<HTMLElement>()

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    setAccountMenuElement(event.currentTarget)
  }

  const onIdentificationContainerClick = (event: React.MouseEvent<HTMLElement>) => {
    navigate(Route.ProfileIdentification)
    onClose()
  }

  const onClose = () => {
    setAccountMenuElement(undefined)
  }

  return (
    <Box pl={2}>
      <IconButton onClick={onClick}>
        <ProfileAvatar />
      </IconButton>

      { user && (
        <Menu
          anchorEl={accountMenuElement}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          disableScrollLock
          elevation={5}
          open={Boolean(accountMenuElement)}
          onClose={onClose}
        >

          <MenuTitle>{ t('header.accountMenu.identification') }</MenuTitle>

          <IdentificationContainer onClick={onIdentificationContainerClick}>
            <ProfileAvatar />
            <IdentificationContent>
              { (!!user.firstName || !!user.lastName) && (
                <div>
                  { user.firstName } { user.lastName }
                </div>
              ) }
              { user.email }
            </IdentificationContent>
          </IdentificationContainer>

          <Link to={Route.ProfileIdentification} onClick={onClose}>
            <MenuItem>
              { t('header.accountMenu.manageMyAccount') }
            </MenuItem>
          </Link>

          <Divider />

          <MenuItem onClick={logout}>
            { t('header.accountMenu.logout') }
          </MenuItem>
        </Menu>
      ) }

    </Box>
  )
}

export default ProfileMenu
