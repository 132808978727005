import { useTranslation } from 'react-i18next'
import { type Interval } from 'date-fns'
import { addDays } from 'date-fns/addDays'
import { lastDayOfMonth } from 'date-fns/lastDayOfMonth'
import { set } from 'date-fns/set'
import { eachMonthOfInterval } from 'date-fns/eachMonthOfInterval'

import { type RuleTemporality, RuleTemporalityType } from '../availabilityAndPricing.models'
import { type TemporalityHandler } from './temporalityRules.type'
import {
  type DateInterval,
  areIntervalsOverlappingInclusive,
  splitIntoMonthsIntervals,
  stitchIntervals,
} from '../date.util'

const generateSafeInterval = (start: Date, nbDays: number): Interval => {
  const end = addDays(set(start, { date: Math.min(nbDays, lastDayOfMonth(start).getDate()) }), 1)
  return { start, end }
}

const useRecurrentRangeDayRuleHandler = (): TemporalityHandler => {
  const { t } = useTranslation()

  return (rule: RuleTemporality) => {
    if (rule.type !== RuleTemporalityType.RecurrentRange) {
      return
    }

    return {
      filter: (period: Interval) => {
        if (!rule.fromDay || !rule.toDay) {
          return false
        }
        if ((rule?.months?.length ?? 0) === 0) {
          return true
        }
        const months = eachMonthOfInterval(period).map(date => date.getMonth())
        return months.some(month => rule.months?.includes(month))
      },

      isRuleActiveForDate: (date: Date) => {
        if (!rule.fromDay || !rule.toDay) {
          return false
        }
        const currentMonth = date.getMonth()
        const currentDay = date.getDate()
        const ruleMonths = rule.months ?? []
        if (ruleMonths.length > 0 && !ruleMonths.includes(currentMonth)) {
          return false
        }
        if (rule.fromDay <= rule.toDay) {
          /* regular date range */
          return currentDay >= rule.fromDay && currentDay <= rule.toDay
        } else {
          /* end of month date range */
          return currentDay <= rule.toDay || currentDay >= rule.fromDay
        }
      },

      getCalendarEntries: (period: Interval) => {
        if (!rule.fromDay || !rule.toDay) {
          return
        }
        const overlap = rule.fromDay > rule.toDay

        const monthIntervals = splitIntoMonthsIntervals(period as DateInterval).filter(({ start }) => {
          if (!rule.months?.length) {
            return true
          }
          return rule.months.includes(start.getMonth())
        })

        const dateRange: Interval[] = []
        monthIntervals.forEach(({ start }) => {
          if (overlap) {
            dateRange.push({
              start: set(start, { date: rule.fromDay }),
              end: addDays(lastDayOfMonth(start), 1),
            })
            dateRange.push(generateSafeInterval(set(start, { date: 1 }), rule.toDay))
          } else {
            dateRange.push(generateSafeInterval(set(start, { date: rule.fromDay }), rule.toDay))
          }
        })

        return stitchIntervals(dateRange.filter(range => areIntervalsOverlappingInclusive(range, period)))
      },

      getTitle: () => {
        if (rule.fromDay === rule.toDay) {
          return t('date.everyDayMonthly', {
            day: String(rule.fromDay).padStart(2, '01'),
          })
        }

        const inverted = rule.fromDay > rule.toDay
        const range = `${t('date.from')} ${String(rule.fromDay).padStart(2, '01')} ${t('date.to')} ${String(rule.toDay).padStart(2, '01')}`

        return inverted ? `${t('date.invertedRange')}, ${range.toLowerCase()}` : range
      },

      getColor: () => '#701a75',

      isRecurrent: () => true,
    }
  }
}

export default useRecurrentRangeDayRuleHandler
