import { type PhoneNumber } from './phoneNumber.model'

export const formatPhoneNumber = (phoneNumber?: PhoneNumber) => {
  if (!phoneNumber?.number) {
    return
  }

  let formattedNumber

  if (phoneNumber.number[0] === '+') {
    const number = phoneNumber.number.substring(2)

    const blocks = [
      number.substring(0, 3),
      number.substring(3, 6),
      number.substring(6, 10),
    ]

    formattedNumber = `(${blocks[0]}) ${blocks[1]}-${blocks[2]}`
  } else {
    const number = phoneNumber.number
    const blocks = [
      number.substring(0, 1),
      number.substring(1, 4),
      number.substring(4, 7),
      number.substring(7, 11),
    ]

    formattedNumber = `${blocks[0]} (${blocks[1]}) ${blocks[2]}-${blocks[3]}`
  }

  return phoneNumber.extension
    ? `${formattedNumber} #${phoneNumber.extension}`
    : formattedNumber
}
