import { createContext, useContext } from 'react'

import { type CalendarContextType } from './Calendar.type'

const CalendarContext = createContext<CalendarContextType>({})

type CalendarContextProviderProps = CalendarContextType & {
  children?: React.ReactNode
}

export const CalendarContextProvider: React.FC<CalendarContextProviderProps> = ({
  children,
  ...context
}) => {
  return (
    <CalendarContext.Provider value={context}>
      { children }
    </CalendarContext.Provider>
  )
}

export const useCalendar = () => useContext(CalendarContext)
