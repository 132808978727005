import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Hamburger from 'hamburger-react'
import Button from '@mui/material/Button'

import Route from '../../../app/Route.enum'
import { useAuthenticationState } from '../../../modules/users'
import Logo from '../../Logo/Logo'
import ProfileMenu from '../../layout/Header/ProfileMenu'
import MobileMenu from '../MobileMenu'
import Link from '../../Link/Link'
import LoggingOutSpinner from './LoggingOutSpinner'
import LanguageDropdown from './LanguageDropdown'
import CurrentCompanyBranch from './CurrentCompanyBranch'
import { useNavigate } from '../../../modules/navigation'
import { useConfig } from '../../../modules/config'

export type HeaderProps = {
  mobileSubHeader?: React.ReactNode
}

const Header: React.FC<HeaderProps> = ({
  mobileSubHeader,
}) => {
  const { t } = useTranslation()
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false)
  const { isAuthenticated } = useAuthenticationState()
  const { navigate } = useNavigate()
  const isStaging = useConfig<boolean>('isStaging')
  const isDevelopment = useConfig<boolean>('isDevelopment')

  return (
    <>
      <div className="w-full shrink-0">
        <div className="flex h-[60px] items-center justify-between border-b">
          <Link to={Route.Dashboard} className="ml-6 w-[175px]">
            <Logo />
          </Link>

          { (isDevelopment || isStaging) && (
            <div className="pl-8 text-xl font-bold uppercase text-red-600">
              { isDevelopment && 'Development' }
              { isStaging && 'Staging' }
            </div>
          ) }

          <div className="hidden grow items-center justify-end lg:flex ">
            <LanguageDropdown />
            <div className="hidden pr-1 lg:flex">

              { isAuthenticated && <ProfileMenu /> }
              { !isAuthenticated && (
                <Button
                  variant="contained"
                  onClick={() => { navigate(Route.Login) }}
                >
                  { t('header.signIn') }
                </Button>
              ) }
            </div>
          </div>
          { isAuthenticated && (
            <div className="flex pr-2 lg:hidden">
              <Hamburger
                toggled={mobileMenuOpened}
                onToggle={() => { setMobileMenuOpened(!mobileMenuOpened) }}
                direction="right"
                size={25}
                rounded
              />
            </div>
          ) }
        </div>
        { mobileSubHeader && (
          <div className="sticky top-[50] z-40 bg-white shadow lg:hidden">
            { mobileSubHeader }
          </div>
        ) }
        { isAuthenticated && <CurrentCompanyBranch /> }
      </div>
      { isAuthenticated && <MobileMenu open={mobileMenuOpened} onClose={() => { setMobileMenuOpened(!mobileMenuOpened) }} /> }
      <LoggingOutSpinner />
    </>
  )
}

export default Header
