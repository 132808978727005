import { type Interval } from 'date-fns'
import { lastDayOfMonth } from 'date-fns/lastDayOfMonth'
import { lastDayOfYear } from 'date-fns/lastDayOfYear'
import { isWithinInterval } from 'date-fns/isWithinInterval'
import { set } from 'date-fns/set'

/**
   * check if the given date is in the season range
   * this also support reverted range (ex: from october to february)
   */
export const isBetweenRange = (date: Date, start: Date, end: Date) => {
  const seasonIntervals: Interval[] = []
  if (start <= end) {
    seasonIntervals.push({ start, end })
  } else {
    const firstDay = new Date(date.getFullYear(), 0, 1, 0, 0, 0, 0)
    const lastDay = lastDayOfYear(firstDay)
    seasonIntervals.push({ start: firstDay, end }, { start, end: lastDay })
  }
  return !!seasonIntervals.find((interval) =>
    isWithinInterval(date, interval),
  )
}

/**
   * get a date from given day, month and year
   * this also make sure the day doesn't bust the month max
   * ex: day:31, month:4 will result in "April 30th" and not "May 1st"
   */
export const getDateFromDayAnMonth = (day: number, month: number, year: number) => {
  const ref = new Date(year, month - 1, 1)
  day = Math.min(day, lastDayOfMonth(ref).getDate())
  return set(new Date(year, month - 1, day), {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  })
}
