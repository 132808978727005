import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import EnableTwoFactorForm from '../../../../forms/users/EnableTwoFactorForm'
import { useEffectOnce } from '../../../../../utils/useEffectOnce'
import { useEnableMfaGetQrCodeAction, useEnableMfaVerifyAccountAction } from '../../../../../modules/users'
import ServerError from '../../../../errors/ServerError'
import { type EnableTwoFactorFormPayload } from '../../../../forms/users/EnableTwoFactorForm/EnableTwoFactorFormPayload.type'
import { type QrCodeData } from './EnableTwoFactorModal'

type Step1Props = {
  onComplete: () => void
  onClose: () => void
  setQrCodeData: (qrCodeData: QrCodeData) => void
  step: number
  nbSteps: number
}

const Step1: React.FC<Step1Props> = ({
  onComplete,
  onClose,
  setQrCodeData,
  step,
  nbSteps,
}) => {
  const { t } = useTranslation()
  const { enableMfaVerifyAccount } = useEnableMfaVerifyAccountAction()
  const { enableMfaGetQrCode, error } = useEnableMfaGetQrCodeAction()

  const onSubmit = async (data: EnableTwoFactorFormPayload) => {
    try {
      setQrCodeData(await enableMfaGetQrCode({ token: data.totp }))
      onComplete()
    } catch (error) {
      console.error(error)
    }
  }

  useEffectOnce(() => {
    enableMfaVerifyAccount().catch(console.error)
  })

  return (
    <>
      <Typography variant="h3">
        { t('pages.users.profile.security.mfa.modal.step1Title', {
          step,
          nbSteps,
        }) }
      </Typography>
      <Typography>
        { t('pages.users.profile.security.mfa.modal.step1Message') }
      </Typography>

      { error && <Box mt="1rem"><ServerError error={error} /></Box> }

      <EnableTwoFactorForm onSubmit={onSubmit} onClose={onClose} />
    </>
  )
}

export default Step1
