import { set } from 'date-fns/set'

import { type CompanyBranch } from '../companies.models'
import { Season } from './Season.enum'
import { getDateFromDayAnMonth, isBetweenRange } from './season.utils'

export const useSeason = () => {
  return (companyBranch: CompanyBranch, date?: Date) => {
    date = set(date ?? new Date(), {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    })
    for (const season of Object.values(Season)) {
      if (!companyBranch.seasons?.[season]?.enabled) {
        continue
      }
      const from = companyBranch.seasons[season].from
      const to = companyBranch.seasons[season].to
      if (!from || !to) {
        continue
      }
      if (
        isBetweenRange(
          date,
          getDateFromDayAnMonth(from.day, from.month, date.getFullYear()),
          getDateFromDayAnMonth(to.day, to.month, date.getFullYear()),
        )
      ) {
        return season
      }
    }
  }
}
