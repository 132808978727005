import { useTranslation } from 'react-i18next'

import ContainedPage from '../../components/layout/ContainedPage'
import { useLanguage } from '../../modules/i18n'
import EnglishContent from './TermsOfUse.en'
import FrenchContent from './TermsOfUse.fr'
import PageTitle from '../../components/layout/PageTitle/PageTitle'
import PageContent from '../../components/layout/PageContent/PageContent'

const TermsOfUsePage: React.FC = () => {
  const { t } = useTranslation()
  const language = useLanguage()

  return (
    <ContainedPage>
      <PageTitle>{ t('pages.termsOfUse.title') }</PageTitle>
      <PageContent className="py-8">
        <div className="prose max-w-none">
          { language === 'fr' ? <FrenchContent /> : <EnglishContent /> }
        </div>
      </PageContent>
    </ContainedPage>
  )
}

export default TermsOfUsePage
