import { useTranslation } from 'react-i18next'
import { useWatch } from 'react-hook-form'

import { type Quote, calculateNumberOfTrucks } from '../../../../modules/quotes'
import { type PromoCode, applyPromoCode } from '../../../../modules/adminPromoCodes'
import { Quantities } from '../../../cell'
import { formatPrice } from '../../../../utils/money'
import { mergeClassName } from '../../../../utils/mergeClassName'
import {
  type ChargeFormValue,
  getSubtotal,
} from '../../invoice/ChargesFormContent'

type SummaryProps = {
  quote: Quote
  promoCode?: PromoCode
}

const Summary: React.FC<SummaryProps> = ({
  quote,
  promoCode,
}) => {
  const { t } = useTranslation()
  const details = (useWatch({ name: 'charges' }) ?? []) as ChargeFormValue[]

  const oldSubtotal = applyPromoCode(
    quote.subtotal,
    promoCode,
  )
  const newSubtotal = applyPromoCode(
    { price: getSubtotal(details), currency: quote.subtotal.currency },
    promoCode,
  )
  newSubtotal.price = Math.max(newSubtotal.price, 0)

  const priceDiff = { price: newSubtotal.price - oldSubtotal.price, currency: oldSubtotal.currency }

  return (
    <>
      <h3 className="font-sans font-bold text-neutral-500">
        { t('forms.move.quote.summary.editingFor') }
        :
      </h3>
      <ul className="ml-6 list-disc">
        <li className="text-neutral-300">
          <span className="text-neutral-500">
            { t('nbMen', { count: quote.resolvedNbMen }) }
          </span>
        </li>
        <li className="text-neutral-300">
          <span className="text-neutral-500">
            { t('nbTrucks', { count: calculateNumberOfTrucks(quote.resolvedNbTrucks) }) }
          </span>
          <br />
          <Quantities>
            { quote.resolvedNbTrucks.map(({ key, value }) => ({
              key: t(`trucks.${key}.title`, { count: value }),
              value: `${value} x`,
            })) }
          </Quantities>
        </li>
      </ul>

      <hr className="mt-8" />
      <h3 className="mb-4 font-sans font-bold text-neutral-500">
        { t('forms.move.quote.summary.priceChange') }
      </h3>

      <div className="flex items-center justify-between">
        <div className="text-neutral-500">
          { t('forms.move.quote.summary.currentPrice') }
        </div>

        <div className="text-right text-neutral-700">
          { formatPrice(oldSubtotal) }
        </div>
      </div>

      <div className="flex items-center justify-between">
        <div className="text-neutral-500">
          { t('forms.move.quote.summary.newPrice') }
        </div>

        <div className="text-right text-neutral-700">
          { formatPrice(newSubtotal) }
        </div>
      </div>

      <div className="mt-4 flex items-center justify-between">
        <div className="text-neutral-500">
          { t('forms.move.quote.summary.difference') }
        </div>

        <div className={mergeClassName(
          'text-right text-neutral-700 text-lg',
          priceDiff.price > 0 && 'text-green-800',
          priceDiff.price < 0 && 'text-red-700',
        )}
        >
          { priceDiff.price > 0 && '+' }
          { formatPrice(priceDiff) }
        </div>
      </div>

    </>
  )
}

export default Summary
