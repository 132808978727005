/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { type CompanyBranchPricing } from '.'
import { Season } from '../season'

const isPriceDefined = (value: any) => {
  return value !== undefined && value !== null && value !== ''
}

export const isPriceRowDefined = (value?: CompanyBranchPricing<any>) => {
  if (!value) {
    return false
  }
  return isPriceDefined(value.basePrice) ||
  isPriceDefined(value[Season.Low]) ||
  isPriceDefined(value[Season.High])
}

type PricingAsStringOptions = {
  asInteger?: boolean
}

/**
 * convert incoming prices (number) as string for forms
 */
export const pricingAsString = (
  pricing?: CompanyBranchPricing<number>,
  {
    asInteger = false,
  }: PricingAsStringOptions = {},
): CompanyBranchPricing<string> | undefined => {
  if (!pricing) {
    return
  }
  if (asInteger) {
    return {
      basePrice: String(pricing.basePrice ?? ''),
      [Season.Low]: String(pricing[Season.Low] ?? ''),
      [Season.High]: String(pricing[Season.High] ?? ''),
    }
  }
  return {
    basePrice: isPriceDefined(pricing.basePrice) ? String(pricing.basePrice!.toFixed(2)) : '',
    [Season.Low]: isPriceDefined(pricing[Season.Low]) ? String(pricing[Season.Low]!.toFixed(2)) : '',
    [Season.High]: isPriceDefined(pricing[Season.High]) ? String(pricing[Season.High]!.toFixed(2)) : '',
  }
}

/**
 * convert form prices (string) as number
 */
export const pricingAsNumber = (
  pricing?: CompanyBranchPricing<string>,
): CompanyBranchPricing<number> | undefined => {
  if (!pricing) {
    return
  }
  if (
    !isPriceDefined(pricing.basePrice) &&
    !isPriceDefined(pricing[Season.Low]) &&
    !isPriceDefined(pricing[Season.High])
  ) {
    return
  }
  return {
    basePrice: isPriceDefined(pricing.basePrice) ? parseFloat(pricing.basePrice!) : undefined,
    [Season.Low]: isPriceDefined(pricing[Season.Low]) ? parseFloat(pricing[Season.Low]!) : undefined,
    [Season.High]: isPriceDefined(pricing[Season.High]) ? parseFloat(pricing[Season.High]!) : undefined,
  }
}
