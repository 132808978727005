import Link, { type LinkProps } from '../../Link/Link'
import { useLocation, usePathGenerator } from '../../../modules/navigation'
import { mergeClassName } from '../../../utils/mergeClassName'

type MobileMenuLinkProps = {
  Icon?: React.FC<{ className?: string }>
  label?: string
  exact?: boolean
  matchRegex?: RegExp
} & LinkProps

const MobileMenuLink: React.FC<MobileMenuLinkProps> = ({
  Icon,
  exact = false,
  matchRegex,
  to,
  params,
  children,
  ...linkProps
}) => {
  const { pathname } = useLocation()
  const generatePath = usePathGenerator()

  const generatedPath = generatePath(to, params)

  const isCurrent = () => {
    if (matchRegex) {
      return matchRegex.test(pathname)
    }
    if (exact) {
      return pathname === generatedPath
    }
    return pathname.startsWith(generatedPath)
  }

  const current = isCurrent()

  return (
    <Link
      to={generatedPath}
      className={mergeClassName(
        'flex align-middle text-slate-500 ',
        current && 'bg-gray-200',
      )}
      {...linkProps}
    >
      <div className="align-center flex justify-center p-4">
        { Icon && <Icon className="ml-1 mr-3 !w-8" /> }
        { children }
      </div>
    </Link>
  )
}

export default MobileMenuLink
