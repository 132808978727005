import { type FullMove, type LightMove } from '../../../adminMoves'
import { type FullMoveForPartner } from '../../../moves/core/moves.model'
import { Permission, useCurrentUser, usePermission } from '../../../users'
import {
  isDateWithin30Mins,
  dateHasPassed,
  getMoveDate,
  getTimezoneDiff,
  getTimeWithTimeZoneDiff,
  convertToFullMove,
} from './moveState.utils'
import { applyPromoCode } from '../../../adminPromoCodes'
import { useLanguage } from '../../../i18n'
import Language from '../../../../app/Language.enum'
import { calculateNumberOfTrucks } from '../../../quotes'
import { type Address } from '../../../../common/address/address.model'
import { useGetPaymentsTotalCharge, useIsPaymentSuccessful } from '../../../payment/core'
import { JobRequestStatus } from '../../../jobRequests/core/jobRequests.enums'
import { Currency } from '../../../../common/amount'
import { InvoiceCategory, useGetInvoicesTotalCharge } from '../../../invoices'
import { subtract } from '../../../../utils/money'
import { shouldUseDoubleDriveTime } from './doubleDriveTime.utils'

const DEFAULT_CHARGE = {
  price: 0,
  currency: Currency.CAD,
}

const defaultActionState = {
  canAssign: false,
  canUnassign: false,
  canRefreshQuotes: false,
  canSendToSmartMoving: false,
  canEditCustomer: false,
  canEditJobs: false,
  canEditPromoCode: false,
  canCancelSelectedQuote: false,
  canSelectAndCustomizeQuote: false,
  canEditSelectedQuote: false,
  canApplyPromoCode: false,
  canRemovePromoCode: false,
  canSendQuoteEmail: false,
  canApproveMove: false,
  canCancelJobRequest: false,
  canCancelJob: false,
  canCreateInvoice: false,
  canCollectTotalBalance: false,
  canAskForPayment: false,
}

const defaultState = {
  isActive: false,
  isHot: false,
  isMoveLocked: false,
  nbQuotes: 0,
  lastQuoteRefresh: undefined,
  hasPartialAddress: false,

  selectedQuote: undefined,
  selectedCompanyName: undefined,
  selectedCompanyLogo: undefined,
  hasCustomQuote: false,
  isAcceptedByCustomer: false,
  isApprovedByAgent: false,
  isAcceptedByPartner: false,
  isDeclinedByPartner: false,

  hasPaidDeposit: false,
  missingDeposit: false,

  promoCode: undefined,
  isPromoCodeApplied: false,

  subtotal: undefined,
  moveDate: undefined,
  moveDateHasPassed: false,
  isJobCompleted: false,
  nbMen: undefined,
  nbTrucks: undefined,
  totalNbTrucks: 0,

  isAssigned: false,
  hasOwnership: false,
  timezoneDiff: 0,
  customerTime: getTimeWithTimeZoneDiff(),

  currency: Currency.CAD,
  totalInvoices: DEFAULT_CHARGE,
  totalPayments: DEFAULT_CHARGE,
  balance: DEFAULT_CHARGE,
  hasPaymentMethod: false,
  hasMoveInvoice: false,
  movePaid: false,

  isUsingDoubleDriveTime: false,

  ...defaultActionState,
}

export const useMoveState = (currentMove?: FullMove | FullMoveForPartner) => {
  const language = useLanguage()
  const { data: currentUser } = useCurrentUser()
  const canManageMoves = usePermission(Permission.canManageMoves)
  const canApproveMove = usePermission(Permission.canApproveMove)
  const canCustomizeQuotes = usePermission(Permission.canCustomizeQuotes)
  const canChargeCustomers = usePermission(Permission.canChargeCustomers)
  const isPaymentSuccessful = useIsPaymentSuccessful()
  const getInvoicesTotalCharge = useGetInvoicesTotalCharge()
  const getPaymentsTotalCharge = useGetPaymentsTotalCharge()

  const getMoveState = (anyMove?: FullMove | FullMoveForPartner) => {
    if (!anyMove) {
      return defaultState
    }
    /* move */

    const move = convertToFullMove(anyMove)

    const isHot = isDateWithin30Mins(new Date(move.createdAt))
    const isMoveLocked = !!move.locked
    const nbQuotes = move.quotes?.length ?? 0
    const lastQuoteRefresh = move.quotes?.[0]?.createdAt ? new Date(move.quotes[0].createdAt) : undefined
    const origin = move.origin.address as Partial<Address>
    const destination = move.destination.address as Partial<Address>
    const hasPartialAddress =
      !origin.street ||
      !origin.city ||
      !origin.region ||
      !origin.country ||
      !origin.postalCode ||
      !destination.street ||
      !destination.city ||
      !destination.region ||
      !destination.country ||
      !destination.postalCode
    const hasPendingJobRequest = move.latestJobRequest?.status === JobRequestStatus.Pending

    /* selected quote */

    const selectedQuote = move.selectedBranch
      ? move.quotes?.find(({ companyBranch }: any) => companyBranch.id === move.selectedBranch)
      : undefined

    let selectedCompanyName
    if (selectedQuote) {
      selectedCompanyName = selectedQuote.companyBranch.company?.identification?.name?.[language as Language] ?? selectedQuote.companyBranch.company?.identification?.name?.[Language.En]
    }

    let selectedCompanyLogo
    if (selectedQuote) {
      selectedCompanyLogo = selectedQuote.companyBranch.company?.logo?.[language as Language] ?? selectedQuote.companyBranch.company?.logo?.[Language.En]
    }

    const hasCustomQuote = !!selectedQuote?.customized
    const isAcceptedByCustomer = !!move.quoteAccepted?.date
    const isApprovedByAgent = !!move.moveApprovedByAgent
    const isAcceptedByPartner = move.jobs.length > 0
    const isDeclinedByPartner = move.latestJobRequest?.status === JobRequestStatus.Declined

    const hasPaidDeposit = isPaymentSuccessful(move.deposit)
    const hasDepositIssues = !!move.deposit && !hasPaidDeposit
    const requireDeposit = isAcceptedByCustomer && !!move.paymentProfile?.depositRequest
    const missingDeposit = requireDeposit || hasDepositIssues

    /* promo code */

    const promoCode = isAcceptedByCustomer ? move.appliedPromoCode : move.promoCode
    const isPromoCodeApplied = isAcceptedByCustomer && !!move.appliedPromoCode

    /* current move values (based on active quote or move requirements) */

    const subtotal = selectedQuote?.subtotal ? applyPromoCode(selectedQuote.subtotal, promoCode) : undefined
    const moveDate = getMoveDate(new Date(selectedQuote?.date ?? move.movingDate), move.originTimezone)
    const moveDateHasPassed = dateHasPassed(moveDate)
    const isJobCompleted = isAcceptedByPartner && moveDateHasPassed
    const nbMen = selectedQuote ? selectedQuote.resolvedNbMen : move.nbMen
    const nbTrucks = selectedQuote ? selectedQuote.resolvedNbTrucks : move.nbTrucks
    const totalNbTrucks = calculateNumberOfTrucks(nbTrucks)
    // todo : isActive is confusing (should be = !moveDateHasPassed )
    const isActive = !moveDateHasPassed && !isAcceptedByCustomer && !isMoveLocked

    /* current user state for the given move */

    const isAssigned = !!move.agent?.id
    const hasOwnership = !!currentUser?.id && !!move.agent?.id && currentUser.id === move.agent.id
    const timezoneDiff = getTimezoneDiff(move.originTimezone)
    const customerTime = getTimeWithTimeZoneDiff(timezoneDiff)

    /* accounting */
    const hasPaymentMethod = !!move.paymentProfile?.paymentMethod
    let totalInvoices = getInvoicesTotalCharge(move.invoices)
    let totalPayments = getPaymentsTotalCharge(move.payments)

    const currency = totalInvoices?.currency ??
      totalPayments?.currency ??
      selectedQuote?.subtotal?.currency ??
      move.quotes?.[0]?.subtotal?.currency ??
      Currency.CAD

    const zeroCharge = {
      price: 0,
      currency,
    }

    totalInvoices ??= zeroCharge
    totalPayments ??= zeroCharge

    const balance = subtract(totalInvoices, totalPayments)

    const hasMoveInvoice = !!move.invoices?.find(invoice => invoice.category === InvoiceCategory.move)
    const movePaid = hasMoveInvoice && balance.price === 0

    /* actions */

    const actions = {
      ...defaultActionState,
      canAssign: canManageMoves && !hasOwnership,
      canUnassign: canManageMoves && hasOwnership,
    }

    if (canManageMoves) {
      actions.canSendToSmartMoving = !moveDateHasPassed && !hasPartialAddress
    }

    if (canManageMoves && isAssigned) {
      actions.canRefreshQuotes = isActive && !hasCustomQuote
      actions.canEditCustomer = true
      actions.canEditJobs = !isAcceptedByCustomer && !hasCustomQuote
      actions.canEditPromoCode = true
      actions.canCancelSelectedQuote = !!selectedQuote &&
        !moveDateHasPassed &&
        !hasPendingJobRequest &&
        !isAcceptedByPartner
      actions.canApplyPromoCode = !promoCode
      actions.canRemovePromoCode = !!promoCode
      actions.canSendQuoteEmail = !moveDateHasPassed && nbQuotes > 0

      if (canCustomizeQuotes && !moveDateHasPassed && !hasPendingJobRequest && !isAcceptedByPartner) {
        actions.canSelectAndCustomizeQuote = nbQuotes > 0 && !hasPartialAddress
        actions.canEditSelectedQuote = !!selectedQuote && !hasPartialAddress
      }

      /* if the user has a payment method, we know he's part of the beta flow */
      if (canApproveMove && isAssigned && hasPaymentMethod) {
        actions.canApproveMove = isAcceptedByCustomer && !moveDateHasPassed && !isApprovedByAgent && !missingDeposit && !hasPendingJobRequest

        actions.canCancelJobRequest = hasPendingJobRequest && !moveDateHasPassed

        const hasJob = (move.jobs?.length ?? 0) > 0
        actions.canCancelJob = hasJob
      }
    }

    if (canChargeCustomers && isAssigned) {
      actions.canCreateInvoice = true
      actions.canCollectTotalBalance = hasPaymentMethod && balance.price > 0
      actions.canAskForPayment = balance.price > 0
    }

    /* regional special case */
    const isUsingDoubleDriveTime = shouldUseDoubleDriveTime(move)

    return {
      isActive,
      isHot,
      isMoveLocked,
      nbQuotes,
      lastQuoteRefresh,
      hasPartialAddress,

      selectedQuote,
      selectedCompanyName,
      selectedCompanyLogo,
      hasCustomQuote,
      isAcceptedByCustomer,
      isApprovedByAgent,
      isAcceptedByPartner,
      isDeclinedByPartner,

      hasPaidDeposit,
      missingDeposit,

      promoCode,
      isPromoCodeApplied,

      subtotal,
      moveDate,
      moveDateHasPassed,
      isJobCompleted,
      nbMen,
      nbTrucks,
      totalNbTrucks,

      isAssigned,
      hasOwnership,
      timezoneDiff,
      customerTime,

      currency,
      totalInvoices,
      totalPayments,
      balance,
      hasPaymentMethod,
      hasMoveInvoice,
      movePaid,

      isUsingDoubleDriveTime,

      ...actions,
    }
  }

  return {
    forMove: getMoveState,
    ...getMoveState(currentMove),
  }
}

export const useLightMoveState = () => {
  const getLightMoveState = (move: LightMove) => {
    const isMoveLocked = !!move.locked
    const moveDate = getMoveDate(new Date(move.movingDate), move.originTimezone)

    return {
      isMoveLocked,
      moveDate,
    }
  }

  return {
    forMove: getLightMoveState,
  }
}
