/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState } from 'react'
import clsx from 'clsx'
import { styled, alpha, lighten, darken } from '@mui/material/styles'
import Button from '@mui/material/Button'
import MuiMenu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { useDataGridContext } from '../../DataGridContext'
import { getBulkActions } from '../../DataGrid.util'
import { useTranslation } from 'react-i18next'

const Menu = styled(MuiMenu)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'dark' ? theme.palette.grey[300] : 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      background: theme.palette.mainBackground.main,
      '&:hover': {
        background: theme.palette.mode === 'dark' ? lighten(theme.palette.mainBackground.main, 0.05) : darken(theme.palette.mainBackground.main, 0.05),
      },
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&.danger': {
        color: theme.palette.error.main,
        '& .MuiSvgIcon-root': {
          color: theme.palette.error.main,
        },
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}))

const BulkActionsDropdown: React.FC = () => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()
  const { selectedRows, actions, clearSelection } = useDataGridContext()
  const bulkActions = getBulkActions(actions)
  const hasSelectedRows = selectedRows.length > 0

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const onClose = () => { setAnchorEl(undefined) }

  const onBulkOptionClick = (bulkCallback?: (ids: string[]) => any) => async () => {
    onClose()
    const response = await bulkCallback?.(selectedRows)
    if (response === false) {
      return
    }
    clearSelection()
  }

  const elligibleActions = bulkActions.filter(({ filter }) => !filter || filter(selectedRows))

  return (
    <>
      <Button
        onClick={onClick}
        variant="contained"
        color="secondary"
        disabled={!hasSelectedRows || elligibleActions.length === 0}
        endIcon={<KeyboardArrowDownIcon />}
      >
        { t('components.dataGrid.header.actions') }
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        { elligibleActions.map(({ label, icon: Icon, danger, bulkCallback }) => (
          <MenuItem
            key={label}
            value={label}
            className={clsx(danger && 'danger')}
            onClick={onBulkOptionClick(bulkCallback)}
          >
            <Icon />
            { label }
          </MenuItem>
        )) }
      </Menu>
    </>
  )
}

export default BulkActionsDropdown
