import { gql } from '@apollo/client'

import { userFields } from '../users.queries'

export const login = gql`
  mutation Login($payload: LoginDto!, $requiredOneOfPermissions: [Permission!]) {
    login(payload: $payload, requiredOneOfPermissions: $requiredOneOfPermissions) {
      valid
      ready
      mfaRequired
      token
    }
  }
`

export const externalMfaLogin = gql`
  mutation ExternalMfaLogin($payload: ExternalMfaLoginDto!) {
    externalMfaLogin(payload: $payload)
  }
`

export const logout = gql`
  mutation Mutation {
    logout
  }
`

export const extendSession = gql`
  mutation ExtendSession {
    extendSession
  }
`

export const resetPasswordRequest = gql`
mutation ResetPasswordRequest($payload: ResetPasswordRequestDto!) {
  resetPasswordRequest(payload: $payload)
}
`

export const resetPassword = gql`
mutation ResetPassword($payload: ResetPasswordDto!) {
  resetPassword(payload: $payload)
}
`

export const enableMfaVerifyAccount = gql`
mutation EnableMfaVerifyAccount {
  enableMfaVerifyAccount
}
`

export const enableMfaGetQrCode = gql`
mutation EnableMfaGetQrCode($payload: EnableMfaGetQrCodeDto!) {
  enableMfaGetQrCode(payload: $payload){
    secret
    qrCode
  }
}
`

export const enableMfa = gql`
mutation EnableMfa($payload: EnableMfaDto!) {
  enableMfa(payload: $payload){
    ${userFields}
  }
}
`

export const disableMfa = gql`
mutation DisableMfa($payload: DisableMfaDto!) {
  disableMfa(payload: $payload){
    ${userFields}
  }
}
`
