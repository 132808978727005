import { NotificationsProvider } from './notifications.context'
import { useNotificationsEngine } from './notifications.hooks'

type CompanyBranchNotificationsProps = {
  children?: React.ReactNode
}

const CompanyBranchNotifications: React.FC<CompanyBranchNotificationsProps> = ({
  children,
}) => {
  return (
    <NotificationsProvider {...useNotificationsEngine()}>
      { children }
    </NotificationsProvider>
  )
}

export default CompanyBranchNotifications
