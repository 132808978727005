/* eslint-disable @typescript-eslint/no-misused-promises */
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import ThumbUpIcon from '@mui/icons-material/ThumbUpAltOutlined'
import ThumbDownIcon from '@mui/icons-material/ThumbDownAltOutlined'

import {
  type JobRequestForPartner,
  useAcceptJobRequestAction,
  useDeclineJobRequestAction,
  useFetchPendingRequestsNotifications,
} from '../../../modules/jobRequests'
import ConfirmationModal, { useConfirmation } from '../../../components/ConfirmationModal'
import AcceptJobRequestConfirmationForm from '../../../components/forms/jobRequests/AcceptJobRequestConfirmationForm'
import DeclineJobRequestConfirmationForm from '../../../components/forms/jobRequests/DeclineJobRequestConfirmationForm'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import { errorNotification, successNotification } from '../../../components/ToastNotifications'

type JobRequestCtaProps = {
  jobRequest: JobRequestForPartner
}

const JobRequestCta: React.FC<JobRequestCtaProps> = ({
  jobRequest,
}) => {
  const { t } = useTranslation()
  const { confirm, modalProps } = useConfirmation()
  const { acceptJobRequest } = useAcceptJobRequestAction()
  const { declineJobRequest } = useDeclineJobRequestAction()
  const fetchPendingRequestsNotifications = useFetchPendingRequestsNotifications()

  const formatError = useErrorFormatter()

  const handleConfirm = async () => {
    const confirmation = await confirm({
      title: t('pages.companyBranch.jobRequest.acceptJob.modalTitle'),
      render: (props) => <AcceptJobRequestConfirmationForm {...props} />,
    })

    if (confirmation === false) {
      return
    }

    try {
      await acceptJobRequest(jobRequest.id, { message: confirmation.message })
      await fetchPendingRequestsNotifications().catch(console.error)
      successNotification(t('pages.companyBranch.jobRequest.acceptJob.success'))
      // todo : redirect to the job / agenda instead ?
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  const handleDecline = async () => {
    const confirmation = await confirm({
      title: t('pages.companyBranch.jobRequest.declineJob.modalTitle'),
      render: (props) => <DeclineJobRequestConfirmationForm {...props} />,
      danger: true,
    })

    if (confirmation === false) {
      return
    }

    try {
      await declineJobRequest(jobRequest.id, { message: confirmation.message })
      await fetchPendingRequestsNotifications().catch(console.error)
      successNotification(t('pages.companyBranch.jobRequest.declineJob.success'))
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  return (
    <>
      <Button
        variant="contained"
        color="error"
        size="large"
        startIcon={<ThumbDownIcon />}
        onClick={handleDecline}
      >
        { t('actions.decline') }
      </Button>

      <Button
        variant="contained"
        color="success"
        size="large"
        startIcon={<ThumbUpIcon />}
        onClick={handleConfirm}
      >
        { t('actions.accept') }
      </Button>
      <ConfirmationModal {...modalProps} />
    </>
  )
}

export default JobRequestCta
