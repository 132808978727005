import { mergeClassName } from '../../../utils/mergeClassName'

type PageContentProps = {
  children?: React.ReactNode
  className?: string
  wide?: boolean
  fullWidth?: boolean
}

const PageContent: React.FC<PageContentProps> = ({
  children,
  className,
  wide = false,
  fullWidth = false,
}) => {
  return (
    <div
      className={mergeClassName(
        'relative m-auto max-w-screen-2xl px-5 lg:px-6',
        wide && 'max-w-[1850px]',
        fullWidth && 'm-0 lg:max-w-[calc(100vw-80px)] lg:px-12 max-w-[100vw]',
        className,
      )}
    >
      { children }
    </div>
  )
}

export default PageContent
