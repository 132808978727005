import { useTranslation } from 'react-i18next'
import { type Interval } from 'date-fns'
import { eachWeekOfInterval } from 'date-fns/eachWeekOfInterval'
import { eachMonthOfInterval } from 'date-fns/eachMonthOfInterval'
import { addDays } from 'date-fns/addDays'

import { type RuleTemporality, RuleTemporalityType } from '../availabilityAndPricing.models'
import { type TemporalityHandler } from './temporalityRules.type'

/**
 * group week days by range to make sure they're connected in calendar
 * @fixme :  invalid connection from sunday to monday
 */
const splitInRanges = (days: number[]): number[][] => {
  const ranges = []
  let pending: number[] = []
  days.forEach(day => {
    const lastPending = pending.length > 0 ? pending[pending.length - 1] : undefined

    if (!lastPending || lastPending === day - 1) {
      pending.push(day)
    } else {
      ranges.push(pending)
      pending = [day]
    }
  })
  ranges.push(pending)
  return ranges
}

const useEveryWeekDayRuleHandler = (): TemporalityHandler => {
  const { t } = useTranslation()

  return (rule: RuleTemporality) => {
    if (rule.type !== RuleTemporalityType.EveryWeekDay) {
      return
    }

    return {
      filter: (period: Interval) => {
        if (!rule.days?.length) {
          return false
        }
        if (!rule?.months?.length) {
          return true
        }
        const months = eachMonthOfInterval(period).map(date => date.getMonth())
        return months.some(month => rule.months?.includes(month))
      },

      isRuleActiveForDate: (date: Date) => {
        if (!rule.days?.length) {
          return false
        }
        const currentMonth = date.getMonth()
        const ruleMonths = rule.months ?? []
        if (ruleMonths.length > 0 && !ruleMonths.includes(currentMonth)) {
          return false
        }
        const isoDay = date.getDay() === 0 ? 7 : date.getDay()
        return rule.days.includes(isoDay)
      },

      getCalendarEntries: (period: Interval) => {
        if (!rule.days?.length) {
          return
        }
        const activesDays = rule.days.filter(d => d >= 1 && d <= 7)
        activesDays.sort((a, b) => a - b)

        const dateRange: Array<Interval | Date> = []
        const mondays = eachWeekOfInterval(period, { weekStartsOn: 1 })
        const dayRanges = splitInRanges(activesDays)

        for (const monday of mondays) {
          for (const range of dayRanges) {
            const dates = range
              .map(day => addDays(monday, day - 1))
              .filter(date => {
                if (!rule?.months?.length) {
                  return true
                }
                return rule.months.includes(date.getMonth())
              })
            if (dates.length > 0) {
              dateRange.push(
                dates.length === 1
                  ? dates[0]
                  : {
                      start: dates[0],
                      end: addDays(dates[dates.length - 1], 1),
                    },
              )
            }
          }
        }

        return dateRange
      },

      getTitle: () => {
        const activesDays = rule.days.filter(d => d >= 1 && d <= 7)
        activesDays.sort((a, b) => a - b)

        if (activesDays[0] === 6 && activesDays[1] === 7) {
          return t('date.everyWeekend')
        }

        const dayNames = Object.values(t('date.weekDays', { returnObjects: true }))
        const days = activesDays.map(day => dayNames[day - 1])
        const lastDay = days.pop()

        const connected = activesDays[activesDays.length - 1] - activesDays[0] === activesDays.length - 1
        if (connected && activesDays.length > 2) {
          return t('date.everyDayConnected', {
            firstDay: days[0],
            lastDay,
          })
        }

        return t('date.everyDay', {
          count: activesDays.length,
          days: days.join(', '),
          lastDay,
        })
      },

      getColor: () => '#b0511a',

      isRecurrent: () => true,
    }
  }
}

export default useEveryWeekDayRuleHandler
