import { useLazyQuery, useMutation, useQuery } from '@apollo/client'

import { type PaginatedQuery, type PaginatedQueryResult, type PaginatedQueryResultData, type QueryResult } from '../../graphQl'
import { type CompanyBranchCoordinates, type FullCompanyBranch } from './adminCompanies.models'
import * as queries from './adminCompanies.queries'
import * as mutations from './adminCompanies.mutations'
import { type InvitePartnerPayload } from './adminCompanies.type'

type UseCompaniesBranchesHookResult = {
  fetchCompaniesBranches: (variables: PaginatedQuery) => Promise<PaginatedQueryResultData<FullCompanyBranch>>
} & PaginatedQueryResult<FullCompanyBranch>

type UseExportCompaniesForBlogHookResult = {
  exportCompaniesForBlog: () => Promise<string>
} & QueryResult<string>

/**
 * returns all companies branches
 * query optimized for data table
 */
export const useCompaniesBranches = ({ reload = false, ...queryArgs } = {}): UseCompaniesBranchesHookResult => {
  const fetchPolicy = reload ? 'network-only' : 'cache-first'

  const [fetch, { data, previousData, networkStatus, loading, error, ...queryProps }] = useLazyQuery(
    queries.getCompaniesBranches,
    {
      fetchPolicy,
      ...queryArgs,
    },
  )
  const fetchCompaniesBranches = async (query?: PaginatedQuery) => {
    const { data } = await fetch({ variables: { query } })
    return data?.admin_getCompaniesBranches
  }

  return {
    fetchCompaniesBranches,
    data: (data ?? previousData)?.admin_getCompaniesBranches,
    loading: (loading && networkStatus === 1),
    error,
    ...queryProps,
  }
}

/**
 * returns all companies branches coordinates to display them on a map
 */
export const useCompaniesBranchesCoordinates = ({ reload = false, ...queryArgs } = {}): QueryResult<[CompanyBranchCoordinates]> => {
  /* istanbul ignore next ; ignore data reloading */
  const fetchPolicy = reload ? 'network-only' : 'cache-first'

  const { data, networkStatus, error, ...queryProps } = useQuery(
    queries.getCompaniesBranchesCoordinates,
    {
      fetchPolicy,
      ...queryArgs,
    },
  )

  return {
    data: data?.admin_getCompaniesBranchesCoordinates,
    error,
    ...queryProps,
  }
}

/**
 * return base64 csv file containing all companies formatted for the blog
 */
export const useCompaniesExportForBlog = ({ reload = false, ...queryArgs } = {}): UseExportCompaniesForBlogHookResult => {
  const fetchPolicy = reload ? 'network-only' : 'cache-first'

  const [fetch, { data, previousData, networkStatus, loading, error, ...queryProps }] = useLazyQuery(
    queries.exportCompaniesForBlog,
    {
      fetchPolicy,
      ...queryArgs,
    },
  )
  const exportCompaniesForBlog = async (query?: PaginatedQuery) => {
    const { data } = await fetch({ variables: { query } })
    return data?.admin_exportCompaniesForBlog
  }

  return {
    exportCompaniesForBlog,
    data: (data ?? previousData)?.admin_exportCompaniesForBlog,
    loading: (loading && networkStatus === 1),
    error,
    ...queryProps,
  }
}

/**
 * delete multiple company branches
 */
export const useDeleteCompanyBranchesAction = () => {
  const [deleteCompanyBranches, { data, loading, ...mutationProps }] = useMutation(mutations.deleteCompanyBranches)

  return {
    deleteCompanyBranches: async (ids: string[]) => {
      const { data } = await deleteCompanyBranches({
        variables: {
          ids,
        },
      })

      return data.admin_deleteCompanyBranches
    },
    data: data?.admin_deleteCompanyBranches,
    loading,
    ...mutationProps,
  }
}

/**
 * restore multiple company branches
 */
export const useRestoreCompanyBranchesAction = () => {
  const [restoreCompanyBranches, { data, loading, ...mutationProps }] = useMutation(mutations.restoreCompanyBranches)

  return {
    restoreCompanyBranches: async (ids: string[]) => {
      const { data } = await restoreCompanyBranches({
        variables: {
          ids,
        },
      })

      return data.admin_restoreCompanyBranches
    },
    data: data?.admin_restoreCompanyBranches,
    loading,
    ...mutationProps,
  }
}

/**
 * approve multiple company branches
 */
export const useApproveCompanyBranchesAction = () => {
  const [approveCompanyBranches, { data, loading, ...mutationProps }] = useMutation(mutations.approveCompanyBranches)

  return {
    approveCompanyBranches: async (ids: string[]) => {
      const { data } = await approveCompanyBranches({
        variables: {
          ids,
        },
      })

      return data.admin_approveCompanyBranches
    },
    data: data?.admin_approveCompanyBranches,
    loading,
    ...mutationProps,
  }
}

/**
 * unapprove multiple company branches
 */
export const useUnapproveCompanyBranchesAction = () => {
  const [unapproveCompanyBranches, { data, loading, ...mutationProps }] = useMutation(mutations.unapproveCompanyBranches)

  return {
    unapproveCompanyBranches: async (ids: string[]) => {
      const { data } = await unapproveCompanyBranches({
        variables: {
          ids,
        },
      })

      return data.admin_unapproveCompanyBranches
    },
    data: data?.admin_unapproveCompanyBranches,
    loading,
    ...mutationProps,
  }
}

/**
 * pause multiple company branches
 */
export const usePauseCompanyBranchesAction = () => {
  const [pauseCompanyBranches, { data, loading, ...mutationProps }] = useMutation(mutations.pauseCompanyBranches)

  return {
    pauseCompanyBranches: async (ids: string[]) => {
      const { data } = await pauseCompanyBranches({
        variables: {
          ids,
        },
      })

      return data.admin_pauseCompanyBranches
    },
    data: data?.admin_pauseCompanyBranches,
    loading,
    ...mutationProps,
  }
}

/**
 * resume multiple company branches
 */
export const useResumeCompanyBranchesAction = () => {
  const [resumeCompanyBranches, { data, loading, ...mutationProps }] = useMutation(mutations.resumeCompanyBranches)

  return {
    resumeCompanyBranches: async (ids: string[]) => {
      const { data } = await resumeCompanyBranches({
        variables: {
          ids,
        },
      })

      return data.admin_resumeCompanyBranches
    },
    data: data?.admin_resumeCompanyBranches,
    loading,
    ...mutationProps,
  }
}

/**
 * get user id for given email (invitation)
 */
export const useGetInvitationUserIdAction = () => {
  const [getInvitationUserId, { data, loading, ...mutationProps }] = useMutation(mutations.getInvitationUserId)

  return {
    getInvitationUserId: async (email: string) => {
      const { data } = await getInvitationUserId({
        variables: {
          payload: { email },
        },
      })

      return data.admin_getInvitationUserId
    },
    data: data?.admin_getInvitationUserId,
    loading,
    ...mutationProps,
  }
}

/**
 * invite a partner
 */
export const useInvitePartnerAction = () => {
  const [invitePartner, { data, loading, ...mutationProps }] = useMutation(mutations.invitePartner)

  return {
    invitePartner: async (companyId: string, payload: InvitePartnerPayload) => {
      const { data } = await invitePartner({
        variables: {
          companyId,
          payload,
        },
      })

      return data.admin_invitePartner
    },
    data: data?.admin_invitePartner,
    loading,
    ...mutationProps,
  }
}

/**
 * cancel a partner invitation
 */
export const useCancelPartnerInvitationAction = () => {
  const [cancelPartnerInvitation, { data, loading, ...mutationProps }] = useMutation(mutations.cancelPartnerInvitation)

  return {
    cancelPartnerInvitation: async (invitationId: string) => {
      const { data } = await cancelPartnerInvitation({
        variables: {
          invitationId,
        },
      })

      return data.admin_cancelPartnerInvitation
    },
    data: data?.admin_cancelPartnerInvitation,
    loading,
    ...mutationProps,
  }
}

/**
 * dissociate a partner from a company
 */
export const useDissociatePartnerAction = () => {
  const [dissociatePartner, { data, loading, ...mutationProps }] = useMutation(mutations.dissociatePartner)

  return {
    dissociatePartner: async (companyId: string) => {
      const { data } = await dissociatePartner({
        variables: {
          companyId,
        },
      })

      return data.admin_dissociatePartner
    },
    data: data?.admin_dissociatePartner,
    loading,
    ...mutationProps,
  }
}
