import { useQuery, useMutation } from '@apollo/client'

import { type QueryResult } from '../../../graphQl'
import * as queries from './externalEvents.queries'
import * as mutations from './externalEvents.mutations'
import { type UpdateImportedCalendarsPayload, type ExternalEvent } from './externalEvents.types'
import { useCurrentCompanyBranch } from '../companies.hooks'

/**
 * returns external events (Outlook, Google Calendar, etc) to display in calendar
 */
export const useExternalEvents = (companyBranchId?: string, { reload = false, ...queryArgs } = {}): QueryResult<ExternalEvent[]> => {
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()
  const fetchPolicy = reload ? 'network-only' : 'cache-first'

  companyBranchId ??= currentCompanyBranch?.id

  const { data, networkStatus, loading, error, ...queryProps } = useQuery(
    queries.getExternalEvents,
    {
      skip: !companyBranchId,
      variables: { companyBranchId },
      fetchPolicy,
      ...queryArgs,
    },
  )

  return {
    data: data?.getExternalEvents ?? [],
    loading: (loading && networkStatus === 1),
    error,
    ...queryProps,
  }
}

/**
 * enable / disable calendar export
 */
export const useSetCalendarExportEnabledAction = () => {
  const [setCalendarExportEnabled, { data, loading, ...mutationProps }] = useMutation(mutations.setCalendarExportEnabled)
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()

  return {
    setCalendarExportEnabled: async (enabled: boolean) => {
      const { data } = await setCalendarExportEnabled({
        variables: {
          companyBranchId: currentCompanyBranch?.id,
          enabled,
        },
      })

      return data.setCalendarExportEnabled
    },
    data: data?.setCalendarExportEnabled,
    loading,
    ...mutationProps,
  }
}

/**
 * update calendar secret url
 */
export const useUpdateCalendarUrlAction = () => {
  const [updateCalendarUrl, { data, loading, ...mutationProps }] = useMutation(mutations.updateCalendarUrl)
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()

  return {
    updateCalendarUrl: async () => {
      const { data } = await updateCalendarUrl({
        variables: {
          companyBranchId: currentCompanyBranch?.id,
        },
      })

      return data.updateCalendarUrl
    },
    data: data?.updateCalendarUrl,
    loading,
    ...mutationProps,
  }
}

/**
 * update calendar secret url
 */
export const useSetImportedCalendarsAction = () => {
  const [setImportedCalendars, { data, loading, ...mutationProps }] = useMutation(mutations.setImportedCalendars)
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()

  return {
    setImportedCalendars: async (payload: UpdateImportedCalendarsPayload) => {
      const { data } = await setImportedCalendars({
        variables: {
          companyBranchId: currentCompanyBranch?.id,
          payload,
        },
      })

      return data.setImportedCalendars
    },
    data: data?.setImportedCalendars,
    loading,
    ...mutationProps,
  }
}
