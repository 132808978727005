import clsx from 'clsx'
import { styled } from '@mui/material/styles'

import StaticCheckbox from './StaticCheckbox'
import { useDataGridContext } from '../DataGridContext'
import { getIndividualActions, getBulkActions } from '../DataGrid.util'
import Column from './Column'
import { useTranslation } from 'react-i18next'

const HeaderTableRow = styled('tr')(({ theme }) => ({
  verticalAlign: 'center',
  '&.hasCheckBox th:nth-of-type(1)': {
    position: 'sticky',
    left: 0,
    zIndex: 11,
  },
  '&.hasActions th:nth-of-type(1)': {
    position: 'sticky',
    left: 0,
    zIndex: 11,
  },
  '&.hasCheckBox.hasActions th:nth-of-type(2)': {
    position: 'sticky',
    left: 62,
    zIndex: 11,
  },
  [theme.breakpoints.down('md')]: {
    '&.hasCheckBox.hasActions th:nth-of-type(2)': {
      left: 0,
    },
  },
}))

const CheckboxTableHeaderCell = styled(Column)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const RowCheckbox = styled(StaticCheckbox)(({ theme }) => ({
  marginTop: 6,
  marginLeft: 10,
}))

type HeaderRowProps = {
  children?: React.ReactNode
}

const Header: React.FC<HeaderRowProps> = ({
  children,
}) => {
  const { t } = useTranslation()
  const { allSelected, toggleAllRowSelection, actions } = useDataGridContext()

  const hasIndividualActions = getIndividualActions(actions).length > 0
  const hasBulkActions = getBulkActions(actions).length > 0

  return (
    <thead>
      <HeaderTableRow className={clsx(
        hasBulkActions && 'hasCheckBox',
        hasIndividualActions && 'hasActions',
      )}
      >
        { hasBulkActions && (
          <CheckboxTableHeaderCell>
            <RowCheckbox checked={allSelected} onClick={toggleAllRowSelection} />
          </CheckboxTableHeaderCell>
        ) }

        { hasIndividualActions && (
          <Column>
            { t('components.dataGrid.header.actions') }
          </Column>
        ) }

        { children }
      </HeaderTableRow>
    </thead>
  )
}

export default Header
