import { useQuery } from '@apollo/client'

import { type QueryResult } from '../../graphQl'
import { type AdminDashboardMetrics } from './dashboard.models'
import * as queries from './dashboard.queries'

/**
 * returns dashboard metrics
 */
export const useAdminDashboardMetrics = ({ reload = false, skip = false, ...queryArgs } = {}): QueryResult<AdminDashboardMetrics | undefined> => {
  const fetchPolicy = reload ? 'network-only' : 'cache-first'

  const { data, networkStatus, loading, error, ...queryProps } = useQuery(
    queries.getDashboardMetrics,
    {
      skip,
      fetchPolicy,
      ...queryArgs,
    },
  )

  return {
    data: data?.admin_getAdminDashboardMetrics,
    loading: (loading && networkStatus === 1),
    error,
    ...queryProps,
  }
}
