import { Permission, useCurrentUserAccountType, usePermission } from '../../../modules/users'
import Menu from './Menu'
import AdminMenuContent from './AdminMenuContent'
import PartnerMenuContent from './PartnerMenuContent'
import { useOnBehalfState } from '../../../modules/companies'

const Menus: React.FC = () => {
  const canReadCompanies = usePermission(Permission.canReadCompanies)
  const { isAdmin } = useCurrentUserAccountType()

  let { onBehalf } = useOnBehalfState()
  onBehalf &&= canReadCompanies

  return (
    <>
      { isAdmin && <Menu adminStyle><AdminMenuContent /></Menu> }
      { (!isAdmin || onBehalf) && <Menu secondaryMenu={isAdmin}><PartnerMenuContent /></Menu> }
    </>
  )
}

export default Menus
