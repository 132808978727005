import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'

import { Form, Input, PhoneNumberField, SubmitButton, SubmitButtonsContainer } from '../../../form'
import { type UpdateUserPayload, type User, useCurrentUser } from '../../../../modules/users'
import useValidationSchema from './validationSchema'
import {
  Row,
  Cell,
} from '../../../../components/cell'
import Spinner from '../../../Spinner'
import { userToFormData, formatFormOutput } from './formDataBuilder'

type EditProfileFormProps = {
  onSubmit: (payload: UpdateUserPayload) => Promise<any>
  onClose?: () => void
}

const UpdateUserForm: React.FC<EditProfileFormProps> = ({ onSubmit, onClose }) => {
  const { t } = useTranslation()
  const { data: user } = useCurrentUser()
  const schema = useValidationSchema()
  const form = useForm({
    defaultValues: userToFormData(user) as User,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  })

  if (!user) {
    return <Spinner />
  }

  const onFormSubmit = async (data: UpdateUserPayload) => {
    return await onSubmit(formatFormOutput(data))
  }

  return (
    <Form form={form} onSubmit={onFormSubmit}>
      <Row>
        <Cell>
          <Input
            name="firstName"
            label={t('forms.fields.firstName.label')}
            variant="standard"
            margin="normal"
            required
          />
        </Cell>

        <Cell>
          <Input
            name="lastName"
            label={t('forms.fields.lastName.label')}
            variant="standard"
            margin="normal"
            required
          />
        </Cell>
      </Row>

      <Row>
        <Cell>
          <div className="mt-[15px] lg:mt-[21px]">
            <PhoneNumberField
              name="phoneNumber"
              label={String(t('forms.fields.phoneNumber.label'))}
              variant="standard"
              required
            />
          </div>
        </Cell>

        <Cell>
          <Input
            name="position"
            label={t('forms.fields.position.label')}
            variant="standard"
            margin="normal"
            required
          />
        </Cell>
      </Row>

      <SubmitButtonsContainer>
        { onClose && <Button onClick={onClose}>{ t('actions.cancel') }</Button> }
        <SubmitButton>{ t('actions.save') }</SubmitButton>
      </SubmitButtonsContainer>
    </Form>
  )
}

export default UpdateUserForm
