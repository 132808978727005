import { useTranslation } from 'react-i18next'
import CompanyIcon from '@mui/icons-material/BusinessOutlined'

import { type FullMove } from '../../../../../modules/adminMoves'
import { useMoveState } from '../../../../../modules/moves'
import { useDateFormatter } from '../../../../../utils/date'
import Paper from '../../../../../components/Paper'
import { Cell, Label, Row, Value } from '../../../../../components/cell'
import TimeAgo from '../../../../../components/TimeAgo'
import { JobStatus, type BasicJob } from '../../../../../modules/jobs'
import JobStatusTag from '../../../../../components/job/JobStatusTag'
import MoveActionButton from '../../MoveActionButton'
import { ActionName } from '../../useActions'

type PartnerJobBoxProps = {
  move: FullMove
  job: BasicJob
}

const PartnerJobBox: React.FC<PartnerJobBoxProps> = ({
  move,
  job,
}) => {
  const { t } = useTranslation()
  const formatDate = useDateFormatter()
  const {
    selectedCompanyName,
    canCancelJob,
  } = useMoveState().forMove(move)

  if (!selectedCompanyName) {
    return null
  }

  const canBeCancelled = canCancelJob && [JobStatus.Pending, JobStatus.Completed].includes(job.status)

  return (
    <Paper>

      <div className="flex justify-between">
        <h2 className="mb-4 font-body2 text-2xl font-semibold text-slate-700">
          <CompanyIcon />
          { ' ' }
          { t('pages.admin.move.details.partnerJob.title', { companyName: selectedCompanyName }) }
        </h2>

        <div>
          <MoveActionButton
            move={move}
            actionName={ActionName.CancelJob}
            args={job.id}
            overrideAction={{
              enabled: canBeCancelled,
            }}
          />
        </div>

      </div>

      <div className="pb-4">
        <Row>
          <Cell>
            <Label>
              { t('pages.admin.move.details.partnerJob.date') }
            </Label>
            <Value>
              { formatDate(job.date) }
              <span className="inline-block pl-2 text-sm text-neutral-400">
                (<TimeAgo date={job.date} />)
              </span>
            </Value>
          </Cell>

          <Cell>
            <Label>
              { t('pages.admin.move.details.partnerJob.time') }
            </Label>
            <Value>
              { t(`arrivalWindows.${job.time}.title`) }
            </Value>
          </Cell>

          <Cell>
            <Label>
              { t('pages.admin.move.details.partnerJob.status') }
            </Label>
            <Value>
              <JobStatusTag jobStatus={job.status} />
            </Value>
          </Cell>

          <Cell />
        </Row>
      </div>
    </Paper>
  )
}

export default PartnerJobBox
