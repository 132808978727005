import { Menu as HLMenu, type MenuItemProps as HLMenuItemProps } from '@headlessui/react'
import { mergeClassName } from '../../utils/mergeClassName'

type MenuItemProps = {
  children?: React.ReactNode
} & HLMenuItemProps<any>

const MenuItem: React.FC<MenuItemProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <HLMenu.Item {...props}>
      { ({ active }) => (
        <div
          className={mergeClassName(
            active && 'bg-gray-100',
            'block cursor-pointer px-4 py-2 text-sm text-gray-700 whitespace-nowrap',
            className,
          )}
        >
          { children }
        </div>
      ) }
    </HLMenu.Item>
  )
}

export default MenuItem
