import { format as dateFnsFormatDate } from 'date-fns/format'
import { frCA as dateFnsFrCa } from 'date-fns/locale/fr-CA'
import { enCA as dateFnsEnCa } from 'date-fns/locale/en-CA'

import Language from '../../app/Language.enum'
import { useLanguage } from '../../modules/i18n'

export const formatDate = (date?: Date | string | null, format = 'y-MM-dd') => {
  if (!date) {
    return
  }
  const dateObject = typeof date === 'string' ? new Date(date) : date
  return dateFnsFormatDate(dateObject, format)
}

export const formatDateTime = (date?: Date | string | null) => {
  return formatDate(date, 'y-MM-dd H:mm:ss')
}

export const useDateFnsLocale = (language?: string) => {
  const defaultLanguage = useLanguage()

  switch (language ?? defaultLanguage) {
    case Language.Fr:
      return dateFnsFrCa
    case Language.En:
    default:
      return dateFnsEnCa
  }
}

type formatDateOptions = {
  showYear?: boolean
  shortMonth?: boolean
  shortDay?: boolean
  showTime?: boolean
}

/**
 * date formatter that returns a string
 */
export const useDateStringFormatter = (language?: string) => {
  const currentLanguage = useLanguage()
  language ??= currentLanguage ?? Language.En
  const locale = useDateFnsLocale(language)

  // eslint-disable-next-line react/display-name
  return (
    date?: any,
    {
      showYear = true,
      shortMonth = false,
      shortDay = false,
    }: formatDateOptions = {},
  ): string | undefined => {
    if (!date) {
      return undefined
    }
    if (typeof date === 'string') {
      date = new Date(date)
    }

    let pattern: string
    switch (language) {
      case Language.Fr: {
        const month = shortMonth ? 'MMM' : 'MMMM'
        pattern = showYear ? `d ${month} y` : `d ${month}`
        break
      }
      case Language.En:
      default: {
        const month = shortMonth ? 'MMM.' : 'MMMM'
        const day = shortDay ? 'd' : 'do'
        pattern = showYear ? `${month} ${day}, y` : `${month} ${day}`
      }
    }

    if (date instanceof Date && !isNaN(date.getTime())) {
      return dateFnsFormatDate(date, pattern, { locale })
    }
  }
}

/**
 * date formatter that returns a string or React node
 */
export const useDateFormatter = (language?: string) => {
  const formatDateAsString = useDateStringFormatter()

  // eslint-disable-next-line react/display-name
  return (
    date?: any,
    {
      showTime,
      ...options
    }: formatDateOptions = {},
  ): string | React.ReactNode => {
    const formatted = formatDateAsString(date, options)
    if (!formatted) {
      return undefined
    }

    const time = showTime ? ` ${formatDate(date, 'H:mm:ss') ?? ''}` : ''

    if (language === Language.Fr && formatted.substring(0, 2) === '1 ') {
      return <>1<sup>er</sup>{ formatted.substring(1) }{ time }</>
    }
    return `${formatted}${time}`
  }
}

/**
 * ignore time zone of a utc date
 */
export const ignoreTimezone = (date?: string | Date) => {
  if (!date) {
    return undefined
  }
  const dateString = typeof date === 'string' ? date : date.toISOString()
  return new Date(dateString.substring(0, dateString.length - 1))
}
