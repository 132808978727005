import { useEffect } from 'react'
import { clsx } from 'clsx'
import { styled } from '@mui/material/styles'
import SortableIcon from '@mui/icons-material/UnfoldMore'
import AscSortIcon from '@mui/icons-material/ExpandLess'
import DescSortIcon from '@mui/icons-material/ExpandMore'

import { SortDirection } from '../../../modules/graphQl'
import { useDataGridContext } from '../DataGridContext'
import { useLanguage } from '../../../modules/i18n'
import { useVisibleColumns } from '../useToggleColumns'

type ReactThProps = React.DetailedHTMLProps<React.ThHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>

const Th = styled('th')(({ theme }) => ({
  position: 'sticky',
  top: 0,
  textAlign: 'left',
  padding: '0.7rem 1rem 0.7rem 0.75rem',
  height: 60,
  background: '#fff',
  zIndex: 10,
  fontSize: '0.875rem',
  fontFamily: 'Montserrat',
  '&.sortable': {
    cursor: 'pointer',
    paddingRight: '2.5rem',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  '& .sortableIcon': {
    position: 'absolute',
    right: '0.5rem',
    opacity: 0.3,
  },
  '& .sortIcon': {
    position: 'absolute',
    right: 0,
  },
  '&:last-of-type': {
    paddingRight: 100,
  },
  '&:last-of-type .sortIcon, &:last-of-type .sortableIcon': {
    right: 60,
  },
}))

type ColumnProps = {
  name?: string
  children?: React.ReactNode
  sortable?: boolean
  defaultVisible?: boolean
} & ReactThProps

const Column: React.FC<ColumnProps> = ({
  name,
  children,
  sortable = false,
  defaultVisible = true,
  ...thProps
}) => {
  const { query, setQuery, registerColumn } = useDataGridContext()
  const visibleColumns = useVisibleColumns()
  const language = useLanguage()
  const currentSortedColumn = query.sortField
  const currentSortDirection = query.sortDirection
  const isVisible = name ? visibleColumns.includes(name) : true

  useEffect(() => {
    if (!name) {
      return
    }
    registerColumn(name, { label: children, defaultVisible })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  const getColumnNewSortDirection = (column: string) => {
    if (column !== currentSortedColumn) {
      return SortDirection.ASC
    }
    if (currentSortDirection === SortDirection.ASC) {
      return SortDirection.DESC
    }
  }

  const sortColumn = (column: string) => {
    const newDirection = getColumnNewSortDirection(column)
    setQuery({
      ...query,
      sortField: newDirection ? column : undefined,
      sortDirection: newDirection,
      page: 1,
    })
  }

  const renderSortableIcon = (column: string) => {
    if (column !== currentSortedColumn) {
      return <SortableIcon className="sortableIcon" />
    }
    if (currentSortDirection === SortDirection.ASC) {
      return <AscSortIcon className="sortIcon" />
    }
    return <DescSortIcon className="sortIcon" />
  }

  if (!isVisible) {
    return null
  }

  return (
    <Th
      className={clsx(
        sortable && 'sortable',
      )}
      onClick={sortable && name ? () => { sortColumn(name) } : undefined}
      {...thProps}
    >
      { children }
      { sortable && name && renderSortableIcon(name) }
    </Th>
  )
}

export default Column
