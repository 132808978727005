import MuiToggleButtonGroup, { type ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup'

import { mergeClassName } from '../../utils/mergeClassName'

const ToggleButtonGroup: React.FC<ToggleButtonGroupProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <MuiToggleButtonGroup
      size="small"
      className={mergeClassName(
        '!bg-white child:!normal-case',
        className,
      )}
      {...props}
    >
      { children }
    </MuiToggleButtonGroup>
  )
}

export default ToggleButtonGroup
