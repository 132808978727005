import * as Yup from 'yup'

import useChargesValidation, {
  useFloatValidator,
} from '../../invoice/ChargesFormContent/validator'
import { type InvoiceFormValue } from './InvoiceForm.type'

const useValidationSchema = () => {
  const chargesValidation = useChargesValidation()
  const floatValidator = useFloatValidator()

  return Yup.object({
    charges: chargesValidation,
    tip: floatValidator({ min: -99999, max: 99999 }),
  }) as Yup.ObjectSchema<InvoiceFormValue, InvoiceFormValue>
}

export default useValidationSchema
