import { ArrivalWindow } from './arrivalWindow'

export const getCompanyBranchRouteRegExp = (route: string) => {
  const anyCompanyRegExp = '([^/]+/)?'
  const anyBranchRegExp = '[^/]*'

  return new RegExp(
    route
      .replace(':companyId?/', anyCompanyRegExp)
      .replace(':branchId', anyBranchRegExp),
  )
}

const arrivalWindowValues: Record<ArrivalWindow, number> = {
  [ArrivalWindow.From7to8]: 7,
  [ArrivalWindow.From8to9]: 8,
  [ArrivalWindow.From9to10]: 9,
  [ArrivalWindow.From13to15]: 13,
  [ArrivalWindow.From16to19]: 16,
}

export const getArrivalWindowValue = (arrivalWindow: ArrivalWindow) => arrivalWindowValues[arrivalWindow]
