import ProfileHeader from '../../../components/users/profile/ProfileHeader'
import LeftSidebarPage from '../../../components/layout/LeftSidebarPage/LeftSidebarPage'
import ProfileSidebar from '../../../components/users/profile/ProfileSidebar'

type PageContainerProps = {
  children?: React.ReactNode
  mobileHeader?: React.ReactNode
}

const PageContainer: React.FC<PageContainerProps> = ({
  children,
  mobileHeader,
}) => {
  return (
    <LeftSidebarPage
      sidebar={<ProfileSidebar />}
      topContent={<ProfileHeader />}
      mobileHeader={mobileHeader}
    >
      { children }
    </LeftSidebarPage>
  )
}

export default PageContainer
