import { Currency, type Amount } from '../../common/amount'
import { getCurrencyDetails } from './getCurrencyDetails'

const DEFAULT_LOCALE = 'en-US'

export type FormatPriceOptions = {
  compact?: boolean
  showDecimals?: boolean
  showCurrency?: boolean
}

export const formatPrice = (
  amount: Amount,
  {
    compact = false,
    showDecimals = true,
    showCurrency = false,
  }: FormatPriceOptions = {},
) => {
  const { symbol } = getCurrencyDetails(amount.currency)
  const priceFormatter = new Intl.NumberFormat(DEFAULT_LOCALE, {
    style: 'currency',
    currency: Currency.USD,
  })

  const price = showDecimals ? amount.price : Math.ceil(amount.price)

  const isMinus = price < 0
  const formattedPrice = priceFormatter.format(price).split('$')[1]
  const parts = formattedPrice.split('.')

  return [
    showCurrency ? `${amount.currency} ` : '',
    isMinus ? '-' : '',
    isMinus && !compact ? ' ' : '',
    symbol,
    !compact ? ' ' : '',
    parts[0],
    showDecimals ? `.${parts[1]}` : '',
  ]
    .join('')
    .trim()
}
