/* istanbul ignore file */

import {
  ApolloClient,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

// @ts-expect-error ; retarded library
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import i18n from 'i18next'
import Cookies from 'js-cookie'

import config from '../../../config'
import { isSsr } from '../../ssr'
import { setValidationErrors } from './graphQl.actions'
import store from '../../../store'
import Route from '../../../app/Route.enum'

const {
  isProduction,
  api,
  users,
  whiteListed401Paths,
} = config

/**
 * detect backend errors
 *  - expired session
 *  - validation errors
 *  - graphQl core error (ex: missing backend validations)
 */
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (!graphQLErrors) {
    return
  }

  /* handle expired session */

  const has401Response = graphQLErrors.find(error => {
    const response = error?.extensions?.response as any
    const statusCode = response?.statusCode
    return statusCode === 401
  })
  if (has401Response && !isSsr()) {
    if (whiteListed401Paths.includes(window.location.pathname)) {
      return
    }
    Cookies.remove(users.authentication.authenticatedCookieName)
    document.location = `${Route.Login}?expired=1`
  }

  /* detect validation errors and set them in the redux store */

  const fieldsErrors: string[] = []
  graphQLErrors.forEach((graphQLError) => {
    const message = (graphQLError.extensions?.response as any)?.message
    const fields = (graphQLError.extensions?.response as any)?.error
    if (message === 'VALIDATION_ERROR' && fields) {
      fieldsErrors.push(fields.split('|'))
    }
  })
  if (fieldsErrors.length > 0) {
    store.dispatch(setValidationErrors(Array.from(new Set(fieldsErrors.flat()))))
  } else {
    /* detect graphQl core errors */

    const fieldsErrors: string[] = []
    graphQLErrors.forEach((graphQLError) => {
      const code = (graphQLError.extensions as any)?.code
      const fields: string[] = Object.keys((graphQLError.extensions?.exception as any)?.errors || {})
      if (code === 'INTERNAL_SERVER_ERROR' && fields.length > 0) {
        fieldsErrors.push(...fields)
      }
    })
    store.dispatch(setValidationErrors(Array.from(new Set(fieldsErrors.flat()))))
  }
})

/**
 * use current app language as accepted language when calling API
 */
const authLink = setContext((_, { headers }) => {
  store.dispatch(setValidationErrors([]))
  return {
    headers: {
      ...headers,
      'Accept-Language': i18n.language ?? 'en',
    },
  }
})

/**
 * include http only cookies when calling API
 * this also uses a 3rd party library to support file uploads
 */
const uploadLink = createUploadLink({
  uri: `${api?.url}/graphql`,
  credentials: api?.includeCredentials ? 'include' : undefined,
  headers: { 'Apollo-Require-Preflight': 'true' },
})

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(uploadLink)),
  cache: new InMemoryCache(),
  connectToDevTools: !isProduction,
})

export default client
