import { useState } from 'react'
import { Loader } from '@googlemaps/js-api-loader'

import { useConfig } from '../../config'
import { useEffectOnce } from '../../../utils/useEffectOnce'
import Spinner from '../../../components/Spinner'

type GoogleMapProviderProps = {
  children?: React.ReactNode
}

const GoogleMapProvider: React.FC<GoogleMapProviderProps> = ({ children }) => {
  const apiKey = useConfig('googleMaps.apiKey')
  const [loaded, setLoaded] = useState(false)

  const loader = new Loader({
    apiKey,
  })

  useEffectOnce(() => {
    loader.importLibrary('places')
      .catch(console.error)
      .finally(() => {
        setLoaded(true)
      })
  })

  if (!loaded) {
    return <Spinner floating />
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{ children }</>
}

export default GoogleMapProvider
