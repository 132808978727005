/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { styled } from '@mui/material/styles'
import { grey } from '@mui/material/colors'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import GoogleIcon from '@mui/icons-material/Google'
import FacebookIcon from '@mui/icons-material/Facebook'

import { useConfig } from '../../../../modules/config'
import { Form, Input, PasswordField, SingleCheckbox, SubmitButton } from '../../../form'
import { type LoginPayload } from '../../../../modules/users'
import Link from '../../../Link'
import useValidationSchema from './validationSchema'
import Route from '../../../../app/Route.enum'

const OrContainer = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  position: 'relative',
  margin: '4rem 0',
  color: grey[300],
  fontSize: '0.8rem',
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    top: '50%',
    height: 1,
    width: '43%',
    borderBottom: 'solid 1px',
  },
  '&::before': {
    left: 0,
  },
  '&::after': {
    right: 0,
  },
  [theme.breakpoints.down('md')]: {
    margin: '2rem 0',
  },
}))

const OAuthButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: '0.5rem',
}))

const OauthButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

type LoginFormProps = {
  onSubmit: (payload: LoginPayload) => Promise<any>
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation()
  const schema = useValidationSchema()
  const form = useForm<LoginPayload>({
    resolver: yupResolver(schema),
    defaultValues: {
      rememberMe: true,
    },
  })
  const apiUrl = useConfig('api.url')

  const googleOauthUrl = `${apiUrl}/login/google`
  const facebookOauthUrl = `${apiUrl}/login/facebook`

  return (
    <>
      <Form form={form} onSubmit={onSubmit}>
        <Input
          name="email"
          label={t('forms.fields.email.label')}
          variant="standard"
          margin="normal"
          autoFocus
        />

        <PasswordField
          name="password"
          label={t('forms.fields.password.label')}
          variant="standard"
          margin="normal"
        />

        <Box textAlign="right">
          <Link to={Route.ResetPasswordRequest} variant="primary">{ t('forms.fields.password.forgotPassword') }</Link>
        </Box>

        <Box mb={2}>
          <SingleCheckbox
            name="rememberMe"
            label={t('forms.fields.rememberMe.label')}
          />
        </Box>

        <SubmitButton size="large">
          { t('actions.login') }
        </SubmitButton>
      </Form>

      { /* todo : decide if we should implement oauth or not */ }

      { /* <OrContainer>
        { t('forms.login.or') }
      </OrContainer>

      <OAuthButtonsContainer>
        <OauthButton
          startIcon={<GoogleIcon />}
          variant="outlined"
          color="secondary"
          size="large"
          href={googleOauthUrl}

        >
          { t('forms.login.signInWithGoogle') }
        </OauthButton>

        <OauthButton
          startIcon={<FacebookIcon />}
          variant="outlined"
          color="secondary"
          size="large"
          href={facebookOauthUrl}
        >
          { t('forms.login.signInWithFacebook') }
        </OauthButton>
      </OAuthButtonsContainer> */ }

    </>
  )
}

export default LoginForm
