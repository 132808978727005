import Button, { type ButtonProps } from '@mui/material/Button'

import { useBreakpoint } from '../../../../modules/theme'

type ToolBarActionButtonProps = ButtonProps & {
  icon?: React.ReactNode
  children?: React.ReactNode
}

const ToolBarActionButton: React.FC<ToolBarActionButtonProps> = ({
  icon,
  children,
  ...buttonProps
}) => {
  const isLargeScreen = useBreakpoint('lg')

  return (
    <div>
      <Button
        variant="contained"
        size={isLargeScreen ? undefined : 'small'}
        startIcon={icon}
        color="secondary"
        className="!normal-case !leading-tight"
        {...buttonProps}
      >
        { children }
      </Button>
    </div>
  )
}

export default ToolBarActionButton
