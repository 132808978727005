import clsx from 'clsx'

import ProfileAvatar from '../ProfileAvatar/ProfileAvatar'
import PageContent from '../../layout/PageContent/PageContent'

type ProfileHeaderProps = {
  hideOnSmallDevices?: boolean
}

const ProfileHeader: React.FC<ProfileHeaderProps> = ({
  hideOnSmallDevices = false,
}) => {
  return (
    <div className={clsx(
      'mt-[-25px] h-[130px] border-b border-zinc-200 lg:mb-[25px] lg:h-[200px]',
      'bg-gradient-to-tl from-zinc-50/80 to-[#fa372c]/5',
      hideOnSmallDevices && 'hidden lg:block',
    )}
    >
      <PageContent>
        <ProfileAvatar
          size={160}
          showLetters={false}
          className="absolute left-1/2 top-[-5px] ml-[-80px] mt-[25px] box-border scale-[0.8] border-4 border-white lg:left-12 lg:top-[65px] lg:ml-0 lg:mt-0 lg:scale-100"
        />
      </PageContent>
    </div>
  )
}

export default ProfileHeader
