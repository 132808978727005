import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'

import { CustomControl, Form, Input, Label, Select, SubmitButton } from '../../../form'
import useValidationSchema from './validationSchema'
import { formatFornOutput, promoCodeToFormData } from './formDataBuilder'
import { useNavigate } from '../../../../modules/navigation'
import {
  type PromoCodePayload,
  type FullPromoCode,
  PromoCodeType,
} from '../../../../modules/adminPromoCodes'
import { Cell, Row } from '../../../cell'

type PromoCodeFormProps = {
  onSubmit: (payload: PromoCodePayload) => Promise<any>
  data?: FullPromoCode
  backlink?: string
}

const PromoCodeForm: React.FC<PromoCodeFormProps> = ({
  onSubmit,
  data,
  backlink,
}) => {
  const { t } = useTranslation()
  const { navigate } = useNavigate()
  const schema = useValidationSchema()
  const form = useForm<PromoCodePayload>({
    resolver: yupResolver(schema),
    defaultValues: data ? promoCodeToFormData(data) : undefined,
    mode: 'onTouched',
  })
  const editing = !!data

  const transformPromoCode = (value?: string) => {
    if (!value) {
      return
    }
    return value
      .toLocaleUpperCase()
      .replace(/[^0-9a-zA-Z_\- ]/g, '')
      .replace(/[_ ]/g, '-')
  }

  const handleSubmit = async (data: PromoCodePayload) => await onSubmit(formatFornOutput(data))

  return (
    <Form form={form} onSubmit={handleSubmit}>

      <div className="max-w-lg">
        <Row>
          <Cell>
            <Label label={t('forms.fields.promoCode.label')}>
              <CustomControl
                name="code"
                outgoingDataFormatter={transformPromoCode}
                applyLocally
              />
            </Label>
          </Cell>
        </Row>

        <Row>
          <Cell>
            <div className="flex gap-x-4">

              <div>
                <Label
                  label={t('forms.fields.couponValue.label')}
                  labelClassName="w-full"
                >
                  <Input
                    name="value"
                    className="max-w-[150px]"
                  />
                </Label>
              </div>

              <div className="shink-0 mt-[27px] w-[85px]">
                <Select
                  name="type"
                  options={[
                    { label: '$', value: PromoCodeType.Amount },
                    { label: '%', value: PromoCodeType.Percentage },
                  ]}
                />
              </div>

            </div>
          </Cell>
        </Row>

        <div className="mt-8 flex gap-3">
          { backlink && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => { navigate(backlink) }}
            >
              { t('actions.cancel') }
            </Button>
          ) }
          <SubmitButton size="large">
            { editing ? t('actions.update') : t('actions.create') }
          </SubmitButton>
        </div>
      </div>
    </Form>
  )
}

export default PromoCodeForm
