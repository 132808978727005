import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

import Logo from '../../Logo'
import Link from '../../Link'
import { LanguageSelector } from '../../../modules/i18n'
import Route from '../../../app/Route.enum'

const BackgroundContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100vw',
  height: '100dvh',
  background: theme.palette.mainBackground.main,
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    minHeight: '100dvh',
  },
  '&::before': {
    content: '""',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100dvh',
    background: theme.palette.mode === 'dark' ? 'url(\'/images/abstractBackground-dark.svg\')' : 'url(\'/images/abstractBackground.svg\')',
    backgroundSize: 'cover',
  },
}))

const Container = styled(Box)(({ theme }) => ({
  width: '85vw',
  maxWidth: 700,
  height: '80vh',
  borderRadius: 15,
  boxShadow: '0px 0px 25px 0px rgba(0,0,0,0.1)',
  background: theme.palette.mainBackground.main,
  overflow: 'auto',
  zIndex: 2,
  [theme.breakpoints.down('md')]: {
    margin: '2.5rem 0',
    width: 'calc(100vw - 5rem)',
    height: 'auto',
    overflow: 'visible',
  },
  [theme.breakpoints.down('sm')]: {
    margin: '1.25rem 0',
    width: 'calc(100vw - 2.5rem)',
  },
}))

const PanelsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}))

const MainPanel = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  width: '50%',
  padding: '4rem 6rem 8rem 6rem',
  boxSizing: 'border-box',
  [theme.breakpoints.down('lg')]: {
    padding: '4rem 3rem 8rem 3rem',
  },
  [theme.breakpoints.down('md')]: {
    padding: '1rem 3rem 2rem 3rem',
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '1rem 1.5rem',
  },
}))

const Header = styled('header')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& > a:first-of-type': {
    width: 200,
  },
  '& > a:last-of-type': {
    [theme.breakpoints.down('md')]: {
      marginTop: '1rem',
    },
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: '1rem 0 2rem 0',
    width: '100%',
    textAlign: 'center',
    '& svg': {
      maxWidth: 200,
    },
  },
}))

const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
  [theme.breakpoints.down('md')]: {
    paddingBottom: '2rem',
  },
}))

export type FloatingTwoColumnsPageProps = {
  children?: React.ReactNode
  hasError?: boolean
}

const FloatingPage: React.FC<FloatingTwoColumnsPageProps> = ({
  children,
  hasError,
}) => {
  return (
    <BackgroundContainer>
      <Container>
        <PanelsContainer>
          <MainPanel>
            <Header>
              <Link to={Route.Dashboard} reload={hasError}>
                <Logo />
              </Link>
              <LanguageSelector />
            </Header>
            <Content>
              <div>
                { children }
              </div>
            </Content>
          </MainPanel>
        </PanelsContainer>
      </Container>
    </BackgroundContainer>
  )
}

export default FloatingPage
