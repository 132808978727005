import { Label } from '../../../form'

type CloseJobRowProps = {
  label?: React.ReactNode
  description?: React.ReactNode
  children?: React.ReactNode
}

const CloseJobRow: React.FC<CloseJobRowProps> = ({
  label,
  description,
  children,
}) => {
  return (
    <div className="mb-4 flex flex-col gap-x-4 border-b pb-4 xl:mb-0 xl:flex-row xl:items-center xl:border-b-0 xl:pb-0">
      <Label
        label={label}
        labelClassName="flex flex-col xl:flex-row xl:items-center gap-x-4 pb-2 xl:pb-0"
        className="w-[300px] shrink-0 whitespace-nowrap"
      >
        { children }

      </Label>

      { description && (
        <div className="shrink text-neutral-400">
          { description }
        </div>
      ) }

    </div>
  )
}

export default CloseJobRow
