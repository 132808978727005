/* eslint-disable @typescript-eslint/no-misused-promises */
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactCountryFlag from 'react-country-flag'
import Button from '@mui/material/Button'
import UserIcon from '@mui/icons-material/Person'
import PendingInvitationIcon from '@mui/icons-material/PersonAdd'
import AddIcon from '@mui/icons-material/AddCircleOutline'
import PauseIcon from '@mui/icons-material/PauseCircleFilled'
import DownloadIcon from '@mui/icons-material/DownloadOutlined'
import MapIcon from '@mui/icons-material/MapOutlined'

import { SortDirection } from '../../../modules/graphQl'
import { type CompanyInvitation, useCompaniesBranches, useCompaniesExportForBlog } from '../../../modules/adminCompanies'
import { Permission, protectAdminPage, usePermission } from '../../../modules/users'
import ContainedPage from '../../../components/layout/ContainedPage/ContainedPage'
import PageContent from '../../../components/layout/PageContent/PageContent'
import PageTitle from '../../../components/layout/PageTitle/PageTitle'
import {
  DataGrid,
  Column,
  Row,
  Cell,
  SmallValue,
  DateValue,
  BooleanValue,
} from '../../../components/dataGrid'
import ServerError from '../../../components/errors/ServerError'
import { useTranslatedValue } from '../../../common/translation'
import Language from '../../../app/Language.enum'
import Link, { LinkVariant } from '../../../components/Link'
import Route from '../../../app/Route.enum'
import { capitalizeFirstLetter } from '../../../utils/string'
import { useActions } from './useActions'
import { mergeClassName } from '../../../utils/mergeClassName'
import { useNavigate } from '../../../modules/navigation'
import ConfirmationModal from '../../../components/ConfirmationModal'
import CompanyBranchPanel from './CompanyBranchPanel'
import { type AdminUser } from '../../../modules/adminUsers'
import Tooltip from '../../../components/Tooltip'
import { errorNotification } from '../../../components/ToastNotifications'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import { downloadBase64Data } from '../../../utils/file'
import { GoogleRating, MovingWaldoRating } from '../../../components/Rating'

const CompaniesPage: React.FC = () => {
  const { t } = useTranslation()
  const { fetchCompaniesBranches, data: companiesBranches, error: companiesBranchesErrors } = useCompaniesBranches({ reload: true })
  const getTranslation = useTranslatedValue()
  const { actions, modalProps } = useActions(companiesBranches?.data)
  const { exportCompaniesForBlog, loading: exportingCompaniesForBlog } = useCompaniesExportForBlog()
  const formatError = useErrorFormatter()

  const { navigate } = useNavigate()
  const [displayCompanyBranch, setDisplayCompanyBranch] = useState<string>()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onQueryUpdate = useCallback(fetchCompaniesBranches, [])

  const canReadUsers = usePermission(Permission.canReadUsers)
  const canManageCompanies = usePermission(Permission.canManageCompanies)

  const downloadCompaniesForBlog = async () => {
    try {
      downloadBase64Data(
        await exportCompaniesForBlog(),
        'companies.csv',
      )
    } catch (error) {
      console.error(error)
      errorNotification(formatError(error))
    }
  }

  const renderUser = (user: AdminUser) => {
    const identification = (
      <>
        <UserIcon className="mr-2 !text-[18px] text-slate-500" />

        { capitalizeFirstLetter(user.firstName) }
        { ' ' }
        { capitalizeFirstLetter(user.lastName) }
      </>
    )
    return (
      <>
        { canReadUsers
          ? (
            <Link
              to={Route.AdminUser}
              params={{ userId: user.id }}
              variant={LinkVariant.Primary}
              onClick={event => { event.stopPropagation() }}
            >
              { identification }
            </Link>
            )
          : identification }

        { ' ' }
        <SmallValue>
          ( { user.position } )
        </SmallValue>
      </>
    )
  }

  const renderPendingInvitation = (invitation: CompanyInvitation) => {
    const identification = !!invitation.firstName || !!invitation.lastName
      ? `${String(invitation.firstName ?? '')} ${String(invitation.lastName ?? '')}`
      : invitation.email

    return (
      <div className="rounded-md bg-gray-200 px-2 text-neutral-400">
        <PendingInvitationIcon className="mr-2 !text-[18px] text-slate-500" />
        <Link
          to={`mailto:${invitation.email}`}
          variant={LinkVariant.Underlined}
          onClick={event => { event.stopPropagation() }}
        >
          { identification }
        </Link>
        { ' ' }
        <SmallValue className="text-neutral-500">({ t('pages.admin.companies.table.invited') })</SmallValue>
      </div>
    )
  }

  if (companiesBranchesErrors) {
    return <ContainedPage><ServerError error={companiesBranchesErrors} /></ContainedPage>
  }

  return (
    <ContainedPage>
      <PageTitle fullWidth>
        <div className="flex flex-col justify-between gap-4 lg:flex-row">
          { t('pages.admin.companies.title') }

          <div>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<DownloadIcon />}
              onClick={downloadCompaniesForBlog}
              disabled={exportingCompaniesForBlog}
              className="!mr-4 !hidden lg:!inline-flex"
            >
              { t('pages.admin.companies.exportCompaniesForBlog') }
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<MapIcon />}
              onClick={() => { navigate(Route.CompaniesMap) }}
              className="!mr-4"
            >
              { t('pages.admin.companies.showOnMap') }
            </Button>
            { canManageCompanies && (
              <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={() => { navigate(Route.CreateCompany) }}
              >
                { t('actions.create') }
              </Button>
            ) }
          </div>
        </div>
      </PageTitle>

      <PageContent fullWidth className="py-8">
        <DataGrid
          name="companies"
          count={companiesBranches?.count ?? 0}
          onQueryUpdate={onQueryUpdate}
          showToggleDeleted
          initialQuery={{ sortField: 'createdAt', sortDirection: SortDirection.DESC }}
          actions={actions}
          columns={(
            <>
              <Column name="createdAt" sortable>
                { t('pages.admin.companies.table.columns.createdAt') }
              </Column>

              <Column name="approved" sortable>
                { t('pages.admin.companies.table.columns.approved') }
              </Column>

              <Column name="active" sortable>
                { t('pages.admin.companies.table.columns.active') }
              </Column>

              <Column name="company.identification.name.en" sortable>
                { t('pages.admin.companies.table.columns.companyName') }
              </Column>

              <Column name="tripAndTravel.trucksOriginAddress.city" sortable>
                { t('pages.admin.companies.table.columns.city') }
              </Column>

              <Column name="company.primaryContact.firstName" sortable>
                { t('pages.admin.companies.table.columns.primaryContact') }
              </Column>

              <Column name="setupCompletion">
                { t('pages.admin.companies.table.columns.completion') }
              </Column>

              <Column name="ratings.movingWaldo.rating" sortable>
                { t('pages.admin.companies.table.columns.ratingMW') }
              </Column>

              <Column name="ratings.google.rating" sortable>
                { t('pages.admin.companies.table.columns.ratingGoogle') }
              </Column>

              <Column name="communication.sendBookingEmailsToPrimaryContact" sortable defaultVisible={false}>
                { t('pages.admin.companies.table.columns.sendBookingEmailsToPrimaryContact') }
              </Column>

              <Column name="communication.bookingEmailCC" sortable defaultVisible={false}>
                { t('pages.admin.companies.table.columns.bookingEmailCC') }
              </Column>
            </>
          )}
        >
          { companiesBranches?.data?.map(companyBranch => (
            <Row
              key={companyBranch.id}
              id={companyBranch.id}
              disabled={!!companyBranch.deletedAt}
              onClick={setDisplayCompanyBranch}
            >
              <Cell><SmallValue><DateValue date={companyBranch.createdAt} /></SmallValue></Cell>

              <Cell center className="!pr-8">
                { !companyBranch.deletedAt && <BooleanValue value={companyBranch.approved} /> }
              </Cell>

              <Cell center className="!pr-8">
                { !companyBranch.deletedAt && companyBranch.active && (
                  <Tooltip tooltip={t('status.active')}>
                    <BooleanValue value={companyBranch.active} />
                  </Tooltip>
                ) }
                { !companyBranch.deletedAt && !companyBranch.active && (
                  <Tooltip tooltip={t('status.paused')}>
                    <PauseIcon className="text-orange-500" />
                  </Tooltip>
                ) }
              </Cell>

              <Cell expand>{ capitalizeFirstLetter(
                getTranslation(companyBranch.company?.identification?.name, Language.En),
              ) }
              </Cell>

              <Cell expand>
                <div className="flex items-center gap-x-1">
                  <div className="flex w-[20px] items-center">
                    { companyBranch.tripAndTravel?.trucksOriginAddress?.country && (
                      <ReactCountryFlag
                        countryCode={companyBranch.tripAndTravel.trucksOriginAddress.country}
                        svg
                      />
                    ) }
                  </div>

                  { capitalizeFirstLetter(
                    companyBranch.tripAndTravel?.trucksOriginAddress?.city,
                  ) }
                  { companyBranch.tripAndTravel?.trucksOriginAddress?.region && (
                    <>
                      { ', ' }
                      { companyBranch.tripAndTravel.trucksOriginAddress.region.toUpperCase() }
                    </>
                  ) }
                </div>
              </Cell>

              <Cell maxWidth={400}>
                { companyBranch.company.primaryContact && (
                  <div className={mergeClassName(
                    companyBranch.company.primaryContact.deletedAt && 'line-through opacity-50 child:line-through',
                  )}
                  >
                    { renderUser(companyBranch.company.primaryContact) }
                  </div>
                ) }
                { companyBranch.company.pendingInvitation && !companyBranch.company.primaryContact && (
                  <div>
                    { renderPendingInvitation(companyBranch.company.pendingInvitation) }
                  </div>
                ) }
              </Cell>

              <Cell center>
                <SmallValue>{ companyBranch.setupProgress }%</SmallValue>
              </Cell>

              <Cell>
                { companyBranch.ratings?.movingWaldo && (
                  <MovingWaldoRating {...companyBranch.ratings.movingWaldo} />
                ) }
              </Cell>

              <Cell>
                { companyBranch.ratings?.google && (
                  <GoogleRating {...companyBranch.ratings.google} />
                ) }
              </Cell>

              <Cell center>
                <BooleanValue
                  value={companyBranch?.communication?.sendBookingEmailsToPrimaryContact ?? true}
                />
              </Cell>

              <Cell>
                <SmallValue>
                  { companyBranch?.communication?.bookingEmailCC }
                </SmallValue>
              </Cell>
            </Row>
          )) }
        </DataGrid>
      </PageContent>

      { modalProps && <ConfirmationModal {...modalProps} /> }
      <CompanyBranchPanel
        actions={actions}
        companyBranch={
          displayCompanyBranch
            ? companiesBranches?.data?.find(companyBranch => companyBranch.id === displayCompanyBranch)
            : undefined
}
        onClose={() => { setDisplayCompanyBranch(undefined) }}
      />
    </ContainedPage>
  )
}

export default protectAdminPage(CompaniesPage)
