import { type FullMove } from '../../../../../modules/adminMoves'
import BalanceBox from './BalanceBox'
import InvoicesBox from './InvoicesBox'
import PaymentsBox from './PaymentsBox'

type AccountingTabProps = {
  move: FullMove
  refreshMove: () => Promise<void>
}

const AccountingTab: React.FC<AccountingTabProps> = ({
  move,
  refreshMove,
}) => {
  return (
    <>
      <BalanceBox
        move={move}
        refreshMove={refreshMove}
      />
      <InvoicesBox move={move} />
      <PaymentsBox move={move} />
    </>
  )
}

export default AccountingTab
