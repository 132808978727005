import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import MoneyIcon from '@mui/icons-material/MonetizationOnRounded'
import UnavailableIcon from '@mui/icons-material/EventBusy'

import {
  Modal,
  ModalDescription,
  ModalHeader,
  ModalTitle,
} from '../../../../components/Modal'
import useSelectedDateFormatted from './useSelectedDateFormatted'
import SelectedDates from './SelectedDates'
import PricingRuleForm from '../../../../components/forms/companyBranch/PricingRuleForm'
import AvailabilityRuleForm from '../../../../components/forms/companyBranch/AvailabilityRuleForm'
import { type AvailabilityRule, type PricingRule, RuleTemporalityRange } from '../../../../modules/companies/core/availabilityAndPricing/availabilityAndPricing.models'
import {
  Season,
  useCurrentCompanyBranch,
  useIsDateUnavailable,
  useSeason,
  useUpdateCompanyBranchAction,
} from '../../../../modules/companies'
import { useErrorFormatter } from '../../../../components/errors/useErrorFormatter'
import { errorNotification, successNotification } from '../../../../components/ToastNotifications'
import { useRuleById } from './useRuleById'
import RuleList from '../RuleList'
import DateLabourPricing from './DateLabourPricing'

export enum RuleType {
  Pricing = 'pricing',
  Availability = 'availability',
}

type RuleModalProps = {
  open: boolean
  onClose: () => void
  selectedRuleId?: string | null
  selectedDates?: { start: Date, end?: Date }
  creationType: RuleType | null
  onEditRule: (id: string) => void
}

const RuleModal: React.FC<RuleModalProps> = ({
  open,
  onClose,
  selectedRuleId,
  selectedDates,
  creationType,
  onEditRule,
}) => {
  const { t } = useTranslation()
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()
  const selectedRule = useRuleById(selectedRuleId)
  const getSeason = useSeason()
  const getDateUnavailability = useIsDateUnavailable()

  const singleDateSelected = !!selectedDates?.start && !selectedDates?.end

  const season = currentCompanyBranch && singleDateSelected
    ? getSeason(currentCompanyBranch, selectedDates.start)
    : undefined

  const dateUnavailable = currentCompanyBranch && singleDateSelected
    ? getDateUnavailability({
      companyBranch: currentCompanyBranch,
      date: selectedDates.start,
    })
    : undefined

  const [selectedCreationType, setSelectedCreationType] = useState<RuleType | null>(creationType)
  const selectedDatesFormatted = useSelectedDateFormatted(selectedDates)
  const { updateCompanyBranch } = useUpdateCompanyBranchAction()
  const formatError = useErrorFormatter()

  const selectedRuleType = selectedRule?.type ?? selectedCreationType

  useEffect(() => {
    if (!open && !creationType) {
      setSelectedCreationType(null)
    }
  }, [open, creationType])

  useEffect(() => {
    setSelectedCreationType(creationType)
  }, [creationType, setSelectedCreationType])

  const onAvailabilityRuleSubmit = async (rule: AvailabilityRule) => {
    const currentAvailabilityRules = currentCompanyBranch?.availabilityRules ?? []
    const updatedRules: AvailabilityRule[] = JSON.parse(JSON.stringify(currentAvailabilityRules))
    const editingIndex = currentAvailabilityRules.findIndex(r => r.id === rule.id)
    const editing = editingIndex !== -1

    if (editing) {
      updatedRules[editingIndex] = rule
    } else {
      updatedRules.push(rule)
    }

    try {
      await updateCompanyBranch({
        availabilityRules: updatedRules,
      })
      onClose()
      successNotification(editing
        ? t('pages.companyBranch.availability.ruleModal.updatedSuccess')
        : t('pages.companyBranch.availability.ruleModal.createdSuccess'),
      )
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  const onPricingRuleSubmit = async (rule: Omit<PricingRule, 'priority'>) => {
    const currentPricingRules = currentCompanyBranch?.pricingRules ?? []
    const updatedRules: PricingRule[] = JSON.parse(JSON.stringify(currentPricingRules))
    const editingIndex = currentPricingRules.findIndex(r => r.id === rule.id)
    const editing = editingIndex !== -1
    const priorityValues = currentPricingRules.map(r => r.priority)
    const highestPriority = priorityValues.length > 0 ? Math.max(...priorityValues) : 0

    if (editing) {
      const currentPriority = currentPricingRules.find(r => r.id === rule.id)?.priority ?? 0
      updatedRules[editingIndex] = {
        ...rule,
        priority: currentPriority,
      }
    } else {
      const newRule = {
        ...rule,
        priority: highestPriority + 1,
      }
      updatedRules.push(newRule)
    }

    try {
      await updateCompanyBranch({
        pricingRules: updatedRules,
      })
      onClose()
      successNotification(editing
        ? t('pages.companyBranch.availability.ruleModal.updatedSuccess')
        : t('pages.companyBranch.availability.ruleModal.createdSuccess'),
      )
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  const singleDate = !!selectedDates?.start && !selectedDates?.end
  const largeModal = selectedRuleType ?? singleDate

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={largeModal ? 'lg:w-[678px]' : 'lg:w-auto lg:max-w-[678px]'}
    >
      { !selectedRuleType && (
        <>
          <ModalHeader>
            <ModalTitle>
              { t('pages.companyBranch.availability.ruleModal.title') }
              <SelectedDates>
                { selectedDatesFormatted }

                { season && (
                  <div className="ml-4 px-1 text-sm text-neutral-500">
                    (
                    { ' ' }
                    { season === Season.Low && t('seasons.low.title') }
                    { season === Season.High && t('seasons.high.title') }
                    { ' ' }
                    )
                  </div>
                ) }

              </SelectedDates>
            </ModalTitle>
          </ModalHeader>
          <ModalDescription>

            <div className="flex flex-col gap-4 pb-10 pt-4 lg:flex-row lg:justify-around">
              <Button
                variant="contained"
                color="secondary"
                size="large"
                startIcon={<MoneyIcon className="!hidden md:!block" />}
                onClick={() => { setSelectedCreationType(RuleType.Pricing) }}
              >
                { t('pages.companyBranch.availability.ruleModal.titlePricing') }
              </Button>

              <Button
                variant="contained"
                color="error"
                size="large"
                startIcon={<UnavailableIcon className="!hidden md:!block" />}
                onClick={() => { setSelectedCreationType(RuleType.Availability) }}
              >
                { t('pages.companyBranch.availability.ruleModal.titleAvailability') }
              </Button>
            </div>

            { dateUnavailable === RuleTemporalityRange.Day && (
              <div className="mb-6 flex items-center justify-center gap-1 rounded-md bg-orange-100 p-3 text-orange-800">
                <UnavailableIcon />
                { ' ' }
                { t('pages.companyBranch.availability.ruleModal.unavailable.day') }
              </div>
            ) }

            { dateUnavailable === RuleTemporalityRange.Am && (
              <div className="mb-4 flex items-center justify-center gap-1 rounded-md bg-orange-100 p-3 text-orange-800">
                <UnavailableIcon className="!text-[18px]" />
                { ' ' }
                { t('pages.companyBranch.availability.ruleModal.unavailable.am') }
              </div>
            ) }

            { dateUnavailable === RuleTemporalityRange.Pm && (
              <div className="mb-4 flex items-center justify-center gap-1 rounded-md bg-orange-100 p-3 text-orange-800">
                <UnavailableIcon />
                { ' ' }
                { t('pages.companyBranch.availability.ruleModal.unavailable.pm') }
              </div>
            ) }

            { currentCompanyBranch && singleDateSelected && (
              <>
                { dateUnavailable !== RuleTemporalityRange.Day && (
                  <>
                    <div className="mb-2 font-body2 font-bold">
                      { t('pages.companyBranch.availability.ruleModal.dateLabourPrice') }
                    </div>
                    <DateLabourPricing companyBranch={currentCompanyBranch} date={selectedDates?.start} />
                  </>
                ) }

                <RuleList
                  period={selectedDates.start}
                  availabilityRules={currentCompanyBranch?.availabilityRules ?? []}
                  pricingRules={currentCompanyBranch?.pricingRules ?? []}
                  onEditRule={onEditRule}
                  compact
                  title={(
                    <div className="mb-2 font-body2 font-bold">
                      { t('pages.companyBranch.availability.ruleModal.dateActiveRules') }
                    </div>
                  )}
                />
              </>
            ) }
          </ModalDescription>
        </>
      ) }

      { selectedRuleType === RuleType.Availability && (
        <AvailabilityRuleForm
          onClose={onClose}
          onSubmit={onAvailabilityRuleSubmit}
          data={selectedRule?.rule as AvailabilityRule}
          selectedDates={selectedDates}
        />
      ) }

      { selectedRuleType === RuleType.Pricing && (
        <PricingRuleForm
          onClose={onClose}
          onSubmit={onPricingRuleSubmit}
          data={selectedRule?.rule as PricingRule}
          selectedDates={selectedDates}
        />
      ) }
    </Modal>
  )
}

export default RuleModal
