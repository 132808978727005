import { useMemo } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { enUS, frFR } from '@mui/x-date-pickers/locales'

import { useLanguage } from '../modules/i18n'
import Language from './Language.enum'

type MuiDatePickerProviderProps = {
  children?: React.ReactNode
}

const MuiDatePickerProvider: React.FC<MuiDatePickerProviderProps> = ({ children }) => {
  const language = useLanguage()
  const muiDatePickerLocale = useMemo(() => {
    switch (language) {
      case Language.Fr:
        return frFR
      case Language.En:
      default:
        return enUS
    }
  }, [language])

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      localeText={muiDatePickerLocale.components.MuiLocalizationProvider.defaultProps.localeText}
    >
      { children }
    </LocalizationProvider>
  )
}

export default MuiDatePickerProvider
