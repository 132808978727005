import { set } from 'date-fns/set'
import { fromZonedTime } from 'date-fns-tz'

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { type QuotesRequestFormValues } from './QuotesRequestForm.type'
import { type QuotesRequestPayload } from '../../../../modules/adminQuotes'
import { keyValueMapToRecord, recordToKeyValueMap } from '../../../../common/keyValueMap'
import { ignoreTimezone } from '../../../../utils/date'
import { type Address } from '../../../../common/address/address.model'

export const quotesRequestToFormData = (
  quotesRequest: QuotesRequestPayload,
): QuotesRequestFormValues => {
  return {
    ...quotesRequest,
    stops: undefined,
    movingDate: ignoreTimezone(quotesRequest.movingDate)!,
    overnightFees: quotesRequest.overnightFees ? String(quotesRequest.overnightFees) : undefined,
    nbTrucks: keyValueMapToRecord(
      quotesRequest.nbTrucks,
      value => String(value),
    ),
    nbMen: String(quotesRequest.nbMen),
    estimatedMovingLabourTime: String(quotesRequest.estimatedMovingLabourTime),
    estimatedPackingLabourTime: (quotesRequest.estimatedPackingLabourTime ?? 0) > 0
      ? String(quotesRequest.estimatedPackingLabourTime)
      : '0',
    origin: {
      ...quotesRequest.origin,
      nbStairs: quotesRequest.origin.nbStairs
        ? String(quotesRequest.origin.nbStairs)
        : undefined,
    },
    destination: {
      ...quotesRequest.destination,
      nbStairs: quotesRequest.destination.nbStairs
        ? String(quotesRequest.destination.nbStairs)
        : undefined,
    },
    specialServices: keyValueMapToRecord(
      quotesRequest.specialServices,
      value => String(value),
    ),
    specialItems: keyValueMapToRecord(
      quotesRequest.specialItems,
      value => String(value),
    ),
    protectiveMaterial: keyValueMapToRecord(
      quotesRequest.protectiveMaterial,
      value => String(value),
    ),
  }
}

const filterEmptyValues = <T extends Record<any, string>>(value: T) => {
  return Object.fromEntries(Object.entries(value).filter(([_, value]) => !!value && value !== '0')) as T
}

export const formatFormOutput = (data: QuotesRequestFormValues): QuotesRequestPayload => {
  const {
    nbTrucksTotal,
    specialServicesTotal,
    specialItemsTotal,
    protectiveMaterialTotal,
    ...formData
  } = data

  /**
   * moving date is expected to be UTC midnight
   */
  const movingDate = fromZonedTime(set(formData.movingDate, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  }), 'UTC')

  return {
    ...formData,
    stops: undefined,
    movingDate,
    overnightFees: formData.overnightFees ? parseInt(formData.overnightFees) : undefined,
    nbMen: parseInt(formData.nbMen),
    estimatedMovingLabourTime: parseFloat(formData.estimatedMovingLabourTime),
    estimatedPackingLabourTime: formData.estimatedPackingLabourTime
      ? parseFloat(formData.estimatedPackingLabourTime)
      : undefined,
    nbTrucks: recordToKeyValueMap(
      filterEmptyValues(formData.nbTrucks),
      parseInt,
    )!,
    origin: {
      address: {
        ...formData.origin.address,
        apartment: (formData.origin.address as Address).apartment !== ''
          ? (formData.origin.address as Address).apartment
          : undefined,
      },
      nbStairs: formData.origin.nbStairs
        ? parseInt(formData.origin.nbStairs)
        : undefined,
    },
    destination: {
      address: {
        ...formData.destination.address,
        apartment: (formData.destination.address as Address).apartment !== ''
          ? (formData.destination.address as Address).apartment
          : undefined,
      },
      nbStairs: formData.destination.nbStairs
        ? parseInt(formData.destination.nbStairs)
        : undefined,
    },
    specialServices: formData.specialServices
      ? recordToKeyValueMap(
        filterEmptyValues(formData.specialServices),
        parseInt,
      )
      : undefined,
    specialItems: formData.specialItems
      ? recordToKeyValueMap(
        filterEmptyValues(formData.specialItems),
        parseInt,
      )
      : undefined,
    protectiveMaterial: formData.protectiveMaterial
      ? recordToKeyValueMap(
        filterEmptyValues(formData.protectiveMaterial),
        parseInt,
      )
      : undefined,
  }
}
