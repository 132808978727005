export const removeTypeNameFromObject = <T,>(payload: any): T => {
  return JSON.parse(
    JSON.stringify(payload, (key, value) => {
      if (key === '__typename') {
        return
      }
      return value
    }),
  )
}
