import { gql } from '@apollo/client'

import { userFields } from './users.queries'

export const register = gql`
mutation Register($payload: RegisterPartnerDto!) {
  registerPartner(payload: $payload) {
    ${userFields}
  }
}
`

export const updatePartner = gql`
mutation UpdatePartner($payload: UpdatePartnerDto!) {
  updatePartner(payload: $payload) {
    ${userFields}
  }
}
`

export const updateLanguage = gql`
mutation UpdateLanguage {
  updateLanguage {
    ${userFields}
  }
}
`

export const updatePassword = gql`
mutation UpdatePassword($payload: UpdatePasswordDto!) {
  updatePassword(payload: $payload) {
    ${userFields}
  }
}
`

export const verifyEmail = gql`
mutation VerifyEmail($payload: VerifyEmailDto!) {
  verifyEmail(payload: $payload){
    ${userFields}
  }
}
`

export const resendVerificationEmail = gql`
mutation ResendVerificationEmail {
  resendVerificationEmail
}
`

export const deleteAccountRequest = gql`
mutation DeleteAccountRequest {
  deleteAccountRequest
}
`

export const deleteAccount = gql`
mutation DeleteAccount($payload: DeleteAccountDto!) {
  deleteAccount(payload: $payload)
}
`
