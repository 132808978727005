import {
  type Actions,
  ACTION_TYPES,
} from './graphQl.actions'

export const key = 'graphQl'

const initialState = {
  validationErrors: [],
}

export const reducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case ACTION_TYPES.SET_VALIDATION_ERRORS: {
      return {
        ...state,
        validationErrors: action.validationErrors,
      }
    }
    default:
      return state
  }
}
