import persistReducer from '../../../store/persistReducer'
import {
  type Actions,
  ACTION_TYPES,
} from './theme.actions'

export const key = 'theme'
export const version = 1
export const persistFields = [
  'darkModeEnabled',
]

const initialState = {
  darkModeEnabled: false,
}

const rawReducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case ACTION_TYPES.THEME_SET_DARK_MODE: {
      return {
        ...state,
        darkModeEnabled: action.darkModeEnabled,
      }
    }
    default:
      return state
  }
}

export const reducer = persistReducer(rawReducer, {
  key,
  version,
  persistFields,
}) as typeof rawReducer
