export type ReportInterval = {
  start: Date
  end: Date
}

export enum ReportIntervalType {
  YearToDate = 'yearToDate',
  MonthToDate = 'monthToDate',
  PreviousMonth = 'previousMonth',
  Custom = 'custom',
}

export type ReportIntervalValue = {
  type: ReportIntervalType.Custom
  interval: ReportInterval
} | { type: ReportIntervalType.YearToDate | ReportIntervalType.MonthToDate | ReportIntervalType.PreviousMonth }
