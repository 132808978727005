import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Box from '@mui/material/Box'

import { Form, Input, SubmitButton } from '../../../form'
import Link from '../../../Link'
import { type ResetPasswordRequestPayload } from '../../../../modules/users'
import useValidationSchema from './validationSchema'
import Route from '../../../../app/Route.enum'

type ResetPasswordRequestFormProps = {
  onSubmit: (payload: ResetPasswordRequestPayload) => Promise<any>
}

const ResetPasswordRequestForm: React.FC<ResetPasswordRequestFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation()

  const schema = useValidationSchema()
  const form = useForm({ resolver: yupResolver(schema) })

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Input
        name="email"
        label={t('forms.fields.email.label')}
        variant="standard"
        margin="normal"
        autoFocus
      />

      <Box mt={4}>
        <SubmitButton>{ t('actions.resetPasswordRequest') }</SubmitButton>
        <Link
          to={Route.Login}
          className="ml-2 mt-6 block lg:ml-8 lg:mt-0 lg:inline-block"
          variant="primary"
        >
          { t('actions.cancel') }
        </Link>
      </Box>
    </Form>
  )
}

export default ResetPasswordRequestForm
