import { useState } from 'react'
import { useWatch } from 'react-hook-form'
import Input, { type InputProps } from '@concrete-form/mui/Input/Input'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import PasswordTooltip from './PasswordTooltip'

type PasswordFieldProps = {
  showValidations?: boolean
} & InputProps

const PasswordField: React.FC<PasswordFieldProps> = ({
  name,
  showValidations = false,
  ...inputProps
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const [hasFocus, setHasFocus] = useState(false)
  const value = useWatch({ name })

  const handleClickShowPassword = () => { setShowPassword(!showPassword) }

  return (
    <PasswordTooltip
      value={value}
      open={showValidations && hasFocus}
    >
      <div>
        <Input
          {...inputProps}
          type={showPassword ? 'text' : 'password'}
          name={name}
          onFocus={() => { setHasFocus(true) }}
          onBlur={() => { setHasFocus(false) }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  { showPassword ? <VisibilityOff /> : <Visibility /> }
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </PasswordTooltip>
  )
}

export default PasswordField
