import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, useFormState, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import InputAdornment from '@mui/material/InputAdornment'
import Alert from '@mui/material/Alert'
import CurrencyIcon from '@mui/icons-material/AccountBalanceOutlined'

import { type CompanyBranch, type CompanyBranchTripAndTravel } from '../../../../modules/companies'
import { Form, Input, Label, AddressField } from '../../../form'
import useValidationSchema from './validationSchema'
import Section from '../../../Section'
import Paper from '../../../Paper/Paper'
import { CompanyBranchTripAndTravelToFormData, formatFormOutput } from './formDataBuilder'
import SubmitButton from '../SubmitButton'
import PricingRulesForm from './PricingRulesForm'
import { type TripAndTravelFormValues } from './TripAndTravel.type'
import { DEFAULT_MAX_DISTANCE, DEFAULT_MAX_PICKUP_RADIUS, DEFAULT_RULES } from './TripAndTravel.const'
import { getCurrencyForCountry } from '../../../../utils/money'

type TripAndTravelFormProps = {
  onSubmit: (payload: CompanyBranchTripAndTravel) => Promise<any>
  companyBranch?: CompanyBranch
}

const TripAndTravelForm: React.FC<TripAndTravelFormProps> = ({
  onSubmit,
  companyBranch,
}) => {
  const initialValues: Partial<TripAndTravelFormValues> = {
    maxPickupRadius: String(DEFAULT_MAX_PICKUP_RADIUS),
    maxDistance: String(DEFAULT_MAX_DISTANCE),
    rules: DEFAULT_RULES,
  }

  const defaultValues = companyBranch?.tripAndTravel
    ? CompanyBranchTripAndTravelToFormData(companyBranch.tripAndTravel)
    : initialValues

  const { t } = useTranslation()
  const schema = useValidationSchema()
  const form = useForm<TripAndTravelFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'onTouched',
  })
  const { submitCount } = useFormState({ control: form.control })
  const [maxPickupRadius, maxDistance] = useWatch({ control: form.control, name: ['maxPickupRadius', 'maxDistance'] })
  const [country, postalCode] = useWatch({ control: form.control, name: ['trucksOriginAddress.country', 'trucksOriginAddress.postalCode'] })
  const currency = getCurrencyForCountry(country)
  const isUs = country?.toUpperCase() === 'US'

  const formatBeforeSubmit = async (values: TripAndTravelFormValues) => await onSubmit(formatFormOutput(values))

  /**
   * trigger validation on "max distance" when "max pickup radius" is updated
   * required since a field can become valid without changing
   */
  useEffect(() => {
    if (!maxDistance) {
      return
    }
    form.setValue('maxDistance', maxDistance, { shouldValidate: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitCount, maxPickupRadius])

  return (
    <Form form={form} onSubmit={formatBeforeSubmit}>
      <Section>
        { t('forms.companyBranch.tripAndTravel.trucksOriginAddress.title') }
      </Section>

      <Alert severity="info" className="mb-4">
        <div className="text-base">
          { t('forms.companyBranch.tripAndTravel.trucksOriginAddress.info') }
        </div>
      </Alert>

      <Paper>
        <AddressField
          name="trucksOriginAddress"
          required
        />
      </Paper>

      <Section>
        { t('forms.companyBranch.tripAndTravel.currency.title') }
      </Section>
      <Paper>
        { postalCode
          ? (
            <>
              { t(
                'forms.companyBranch.tripAndTravel.currency.basedOnCountry',
                { country: t(`countries.${country}`) },
              ) }
              <br />
              <div className="my-4 inline-flex items-center gap-x-4 rounded-md border p-4">
                <CurrencyIcon className="text-neutral-400" />
                <div className="font-bold">
                  { t(`currencies.${currency}`) } ({ currency })
                </div>
              </div>
              <div className="text-neutral-500">
                { t('forms.companyBranch.tripAndTravel.currency.pleaseUseThisCurrency') }
              </div>
            </>
            )
          : (
            <em>
              { t('forms.companyBranch.tripAndTravel.currency.notReady') }
            </em>
            ) }
      </Paper>

      <Section>
        { t('forms.companyBranch.tripAndTravel.maxDistance.title') }
      </Section>
      <Paper>
        <Label label={t('forms.companyBranch.tripAndTravel.maxDistance.maxPickupRadiusLabel')} required>
          <div className="mb-4 xl:w-64">
            <Input
              name="maxPickupRadius"
              size="small"
              inputProps={{ maxLength: 5 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    km
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Label>

        <Label label={t('forms.companyBranch.tripAndTravel.maxDistance.maxDistanceLabel')} required>
          <div className="xl:w-64">
            <Input
              name="maxDistance"
              size="small"
              inputProps={{ maxLength: 4 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    km
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Label>
      </Paper>

      <Section>
        { t('forms.companyBranch.tripAndTravel.travelAndTransport.title') }
      </Section>

      <Alert severity="info" className="mb-4">
        <div className="text-base">
          { t('forms.companyBranch.tripAndTravel.travelAndTransport.info') }
        </div>
      </Alert>

      { isUs && (
        <Alert severity="warning" className="mb-4">
          <div className="text-base">
            { t('forms.companyBranch.tripAndTravel.travelAndTransport.usWarning') }
          </div>
        </Alert>
      ) }

      <Paper className="bg-zinc-50">
        <PricingRulesForm
          companyBranch={companyBranch}
          currency={currency}
        />
      </Paper>

      <SubmitButton companyBranch={companyBranch} />
    </Form>
  )
}

export default TripAndTravelForm
