import { useTranslation } from 'react-i18next'
import Alert from '@mui/material/Alert'

import { type PaymentMethod as PaymentMethodModel } from '../../../modules/payment'
import { mergeClassName } from '../../../utils/mergeClassName'
import CreditCardLogo from './CreditCardLogo'
import { useFormattedCreditCard } from './useFormattedCreditCard'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

type PaymentMethodProps = {
  paymentMethod: PaymentMethodModel
} & ReactDiv

const PaymentMethod: React.FC<PaymentMethodProps> = ({
  paymentMethod,
  className,
  ...props
}) => {
  const { t } = useTranslation()
  const {
    brand,
    formattedDate,
    expired,
    expiresSoon,
  } = useFormattedCreditCard(paymentMethod)

  return (
    <div className="flex flex-col gap-y-4">
      <div
        className={mergeClassName(
          'flex rounded-md border border-neutral-200 pr-4 gap-x-4 p-3',
          className,
        )}
        {...props}
      >
        <div className="flex size-[60px] items-center justify-center rounded-full bg-gray-100 p-2">
          <CreditCardLogo
            brand={paymentMethod.brand}
          />
        </div>
        <div>
          <div className="mt-2 font-sans text-lg leading-tight text-neutral-800">
            <div className="whitespace-no-wrap flex gap-x-2">
              <span className="text-neutral-400">
                •••• •••• ••••
              </span>
              { paymentMethod.lastFourDigits }
            </div>
          </div>
          <div className="flex flex-wrap items-center gap-x-2">
            <div className="text-sm">
              { brand }
            </div>
            <div className="text-sm text-neutral-400">
              { t('components.paymentMethod.expiration') }
              { ' ' }
              { formattedDate }
            </div>
          </div>
        </div>
      </div>
      { expired && (
        <Alert severity="error">
          { t('components.paymentMethod.expiredCard') }
        </Alert>
      ) }
      { expiresSoon && (
        <Alert severity="warning">
          { t('components.paymentMethod.cardExpiresSoon') }
        </Alert>
      ) }
    </div>
  )
}

export default PaymentMethod
