import { PercentPieChart } from '../../../../components/charts'

type RowStats = {
  label: React.ReactNode
  value: React.ReactNode
}

type ConversionRowProps = {
  value: number
  stats: Record<string, RowStats>
}

const ConversionRow: React.FC<ConversionRowProps> = ({
  value,
  stats,
}) => {
  return (
    <div className="mb-8 mt-2 flex flex-col items-center gap-x-8 gap-y-4 lg:flex-row">
      <div className="size-[130px] shrink-0">
        <PercentPieChart value={value} />
      </div>

      <div className="flex flex-col items-center gap-y-2 lg:items-stretch">

        { Object.entries(stats).map(([key, rowStats]) => (

          <div key={key} className="flex items-center gap-x-4 lg:justify-between 2xl:gap-x-2">
            <div className="text-sm font-medium leading-tight text-gray-500 2xl:text-right">
              { rowStats.label }
            </div>
            <div className="shrink-0 grow-0 font-bold lg:w-[50px]">
              { rowStats.value }
            </div>
          </div>
        )) }
      </div>
    </div>
  )
}

export default ConversionRow
