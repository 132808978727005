import { mergeClassName } from '../../../utils/mergeClassName'

type SmallValueProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const SmallValue: React.FC<SmallValueProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={mergeClassName(
        'inline-flex h-[28px] items-center text-xs opacity-60',
        className,
      )}
      {...props}
    >
      { children }
    </div>
  )
}

export default SmallValue
