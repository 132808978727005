import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ServerError from '../../../components/errors/ServerError'
import Spinner from '../../../components/Spinner'
import { useCurrentUser, protectPage } from '../../../modules/users'
import SectionBlock from '../../../components/Section'
import Paper from '../../../components/Paper'
import MobileHeader from '../../../components/layout/MobileHeader/MobileHeader'
import DisplayPersonalInformations from '../../../components/users/profile/identification/DisplayPersonalInformations'
import EditPersonalInformations from '../../../components/users/profile/identification/EditPersonalInformations'
import Route from '../../../app/Route.enum'
import PageContainer from './PageContainer'

enum Section {
  Identification = 1
}

const IdentificationPage: React.FC = () => {
  const { t } = useTranslation()
  const { loading, error } = useCurrentUser({ reload: true })
  const [editingSection, setEditingSection] = useState<Section>()

  const onClose = () => { setEditingSection(undefined) }

  if (loading || error) {
    return (
      <PageContainer>
        { loading
          ? (
            <Spinner floating />
            )
          : (
            <ServerError error={error} />
            ) }

      </PageContainer>
    )
  }

  return (
    <PageContainer
      mobileHeader={<MobileHeader title={String(t('pages.users.profile.identification.title'))} backlinkTo={Route.Profile} />}
    >
      <SectionBlock
        onEdit={() => { setEditingSection(Section.Identification) }}
        editing={!!editingSection}
      >
        { t('pages.users.profile.identification.personalInformations.title') }
      </SectionBlock>

      <Paper>
        { editingSection === Section.Identification
          ? (
            <EditPersonalInformations onClose={onClose} />
            )
          : (
            <DisplayPersonalInformations />
            ) }
      </Paper>
    </PageContainer>
  )
}

export default protectPage(IdentificationPage)
