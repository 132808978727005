import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, useWatch, useFormState } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Box from '@mui/material/Box'

import { Form, PasswordField, SubmitButton } from '../../../form'
import Link from '../../../Link'
import { type ResetPasswordFormPayload } from './ResetPasswordFormPayload.type'
import useValidationSchema from './validationSchema'
import Route from '../../../../app/Route.enum'

type ResetPasswordFormProps = {
  onSubmit: (payload: ResetPasswordFormPayload) => Promise<any>
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation()
  const schema = useValidationSchema()
  const form = useForm({ resolver: yupResolver(schema) })
  const { submitCount } = useFormState({ control: form.control })
  const [password, passwordConfirmation] = useWatch({ control: form.control, name: ['password', 'passwordConfirmation'] })

  /**
   * trigger validation on "new password confirmation" when "new password" is updated
   * required since a field can become valid without changing
   */
  useEffect(() => {
    if (submitCount < 1) {
      return
    }
    form.setValue('passwordConfirmation', passwordConfirmation, { shouldValidate: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitCount, password])

  return (
    <Form form={form} onSubmit={onSubmit}>
      <PasswordField
        showValidations
        name="password"
        label={t('forms.fields.password.label')}
        variant="standard"
        margin="normal"
        autoFocus
      />

      <PasswordField
        name="passwordConfirmation"
        label={t('forms.fields.passwordConfirmation.label')}
        variant="standard"
        margin="normal"
      />

      <Box mt={4}>
        <SubmitButton>{ t('actions.resetPassword') }</SubmitButton>
        <Link
          to={Route.Login}
          className="ml-2 mt-6 block lg:ml-8 lg:mt-0 lg:inline-block"
          variant="primary"
        >
          { t('actions.cancel') }
        </Link>
      </Box>
    </Form>
  )
}

export default ResetPasswordForm
