import { mergeClassName } from '../../utils/mergeClassName'

type CardMetricProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>

const CardMetric: React.FC<CardMetricProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <dd
      className={mergeClassName(
        'w-full flex flex-wrap justify-between text-3xl font-medium leading-10 tracking-tight text-gray-900',
        className,
      )}
      {...props}
    >
      { children }
    </dd>
  )
}

export default CardMetric
