type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

type HorizontalBarItemProps = {
  value: number
  details?: React.ReactNode
} & ReactDiv

const HorizontalBarItem: React.FC<HorizontalBarItemProps> = ({
  value,
  details,
  className,
  children,
  ...props
}) => {
  return (
    <div
      {...props}
    >

      <div>
        <div className="mb-1 flex items-end justify-between text-neutral-600">
          <div className="font-body2 text-sm font-bold">
            { children }
          </div>
          { details && (
            <div className="whitespace-nowrap text-sm text-neutral-400">
              { details }
            </div>
          ) }
        </div>
        <div className="w-full overflow-hidden rounded-md bg-gray-200">
          <div className="h-[5px] bg-slate-400" style={{ width: `${value}%` }} />
        </div>
      </div>
    </div>
  )
}

export default HorizontalBarItem
