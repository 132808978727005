import {
  useCurrentUser,
  useCurrentUserAccountType,
  useRoles,
} from '../modules/users'
import Spinner from '../components/Spinner/Spinner'
import { Error403Page, Error500Page } from '../pages/errors'
import { CompanyBranchNotifications, useCurrentCompany } from '../modules/companies'
import { JobRequestsFetcher, IncomingJobRequestsNotification } from '../modules/jobRequests'

type AppGateProps = {
  children?: React.ReactNode
}

/**
 * App Gate make sure that the required data is available before rendering app
 */
const AppGate: React.FC<AppGateProps> = ({ children }) => {
  const roles = useRoles()
  const user = useCurrentUser()
  const { isAdmin, isPartner } = useCurrentUserAccountType()
  const company = useCurrentCompany()

  const hasProperRole = isAdmin || isPartner
  const loadingApp = user.loading || company.loading || roles.loading

  if (loadingApp) {
    return <Spinner floating />
  }

  if (!!user.error || !!company.error || !!roles.error) {
    return <Error500Page />
  }

  if (user.data && !hasProperRole) {
    return <Error403Page />
  }

  return (
    <CompanyBranchNotifications>
      { children }

      { /* fetch job requests periodically and show a notification when a new one is received */ }
      <JobRequestsFetcher />
      <IncomingJobRequestsNotification />
    </CompanyBranchNotifications>
  )
}

export default AppGate
