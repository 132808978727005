import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import InputAdornment from '@mui/material/InputAdornment'

import { type InvoiceEstimate } from '../../../../modules/invoices'
import {
  formatPrice,
  multiply,
} from '../../../../utils/money'
import Paper from '../../../Paper'
import { Input } from '../../../form'
import { type Amount } from '../../../../common/amount'

type TipProps = {
  invoiceEstimate: InvoiceEstimate
}

const Tip: React.FC<TipProps> = ({
  invoiceEstimate,
}) => {
  const { t } = useTranslation()
  const fieldName = 'tip'
  const form = useFormContext()

  if ((invoiceEstimate.subtotal?.price ?? 0) <= 0) {
    return null
  }

  /**
   * apply a tip
   */
  const applyTip = (tip: Amount) => {
    form.setValue(fieldName, String(tip.price), { shouldValidate: true })
  }

  return (
    <Paper className="3xl:mt-8">

      <h2 className="mb-4 font-body2 text-lg font-semibold text-slate-700">
        { t('forms.invoice.sidebar.tip.title') }
      </h2>

      <div className="flex justify-between">
        { [15, 18, 20].map((tipPercentage) => {
          const tipValue = multiply(invoiceEstimate.subtotal, tipPercentage / 100)
          return (
            <div
              key={tipPercentage}
              className="align-center flex justify-center"
            >
              <div
                className="cursor-pointer rounded-lg bg-slate-500 px-6 py-4 text-center text-white hover:bg-slate-500/90"
                onClick={() => { applyTip(tipValue) }}
              >
                <div className="text-lg">
                  { tipPercentage }%
                </div>

                <div className="whitespace-nowrap text-xs">
                  { formatPrice(
                    tipValue,
                    { compact: true },
                  ) }
                </div>
              </div>
            </div>
          )
        }) }
      </div>

      <div className="flex justify-between pt-4">
        <div className="pt-2 text-neutral-500">
          { t('forms.fields.tipAmount.label') }
        </div>

        <div className="max-w-[175px]">
          <Input
            name={fieldName}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  $
                </InputAdornment>
              ),
              className: 'bg-white',
            }}
            inputProps={{
              maxLength: 8,
            }}
            size="small"
          />
        </div>
      </div>

    </Paper>
  )
}

export default Tip
