type CardLabelProps = {
  title: React.ReactNode
  subtitle?: React.ReactNode
  Icon?: React.FC<{ className?: string }>
}

const CardLabel: React.FC<CardLabelProps> = ({
  title,
  subtitle,
  Icon,
}) => {
  return (
    <>
      { Icon && (
        <Icon className="mr-3 text-gray-600" />
      ) }
      <div>
        <div>{ title }</div>
        { subtitle && (<div className="text-sm text-zinc-500">{ subtitle }</div>) }
      </div>
    </>
  )
}

export default CardLabel
