import { useTranslation } from 'react-i18next'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

type EditAllProps = {
  value: boolean
  onChange: (value: boolean) => void
}

const EditAll: React.FC<EditAllProps> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation()
  return (
    <label>
      <FormControlLabel
        control={<Switch value={value} onChange={(_, checked) => { onChange(checked) }} />}
        label={(
          <>
            { t('forms.move.quote.editAllfields.label') }
            <br />
            <strong>
              { t('forms.move.quote.editAllfields.iKnowWhatImDoing') }
            </strong>
          </>
        )}
      />
    </label>
  )
}

export default EditAll
