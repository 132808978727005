import { useTranslation } from 'react-i18next'

import { ProtectiveMaterial } from '../../../../../modules/companies'
import Paper from '../../../../Paper'
import { CollapsableIntegerCollectionField } from '../../../../form'

const ProtectiveMaterialFormContent: React.FC = () => {
  const { t } = useTranslation()

  const protectiveMaterialOptions = Object.values(ProtectiveMaterial).map(protectiveMaterial => ({
    value: protectiveMaterial,
    label: t(`protectiveMaterial.${protectiveMaterial}.title`, { count: 2 }),
  }))

  return (
    <>
      <h2 className="mb-2 font-body2 text-lg font-bold lg:my-4">
        { t('forms.quotesRequest.protectiveMaterial.title') }
      </h2>
      <Paper>
        <CollapsableIntegerCollectionField
          name="protectiveMaterial"
          options={protectiveMaterialOptions}
          getLabel={(key, value) => t(`protectiveMaterial.${key}.title`, { count: value })}
          emptyValue={t('forms.quotesRequest.protectiveMaterial.noProtectiveMaterial')}
          max={99}
        />
      </Paper>
    </>
  )
}

export default ProtectiveMaterialFormContent
