import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { type CompanyBranchPricing } from './CompanyBranchPricing'

type PriceValidationOptions = {
  allowDecimal?: boolean
  allowZero?: boolean
  required?: boolean
  isBasePrice?: boolean
}

export const usePriceValidation = () => {
  const { t } = useTranslation()

  return ({
    allowDecimal = true,
    allowZero = false,
    required = false,
    isBasePrice = false,
  }: PriceValidationOptions = {}) => {
    const pattern = allowDecimal ? /^([0-9]+(\.[0-9]{1,2})?)?$/ : /^[0-9]*$/

    const validator = Yup.string()
      .matches(
        pattern,
        t('forms.companyBranch.validationErrors.invalidPrice'),
      )
      .test(
        'notZero',
        isBasePrice
          ? t('forms.companyBranch.validationErrors.basePriceNotZero')
          : t('forms.companyBranch.validationErrors.priceNotZero'),
        value => value ? allowZero || parseFloat(value) > 0 : true,
      )
    return required ? validator.required() : validator
  }
}

type PricesValidationOptions = {
  basePriceRequired?: boolean
  allowBasePriceZero?: boolean
  schema?: Yup.ObjectSchema<any, CompanyBranchPricing<string>>
} & Omit<PriceValidationOptions, 'required' | 'isBasePrice'>

export const usePricesValidation = () => {
  const priceValidation = usePriceValidation()

  return ({
    basePriceRequired = false,
    allowBasePriceZero,
    schema = Yup.object<CompanyBranchPricing<string>>(),
    ...priceValidationOptions
  }: PricesValidationOptions = {}) => {
    allowBasePriceZero ??= priceValidationOptions.allowZero ?? false

    return schema.shape({
      basePrice: priceValidation({
        ...priceValidationOptions,
        allowZero: allowBasePriceZero,
        required: basePriceRequired,
        isBasePrice: true,
      }),
      low: priceValidation({ ...priceValidationOptions }),
      high: priceValidation({ ...priceValidationOptions }),
    })
  }
}
