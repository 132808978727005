import Route from '../../../app/Route.enum'
import { JobRequestStatus, type LightJobRequest } from '../../../modules/jobRequests'
import { mergeClassName } from '../../../utils/mergeClassName'
import Link from '../../Link'
import Status from './Status'
import MovingDate from './MovingDate'
import Adresses from './Adresses'
import MoveSize from './MoveSize'
import Estimate from './Estimate'
import Customer from './Customer'
import { useCurrentCompanyBranch } from '../../../modules/companies'

type JobRequestItemProps = {
  jobRequest: LightJobRequest
  compact?: boolean
}

const JobRequestItem: React.FC<JobRequestItemProps> = ({
  jobRequest,
  compact = false,
}) => {
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()
  const isPending = jobRequest.status === JobRequestStatus.Pending

  if (!currentCompanyBranch) {
    return null
  }

  return (
    <Link
      to={Route.JobRequest}
      params={{
        branchId: currentCompanyBranch.id,
        jobRequestId: jobRequest.id,
      }}
    >
      <div className={mergeClassName(
        'relative my-5 rounded-3xl border bg-white px-8 py-6 shadow-md shadow-neutral-300/30 transition-all  duration-200 hover:shadow-lg',
        isPending && 'rounded-tl-none',
      )}
      >
        { isPending && (
          <>
            <div className="absolute left-[-4px] top-[-4px] z-10 size-[8px] rounded-full bg-red-400" />
            <div className="absolute left-[-4px] top-[-4px] size-[8px] animate-ping rounded-full bg-transparent shadow-[0_0_0_3px_rgba(255,0,0,0.1)]" />
          </>
        ) }

        <div className="flex justify-between">

          <div className="flex grow flex-col gap-y-3 sm:flex-row">

            <div className="flex flex-col gap-y-5">
              <Status jobRequest={jobRequest} compact={compact} />
              <div className="mb-5 sm:mb-0 lg:hidden">
                <Estimate jobRequest={jobRequest} compact={compact} />
              </div>
            </div>

            <div className="flex grow flex-col gap-y-2">
              <Customer jobRequest={jobRequest} compact={compact} />

              { !compact && (
                <div className="flex grow flex-col gap-y-3 xl:flex-row">
                  <div className="flex grow flex-col gap-y-3 2xl:flex-row">
                    <MovingDate jobRequest={jobRequest} />
                    <Adresses jobRequest={jobRequest} />
                  </div>

                  <MoveSize jobRequest={jobRequest} />
                </div>
              ) }
            </div>

            <div className="hidden lg:block">
              <Estimate jobRequest={jobRequest} compact={compact} />
            </div>
          </div>
        </div>

      </div>
    </Link>
  )
}

export default JobRequestItem
