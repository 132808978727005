import { useEffect } from 'react'

import Route from '../../../app/Route.enum'
import { useNavigate, useParams } from '../../../modules/navigation'
import { useCurrentCompanyBranch } from '../../../modules/companies'
import { protectPage } from '../../../modules/users'
import Spinner from '../../../components/Spinner/Spinner'
import ContainedPage from '../../../components/layout/ContainedPage'
import { Error404Page } from '../../errors'

const JobRequestsLandingPage: React.FC = () => {
  const { branchId } = useParams()
  const { replace } = useNavigate()
  const {
    data: currentCompanyBranch,
    error: currentCompanyBranchError,
    loading: currentCompanyBranchLoading,
  } = useCurrentCompanyBranch()

  const companyCreated = !!currentCompanyBranch
  const needBranchRedirection = !branchId

  /**
   * 1) if user has no company, redirect to dashboard so he can create one
   * 2) if branchId is not present in url, redirect to it
   */
  useEffect(() => {
    if (!!currentCompanyBranchError || currentCompanyBranchLoading) {
      return
    }
    if (!companyCreated) {
      replace(Route.Dashboard); return
    }
    if (needBranchRedirection) {
      replace(Route.JobRequests, { branchId: currentCompanyBranch.id })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompanyBranchError, currentCompanyBranchLoading, companyCreated, needBranchRedirection])

  if (currentCompanyBranchLoading) {
    return <ContainedPage><Spinner floating /></ContainedPage>
  }

  if (currentCompanyBranchError) {
    return <Error404Page />
  }

  return null
}

export default protectPage(JobRequestsLandingPage)
