import Chip from '@mui/material/Chip'

type TagValueProps = {
  value?: string | string[] | null
}

const TagValue: React.FC<TagValueProps> = ({
  value,
}) => {
  if (!value) {
    return null
  }

  const values = Array.isArray(value) ? value : [value]

  return (
    <div className="flex gap-2">
      { values.map(value => (
        <Chip
          key={value}
          label={value}
          size="small"
        />
      )) }
    </div>
  )
}

export default TagValue
