import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Alert from '@mui/material/Alert'

import { type CompanyBranch, type CompanyBranchSeasons, Season } from '../../../../modules/companies'
import { Form, MonthSelect, ToggleSwitch, DaySelect } from '../../../form'
import useValidationSchema from './validationSchema'
import Section from '../../../Section'
import Paper from '../../../Paper/Paper'
import { formatFormOutput, companyBranchSeasonsToFormData } from './formDataBuilder'
import SubmitButton from '../SubmitButton'
import { Cell, Label, Row } from '../../../cell'
import { type SeasonsFormValues, computedName } from './SeasonsForm.type'

type SeasonsFormProps = {
  onSubmit: (payload: CompanyBranchSeasons) => Promise<any>
  companyBranch?: CompanyBranch
}

const SeasonsForm: React.FC<SeasonsFormProps> = ({
  onSubmit,
  companyBranch,
}) => {
  const initialValues: SeasonsFormValues = {
    [Season.Low]: {
      enabled: true,
      from: { day: '1', month: '12' },
      to: { day: '29', month: '2' },
    },
    [Season.High]: {
      enabled: true,
      from: { day: '15', month: '5' },
      to: { day: '15', month: '9' },
    },
  }

  const { t } = useTranslation()
  const schema = useValidationSchema()
  const form = useForm<SeasonsFormValues>({
    resolver: yupResolver(schema),
    defaultValues: companyBranch?.seasons
      ? companyBranchSeasonsToFormData(companyBranch.seasons)
      : initialValues,
    mode: 'onChange',
  })
  const { [computedName]: _, ...formValues } = form.getValues()
  const formValuesJson = JSON.stringify(formValues)
  const errors = form.formState.errors
  const globalError = errors[computedName]

  const formatBeforeSubmit = async (values: SeasonsFormValues) => await onSubmit(formatFormOutput(values))

  /**
   * form global validation
   */
  useEffect(() => {
    const formValues: SeasonsFormValues = JSON.parse(formValuesJson)
    form.setValue(
      computedName,
      formatFormOutput(formValues),
      { shouldValidate: true },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValuesJson])

  return (
    <Form form={form} onSubmit={formatBeforeSubmit}>
      <Section>
        { t('forms.companyBranch.seasons.title') }
      </Section>

      <Alert severity="info" className="mb-4">
        <div className="text-base">
          { t('forms.companyBranch.seasons.tip') }
        </div>
      </Alert>

      <Paper className="bg-zinc-50">
        { Object.values(Season).map(season => (
          <Paper key={season} className="mt-2">
            <ToggleSwitch
              name={`${season}.enabled`}
              label={(
                <h3 className="font-body2 text-[1.4rem] font-light leading-7">
                  { t(`seasons.${season}.title`) }
                </h3>
              )}
            />
            <Row>

              { ['from', 'to'].map(segment => (
                <Cell key={segment}>
                  <Label>
                    { t(`forms.companyBranch.seasons.${segment}`) }
                  </Label>
                  <div className="flex shrink-0 gap-8">
                    <DaySelect
                      name={`${season}.${segment}.day`}
                      textFieldProps={{ fullWidth: false, size: 'small' }}
                      className="mr-[10px] w-[80px] md:w-[100px]"
                      disabled={!formValues?.[season]?.enabled}
                    />
                    <MonthSelect
                      name={`${season}.${segment}.month`}
                      textFieldProps={{ size: 'small' }}
                      disabled={!formValues?.[season]?.enabled}
                    />
                  </div>
                </Cell>
              )) }

            </Row>
          </Paper>
        )) }

        { typeof globalError?.message === 'string' && (
          <div className="mt-4 lg:mt-6">
            <Alert severity="error">
              { globalError?.message }
            </Alert>
          </div>
        ) }
      </Paper>

      <SubmitButton companyBranch={companyBranch} />
    </Form>
  )
}

export default SeasonsForm
