import { mergeClassName } from '../../utils/mergeClassName'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

type HorizontalBarListProps = ReactDiv

const HorizontalBarList: React.FC<HorizontalBarListProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={mergeClassName(
        'flex flex-col gap-y-8',
        className,
      )}
      {...props}
    >
      { children }
    </div>
  )
}

export default HorizontalBarList
