import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useValidationErrors } from '../../modules/graphQl'

const useBackendValidationErrors = () => {
  const { t } = useTranslation()
  const fieldsError = useValidationErrors()
  const { formState: { submitCount }, setError } = useFormContext()

  useEffect(() => {
    if (!fieldsError || fieldsError.length === 0 || submitCount === 0) {
      return
    }
    fieldsError.forEach((field) => {
      setError(field, { type: 'custom', message: String(t('formValidation.default')) })
    })
  }, [fieldsError, submitCount, setError, t])
}

export default useBackendValidationErrors
