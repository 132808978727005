import { useTranslation } from 'react-i18next'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

import Spinner from '../../../components/Spinner'
import ServerError from '../../../components/errors/ServerError'
import ContainedPage from '../../../components/layout/ContainedPage'
import PageContent from '../../../components/layout/PageContent'
import PageTitle from '../../../components/layout/PageTitle'
import { useNavigate, useParams } from '../../../modules/navigation'
import { protectAdminPage } from '../../../modules/users'
import { validateUuid } from '../../../utils/uuid'
import { Error404Page } from '../../errors'
import Route from '../../../app/Route.enum'
import Link from '../../../components/Link'
import { capitalizeFirstLetter } from '../../../utils/string'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import { errorNotification, successNotification } from '../../../components/ToastNotifications'
import { usePromoCode, useUpdatePromoCodeAction, type PromoCodePayload } from '../../../modules/adminPromoCodes'
import PromoCodeForm from '../../../components/forms/promoCodes/PromoCodeForm'

const EditPromoCodePage: React.FC = () => {
  const { t } = useTranslation()
  const { promoCodeId } = useParams()
  const { navigate } = useNavigate()
  const validId = validateUuid(promoCodeId)
  const { data: PromoCode, loading, error } = usePromoCode(promoCodeId ?? '', { skip: !validId })
  const { updatePromoCode, loading: updatingPromoCode } = useUpdatePromoCodeAction()
  const formatError = useErrorFormatter()

  const onSubmit = async (payload: PromoCodePayload) => {
    if (!promoCodeId) {
      return
    }
    try {
      await updatePromoCode(promoCodeId, payload)
      successNotification('Promo code updated')
      navigate(Route.PromoCodes)
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  if (!validId || !promoCodeId) {
    return <Error404Page />
  }

  if (error) {
    return (
      <ContainedPage>
        <ServerError error={error} />
      </ContainedPage>
    )
  }

  if (loading || updatingPromoCode || !PromoCode) {
    return <ContainedPage><Spinner /></ContainedPage>
  }

  return (
    <ContainedPage>
      <PageTitle>
        <Link to={Route.PromoCodes} className="hidden lg:block">
          <div className="ml-[-5px] text-base/loose underline">
            <ChevronLeftIcon /> { t('actions.goBack') }
          </div>
        </Link>
        { capitalizeFirstLetter(PromoCode.code) }
      </PageTitle>

      <PageContent className="px-3 py-8 pr-4">

        <PromoCodeForm
          data={PromoCode}
          onSubmit={onSubmit}
          backlink={Route.PromoCodes}
        />
      </PageContent>
    </ContainedPage>
  )
}

export default protectAdminPage(EditPromoCodePage)
