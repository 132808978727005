import { useTranslation } from 'react-i18next'

import { type FullMove } from '../../../modules/adminMoves'
import { useMoveStatus, MoveStatus } from '../../../modules/moves'
import { mergeClassName } from '../../../utils/mergeClassName'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

type MoveStatusTagProps = {
  move: FullMove
} & ReactDiv

const MoveStatusTag: React.FC<MoveStatusTagProps> = ({
  move,
  className,
  ...props
}) => {
  const { t } = useTranslation()
  const status = useMoveStatus(move)

  if (!status) {
    return null
  }

  const statusTitle = t(`move.status.${status}.title`)

  return (
    <div
      className={mergeClassName(
        'inline-flex rounded-full text-sm px-2 py-2 font-sans items-center justify-center leading-none',
        status === MoveStatus.AgentMeetingBooked && 'bg-green-400',
        status === MoveStatus.Abandoned && 'bg-orange-300',
        status === MoveStatus.NotSupported && 'bg-gray-300',
        status === MoveStatus.QuoteAccepted && 'bg-green-800 text-white',
        status === MoveStatus.MoveApprovedByAgent && 'bg-green-800 text-white',
        status === MoveStatus.JobAcceptedByPartner && 'bg-green-800 text-white',
        status === MoveStatus.Selected && 'bg-green-400',
        status === MoveStatus.Hot && 'bg-lime-300',
        status === MoveStatus.Pending && 'bg-green-200',
        className,
      )}
      {...props}
    >
      { statusTitle }
    </div>
  )
}

export default MoveStatusTag
