import { Currency } from '../../common/amount'

type CurrencyDetail = {
  nbDecimals: number
  symbol: string
}

/**
 * returns details of the given currency (nb decimals, symbol, etc)
 */
export const getCurrencyDetails = (currency: Currency): CurrencyDetail => {
  switch (currency) {
    case Currency.USD:
    case Currency.CAD:
      return { nbDecimals: 2, symbol: '$' }
    case Currency.EUR:
      return { nbDecimals: 2, symbol: '€' }
    default:
      throw new Error(`Currency not supported: ${currency as string}`)
  }
}
