import CheckIcon from '@mui/icons-material/Check'

import { type PricingRule } from '../../../../modules/companies/core/availabilityAndPricing/availabilityAndPricing.models'
import { useTemporalityRuleHandler } from '../../../../modules/companies/core/availabilityAndPricing/temporalityRules/temporalityRules.hooks'

type PricingRuleItemProps = {
  rule: PricingRule
  active?: boolean
}

const PricingRuleItem: React.FC<PricingRuleItemProps> = ({
  rule,
  active = false,
}) => {
  const getRuleHandler = useTemporalityRuleHandler()
  const handler = getRuleHandler(rule.temporality)

  return (
    <div className="flex grow items-center gap-2 text-sm">
      <div className="size-[16px] rounded" style={{ backgroundColor: handler?.getColor() }} />
      <span>
        { active && (
          <>
            <CheckIcon className="!text-[18px]" />
            { ' ' }
          </>
        ) }
        <span className={active ? 'font-bold text-black' : undefined}>
          { handler?.getTitle() }
        </span>
      </span>
    </div>
  )
}

export default PricingRuleItem
