import { createContext, useContext } from 'react'
import { type DataGridContextData } from './DataGrid.type'

export const DataGridContext = createContext<DataGridContextData | null>(null)

export type DataGridProviderProps = {
  children: React.ReactNode
} & DataGridContextData

export const DataGridProvider: React.FC<DataGridProviderProps> = ({
  children,
  ...contextData
}) => {
  return (
    <DataGridContext.Provider
      value={contextData}
    >
      { children }
    </DataGridContext.Provider>
  )
}

export const useDataGridContext = (): DataGridContextData => {
  const context = useContext(DataGridContext)
  if (!context) {
    throw new Error('Missing data grid context. Did you forget to use <DataGrid /> ?')
  }
  return context
}
