import { useTranslation } from 'react-i18next'

import { Currency } from '../../../../common/amount'
import HorizontalBarList, { HorizontalBarItem } from '../../../../components/HorizontalBarList'
import { Card } from '../../../../components/card'
import { useAdminDashboardMetrics } from '../../../../modules/dashboard'
import { formatPrice } from '../../../../utils/money'

const TopCompanies: React.FC = () => {
  const { t } = useTranslation()
  const { data: dashboardMetrics } = useAdminDashboardMetrics()

  const topCompaniesMetrics = dashboardMetrics?.moves?.topCompanies

  if (!topCompaniesMetrics) {
    return null
  }

  const total = topCompaniesMetrics.reduce((acc, curr) => acc + curr.revenue, 0)

  return (
    <div className="flex flex-col">
      <h2 className="mb-4 mt-5 text-xl leading-6 text-gray-900">
        { t('pages.admin.dashboard.topCompanies.title') }
        { ' ' }
        <span className="text-base text-gray-500">(
          { t('pages.admin.dashboard.last30Days') }
          )
        </span>
      </h2>

      <Card className="grow xl:col-span-2 xl:p-8">

        <HorizontalBarList className="m-2 overflow-y-auto pr-2 xl:max-h-[415px]">

          { topCompaniesMetrics.map(({ label, count, revenue }) => (

            <HorizontalBarItem
              key={label}
              value={(revenue / total) * 100}
              details={(
                <>
                  { count }
                  { ' / ' }
                  { formatPrice(
                    {
                      price: revenue,
                      currency: Currency.CAD,
                    },
                    {
                      showDecimals: false,
                      compact: true,
                    },
                  ) }
                </>
              )}
            >
              { label }
            </HorizontalBarItem>

          )) }

        </HorizontalBarList>
      </Card>
    </div>
  )
}

export default TopCompanies
