import { useTranslation } from 'react-i18next'
import InvoiceIcon from '@mui/icons-material/RequestQuoteOutlined'

import { type FullMove } from '../../../../../modules/adminMoves'
import Paper from '../../../../../components/Paper'
import InvoicesTable from './InvoicesTable'

type InvoicesBoxProps = {
  move: FullMove
}

const InvoicesBox: React.FC<InvoicesBoxProps> = ({
  move,
}) => {
  const { t } = useTranslation()
  return (
    <Paper>
      <h2 className="mb-4 flex items-center gap-x-2 font-body2 text-2xl font-semibold text-slate-700 ">
        <InvoiceIcon />
        <div>
          { t('pages.admin.move.accounting.invoices.title') }
        </div>
      </h2>

      <div className="mb-6">
        <InvoicesTable move={move} />
      </div>
    </Paper>
  )
}

export default InvoicesBox
