import { type Quote } from './quotes.models'
import { QuotesView } from './quotes.types'
import bestViewHandler from './viewHandlers/best.viewHandler'
import cheapestViewHandler from './viewHandlers/cheapest.viewHandler'
import {
  getCheaperQuoteDate,
  getCheaperQuotePrice,
} from './utils/quotes.utils'
import { useMove } from '../../adminMoves'
import { type PromoCode } from '../../adminPromoCodes'
import { applyPromoCode } from '../../adminPromoCodes/core/promoCodes.utils'
import { useParams } from '../../navigation'

const viewHandlerFactory = (view: QuotesView) => {
  switch (view) {
    case QuotesView.best:
      return bestViewHandler
    case QuotesView.cheapest:
    default:
      return cheapestViewHandler
  }
}

/**
 * get current quotes view
 */
export const useQuotesView = () => {
  return QuotesView.cheapest
}

/**
 * returns sorted quotes
 */
export const useSortedQuote = (quotes: Quote[], view?: QuotesView) => {
  return useQuotesSorter()(quotes, view)
}

/**
 * returns a function to filter the quotes
 */
export const useQuotesSorter = () => {
  const savedView = useQuotesView()
  return (quotes: Quote[], view?: QuotesView) => {
    return viewHandlerFactory(view ?? savedView).sort([...quotes])
  }
}

/**
 * returns constraints to display based on filter applied
 */
export const useQuoteConstraints = (quote: Quote) => {
  const savedView = useQuotesView()
  return viewHandlerFactory(savedView).getConstraints?.(quote) ?? quote.constraints
}

/**
 * returns a cheaper alternative date available for a quote (if available)
 */
export const useCheaperDate = (quote: Quote) => {
  const { moveId } = useParams()
  const { data: move } = useMove(moveId)
  return getCheaperQuoteDate(quote, move)
}

/**
 * returns a cheaper price for a quote (if available)
 */
export const useCheaperPrice = (quote: Quote, promoCode?: PromoCode) => {
  const cheaperQuotePrice = getCheaperQuotePrice(quote)
  if (!cheaperQuotePrice) {
    return
  }
  if (!promoCode) {
    return cheaperQuotePrice
  }
  return applyPromoCode(cheaperQuotePrice, promoCode)
}
