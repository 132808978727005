import { useState, useRef, useCallback } from 'react'

/**
 * manage rows selection for grid table
 */
const useRowSelection = () => {
  const rows = useRef<string[]>([])
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const allSelected = rows.current.length > 0 && selectedRows.length >= rows.current.length

  const registerRow = (id: string) => {
    rows.current.push(id)
  }

  const unRegisterRow = (id: string) => {
    rows.current = rows.current.filter(row => row !== id)
    setSelectedRows([])
  }

  const toggleRowSelection = useCallback((id: string) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter(selectedRowId => selectedRowId !== id))
    } else {
      setSelectedRows([...selectedRows, id])
    }
  }, [selectedRows])

  const toggleAllRowSelection = useCallback(() => {
    setSelectedRows(allSelected ? [] : [...rows.current])
  }, [allSelected])

  const clearSelection = () => { setSelectedRows([]) }

  return {
    selectedRows,
    allSelected,
    registerRow,
    unRegisterRow,
    toggleRowSelection,
    toggleAllRowSelection,
    clearSelection,
  }
}

export default useRowSelection
