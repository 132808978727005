import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ErrorIcon from '@mui/icons-material/Warning'

import { useMonitoring } from '../../modules/monitoring'
import FloatingPage from '../../components/layout/FloatingPage'

const Error403Page: React.FC = () => {
  const { t } = useTranslation()
  const { logWarn } = useMonitoring()

  useEffect(() => {
    logWarn(`Error 403 : ${window.location.href}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FloatingPage hasError>
      <div className="text-center">
        <ErrorIcon className="!text-[5rem] text-destructive" />
        <br />
        <h1 className="mb-3 font-body2 text-4xl lg:text-6xl">{ t('pages.403.title') }</h1>
        <h2 className="whitespace-pre-line font-body2 text-lg lg:text-2xl">{ t('pages.403.subtitle') }</h2>
      </div>
    </FloatingPage>
  )
}

export default Error403Page
