import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import ReactCountryFlag from 'react-country-flag'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

import { Input, Select, Label } from '../..'
import AddressAutoCompleteControl from './AddressAutocompleteControl'
import type Address from './Address.type'
import { useCountry, useRegions } from '../../../../modules/localization'

export type AddressFieldProps = {
  name: string
  required?: boolean
  editCountry?: boolean
  allowUs?: boolean
}

const Flag = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    height: 20,
    marginRight: '0.5rem',
  },
}))

/**
 * @deprecated use PlaceField instead
 */
const AddressField: React.FC<AddressFieldProps> = ({
  name,
  required = false,
  editCountry = true,
  allowUs = true,
}) => {
  const { t } = useTranslation()
  const currentCountry = useCountry()
  const countryValue = useWatch({ name: `${name}.country` })
  const { setValue: setFormValue, formState } = useFormContext()
  const iteration = useRef(0)
  const addressCountry = countryValue ?? currentCountry
  const regions = useRegions(addressCountry)
  const hasErrors = Object.keys(formState?.errors ?? {}).length > 0

  const onCountryChange = () => {
    setFormValue(`${name}.apartment`, '')
    setFormValue(`${name}.street`, '')
    setFormValue(`${name}.city`, '')
    setFormValue(`${name}.region`, '')
    setFormValue(`${name}.postalCode`, '')
    iteration.current++
  }

  const CanadaFlag = <ReactCountryFlag countryCode="CA" svg className="mr-2" />
  const UsaFlag = <ReactCountryFlag countryCode="US" svg className="mr-2" />

  const countriesOptions = [
    { value: 'CA', label: <Flag>{ CanadaFlag } { t('countries.CA') }</Flag> },
  ]

  if (allowUs) {
    countriesOptions.push(
      { value: 'US', label: <Flag>{ UsaFlag } { t('countries.US') }</Flag> },
    )
  }

  const regionsOptions = Object.entries(regions).map(([code, name]) => ({
    label: name,
    value: code,
  }))

  const onSelectAddress = (address: Address) => {
    setFormValue(`${name}.street`, address.street, { shouldValidate: hasErrors })
    setFormValue(`${name}.city`, address.city, { shouldValidate: hasErrors })
    setFormValue(`${name}.region`, address.region, { shouldValidate: true })
    setFormValue(`${name}.postalCode`, address.postalCode, { shouldValidate: hasErrors })
  }

  const regionLabel = addressCountry === 'US'
    ? t('components.formFields.AddressField.fields.state.label')
    : t('components.formFields.AddressField.fields.province.label')

  const postalCodeLabel = addressCountry === 'US'
    ? t('components.formFields.AddressField.fields.zipCode.label')
    : t('components.formFields.AddressField.fields.postalCode.label')

  return (
    <div className="flex flex-col md:gap-4">
      { editCountry && (
        <div className="mb-2 flex flex-col md:mb-0 md:grid md:grid-cols-2 md:gap-4">
          <Label label={t('components.formFields.AddressField.fields.country.label')} required={required}>
            <Select
              name={`${name}.country`}
              options={countriesOptions}
              required={required}
              data-testid="address-country"
              onChange={onCountryChange}
              size="small"
            />
          </Label>
        </div>
      ) }

      <div className="mb-2 flex flex-col gap-2 md:mb-0 md:grid md:grid-cols-12 md:gap-4">
        <div className="md:col-span-8">
          <AddressAutoCompleteControl
            key={`street-${String(countryValue)}-${iteration.current}`}
            name={name}
            required={required}
            onSelect={onSelectAddress}
            country={addressCountry}
          />
        </div>

        <div className="md:col-span-4">
          <Label label={t('components.formFields.AddressField.fields.apartment.label')}>
            <Input
              name={`${name}.apartment`}
              inputProps={{ maxLength: 10 }}
              size="small"
            />
          </Label>
        </div>
      </div>

      <div className="flex flex-col gap-2 md:grid md:grid-cols-12 md:gap-4">
        <div className="md:col-span-6">
          <Label label={t('components.formFields.AddressField.fields.city.label')} required={required}>
            <Input
              name={`${name}.city`}
              required={required}
              inputProps={{ maxLength: 255 }}
              size="small"
            />
          </Label>
        </div>

        <div className="md:col-span-3 xl:col-span-4">
          <Label label={regionLabel} required={required}>
            <Select
              name={`${name}.region`}
              options={regionsOptions}
              allowEmpty
              required={required}
              data-testid="address-region"
              size="small"
            />
          </Label>
        </div>

        <div className="md:col-span-3 xl:col-span-2">
          <Label label={postalCodeLabel} required={required}>
            <Input
              name={`${name}.postalCode`}
              required={required}
              inputProps={{ maxLength: 7 }}
              size="small"
            />
          </Label>
        </div>
      </div>
    </div>
  )
}

export default AddressField
