import { useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/ExpandLess'

import IntegerCollectionField, {
  type IntegerCollectionFieldProps,
} from '../IntegerCollectionField'
import { Quantities } from '../../../cell'

type CollapsableIntegerCollectionFieldProps = {
  getLabel: (key: string, value: number) => string
  emptyValue?: React.ReactNode
  openByDefault?: boolean
} & IntegerCollectionFieldProps

const CollapsableIntegerCollectionField: React.FC<CollapsableIntegerCollectionFieldProps> = ({
  name,
  getLabel,
  emptyValue,
  openByDefault = false,
  ...props
}) => {
  const { t } = useTranslation()
  const [editing, setEditing] = useState(openByDefault)

  const value: Record<any, number> = useWatch({ name }) ?? {}

  const toggleEditing = () => {
    setEditing(isEditing => !isEditing)
  }

  const quantities = Object
    .entries(value)
    .filter(([, value]) => value > 0)
    .map(([key, value]) => ({
      key: getLabel(key, value),
      value,
    }))
  const isEmpty = quantities.length === 0

  return (
    <>
      { editing
        ? (
          <IntegerCollectionField
            name={name}
            small
            {...props}
          />
          )
        : (
          <>
            { isEmpty && !!emptyValue && (<em>{ emptyValue }</em>) }
            <Quantities>
              { quantities }
            </Quantities>
          </>
          ) }

      <Button
        variant="outlined"
        color="secondary"
        size="small"
        onClick={toggleEditing}
        className="!mb-1 !mt-4"
        startIcon={editing ? <CloseIcon /> : <EditIcon />}
      >
        { editing ? t('actions.close') : t('actions.edit') }
      </Button>
    </>
  )
}

export default CollapsableIntegerCollectionField
