import { gql } from '@apollo/client'

import { companyBranchFields } from '../companies.queries'

export const setCalendarExportEnabled = gql`
mutation SetCalendarExportEnabled($companyBranchId: String!, $enabled: Boolean!) {
  setCalendarExportEnabled(companyBranchId: $companyBranchId, enabled: $enabled) {
    ${companyBranchFields}
  }
}
`

export const updateCalendarUrl = gql`
mutation UpdateCalendarUrl($companyBranchId: String!) {
  updateCalendarUrl(companyBranchId: $companyBranchId) {
    ${companyBranchFields}
  }
}
`

export const setImportedCalendars = gql`
mutation SetImportedCalendars($companyBranchId: String!, $payload: UpdateImportedCalendarsDto!) {
  setImportedCalendars(companyBranchId: $companyBranchId, payload: $payload) {
    ${companyBranchFields}
  }
}
`
