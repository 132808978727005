import { useTranslation } from 'react-i18next'
import { differenceInSeconds } from 'date-fns/differenceInSeconds'
import PersonIcon from '@mui/icons-material/Person'
import CheckIcon from '@mui/icons-material/Check'
import PersonOffIcon from '@mui/icons-material/PersonOff'

import { capitalizeFirstLetter } from '../../../utils/string'
import { Row, Cell, Label, Value } from '../../../components/cell'
import { formatDateTime, useDateFormatter } from '../../../utils/date'
import { mergeClassName } from '../../../utils/mergeClassName'
import { type AdminUser } from '../../../modules/adminUsers'
import { useConfig } from '../../../modules/config'
import TimeAgo from '../../../components/TimeAgo'

type UserStatusProps = {
  user: AdminUser
  full?: boolean
}

const UserStatus: React.FC<UserStatusProps> = ({
  user,
  full = true,
}) => {
  const { t } = useTranslation()
  const onlineThresholdSeconds = useConfig<number>('users.onlineThresholdSeconds')
  const formatDate = useDateFormatter()

  const isDeleted = !!user.deletedAt

  const isOnline = user.lastConnexion
    ? differenceInSeconds(new Date(), new Date(user.lastConnexion)) <= onlineThresholdSeconds
    : false

  return (
    <>
      <Row>
        <Cell>
          <Label>
            { t('pages.admin.user.status.status') }
          </Label>
          <Value
            className={mergeClassName(!isDeleted ? 'text-green-800' : 'text-red-800')}
          >
            { isDeleted
              ? (
                <>
                  <PersonOffIcon />
                  { ' ' }
                  { t('pages.admin.user.status.deletedAt') }
                  { ' ' }
                  { formatDate(user.deletedAt) }
                </>
                )
              : (
                <>
                  <CheckIcon /> { t('status.active') }
                </>
                ) }
          </Value>
        </Cell>

        <Cell>
          <Label>
            { t('pages.admin.user.status.createdAt') }
          </Label>
          <Value title={formatDateTime(user.createdAt)}>{ formatDate(user.createdAt) }</Value>
        </Cell>

        { full && (
          <Cell>
            <Label>
              { t('pages.admin.user.status.updatedAt') }
            </Label>
            <Value title={formatDateTime(user.updatedAt)}>{ formatDate(user.updatedAt) }</Value>
          </Cell>
        ) }
      </Row>

      <Row>
        <Cell>
          <Label>
            { t('pages.admin.user.status.online') }
          </Label>
          <Value
            className={mergeClassName(isOnline ? 'text-green-800' : 'text-red-800')}
          >
            <PersonIcon />
            <span className="uppercase">
              { isOnline
                ? t('status.online')
                : t('status.offline') }
            </span>
          </Value>
        </Cell>

        <Cell>
          <Label>
            { t('pages.admin.user.status.lastConnexion.label') }
          </Label>
          <Value>
            { user.lastConnexion
              ? (
                <TimeAgo
                  date={user.lastConnexion}
                  title={formatDateTime(user.lastConnexion)}
                />
                )
              : t('pages.admin.user.status.lastConnexion.never') }
          </Value>
        </Cell>

        { full && (
          <Cell>
            { user.externalProvider && (
              <>
                <Label>
                  { t('pages.admin.user.status.source') }
                </Label>
                <Value>
                  { capitalizeFirstLetter(user.externalProvider) }
                </Value>
              </>
            ) }
          </Cell>
        ) }
      </Row>
    </>
  )
}

export default UserStatus
