import { type LightJob } from '../../../modules/jobs'
import Price from '../../Price'

type EstimateProps = {
  job: LightJob
  cta?: React.ReactNode
}

const Estimate: React.FC<EstimateProps> = ({
  job,
  cta,
}) => {
  return (
    <div className="text-center lg:min-w-[220px] lg:text-right 2xl:text-right">
      <div className="text-xl">
        { cta ?? (
          <Price amount={job.subtotal} />
        ) }

      </div>
    </div>
  )
}

export default Estimate
